import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link} from 'react-router-dom';

import './css/BotonesPortada.css'

const BotonesPortada = (props) => (
	<div id="contenedor-botones" className="col-12 sin-margen-y-padding">

		<div className="row sin-margen-y-padding mb-2">
			<div id="titulo-botones" className="col-lg-4 col-md-3 col-sm-4">
				<h3>Servicios</h3>
			</div>
		</div>

		<div className="row sin-margen-y-padding">


			<Link to="/pacientes/analisis-clinicos" id="btn1-inicio" type="button" className="nav-link-topage py-3 btns-inicio" rel="external" data-ajax="false"><div className="col-12"><FontAwesomeIcon className="my-1" icon="microscope" /><br /> <p>Laboratorio Clínico</p></div></Link> {/*target="_self"*/}

			<Link to="/laboratorios-y-hospitales/analisis-clinicos" id="btn2-inicio" type="button" className="nav-link-topage py-3 btns-inicio" rel="external" data-ajax="false"><div className="col-12"><FontAwesomeIcon className="my-1" icon="dna" /><br /> <p>Laboratorio de Referencia</p></div></Link>

			<Link to="/empresas-e-indusrias/analisis-microbiologico" id="btn3-inicio" type="button" className="nav-link-topage py-3 btns-inicio" rel="external" data-ajax="false"><div className="col-12"><FontAwesomeIcon className="my-1" icon="flask" /><br /> <p>Laboratorio Industrial</p></div></Link>

			<Link to="/veterinaria/analisis-clinicos" id="btn4-inicio" type="button" className="nav-link-topage py-3 btns-inicio" rel="external" data-ajax="false"><div className="col-12"><FontAwesomeIcon className="my-1" icon="paw" /><br /><p>Laboratorio Veterinario</p></div></Link>

			<Link to="/servicios-medicos" id="btn5-inicio" type="button" className="nav-link-topage py-3 btns-inicio" rel="external" data-ajax="false"><div className="col-12"><FontAwesomeIcon className="my-1" icon="user-md" /><br /> <p>Servicios Médicos</p></div></Link>

		</div>

	</div>
)

export default BotonesPortada;
