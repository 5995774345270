import React, { Component } from 'react';

import { connect } from 'react-redux';

import Jodit from '../../Jodit/Components/Jodit';

//import Summernote from '../../Summernote/Containers/Summernote.jsx';

import HistoriaComponent from '../Components/Historia.jsx';

class Historia extends Component {

  render(){

    let renderVal = this.props.dataUI,
        textHistoriaObj = renderVal.textos.find( text => {
          return text.id === 'tex002'
        })

    return(
      <HistoriaComponent>
        <Jodit textObj={textHistoriaObj} type='updateText' />
      </HistoriaComponent>
    )

  }


}

let mapStateToProps = (state, props) => {
  return {
    dataUI: state.dataUI
  }
}

export default connect(mapStateToProps)(Historia);
