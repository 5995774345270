import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from 'react-router-dom';

import AdminUI from '../GeneralMenus/AdminUI';

import AdminUser from '../GeneralMenus/AdminUser';

import AdminEmpresa from '../GeneralMenus/AdminEmpresa';

import Mensajeria from '../GeneralMenus/Mensajeria';

import AdminObjetivos from '../GeneralMenus/AdminObjetivos';

import AdminCompras from '../GeneralMenus/AdminCompras';

import AdminProductos from '../GeneralMenus/AdminProductos';

import toggleSideOff from '../../../../../../MyModules/toggleSideOff';

let DirectorLeftMenu =  props => {

    let arrayOfAdminUserValues = [],
        allAdminUserValuesFalse = true,
        arrayOfAdminEmpresaValues = [],
        allAdminEmpresaValuesFalse = true,
        arrayOfMensajeriaValues = [],
        allMensajeriaValuesFalse = true,
        arrayOfComprasValues = [],
        allComprasValuesFalse = true,
        arrayOfProductosValues = [],
        allProductosValuesFalse = true

    if(props.usuario.permisos !== null){
      arrayOfAdminUserValues = Object.values(props.usuario.permisos.AdminUser)
      allAdminUserValuesFalse = arrayOfAdminUserValues.every(v => v === false)
      arrayOfAdminEmpresaValues = Object.values(props.usuario.permisos.AdminEmpresa)
      allAdminEmpresaValuesFalse = arrayOfAdminEmpresaValues.every(v => v === false)
      arrayOfMensajeriaValues = Object.values(props.usuario.permisos.Mensajeria)
      allMensajeriaValuesFalse = arrayOfMensajeriaValues.every(v => v === false)
      arrayOfComprasValues =  props.usuario.permisos.Compras !== null ? Object.values(props.usuario.permisos.Compras) : []
      allComprasValuesFalse = arrayOfComprasValues.every(v => v === false)
      arrayOfProductosValues =  props.usuario.permisos.Productos !== null ? Object.values(props.usuario.permisos.Productos) : []
      allProductosValuesFalse = arrayOfProductosValues.every(v => v === false)
    }

    return(
        <ul className="navbar-nav navbar-sidenav" id="exampleAccordion" onClick={toggleSideOff}>
            <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Dashboard">
                <Link to="/dashboard" className="nav-link">
                <FontAwesomeIcon icon="tachometer-alt" />
                <span className="nav-link-text"> Dashboard</span>
                </Link>
            </li>
            {
                ((props.usuario.permisos === null) || (props.usuario.permisos.AdminUI !== true)) ? null : (
                    <AdminUI usuario={props.usuario}/>
                )
            }
            {
                ((props.usuario.permisos === null) || ((Object.entries(props.usuario.permisos.AdminUser).length > 0) !== true) || (allAdminUserValuesFalse === true)) ? null : (
                    <AdminUser usuario={props.usuario}/>
                )
            }
            {
                ((props.usuario.permisos === null) || ((Object.entries(props.usuario.permisos.AdminEmpresa).length > 0) !== true) || (allAdminEmpresaValuesFalse === true)) ? null : (
                    <AdminEmpresa usuario={props.usuario}/>
                )
            }
            {
                ((props.usuario.permisos === null) || ((arrayOfComprasValues.length > 0)!== true) || (allComprasValuesFalse === true)) ? null : (
                    <AdminCompras usuario={props.usuario}/>
                )
            }
            {
                ((props.usuario.permisos === null) || ((arrayOfProductosValues.length > 0)!== true) || (allProductosValuesFalse === true)) ? null : (
                    <AdminProductos usuario={props.usuario}/>
                )
            }
            {
                ((props.usuario.permisos === null) || ((Object.entries(props.usuario.permisos.Mensajeria).length > 0)!== true) || (allMensajeriaValuesFalse === true)) ? null : (
                <Mensajeria usuario={props.usuario}/>
                )
            }
            {
                ((props.usuario.permisos === null) || (props.usuario.permisos.AdminObjetivos !== true)) ? null : (
                    <AdminObjetivos usuario={props.usuario}/>
                )
            }
        </ul>
    )
}

export default DirectorLeftMenu