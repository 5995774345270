let consulta = (ruta) => {

  //console.log(usuarioJSON.sexo)

  const string =
    `mutation deleteRutaMensajeria {
      mensajeriaMutation {
        deleteRutaMensajeria(idRuta: "${ruta.id}")
        {
          id
          mensaje
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
