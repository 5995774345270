import React, { Component } from 'react';

import NavbarEmpresasEIndustriasComponent from '../Components/NavbarEmpresasEIndustrias.jsx';

//Importando Link de React Router
import { NavLink } from 'react-router-dom';
//import { NavHashLink } from 'react-router-hash-link';

import $ from 'jquery';

import menu from '../../../../../MyModules/menu.js';

class NavbarEmpresasEIndustrias extends Component {

	componentDidMount(){
		menu.mountMenuEmpresasEIndustrias();
	}

	toScroll(el) {
	  //console.log(el.id);
  	  $('html, body').stop().animate({
  	  	scrollTop: $(`#${el.id}`).offset().top
  	  }, 1500, 'easeInOutExpo');
  }

	render(){
		return(
			<NavbarEmpresasEIndustriasComponent id="NavbarEmpresasEIndustrias" className="navbar navbar-expand-md navbar-default">

				<NavLink className="navbar-brand" to="/" rel="external" data-ajax="false"> {/*target="_self"*/}
					<img id="brand" src="/img/Inicio/LogoBlancoConLetra.svg" alt="Logo Biolab Blanco" />
				</NavLink>

				<button type="button" className="navbar-toggler x collapsed" data-toggle="collapse" data-target="#NavbarEmpresasEIndustrias-collapse" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
					<span className="icon-bar"></span>
					<span className="icon-bar"></span>
					<span className="icon-bar"></span>
					<span className="sr-only">Toggle navigation</span>
				</button>

					<div className="navbar-header">

						<div id="icono-tipo-cliente" className="navbar-brand" href="#page-top">
							<span className="icon-icono-de-industria"></span>
							<p>Empresas <br/>
								E Industrias</p>
						</div>

					</div>

					<div className="collapse navbar-collapse sin-margen-y-padding fixed-top" id="NavbarEmpresasEIndustrias-collapse">
					  <ul id="navbar-nav-NavbarEmpresasEIndustrias" className="navbar-nav" style={{zIndex: '10'}}>
						<li className="nav-item">
							<NavLink to="/" className="page-scroll nav-link" rel="external" data-ajax="false" >Inicio</NavLink>{/*target="_self"*/}
						</li>
						{/*<li className="dropdown mr-auto" style={{'display' : 'inline-block'}}>
			  				<div id="dropdown-container" className="row justify-content-center">
								<NavLink id="dropdown-link" to="/empresas-e-indusrias" exact className="nav-link col-11 col-md-10 col-lg-8 col-xl-7" rel="external" data-ajax="false" activeStyle={{'color': '#2984be'}}>Quiénes Somos</NavLink>
								<a className="dropdown-toggle dropdown-toggle-split nav-link col-1 col-md-2" data-toggle="dropdown" role="button" aria-expanded="false" rel="external" data-ajax="false" style={{'paddingLeft' : '0px', 'textAlign' : 'center'}} href="#QuienesSomos"><span className="caret"></span></a>
								<div className="dropdown-menu col-12">
									<NavHashLink exact to="/empresas-e-indusrias/#NavbarEmpresasEIndustrias" activeClassName="active" className="nav-link dropdown-item" scroll={this.toScroll}>Nosotros</NavHashLink>
									<NavHashLink exact to="/empresas-e-indusrias/#anchor-historia" activeClassName="active" className="nav-link dropdown-item" scroll={this.toScroll}>Historia</NavHashLink>
									<NavHashLink exact to="/empresas-e-indusrias/#anchor-mision" activeClassName="active" className="nav-link dropdown-item" scroll={this.toScroll}>Misión</NavHashLink>
									<NavHashLink exact to="/empresas-e-indusrias/#anchor-vision" activeClassName="active" className="nav-link dropdown-item" scroll={this.toScroll}>Visión</NavHashLink>
									<NavHashLink exact to="/empresas-e-indusrias/#anchor-politica" activeClassName="active" className="nav-link dropdown-item" scroll={this.toScroll}>Políticas de Calidad</NavHashLink>
								</div>
			  				</div>
						</li>*/}
						<li className="nav-item">
							<NavLink className="nav-link" to="/empresas-e-indusrias/analisis-microbiologico" rel="external" data-ajax="false" activeStyle={{'color': '#2984be'}}>Análisis Microbiológico</NavLink>{/*target="_self"*/}
						</li>
						<li className="nav-item">
							<NavLink className="nav-link" to="/empresas-e-indusrias/analisis-fisico-quimico" rel="external" data-ajax="false" activeStyle={{'color': '#2984be'}}>Análisis Físico Químico</NavLink>{/*target="_self"*/}
						</li>
						<li className="nav-item">
							<NavLink className="nav-link" to="/empresas-e-indusrias/salud-ocupacional" rel="external" data-ajax="false" activeStyle={{'color': '#2984be'}}>Salud Ocupacional</NavLink>{/*target="_self"*/}
						</li>
						<li className="nav-item">
							<NavLink className="nav-link" to="/empresas-e-indusrias/servicios-medicos" rel="external" data-ajax="false" activeStyle={{'color': '#2984be'}}>Servicios Médicos</NavLink>{/*target="_self"*/}
						</li>
						<li className="nav-item">
							<NavLink className="nav-link" to="/empresas-e-indusrias/contacto" rel="external" data-ajax="false" activeStyle={{'color': '#2984be'}}>Contáctenos</NavLink>
						</li>
					  </ul>
					</div>

			</NavbarEmpresasEIndustriasComponent>
		)
	}

}

export default NavbarEmpresasEIndustrias;
