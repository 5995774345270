import React, { Component } from 'react';

//import { NavLink } from 'react-router-dom';

import { connect } from 'react-redux';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import filter from './filter.js';

class Buscador extends Component {

  constructor(props){
    super(props);
    // No llames this.setState() aquí!
    //this.state = { counter: 0 };
    this.state = {
      selectFilterUsuarioValue: null,
      selectFilterEmpresaValue: null,
      selectClasificacion: ''
    }

    this.onBuscadorChange = this.onBuscadorChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this)
  }

  onBuscadorChange(event){

    let type = event.target.dataset.type,
        value = event.target.value


    let usuarios = this.props.usuarios,
        empresas = this.props.empresas,
        selectUsuarios = document.getElementById('selectFilterUsuario') !== null ? document.getElementById('selectFilterUsuario').value : null,
        selectEmpresas = document.getElementById('selectFilterEmpresa') !== null ? document.getElementById('selectFilterEmpresa').value : null

    let data = type === 'user' ? usuarios : empresas

    let select = type === 'user' ? selectUsuarios : selectEmpresas

    let isClasificacion = false

    if(event.target.id === 'selectFilterClasificacionEmpresa'){
      this.setState({
        ...this.state,
        selectClasificacion: event.target.value
      })
      isClasificacion = true
    }

    let clasificacion = event.target.id === 'selectFilterClasificacionEmpresa' ? event.target.value : this.state.selectClasificacion

    filter(this, data, type, select, value, clasificacion, isClasificacion)

  }

  onSelectChange(event){

    const eventId = event.target.id

    switch(eventId){
      case 'selectFilterUsuario':
        this.setState({
          ...this.state,
          selectFilterUsuarioValue: event.target.value
        })
        break;
      case 'selectFilterEmpresa':
        this.setState({
          ...this.state,
          selectFilterEmpresaValue: event.target.value
        })
        break;
      default:
        this.setState({
          ...this.state
        })

    } 
  }

  render(){

    let userLocation = window.location.href.toString().split(window.location.host)[1] === '/dashboard/admin-user',
        empresaLocation = window.location.href.toString().split(window.location.host)[1] === '/dashboard/admin-company';

    let arrayOfAdminUserValues = [],
    allAdminUserValuesFalse = true,
    arrayOfAdminEmpresaValues = [],
    allAdminEmpresaValuesFalse = true

    if(this.props.usuario !== null) {

      if(this.props.usuario.permisos !== null){
        arrayOfAdminUserValues = Object.values(this.props.usuario.permisos.AdminUser)
        allAdminUserValuesFalse = arrayOfAdminUserValues.every(v => v === false)
        arrayOfAdminEmpresaValues = Object.values(this.props.usuario.permisos.AdminEmpresa)
        allAdminEmpresaValuesFalse = arrayOfAdminEmpresaValues.every(v => v === false)
      }
  
      if(this.props.usuario.permisos === null){
        return null
      } else if( ( ((this.props.usuario.permisos !== null) || ((Object.entries(this.props.usuario.permisos.AdminUser).length > 0) === true) || (allAdminUserValuesFalse === false)) || ((this.props.usuario.permisos !== null) || ((Object.entries(this.props.usuario.permisos.AdminEmpresa).length > 0) === true) || (allAdminEmpresaValuesFalse === false)) ) && ((userLocation) || (empresaLocation))){
        return(
          <li className="nav-item text-center self-align-right text-md-right">
            <form className="form-inline">
              <div className="input-group col-12">
                {
                  userLocation ? <input className="form-control" data-type="user" onChange={this.onBuscadorChange} type="text" placeholder="Buscador" /> : null
                }
                {
                  empresaLocation && (
                    <>
                          <div className="input-group-append col-xs-12 mx-auto">
                            <label className="input-group-text" htmlFor="selectFilterClasificacionEmpresa">Clasificación de Empresa:</label>
                          </div> 
                          <select onChange={this.onBuscadorChange} className="custom-select col-xs-12 mx-auto" id="selectFilterClasificacionEmpresa" defaultValue="Ninguno" data-type="company">
                            <option value="">Ninguno</option>
                            <option value="Proveedor">Proveedor</option>
                            <option value="Cliente">Cliente</option>
                          </select>
                          <input className="form-control" data-type="company" onChange={this.onBuscadorChange}  type="text" placeholder="Buscador" />
                    </>
                  )
                }
                {
                  userLocation ? (
                    <div className="input-group">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="input-group-append col-xs-12 mx-auto">
                            <label className="input-group-text" htmlFor="selectFilterUsuario">Filtrar por:</label>
                          </div>
                          <select onChange={this.onSelectChange} className="custom-select col-xs-12 mx-auto" id="selectFilterUsuario" defaultValue="Nombre">
                            <option value="Nombre">Nombre</option>
                            <option value="Apellidos">Apellidos</option>
                            <option value="Usuario">Usuario</option>
                            <option value="Correo">Correo</option>
                            <option value="Tipo">Tipo</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  ) : null
                }
                {
                  empresaLocation && (
                    <div className="input-group">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="input-group-append col-xs-12 mx-auto">
                            <label className="input-group-text" htmlFor="selectFilterEmpresa">Filtrar por:</label>
                          </div>
                          <select onChange={this.onSelectChange} className="custom-select col-xs-12 mx-auto" id="selectFilterEmpresa" defaultValue="Nombre Comercial">
                            <option value="Nombre Comercial">Nombre Comercial</option>
                            <option value="Nombre Razon Social">Nombre Razón Social</option>
                            <option value="Correo">Correo</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </form>
          </li>
        )
      }else{
        return null
      }

    }else{
      return null
    }

  }

}

export default connect()(Buscador)
