import React, { Component } from 'react';

import ModalB4 from 'react-bootstrap4-modal';

import { connect } from 'react-redux';

import Summernote from '../../Summernote/Containers/Summernote.jsx';

import ModalContainer from '../Components/Modal.jsx';

class Modal extends Component {

  constructor(props){
		super(props)

    // This binding is necessary to make `this` work in the callback
    this.handleCloseModal = this.handleCloseModal.bind(this);
	}

  handleCloseModal(){
    this.props.dispatch({
		//props.dispatch({
			type: `CLOSE_MODAL`
		})
  }

  render() {
    let modal = this.props.Modal.modal,
        modalVisible = this.props.Modal.visible

        if(modal === null){
          return null
        }else if(modal.titulo === 'Análisis de paternidad'){
          return(
            <ModalContainer>
              <ModalB4 visible={modalVisible} fade={true} className="modal-type2" >{/*id={`Modal-${modal.id}`}*/}
          		  	<div className="modal-dialog" role="document">
            				<div className="modal-content">
            				  <div className="modal-header">
                      <img className="brand-modal" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco" />
            					<button type="button" className="close cerrar-cruz" onClick={this.handleCloseModal} aria-label="Close">
            						<div></div>
            						<div></div>
            					</button>
            				  </div>

            					<div className="modal-body sin-margen-y-padding container-fluid">
                        <h3 id={`Modal-titulo-${modal.id}`} className="col-12">{modal.titulo}</h3>
                        <Summernote itemID={`#Modal-titulo-${modal.id}`} textObj={modal} texto={modal.titulo} url='http://localhost:8080/updateModalTitulo'></Summernote>
                          <div className="col-10 mx-auto"><img src={modal.imagen} className="img-fluid" alt={modal.titulo} /></div>
              						<div className="modal-analisis">
                            <div id={`Modal-text-${modal.id}`}/>
                            <Summernote itemID={`#Modal-text-${modal.id}`} textObj={modal} texto={modal.texto} url='http://localhost:8080/updateModalText'/>
                            <div id={`Modal-table-${modal.id}`}/>
                          </div>
                          <div className="mensaje mx-auto" style={{marginRight: 'margin-right: 0px'}}>
                            <a className="col-12" href="contacto.html" rel="external" data-ajax="false">
                              <h3 className="col-12 text-center"><span className="icon-light-bulb"></span> PARA MÁS INFORMACIÓN, CONTÁCTENOS</h3><br/>
                            </a>
                          </div>
            					</div>
            				  <div className="modal-footer">
            					  <button type="button" className="btn btn-default mx-auto" onClick={this.handleCloseModal} >Cerrar</button>
            				  </div>
            				</div>
          		  	</div>
              </ModalB4>
            </ModalContainer>
          )
        }else{
          return(
            <ModalContainer>
              <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
        		  	<div className="modal-dialog" role="document">
          				<div className="modal-content">
          				  <div className="modal-header">
                    <img className="brand-modal" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco" />
          					<button type="button" className="close cerrar-cruz" onClick={this.handleCloseModal} aria-label="Close">
          						<div></div>
          						<div></div>
          					</button>
          				  </div>

          					<div className="modal-body sin-margen-y-padding container-fluid">
                      <h3 id={`Modal-titulo-${modal.id}`} className="col-12">{modal.titulo}</h3>
                      <Summernote itemID={`#Modal-titulo-${modal.id}`} textObj={modal} texto={modal.titulo} url='http://localhost:8080/updateModalTitulo'></Summernote>
              					<div className="col-10 mx-auto"><img src={modal.imagen} className="img-fluid" alt={modal.titulo} /></div>
            						<div className="modal-analisis">
                          <div id={`Modal-text-${modal.id}`}/>
                          <Summernote itemID={`#Modal-text-${modal.id}`} textObj={modal} texto={modal.texto} url='http://localhost:8080/updateModalText'/>
                          <div id={`Modal-table-${modal.id}`}/>
                        </div>
                        <div className="mensaje mx-auto" style={{marginRight: 'margin-right: 0px'}}>
                          <a className="col-12" href="contacto.html" rel="external" data-ajax="false">
                            <h3 className="col-12 text-center"><span className="icon-light-bulb"></span> PARA MÁS INFORMACIÓN, CONTÁCTENOS</h3><br/>
                          </a>
                        </div>
          					</div>
          				  <div className="modal-footer">
          					  <button type="button" className="btn btn-default mx-auto" onClick={this.handleCloseModal}>Cerrar</button>
          				  </div>
          				</div>
        		  	</div>
            </ModalB4>
          </ModalContainer>
          )
        }
  }
}

let mapStateToProps = (state, props) => {
  return {
		conexion: state.logIn.conexion,
    usuario: state.logIn.usuario,
    Modal: state.Modal
  }
}

export default connect(mapStateToProps)(Modal);
