import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let TableAgregarOrden = props => {

    let solicitudesMensajeria;

    if((props.mensajeroFilter === null) || (props.mensajeroFilter.value === null)){
      solicitudesMensajeria = props.solicitudesMensajeria.filter( solicitud => {
        return parseInt(solicitud.fechaDeRecoleccion) >= props.fechaOrdenes.ordenesDesde &&
              parseInt(solicitud.fechaDeRecoleccion) <= props.fechaOrdenes.ordenesHasta
      });
    }else{

      let solicitudesMensajeriaSinFilterMensajeria = props.solicitudesMensajeria.filter( solicitud => {
        return parseInt(solicitud.fechaDeRecoleccion) >= props.fechaOrdenes.ordenesDesde &&
              parseInt(solicitud.fechaDeRecoleccion) <= props.fechaOrdenes.ordenesHasta
      });

      solicitudesMensajeria = solicitudesMensajeriaSinFilterMensajeria.filter( solicitud => {
        return solicitud.idMensajero === props.mensajeroFilter.value.id
      })

    }

    /*let ordenesMensajero = props.solicitudesMensajeria.filter( Solicitud => {
      return Solicitud.idMensajero === props.usuario.id
    })*/

    /*let ordenesMensajero = solicitudesMensajeria.filter( Solicitud => {
      return Solicitud.idMensajero === props.usuario.id
    })*/

    return(
        <table className="table text-center table-bordered table-hover table-sm">
          <thead className="thead-light">
            <tr>
                <th scope="col" className="d-none d-md-table-cell" >Id de Orden</th>
                <th scope="col">Nombre Empresa o Usuario</th>
                <th scope="col" className="d-none d-sm-table-cell" >Instrucciones</th>
                <th scope="col" className="d-none d-sm-table-cell" >Fecha</th>
                <th scope="col">Estado</th>
                <th scope="col">Acciones</th>
            </tr>
            </thead>
            <tbody>
            {
              solicitudesMensajeria.map(solicitudMensajeria => {
                
                let fechaDeRecoleccion = new Date(parseInt(solicitudMensajeria.fechaDeRecoleccion))

                //Creando la fecha
                let dia
                (fechaDeRecoleccion.getDate()) < 10 ? dia = `0${fechaDeRecoleccion.getDate()}` : dia = `${fechaDeRecoleccion.getDate()}`
                let mes
                (fechaDeRecoleccion.getMonth() + 1) < 10 ? mes = `0${fechaDeRecoleccion.getMonth() + 1}` : mes = `${fechaDeRecoleccion.getMonth() + 1}`
                let year = fechaDeRecoleccion.getFullYear()
                //Fecha
                let fecha = `${year}-${mes}-${dia}`
                //Creando Hora
                let hora
                (fechaDeRecoleccion.getHours()) < 10 ? hora = `0${fechaDeRecoleccion.getHours()}` : hora = `${fechaDeRecoleccion.getHours()}`
                let minutos
                (fechaDeRecoleccion.getMinutes()) < 10 ? minutos = `0${fechaDeRecoleccion.getMinutes()}` : minutos = `${fechaDeRecoleccion.getMinutes()}`
                //Hora
                let time = `${hora}:${minutos}`

                return (
                  <tr key={solicitudMensajeria.id}>
                    {/*<th scope="row">{usuario.nombre}</th>*/}
                    <td className="d-none d-md-table-cell">{solicitudMensajeria.id}</td>
                    {
                      (solicitudMensajeria.idUsuario !== null) ? (
                        <td>
                          {
                            props.usuarios.map(usuario => {
                              return ((usuario.id === solicitudMensajeria.idUsuario) === true) ? `${usuario.nombre} ${usuario.apellidos}`: null
                            })
                          }
                        </td>
                      ) : null
                    }
                    {
                      (solicitudMensajeria.idEmpresa !== null) ? (
                        <td>
                          {
                            props.empresas.map(empresa => {
                              return ((empresa.id === solicitudMensajeria.idEmpresa) === true) ? `${empresa.nombre_comercial}`: null
                            })
                          }
                        </td>
                      ) : null
                    }
                    <td className="d-none d-sm-table-cell" >{solicitudMensajeria.instrucciones}</td>
                    <td className="d-none d-sm-table-cell" >{fecha} a {time} horas</td>
                    <td>{solicitudMensajeria.estado}</td>
                    <td>

                    <button type="button" onClick={() => {
                          let typeModal = 'ordenMensariaModalView';
                          props.handleOpenModal(solicitudMensajeria, typeModal)
                      }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#2984be'}}>
                        <FontAwesomeIcon icon="eye" />
                    </button> {/*ref={props.setButtonViewUser}*/}

                    {
                      (((props.usuario.tipo === 'colaborador') && (props.usuario.colaborador.puesto === 'Mensajero')) || (props.usuario.tipo === 'cliente')) ? null : (
                        <button type="button" onClick={() => {
                            props.handleRemoveOrdenesRuta(solicitudMensajeria)
                        }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#2984be'}}>
                          <FontAwesomeIcon icon="minus-circle" />
                        </button>
                      )
                    }


                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
    )
}

export default TableAgregarOrden