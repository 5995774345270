import React, { Component } from 'react';

import DashboardComponent from '../../../../../dashboard/components/DashboardComponent.jsx';

//import Select from 'react-select';

import GraphQL from './GetAllRutas/FetchGraphQLMensajeria.js';

import GraphQLOrdenesMensajeria from '../../../../../../../apiService/FetchGraphQLMensajeria.js';

import TableVerRutas from '../components/TableVerRutas.jsx';

import GraphQLUsuarios from '../../../../../../../apiService/FetchGraphQLUsuarios.js';

import GraphQLEmpresas from '../../../../../../../apiService/FetchGraphQLEmpresas.js';

import GraphQLEditRutas from '../../../Modals/RutaEditFetch/fetchGraphQL.js';

import deleteRutaFetch from './DeleteRutaFetch/FetchDeleteRuta.js'

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

class VerRutas extends Component {

    constructor(props) {
		super(props);
		
		this.state = {
			rutasDesde: "",
			rutasHasta: ""
        }
        
		this.setDate = this.setDate.bind(this);
		this.handleOpenModal = this.handleOpenModal.bind(this);
		this.handleDeleteRuta = this.handleDeleteRuta.bind(this);
		this.handleFinalizarRuta = this.handleFinalizarRuta.bind(this);

	}
	
	handleDeleteRuta(ruta){
		return deleteRutaFetch(this, ruta)
	}

    componentDidMount(){

        let date = new Date()

		let dia 
		(date.getDate()) < 10 ? dia = `0${date.getDate()}` : dia = `${date.getDate()}`
		let mes
        (date.getMonth() + 1) < 10 ? mes = `0${date.getMonth() + 1}` : mes = `${date.getMonth() + 1}`
		let year = date.getFullYear()
		//Fecha
		let fecha = `${year}-${mes}-${dia}`

		this.setState({
			rutasDesde: fecha,
			rutasHasta: fecha
		})

        let arrayOfMensajeriaValues = [],
        allMensajeriaValuesFalse = true

        if(this.props.usuario !== null){
            if(this.props.usuario.permisos !== null){
                arrayOfMensajeriaValues = Object.values(this.props.usuario.permisos.Mensajeria)
                allMensajeriaValuesFalse = arrayOfMensajeriaValues.every(v => v === false)
            }
		}

		if( (this.props.conexion !== false) && (this.props.usuario.permisos !== null) && ((Object.entries(this.props.usuario.permisos.Mensajeria).length > 0) === true) && (allMensajeriaValuesFalse === false) && ( (this.props.usuario.permisos.Mensajeria.ViewRuta === true) || (this.props.usuario.permisos.Mensajeria.EditRuta === true) || (this.props.usuario.permisos.Mensajeria.DeleteRuta === true) || (this.props.usuario.permisos.Mensajeria.CheckListRuta === true) ) ){

			GraphQLUsuarios(this);

			GraphQLEmpresas(this);

			let fechaToTimeStamp = new Date(fecha.replace(/-/g, '/')),
					fechaDesdeTimestampString = fechaToTimeStamp.setHours(0,0,0,0),
					fechaHastaTimestampString = fechaToTimeStamp.setHours(23,59,59,0);
					
					let fechaDesdeString = new Date(parseInt(fechaDesdeTimestampString)),
						fechaHastaString = new Date(parseInt(fechaHastaTimestampString))
							
					//console.log(fechaDesdeString)
					//console.log(fechaHastaString)
			
			GraphQL(this, fechaDesdeString, fechaHastaString);

			GraphQLOrdenesMensajeria(this, fechaDesdeString, fechaHastaString);

		}

    }

    setDate(event, type) {
		
		let fechaToTimeStampDesde = new Date(this.state.rutasDesde.replace(/-/g, '/')),
				fechaToTimeStampHasta = new Date(this.state.rutasHasta.replace(/-/g, '/'))

		if(type === "rutasDesde"){
			this.setState({
				rutasDesde: event.target.value
			})
			fechaToTimeStampDesde = new Date(event.target.value.replace(/-/g, '/'))
		}else if(type === "rutasHasta"){
			this.setState({
				rutasHasta: event.target.value
			})
			fechaToTimeStampHasta = new Date(event.target.value.replace(/-/g, '/'))
		}

		let fechaDesdeTimestampString = fechaToTimeStampDesde.setHours(0,0,0,0),
				fechaHastaTimestampString = fechaToTimeStampHasta.setHours(23,59,59,0);
				
				let fechaDesdeString = new Date(parseInt(fechaDesdeTimestampString)),
						fechaHastaString = new Date(parseInt(fechaHastaTimestampString))
						
				//console.log(fechaDesdeString)
				//console.log(fechaHastaString)
		
		GraphQL(this, fechaDesdeString, fechaHastaString);

		GraphQLOrdenesMensajeria(this, fechaDesdeString, fechaHastaString);

	}

	handleOpenModal(modal, typeModal){
        this.props.dispatch({
            type: `OPEN_MODAL`,
            payload: {
                modal,
                typeModal
            }
        })
	}
	
	handleFinalizarRuta(rutaMensajeria){

		let ordenes = this.props.solicitudesMensajeria.filter( orden => { 
			return rutaMensajeria.ordenesRuta.includes(orden.id)
		})

		if(ordenes.length !== 0){
	
			let ordenesFinalizadas = ordenes.filter( orden => {
				return orden.estado === 'Finalizado'
			})

			let ordenesCanceladas = ordenes.filter( orden => {
				return orden.estado === 'Cancelado'
			})
	
			if(ordenes.length === (ordenesFinalizadas.length + ordenesCanceladas.length)){

				let ruta = {
					...rutaMensajeria
				}

				ruta.estado = "Finalizado"

				GraphQLEditRutas(this, ruta)

			}

		}


	}

	render(){

	let arrayOfMensajeriaValues = [],
	allMensajeriaValuesFalse = true

	if(this.props.usuario !== null){
		if(this.props.usuario.permisos !== null){
			arrayOfMensajeriaValues = Object.values(this.props.usuario.permisos.Mensajeria)
			allMensajeriaValuesFalse = arrayOfMensajeriaValues.every(v => v === false)
		}
	}



	if (this.props.conexion === false) {
		return <Redirect to='/login' />
	}else if(this.props.usuario.permisos === null){
		return(
			<h1>No te Han Asignado Permisos</h1>
		)
	}else if( (this.props.usuario.permisos === null) || ((Object.entries(this.props.usuario.permisos.Mensajeria).length > 0) !== true) || (allMensajeriaValuesFalse === true) ){
		return <Redirect to='/dashboard' />
	}else if((this.props.usuario.permisos.Mensajeria.ViewRuta === true) || (this.props.usuario.permisos.Mensajeria.EditRuta === true) || (this.props.usuario.permisos.Mensajeria.DeleteRuta === true) || (this.props.usuario.permisos.Mensajeria.CheckListRuta === true)){
        return(
            <DashboardComponent>
                <div className="container-fluid" style={{minHeight: '78vh'}}>
                {
				    ( ((this.props.usuario.tipo === 'colaborador') && (this.props.usuario.colaborador.puesto === 'Mensajero')) ) ? null : (
					    <form className="row" style={{marginBottom: '25px'}}>
						    <div className="col">
							    <label htmlFor="rutasDesde">Rutas desde:</label>
							    <input id="rutasDesde" name="rutasDesde" className="col" type="date" value={this.state.rutasDesde} 
										onChange={(event) => {
											let typeDate = 'rutasDesde';
											this.setDate(event,typeDate)
										}} />
						    </div>
						    <div className="col">
							    <label htmlFor="rutasHasta">Rutas hasta:</label>
							    <input id="rutasHasta" name="rutasHasta" className="col" type="date" value={this.state.rutasHasta} 
										onChange={(event) => {
											let typeDate = 'rutasHasta';
											this.setDate(event,typeDate)
										}} />
						    </div>
						    {/*<div className="col-12" style={{marginTop: '25px'}}>
							    <div className="row">
								    <label htmlFor="react-select-3-input" className="col-lg-6 col-12"><b>Selecciona un Mensajero</b></label>
								    <Select
													className="col-lg-6 col-12"
													placeholder="Escoje un Mensajero"
													value={this.state.selectedOptionMensajeros}
													onChange={this.handleChangeMensajero}
													options={SelectMensajero}
											/>
							    </div>
						    </div>*/}


					    </form>
                        )
                    }
                    <TableVerRutas usuario={this.props.usuario} usuarios={this.props.usuarios} rutasMensajeria={this.props.rutasMensajeria} handleOpenModal={this.handleOpenModal} handleDeleteRuta={this.handleDeleteRuta} fechaRutas={this.state} handleFinalizarRuta={this.handleFinalizarRuta}/>
                </div>
            </DashboardComponent>
			)
		}else{
			return <Redirect to='/dashboard' />
		}
	}
	
}

let mapStateToProps = (state, props) => {
    return {
      conexion: state.logIn.conexion,
	  usuario: state.logIn.usuario,
	  solicitudesMensajeria: state.dataMensajeria.solicitudesMensajeria,
      rutasMensajeria: state.dataMensajeria.rutasMensajeria,
      usuarios: state.dataUsers.usuarios,
      empresas: state.dataEmpresas.empresas
    }
}
  
export default connect(mapStateToProps)(VerRutas)