// JavaScript Document
import GraphQL from './ConsultaGraphQLUpdateSatePassPago';

import url from '../../../../../apiService/ApiUrl.js';

function handleError(err){
	console.log(`Request failed: ${err}`);
}

const fetchGraphQLUpdateStatePassPago = (This, data, state) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(data)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		if(This.setState !== undefined){
			This.setState({
				ordenCompra: {
					...This.state.ordenCompra,
					passPago: {
						...This.state.ordenCompra.passPago,
						estado: res.data.ordenesCompraMutation.updateStatePassPago.estado
					}
				},
				loading: false
			})
		}else{
			This({
				...state,
				ordenCompra: {
					...state.ordenCompra,
					passPago: {
						...state.ordenCompra.passPago,
						estado: res.data.ordenesCompraMutation.updateStatePassPago.estado
					}
				},
				loading: false
			})
		}


	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchGraphQLUpdateStatePassPago;
