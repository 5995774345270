import logIn from './logIn';
import Modal from './Modal';
import dataUI from './dataUI';
import dataUsers from './dataUsers';
import dataEmpresas from './dataEmpresas';
import dataMensajeria from './dataMensajeria';
import dataAdminObjetivos from './dataAdminObjetivos';
import dataOrdenesCompra from './dataOrdenesCompra';
import suscribeGraphQL from './suscribeGraphQL';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  logIn,
  Modal,
  dataUI,
  dataUsers,
  dataEmpresas,
  dataMensajeria,
  dataAdminObjetivos,
  dataOrdenesCompra,
  suscribeGraphQL
})

export default rootReducer
