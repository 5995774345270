import GraphQL from './ConsultaGraphQL';

import url from '../../../../../../apiService/ApiUrl.js';

// JavaScript Document
function handleError(err, This){
	console.log(`Request failed: ${err}`);
	This.setState({
		loading: false,
		insertSucursalMensaje: {
			response: 'failure',
			mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
		}
	})
}

const insertSucursal = (This, sucursalJSON) => {
	//console.log(usuarioJSON)
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(sucursalJSON)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		//console.log(res.data.insertSucursal)

		This.setState({
			loading: false,
			insertSucursalMensaje: {
				response: 'success',
				mensaje: res.data.empresaMutation.insertSucursal.mensaje
			}
		})

		This.props.dispatch({
			type: 'ADD_SUCURSAL_TO_SUCURSALES',
			payload: {
				dataSucursal: res.data.empresaMutation.insertSucursal.sucursal
			}
		})
		return res

	})
	.catch(err => handleError(err, This));

	//termina fetch
}

export default insertSucursal;
