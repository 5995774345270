import GraphQL from './ConsultaGraphQL';

import url from '../../../../../../apiService/ApiUrl.js';

// JavaScript Document
function handleError(err, This){
	console.log(`Request failed: ${err}`);
	This.setState({
		insertUserMensaje: {
			response: 'failure',
			mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
		}
	})
}

const updateUser = (This, usuarioJSON) => {
	//console.log(usuarioJSON)
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(usuarioJSON)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		This.setState({
			insertUserMensaje: {
				response: 'success',
				mensaje: res.data.userMutation.insertUser.mensaje
			}
		})

		This.props.dispatch({
			type: 'ADD_USER_TO_USERS',
			payload: {
				dataUser: res.data.userMutation.insertUser.usuario
			}
		})
		
	})
	.catch(err => handleError(err, This));

	//termina fetch
}

export default updateUser;
