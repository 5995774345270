let consulta = (ordenJSON) => {

  //console.log(empresaJSON)
  let fechaDeRecoleccion =  new Date(parseInt(ordenJSON.fechaDeRecoleccion)),
      fechaDeRecoleccionString = fechaDeRecoleccion.toString()

  const string =
    `mutation updateSolicitud {
      mensajeriaMutation {
        updateSolisitudDeAnalisis(updateSolisitudDeAnalisis: {
          id: "${ordenJSON.id}",
          idUsuario: ${ordenJSON.idUsuario},
          idEmpresa: ${ordenJSON.idEmpresa},
          idMensajero: "${ordenJSON.idMensajero}",
          instrucciones: "${ordenJSON.instrucciones}",
          cantidadDeMuestras: ${ordenJSON.cantidadDeMuestras},
          personaQueLlamo: "${ordenJSON.personaQueLlamo}",
          telefono: "${ordenJSON.telefono}",
          direccionDeRecepcion: "${ordenJSON.direccionDeRecepcion}",
          fechaDeRecoleccion: "${fechaDeRecoleccionString}",
          tipo: "${ordenJSON.tipo}",
          estado: "${ordenJSON.estado}",
          urgente: ${ordenJSON.urgente}
        })
        {
          id
          mensaje
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
