import React from 'react';

import './css/Miembros.css';

const Miembros = (props) => (
	<section id="seccion-miembros-inicio" className="container-fluid sin-margen-y-padding">
		{props.children}
	</section>
)

export default Miembros;
