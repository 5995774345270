import React, { useState } from 'react';

import DashboardComponent from '../../../dashboard/components/DashboardComponent.jsx';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

import QRScanner from '../../../../Components/QRScanner';

let DashboardQR = props => {

  const [idPassPago, setIDPassPago] = useState(null)

  let handleButtonClick = (idPassPago) => {

    setIDPassPago(idPassPago)

  }

  let arrayOfComprasValues = [],
        allComprasValuesFalse = true

    if(props.usuario !== null){
        if(props.usuario.permisos !== null){
            arrayOfComprasValues = Object.values(props.usuario.permisos.Compras)
            allComprasValuesFalse = arrayOfComprasValues.every(v => v === false)
        }
    }

    if (props.conexion === false) {
        return <Redirect to='/login' />
    }else if(props.usuario.permisos === null){
        return(
            <h1>No te Han Asignado Permisos</h1>
        )
    }else if( (props.usuario.permisos === null) || ((Object.entries(props.usuario.permisos.Compras).length > 0) !== true) || (allComprasValuesFalse === true) || (props.usuario.permisos.Compras.CreateOrdenCompra === false) ) {
        return <Redirect to='/dashboard' />
    }else if(idPassPago !== null){
      return <Redirect to={`/dashboard/admin-compras/pass-pago/${idPassPago}`} />
    }else if(props.usuario.permisos.Compras.CreateOrdenCompra === true){

      return(
        <DashboardComponent>
          <QRScanner continuouslyScann={true} tipo={"Contraseña de Pago"} handleButtonClick={handleButtonClick} handleClickText="Buscar Contraseña de Pago " />
        </DashboardComponent>
      )

    }
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario
  }
}

export default connect(mapStateToProps)(DashboardQR)