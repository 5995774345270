import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loader from '../../../../../Components/SimpleLoader';

import Select from 'react-select';

import { connect } from 'react-redux';

import GraphQLUsuarios from '../../../../../../apiService/FetchGraphQLUsuarios';

import FetchGraphQLInsertSucursal from '../NewSucursalFetch/FetchNewSucursalPromise';

class InsertSucursal extends Component {

    constructor(props) {

        super(props);
    
        this.state = {
          loading: false,
          selectedOptionUsuarios: null,
          insertSucursalMensaje: null,
          //selectedOptionEmpresas: null,
          dataSucursal: {
              nombreComercial: "",
              telefonos: "",
              correo: "",
              direccion: "",
              convenio: "",
              FK_empresa: this.props.empresa.id,
              FK_contacto_directo_usuario: ""
          }
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onInputChangeSelectUsuarios = this.onInputChangeSelectUsuarios.bind(this);
        this.onSaveSucursal = this.onSaveSucursal.bind(this);
        //this.onInputChangeSelectEmpresas = this.onInputChangeSelectEmpresas.bind(this);
    
    }

    componentDidMount(){
        GraphQLUsuarios(this)
    }

    handleInputChange(event, type){
        if(type === "nombreComercial"){
            this.setState({
                dataSucursal: {
                    ...this.state.dataSucursal,
                    nombreComercial: event.target.value
                }
            })
        }else if(type === "telefonos"){
            this.setState({
                dataSucursal: {
                    ...this.state.dataSucursal,
                    telefonos: event.target.value
                }
            })
        }else if(type === "correo"){
            this.setState({
                dataSucursal: {
                    ...this.state.dataSucursal,
                    correo: event.target.value
                }
            })
        }else if(type === "direccion"){
            this.setState({
                dataSucursal: {
                    ...this.state.dataSucursal,
                    direccion: event.target.value
                }
            })
        }else if(type === "convenio"){
            this.setState({
                dataSucursal: {
                    ...this.state.dataSucursal,
                    convenio: event.target.value
                }
            })
        }
    }

    onInputChangeSelectUsuarios(selectedOptionUsuarios){
    
        this.setState({
          selectedOptionUsuarios,
          dataSucursal: {
              ...this.state.dataSucursal,
              FK_contacto_directo_usuario: selectedOptionUsuarios === null ? "" : selectedOptionUsuarios.value.id
          }
        });
    
    }

    onSaveSucursal(e){
        e.preventDefault()
        this.setState({
            loading: true
        })
        FetchGraphQLInsertSucursal(this, this.state.dataSucursal)
    }

    /*onInputChangeSelectEmpresas(selectedOptionEmpresas){
    
        this.setState({
          selectedOptionEmpresas,
          dataSucursal: {
              ...this.state.dataSucursal,
              FK_Empresa: selectedOptionEmpresas === null ? "" : selectedOptionEmpresas.value.id
          }
        });
    
    }*/

    render(){

        let usuariosEmpresa = this.props.usuarios.filter( usuario => usuario.empresa_asociada.id === this.props.empresa.id)

        let SelectUsuarios = Array.from(usuariosEmpresa, usuario => {
            return { value: usuario, label: `${usuario.nombre} ${usuario.apellidos}` }
        })/*,
        SelectEmpresas = Array.from(this.props.empresas, empresa => {
            return { value: empresa, label: `${empresa.nombre_comercial}` }
        })*/

        return(
            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <button style={{backgroundColor: 'transparent', border: 'none', color: '#2984be'}} onClick= { () => {
                            let type = "EditDataEmpresa"
                            this.props.handleChangeDisplay(type)
                        } }><FontAwesomeIcon icon="arrow-left" style={{fontSize: '25px', marginTop: '25px', marginLeft: '25px'}} /></button>
                    </div>
                </div>

                <div className="row text-center">
                    <div className="col-12">
                        <FontAwesomeIcon icon="building" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                        <h1>Insertar una nueva Sucursal</h1>
                    </div>
                </div>

                <form className="row text-center" onSubmit={this.onSaveSucursal}>

                    <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                        <label htmlFor="nombreComercial">Nombre Comercial: </label>
                        <input value={this.state.dataSucursal.nombreComercial} type="text" className="form-control" id="nombreComercial" aria-describedby="nombreComercial" placeholder="Ingresa Nombre Comercial" onChange={(event)=>{
                            let type = "nombreComercial";
                            this.handleInputChange(event, type)
                        }} />
                        <small id="nombreComercialSmall" className="form-text text-muted">Nombre Comercial.</small>
                    </div>

                    <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                        <label htmlFor="telefonos">Teléfonos: </label>
                        <input value={this.state.dataSucursal.telefonos} type="text" className="form-control" id="telefonos" aria-describedby="telefonos" placeholder="Ingresa Los Teléfonos" onChange={(event)=>{
                            let type = "telefonos";
                            this.handleInputChange(event, type)
                        }} />
                        <small id="telefonosSmall" className="form-text text-muted">Teléfonos.</small>
                    </div>

                    <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                        <label htmlFor="correo">Correo: </label>
                        <input value={this.state.dataSucursal.correo} type="email" className="form-control" id="correo" aria-describedby="correo" placeholder="Ingresa el Correo" onChange={(event)=>{
                            let type = "correo";
                            this.handleInputChange(event, type)
                        }} />
                        <small id="correoSmall" className="form-text text-muted">Correo.</small>
                    </div>

                    <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                        <label htmlFor="direccion">Dirección: </label>
                        <input value={this.state.dataSucursal.direccion} type="text" className="form-control" id="direccion" aria-describedby="direccion" placeholder="Ingresa la Dirección" onChange={(event)=>{
                            let type = "direccion";
                            this.handleInputChange(event, type)
                        }} />
                        <small id="direccionSmall" className="form-text text-muted">Dirección.</small>
                    </div>

                    <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                        <label htmlFor="convenio">Convenio: </label>
                        <input value={this.state.dataSucursal.convenio} type="text" className="form-control" id="convenio" aria-describedby="convenio" placeholder="Ingresa el Convenio" onChange={(event)=>{
                            let type = "convenio";
                            this.handleInputChange(event, type)
                        }} />
                        <small id="convenioSmall" className="form-text text-muted">Convenio.</small>
                    </div>

                    {/*<div className="form-group col-12 col-md-6 col-lg-4 mx-auto" style={{marginTop: '25px'}}>
                          <Select
                            id="SelectEmpresas"
                            className="col-12"
                            placeholder="Selecciona una Empresa"
                            isMulti={false}
                            isClearable
                            value={this.state.selectedOptionEmpresas}
                            onChange={this.onInputChangeSelectEmpresas}
                            options={SelectEmpresas}
                          />
                    </div>*/}

                    <div className="form-group col-12 col-md-6 col-lg-4 mx-auto" style={{marginTop: '25px'}}>
                          <Select
                            id="selectUsuarios"
                            className="col-12"
                            placeholder="Selecciona un Contacto Directo"
                            isMulti={false}
                            isClearable
                            value={this.state.selectedOptionUsuarios}
                            onChange={this.onInputChangeSelectUsuarios}
                            options={SelectUsuarios}
                          />
                    </div>

                    <div className="col-12">
                        <button type="submit" className="btn btn-default mx-auto"><FontAwesomeIcon icon="save" style={{'fontSize': '150%', 'color': '#2984be'}} /></button>
                    </div>


                </form>

                {
                    this.state.loading === true ? (
                        <div className="row" style={{marginTop: '25px'}}>
                            <Loader />
                        </div>
                    ) : null
                }

                {
                    this.state.insertSucursalMensaje !== null ? (
                      <div className="row" style={{marginTop: '25px'}}>
                        {
                          (this.state.insertSucursalMensaje.response === 'success') ? (
                            <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.insertSucursalMensaje.mensaje}</div>
                          ) : (
                            <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.insertSucursalMensaje.mensaje}</div>
                          )
                        }
                      </div>
                    ) : null
                }

            </div>
        )
    }
}

let mapStateToProps = (state, props) => {
    return {
      usuarios: state.dataUsers.usuarios,
      empresas: state.dataEmpresas.empresas
    }
}
  
export default connect(mapStateToProps)(InsertSucursal)