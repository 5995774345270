import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FetchCreateTipoCliente from '../Containers/FetchCreateTipoCliente/FetchGraphQLCreateTipoCliente.js';

import DashboardComponent from '../../../dashboard/components/DashboardComponent.jsx';

let CreateTipoCliente = props => {

    const [state, setState] = useState({
        nombre: "",
        insertTipoClienteMensaje: null
    });

    let handleInputChange = (event) => {

        event.preventDefault()

        setState({
            ...state,
            [event.target.id]: event.target.value
        })

    }

    let handleSubmit = (event) => {

        event.preventDefault()

        let tipoCliente = {
            nombre: state.nombre
        }

        FetchCreateTipoCliente(setState, state, tipoCliente)

    }


    return(
        <DashboardComponent>
            <div className="row mx-5">
                <div className="col-12">
                    <h1 className="text-center">Crear Tipo de Cliente</h1>
                </div>
                <div className="col-12 d-none-print">
                    <Link to="/dashboard/productos/tipo-clientes">
                        <button style={{backgroundColor: 'transparent', border: 'none', color: '#2984be'}}><FontAwesomeIcon icon="arrow-left" style={{fontSize: '25px'}} /></button>
                    </Link>
                </div>
                <form className="mx-auto" onSubmit={handleSubmit}>
                    <div className="mx-auto text-center">
                        <div className="form-group">
                            <label htmlFor="nombre"><b>Nombre:</b></label>
                            <input type="text" value={state.nombre} id="nombre" className="form-control" onChange={handleInputChange} required />
                        </div>
                    </div>

                        <div className="mx-auto text-center mb-4">
                            <button className="btn btn-outline-info" type="submit">Crear Tipo Cliente</button>
                        </div>

                    {
                        state.insertTipoClienteMensaje !== null ? (
                            <div className="row">
                                {
                                    (state.insertTipoClienteMensaje.response === 'success') ? (
                                        <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{state.insertTipoClienteMensaje.mensaje}</div>
                                    ) : (
                                        <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{state.insertTipoClienteMensaje.mensaje}</div>
                                    )
                                }
                            </div>
                        ) : null
                    }
                </form>
            </div>
        </DashboardComponent>
    )
}

export default CreateTipoCliente