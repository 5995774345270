let consulta = (idTipoCliente) => {

  let string = `{
    appInfo{
      productos{
        tipoCliente( id: "${idTipoCliente}"){
          id
          nombre
        }
      }
    }
  }`

  /*return {
    "query": string
  }*/

  return string

}

export default consulta;
