let consulta = (data) => {

  let checkList = JSON.stringify(data.checkList).replace(/{"/g, '{').replace(/":/g, ':').replace(/,"/g, ',')

  const string =
    `mutation insertCheckListOrdenMensajeria {
      mensajeriaMutation {
        insertCheckListOrdenMensajeria(idOrden: "${data.idOrden}", estado: "${data.estado}", checkList: ${checkList}){
          id
          checkList{
            nombreQuienRevisa
            fecha
            cantidadDeMuestrasTruly
            observaciones
          }
          mensaje
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
