const initialState = {
  usuarios: [],
  search: [],
  emptyFilter: false,
  nuevoUsuario: {
    usuario: "",
    password: "",
    email: "",
    nombre: "",
    apellidos: "",
    telefono: 0,
    celular: 0,
    DPI: "",
    fecha_de_nacimiento: "",
    direccion:"",
    NIT: "",
    nota: "",
    sexo: "Masculino",
    tipo: "cliente",
    permisos: {
      AdminUI: null,
      AdminUser: null,
      AdminEmpresa: null,
      Mensajeria: null,
      AdminObjetivos: null,
      Compras: null,
      Productos: null,
    },
    colaborador: {
      departamento: null,
      puesto: null
    },
    empresa_asociada: {
      id: null,
      nombre: null,
      telefono: null,
      correo: null
    },
    idSucursal: null
  },
  dataInfo: {}
}

let dataUsers = (state = initialState, action) => {
  switch (action.type) {

    case 'CHANGE_DATA_USER': {

      let user = action.payload.dataUser

      let userArr = [user]

      let newStateUsuarios = state.usuarios.map( obj => userArr.find(o => o.id === obj.id) || obj )

      let newState = {
        ...state,
        usuarios: newStateUsuarios
      }

      return newState
    }
      
    case 'EMPTY_USER_FILTER': {

      let emptyFilter = action.payload.dataEmpty

      let newState = {
        ...state,
        emptyFilter
      }

      return newState

    }

    case 'NEW_USER': {

      let nuevoUsuario = action.payload.dataUser

      let newState = {
        ...state,
        nuevoUsuario
      }

      return newState

    }

    case 'NEW_USER_HANDLE_CHECKED_PERMISOS': {

      let type = action.payload.type,
          newStateChecked = action.payload.newState

      let newState = {
        ...state,
        nuevoUsuario: {
          ...state.nuevoUsuario,
          permisos: {
            ...state.nuevoUsuario.permisos,
            [type]: newStateChecked
          }
        }
      }

      return newState

    }

    case 'NEW_USER_HANDLE_SUBCHECKED_PERMISOS': {
      let admin = action.payload.admin,
          type = action.payload.type,
          newStateChecked = action.payload.newState

      let newState = {
        ...state,
        nuevoUsuario: {
          ...state.nuevoUsuario,
          permisos: {
            ...state.nuevoUsuario.permisos,
            [admin]: {
              ...state.nuevoUsuario.permisos[admin],
              [type]: newStateChecked
            } 
          }
        }
      }

      return newState

    }

    case 'DELETE_USER': {

      let user = action.payload.dataUser

      let newUsuarios = state.usuarios.filter( usuario => usuario.id !== user.id)

      let newState = {
        ...state,
        usuarios: newUsuarios
      }

      return newState

    }

    case 'FETCH_USERS': {

      let usuarios = action.payload.dataUsers

      let usuariosConEmpresa = usuarios.map( usuario => {
        if(usuario.empresa_asociada === null){
          usuario.empresa_asociada = {id: null, nombre: null, telefono: null, correo: null}
        }
        return usuario
      })

      //console.log(usuariosConEmpresa)

      let newState = {
        ...state,
        usuarios: usuariosConEmpresa
      }

      return newState
    }

    case 'FILTER_USERS': {

      let usuarios = action.payload.dataUsers

      let newState = {
        ...state,
        search: usuarios
      }

      return newState
      
    }

    case 'CLEAN_FILTER_USERS': {

      let newState = {
        ...state,
        search: []
      }

      return newState
      
    }

    case 'FETCH_DATA_INFO': {
      let dataInfo = action.payload.dataInfo

      let newState = {
        ...state,
        dataInfo
      }

      return newState
    }

    case 'ADD_USER_TO_USERS': {

      let user = action.payload.dataUser,
          userArr = [user]

      let usuarios = state.usuarios.concat(userArr)

      let newState = {
        ...state,
        usuarios
      }

      return newState
    }

    case 'EDIT_USER_CREATE_PERMISIONS_STRUCTURE': {

      let userId = action.payload.idUsuario

      let structurePermisions = {
        AdminUI: false,
        AdminUser: {
          ViewUser: false,
          CreateUser: false,
          EditUser: false,
          DeleteUser: false
        },
        AdminEmpresa: {
          ViewEmpresa: false,
          CreateEmpresa: false,
          EditEmpresa: false,
          DeleteEmpresa: false
        },
        Mensajeria: {
          QRScanner: false,
          ViewOrden: false,
          CreateOrden: false,
          EditOrden: false,
          DeleteOrden: false,
          ActOrdenAdmin: false,
          ActOrdenMuestra: false,
          CheckListOrden: false,
          ViewRuta: false,
          RealizarRuta: false,
          EditRuta: false,
          DeleteRuta: false,
          CheckListRuta: false,
          ViewPlantillaRuta: false,
          RealizarPlantillaRuta: false,
          EditPlantillaRuta: false,
          DeletePlantillaRuta: false
        },
        AdminObjetivos: false,
        Compras: {
          CreateOrdenCompra: false,
          AddProductoCompra: false
        },
        Productos: {
          ViewProducto: false,
          CreateProducto: false,
          EditProducto: false,
          DeleteProducto: false,
          ViewTipoCliente: false,
          CreateTipoCliente: false,
          EditTipoCliente: false,
          DeleteTipoCliente: false,
          ViewAseguradora: false,
          CreateAseguradora: false,
          EditAseguradora: false,
          DeleteAseguradora: false,
        } 
      }

      let user = state.usuarios.filter( usuario => usuario.id === userId)[0]

      user.permisos = structurePermisions

      let userArr = [user]

      let newStateUsuarios = state.usuarios.map( obj => userArr.find(o => o.id === obj.id) || obj )

      let newState = {
        ...state,
        usuarios: newStateUsuarios
      }

      return newState
    }

    case 'EDIT_USER_HANDLE_CHECKED_PERMISOS': {

      let type = action.payload.type,
          userId = action.payload.idUsuario,
          newStateChecked = action.payload.newState

      let user = state.usuarios.filter( usuario => usuario.id === userId)[0]

      user.permisos[type] = newStateChecked
      
      let userArr = [user]
      let newStateUsuarios = state.usuarios.map( obj => userArr.find(o => o.id === obj.id) || obj )

      let newState = {
        ...state,
        usuarios: newStateUsuarios
      }

      return newState

    }

    case 'EDIT_USER_HANDLE_SUBCHECKED_PERMISOS': {

      let admin = action.payload.admin,
          type = action.payload.type,
          userId = action.payload.idUsuario,
          newStateChecked = action.payload.newState

      let user = state.usuarios.filter( usuario => usuario.id === userId)[0]

      user.permisos[admin][type] = newStateChecked
      let userArr = [user]
      let newStateUsuarios = state.usuarios.map( obj => userArr.find(o => o.id === obj.id) || obj )

      let newState = {
        ...state,
        usuarios: newStateUsuarios
      }

      return newState

    } 

    case 'UNFETCH_USERS': {
      return action.payload.dataUsers
    }

    case 'CLEAN_NEW_USER': {

      let newState = {
        ...state,
        nuevoUsuario: {
          usuario: "",
          password: "",
          email: "",
          nombre: "",
          apellidos: "",
          telefono: 0,
          celular: 0,
          DPI: "",
          fecha_de_nacimiento: "",
          direccion:"",
          NIT: "",
          nota: "",
          sexo: "Masculino",
          tipo: "cliente",
          permisos: {
            AdminUI: null,
            AdminUser: null,
            AdminEmpresa: null,
            Mensajeria: null,
            AdminObjetivos: null,
            Compras: null
          },
          colaborador: {
            departamento: null,
            puesto: null
          },
          empresa_asociada: {
            id: null,
            nombre: null,
            telefono: null,
            correo: null
          },
          idSucursal: null
        }
      }

      return newState
      
    }

    //break;
    default:
      return state
  }
}

export default dataUsers
