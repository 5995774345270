let consulta = (textId, itemCode, type) => {
  let htmlCode = itemCode.replace(/"/g,'\\"').replace(/\r|\n/g,'')

  var string

  if(type === 'http://localhost:8080/updateText'){
    string = `mutation UpdateText {
                dataUIMutation{
                  updateText(textId: "${textId}", newTexto: "${htmlCode}") {
                    idTexto
                    newTexto
                    mensaje
                  }
                }
              }`
  }else if(type === 'http://localhost:8080/updateModalText'){
    string = `mutation UpdateModalText {
                dataUIMutation{
                  updateModalText(textId: "${textId}", newTexto: "${htmlCode}") {
                    idModal
                    newTexto
                    mensaje
                  }
                }
              }`
  }else if(type === 'http://localhost:8080/updateModalTitulo'){
    string = `mutation UpdateModalTitulo {
                dataUIMutation{
                  updateModalTitulo(textId: "${textId}", newTexto: "${htmlCode}") {
                    idModal
                    newTitulo
                    mensaje
                  }
                }
              }`
  }

  return {
    "query": string
  }

}

export default consulta;
