let handleInputChange = (event, This) => {

    let value = event.target.value,
        ruta = event.target.dataset.ruta,
        type = event.target.dataset.valueType
  
    //console.log(ruta)
  
    This.props.dataMensajeria.rutasMensajeria.filter( obj => {
  
      if((obj.id === JSON.parse(ruta).id) === true){
  
        //console.log(empresa)
  
        if(type === 'nombreRuta'){
  
          //console.log(obj)
  
          obj.nombreRuta = value
  
          This.props.dispatch({
            type:'CHANGE_DATA_RUTA_MENSAJERIA',
            payload: {
              dataRuta: obj
            }
          })
  
        }if(type === 'mensajeroRuta'){
  
          //console.log(obj)
  
          obj.idMensajero = value
          obj.ordenesRuta = []
  
          This.props.dispatch({
            type:'CHANGE_DATA_RUTA_MENSAJERIA',
            payload: {
              dataRuta: obj
            }
          })
  
        }
  
      }
  
      return null
  
    })
  
  }
  
  export default handleInputChange;