let consulta = (idProducto) => {

  const string =
    `mutation{
      ordenesCompraMutation{
        deleteProductoCompra(idProducto: "${idProducto}"){
          idProducto
          mensaje
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
