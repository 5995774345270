let consulta = (idPassPago) => {

  let string = `{
    appInfo{
      ordenesCompra{
        ordenCompraOfPassPago(idPassPago: "${idPassPago}"){
          id
          idCreador
          idAutorizador
          idUsuarioRecepcion
          idUsuarioArea
          idProveedor
          idSucursal
          proveedor
          idSucursal
          proveedor
          fecha
          estado
          productos{
            nombre
            cantidad
            NoCatalogo
            descripcion
            observaciones
            tipo
            valorUnitario
            checkList{
              integridadDeEmpaque
              valorDelProducto
              temperaturaDeTransporte
              observacionDeRecibido
            }
          }
          facturas{
            noFactura
            factura
            concepto
            productos
          }
          passPago{
            id
            fechaPass
            fechaDePago
            estado
            emailTracking{
              estado
              fecha
              correo
            }
          }
        }
      } 
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
