import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Select from 'react-select';

import { formatterTwoDecimals } from '../../../../MyModules/formatterDecimals';

import FetchEmpresas from '../../../../apiService/FetchGraphQLEmpresas';

import FetchPassPagos from './FetchGetPassPagos/FetchGraphQLGetPassPagos';

import DashboardComponent from '../../dashboard/components/DashboardComponent.jsx';

class ListPassPago extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            filter: {
                idPassPago: "",
                fechaInicio: "",
                fechaFinal: "",
                proveedor: "",
                valProveedor: null,
                estado: ''
            },
            passPagos: []
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onInputProveedorChange = this.onInputProveedorChange.bind(this);
        this.onInputTextProveedorChange = this.onInputTextProveedorChange.bind(this);
        this.onClickReset = this.onClickReset.bind(this);
    }

    handleInputChange(event){

        if(event.target.id === "fechaInicio"){
            this.setState({
                filter: {
                  ...this.state.filter,
                  fechaInicio:  event.target.value
                }
            })
      
            let fechaInicioTimeStamp = new Date(event.target.value.replace(/-/g, '/')),
                fechaFinalTimeStamp = new Date(this.state.filter.fechaFinal.replace(/-/g, '/')),
                fechaInicioTimestampString = fechaInicioTimeStamp.setHours(0,0,0,0),
                fechaFinalTimestampString = fechaFinalTimeStamp.setHours(23,59,59,0),
                fechaInicioString = new Date(parseInt(fechaInicioTimestampString)),
                fechaFinalString = new Date(parseInt(fechaFinalTimestampString))
            
            FetchPassPagos(this, fechaInicioString, fechaFinalString)

        }else if(event.target.id === "fechaFinal"){
            this.setState({
                filter: {
                  ...this.state.filter,
                  fechaFinal:  event.target.value
                }
            })
      
            let fechaInicioTimeStamp = new Date(this.state.filter.fechaInicio.replace(/-/g, '/')),
                fechaFinalTimeStamp = new Date(event.target.value.replace(/-/g, '/')),
                fechaInicioTimestampString = fechaInicioTimeStamp.setHours(0,0,0,0),
                fechaFinalTimestampString = fechaFinalTimeStamp.setHours(23,59,59,0),
                fechaInicioString = new Date(parseInt(fechaInicioTimestampString)),
                fechaFinalString = new Date(parseInt(fechaFinalTimestampString))
            
            FetchPassPagos(this, fechaInicioString, fechaFinalString)

        }else{
            this.setState({
                filter: {
                    ...this.state.filter,
                    [event.target.id]: event.target.value
                }
            })
        }

    }

    componentDidMount(){

        let fecha = new Date(),
        year = fecha.getFullYear()
        let mes
        (fecha.getMonth()+1) < 10 ? mes = `0${fecha.getMonth()+1}` : mes = `${fecha.getMonth()+1}`
        //let dia
        //fecha.getDate() < 10 ? dia = `0${fecha.getDate()}` : dia = `${fecha.getDate()}`

        let fechaInicio = new Date(),
            fechaFinal = new Date(new Date(fecha.getFullYear(), fecha.getMonth()+1, 0))

        let diaFinal
            fechaFinal.getDate() < 10 ? diaFinal = `0${fechaFinal.getDate()}` : diaFinal = `${fechaFinal.getDate()}`

        let fechaInicioTimestampString = new Date(parseInt(fechaInicio.setDate(1))).setHours(0,0,0,0).toString(),
            fechaFinalTimestampString = fechaFinal.setHours(23,59,59,0).toString(),
            fechaInicioString = new Date(parseInt(fechaInicioTimestampString)),
            fechaFinalString = new Date(parseInt(fechaFinalTimestampString))

        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                fechaInicio: `${year}-${mes}-01`,
                fechaFinal: `${year}-${mes}-${diaFinal}`
            }
        })

        FetchPassPagos(this, fechaInicioString, fechaFinalString)
        FetchEmpresas(this)
    
    }

    onInputProveedorChange(selectObj){

        this.setState({
            filter: {
                ...this.state.filter,
                proveedor: selectObj.label,
                valProveedor: selectObj
            } 
        })

    }

    onInputTextProveedorChange(value){

        this.setState({
            filter: {
                ...this.state.filter,
                proveedor: value,
                valProveedor: this.state.filter.proveedor === "" ? null : {value, label: value}
            }
        })
            
    }

    onClickReset(){
        this.setState({
            filter: {
                ...this.state.filter,
                proveedor: "",
                valProveedor: null
            }
        })
    }

    render(){

        let selectProveedores  = Array.from(this.props.empresas.filter( empresa => empresa.clasificacion_empresa.includes('Proveedor') ), empresa => {
            return { value: empresa.id, label: `${empresa.nombre_comercial}` }
        })

        let passPagosProveedoresFilter = this.state.filter.proveedor === "" ? this.state.passPagos : this.state.passPagos.filter( passPago => {

            let proveedorIdFilter = selectProveedores.filter( proveedor => proveedor.label === this.state.filter.proveedor )[0]

            return proveedorIdFilter !== undefined ? passPago.idProveedor === proveedorIdFilter.value : passPago.proveedor === this.state.filter.proveedor

        })

        let passPagosEstadoFilter = this.state.filter.estado === "" ? passPagosProveedoresFilter : passPagosProveedoresFilter.filter( passPago => passPago.estado === this.state.filter.estado)

        let passPagosidPassPagoFilter = this.state.filter.idPassPago === "" ? passPagosEstadoFilter : passPagosEstadoFilter.filter( passPago => passPago.id.includes(this.state.filter.idPassPago))

        let passPagos = passPagosidPassPagoFilter

        return(
            <DashboardComponent >
                <div className="row mx-0">
                    <div className="col-12">
                        <h1 className="text-center">Ver Contraseñas de Pago</h1>
                    </div>
                    <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
                        <label htmlFor="fechaInicio">Fecha de Inicio de Pago</label>
                        <input id="fechaInicio" className="form-control" type="date" value={this.state.filter.fechaInicio} onChange={this.handleInputChange}/>
                    </div>

                    <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
                        <label htmlFor="fechaFinal">Fecha Final de Pago</label>
                        <input id="fechaFinal" className="form-control" type="date" value={this.state.filter.fechaFinal} onChange={this.handleInputChange}/>
                    </div>

                    <div className="form-group col-12 col-md-4 col-lg-3 mx-auto text-center">
                        <label htmlFor="proveedor" className="col"><b>Proveedor:</b></label>
                        <Select id="proveedor" 
                            options={selectProveedores} 
                            onChange={this.onInputProveedorChange}
                            onInputChange={this.onInputTextProveedorChange}
                            placeholder="Busca un Proveedor"
                            value={this.state.filter.valProveedor}
                            onMenuClose={ () => this.setState({ filter: { ...this.state.filter } }) } 
                        />
                        <button type="button" className="mt-2 btn btn-outline-info" onClick={this.onClickReset}>Limpiar Busqueda</button>
                        <small id="proveedorSmall" className="form-text text-muted">Proveedor.</small>
                    </div>

                    <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
                        <label htmlFor="estado">Estado: </label>
                        <select value={this.state.filter.estado} className="form-control" id="estado" onChange={this.handleInputChange}>
                            <option value=''>Todos</option>
                            <option value='Pagado'>Pagado</option>
                            <option value='No Pagado'>No Pagado</option>
                        </select>
                    </div>

                    <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
                        <label htmlFor="idPassPago">No. de Contraseña de Pago</label>
                        <input id="idPassPago" className="form-control" type="text" value={this.state.filter.idPassPago} onChange={this.handleInputChange}/>
                    </div>

                    <div className="col-12">
                    {
                        passPagos.length > 0 ? (
                            <table className="table text-center table-bordered table-hover table-sm">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col" className="d-none d-md-table-cell">No. Abreviado</th>
                                        <th scope="col">Proveedor</th>
                                        <th scope="col" className="d-none d-md-table-cell">No. Facturas</th>
                                        <th scope="col" className="d-none d-md-table-cell">Total (Q.)</th>
                                        <th scope="col">Estado de Orden</th>
                                        <th scope="col">Se envió por correo</th>
                                        <th scope="col">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    passPagos.map( passPago => {

                                        let colorFondo;

                                        if(passPago.estado === 'No Pagado'){
                                            colorFondo = 'transparent'
                                        }else if(passPago.estado === 'Pagado'){
                                            colorFondo = '#9BEBA9'
                                        }

                                        let idAbreviado = passPago.id.substr(passPago.id.length - 6);

                                        if(this.props.empresas.length > 0){
                                            
                                            let proveedor = passPago.proveedor === null ? this.props.empresas.filter( empresa => empresa.id === passPago.idProveedor)[0].nombre_comercial : passPago.proveedor

                                            let valoresTotalesDeProducto = passPago.productos.map( producto => producto.cantidad * producto.valorUnitario)
                                            let reducer = (accumulator, currentValue) => accumulator + currentValue
                                            let valorTotal = valoresTotalesDeProducto.reduce(reducer)
    
                                            return (
                                                <tr key={passPago.id} style={{backgroundColor: colorFondo}}>
                                                    <td className="d-none d-md-table-cell">{idAbreviado}</td>
                                                    <td>{proveedor}</td>
                                                    <td className="d-none d-md-table-cell">{passPago.facturas.map( (factura, i) => <p key={i}>{factura.noFactura}</p>)}</td>
                                                    <td className="d-none d-md-table-cell">{formatterTwoDecimals.format(valorTotal)}</td>
                                                    <td>{passPago.estado}</td>
                                                    <td>{passPago.emailTracking !== null ? (
                                                        <>
                                                            {passPago.emailTracking.estado === "success" ? <FontAwesomeIcon icon="check" style={{ color: '#77FF34', marginLeft: '5px' }} /> : <FontAwesomeIcon icon="times" style={{ color: '#FF2304', marginLeft: '5px' }} />}
                                                        </>
                                                    ) : "No se ha enviado por correo" }</td>
                                                    <td>
                                                        <Link to={`/dashboard/admin-compras/pass-pago/${passPago.id}`}>
                                                            <button type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                                                                <FontAwesomeIcon icon="eye" />
                                                            </button>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        }else{
                                            return null
                                        }
                                    })
                                }
                                </tbody>
                            </table>
                        ) : (
                            <h2 className="text-center">No hay Contraseñas de Pago que Mostrar</h2>
                        ) 
                    }
                    </div>
                </div>
            </DashboardComponent>
        )
    }
}

let mapStateToProps = (state, props) => {
    return {
      empresas: state.dataEmpresas.empresas
    }
}

export default connect(mapStateToProps)(ListPassPago)