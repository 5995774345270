import React, { Component } from 'react';

import { connect } from 'react-redux';

import moment from 'moment';

import FetchEditMeta from './EditMetaFetch/FetchEditMetaPromise.js';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../../../Modal/Components/Modal.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class MetaView extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      selectedOptionUsuarios: null
    }

    this.aumentarContador = this.aumentarContador.bind(this);
    this.finalizarMetaSinCumplir = this.finalizarMetaSinCumplir.bind(this);
    
  }

  aumentarContador(){

    let meta = this.props.dataAdminObjetivos.metas.filter( meta => meta.id === this.props.meta.id)[0]

    let contadorActual = meta.contador,
        valorDeAumento = parseInt(document.querySelector('#aumentarContador').value),
        nuevoValor = contadorActual + valorDeAumento,
        newMeta = {
          ...this.props.meta,
          contador: nuevoValor
        }

        if((nuevoValor >= newMeta.indicador) && (newMeta.tipoIndicador === "Mas Que o Igual")){
          newMeta.estado = 'Finalizado';
          newMeta.fechaFinal = new Date();
          FetchEditMeta(this, newMeta)
        }else if((nuevoValor < newMeta.indicador) && (newMeta.tipoIndicador === "Mas Que o Igual")){
          newMeta.estado = 'En Proceso';
          newMeta.fechaFinal = null;
          FetchEditMeta(this, newMeta)
        }else if((nuevoValor > newMeta.indicador) && (newMeta.tipoIndicador === "Menos Que o Igual")){
          newMeta.estado = 'Finalizado Sin Alcanzar Meta';
          newMeta.fechaFinal = new Date();
          FetchEditMeta(this, newMeta)
        }else if((nuevoValor <= newMeta.indicador) && (newMeta.tipoIndicador === "Menos Que o Igual")){
          newMeta.estado = 'En Proceso';
          newMeta.fechaFinal = null;
          FetchEditMeta(this, newMeta)
        }else{
          FetchEditMeta(this, newMeta)
        }


  }

  finalizarMetaSinCumplir(){

    let meta = this.props.dataAdminObjetivos.metas.filter( meta => meta.id === this.props.meta.id)[0]

    if((meta.contador <= meta.indicador) && (meta.tipoIndicador === "Menos Que o Igual")){
      meta.estado = 'Finalizado';
      meta.fechaFinal = new Date();
      FetchEditMeta(this, meta)
    }else if((meta.contador > meta.indicador) && (meta.tipoIndicador === "Menos Que o Igual")) {
      meta.estado = 'Finalizado Sin Alcanzar Meta';
      meta.fechaFinal = new Date();
      FetchEditMeta(this, meta)
    }else if((meta.contador < meta.indicador) && (meta.tipoIndicador === "Mas Que o Igual")){
      meta.estado = 'Finalizado Sin Alcanzar Meta';
      meta.fechaFinal = new Date();
      FetchEditMeta(this, meta)
    }

  }

  render(){
    //let meta = this.props.meta,
    let meta = this.props.dataAdminObjetivos.metas.filter( meta => meta.id === this.props.meta.id)[0],
      modalVisible = this.props.modalVisible,
      objetivo = meta.idObjetivo !== "" ? this.props.objetivos.filter( objetivo => objetivo.id === meta.idObjetivo)[0] : null,
      sucursal = meta.idSucursal !== null ? this.props.sucursales.filter( sucursal => sucursal.id === meta.idSucursal)[0] : null,
      handleCloseModal = this.props.handleCloseModal,
      estadoIcon = {
        icon: "history",
        color: "rgb(45, 166, 196)"
      }

      if(meta.estado === "En Proceso"){
        estadoIcon =  {
          icon: "history",
          color: "rgb(45, 166, 196)"
        }
      }else if(meta.estado === "Finalizado"){
        estadoIcon ={
          icon: "check",
          color: "rgb(119, 255, 52)"
        }
      }else if(meta.estado === "Finalizado Sin Alcanzar Meta"){
        estadoIcon ={
          icon: "times",
          color: "red"
        }
      }

    return(
        <ModalContainer>
          <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                <img className="brand-modal" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco" />
                <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                  <div></div>
                  <div></div>
                </button>
                </div>

                <div className="modal-body sin-margen-y-padding container-fluid">
                  <div className="row text-center">


                    <div className="col-12">
                      <FontAwesomeIcon icon="calendar-check" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                      <h1>Nombre de Meta: {meta.nombre}</h1>
                    </div>
                    <div className="col-12 row" style={{'marginTop': '25px'}}>

                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="nombreMeta" style={{'marginTop': '0px'}}><b>Nombre:</b> {meta.nombre}</p>
                      </div>

                      {
                        meta.idObjetivo !== "" ? (
                          <div className="col-md-4 row mx-auto">
                            <p className="col" id="nombreObjetivo" style={{'marginTop': '0px'}}><b>Objetivo al que Pertenece:</b> {objetivo.nombre}</p>
                          </div>
                        ) : null
                      }

                      {
                        meta.idSucursal !== null ? (
                          <div className="col-md-4 row mx-auto">
                            <p className="col" id="nombreSucursal" style={{'marginTop': '0px'}}><b>Sucursal:</b> {sucursal.nombre_comercial}</p>
                          </div>
                        ) : null
                      }

                      {
                        meta.tareas.length !== 0 ? (
                          <div className="col-md-4 row mx-auto">
                            <b className="text-center col-12">Tareas de la Meta:</b>
                            <ul>
                              {
                                meta.tareas.map( (tarea, i) => <li key={i} className="col text-left" style={{'marginTop': '0px'}}><b>Nombre:</b> {tarea.nombre} <br/> <b>Estado:</b> {tarea.estado}</li>)
                              }
                            </ul>
                          </div>
                        ) : null
                      }

                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="creadorMeta" style={{'marginTop': '0px'}}><b>Creador de Meta:</b> {meta.creador.nombre} {meta.creador.apellidos}</p>
                      </div>

                      <div className="col-md-4 row mx-auto">
                        <b className="text-center col-12">Involucrados:</b>
                        <ul>
                          {
                            meta.invitados.map( (invitado, i) => <li key={i} className="col text-left" style={{'marginTop': '0px'}}>{invitado.nombre} {invitado.apellidos}</li>)
                          }
                        </ul>
                      </div>

                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="FechaInicioMeta" style={{'marginTop': '0px'}}><b>Fecha de Inicio:</b> {moment(meta.fechaDeInicio).format("DD/MM/YYYY")} a las {moment(meta.fechaDeInicio).format("HH:mm")} horas</p>
                      </div>

                      {
                        meta.fechaFinal !== null ? (
                          <div className="col-md-4 row mx-auto">
                            <p className="col" id="FechaFinalMeta" style={{'marginTop': '0px'}}><b>Fecha Final:</b> {moment(meta.fechaFinal).format("DD/MM/YYYY")} a las {moment(meta.fechaFinal).format("HH:mm")} horas</p>
                          </div>
                        ) : null
                      }

                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="FechaFinalPlanificadaMeta" style={{'marginTop': '0px'}}><b>Fecha Final Planificada:</b> {moment(meta.fechaFinalPlanificada).format("DD/MM/YYYY")} a las {moment(meta.fechaFinalPlanificada).format("HH:mm")} horas</p>
                      </div>

                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="estadoMeta" style={{'marginTop': '0px'}}><b>Estado:</b> {meta.estado} <FontAwesomeIcon icon={estadoIcon.icon} style={{'fontSize': '25px', color: `${estadoIcon.color}`}} /> </p>
                      </div>

                      {
                        meta.tipo !== null ? (
                          <div className="col-md-4 row">
                            <p className="col" id="tipoMeta" style={{'marginTop': '0px'}}><b>Tipo de Meta:</b> {meta.tipo}</p>
                          </div>
                        ) : null
                      }

                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="tipoIndicador" style={{'marginTop': '0px'}}><b>Tipo de Indicador:</b> {meta.tipoIndicador}</p>
                      </div>

                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="indicador" style={{'marginTop': '0px'}}><b>Indicador:</b> {meta.indicador.toString()}</p>
                      </div>
                      
                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="contador" style={{'marginTop': '0px'}}><b>Contador:</b> {meta.contador.toString()}</p>
                      </div>

                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="nivelDeVisualizacionMeta" style={{'marginTop': '0px'}}><b>Nivel de Vizualización:</b> {meta.nivelDeVisualizacion}</p>
                      </div>

                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="observacionesMeta" style={{'marginTop': '0px'}}><b>Observaciones de Meta:</b> {meta.observaciones}</p>
                      </div>

                    </div>

                    <div className="form-group col-12 col-md-6 col-lg-4 mx-auto" style={{marginTop: '0px'}}>
                      <label htmlFor="aumentarContador" style={{marginRight: '5px'}}>Aumentar o Disminuir Contador: </label>
                      <input id="aumentarContador" type="number" />
                      <button type="button" className="mx-auto btn btn-outline-info" onClick={this.aumentarContador}>Aumentar o Disminuir Contador</button>
                    </div>

                    {
                      ((meta.tipoIndicador === "Menos Que o Igual") || (meta.tipoIndicador === "Mas Que o Igual")) ? (
                        <div className="form-group col-12" style={{marginTop: '0px'}}>
                          <button type="button" className="mx-auto btn btn-outline-info" onClick={this.finalizarMetaSinCumplir}>Finalizar Meta</button>
                        </div>
                      ) : null
                    }

                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
                </div>
              </div>
            </div>
        </ModalB4>
      </ModalContainer>
    )
  }
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario,
    usuarios: state.dataUsers.usuarios,
    dataAdminObjetivos: state.dataAdminObjetivos,
    dataEmpresas: state.dataEmpresas
  }
}

export default connect(mapStateToProps)(MetaView)
