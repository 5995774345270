import React from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let BotonAdminUser = props => {

  let arrayOfAdminUserValues = [],
      allAdminUserValuesFalse = true

  if(props.usuario.permisos !== null){
    arrayOfAdminUserValues = Object.values(props.usuario.permisos.AdminUser)
    allAdminUserValuesFalse = arrayOfAdminUserValues.every(v => v === false)
  }
  
  if(allAdminUserValuesFalse === false) {
    return(
      <Link to="/dashboard/admin-user" style={{'marginLeft': '50px'}}>
        <button className="BotonDashboard">
          <div className="contenedorIcono">
            <FontAwesomeIcon icon="users-cog" />
          </div>
          <span className="nav-link-text">Administración de Usuarios</span>
        </button>
      </Link>
    )
  }else{
    return null
  }
}

export default BotonAdminUser
