import React, { Component } from 'react';

import DashboardComponent from '../../../dashboard/components/DashboardComponent.jsx';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

import QRMensajeria from './QRMensajeria.jsx'

class DashboardMEnsajeria extends Component {

  render(){

    let arrayOfMensajeriaValues = [],
        allMensajeriaValuesFalse = true

    if(this.props.usuario !== null){
      if(this.props.usuario.permisos !== null){
        arrayOfMensajeriaValues = Object.values(this.props.usuario.permisos.Mensajeria)
        allMensajeriaValuesFalse = arrayOfMensajeriaValues.every(v => v === false)
      }
    }

    if (this.props.conexion === false) {
			return <Redirect to='/login' />
		}else if((this.props.usuario.permisos === null) || ((Object.entries(this.props.usuario.permisos.Mensajeria).length > 0) !== true) || (allMensajeriaValuesFalse === true) || (this.props.usuario.permisos.Mensajeria.QRScanner === false)){
      return <Redirect to='/dashboard' />
    }else{
      return(
        <DashboardComponent>
          <QRMensajeria continuouslyScann={true} location={this.props.location} />
        </DashboardComponent>
      )
    }
  }
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario
  }
}

export default connect(mapStateToProps)(DashboardMEnsajeria)
