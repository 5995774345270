let consulta = (promocion) => {

  const string =
    `mutation{
      dataUIMutation{
        addPromocion(titulo: "${promocion.titulo}", promocion: "${promocion.promocion}", imagen:"${promocion.imagen}"){
          promocion{
            id
            titulo
            promocion
            imagen
          }
          mensaje}
      }
    }`

    return {
      "query": string
    }

}

export default consulta
