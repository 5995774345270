import React, { Component } from 'react';

import { connect } from 'react-redux';

import Summernote from '../../../../Summernote/Containers/Summernote.jsx';

class ListaAnalisisMicrobiologicos extends Component {

  render(){
    let renderVal = this.props.dataUI,
        textFisicoQuimicoAguaObj = renderVal.textos.find( text => {
          return text.id === 'tex014'
        }),
        texto = textFisicoQuimicoAguaObj.texto

    return(
      <div>
        <div className="container">
    			<h4 className="col-12"><span className="icon-blood-analysis" style={{"color": "#1ea1d7", "fontSize": "50px", "top": "15px", "position": "relative"}}></span>AGUA</h4>
    		</div>
        <div id="texto-fisico-quimico-agua" className="container"/>
        <Summernote itemID='#texto-fisico-quimico-agua' textObj={textFisicoQuimicoAguaObj} texto={texto} url='http://localhost:8080/updateText' />
      </div>
    )
  }

}

let mapStateToProps = (state, props) => {
  return {
    dataUI: state.dataUI
  }
}

export default connect(mapStateToProps)(ListaAnalisisMicrobiologicos);
