import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { connect } from 'react-redux';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../../../../Modal/Components/Modal';

import Loader from '../../../../../Components/SimpleLoader';

import AddPromocionFetch from './AddPromocionFetch/FetchAddPromocionPromise';

class AddPromocion extends Component {

  constructor(props){

    super(props)

    this.state = {
      titulo: "",
      promocion: "",
      imagen: "",
      loading: false,
      addPromocionMensaje: null
    }

    this.onInputChange = this.onInputChange.bind(this);
    this.imageToBase64 = this.imageToBase64.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

  }

  onInputChange = (event) => {
      this.setState({ 
        [event.target.id]: event.target.value 
      })
  }

  imageToBase64 = (event) => {
    const id = event.target.id
    const reader = new FileReader();

    reader.readAsDataURL(event.target.files[0])

    reader.onload = () => {
      this.setState({ [id]: reader.result })
    }
  }

  onSubmit = (event) => {

    event.preventDefault()

    let promocionData = {
      ...this.state
    }

    this.setState({
      loading: true
    })

    AddPromocionFetch(this, promocionData)

  }


  render(){
    let modalVisible = this.props.modalVisible,
      handleCloseModal = this.props.handleCloseModal

    return(
        <ModalContainer>
          <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                <img className="brand-modal" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco" />
                <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                  <div></div>
                  <div></div>
                </button>
                </div>

                <div className="modal-body container">
                  <form className="row" onSubmit={this.onSubmit} >

                    <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
                      <label htmlFor="titulo">Título de Promoción</label>
                      <input type="text" className="form-control" id="titulo" aria-describedby="tituloHelp" onChange={this.onInputChange} value={this.state.titulo}/>
                      <small id="tituloHelp" className="form-text text-muted">Este es el campo de título</small>
                    </div>

                    <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
                      <label htmlFor="promocion">Texto de Promoción</label>
                      <textarea type="text" className="form-control" id="promocion" aria-describedby="tituloHelp" onChange={this.onInputChange} value={this.state.promocion}/>
                      <small id="tituloHelp" className="form-text text-muted">Este es el campo del texto de la promoción</small>
                    </div>

                    <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
                      <label htmlFor="imagen">Imagen de Promoción</label>
                      <input type="file" className="form-control-file" id="imagen" aria-describedby="tituloHelp" onChange={this.imageToBase64} />
                      <small id="tituloHelp" className="form-text text-muted">Selecciona una Imagen para la promoción</small>
                    </div>

                    <div className="col-12 text-center mt-5">
                      <button type="submit" className="btn btn-outline-info">Agregar Promoción <FontAwesomeIcon icon="plus-circle" /></button>
                    </div>

                    {
                      this.state.loading === true ? (
                        <div className="col-12 mt-5">
                          <Loader />
                        </div>
                      ) : null
                    }

                    {
                      ((this.state.addPromocionMensaje !== null) && (this.state.addPromocionMensaje.response === "success")) ? (
                        <div className="alert alert-success col-12 text-center mt-5" role="alert">
                            {this.state.addPromocionMensaje.mensaje}
                        </div>
                      ) : null 
                    }

                    {
                      ((this.state.addPromocionMensaje !== null) && (this.state.addPromocionMensaje.response === "failure")) ? (
                        <div className="alert alert-danger col-12 text-center mt-5" role="alert">
                            {this.state.addPromocionMensaje.mensaje}
                        </div>
                      ) : null 
                    }

                  </form>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
                </div>
              </div>
            </div>
        </ModalB4>
      </ModalContainer>
    )
  }

}

export default connect()(AddPromocion)
