import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let EveryoneTable = props => {

    return(
          <tbody>
            {
              props.solicitudesMensajeria.map(solicitudMensajeria => {

                if(props.ruta !== undefined){
                  solicitudMensajeria.ruta = props.ruta
                }
                
                let fechaDeRecoleccion = new Date(parseInt(solicitudMensajeria.fechaDeRecoleccion))
    
                //Creando la fecha
                let dia
                (fechaDeRecoleccion.getDate()) < 10 ? dia = `0${fechaDeRecoleccion.getDate()}` : dia = `${fechaDeRecoleccion.getDate()}`
                let mes
                (fechaDeRecoleccion.getMonth() + 1) < 10 ? mes = `0${fechaDeRecoleccion.getMonth() + 1}` : mes = `${fechaDeRecoleccion.getMonth() + 1}`
                let year = fechaDeRecoleccion.getFullYear()
                //Fecha
                let fecha = `${year}-${mes}-${dia}`
                //Creando Hora
                let hora
                (fechaDeRecoleccion.getHours()) < 10 ? hora = `0${fechaDeRecoleccion.getHours()}` : hora = `${fechaDeRecoleccion.getHours()}`
                let minutos
                (fechaDeRecoleccion.getMinutes()) < 10 ? minutos = `0${fechaDeRecoleccion.getMinutes()}` : minutos = `${fechaDeRecoleccion.getMinutes()}`
                //Hora
                let time = `${hora}:${minutos}`

                let colorFondo;

                if(solicitudMensajeria.estado === 'Pendiente de Entrega'){
                  colorFondo = 'transparent'
                }else if(solicitudMensajeria.estado === 'Entregado a Mensajero'){
                  colorFondo = '#FFF4A3'
                }else if(solicitudMensajeria.estado === 'Entregado a Laboratorio'){
                  colorFondo = '#50D0FF'
                }else if(solicitudMensajeria.estado === 'Fuera de Condiciones'){
                  colorFondo = '#FFB376'
                }else if(solicitudMensajeria.estado === 'Finalizado'){
                  colorFondo = '#9BEBA9'
                }else if(solicitudMensajeria.estado === 'Cancelado'){
                  colorFondo = '#EB8484'
                }
    
                return (
                  <tr key={solicitudMensajeria.id} style={{backgroundColor: colorFondo}}>
                    {/*<th scope="row">{usuario.nombre}</th>*/}
                    <td className="d-none d-md-table-cell">{solicitudMensajeria.id}</td>
                    {
                      (solicitudMensajeria.idUsuario !== null) ? (
                        <td>
                          {
                            props.usuarios.map(usuario => {
                              return ((usuario.id === solicitudMensajeria.idUsuario) === true) ? `${usuario.nombre} ${usuario.apellidos}`: null
                            })
                          }
                        </td>
                      ) : null
                    }
                    {
                      (solicitudMensajeria.idEmpresa !== null) ? (
                        <td>
                          {
                            props.empresas.map(empresa => {
                              return ((empresa.id === solicitudMensajeria.idEmpresa) === true) ? `${empresa.nombre_comercial}`: null
                            })
                          }
                        </td>
                      ) : null
                    }
                    {
                      (solicitudMensajeria.personaQueDejaLaMuestra !== null) ? (
                        <td>
                          {solicitudMensajeria.personaQueDejaLaMuestra}
                        </td>
                      ) : null
                    }
                    {
                      solicitudMensajeria.notasDeOrden !== null ? (
                        <td className="d-none d-sm-table-cell" >{solicitudMensajeria.notasDeOrden}</td>
                      ) : null
                    }
                    {
                      solicitudMensajeria.instrucciones !== null ? (
                        <td className="d-none d-sm-table-cell" >{solicitudMensajeria.instrucciones}</td>
                      ) : null
                    }
                    {
                      solicitudMensajeria.fechaDeRecoleccion !== null ? (
                        <td className="d-none d-sm-table-cell" >{fecha} a {time} horas</td>   
                      ) : (<td className="d-none d-sm-table-cell" >No Tiene</td>)
                    }
                    {
                      solicitudMensajeria.fechaDeRecoleccion !== null ? (
                        <td className="d-sm-none d-xs-table-cell" >{time} horas</td>  
                      ) : (<td className="d-sm-none d-xs-table-cell" >No Tiene</td>)
                    }
                    <td>{solicitudMensajeria.estado}</td>
                    <td>

                      {
                        props.usuario.permisos.Mensajeria.ViewOrden === true ? (
                          <button type="button" onClick={() => {

                              solicitudMensajeria.tipoModal = "ModalView"

                              let typeModal = 'ordenMensariaModalView';
                              props.handleOpenModal(solicitudMensajeria, typeModal)

                          }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#2984be'}}>
                            <FontAwesomeIcon icon="eye" />
                          </button>
                        ) : null
                      }

                      {
                        (props.usuario.permisos.Mensajeria.ActOrdenAdmin === true) || (props.usuario.permisos.Mensajeria.ActOrdenMuestra === true) ? (
                          <button type="button" onClick={() => {
                            //props.handleChangeEstadoSolicitud(solicitudMensajeria)
  
                            if((solicitudMensajeria.tipo === 'Administrativa') && (props.usuario.permisos.Mensajeria.ActOrdenAdmin === true)){
                              props.handleChangeEstadoSolicitud(solicitudMensajeria)
                            }else{
                              if((solicitudMensajeria.estado === 'Entregado a Mensajero') && (props.usuarioData.colaborador.puesto !== 'Mensajero') && (props.usuario.permisos.Mensajeria.ActOrdenMuestra === true)){
                                props.handleChangeEstadoSolicitud(solicitudMensajeria)
                              }else if((solicitudMensajeria.tipo === "RecoleccionMuestra") && (props.usuario.permisos.Mensajeria.ActOrdenMuestra === true)){
                                let typeModal = 'changeEstadoYCantidadDeMuestras';
                                props.handleOpenModal(solicitudMensajeria, typeModal)
                              }
                            } 
  
                          }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#2984be'}}>
                            <FontAwesomeIcon icon="sync-alt" />
                          </button>
                        ) : null
                      }

                      {
                        ( ((props.usuario.tipo === 'colaborador') && ( props.usuario.permisos.Mensajeria.CheckListOrden === true ) && (solicitudMensajeria.tipo === 'RecoleccionMuestra') && (solicitudMensajeria.cantidadDeMuestras !== null)) && ((solicitudMensajeria.estado === 'Entregado a Laboratorio') || (solicitudMensajeria.estado === 'Fuera de Condiciones') || (solicitudMensajeria.estado === 'Finalizado')) ) ? 
                        ( <button type="button" onClick={() => {
                          //props.handleChangeEstadoSolicitud(solicitudMensajeria)

                          solicitudMensajeria.tipoModal = "CheckList"

                          let typeModal = 'ordenMensariaModalView';
                          props.handleOpenModal(solicitudMensajeria, typeModal)

                        }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#2984be'}}>
                          <FontAwesomeIcon icon="check-circle" />
                        </button>) : null
                      }

                      {
                        props.usuario.permisos.Mensajeria.EditOrden === true ? (
                          <button type="button" onClick={() => {
                            let typeModal = 'ordenMensjeriaModalEdit';
                            props.handleOpenModal(solicitudMensajeria, typeModal)
                          }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#2984be'}}>
                            <FontAwesomeIcon icon="edit" />
                          </button>
                        ) : null
                      }

                      {
                        props.usuario.permisos.Mensajeria.DeleteOrden === true ? (
                          <button onClick={ () => {

                            let typeModal = 'DeleteQuestion';

                            let data = {
                              DeleteFunction: props.handleDeleteOrdenMensajeria,
                              data: solicitudMensajeria
                            }

                            props.handleOpenModal(data, typeModal)

                          }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                            <FontAwesomeIcon icon="trash-alt" />
                          </button>
                        ) : null
                      }
    
    
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
    )
}

export default EveryoneTable