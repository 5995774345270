// JavaScript Document
import GraphQL from './ConsultaGraphQLMensajeria';

import url from './ApiUrl.js';
import urlencode from 'urlencode';

function handleError(err){
	console.log(`Request failed: ${err}`);
}

const fetchMensajeria = (This, fechaDesdeTimestamp, fechaHastaTimestamp) => {

	const options = {
		method: 'GET',
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(`${url}?query=query${urlencode(GraphQL(fechaDesdeTimestamp, fechaHastaTimestamp))}`, options)
	.then(res => res.json())
	.then((res) => {

		This.props.dispatch({
			type: 'FETCH_SOLICITUDES_MENSAJERIA',
			payload: {
				dataMensajeria: res.data.appInfo.solicitudesMensajeria
			}
		})

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchMensajeria;
