let handleInputChange = (event, This) => {

  let value = event.target.value,
      empresa = event.target.dataset.empresa,
      type = event.target.dataset.valueType

  if((This.props.dataEmpresas.nuevaEmpresa.id === JSON.parse(empresa).id) === true){

      //console.log(empresa)

      if(type === 'nombre_comercial'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataEmpresas.nuevaEmpresa.nombre_comercial = value

        This.props.dispatch({
          type:'NEW_EMPRESA',
          payload: {
            dataEmpresa: This.props.dataEmpresas.nuevaEmpresa
          }
        })

      }else if(type === 'nombre_razon_social'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataEmpresas.nuevaEmpresa.nombre_razon_social = value

        This.props.dispatch({
          type:'NEW_EMPRESA',
          payload: {
            dataEmpresa: This.props.dataEmpresas.nuevaEmpresa
          }
        })

      }else if(type === 'telefono'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataEmpresas.nuevaEmpresa.telefono = value

        This.props.dispatch({
          type:'NEW_EMPRESA',
          payload: {
            dataEmpresa: This.props.dataEmpresas.nuevaEmpresa
          }
        })

      }else if(type === 'correo'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataEmpresas.nuevaEmpresa.correo = value

        This.props.dispatch({
          type:'NEW_EMPRESA',
          payload: {
            dataEmpresa: This.props.dataEmpresas.nuevaEmpresa
          }
        })

      }else if(type === 'NIT'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataEmpresas.nuevaEmpresa.NIT = value

        This.props.dispatch({
          type:'NEW_EMPRESA',
          payload: {
            dataEmpresa: This.props.dataEmpresas.nuevaEmpresa
          }
        })

      }else if(type === 'direccion_fiscal'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataEmpresas.nuevaEmpresa.direccion_fiscal = value

        This.props.dispatch({
          type:'NEW_EMPRESA',
          payload: {
            dataEmpresa: This.props.dataEmpresas.nuevaEmpresa
          }
        })

      }else if(type === 'direccion_comercial'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataEmpresas.nuevaEmpresa.direccion_comercial = value

        This.props.dispatch({
          type:'NEW_EMPRESA',
          payload: {
            dataEmpresa: This.props.dataEmpresas.nuevaEmpresa
          }
        })

      }else if(type === 'descripcion'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataEmpresas.nuevaEmpresa.descripcion = value

        This.props.dispatch({
          type:'NEW_EMPRESA',
          payload: {
            dataEmpresa: This.props.dataEmpresas.nuevaEmpresa
          }
        })

      }else if(type === 'convenio'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataEmpresas.nuevaEmpresa.convenio = value

        This.props.dispatch({
          type:'NEW_EMPRESA',
          payload: {
            dataEmpresa: This.props.dataEmpresas.nuevaEmpresa
          }
        })

      }else if(type === 'credito_autorizado'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataEmpresas.nuevaEmpresa.credito_autorizado = value

        This.props.dispatch({
          type:'NEW_EMPRESA',
          payload: {
            dataEmpresa: This.props.dataEmpresas.nuevaEmpresa
          }
        })

      }else if(type === 'dias_de_credito_autorizado'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataEmpresas.nuevaEmpresa.dias_de_credito_autorizado = value

        This.props.dispatch({
          type:'NEW_EMPRESA',
          payload: {
            dataEmpresa: This.props.dataEmpresas.nuevaEmpresa
          }
        })

      }else if(type === 'tipo_de_pago_comun'){

        //console.log(This.props.dataEmpresas.nuevaEmpresa)

        This.props.dataEmpresas.nuevaEmpresa.tipo_de_pago_comun = value

        This.props.dispatch({
          type:'NEW_EMPRESA',
          payload: {
            dataEmpresa: This.props.dataEmpresas.nuevaEmpresa
          }
        })

      }

    }

}

export default handleInputChange;
