let OptionAirModeCodeUI = {
 airMode: true,
 focus: true,
 lang: 'es-ES',
 dialogsInBody: true,
 allowFullscreen: false,
 popover: {
     air: [
     ['style', ['style']],
     ['font', ['bold', 'underline', 'clear']],
     ['fontname', ['fontname']],
     ['para', ['ul', 'ol', 'paragraph']],
     ['table', ['table']],
     //['insert', ['link', 'picture', 'video']],
     ['view', ['fullscreen', 'codeview', 'help']]
   ]
  },
 codemirror: {
   lineWrapping: true,
   lineNumbers: true,
   theme: 'monokai',
   mode: 'xml'
 }
}

let OptionCodeUI = {
 focus: true,
 lang: 'es-ES',
 dialogsInBody: true,
 allowFullscreen: false,
 toolbar: [
   ['style', ['style']],
   ['font', ['bold', 'underline', 'clear']],
   ['fontname', ['fontname']],
   ['para', ['ul', 'ol', 'paragraph']],
   ['table', ['table']],
   //['insert', ['link', 'picture', 'video']],
   ['view', ['fullscreen', 'codeview', 'help']]
 ],
 codemirror: {
   lineWrapping: true,
   lineNumbers: true,
   theme: 'monokai',
   mode: 'xml'
 }
}

let OptionAirModeUI = {
 airMode: true,
 focus: true,
 lang: 'es-ES',
 dialogsInBody: true,
 allowFullscreen: false,
 popover: {
     air: [
     ['style', ['style']],
     ['font', ['bold', 'underline', 'clear']],
     ['fontname', ['fontname']],
     ['para', ['ul', 'ol', 'paragraph']],
     ['table', ['table']],
     //['insert', ['link', 'picture', 'video']],
     ['view', ['fullscreen', 'help']]
   ]
  },
}

let OptionUI = {
  focus: true,
  lang: 'es-ES',
  dialogsInBody: true,
  allowFullscreen: false,
  toolbar: [
    ['style', ['style']],
    ['font', ['bold', 'underline', 'clear']],
    ['fontname', ['fontname']],
    ['para', ['ul', 'ol', 'paragraph']],
    ['table', ['table']],
    //['insert', ['link', 'picture', 'video']],
    ['view', ['fullscreen', 'help']]
  ]
}

export { OptionAirModeCodeUI, OptionCodeUI, OptionAirModeUI, OptionUI }
