import React, { useState, useEffect, useRef } from 'react';

import './css/Parser.css';

import { connect } from 'react-redux';

import JoditEditor from "jodit-react";

import parse from 'html-react-parser';

import Edit from './Edit';

import Save from './Save';

import FetchUpdateTextPromise from '../Containers/FetchUpdateTextPromise';

let Jodit = props => {

    let [ state, setState ] = useState({
        edit: false
    })

    const editor = useRef(null)

    const [content, setContent] = useState('')

    const config = {
		//readonly: true // all options from https://xdsoft.net/jodit/doc/
	}

    useEffect(() => {
        setContent(props.textObj.texto)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onHandleEdit = () => {

        setState({
            edit: !state.edit
        })

        var htmlParser = new DOMParser();
        let contentParser = htmlParser.parseFromString(content, 'text/html').lastChild.lastChild.lastChild;
        contentParser.classList.add('editorTopClass')
        var wrapper = document.createElement('div')
        wrapper.appendChild(contentParser)
        
        setContent(wrapper.innerHTML)

    }

    const onHandleSave = () => {

        var htmlParser = new DOMParser();
        let contentParser = htmlParser.parseFromString(content, 'text/html').lastChild.lastChild.lastChild;
        contentParser.classList.remove('editorTopClass')
        var wrapper = document.createElement('div')
        wrapper.appendChild(contentParser)
        
        setContent(wrapper.innerHTML)

        FetchUpdateTextPromise(props.textObj.id, wrapper.innerHTML, props.type, props.dispatch)
        setState({
            edit: !state.edit
        })
    }

    return(
        <div className="container">
            <div className="row">
                {
                    props.conexion === false ? (
                        <div>
                            {parse(content)}
                        </div>
                    ) : (
                        <div>

                            {
                                state.edit === false ? (
                                    <div>
                                        {parse(content)}
                                        <div className="col d-flex justify-content-center">
                                            <Edit handleEdit={onHandleEdit} />
                                        </div>
                                    </div>
                                ) : null
                            }

                            {
                                state.edit === true ? (
                                    <div>
                                        <JoditEditor
                                            ref={editor}
                                            value={content}
                                            config={config}
                                            tabIndex={1} // tabIndex of textarea
                                            onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                            //onChange={newContent => { setContent(newContent) }}
                                        />
                                        <div className="col d-flex justify-content-center">
                                            <Save handleSave={onHandleSave} />
                                        </div>
                                    </div>
                                ) : null
                            }

                        </div>
                    )
                }
            </div>
        </div>
    )
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario
  }
}

export default connect(mapStateToProps)(Jodit)