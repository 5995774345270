// JavaScript Document
import GraphQL from './ConsultaGraphQLCreateTipoCliente';

import url from '../../../../../../apiService/ApiUrl.js';

function handleError(err, state, setState){
	console.log(`Request failed: ${err}`);

	setState({
		...state,
		insertTipoClienteMensaje: {
			response: "failure",
			mensaje: "Ha ocurrido un error al intentar insertar un nuevo tipo de cliente."
		}
	})	

}

const fetchCreateTipoCliente = (setState, state, tipoCliente) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(tipoCliente)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		if(res){
			setState({
				...state,
				insertTipoClienteMensaje: {
					response: "success",
					mensaje: "Se ha insertado de forma exitosa un nuevo tipo de cliente."
				}
			})
		}else{
			setState({
				...state,
				insertTipoClienteMensaje: {
					response: "failure",
					mensaje: "Ha ocurrido un error al intentar insertar un nuevo tipo de cliente."
				}
			})
		}


	})
	.then( () => {
		/* istanbul ignore next */
		setTimeout(() => {
			setState({
				nombre: "",
				insertTipoClienteMensaje: null
			})
		}, 1500);
	})
	.catch(err => handleError(err, state, setState))
	.then( () => {
		/* istanbul ignore next */
		setTimeout(() => {
			setState({
				nombre: "",
				insertTipoClienteMensaje: null
			})
		}, 1500);
	});

	//termina fetch
}

export default fetchCreateTipoCliente;
