import React from 'react';

import EmpresasEIndustrias from '../../Components/EmpresasEIndustrias.jsx';

//import GaleriaAnalisisMicrobiologicos from '../Containers/GaleriaAnalisisMicrobiologicos.jsx';
import PortadaAnalisisClinicos from '../../Components/PortadaAnalisisClinicos';

import ListaAnalisisMicrobiologicos from '../Containers/ListaAnalisisMicrobiologicos.jsx';

import Metodologias from '../Containers/Metodologias.jsx';

let AnalisisMicrobiologico = props => (
  <EmpresasEIndustrias>
    {/* <GaleriaAnalisisMicrobiologicos /> */}
    <PortadaAnalisisClinicos />
    <ListaAnalisisMicrobiologicos />
    <Metodologias />
  </EmpresasEIndustrias>
)

export default AnalisisMicrobiologico;
