import React from 'react';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../../../Modal/Components/Modal.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import OrdenEmpresa from '../../components/RealizarOrdenMuestras/OrdenEmpresa.jsx';

import OrdenUsuario from '../../components/RealizarOrdenMuestras/OrdenUsuario.jsx';

import Select from 'react-select';

import Toggle from 'react-toggle';
import "react-toggle/style.css" // for ES6 modules

let MensajeriaOrdenesEdit = props => {

  let orden = props.orden,
      ordenString = JSON.stringify(orden),
      modalVisible = props.modalVisible,
      handleCloseModal = props.handleCloseModal,
      usuario,
      empresa,
      defaultUsuarioValue,
      defaultEmpresaValue,
      mensajero = props.dataUsers.usuarios.filter(mensajero => {
        return mensajero.id === orden.idMensajero
      })
      mensajero = mensajero[0]
  let defaultMensajeroValue = mensajero !== undefined ? { value: mensajero, label: `${mensajero.nombre} ${mensajero.apellidos}` } : null
  
  if (orden.idUsuario !== null) {
    usuario = props.dataUsers.usuarios.filter(usuario => {
      return usuario.id === orden.idUsuario
    })
    usuario = usuario[0]
    defaultUsuarioValue = { value: usuario, label: `${usuario.nombre} ${usuario.apellidos}` }
  } else {
    usuario = null
  }

  if (orden.idEmpresa !== null) {
    empresa = props.dataEmpresas.empresas.filter(empresa => {
      return empresa.id === orden.idEmpresa
    })
    empresa = empresa[0]
    defaultEmpresaValue = { value: empresa, label: empresa.nombre_comercial }
  } else {
    empresa = null
  }

  //Creando la fecha minima
  let dateMin = new Date()
  let diaMin
  (dateMin.getDate()) < 10 ? diaMin = `0${dateMin.getDate()}` : diaMin = `${dateMin.getDate()}`
  let mesMin
  (dateMin.getMonth() + 1) < 10 ? mesMin = `0${dateMin.getMonth() + 1}` : mesMin = `${dateMin.getMonth() + 1}`
  let yearMin = dateMin.getFullYear()
  //Fecha minima
  let minDate = `${yearMin}-${mesMin}-${diaMin}`

  let fechaDeRecoleccion = new Date(parseInt(orden.fechaDeRecoleccion))

  //Creando la fecha
  let dia
  (fechaDeRecoleccion.getDate()) < 10 ? dia = `0${fechaDeRecoleccion.getDate()}` : dia = `${fechaDeRecoleccion.getDate()}`
  let mes
  (fechaDeRecoleccion.getMonth() + 1) < 10 ? mes = `0${fechaDeRecoleccion.getMonth() + 1}` : mes = `${fechaDeRecoleccion.getMonth() + 1}`
  let year = fechaDeRecoleccion.getFullYear()
  //Fecha
  let fecha = `${year}-${mes}-${dia}`
  //Creando Hora
  let hora
  (fechaDeRecoleccion.getHours()) < 10 ? hora = `0${fechaDeRecoleccion.getHours()}` : hora = `${fechaDeRecoleccion.getHours()}`
  let minutos
  (fechaDeRecoleccion.getMinutes()) < 10 ? minutos = `0${fechaDeRecoleccion.getMinutes()}` : minutos = `${fechaDeRecoleccion.getMinutes()}`
  //Hora
  let time = `${hora}:${minutos}`

  //Hora minima
  let minTime
  //((this.state.fechaInputDate === minDate) === true) ? minTime = `${hora}:${minutos}` : minTime = "00:00"
  ((fecha === minDate) === true) ? minTime = `${hora}:${minutos}` : minTime = "00:00"

  let SelectEmpresa = Array.from(props.dataEmpresas.empresas, empresa => {
    return { value: empresa, label: empresa.nombre_comercial, target: { value: empresa.id, dataset: { orden: ordenString, valueType: 'empresaOrden' } } } //value: empresa.nombre_comercial
  })

  //let { selectedOptionUsuarios } = this.state;

  let SelectUsuarios = Array.from(props.dataUsers.usuarios, usuario => {
    return { value: usuario, label: `${usuario.nombre} ${usuario.apellidos}`, target: { value: usuario.id, dataset: { orden: ordenString, valueType: 'usuarioOrden' } }  } //value: empresa.nombre_comercial
  })

  let SelectMensajeroArray = props.dataUsers.usuarios.filter( usuario => {
    return usuario.colaborador.puesto === 'Mensajero'
  })

  let SelectMensajero = Array.from(SelectMensajeroArray, mensajero => {
    return { value: mensajero, label: `${mensajero.nombre} ${mensajero.apellidos}`, target: { value: mensajero.id, dataset: { orden: ordenString, valueType: 'mensajeroOrden' } } } //value: empresa.nombre_comercial
  })

      return(
        <ModalContainer>
          <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                <img className="brand-modal" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco" />
                <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                  <div></div>
                  <div></div>
                </button>
                </div>

                <div className="sin-margen-y-padding container-fluid">

                  {
                    props.orden.ruta !== undefined ? (
                      <button style={{backgroundColor: 'transparent', border: 'none', color: '#2984be', marginTop: '25px', marginLeft: '25px'}} onClick={() => {
                        let typeModal = 'rutaMensariaModalView';
                        props.handleOpenModal(props.orden.ruta, typeModal)
                    }}><FontAwesomeIcon icon="arrow-left" style={{fontSize: '25px'}} /></button>
                    ) : null
                  }
                
                  <div className="row text-center">
                    <div className="col-12">
                      <FontAwesomeIcon icon="clipboard-list" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                      <h1>Id de Orden: {orden.id}</h1>
                    </div>

                    {
                      empresa !== null ? (
                        <nav className="col-12 col-md-2 flex-md-column" style={{marginTop: '30px'}}>
                          <div className="nav nav-pills nav-fill" id="nav-tab" role="tablist">
                            <a className="nav-item nav-link active" id="nav-empresa-tab" data-toggle="tab" href="#nav-ord-empresa" role="tab" aria-controls="nav-ord-empresa" aria-selected="true"><FontAwesomeIcon icon="building" /> Orden Empresa</a>
                            <a className="nav-item nav-link" id="nav-usuario-tab" data-toggle="tab" href="#nav-ord-usuario" role="tab" aria-controls="nav-ord-usuario" aria-selected="false"><FontAwesomeIcon icon="user" /> Orden Usuario</a>
                          </div>
                        </nav>
                      ) : (
                        <nav className="col-12 col-md-2 flex-md-column" style={{marginTop: '30px'}}>
                          <div className="nav nav-pills nav-fill" id="nav-tab" role="tablist">
                            <a className="nav-item nav-link" id="nav-empresa-tab" data-toggle="tab" href="#nav-ord-empresa" role="tab" aria-controls="nav-ord-empresa" aria-selected="false"><FontAwesomeIcon icon="building" /> Orden Empresa</a>
                            <a className="nav-item nav-link active" id="nav-usuario-tab" data-toggle="tab" href="#nav-ord-usuario" role="tab" aria-controls="nav-ord-usuario" aria-selected="true"><FontAwesomeIcon icon="user" /> Orden Usuario</a>
                          </div>
                        </nav>
                      )
                    }

                    <div className="col-12 col-md-10" style={{'marginTop': '25px'}}>
                      <form>
                        <div className="form-group row">


                          {
                            empresa !== null ? (
                              <div className="tab-content col-sm-6 col-12 " id="nav-tabContent">

                                  <OrdenEmpresa
                                  active="active" defaultValue={defaultEmpresaValue} SelectEmpresa={SelectEmpresa} handleChangeEmpresa={props.handleInputChange} />

                                  <OrdenUsuario defaultValue={defaultUsuarioValue} SelectUsuarios={SelectUsuarios} handleChangeUsuario={props.handleInputChange} />

                              </div>
                            ) : (
                              <div className="tab-content col-sm-6 col-12" id="nav-tabContent">

                                  <OrdenEmpresa
                                  defaultValue={defaultEmpresaValue} SelectEmpresa={SelectEmpresa} handleChangeEmpresa={props.handleInputChange} />

                                  <OrdenUsuario active="active" defaultValue={defaultUsuarioValue} SelectUsuarios={SelectUsuarios} handleChangeUsuario={props.handleInputChange} />

                              </div>
                            )
                          }

                          <div className="col-sm-6 col-12">
                            <div className="row">
                              <label htmlFor="react-select-3-input" className="col-lg-6 col-12"><b>Selecciona un Mensajero</b></label>
                              <Select
                                className="col-lg-6 col-12"
                                placeholder="Escoje un Mensajero"
                                defaultValue={defaultMensajeroValue}
                                onChange={props.handleInputChange}
                                options={SelectMensajero}
                              />
                            </div>
                          </div>

                          <div className="col-md-4 row">
                            <label htmlFor="tipoOrden" className="col"><b>Tipo:</b></label>
                            <select id="tipoOrden" ref={props.setRef} data-orden={ordenString}  className="inputUserVal" onChange={props.handleInputChange} data-value-type="tipoOrden" value={orden.tipo}>
                              <option value="Administrativa">Administrativa</option>
                              <option value="RecoleccionMuestra">Recoleccion de Muestra</option>
                            </select>
                          </div>

                         
                          <div className="col-md-4 row" style={{marginTop: '15px'}}>
                            <label htmlFor="instrucciones" className="col"><b>Instrucciones:</b></label>
                            <textarea ref={props.setRef} data-orden={ordenString} data-value-type="instrucciones" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" id="instrucciones" value={orden.instrucciones} style={{'top': '-3px'}}/>
                          </div>

                          {
                            orden.tipo === "RecoleccionMuestra" ? (
                              <div className="col-md-4 row" style={{marginTop: '15px'}}>
                                <label htmlFor="cantidadDeMuestras" className="col"><b>Cantidad de Muestras:</b></label>
                                {
                                  (orden.cantidadDeMuestras === null) ? (
                                    <input ref={props.setRef} data-orden={ordenString} data-value-type="cantidadDeMuestras" onChange={props.handleInputChange} type="number"  className="col form-control-plaintext form-control-sm inputUserVal" id="cantidadDeMuestras" min="1" max="30" value="1" style={{'top': '-3px'}}/>
                                  ) : (
                                    <input ref={props.setRef} data-orden={ordenString} data-value-type="cantidadDeMuestras" onChange={props.handleInputChange} type="number"  className="col form-control-plaintext form-control-sm inputUserVal" id="cantidadDeMuestras" min="1" max="30" value={orden.cantidadDeMuestras} style={{'top': '-3px'}}/>
                                  ) 
                                }
                            </div>
                            ) : null
                          }

                          <div className="col-md-4 row" style={{marginTop: '15px'}}>
                            <label htmlFor="personaQueLlamo" className="col"><b>Persona que Llamó:</b></label>
                            <input ref={props.setRef} data-orden={ordenString} data-value-type="personaQueLlamo" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" id="personaQueLlamo" value={orden.personaQueLlamo} style={{'top': '-3px'}}/>
                          </div>

                          <div className="col-md-4 row" style={{marginTop: '15px'}}>
                            <label htmlFor="telefono" className="col"><b>Teléfono:</b></label>
                            <input ref={props.setRef} data-orden={ordenString} data-value-type="telefono" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" id="telefono" value={orden.telefono} style={{'top': '-3px'}}/>
                          </div>

                          <div className="col-md-4 row" style={{marginTop: '15px'}}>
                            <label htmlFor="direccion" className="col"><b>Dirección:</b></label>
                            <input ref={props.setRef} data-orden={ordenString} data-value-type="direccion" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" id="direccion" value={orden.direccionDeRecepcion} style={{'top': '-3px'}}/>
                          </div>

                          <div className="col-md-4 row">
                            <label htmlFor="estado" className="col"><b>Estado:</b></label>
                            <select id="estado" ref={props.setRef} data-orden={ordenString}  className="inputUserVal" onChange={props.handleInputChange} data-value-type="estado" value={orden.estado}>
                              <option value="Pendiente de Entrega">Pendiente de Entrega</option>
                              <option value="Entregado a Mensajero">Entregado a Mensajero</option>
                              <option value="Entregado a Laboratorio">Entregado a Laboratorio</option>
                              <option value="Fuera de Condiciones">Fuera de Condiciones</option>
                              <option value="Finalizado">Finalizado</option>
                            </select>
                          </div>

                          <div className="col-md-4 row" style={{marginTop: '15px'}}>
                            <label htmlFor="diaDeRecoleccion" className="col-lg-6 col-12"><b>Día de Recolección</b></label>
                            <input id="diaDeRecoleccion" value={fecha} data-orden={ordenString} data-value-type="diaDeRecoleccionYHora" onChange={props.handleInputChange} type="date" min={minDate} className="mx-auto" placeholder="Día de Recolección"/>
                          </div>

                          <div className="col-md-4 row" style={{marginTop: '15px'}}>
                            <label htmlFor="horaDeRecoleccion" className="col-lg-6 col-12"><b>Hora de Recolección</b></label>
                            <input id="horaDeRecoleccion" data-orden={ordenString} data-value-type="diaDeRecoleccionYHora" type="time" value={time} onChange={props.handleInputChange} min={minTime} className="mx-auto" placeholder="Hora de Recolección"/>
                          </div>

                          <div className="mx-auto" style={{marginTop: '15px'}}>
                            <div className="row">
                              <p className="col-lg-6 col-12 text-center"><b>¿Es Urgente?</b></p>
                              <label className="mx-auto">
                                <Toggle
                                  data-orden={ordenString}
                                  data-value-type="urgente"
                                  defaultChecked={orden.urgente}
                                  onChange={props.handleInputChange} />
                              </label>
                            </div>
                          </div>

                        </div>
                      </form>
                    </div>
                  </div>
                  {
                    props.updateOrdenMensajeriaMensaje !== null ? (
                      <div className="row" style={{marginTop: '25px'}}>
                        {
                          (props.updateOrdenMensajeriaMensaje.response === 'success') ? (
                            <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.updateOrdenMensajeriaMensaje.mensaje}</div>
                          ) : (
                            <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.updateOrdenMensajeriaMensaje.mensaje}</div>
                          )
                        }
                      </div>
                    ) : null
                  }
                </div>

                <div className="modal-footer row" style={{height: 'auto'}}>

                    <div className="col-12 col-md" style={{marginTop: '15px'}}>
                      <button type="button" className="btn btn-default mx-auto" onClick={props.handleSaveOrdenMensajeria}><FontAwesomeIcon icon="save" style={{'fontSize': '150%'}} /></button>
                    </div>

                    <div className="col-12 col-md" style={{marginTop: '15px'}}>
                      <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
                    </div>


                </div>
              </div>
            </div>
        </ModalB4>
      </ModalContainer>
      )
}

export default MensajeriaOrdenesEdit
