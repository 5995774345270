let updateModalTiulo = (state, action) => {
  state.AllModals.modalsPreventivos.modalsPreventivosPacientes.map( modal => {
    if(modal.id === action.payload.id){
      modal.titulo = action.payload.data
    }
    return modal
  })
  state.AllModals.modalsPreventivos.modalsPreventivosLaboratorios.map( modal => {
    if(modal.id === action.payload.id){
      modal.titulo = action.payload.data
    }
    return modal
  })
  state.AllModals.modalsClinicos.modalsClinicosPacientes.map( modal => {
    if(modal.id === action.payload.id){
      modal.titulo = action.payload.data
    }
    return modal
  })
  state.AllModals.modalsClinicos.modalsClinicosLaboratorios.map( modal => {
    if(modal.id === action.payload.id){
      modal.titulo = action.payload.data
    }
    return modal
  })
  state.AllModals.modalsClinicos.modalsClinicosVeterinaria.map( modal => {
    if(modal.id === action.payload.id){
      modal.titulo = action.payload.data
    }
    return modal
  })

  return {
      ...state,
      AllModals: {
        modalsPreventivos: {
          modalsPreventivosPacientes: state.AllModals.modalsPreventivos.modalsPreventivosPacientes,
          modalsPreventivosLaboratorios: state.AllModals.modalsPreventivos.modalsPreventivosLaboratorios
        },
        modalsClinicos: {
          modalsClinicosPacientes: state.AllModals.modalsClinicos.modalsClinicosPacientes,
          modalsClinicosLaboratorios: state.AllModals.modalsClinicos.modalsClinicosLaboratorios,
          modalsClinicosVeterinaria: state.AllModals.modalsClinicos.modalsClinicosVeterinaria
        }
      }
  }
}

export default updateModalTiulo
