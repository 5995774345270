let consulta = (idPromocion) => {

  //console.log(usuarioJSON.sexo)

  const string =
    `mutation{
      dataUIMutation{
        deletePromocion(idPromocion: ${idPromocion}){
          idPromocion
          mensaje
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
