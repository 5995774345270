import React, { Component } from 'react';

import { connect } from 'react-redux';

import FetchGraphQLMetas from '../../containers/FetchGetMetas/FetchGraphQLGetMetas';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class MetasTable extends Component {


  constructor(props) {
		super(props);

		this.state={
			filter: {
        estado: "Todos",
        sucursal: "",
        tipo: "Todos",
        nivelDeVisualizacion: "Cualquiera",
        nombre: "",
        objetivo: "",
        tipoFecha: "fechaInicio",
        fechaDesde: "",
        fechaHasta: ""
      }
    }
    
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount(){

      let fecha = new Date(),
        year = fecha.getFullYear()
      let mes
      (fecha.getMonth()+1) < 10 ? mes = `0${fecha.getMonth()+1}` : mes = `${fecha.getMonth()+1}`
      let dia
      fecha.getDate() < 10 ? dia = `0${fecha.getDate()}` : dia = `${fecha.getDate()}`

      let fechaDesde = new Date(),
          fechaHasta = new Date()

      let fechaDesdeTimestampString = new Date(parseInt(fechaDesde.setDate(1))).setHours(0,0,0,0).toString(),
          fechaHastaTimestampString = fechaHasta.setHours(23,59,59,0).toString(),
          fechaDesdeString = new Date(parseInt(fechaDesdeTimestampString)),
          fechaHastaString = new Date(parseInt(fechaHastaTimestampString))

      FetchGraphQLMetas(this, this.props.usuario, this.state.filter.tipoFecha, fechaDesdeString, fechaHastaString)

      this.setState({
        filter: {
          ...this.state.filter,
          fechaDesde: `${year}-${mes}-01`,
          fechaHasta: `${year}-${mes}-${dia}`
        }
      })
    
  }

  handleInputChange(event) {
    if(event.target.id === "fechaDesde"){
      this.setState({
          filter: {
            ...this.state.filter,
            fechaDesde:  event.target.value
          }
      })

      let fechaDesdeTimeStamp = new Date(event.target.value.replace(/-/g, '/')),
          fechaHastaTimeStamp = new Date(this.state.filter.fechaHasta.replace(/-/g, '/')),
          fechaDesdeTimestampString = fechaDesdeTimeStamp.setHours(0,0,0,0),
          fechaHastaTimestampString = fechaHastaTimeStamp.setHours(23,59,59,0),
          fechaDesdeString = new Date(parseInt(fechaDesdeTimestampString)),
          fechaHastaString = new Date(parseInt(fechaHastaTimestampString))
      
      FetchGraphQLMetas(this, this.props.usuario, this.state.filter.tipoFecha, fechaDesdeString, fechaHastaString)
    }else if(event.target.id === "fechaHasta"){
      this.setState({
          filter: {
            ...this.state.filter,
            fechaHasta:  event.target.value
          }
      })

      let fechaDesdeTimeStamp = new Date(this.state.filter.fechaDesde.replace(/-/g, '/')),
          fechaHastaTimeStamp = new Date(event.target.value.replace(/-/g, '/')),
          fechaDesdeTimestampString = fechaDesdeTimeStamp.setHours(0,0,0,0),
          fechaHastaTimestampString = fechaHastaTimeStamp.setHours(23,59,59,0),
          fechaDesdeString = new Date(parseInt(fechaDesdeTimestampString)),
          fechaHastaString = new Date(parseInt(fechaHastaTimestampString))
      
      FetchGraphQLMetas(this, this.props.usuario, this.state.filter.tipoFecha, fechaDesdeString, fechaHastaString)
    }else if(event.target.id === "tipoFecha"){
      this.setState({
          filter: {
            ...this.state.filter,
            tipoFecha:  event.target.value
          }
      })

      let fechaDesdeTimeStamp = new Date(this.state.filter.fechaDesde.replace(/-/g, '/')),
          fechaHastaTimeStamp = new Date(this.state.filter.fechaHasta.replace(/-/g, '/')),
          fechaDesdeTimestampString = fechaDesdeTimeStamp.setHours(0,0,0,0),
          fechaHastaTimestampString = fechaHastaTimeStamp.setHours(23,59,59,0),
          fechaDesdeString = new Date(parseInt(fechaDesdeTimestampString)),
          fechaHastaString = new Date(parseInt(fechaHastaTimestampString))
      
      FetchGraphQLMetas(this, this.props.usuario, event.target.value, fechaDesdeString, fechaHastaString)         
    }else if(event.target.id === "estado"){
      this.setState({
        filter: {
          ...this.state.filter,
          estado: event.target.value
        }
      })
    }else if(event.target.id === "sucursal"){
      this.setState({
        filter: {
          ...this.state.filter,
          sucursal: event.target.value
        }
      })
    }else if(event.target.id === "tipo"){
      this.setState({
        filter: {
          ...this.state.filter,
          tipo: event.target.value
        }
      })
    }else if(event.target.id === "nivelDeVisualizacion"){
      this.setState({
        filter: {
          ...this.state.filter,
          nivelDeVisualizacion: event.target.value
        }
      })
    }else if(event.target.id === "nombre"){
      this.setState({
        filter: {
          ...this.state.filter,
          nombre: event.target.value
        }
      })
    }else if(event.target.id === "objetivo"){
      this.setState({
        filter: {
          ...this.state.filter,
          objetivo: event.target.value
        }
      })
    }
  }

  render(){

    let metasEstadoFilter = this.props.metas.filter( meta => {

      let metaReturn = meta

      if(this.state.filter.estado === "Todos"){
        metaReturn = meta
      }else {
        metaReturn = meta.estado === this.state.filter.estado
      }

      return metaReturn

    }),
    metasSucursalFilter = metasEstadoFilter.filter( meta => {

      let metaReturn = meta

      if(this.state.filter.sucursal === ""){
        metaReturn = meta
      }else {
        metaReturn = meta.idSucursal === this.state.filter.sucursal
      }

      return metaReturn

    }),
    metasTipoFilter = metasSucursalFilter.filter( meta => {

      let metaReturn = meta

      if(this.state.filter.tipo === "Todos"){
        metaReturn = meta
      }else {
        metaReturn = meta.tipo === this.state.filter.tipo
      }

      return metaReturn

    }),
    metasNivelDeVisualizacionFilter = metasTipoFilter.filter( meta => {

      let metaReturn = meta

      if(this.state.filter.nivelDeVisualizacion === "Cualquiera"){
        metaReturn = meta
      }else {
        metaReturn = meta.nivelDeVisualizacion === this.state.filter.nivelDeVisualizacion
      }

      return metaReturn

    }),
    metasNombreFilter = metasNivelDeVisualizacionFilter.filter( meta => {

      let metaReturn = meta

      if(this.state.filter.nombre === ""){
        metaReturn = meta
      }else {
        metaReturn = meta.nombre.toLowerCase().includes(this.state.filter.nombre.toLowerCase())
      }

      return metaReturn

    }),
    metasObjetivoFilter = metasNombreFilter.filter( meta => {

      let metaReturn = meta

      if(this.state.filter.objetivo === ""){
        metaReturn = meta
      }else {
        metaReturn = meta.idObjetivo === this.state.filter.objetivo
      }

      return metaReturn

    }),
    metasSelect = metasObjetivoFilter

    return(
      <div className="table-responsive" style={{minHeight: "50vh"}}>
    
        <button style={{backgroundColor: 'transparent', border: 'none', color: '#2984be'}} onClick={ () => {
          let type = "CalendarButton"
          this.props.handleChangeDisplay(type)
        } }><FontAwesomeIcon icon="arrow-left" style={{fontSize: '25px'}} /></button>

        <div className="container" style={{marginTop: '25px'}}>
          <div className="row">

            <div className="form-group col-12 col-md-7 col-lg-4 mx-auto">
                  <label htmlFor="tipoFecha">Tipo de Filtrado de Fecha</label>
                    <select id="tipoFecha" className="form-control" value={this.state.filter.tipoFecha} onChange={this.handleInputChange}>
                        <option value="fechaInicio">Fecha Inicio</option>
                        <option value="fechaFinal">Fecha Final</option>
                        <option value="fechaFinalPlanificada">Fecha Final Planificada</option>
                    </select>
              </div> 

              <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
                <label htmlFor="fechaDesde">Fecha Desde</label>
                <input id="fechaDesde" className="form-control" type="date" value={this.state.filter.fechaDesde} onChange={this.handleInputChange}/>
              </div>

              <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
                <label htmlFor="fechaHasta">Fecha Hasta</label>
                <input id="fechaHasta" className="form-control" type="date" value={this.state.filter.fechaHasta} onChange={this.handleInputChange}/>
              </div>

            <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
              <label htmlFor="estado">Filtrar por Estado</label>
              <select className="form-control" id="estado" value={this.state.filter.estado} onChange={this.handleInputChange}>
                <option value="Todos">Todos</option>
                <option value="En Proceso">En Proceso</option>
                <option value="Finalizado">Finalizado</option>
                <option value="Finalizado Sin Alcanzar Meta">Finalizado Sin Alcanzar Meta</option>
              </select>
            </div>

            <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
              <label htmlFor="sucursal">Filtrar por Sucursal</label>
              <select className="form-control" id="sucursal" value={this.state.filter.sucursal} onChange={this.handleInputChange}>
                <option value="">Todos</option>
                {
                  this.props.sucursales.map( sucursal => <option value={sucursal.id} key={sucursal.id}>{sucursal.nombre_comercial}</option>)
                }
              </select>
            </div>

            <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
              <label htmlFor="tipo">Filtrar por Tipo</label>
              <select className="form-control" id="tipo" value={this.state.filter.tipo} onChange={this.handleInputChange}>
                <option value="Todos">Todos</option>
                {
                  this.props.etiquetas.map( etiqueta => <option value={etiqueta.nombre} key={etiqueta.id}>{etiqueta.nombre}</option>)
                }
              </select>
            </div>

            {
                this.props.usuario.colaborador.puesto.includes('Director') ? (
                  <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
                    <label htmlFor="nivelDeVisualizacion">Filtrar por Nivel De Visualización</label>
                    <select className="form-control" id="nivelDeVisualizacion" value={this.state.filter.nivelDeVisualizacion} onChange={this.handleInputChange}>
                    <option value="Cualquiera">Cualquiera</option>
                      <option value="Todos">Todos</option>
                      <option value="Coordinacion">Coordinación</option>
                      <option value="Direccion">Dirección</option>
                    </select>
                  </div>
                ) : null
            }

            <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
              <label htmlFor="objetivo">Filtrar por Objetivo</label>
              <select className="form-control" id="objetivo" value={this.state.filter.objetivo} onChange={this.handleInputChange}>
                <option value="">Todos</option>
                {
                  this.props.objetivos.map( objetivo => <option value={objetivo.id} key={objetivo.id}>{objetivo.nombre}</option>)
                }
              </select>
            </div>

            <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
              <label htmlFor="nombre">Filtrar por Nombre</label>
              <input type="text" className="form-control" id="nombre" placeholder="Filtrar por Nombre" value={this.state.filter.nombre} onChange={this.handleInputChange}/>
            </div>

          </div>
        </div>
    
        <div className="justify-content-end">
            <button onClick={() => {
              let fecha = new Date()
              let data ={
                start: fecha,
                end: fecha,
                type: "Meta"
              }
              this.props.handleOpenModal(data, "InsertObjMetaTarea")
            }}
            type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'float': 'right', 'color': '#2984be'}}>
              <FontAwesomeIcon icon="plus-circle" /> Nueva meta <FontAwesomeIcon icon="calendar-check" />
            </button>
        </div>
        <table className="table text-center table-bordered table-hover table-sm">
          <thead className="thead-light">
            <tr>
              <th scope="col">Nombre</th>
              <th className="d-none d-md-table-cell" scope="col">Observaciones</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {
              metasSelect.map( meta => {
    
                let usuarios = this.props.usuarios
    
                meta.creador = usuarios.filter( usuario => usuario.id === meta.idCreador )[0]
    
                meta.invitados = usuarios.filter( usuario => meta.idsInvolucrados.includes(usuario.id) )
    
                meta.tareas = this.props.tareas.filter( tarea => tarea.idMeta === meta.id)
    
                meta.objetivo = this.props.objetivos.filter( objetivo => meta.idObjetivo === objetivo.id)[0]
    
                return (
                  <tr key={meta.id}>
                    {
                      ((meta.estado === "Finalizado") || (meta.estado === "Finalizado Sin Alcanzar Meta")) ? <td style={{textDecoration: "line-through"}}>{meta.nombre}</td> : <td>{meta.nombre}</td>
                    }
                    <td className="d-none d-md-table-cell" >{meta.observaciones}</td>
                    {/*<td className="d-none d-md-table-cell" >{empresa.telefono}</td>*/}
                    <td>
                      <button type="button" onClick={() => {
                          let typeModal = 'MetaView';
                          this.props.handleOpenModal(meta, typeModal)
                      }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                        <FontAwesomeIcon icon="eye" />
                      </button> {/*ref={this.props.setButtonViewUser}*/}
    
                      <button type="button" onClick={() => {
                          let typeModal = 'EditObjMetaTarea';
                          let data = {
                            type: 'Meta',
                            meta
                          }
                          this.props.handleOpenModal(data, typeModal)
                      }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                        <FontAwesomeIcon icon="edit" />
                      </button>{/*ref={this.props.setButtonEditUser}*/}
    
                      <button onClick={ () => {

                          let typeModal = 'DeleteQuestion';

                          let data = {
                            DeleteFunction: this.props.handleDeleteMeta,
                            data: meta
                          }

                          this.props.handleOpenModal(data, typeModal)

                        }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                        <FontAwesomeIcon icon="trash-alt" />
                      </button>
    
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    )
  }
}

let mapStateToProps = (state, props) => {
  return {
    usuario: state.logIn.usuario,
    sucursales: state.dataEmpresas.sucursales,
    metas: state.dataAdminObjetivos.metas,
    objetivos: state.dataAdminObjetivos.objetivos,
    etiquetas: state.dataAdminObjetivos.etiquetas.filter( etiqueta => etiqueta.tipoEvento === 'Meta')
  }
}

export default connect(mapStateToProps)(MetasTable)
