// JavaScript Document
import GraphQL from './ConsultaGraphQLUsuarios';

import url from './ApiUrl.js';
import urlencode from 'urlencode';

function handleError(err){
	console.log(`Request failed: ${err}`);
}

const fetchUsers = (This) => {

	const options = {
		method: 'GET',
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(`${url}?query=query${urlencode(GraphQL())}`, options)
	.then(res => res.json())
	.then( (res) => {

		res.data.appInfo.usuarios.forEach(usuario => {
			if(usuario.permisos !== null){

				if(usuario.permisos.AdminUser === null){
					usuario.permisos.AdminUser = {
						ViewUser: false,
						CreateUser: false,
						EditUser: false,
						DeleteUser: false
					}
				}

				if(usuario.permisos.AdminEmpresa === null) {
					usuario.permisos.AdminEmpresa = {
						ViewEmpresa: false,
						CreateEmpresa: false,
						EditEmpresa: false,
						DeleteEmpresa: false
					}
				}

				if(usuario.permisos.Mensajeria === null){
					usuario.permisos.Mensajeria = {
						QRScanner: false,
						ViewOrden: false,
						CreateOrden: false,
						EditOrden: false,
						DeleteOrden: false,
						ActOrdenAdmin: false,
						ActOrdenMuestra: false,
						CheckListOrden: false,
						ViewRuta: false,
						RealizarRuta: false,
						EditRuta: false,
						DeleteRuta: false,
						CheckListRuta: false,
						ViewPlantillaRuta: false,
						RealizarPlantillaRuta: false,
						EditPlantillaRuta: false,
						DeletePlantillaRuta: false
					}
				}

				if(usuario.permisos.Compras === null){
					usuario.permisos.Compras = {
						CreateOrdenCompra: false,
						AddProductoCompra: false
					}
				}

				if(usuario.permisos.Productos === null){
					usuario.permisos.Productos = {
						ViewProducto: false,
						CreateProducto: false,
						EditProducto: false,
						DeleteProducto: false,
						ViewTipoCliente: false,
						CreateTipoCliente: false,
						EditTipoCliente: false,
						DeleteTipoCliente: false,
						ViewAseguradora: false,
						CreateAseguradora: false,
						EditAseguradora: false,
						DeleteAseguradora: false,
					}
				}
				
			}
		});

		This.props.dispatch({
			type: 'FETCH_USERS',
			payload: {
				dataUsers: res.data.appInfo.usuarios
			}
		})

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchUsers;
