const initialState = {
    filter: {
        idOrden: "",
        fechaInicio: "",
        fechaFinal: "",
        proveedor: "",
        valProveedor: null,
        creador: "",
        valCreador: null
    },
    ordenes: []
  }
  
  let dataOrdenesCompra = (state = initialState, action) => {
    switch (action.type) {
      case 'FILTER_ORDENES_COMPRA': {
  
        //Obtener datos almacenados Res
        let filter = action.payload.filter;

        return {
            ...state,
            filter
        }
  
      }
      case 'SET_ORDENES_COMPRA': {
  
        //Obtener datos almacenados Res
        let ordenes = action.payload.ordenes;

        return {
            ...state,
            ordenes
        }
  
      }
        //break;
      default:
        return state
    }
  }
  
  export default dataOrdenesCompra