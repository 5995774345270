// JavaScript Document
import GraphQL from './ConsultaGraphQLAddFacturasCompra';

import url from '../../../../../apiService/ApiUrl.js';

function handleError(err){
	console.log(`Request failed: ${err}`);
}

const fetchAddFacturasCompra = (This, FactYPassPago) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(FactYPassPago)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		//console.log(res)

		This.setState({
			orden: {
				...This.state.orden,
				facturas: This.state.orden.facturas === null ? res.data.ordenesCompraMutation.addFacturasCompra.facturas : [...This.state.orden.facturas, ...res.data.ordenesCompraMutation.addFacturasCompra.facturas],
				passPago: res.data.ordenesCompraMutation.addFacturasCompra.passPago !== null ? {
					...res.data.ordenesCompraMutation.addFacturasCompra.passPago,
					fechaPass: new Date(parseInt(res.data.ordenesCompraMutation.addFacturasCompra.passPago.fechaPass)),
					fechaDePago: new Date(parseInt(res.data.ordenesCompraMutation.addFacturasCompra.passPago.fechaDePago))
				} : null,
				razonDeEntregaParcial: res.data.ordenesCompraMutation.addFacturasCompra.razonDeEntregaParcial,
				productos: res.data.ordenesCompraMutation.addFacturasCompra.productos,
				idUsuarioRecepcion: res.data.ordenesCompraMutation.addFacturasCompra.idUsuarioRecepcion
			},
			loading: false,
			displayAddFactura: false
		})

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchAddFacturasCompra;
