let consulta = (newPass, idUser) => {

  //console.log(usuarioJSON.sexo)

  const string =
    `mutation updatePass {
      userMutation{
        updatePassword(
           idUser: "${idUser}"
          newPass: "${newPass}"
        ) {
          idUser
          newPass
          mensaje
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
