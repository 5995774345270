import React from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let AdminUI = props => {
  if(props.usuario.permisos.AdminUI === true){
    return(
      <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Charts">
        <Link to="/" className="nav-link">
          <FontAwesomeIcon icon="edit" />
          <span className="nav-link-text"> Administración de contenido Web</span>
        </Link>
      </li>
    )
  }else{
    return null
  }
}

export default AdminUI
