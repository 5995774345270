import React, { Component } from 'react';
import LogInComponent from '../components/LogInComponent.jsx';
import { Redirect } from 'react-router';
import Loader from '../../../Components/Loader';

import url from '../../../../apiService/ApiUrl.js';

import { connect } from 'react-redux';

import sessionService from '../../../../sessionService/sessionService';

class LogIn extends Component{

  constructor(props) {
    super(props);

    this.OnLogIn = this.OnLogIn.bind(this);

  }

  async OnLogIn(event){

    event.preventDefault();

    let pass = document.querySelector('#password').value,
        usuarioOEmail = document.querySelector('#usuarioOEmail').value

    //let url = 'http://localhost:8080/graphql';

    let usuarioData = {
      usuarioOEmail,
      pass
    }

    let sessionServiceCallback = () => { 
      if(this.props.conexion === true && (this.props.usuario.usuario_activo === true)){
        return <Redirect to='/dashboard' />
      }else if(((this.props.usuario === null) && (this.props.conexion === false)) || (this.props.usuario.usuario_activo === false)) {
        this.props.dispatch({
          type: 'LOGIN',
          payload: {
            logIn: {
              conexion: false,
              usuario: null
            }
          }
        })
        let sessionServiceCallback = () => {
      
          if(this.props.conexion === true){
            return <Loader />
          }else{
            return <Redirect to='/login' />
          }
        }
    
        sessionService.logOut(url, this, sessionServiceCallback)
        
        document.querySelector('#incorrect-data').style.display = 'block';
      }
    }

    sessionService.logIn(url, usuarioData, this, sessionServiceCallback)

    //GraphQL(usuarioOEmail, valpass, this);


  }

    

  render() {
    if (this.props.conexion === true && (this.props.usuario.usuario_activo === true)) {
		  return <Redirect to='/dashboard' />
		}else{
      return (
        <LogInComponent>
          <div className="d-flex align-items-center" style={{marginTop: '80px'}}>
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-7 col-lg-5 mx-auto">
                  <div className="card card-login mx-auto mt-5">
                    <div className="card-header text-center">
                      <p>Ingresa</p>
                    </div>
                    <div className="card-body">
                      <form onSubmit={this.OnLogIn}>
                        <div className="form-group">
                          <label htmlFor="usuarioOEmail">Usuario o Correo Electrónico</label>
                          <input className="form-control" id="usuarioOEmail" type="text" aria-describedby="emailHelp" placeholder="Escribe tu usuario o correo electrónico" />
                        </div>
                        <div className="form-group">
                          <label htmlFor="password">Contraseña</label>
                          <input className="form-control" id="password" type="password" placeholder="Escribe tu contraseña" />
                        </div>
                        <button className="btn btn-outline-light btn-block" type="submit">Ingresa</button>
                        <div id="incorrect-data" style={{'marginTop': '15px', 'display': 'none', 'transition': 'all 0.5s ease-in-out'}} className="alert alert-danger" role="alert">Ingresaste datos incorrectos o eres un usuario inactivo, por favor prueba de Nuevo</div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LogInComponent>
      )
    }
  }

}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario
  }
}

export default connect(mapStateToProps)(LogIn)
