let consulta = (usuarioJSON) => {

  //console.log(usuarioJSON)
  let permisos = JSON.stringify(usuarioJSON.permisos).replace(/{"/g, '{').replace(/":/g, ':').replace(/,"/g, ',')

  let string

  console.log(usuarioJSON)

  if(usuarioJSON.tipo !== "colaborador"){
    string =
      `mutation updateUser {
        userMutation{
          updateUser(
            userData: {
              id: "${usuarioJSON.id}"
              usuario: "${usuarioJSON.usuario}"
              nombre: "${usuarioJSON.nombre}"
              apellidos: "${usuarioJSON.apellidos}"
              telefono: ${usuarioJSON.telefono}
              celular: ${usuarioJSON.celular}
              email: "${usuarioJSON.email}"
              DPI: "${usuarioJSON.DPI}"
              fecha_de_nacimiento: "${usuarioJSON.fecha_de_nacimiento}"
              direccion: "${usuarioJSON.direccion}"
              NIT: "${usuarioJSON.NIT}"
              nota: "${usuarioJSON.nota}"
              sexo: "${usuarioJSON.sexo}"
              tipo: "${usuarioJSON.tipo}"
              permisos: ${permisos}
              usuario_activo: ${usuarioJSON.usuario_activo}
              empresa_asociada: {
                id: "${usuarioJSON.empresa_asociada.id}"
                nombre: "${usuarioJSON.empresa_asociada.nombre}"
                telefono: ${usuarioJSON.empresa_asociada.telefono}
                correo: "${usuarioJSON.empresa_asociada.correo}"
              }
              idSucursal: ${usuarioJSON.idSucursal}
            }
          ) {
            mensaje
            id
          }
        }
      }`
  } else {
    string =
      `mutation updateUser {
        userMutation{
          updateUser(
            userData: {
              id: "${usuarioJSON.id}"
              usuario: "${usuarioJSON.usuario}"
              nombre: "${usuarioJSON.nombre}"
              apellidos: "${usuarioJSON.apellidos}"
              telefono: ${usuarioJSON.telefono}
              celular: ${usuarioJSON.celular}
              email: "${usuarioJSON.email}"
              DPI: "${usuarioJSON.DPI}"
              fecha_de_nacimiento: "${usuarioJSON.fecha_de_nacimiento}"
              direccion: "${usuarioJSON.direccion}"
              NIT: "${usuarioJSON.NIT}"
              nota: "${usuarioJSON.nota}"
              sexo: "${usuarioJSON.sexo}"
              tipo: "${usuarioJSON.tipo}"
              permisos: ${permisos}
              usuario_activo: ${usuarioJSON.usuario_activo}
              empresa_asociada: {
                id: "${usuarioJSON.empresa_asociada.id}"
                nombre: "${usuarioJSON.empresa_asociada.nombre}"
                telefono: ${usuarioJSON.empresa_asociada.telefono}
                correo: "${usuarioJSON.empresa_asociada.correo}"
              }
              colaborador: {
                id: "${usuarioJSON.colaborador.id}"
                puesto: "${usuarioJSON.colaborador.puesto}"
                departamento: "${usuarioJSON.colaborador.departamento}"
              }
              idSucursal: ${usuarioJSON.idSucursal}
            }
          ) {
            mensaje
            id
          }
        }
      }`
  }


    return {
      "query": string
    }

}

export default consulta
