let consulta = (tareaJSON) => {

  let idsInvolucrados = tareaJSON.idsInvolucrados.toString()

  //console.log(tareaJSON)

  const string =
    `mutation insertAdminObjetivos{
      adminObjetivos{
        editTarea(tareaData: {
          id: "${tareaJSON.id}",
          nombre: "${tareaJSON.nombre}",
          idCreador: ${tareaJSON.idCreador},
          idSucursal: ${tareaJSON.idSucursal},
          idMeta: "${tareaJSON.idMeta}",
          idsInvolucrados: [${idsInvolucrados}],
          fechaDeInicio: "${tareaJSON.fechaDeInicio}",
          fechaFinalPlanificada: "${tareaJSON.fechaFinalPlanificada}",
          estado: "${tareaJSON.estado}",
          tipo: "${tareaJSON.tipo}",
          nivelDeVisualizacion: "${tareaJSON.nivelDeVisualizacion}",
          observaciones: "${tareaJSON.observaciones}"
        })
        {
          id
          mensaje
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
