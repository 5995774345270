import GraphQL from './ConsultaGraphQL';

import url from '../../../../../../apiService/ApiUrl.js';

//import moment from 'moment';

// JavaScript Document
function handleError(err, This){
	console.log(`Request failed: ${err}`);
	This.setState({
		mensajeEdit: {
			response: 'failure',
			mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
		}
	})
}

const insertObjetivo = (This, objetivoJSON, stateCheckBox) => {
	//console.log(usuarioJSON)
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(objetivoJSON)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		This.setState({
			mensajeEdit: {
				response: 'success',
				mensaje: res.data.adminObjetivos.editObjetivo.mensaje
			}
		})

		//console.log(res)

		This.props.dispatch({
			type: 'EDIT_OBJETIVO_TO_OBJETIVOS',
			payload: {
				dataObjetivo: {
					id: res.data.adminObjetivos.editObjetivo.id,
					nombre: objetivoJSON.nombre,
					idCreador: objetivoJSON.idCreador,
					idSucursal: objetivoJSON.idSucursal,
					idsInvolucrados: objetivoJSON.idsInvolucrados,
					fechaDeInicio: new Date(objetivoJSON.fechaDeInicio),
					fechaFinal: objetivoJSON.fechaFinal,
					fechaFinalPlanificada: new Date(objetivoJSON.fechaFinalPlanificada),
					estado: objetivoJSON.estado,
					tipo: objetivoJSON.tipo,
					nivelDeVisualizacion: objetivoJSON.nivelDeVisualizacion,
					observaciones: objetivoJSON.observaciones,
					type: "Objetivo"
				},
				stateCheckBox
			}
		})

		return res
	})
	.then( () =>{
		setTimeout( () => {
			This.setState({
				mensajeEdit: null
			})
		}, 3000);
	})
	.catch(err => handleError(err, This))
	.then(() =>{
		setTimeout( () => {
			This.setState({
				mensajeEdit: null
			})
		}, 5000);
	});;

	//termina fetch
}

export default insertObjetivo;
