import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let CheckListView = props => {

    let fecha = new Date(parseInt(props.checkList.fecha)),
    year = fecha.getFullYear()
    let mes
    (fecha.getMonth()+1) < 10 ? mes = `0${fecha.getMonth()+1}` : mes = `${fecha.getMonth()+1}`
    let dia
    fecha.getDate() < 10 ? dia = `0${fecha.getDate()}` : dia = `${fecha.getDate()}`
    let hora
    fecha.getHours() < 10 ? hora = `0${fecha.getHours()}` : hora = `${fecha.getHours()}`
    let minutos
    fecha.getMinutes() < 10 ? minutos = `0${fecha.getMinutes()}` : minutos = `${fecha.getMinutes()}`
    let seg
    fecha.getSeconds() < 10 ? seg = `0${fecha.getSeconds()}` : seg = `${fecha.getSeconds()}`

    fecha = `${dia}/${mes}/${year} a las ${hora}:${minutos}:${seg} horas`

    return(
        <div className="row">
            <div className="col-12">
                <h3 className="text-center">Check List Orden</h3>
            </div>
            <div className="col-12 col-md-4 col-lg-3 mx-auto">
                <p className="text-center">Nombre de Quién Revisa: {props.checkList.nombreQuienRevisa}</p>
            </div>
            <div className="col-12 col-md-4 col-lg-3 mx-auto">
                <p className="text-center">Fecha de Check List: {fecha}</p>
            </div>
            <div className="col">
                <p className="text-center">¿La Cantidad de Paquetes es {props.cantidadDeMuestras}?
                    {
                        props.checkList.cantidadDeMuestrasTruly === true ? <FontAwesomeIcon icon="check" style={{ color: '#77FF34', marginLeft: '5px' }} /> : <FontAwesomeIcon icon="times" style={{ color: '#FF2304', marginLeft: '5px' }} />
                    }
                </p>     
            </div>

            {
                props.checkList.observaciones !== null ? (
                    <div className="col-12 col-md-4 col-lg-3 mx-auto">
                        <p className="text-center">Observaciones: {props.checkList.observaciones}</p>
                    </div>
                ) : null
            }

            <div className="col-12">
            <div className="row">
                <button type="button" className="btn btn-outline-info mx-auto" onClick={props.handleCheckListView} style={{marginTop: '15px'}}>← Regresar</button>
            </div>
            </div>

        </div>
    )
}

export default CheckListView