import React, { Component } from 'react';

import { connect } from 'react-redux';

import Summernote from '../../../../Summernote/Containers/Summernote.jsx';

class Metodologias extends Component {

  render(){
    let renderVal = this.props.dataUI,
        textmetodologiaObj = renderVal.textos.find( text => {
          return text.id === 'tex013'
        }),
        texto = textmetodologiaObj.texto
    return(
      <section id="seccion-metodologias">
    		<div className="container">{/*style="color: #3dbcb2;"*/}
    			<h4 className="col-12">METODOLOGÍAS</h4>
    		</div>
    		<div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div id="texto-metodologias" />
              <Summernote itemID='#texto-metodologias' textObj={textmetodologiaObj} texto={texto} url='http://localhost:8080/updateText' />
            </div>
      			<div className="col-md-6 col-12">
      				<img src="/img/analisismicrobiologico/analisis.jpg" alt="Imagen Metodología Análisis Microbiológico" className="img-fluid" />
      			</div>
          </div>
    		</div>
    		<div id="mensaje" className="row" style={{"marginRight": "0px"}}>
    			<span className="col-12" href="contacto.html" rel="external" data-ajax="false">
    			<h3 className="col-12 text-center"><span className="icon-light-bulb"></span> CONSULTE NUESTROS SERVICIOS ADICIONALES</h3><br />
    			<em><p className="col-12 text-center" style={{"textAlign": "center", "paddingLeft": "15px"}}>(Servicios de mensajería, asesorías, capacitaciones, entre otros)</p></em>
    			</span>
    		</div>
    	</section>
    )
  }

}

let mapStateToProps = (state, props) => {
  return {
    dataUI: state.dataUI
  }
}

export default connect(mapStateToProps)(Metodologias);
