let consulta = (orden) => {

  const string =
    `mutation{
      ordenesCompraMutation{
        deleteOrdenCompra(idOrden: "${orden.id}"){
          idOrden
          mensaje
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
