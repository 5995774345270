import React, { Component } from 'react';
//import NavInicio from './NavInicio.jsx';
import NewNavbarInicio from './NewNavbarInicio/Containers/NavbarInicio';
import Portada from './Portada/Portada.jsx';
import Historia from '../../Containers/Historia';
import MisionVisionValores from '../../Components/MisionVisionValores/MisionVisionValores.jsx';
//import GaleriaInicio from '../Containers/Galeria.jsx';
import Promociones from '../../Components/Promociones/Containers/Promociones';
import Reconocimientos from '../../Containers/Reconocimientos.jsx';
import Miembros from '../../Containers/Miembros.jsx';

import { connect } from 'react-redux';

//Import MenuCMR
import MenuCMR from '../../../Dashboard/dashboard/containers/MenuCMR.jsx';

//Import de Modal
import ModalPortal from '../../../Components/ModalPortal';
import Modal from '../../../Modal/Containers/ModalDashboard';

/*Footer*/
import Footer from '../../../../App/Components/NewFooter.jsx';

class Inicio extends Component {

	render(){
		return (
		<div>
			{/*<NavInicio conexion={this.props.conexion}/>*/}
			<NewNavbarInicio conexion={this.props.conexion} />
			<Portada />
			<Historia />
			<MisionVisionValores />
			<Reconocimientos />
			<Miembros />
			<Promociones />
			{/*<GaleriaInicio />*/}
			<Footer />
			<MenuCMR />
			<ModalPortal>
				<Modal />
			</ModalPortal>
		</div>
		)
	}

}

let mapStateToProps = (state, props) => {
	return {
		conexion: state.logIn.conexion
	}
}
  
  export default connect(mapStateToProps)(Inicio)
