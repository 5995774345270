import React from 'react';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../../../Modal/Components/Modal.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let UserEdit = props => {
  let usuario = props.usuario,
      usuarioString = JSON.stringify(usuario),
      modalVisible = props.modalVisible,
      handleCloseModal = props.handleCloseModal

      //console.log(fecha_de_nacimiento_Convertido)

      return(
        <ModalContainer>
          <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                <img className="brand-modal" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco" />
                <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                  <div></div>
                  <div></div>
                </button>
                </div>

                <div className="modal-body sin-margen-y-padding container-fluid">
                  <div className="row text-center">
                    <div className="col-12">
                      <FontAwesomeIcon icon="user-circle" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                      <h1>{usuario.nombre} {usuario.apellidos}</h1>
                      <h2>Cambio de Contraseña</h2>
                    </div>
                    <div className="col-12" style={{'marginTop': '25px'}}>
                      <form>
                        <div className="form-group row">

                          <div className="col row">
                            <label htmlFor="NuevaContraseña" className="col"><b>Nueva Contraseña:</b></label>
                            <input data-usuario={usuarioString} data-value-type="password" type="password"  className="col form-control-plaintext form-control-sm inputUserVal" id="NuevaContraseña" defaultValue="" style={{'top': '-3px'}}/>{/*ref={props.setRef} onChange={props.handleInputChange} */}
                          </div>

                        </div>
                      </form>
                    </div>
                  </div>
                  {
                    props.updateUserContrasenaMensaje !== null ? (
                      <div className="row">
                        {
                          (props.updateUserContrasenaMensaje.response === 'success') ? (
                            <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.updateUserContrasenaMensaje.mensaje}</div>
                          ) : (
                            <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.updateUserContrasenaMensaje.mensaje}</div>
                          )
                        }
                      </div>
                    ) : null
                  }
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default mx-auto" onClick={props.handleChangePassWord}><FontAwesomeIcon icon="unlock-alt" style={{'fontSize': '150%'}} /> Cambiar Contraseña</button>
                  <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
                </div>
              </div>
            </div>
        </ModalB4>
      </ModalContainer>
      )
}

export default UserEdit
