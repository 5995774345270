import React, { Component } from 'react';

import { formatterTwoDecimals } from '../../../../MyModules/formatterDecimals';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import Popup from 'react-popup';
import 'react-popup/style.css';

import QRCreator from '../../Components/QRCreator';

import Toggle from 'react-toggle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

import Loader from '../../../Components/SimpleLoader';

import DashboardComponent from '../../dashboard/components/DashboardComponent.jsx';

import AddFactura from './AddFactura';

import ReenvioCorreo from '../Components/ReenvioCorreo';

import ChangeProductosToNewOrden from './ChangeProductosToNewOrden';

import FetchGraphQLGetOrden from './FetchGetOrdenCompra/FetchGraphQLGetOrdenCompra';

import FetchEmpresas from '../../../../apiService/FetchGraphQLEmpresas';

import FetchUsuarios from '../../../../apiService/FetchGraphQLUsuarios';

import FetchUpdateStateCompra from './FetchUpdateStateOrdenCompra/FetchGraphQLUpdateStateCompra';

import FetchAddFacturasCompra from './FetchAddFacturasOrdenCompra/FetchGraphQLAddFacturasCompra';

import FetchAddFactura from './FetchAddFacturaOrdenCompra/FetchGraphQLAddFacturaCompra';

import FetchAddIdRecepcionArea from './FetchAddIdRecepcionArea/FetchGraphQLAddIdRecepcionArea';

import FetchChangeProductosToNewOrden from './FetchChangeProductosToNewOrden/FetchGraphQLChangeProductos';

import CheckList from '../Components/CheckList';

import PrintOrdenCompra from '../Components/PrintOrdenCompra';

import './css/printPassPago.css';

class VerOrdenCompra extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            idUsuarioRecepcion: null,
            idUsuarioArea: null,
            orden: null,
            factura: null,
            loading: false,
            displayAddFactura: false,
            displayCheckList: false,
            displayCambiarProductos: false,
            sendCorreo: true,
            defaultCorreo: false,
            razonDeFueraDeCondiciones: '',
            razonDeFueraDeCondicionesDisplay: false,
            correo: ''
        }

        this.handleUpdateStateOrden = this.handleUpdateStateOrden.bind(this);
        this.handleInputFileChangeFactura = this.handleInputFileChangeFactura.bind(this);
        this.handleSubmitFacturaYPassPago = this.handleSubmitFacturaYPassPago.bind(this);
        this.handleAddFactura = this.handleAddFactura.bind(this);
        this.handleClickViewMoreInfoProducto = this.handleClickViewMoreInfoProducto.bind(this);
        this.handleChangeSendCorreo = this.handleChangeSendCorreo.bind(this);
        this.handleChangeDefaultCorreo = this.handleChangeDefaultCorreo.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckListProducto = this.handleCheckListProducto.bind(this)
        this.handleAddIdUsuarioArea = this.handleAddIdUsuarioArea.bind(this)
        this.handleChangeProductoToNewOrden = this.handleChangeProductoToNewOrden.bind(this)
    }

    componentDidMount(){
        FetchEmpresas(this)
        FetchUsuarios(this)
        FetchGraphQLGetOrden(this, this.props.match.params.idOrden)
    }

    async handleUpdateStateOrden(estado){

        let data

        if(this.state.razonDeFueraDeCondiciones !== ''){

            data = {
                estado,
                correo: this.state.correo,
                razonDeFueraDeCondiciones: this.state.razonDeFueraDeCondiciones
            }

        }else{
            data = {
                estado,
                correo: this.state.correo
            }
        }


        let dataStateCompra = await FetchUpdateStateCompra(this, this.state.orden.id, data)

        let {
            estado: estadoOrdenCompra,
            emailTracking
        } = dataStateCompra

        emailTracking = emailTracking !== null ? {
            ...emailTracking,
            fecha: new Date(emailTracking.fecha)
        } : null

        this.setState({
            orden: {
                ...this.state.orden,
                estado: estadoOrdenCompra,
                emailTracking
            }
        })

    }

    handleInputFileChangeFactura(){

        let This = this
        function convertToBase64() {
            //Read File
            var selectedFile = document.getElementById("archivo").files;
            //Check File is not Empty
            if (selectedFile.length > 0) {
                // Select the very first file from list
                var fileToLoad = selectedFile[0];
                // FileReader function for read the file.
                var fileReader = new FileReader();
                var base64;
                // Onload of file read the file content
                fileReader.onload = function(fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    // Print data in console
                    //console.re.log(base64);
                    //console.log(base64);
                    This.setState({
                        factura: base64
                    })
                };
                // Convert data to base64
                fileReader.readAsDataURL(fileToLoad);
            }
        }
        convertToBase64()
    }

    handleSubmitFacturaYPassPago(data){

        let FactYPassPago = {
            idOrden: this.state.orden.id,
            idUsuarioRecepcion: this.props.usuario.id,
            productos: this.state.orden.productos,
            facturas: data.facturas,
            fechaPass: data.fechaDePago === null ? null : new Date().toString(),
            fechaDePago: data.fechaDePago === null ? null : data.fechaDePago.toString(),
            razonDeEntregaParcial: data.razonDeEntregaParcial, 
            correo: data.correo
        }

        //console.log(FactYPassPago)

        this.setState({
            loading: true
        })

        FetchAddFacturasCompra(this, FactYPassPago)
    }

    handleAddFactura(noFactura){

        let data = {
            idOrden: this.state.orden.id,
            noFactura,
            factura: this.state.factura
        }

        this.setState({
            loading: true
        })

        FetchAddFactura(this, data)

    }

    handleClickViewMoreInfoProducto(producto){

        Popup.create({
            title: 'Información de Producto',
            content: <div className="col-12">
                        {
                           ((producto.NoCatalogo === null) || (producto.NoCatalogo === undefined) || (producto.NoCatalogo === '')) ? null : (
                                <p className="text-justify"><b>No. de Catálogo:</b> <br/>
                                {producto.NoCatalogo}
                                </p>
                            )
                        }
                        {
                            ((producto.descripcion === null) || (producto.descripcion === undefined) || (producto.descripcion === '')) ? null : (
                                <p className="text-justify"><b>Descripcion:</b> <br/>
                                {producto.descripcion}
                                </p>
                            )
                        }
                        {
                            ((producto.observaciones === null) || (producto.observaciones === undefined) || (producto.observaciones === '')) ? null : (
                                <p className="text-justify"><b>Observaciones:</b> <br/>
                                {producto.observaciones}
                                </p>
                            )    
                        }

                        {
                            ((producto.checkList === null) || (producto.checkList === undefined) || (producto.checkList === '')) ? null : (
                                <div>
                                    <h4 className="text-center">Check List</h4>

                                    {
                                        ((producto.checkList.integridadDeEmpaque === null)) ? null : (
                                            <p className="text-justify"><b>Integridad del Empaque:</b> { producto.checkList.integridadDeEmpaque === true ? <FontAwesomeIcon icon="check" style={{ color: '#77FF34', marginLeft: '5px' }} /> : <FontAwesomeIcon icon="times" style={{ color: '#FF2304', marginLeft: '5px' }} /> }</p>
                                        )
                                    }
                                    
                                    {
                                        ((producto.checkList.valorDelProducto === null)) ? null : (
                                            <p className="text-justify"><b>Valor del Producto o Servicio:</b> { producto.checkList.valorDelProducto === true ? <FontAwesomeIcon icon="check" style={{ color: '#77FF34', marginLeft: '5px' }} /> : <FontAwesomeIcon icon="times" style={{ color: '#FF2304', marginLeft: '5px' }} /> }</p>
                                        )
                                    }

                                    {
                                        ((producto.checkList.observacionDeRecibido === null) || (producto.checkList.observacionDeRecibido === '')) ? null : (
                                            <p className="text-justify"><b>Observacion de Recibido:</b> { producto.checkList.observacionDeRecibido }</p>
                                        )
                                    }

                                    {
                                        ((producto.checkList.temperaturaDeTransporte === null)) ? null : (
                                            <p className="text-justify"><b>Temperatura de Transporte:</b> { producto.checkList.temperaturaDeTransporte }</p>
                                        )
                                    }

                                </div>
                            )
                        }
                    </div>,
            /*buttons: {
                right: [{
                    text: 'Cerrar',
                    className: 'danger',
                    action: function () {
                        // Close this popup. Close will always close the current visible one, if one is visible
                        Popup.close();
                    }
                }]
            }*/
        })
    }

    handleChangeSendCorreo = () => {

        let proveedor

        if((this.state.orden !== null) && (this.props.empresas.length > 0)){
            proveedor = this.props.empresas.filter( empresa => empresa.id === this.state.orden.idProveedor)[0]
        }

        if(this.state.sendCorreo === true){
            this.setState({
                sendCorreo: !this.state.sendCorreo,
                correo: null
            })
        }else{
            if(this.state.defaultCorreo === true){
                this.setState({
                    sendCorreo: !this.state.sendCorreo,
                    correo: proveedor.correo
                })
            }else{
                this.setState({
                    sendCorreo: !this.state.sendCorreo,
                    correo: ""
                })
            }
        }

    }

    handleChangeDefaultCorreo = () => {

        let proveedor

        if((this.state.orden !== null) && (this.props.empresas.length > 0)){
            proveedor = this.props.empresas.filter( empresa => empresa.id === this.state.orden.idProveedor)[0]
        }

        if(this.state.defaultCorreo === true){
            this.setState({
                defaultCorreo: !this.state.defaultCorreo,
                correo: ""
            })
        }else{
            this.setState({
                defaultCorreo: !this.state.defaultCorreo,
                correo: proveedor.correo
            })
        }
    }

    handleInputChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    handleCheckListProducto = (nombre, checkList) => {

        let productos = this.state.orden.productos,
            productoArr = productos.filter( producto => producto.nombre === nombre )

        productoArr[0].checkList = checkList

        let newProductos = productos.map( obj => productoArr.find(o => o.nombre === obj.nombre) || obj )

        this.setState({
            orden: {
                ...this.state.orden,
                productos: newProductos
            }
        })

    }

    handleAddIdUsuarioArea = () => {

        this.setState({
            loading: true
        })

        let data ={
            idOrden: this.state.orden.id,
            idUsuarioArea: this.props.usuario.id
        }
        FetchAddIdRecepcionArea(this, data)
    }

    handleChangeProductoToNewOrden = (productos) => {

        let oldOrden = {
            ...this.state.orden
        }

        delete oldOrden['id']
        delete oldOrden['facturas']
        delete oldOrden['idUsuarioArea']
        delete oldOrden['razonDeEntregaParcial']
        delete oldOrden['razonDeFueraDeCondiciones']
        delete oldOrden['passPago']

        let data = {
            idOldOrden: this.state.orden.id,
            idCreador: this.props.usuario.id,
            oldOrden,
            productos
        }

        this.setState({
            loading: true
        })

        FetchChangeProductosToNewOrden(this, data)

    }
    
    render(){

        let creador,
            autorizador,
            proveedor,
            usuarioRecepcion,
            usuarioArea,
            fecha,
            year,
            mes,
            dia,
            valoresTotalesDeProducto,
            valorTotal,
            productosOfFacturasOrden = [],
            idAbreviado,
            yearEmealTracking,
            mesEmealTracking,
            diaEmealTracking,
            horaEmealTracking,
            minutosEmealTracking

        if(this.state.orden !== null){
            fecha = this.state.orden.fecha
            year = fecha.getFullYear()
            mes = (fecha.getMonth()+1) < 10 ? mes = `0${fecha.getMonth()+1}` : mes = `${fecha.getMonth()+1}`
            dia = fecha.getDate() < 10 ? dia = `0${fecha.getDate()}` : dia = `${fecha.getDate()}`

            valoresTotalesDeProducto = this.state.orden.productos.map( producto => producto.cantidad * producto.valorUnitario)
            let reducer = (accumulator, currentValue) => accumulator + currentValue

            if(valoresTotalesDeProducto.length > 0){
                valorTotal = valoresTotalesDeProducto.reduce(reducer)
            }

            if(this.state.orden.facturas !== null){
                let productosOfFacturasOrdenArr = this.state.orden.facturas.map( factura => factura.productos)
                productosOfFacturasOrden = Array.prototype.concat.apply([], productosOfFacturasOrdenArr)
            }

            if(this.state.orden.emailTracking !== null){
                let fechaEmailTracking = this.state.orden.emailTracking.fecha
                yearEmealTracking = fechaEmailTracking.getFullYear()
                mesEmealTracking = (fechaEmailTracking.getMonth()+1) < 10 ? mesEmealTracking = `0${fechaEmailTracking.getMonth()+1}` : mesEmealTracking = `${fechaEmailTracking.getMonth()+1}`
                diaEmealTracking = fechaEmailTracking.getDate() < 10 ? diaEmealTracking = `0${fechaEmailTracking.getDate()}` : diaEmealTracking = `${fechaEmailTracking.getDate()}`
                horaEmealTracking = fechaEmailTracking.getHours() < 10 ? horaEmealTracking = `0${fechaEmailTracking.getHours()}` : horaEmealTracking = `${fechaEmailTracking.getHours()}`
                minutosEmealTracking = fechaEmailTracking.getMinutes() < 10 ? minutosEmealTracking = `0${fechaEmailTracking.getMinutes()}` : minutosEmealTracking = `${fechaEmailTracking.getMinutes()}`
            }

        }

        if((this.state.orden !== null) && (this.props.usuarios.length > 0)){
            creador = this.props.usuarios.filter( usuario => usuario.id === this.state.orden.idCreador)[0]
            autorizador = this.props.usuarios.filter( usuario => usuario.id === this.state.orden.idAutorizador)[0]
            usuarioRecepcion = this.props.usuarios.filter( usuario => usuario.id === this.state.orden.idUsuarioRecepcion)[0]
            usuarioArea = this.props.usuarios.filter( usuario => usuario.id === this.state.orden.idUsuarioArea)[0]
        }

        if((this.state.orden !== null) && (this.props.empresas.length > 0)){
            proveedor = this.props.empresas.filter( empresa => empresa.id === this.state.orden.idProveedor)[0]
        }

        if(this.state.orden !== null){
            idAbreviado = this.state.orden.id.substr(this.state.orden.id.length - 6);
        }

        return(
            <DashboardComponent >
                <div className="row mx-5">
                    <div className="col-12 d-none-print">
                        <Link to="/dashboard/admin-compras/ordenes">
                            <button style={{backgroundColor: 'transparent', border: 'none', color: '#2984be'}}><FontAwesomeIcon icon="arrow-left" style={{fontSize: '25px'}} /></button>
                        </Link>
                    </div>
                    <div className="col-12">
                        <h1 className="text-center">Orden Compra</h1>
                    </div>

                    {
                        this.state.orden !== null ? (
                            <div className="col-12 mx-auto">
                                <QRCreator titulo="No. Orden de Compra" idOrden={this.state.orden.id}/>
                            </div>
                        ) : null
                    }

                    {
                        ((this.state.orden !== null) && (idAbreviado !== undefined)) ? (
                            <div className="col-12 mx-auto">
                                <p className="text-center" style={{fontSize: '25px'}}><b>No. Orden Compra Abreviado:</b> {idAbreviado}</p>
                            </div>
                        ) : null
                    }

                    {
                        this.state.orden !== null ? (
                            <div className="col-12 col-md-6 col-lg-4 mx-auto">
                                <p className="text-center"><b>Estado:</b> {this.state.orden.estado}</p>
                            </div>
                        ) : null
                    }

                    {
                        ((this.state.orden !== null) && (this.state.orden.razonDeFueraDeCondiciones !== null)) ? (
                            <div className="col-12 col-md-6 col-lg-4 mx-auto">
                                <p className="text-center"><b>Razón de Estado Fuera de Condiciones:</b> {this.state.orden.razonDeFueraDeCondiciones}</p>
                            </div>
                        ) : null
                    }

                    {
                        ((this.state.orden !== null) && (this.props.usuarios.length > 0) && (creador !== undefined)) ? (
                            <div className="col-12 col-md-6 col-lg-4 mx-auto">
                                <p className="text-center"><b>Creador:</b> {creador.nombre} {creador.apellidos}</p>
                            </div>
                        ) : null
                    }

                    {
                        ((this.state.orden !== null) && (this.props.usuarios.length > 0)) ? (
                            <div className="col-12 col-md-6 col-lg-4 mx-auto">
                                <p className="text-center"><b>Autorizador:</b> {autorizador.nombre} {autorizador.apellidos}</p>
                            </div>
                        ) : null
                    }

                    {
                        ((this.state.orden !== null) && (this.props.empresas.length > 0) && (proveedor !== undefined)) ? (
                            <div className="col-12 col-md-6 col-lg-4 mx-auto">
                                <p className="text-center"><b>Proveedor:</b> {proveedor.nombre_comercial}</p>
                            </div>
                        ) : (
                            <div className="col-12 col-md-6 col-lg-4 mx-auto">
                                <p className="text-center"><b>Proveedor:</b> {this.state.orden !== null ? this.state.orden.proveedor : ""}</p>
                            </div> 
                        )
                    }

                    {
                        ((this.state.orden !== null) && (this.props.usuarios.length > 0) && (usuarioRecepcion !== undefined)) ? (
                            <div className="col-12 col-md-6 col-lg-4 mx-auto">
                                <p className="text-center"><b>Responsable de Recepcion:</b> {usuarioRecepcion.nombre} {usuarioRecepcion.apellidos}</p>
                            </div>
                        ) : null
                    }

                    {
                        ((this.state.orden !== null) && (this.props.usuarios.length > 0) && (usuarioArea !== undefined)) ? (
                            <div className="col-12 col-md-6 col-lg-4 mx-auto">
                                <p className="text-center"><b>Responsable de Recepcion en Área:</b> {usuarioArea.nombre} {usuarioArea.apellidos}</p>
                            </div>
                        ) : null
                    }

                    {
                        ((this.state.orden !== null) && (this.state.orden.observaciones !== null)) ? (
                            <div className="col-12 col-md-6 col-lg-4 mx-auto">
                                <p className="text-center"><b>Observaciones:</b> {this.state.orden.observaciones}</p>
                            </div>
                        ) : null
                    }

                    {
                        this.state.orden !== null ? (
                            <div className="col-12 col-md-6 col-lg-4 mx-auto">
                                <p className="text-center"><b>Fecha de Creación:</b> {dia}/{mes}/{year}</p>
                            </div>
                        ) : null
                    }

                    {
                        (this.state.orden !== null) && (this.state.orden.emailTracking !== null) ? (
                            <div className="col-12 col-md-6 col-lg-4 mx-auto">
                                <p className="text-center"><b>Envío de correo electrónico de orden de compra:</b> {this.state.orden.emailTracking.estado === 'success' ? <p>Exitoso <FontAwesomeIcon icon="check" style={{ color: '#77FF34', marginLeft: '5px' }} /> en fecha: {diaEmealTracking}/{mesEmealTracking}/{yearEmealTracking} a la {horaEmealTracking}:{minutosEmealTracking} horas {this.state.orden.emailTracking.correo !== null ? `al correo ${this.state.orden.emailTracking.correo}` : null}</p>:<p>Fallo en el envío <FontAwesomeIcon icon="times" style={{ color: '#FF2304', marginLeft: '5px' }} /> en fecha: {diaEmealTracking}/{mesEmealTracking}/{yearEmealTracking} a la {horaEmealTracking}:{minutosEmealTracking} horas {this.state.orden.emailTracking.correo !== null ? `al correo ${this.state.orden.emailTracking.correo}` : null}</p>}</p>
                            </div>
                        ) : null
                    }

                    {
                        ((this.state.orden !== null) && (this.state.orden.razonDeEntregaParcial !== null)) ? (
                            <div className="col-12 col-md-6 col-lg-4 mx-auto">
                                <p className="text-center"><b>Razón de Entrega Parcial:</b> {this.state.orden.razonDeEntregaParcial}</p>
                            </div>
                        ) : null
                    }

                    {
                        this.state.orden !== null ? (
                            <div className="table-responsive">
                                <table className="table text-center table-bordered table-hover table-sm">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Nombre Producto</th>
                                            <th scope="col" className="d-none d-md-table-cell">Cantidad</th>
                                            <th scope="col" className="d-none d-md-table-cell">Valor Unitario</th>
                                            <th scope="col">Valor Total</th>
                                            <th scope="col" className="d-none-print">Acciones</th>
                                            {
                                                ((this.state.orden !== null) && ((this.state.orden.estado === "Autorizado") && (this.state.orden.productos.length > productosOfFacturasOrden.length)) && (this.state.displayAddFactura === true )) ? (
                                                    <th scope="col" className="d-none-print">Check List de Producto</th>
                                                ): null
                                            }      

                                            {/*<th scope="col">Acciones</th>*/}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.orden.productos.map( (producto,i) => {

                                                let valorTotal = producto.cantidad * producto.valorUnitario

                                                return(
                                                    <tr key={i}>
                                                        <td className="align-middle">{producto.nombre}</td>
                                                        <td className="d-none d-md-table-cell align-middle">{producto.cantidad}</td>
                                                        <td className="d-none d-md-table-cell align-middle">Q. {formatterTwoDecimals.format(producto.valorUnitario)}</td>
                                                        <td className="align-middle">Q. {formatterTwoDecimals.format(valorTotal)}</td>
                                                        <td className="d-none-print align-middle">
                                                            <button type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}} onClick={(event)=>{
                                                                this.handleClickViewMoreInfoProducto(producto)
                                                            }}>
                                                                <FontAwesomeIcon icon="eye" />
                                                            </button>
                                                        </td>
                                                        {
                                                            ((this.state.orden !== null) && ((this.state.orden.estado === "Autorizado") && (this.state.orden.productos.length > productosOfFacturasOrden.length)) && (this.state.displayAddFactura === true )) ? (
                                                                <td className="align-middle">
                                                                    <CheckList producto={producto} handleCheckListProducto={this.handleCheckListProducto} />
                                                                </td>
                                                            ): null
                                                        }
                                                    </tr>
                                                )

                                            })
                                        }
                                        <tr>
                                            {/*<td colSpan="4" className="d-none d-md-table-cell"><b>Valor de Orden Total</b></td>*/}
                                            <td colSpan="3" className="d-none d-md-table-cell"><b>Valor de Orden Total</b></td>
                                            <td colSpan="1" className="d-md-none"><b>Valor de Orden Total</b></td>
                                            <td><b>Q. {formatterTwoDecimals.format(valorTotal)}</b></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        ) : null
                    }

                    {
                        ((this.state.orden !== null) && (this.state.orden.facturas !== null)) ? (
                            <div className="table-responsive">
                                <h2 className="text-center">Facturas</h2>
                                <table className="table text-center table-bordered table-hover table-sm">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">No. Factura</th>
                                            <th scope="col">Concepto</th>
                                            <th scope="col">Valor</th>
                                            <th scope="col" className="d-none-print">Documento Factura</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.orden.facturas.map ( (factura, i) => {

                                            let productosOfFacturas =  this.state.orden.productos.filter( producto => factura.productos.includes(producto.nombre) )
                                            let valoresOfProductosFacturas = productosOfFacturas.map( producto => producto.valorUnitario * producto.cantidad )

                                            let valorTotalFactura = 0;

                                            valoresOfProductosFacturas.forEach( valor => {
                                                valorTotalFactura += valor;
                                            });

                                            return(
                                                <tr key={i}>
                                                    <td>{factura.noFactura}</td>
                                                    <td>{factura.concepto}</td>
                                                    <td>{formatterTwoDecimals.format(valorTotalFactura)}</td>
                                                    <td className="d-none-print" >{factura.factura === null ? (
                                                        <div className="col-12">
                                                            <div className="form-group text-center">
                                                                <label htmlFor="archivo">Selecciona la Factura </label>
                                                                <input type="file" id="archivo" className="form-control-file" accept=".pdf" onChange={this.handleInputFileChangeFactura} />
                                                            </div>
                                                            {
                                                                this.state.factura !== null ? <button style={{backgroundColor: 'transparent', border: 'none', color: '#2984be'}}><FontAwesomeIcon icon="file-upload" style={{fontSize: '25px'}} onClick={ () => this.handleAddFactura(factura.noFactura) } /></button> : null
                                                            }
                                                        </div>
                                                    ) : (
                                                        <a download="factura.pdf" href={factura.factura}><button style={{backgroundColor: 'transparent', border: 'none', color: '#2984be'}}><FontAwesomeIcon icon="file-download" style={{fontSize: '25px'}} /></button></a>
                                                    )}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        ) : null
                    }

                    {
                        ((this.state.orden !== null) && (this.state.displayCambiarProductos === true)) ? (
                            <ChangeProductosToNewOrden productos={this.state.orden.productos} handleChangeProductoToNewOrden={this.handleChangeProductoToNewOrden} />
                        ) : null
                    }


                    {
                        this.state.loading === true ? (
                            <Loader />
                        ) : null
                    }

                    {/*
                        this.state.orden !== null ? (
                            <div className="col-12 d-flex d-none-print" style={{ marginTop: '15px', marginBottom: '15px' }}>
                                <button className="btn btn-outline-info mx-auto" onClick={ () => window.print() }>Imprimir Orden de Compra {<FontAwesomeIcon icon="print" />}</button>
                            </div>
                        ) : null
                    */}

                    {
                       this.state.orden !== null && (
                           <PrintOrdenCompra className="d-none" ref={el => (this.componentRef = el)} orden={this.state.orden} empresas={this.props.empresas} proveedor={proveedor} valorDeOrdenTotal={valorTotal} fechaDeCreacionOrden={{
                               dia,
                               mes,
                               year
                           }} />
                       ) 
                    }
                    <ReactToPrint 
                        content={() => this.componentRef} 
                        onBeforeGetContent={()=>{
                            let ordenCompra = document.querySelector('#printOrdenCompra')
                            ordenCompra.classList.remove('d-none')
                        }}
                        onAfterPrint={
                            ()=>{
                                let ordenCompra = document.querySelector('#printOrdenCompra')
                                ordenCompra.classList.add('d-none')
                            }
                        }
                        >
                        <PrintContextConsumer>
                            {({ handlePrint }) => (
                                <div className="col-12 d-flex d-none-print" style={{ marginTop: '15px', marginBottom: '15px' }}>
                                    <button className="btn btn-outline-info mx-auto" onClick={handlePrint}>Imprimir Orden de Compra {<FontAwesomeIcon icon="print" />}</button>
                                </div>
                            )}
                        </PrintContextConsumer>
                    </ReactToPrint>


                    {
                        ((this.state.orden !== null) && (this.state.displayCambiarProductos === false) && (this.state.orden.passPago === null)) ? (
                            <div className="col-12 d-flex d-none-print" style={{ marginTop: '15px', marginBottom: '15px' }}>
                                <button className="btn btn-outline-info mx-auto" onClick={ () => this.setState({
                                    displayCambiarProductos: true
                                }) }>Cambiar Productos a una Nueva Orden</button>
                            </div>
                        ) : null
                    }

                    {
                        ((this.state.orden !== null) && (this.state.orden.passPago !== null)) ? (
                            <div className="col-12 d-flex d-none-print" style={{ marginTop: '15px', marginBottom: '15px' }}>
                                <Link className="btn btn-outline-info mx-auto" to={`/dashboard/admin-compras/pass-pago/${this.state.orden.passPago.id}`}>
                                    Ver Contraseña de Pago {<FontAwesomeIcon icon="list-alt" />}
                                </Link>
                            </div>
                        ) : null
                    }

                    {
                       ((this.state.orden !== null) && (this.state.orden.estado === "Pendiente de Autorizacion") && ((autorizador !== undefined) &&(this.props.usuario.id === autorizador.id) )) ? (
                        <div className="mx-auto d-none-print">
                            <div className="form-group" style={{marginTop: '15px'}}>
                                <div className="row">
                                    <p className="col-lg-6 col-12 text-center"><b>Mandar Por Correo</b></p>
                                    <label className="mx-auto">
                                    <Toggle
                                        id="correoSend"
                                        defaultChecked={this.state.sendCorreo}
                                        onChange={this.handleChangeSendCorreo} />
                                    </label>
                                </div>
                            </div>
                        </div>
                       ) : null
                    }

                    {
                       ((this.state.orden !== null) && (this.state.orden.estado === "Pendiente de Autorizacion") && ((autorizador !== undefined) &&(this.props.usuario.id === autorizador.id) )) ? (
                        <div className="mx-auto d-none-print">
                            {
                                ((this.state.sendCorreo === true) && (this.state.orden.idProveedor !== null)) ? (
                                    <div className="form-group" style={{marginTop: '15px'}}>
                                        <div className="row">
                                            <p className="col-lg-6 col-12 text-center"><b>Usar Correo por Defecto</b></p>
                                            <label className="mx-auto">
                                            <Toggle
                                                id="correoDefault"
                                                defaultChecked={this.state.defaultCorreo}
                                                onChange={this.handleChangeDefaultCorreo} />
                                            </label>
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>
                       ) : null

                    }

                    {
                       ((this.state.orden !== null) && (this.state.orden.estado === "Pendiente de Autorizacion") && ((autorizador !== undefined) &&(this.props.usuario.id === autorizador.id) )) ? (
                            <div className="mx-auto d-none-print">
                                {
                                    ((this.state.sendCorreo === true) && (this.state.defaultCorreo === false)) ? (
                                        <div className="form-group">
                                            <label htmlFor="correo">Coloca el Correo a enviar Orden de Compra</label>
                                            <input type="mail" id="correo" className="form-control" onChange={this.handleInputChange} required />
                                        </div>
                                    ) : null
                                }
                            </div>
                        ) : null
                    }

                    {
                        ((this.state.orden !== null) && (this.state.orden.estado === "Pendiente de Autorizacion") && ((autorizador !== undefined) &&(this.props.usuario.id === autorizador.id) )) ? (
                            <div className="form-group col-12 d-none-print" style={{marginTop: '15px'}}>
                                <div className="mx-auto text-center mb-5">
                                    <div className="btn-group" role="group" aria-label="AutorizarOrden">
                                        <button type="button" className="btn btn-outline-success" onClick={ () => {
                                            let estado = "Autorizado"
                                            this.handleUpdateStateOrden(estado)
                                        }}>Autorizar Orden</button>
                                        <button type="button" className="btn btn-outline-danger" onClick={ () => {
                                            let estado = "No Autorizado"
                                            this.handleUpdateStateOrden(estado)
                                        }}>No Autorizar Orden</button>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }

                    {
                        ((this.state.orden !== null) && ((this.state.orden.estado === "Autorizado") && (this.state.orden.productos.length > productosOfFacturasOrden.length)) && (this.state.displayAddFactura === false )) ? (
                            <div className="mx-auto text-center mb-5 d-none-print">
                                <button className="btn btn-outline-info mx-auto text-center" onClick={ () => {
                                    this.setState({
                                        displayAddFactura: true
                                    })
                                }}>Agregar Facturas y Contraseña de Pago</button>
                            </div>
                        ) : null
                    }

                    {
                      ((this.state.orden !== null) && (this.state.razonDeFueraDeCondicionesDisplay === false) && ((this.state.orden.estado === "Autorizado") && (this.state.orden.productos.length > productosOfFacturasOrden.length))) ? (
                            <div className="col-12 mx-auto text-center mb-5 d-none-print">
                                <button onClick={ () => this.setState({
                                    razonDeFueraDeCondicionesDisplay: true
                                })} className="btn btn-warning">Cambiar Estado a Fuera de Condiciones</button>
                            </div>
                       ) : null
                    }

                    {
                        (this.state.orden !== null) && (this.state.orden.estado === "Autorizado") ? <ReenvioCorreo correoProveedor={proveedor ? proveedor.correo : ""} idOrden={this.state.orden.id} /> : null
                    }

                    {
                        this.state.razonDeFueraDeCondicionesDisplay === true ?
                        (<div className="col-12">
                            <input id="razonDeFueraDeCondiciones" onChange={this.handleInputChange} className="form-control" type="text" placeholder="Escribe la razón" value={this.state.razonDeFueraDeCondiciones}/>
                            <div className="col-12 mx-auto text-center m-5 d-none-print">
                                <button className="btn btn-info mr-1" onClick={ () => this.setState({ razonDeFueraDeCondicionesDisplay: false })}>Cancelar</button>
                                <button onClick={ () => {
                                    if(this.state.razonDeFueraDeCondiciones === ''){

                                        let alert = document.querySelector('#alertFueraDecondiciones')
                                        alert.classList.remove('d-none')

                                        setTimeout( () => {
                                            alert.classList.add('d-none')
                                        }, 3000)

                                    }else{
                                        this.handleUpdateStateOrden("Fuera De Condiciones");
                                        this.setState({
                                            razonDeFueraDeCondicionesDisplay: false 
                                        })
                                    }
                                }} className="btn btn-warning">Cambiar Estado a Fuera de Condiciones</button>
                            </div>
                            <div id="alertFueraDecondiciones" className="alert alert-info mt-5 d-none" role="alert">
                                Tienes que Agregar una Razón por la cual esta Fuera de Condiciones
                            </div>
                        </div>) : null
                    }

                    {
                        ((this.state.orden !== null) && ((this.state.orden.estado === "Autorizado") && (this.state.orden.productos.length > productosOfFacturasOrden.length)) && (this.state.displayAddFactura === true )) ? (
                            <AddFactura productos={this.state.orden.productos} valorTotalProductos={valorTotal} handleSubmitFacturaYPassPago={this.handleSubmitFacturaYPassPago} proveedor={proveedor !== undefined ? proveedor : this.state.orden.proveedor} correoProveedor={proveedor !== undefined ? proveedor.correo : null} productosOfFacturasOrden={productosOfFacturasOrden} productosOfOrden={this.state.orden.productos} />
                        ) : null
                    }

                    {
                        ((this.state.orden !== null) && ((this.state.orden.idUsuarioArea === null) && (this.state.orden.idUsuarioRecepcion !== null))) ? (
                            <div className="col-12 mx-auto text-center mb-5 d-none-print">
                                <button onClick={this.handleAddIdUsuarioArea} className="btn btn-outline-info">Recibir en Área</button>
                            </div>
                        ) : null
                    }

                </div>
                <Popup />
            </DashboardComponent>
        )
    }

}

let mapStateToProps = (state, props) => {
    return {
        usuario: state.logIn.usuario,
        usuarios: state.dataUsers.usuarios,
        empresas: state.dataEmpresas.empresas
    }
}

export default connect(mapStateToProps)(VerOrdenCompra)