let consulta = (orden) => {

  let string;

  let productos = JSON.stringify(orden.productos).replace(/{"/g, '{').replace(/":/g, ':').replace(/,"/g, ',')

  let observaciones = orden.observaciones === "" ? null : orden.observaciones
    
  if(orden.idProveedor === ""){

    string = `mutation{
      ordenesCompraMutation{
        insertOrdenCompra(insertOrdenCompra: {
          idCreador: "${orden.idCreador}"
          idAutorizador: "${orden.idAutorizador}"
          idSucursal: "${orden.idSucursal}"
          proveedor: "${orden.proveedor}"
          observaciones: "${observaciones}"
          fecha: "${orden.fecha}"
          estado: "${orden.estado}"
          productos: ${productos}
        }){
          id
          mensaje
        }
      }
    }`

  }else{
    string = `mutation{
      ordenesCompraMutation{
        insertOrdenCompra(insertOrdenCompra: {
          idCreador: "${orden.idCreador}"
          idAutorizador: "${orden.idAutorizador}"
          idSucursal: "${orden.idSucursal}"
          idProveedor: "${orden.idProveedor}"
          observaciones: "${observaciones}"
          fecha: "${orden.fecha}"
          estado: "${orden.estado}"
          productos: ${productos}
        }){
          id
          mensaje
        }
      }
    }`
  }

  return {
    "query": string
  }

}

export default consulta;
