import GraphQL from './ConsultaGraphQL';

import url from '../../../../../../../../apiService/ApiUrl.js';

function handleError(err, This){
	console.log(`Request failed: ${err}`);
	This.setState({
		mensajeInsertRuta: {
			response: 'failure',
			mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
		}
	})
}

const insertRutaMensajeria = (This, data) => {

	const options = {
		method: 'POST',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(data)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		/*if(orden !== undefined){
			orden.estado = data.estado
			This.props.dispatch({
				type: `CHANGE_DATA_ORDEN_MENSAJERIA`,
				payload: {
					dataOrden: orden
				}
			})
		}*/

		//console.log(res)

		This.setState({
			mensajeInsertRuta: {
				response: 'success',
				mensaje: `${res.data.mensajeriaMutation.insertRutaMensajeria.mensaje} de la ruta con id ${res.data.mensajeriaMutation.insertRutaMensajeria.id}`
			}
		})

	}).then( ()=>{
		setTimeout( ()=>{ 
			This.setState({
				orden: null,
				ordenesDesde: "",
				ordenesHasta: "",
				selectedOptionMensajeros: null,
				nombreRuta: "",
				ordenesRuta: [],
				mensajeInsertRuta: null
			})
		}, 3000)
	})
	.catch(err => handleError(err, This))
	.then( ()=>{
		setTimeout( ()=>{ 
			This.setState({
				orden: null,
				ordenesDesde: "",
				ordenesHasta: "",
				selectedOptionMensajeros: null,
				nombreRuta: "",
				ordenesRuta: [],
				mensajeInsertRuta: null
			})
		}, 3000)
	});

	//termina fetch
}

export default insertRutaMensajeria;
