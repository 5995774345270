import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import MensajeriaTable from '../../../components/MensajeriaTable/index.js';

let ModalView = props => {

    let ruta = props.ruta,
        mensajero = props.dataUsers.usuarios.filter(mensajero => {
        return mensajero.id === ruta.idMensajero
      })

  let fechaDeRuta = new Date(parseInt(ruta.fecha))

  let solicitudesMensajeria = props.dataSolicitudesMensajeria.filter( solicitud => {
    return ruta.ordenesRuta.includes(solicitud.id)
  })

  //Creando la fecha
  let dia
  (fechaDeRuta.getDate()) < 10 ? dia = `0${fechaDeRuta.getDate()}` : dia = `${fechaDeRuta.getDate()}`
  let mes
  (fechaDeRuta.getMonth() + 1) < 10 ? mes = `0${fechaDeRuta.getMonth() + 1}` : mes = `${fechaDeRuta.getMonth() + 1}`
  let year = fechaDeRuta.getFullYear()
  //Fecha
  let fecha = `${dia}-${mes}-${year}`
  //let fecha = `${year}-${mes}-${dia}`
  //Creando Hora
  /*let hora
  (fechaDeRuta.getHours()) < 10 ? hora = `0${fechaDeRuta.getHours()}` : hora = `${fechaDeRuta.getHours()}`
  let minutos
  (fechaDeRuta.getMinutes()) < 10 ? minutos = `0${fechaDeRuta.getMinutes()}` : minutos = `${fechaDeRuta.getMinutes()}`
  //Hora
  let time = `${hora}:${minutos}`*/

  //Termina Fecha de

    return(
      <div className="row text-center">
        <div className="col-12">
          <FontAwesomeIcon icon="route" style={{'fontSize': '400%', 'marginTop': '25px'}} />
          <h1>Información de Ruta</h1>
        </div>
        <div className="col-12" style={{'marginTop': '25px'}}>

            <div className="row">

              <div className="col-md-4 mx-auto">
                <p className="col" id="idOrden" style={{'marginTop': '0px'}}><b>Ruta Id:</b> {ruta.id}</p>
              </div>

              <div className="col-md-4 mx-auto">
                <p className="col" id="personaQueLlamo" style={{'marginTop': '0px'}}><b>Nombre de la Ruta:</b> {ruta.nombreRuta}</p>
              </div>

              <div className="col-md-4 mx-auto">
                <p className="col" id="mensajero" style={{'marginTop': '0px'}}><b>Mensajero:</b> {mensajero[0].nombre} {mensajero[0].apellidos}</p>
              </div>
          
              <div className="col-md-4 mx-auto">
                <p className="col" id="telefono" style={{'marginTop': '0px'}}><b>Estado de Ruta:</b> {ruta.estado}</p>
              </div>
              
              <div className="col-md-4 mx-auto">
                <p className="col" id="fecha" style={{ 'marginTop': '0px' }}><b>Fecha de Ruta:</b> {fecha}</p>
              </div>

            </div>

        </div>

        <div className="col-12">
          <MensajeriaTable typeTable="ModalTable" ruta={ruta} solicitudesMensajeriaRutas={solicitudesMensajeria} handleOpenModal={props.handleOpenModal} usuarios={props.dataUsers.usuarios} empresas={props.dataEmpresas.empresas}
          handleDeleteOrdenMensajeria={props.handleDeleteOrdenMensajeria} handleChangeEstadoSolicitud={props.handleChangeEstadoSolicitud} usuario={props.usuario}
          />
        </div>

        {
          ( (props.usuario.tipo === 'colaborador') && ( props.usuario.permisos.Mensajeria.CheckListRuta === true ) ) ? 
          ( <div className="col-12 col-md">
              <div className="row">
                <button type="button" className="btn btn-outline-info mx-auto" onClick={props.handleCheckList}>Check List Ruta</button>
              </div>
            </div>) : null
        }

        {
          ( (props.usuario.tipo === 'colaborador') && ( props.usuario.permisos.Mensajeria.CreateOrden === true ) ) ? 
          ( <div className="col-12 col-md">
              <div className="row">
                <button type="button" className="btn btn-outline-info mx-auto" onClick={props.handleAddOrdenView}>Crear Orden y Agregarla a la Ruta</button>
              </div>
            </div>) : null
        }

        {
          ruta.estado === "Validado Fuera de Condiciones" ? (
            <div className="col-12 col-md">
              <div className="row">
                <button type="button" className="btn btn-outline-danger mx-auto" onClick={props.handleFinalizarConCondicionesInadecuadas}>Finalizar con Condiciones Inadecuadas</button>
              </div>
            </div>
          ) : null
        }

        {
          ruta.checkList !== null ? (
            <div className="col-12 col-md">
              <div className="row">
                <button type="button" className="btn btn-outline-info mx-auto" onClick={props.handleCheckListView}>Ver Check List Ruta</button>
              </div>
            </div>
          ) : null
        }

      </div> 
    )
}

export default ModalView