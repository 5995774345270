import React, { Component } from 'react';

import { connect } from 'react-redux';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../../../../Modal/Components/Modal.jsx';

import ModalView from './ModalView.jsx';
import CheckList from './CheckList.jsx';

import fetchCheckList from './CheckListFetch/FetchCheckListPromise.js';

import CheckListView from './CheckListView.jsx';

import CancelarOrdenAdministrativa from './CancelarOrdenAdministrativa.jsx';

import FetchCancelEstado from '../FetchCancelEstado/fetchGraphQLCancelEstado.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class index extends Component{

  constructor(props){
    super(props)

    let display = "ModalView"

    if(this.props.orden.tipoModal !== undefined){
        display = this.props.orden.tipoModal
    }

    this.state = {
      display,
      cantidadDeMuestras: this.props.orden.cantidadDeMuestras,
      cantidadDeMuestrasTruly: false,
      observaciones: "",
      checkListMensaje: null,
      cancelMensaje: null
    }

    // This binding is necessary to make `this` work in the callback
    this.handleCheckList = this.handleCheckList.bind(this);
    this.handleCheckListView = this.handleCheckListView.bind(this);
    this.handleChekListData = this.handleChekListData.bind(this);
    this.handleSubmitCheckList = this.handleSubmitCheckList.bind(this);
    this.handleDisplayCancelar = this.handleDisplayCancelar.bind(this);
    this.onSubmitCancelar = this.onSubmitCancelar.bind(this);
  }

  componentDidMount(){

    if(this.props.orden.checkList !== null){

      this.setState({
        cantidadDeMuestrasTruly: this.props.orden.checkList.cantidadDeMuestrasTruly,
        observaciones: this.props.orden.checkList.observaciones === null ? "" : this.props.orden.checkList.observaciones
      })
    }

  }

  handleCheckList(){
    if(this.state.display === "ModalView"){
      this.setState({
        display: "CheckList"
      })
    }else{

      if(this.props.orden.checkList !== null){
        this.setState({
          display: "ModalView",
          cantidadDeMuestrasTruly: this.props.orden.checkList.cantidadDeMuestrasTruly,
          observaciones: this.props.orden.checkList.observaciones,
          checkListMensaje: null
        })
      }else{
        this.setState({
          display: "ModalView",
          cantidadDeMuestrasTruly: false,
          observaciones: "",
          checkListMensaje: null
        })
      }

    }
  }

  handleCheckListView(){
    if(this.state.display === "ModalView"){
      this.setState({
        display: "CheckListView"
      })
    }else{
      this.setState({
        display: "ModalView"
      })
    }
  }

  handleChekListData(event){

    let type = event.target.dataset.valueType

    let setStates = (This, type, value) => {

      let Obj = JSON.parse(`{"${type}": ${value}}`)

      This.setState(Obj)

    }

    if(type === "cantidadDeMuestrasTruly"){
      setStates(this, type, this.state.cantidadDeMuestrasTruly === false ? true : false)
    }else if(type === "observaciones"){
      this.setState({
        observaciones: event.target.value
      })
    }

  }

  handleSubmitCheckList(){

    //Creando la Data del CheckList
    let dataCheckList = {
      ...this.state
    }

    //Eliminando del CheckList el display del modal y el Mensaje
    delete dataCheckList.display
    delete dataCheckList.checkListMensaje
    delete dataCheckList.cantidadDeMuestras
    delete dataCheckList.cancelMensaje

    //Creando el objeto para Validar si el checklist tiene todo true
    let dataCheckListTrueValidate = {
      ...dataCheckList
    }

    //Eliminando de la Validacion del checklist Cantidad de Muestras y Observaciones
    delete dataCheckListTrueValidate.observaciones

    //Creando el Array de valores del objeto para validar
    let valuesOfDataCheckListTrueValidate = Object.values(dataCheckListTrueValidate)

    //filtrando del array los valores que sean true y creando un nuevo array con ellos
    let trueValuesOfDataCheckListTrueValidate = valuesOfDataCheckListTrueValidate.filter( value => {
      return value === true
    })

    //Comparando que el largo de los dos arrays sea el mismo para establecer un estado
    let estado
    if(valuesOfDataCheckListTrueValidate.length === trueValuesOfDataCheckListTrueValidate.length){
      estado = "Finalizado"
    }else{
      estado = "Fuera de Condiciones"
    }

    //Agregando el Nombre de quien revisa al CheckList y asignando null a observaciones si es un array vacio
    dataCheckList.nombreQuienRevisa = `${this.props.usuario.nombre} ${this.props.usuario.apellidos}`
    dataCheckList.observaciones = dataCheckList.observaciones === "" ? dataCheckList.observaciones = null : dataCheckList.observaciones

    let data = {
      idOrden: this.props.orden.id,
      estado,
      checkList: dataCheckList
    }

    //console.log(data)

    fetchCheckList(this, data)

  }

  handleDisplayCancelar(){
    if(this.state.display === "ModalView"){
      this.setState({
        display: "CancelarOrdenAdministrativa"
      })
    }else{
      this.setState({
        display: "ModalView"
      })
    }
  }

  onSubmitCancelar(orden){

    return event => {

      event.preventDefault();
    
      let razonDeCancelacion = document.querySelector('#razonDeCancelacion').value

      let data = {
        id: orden.id,
        estado: 'Cancelado',
        razonDeCancelacion
      }

      FetchCancelEstado(this, data, orden)

      //console.log(data)
      
    }

  }

  render(){

    let modalVisible = this.props.modalVisible,
        handleCloseModal = this.props.handleCloseModal

    return(
        <ModalContainer>
          <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                <img className="brand-modal" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco" />
                <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                  <div></div>
                  <div></div>
                </button>
                </div>

                <div className="modal-body container-fluid">

                  {
                    this.props.orden.ruta !== undefined ? (
                      <button style={{backgroundColor: 'transparent', border: 'none', color: '#2984be'}} onClick={() => {
                        let typeModal = 'rutaMensariaModalView';
                        this.props.handleOpenModal(this.props.orden.ruta, typeModal)
                    }}><FontAwesomeIcon icon="arrow-left" style={{fontSize: '25px'}} /></button>
                    ) : null
                  }

                  {
                    this.state.display === "ModalView" ? <ModalView orden={this.props.orden} dataUsers={this.props.dataUsers} dataEmpresas={this.props.dataEmpresas} usuario={this.props.usuario} handleChangeEstadoSolicitud={this.props.handleChangeEstadoSolicitud} handleOpenModal={this.props.handleOpenModal} handleCheckList={this.handleCheckList} handleCheckListView={this.handleCheckListView} handleCancelar={this.handleDisplayCancelar} /> : null
                  }

                  {
                    this.state.display === "CheckList" ? <CheckList usuario={this.props.usuario} checkListMensaje={this.state.checkListMensaje} cantidadDeMuestras={this.state.cantidadDeMuestras} cantidadDeMuestrasTruly={this.state.cantidadDeMuestrasTruly} observaciones={this.state.observaciones} handleInputChange={this.handleChekListData}  handleCheckList={this.handleCheckList} handleSubmitCheckList={this.handleSubmitCheckList}/> : null
                  }

                  {
                    this.state.display === "CheckListView"  ? <CheckListView cantidadDeMuestras={this.state.cantidadDeMuestras} checkList={this.props.orden.checkList} handleCheckListView={this.handleCheckListView} /> : null
                  }

                  {
                    this.state.display === "CancelarOrdenAdministrativa" ? <CancelarOrdenAdministrativa handleCancelar={this.handleDisplayCancelar} orden={this.props.orden} onSubmitCancelar={this.onSubmitCancelar} cancelMensaje={this.state.cancelMensaje}/> : null
                  }

                  {
                    this.props.mensajeDeCambioDeEstado !== null ? (
                      <div className="row" style={{marginTop: '25px'}}>
                        {
                          (this.props.mensajeDeCambioDeEstado.response === 'success') ? (
                            <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.props.mensajeDeCambioDeEstado.mensaje}</div>
                          ) : (
                            <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.props.mensajeDeCambioDeEstado.mensaje}</div>
                          )
                        }
                      </div>
                    ) : null
                  }
                
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
                </div>
              </div>
            </div>
        </ModalB4>
      </ModalContainer>
    )
  }

}

let mapStateToProps = (state, props) => {
  return {
		conexion: state.logIn.conexion,
    usuario: state.logIn.usuario,
    Modal: state.Modal,
    dataUsers: state.dataUsers,
    dataEmpresas: state.dataEmpresas,
    dataMensajeria: state.dataMensajeria
  }
}

export default connect(mapStateToProps)(index);
