import GraphQL from './ConsultaGraphQL';

import url from '../../../../../../../../apiService/ApiUrl.js';

// JavaScript Document
function handleError(err){
	console.log(`Request failed: ${err}`);
	return 'failure'
}

const deletePlantillaRuta = (This, plantilla) => {
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(plantilla)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	return fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		This.props.dispatch({
			type: 'DELETE_PLANTILLA_RUTA_MENSAJERIA',
			payload: {
				dataPlantilla: plantilla
			}
		})

		return 'success'

		//console.log(res)
		//return res
	})
	.catch(err => handleError(err));

	//termina fetch
}

export default deletePlantillaRuta;
