let consulta = (fechaInicio, fechaFinal) => {

  let string = `{
    appInfo{
      ordenesCompra{
        passPagos(
          fechaInicio: "${fechaInicio}", 
          fechaFinal: "${fechaFinal}"
        ){
          id
          fechaPass
          fechaDePago
          estado
          idProveedor
          proveedor
          productos{
            nombre
            cantidad
            valorUnitario
          }
          facturas{
            noFactura
          }
          emailTracking{
            estado
            fecha
            correo
          }
        }
      }
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
