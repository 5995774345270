// JavaScript Document
import GraphQL from './ConsultaGraphQLResendEmailOrdenCompra';

import url from '../../../../../apiService/ApiUrl.js';

function handleError(err){
	console.log(`Request failed: ${err}`);
}

const FetchResendEmailOrdenCompra = (data) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(data)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	return fetch(url, options)
	.then(res => res.json())
	.then( (res) => {
		//console.log(res)
		return res.data.ordenesCompraMutation.resendEmailOrdenCompra
	})
	.catch(err => handleError(err));

	//termina fetch
}

export default FetchResendEmailOrdenCompra;
