import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Collapse } from 'react-bootstrap';

class Permisos extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapsePermisoAdminUser: true,
            collapsePermisoAdminEmpresa: true,
            collapsePermisoAdminMensajeria: true,
            collapsePermisoCompras: true,
            collapsePermisoProductos: true
        }

		this.handleChecked = this.handleChecked.bind(this);
        this.handleSubChecked = this.handleSubChecked.bind(this);
    }

    showHide(e, type) {
        e.preventDefault();

        this.setState({
            [type]: !this.state[type]
        });
    }

    handleChecked(type){
        if(type === "AdminUI"){
            if(this.props.nuevoUsuario.permisos.AdminUI === null){
                this.props.dispatch({
                    type: 'NEW_USER_HANDLE_CHECKED_PERMISOS',
                    payload: {
                        type,
                        newState: true
                    }
                })
            }else{
                this.props.dispatch({
                    type: 'NEW_USER_HANDLE_CHECKED_PERMISOS',
                    payload: {
                        type,
                        newState: null
                    }
                })
            }
        }else if(type === "AdminUser"){
            if(this.props.nuevoUsuario.permisos.AdminUser === null){
                this.props.dispatch({
                    type: 'NEW_USER_HANDLE_CHECKED_PERMISOS',
                    payload: {
                        type,
                        newState: {
                            ViewUser: false,
                            CreateUser: false,
                            EditUser: false,
                            DeleteUser: false
                        }
                    }
                })
            }else{
                this.props.dispatch({
                    type: 'NEW_USER_HANDLE_CHECKED_PERMISOS',
                    payload: {
                        type,
                        newState: null
                    }
                })
                document.querySelector("#ViewUser").checked = null;
                document.querySelector("#CreateUser").checked = null;
                document.querySelector("#EditUser").checked = null;
                document.querySelector("#DeleteUser").checked = null;
            }
        }else if(type === "AdminEmpresa"){
            if(this.props.nuevoUsuario.permisos.AdminEmpresa === null){
                this.props.dispatch({
                    type: 'NEW_USER_HANDLE_CHECKED_PERMISOS',
                    payload: {
                        type,
                        newState: {
                            ViewEmpresa: false,
                            CreateEmpresa: false,
                            EditEmpresa: false,
                            DeleteEmpresa: false
                        }
                    }
                })
            }else{
                this.props.dispatch({
                    type: 'NEW_USER_HANDLE_CHECKED_PERMISOS',
                    payload: {
                        type,
                        newState: null
                    }
                })
                document.querySelector("#ViewEmpresa").checked = null;
                document.querySelector("#CreateEmpresa").checked = null;
                document.querySelector("#EditEmpresa").checked = null;
                document.querySelector("#DeleteEmpresa").checked = null;
            }
        }else if(type === "Mensajeria"){
            if(this.props.nuevoUsuario.permisos.Mensajeria === null){
                this.props.dispatch({
                    type: 'NEW_USER_HANDLE_CHECKED_PERMISOS',
                    payload: {
                        type,
                        newState: {
                            QRScanner: false,
                            ViewOrden: false,
                            CreateOrden: false,
                            EditOrden: false,
                            DeleteOrden: false,
                            ActOrdenAdmin: false,
                            ActOrdenMuestra: false,
                            CheckListOrden: false,
                            ViewRuta: false,
                            RealizarRuta: false,
                            EditRuta: false,
                            DeleteRuta: false,
                            CheckListRuta: false,
                            ViewPlantillaRuta: false,
                            RealizarPlantillaRuta: false,
                            EditPlantillaRuta: false,
                            DeletePlantillaRuta: false
                        }
                    }
                })
            }else{
                this.props.dispatch({
                    type: 'NEW_USER_HANDLE_CHECKED_PERMISOS',
                    payload: {
                        type,
                        newState: null
                    }
                })
                document.querySelector("#QRScanner").checked = null;
                document.querySelector("#ViewOrden").checked = null;
                document.querySelector("#CreateOrden").checked = null;
                document.querySelector("#EditOrden").checked = null;
                document.querySelector("#DeleteOrden").checked = null;
                document.querySelector("#ActOrdenAdmin").checked = null;
                document.querySelector("#ActOrdenMuestra").checked = null;
                document.querySelector("#CheckListOrden").checked = null;
                document.querySelector("#ViewRuta").checked = null;
                document.querySelector("#RealizarRuta").checked = null;
                document.querySelector("#EditRuta").checked = null;
                document.querySelector("#DeleteRuta").checked = null;
                document.querySelector("#CheckListRuta").checked = null;
                document.querySelector("#ViewPlantillaRuta").checked = null;
                document.querySelector("#RealizarPlantillaRuta").checked = null;
                document.querySelector("#EditPlantillaRuta").checked = null;
                document.querySelector("#DeletePlantillaRuta").checked = null;
            }
        }else if(type === "AdminObjetivos"){
            if(this.props.nuevoUsuario.permisos.AdminObjetivos === null){
                this.props.dispatch({
                    type: 'NEW_USER_HANDLE_CHECKED_PERMISOS',
                    payload: {
                        type,
                        newState: true
                    }
                })
            }else{
                this.props.dispatch({
                    type: 'NEW_USER_HANDLE_CHECKED_PERMISOS',
                    payload: {
                        type,
                        newState: null
                    }
                })
            }
        }else if(type === "Compras"){
            if(this.props.nuevoUsuario.permisos.Compras === null){
                this.props.dispatch({
                    type: 'NEW_USER_HANDLE_CHECKED_PERMISOS',
                    payload: {
                        type,
                        newState: {
                            CreateOrdenCompra: false,
                            AddProductoCompra: false,
                            ViewProductosCompras: false,
                        }
                    }
                })
            }else{
                this.props.dispatch({
                    type: 'NEW_USER_HANDLE_CHECKED_PERMISOS',
                    payload: {
                        type,
                        newState: null
                    }
                })
                document.querySelector("#CreateOrdenCompra").checked = null;
                document.querySelector("#AddProductoCompra").checked = null;
            }
        }else if(type === "Productos"){
            if(this.props.nuevoUsuario.permisos.Productos === null){
                this.props.dispatch({
                    type: 'NEW_USER_HANDLE_CHECKED_PERMISOS',
                    payload: {
                        type,
                        newState: {
                            ViewProducto: false,
                            CreateProducto: false,
                            EditProducto: false,
                            DeleteProducto: false,
                            ViewTipoCliente: false,
                            CreateTipoCliente: false,
                            EditTipoCliente: false,
                            DeleteTipoCliente: false,
                            ViewAseguradora: false,
                            CreateAseguradora: false,
                            EditAseguradora: false,
                            DeleteAseguradora: false,
                        }
                    }
                })
            }else{
                this.props.dispatch({
                    type: 'NEW_USER_HANDLE_CHECKED_PERMISOS',
                    payload: {
                        type,
                        newState: null
                    }
                })
                document.querySelector("#ViewProducto").checked = null;
                document.querySelector("#CreateProducto").checked = null;
                document.querySelector("#EditProducto").checked = null;
                document.querySelector("#DeleteProducto").checked = null;
                document.querySelector("#ViewTipoCliente").checked = null;
                document.querySelector("#CreateTipoCliente").checked = null;
                document.querySelector("#EditTipoCliente").checked = null;
                document.querySelector("#DeleteTipoCliente").checked = null;
                document.querySelector("#ViewAseguradora").checked = null;
                document.querySelector("#CreateAseguradora").checked = null;
                document.querySelector("#EditAseguradora").checked = null;
                document.querySelector("#DeleteAseguradora").checked = null;
            }
        }
    }

    handleSubChecked({ admin, type }){
        if((this.props.nuevoUsuario.permisos[admin][type] === false) || (this.props.nuevoUsuario.permisos[admin][type] === null)){
            this.props.dispatch({
                type: 'NEW_USER_HANDLE_SUBCHECKED_PERMISOS',
                payload: {
                    admin,
                    type,
                    newState: true
                }
            })
        }else{
            this.props.dispatch({
                type: 'NEW_USER_HANDLE_SUBCHECKED_PERMISOS',
                payload: {
                    admin,
                    type,
                    newState: false
                }
            })
        }
    }

    render(){
        return(
            <div className="form-group col-12 mx-auto">
                
                <label htmlFor="permisos" className="col"><b>Permisos:</b></label>

                <div className="row">

                    {/* Empieza Permisos de Administración de Contenido Web */}
                    <div className="col-12 col-md-4 col-lg-3 mx-auto" style={{marginTop: '25px'}}>
                        <button className="btn btn-outline-info" type="button">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="AdminUI" value="AdminUI" onChange={ () => {
                                    let type = "AdminUI"
                                    this.handleChecked(type)
                                }}/>
                                <label className="form-check-label" htmlFor="AdminUI">Administración de Contenido Web</label>
                            </div>
                        </button>
                    </div>
                    {/* Termina Permisos de Administración de Contenido Web */}

                    {/* Empieza Permisos de Administración de Usuarios */}
                    <div className="col-12 col-md-4 col-lg-3 mx-auto" style={{marginTop: '25px'}}>

                        <div className="btn-group" role="group">
                            <button className="btn btn-outline-info" type="button">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="AdminUser" value="AdminUser" onChange={ () => {
                                        let type = "AdminUser"
                                        this.handleChecked(type)
                                    }} />
                                    <label className="form-check-label" htmlFor="AdminUser">Administración de Usuarios</label>
                                </div>
                            </button>
                            <button className="btn btn-outline-info dropdown-toggle" type="button" data-toggle="collapse" data-target="#collapsePermisoAdminUser" aria-expanded="false" aria-haspopup="true" onClick={ (e) => this.showHide(e, 'collapsePermisoAdminUser')}></button>
                        </div>

                        <Collapse in={!this.state.collapsePermisoAdminUser} appear={true} >
                            <div className="collapse" id="collapsePermisoAdminUser">

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="ViewUser" value="ViewUser" disabled={this.props.nuevoUsuario.permisos.AdminUser === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "AdminUser",
                                            type = "ViewUser"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="ViewUser">Ver Usuario</label>
                                </div>
                    
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="CreateUser" value="CreateUser" disabled={this.props.nuevoUsuario.permisos.AdminUser === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "AdminUser",
                                            type = "CreateUser"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="CreateUser">Crear Usuario</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="EditUser" value="EditUser" disabled={this.props.nuevoUsuario.permisos.AdminUser === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "AdminUser",
                                            type = "EditUser"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="EditUser">Editar Usuario</label>
                                </div>
                        
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="DeleteUser" value="DeleteUser" disabled={this.props.nuevoUsuario.permisos.AdminUser === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "AdminUser",
                                            type = "DeleteUser"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="DeleteUser">Eliminar Usuario</label>
                                </div>
                    
                            </div>
                        </Collapse>
                
                        

                    </div>
                    {/* Termina Permisos de Administración de Usuarios */}

                    {/* Empieza Permisos de Administración de Empresas */}
                    <div className="col-12 col-md-4 col-lg-3 mx-auto" style={{marginTop: '25px'}}>

                        <div className="btn-group" role="group">
                            <button className="btn btn-outline-info" type="button">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="AdminEmpresa" value="AdminEmpresa" onChange={ () => {
                                        let type = "AdminEmpresa"
                                        this.handleChecked(type)
                                    }}/>
                                    <label className="form-check-label" htmlFor="AdminEmpresa">Administración de Empresas</label>
                                </div>
                            </button>
                            <button className="btn btn-outline-info dropdown-toggle" type="button" data-toggle="collapse" data-target="#collapsePermisoAdminEmpresa" aria-expanded="false" aria-haspopup="true" onClick={ (e) => this.showHide(e, 'collapsePermisoAdminEmpresa')}></button>
                        </div>

                        <Collapse in={!this.state.collapsePermisoAdminEmpresa} appear={true} >
                            <div className="collapse" id="collapsePermisoAdminEmpresa">

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="ViewEmpresa" value="ViewEmpresa" disabled={this.props.nuevoUsuario.permisos.AdminEmpresa === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "AdminEmpresa",
                                            type = "ViewEmpresa"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="ViewEmpresa">Ver Empresa</label>
                                </div>
                    
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="CreateEmpresa" value="CreateEmpresa" disabled={this.props.nuevoUsuario.permisos.AdminEmpresa === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "AdminEmpresa",
                                            type = "CreateEmpresa"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="CreateEmpresa">Crear Empresa</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="EditEmpresa" value="EditEmpresa" disabled={this.props.nuevoUsuario.permisos.AdminEmpresa === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "AdminEmpresa",
                                            type = "EditEmpresa"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="EditEmpresa">Editar Empresa</label>
                                </div>
                        
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="DeleteEmpresa" value="DeleteEmpresa" disabled={this.props.nuevoUsuario.permisos.AdminEmpresa === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "AdminEmpresa",
                                            type = "DeleteEmpresa"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="DeleteEmpresa">Eliminar Empresa</label>
                                </div>
                    
                            </div>
                        </Collapse>
                
                        

                    </div>
                    {/* Termina Permisos de Administración de Empresas */}

                    {/* Empieza Permisos de Mensajería */}
                    <div className="col-12 col-md-4 col-lg-3 mx-auto" style={{marginTop: '25px'}}>

                        <div className="btn-group" role="group">
                            <button className="btn btn-outline-info" type="button">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="Mensajeria" value="Mensajeria" onChange={ () => {
                                        let type = "Mensajeria"
                                        this.handleChecked(type)
                                    }}/>
                                    <label className="form-check-label" htmlFor="Mensajeria">Mensajería</label>
                                </div>
                            </button>
                            <button className="btn btn-outline-info dropdown-toggle" type="button" data-toggle="collapse" data-target="#collapsePermisoAdminMensajeria" aria-expanded="false" aria-haspopup="true" onClick={ (e) => this.showHide(e, 'collapsePermisoAdminMensajeria')}></button>
                        </div>

                        <Collapse in={!this.state.collapsePermisoAdminMensajeria} appear={true} >
                            <div className="collapse" id="collapsePermisoAdminMensajeria">

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="QRScanner" value="QRScanner" disabled={this.props.nuevoUsuario.permisos.Mensajeria === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Mensajeria",
                                            type = "QRScanner"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="QRScanner">Escaner QR</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="ViewOrden" value="ViewOrden" disabled={this.props.nuevoUsuario.permisos.Mensajeria === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Mensajeria",
                                            type = "ViewOrden"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="ViewOrden">Ver Orden</label>
                                </div>
                    
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="CreateOrden" value="CreateOrden" disabled={this.props.nuevoUsuario.permisos.Mensajeria === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Mensajeria",
                                            type = "CreateOrden"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="CreateOrden">Crear Orden</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="EditOrden" value="EditOrden" disabled={this.props.nuevoUsuario.permisos.Mensajeria === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Mensajeria",
                                            type = "EditOrden"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="EditOrden">Editar Orden</label>
                                </div>
                        
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="DeleteOrden" value="DeleteOrden" disabled={this.props.nuevoUsuario.permisos.Mensajeria === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Mensajeria",
                                            type = "DeleteOrden"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="DeleteOrden">Eliminar Orden</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="ActOrdenAdmin" value="ActOrdenAdmin" disabled={this.props.nuevoUsuario.permisos.Mensajeria === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Mensajeria",
                                            type = "ActOrdenAdmin"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="ActOrdenAdmin">Actualizar Orden Administrativa</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="ActOrdenMuestra" value="ActOrdenMuestra" disabled={this.props.nuevoUsuario.permisos.Mensajeria === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Mensajeria",
                                            type = "ActOrdenMuestra"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="ActOrdenMuestra">Actualizar Orden de Recolección de Muestra</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="CheckListOrden" value="CheckListOrden" disabled={this.props.nuevoUsuario.permisos.Mensajeria === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Mensajeria",
                                            type = "CheckListOrden"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="CheckListOrden">Check List Orden</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="ViewRuta" value="ViewRuta" disabled={this.props.nuevoUsuario.permisos.Mensajeria === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Mensajeria",
                                            type = "ViewRuta"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="ViewRuta">Ver Ruta</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="RealizarRuta" value="RealizarRuta" disabled={this.props.nuevoUsuario.permisos.Mensajeria === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Mensajeria",
                                            type = "RealizarRuta"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="RealizarRuta">Realizar Ruta</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="EditRuta" value="EditRuta" disabled={this.props.nuevoUsuario.permisos.Mensajeria === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Mensajeria",
                                            type = "EditRuta"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="EditRuta">Editar Ruta</label>
                                </div>
                        
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="DeleteRuta" value="DeleteRuta" disabled={this.props.nuevoUsuario.permisos.Mensajeria === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Mensajeria",
                                            type = "DeleteRuta"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="DeleteRuta">Eliminar Ruta</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="CheckListRuta" value="CheckListRuta" disabled={this.props.nuevoUsuario.permisos.Mensajeria === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Mensajeria",
                                            type = "CheckListRuta"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="CheckListRuta">Check List Ruta</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="ViewPlantillaRuta" value="ViewPlantillaRuta" disabled={this.props.nuevoUsuario.permisos.Mensajeria === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Mensajeria",
                                            type = "ViewPlantillaRuta"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="ViewPlantillaRuta">Ver Plantilla de Ruta</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="RealizarPlantillaRuta" value="RealizarPlantillaRuta" disabled={this.props.nuevoUsuario.permisos.Mensajeria === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Mensajeria",
                                            type = "RealizarPlantillaRuta"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="RealizarPlantillaRuta">Realizar Plantilla de Ruta</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="EditPlantillaRuta" value="EditPlantillaRuta" disabled={this.props.nuevoUsuario.permisos.Mensajeria === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Mensajeria",
                                            type = "EditPlantillaRuta"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="EditPlantillaRuta">Editar Plantilla de Ruta</label>
                                </div>
                        
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="DeletePlantillaRuta" value="DeletePlantillaRuta" disabled={this.props.nuevoUsuario.permisos.Mensajeria === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Mensajeria",
                                            type = "DeletePlantillaRuta"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="DeletePlantillaRuta">Eliminar Plantilla de Ruta</label>
                                </div>
                    
                            </div>
                        </Collapse>
                
                        

                    </div>
                    {/* Termina Permisos de Mensajería */}

                    {/* Empieza Permisos de Administración de Objetivos */}
                    <div className="col-12 col-md-4 col-lg-3 mx-auto" style={{marginTop: '25px'}}>
                        <button className="btn btn-outline-info" type="button">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="AdminObjetivos" value="AdminObjetivos" onChange={ () => {
                                    let type = "AdminObjetivos"
                                    this.handleChecked(type)
                                }}/>
                                <label className="form-check-label" htmlFor="AdminObjetivos">Administración de Objetivos</label>
                            </div>
                        </button>
                    </div>
                    {/* Termina Permisos de Administración de Objetivos */}

                    {/* Empieza Permisos de Administración de Compras */}
                    <div className="col-12 col-md-4 col-lg-3 mx-auto" style={{marginTop: '25px'}}>

                        <div className="btn-group" role="group">
                            <button className="btn btn-outline-info" type="button">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="Compras" value="Compras" onChange={ () => {
                                        let type = "Compras"
                                        this.handleChecked(type)
                                    }}/>
                                    <label className="form-check-label" htmlFor="Compras">Administración de Compras</label>
                                </div>
                            </button>
                            <button className="btn btn-outline-info dropdown-toggle" type="button" data-toggle="collapse" data-target="#collapsePermisoCompras" aria-expanded="false" aria-haspopup="true" onClick={ (e) => this.showHide(e, 'collapsePermisoCompras')}></button>
                        </div>

                        <Collapse in={!this.state.collapsePermisoCompras} appear={true} >
                            <div className="collapse" id="collapsePermisoCompras">
                    
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="CreateOrdenCompra" value="CreateOrdenCompra" disabled={this.props.nuevoUsuario.permisos.Compras === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Compras",
                                            type = "CreateOrdenCompra"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="CreateOrdenCompra">Crear Orden de Compra</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="AddProductoCompra" value="AddProductoCompra" disabled={this.props.nuevoUsuario.permisos.Compras === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Compras",
                                            type = "AddProductoCompra"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="AddProductoCompra">Crear Producto de Compra</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="ViewProductosCompras" value="ViewProductosCompras" disabled={this.props.nuevoUsuario.permisos.Compras === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Compras",
                                            type = "ViewProductosCompras"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="ViewProductosCompras">Ver Productos de Compras de Proveedores</label>
                                </div>
                    
                            </div>
                        </Collapse>
                
                        

                    </div>
                    {/* Termina Permisos de Administración de Compras */}

                    {/* Empieza Permisos de Administración de Productos */}
                    <div className="col-12 col-md-4 col-lg-3 mx-auto" style={{marginTop: '25px'}}>

                        <div className="btn-group" role="group">
                            <button className="btn btn-outline-info" type="button">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="Productos" value="Productos" onChange={ () => {
                                        let type = "Productos"
                                        this.handleChecked(type)
                                    }}/>
                                    <label className="form-check-label" htmlFor="Productos">Administración de Productos</label>
                                </div>
                            </button>
                            <button className="btn btn-outline-info dropdown-toggle" type="button" data-toggle="collapse" data-target="#collapsePermisoProductos" aria-expanded="false" aria-haspopup="true" onClick={ (e) => this.showHide(e, 'collapsePermisoProductos')}></button>
                        </div>

                        <Collapse in={!this.state.collapsePermisoProductos} appear={true} >
                            <div className="collapse" id="collapsePermisoProductos">

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="ViewProducto" value="ViewProducto" disabled={this.props.nuevoUsuario.permisos.Productos === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Productos",
                                            type = "ViewProducto"     
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="ViewProducto">Ver Producto</label>
                                </div>
                    
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="CreateProducto" value="CreateProducto" disabled={this.props.nuevoUsuario.permisos.Productos === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Productos",
                                            type = "CreateProducto"   
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="CreateProducto">Crear Producto</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="EditProducto" value="EditProducto" disabled={this.props.nuevoUsuario.permisos.Productos === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Productos",
                                            type = "EditProducto"     
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="EditProducto">Editar Producto</label>
                                </div>
                    
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="DeleteProducto" value="DeleteProducto" disabled={this.props.nuevoUsuario.permisos.Productos === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Productos",
                                            type = "DeleteProducto"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="DeleteProducto">Eliminar Producto</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="ViewTipoCliente" value="ViewTipoCliente" disabled={this.props.nuevoUsuario.permisos.Productos === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Productos",
                                            type = "ViewTipoCliente"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="ViewTipoCliente">Ver Tipo Cliente</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="CreateTipoCliente" value="CreateTipoCliente" disabled={this.props.nuevoUsuario.permisos.Productos === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Productos",
                                            type = "CreateTipoCliente"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="CreateTipoCliente">Crear Tipo Cliente</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="EditTipoCliente" value="EditTipoCliente" disabled={this.props.nuevoUsuario.permisos.Productos === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Productos",
                                            type = "EditTipoCliente"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="EditTipoCliente">Editar Tipo Cliente</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="DeleteTipoCliente" value="DeleteTipoCliente" disabled={this.props.nuevoUsuario.permisos.Productos === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Productos",
                                            type = "DeleteTipoCliente"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="DeleteTipoCliente">Eliminar Tipo Cliente</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="ViewAseguradora" value="ViewAseguradora" disabled={this.props.nuevoUsuario.permisos.Productos === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Productos",
                                            type = "ViewAseguradora"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="ViewAseguradora">Ver Aseguradora</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="CreateAseguradora" value="CreateAseguradora" disabled={this.props.nuevoUsuario.permisos.Productos === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Productos",
                                            type = "CreateAseguradora"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="CreateAseguradora">Crear Aseguradora</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="EditAseguradora" value="EditAseguradora" disabled={this.props.nuevoUsuario.permisos.Productos === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Productos",
                                            type = "EditAseguradora"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="EditAseguradora">Editar Aseguradora</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="DeleteAseguradora" value="DeleteAseguradora" disabled={this.props.nuevoUsuario.permisos.Productos === null ? "disabled" : ""} onChange={ () => {
                                        let admin = "Productos",
                                            type = "DeleteAseguradora"
                                        this.handleSubChecked({ admin, type })
                                    }} />
                                    <label className="form-check-label" htmlFor="DeleteAseguradora">Eliminar Aseguradora</label>
                                </div>

                            </div>
                        </Collapse>
                
                        

                    </div>
                    {/* Termina Permisos de Administración de Productos */}

                </div>
                
                <small id="permisosSmall" className="form-text text-muted">Permisos.</small>
            </div>
        )
    }
}

let mapStateToProps = (state, props) => {
    return {
      nuevoUsuario: state.dataUsers.nuevoUsuario
    }
}
  
export default connect(mapStateToProps)(Permisos);