import React, { Component } from 'react';

import { connect } from 'react-redux';

import DeleteQuestion from '../../../Dashboard/Components/DeleteQuestion';

//Importando Modales de Promociones
import AddPromocion from '../../../WebPage/Components/Promociones/Containers/modals/AddPromocion';

//Importando Usuario y Funciones Usuario
import UserView from '../../../Dashboard/AdminUser/containers/Modals/UserView.jsx';
import UserEdit from '../../../Dashboard/AdminUser/containers/Modals/UserEdit';
import InsertUser from '../../../Dashboard/AdminUser/containers/Modals/InsertUser';
import PassWordEdit from '../../../Dashboard/AdminUser/containers/Modals/PassWordEdit.jsx';

import FetchUpdateUserPromise from '../../../Dashboard/AdminUser/containers/Modals/UserEditFetch/FetchUpdateUserPromise.js';
import FetchNewUserPromise from '../../../Dashboard/AdminUser/containers/Modals/NewUserFetch/FetchNewUserPromise.js';
import FetchUpdatePass from '../../../Dashboard/AdminUser/containers/Modals/ChangePassFetch/FetchUpdatePass.js';

//Importando Empresa y Funciones Empresa
import EmpresaView from '../../../Dashboard/AdminEmpresa/containers/Modals/EmpresaView';
import InsertEmpresa from '../../../Dashboard/AdminEmpresa/containers/Modals/InsertEmpresa.jsx';
import EmpresaEdit from '../../../Dashboard/AdminEmpresa/containers/Modals/EmpresaEdit';

import FetchUpdateEmpresaPromise from '../../../Dashboard/AdminEmpresa/containers/Modals/EmpresaEditFetch/FetchUpdateEmpresaPromise.js';
import FetchNewEmpresaPromise from '../../../Dashboard/AdminEmpresa/containers/Modals/NewEmpresaFetch/FetchNewEmpresaPromise.js';

//Importando Mensajeria y Funciones Mensajeria
//Ordenes
import MensajeriaOrdenesView from '../../../Dashboard/Mensajeria/containers/Modals/MensajeriaOrdenesView';
import MensajeriaOrdenesEdit from '../../../Dashboard/Mensajeria/containers/Modals/MensajeriaOrdenesEdit.jsx';
import MensajeriaOrdenChangeEstadoYCantidadDeMuestras from '../../../Dashboard/Mensajeria/containers/Modals/MensajeriaOrdenChangeEstadoYCantidadDeMuestras.jsx';

import FetchCancelEstado from '../../../Dashboard/Mensajeria/containers/Modals/FetchCancelEstado/fetchGraphQLCancelEstado.js';

import FetchUpdateEsadoSolicitudGraphQL from '../../../Dashboard/Mensajeria/containers/QRScanner/FetchUpdateEstadoSolicitud/fetchGraphQL.js';
import FetchUpdateEsadoSolicitudYCantidadGraphQL from '../../../Dashboard/Mensajeria/containers/Modals/FetchUpdateEstadoYCantidadSolicitud/fetchGraphQLUpdateEstadoYCantidadSolicitud.js';

import FetchUpdateMensajeriaOrdenPromise from '../../../Dashboard/Mensajeria/containers/Modals/MensajeriaOrdenEditFetch/FetchUpdateMensajeriaOrdenPromise.js';
import deleteOrden from '../../../Dashboard/Mensajeria/containers/DeleteOrdenFetch/FetchDeleteOrden.js';

//Rutas
import MensajeriaRutasView from '../../../Dashboard/Mensajeria/containers/Modals/MensajeriaRutasView';
import MensajeriaRutasEdit from '../../../Dashboard/Mensajeria/containers/Modals/MensajeriaRutasEdit.jsx';

import FetchUpdateMensajeriaRutasPromise from '../../../Dashboard/Mensajeria/containers/Modals/RutaEditFetch/fetchGraphQL.js';

//Plantillas Ruta
import MensajeriaPlantillasRutasView from '../../../Dashboard/Mensajeria/containers/Modals/MensajeriaPlantillasRutasView.jsx';
import MensajeriaPlantillaOrdenesRutasView from '../../../Dashboard/Mensajeria/containers/Modals/MensajeriaPlantillaOrdenesRutasView.jsx';

//Importando AdminObjetivos y Funciones AdminObjetivos
import InsertObjMetaTarea from '../../../Dashboard/AdminObjetivos/containers/Modals/InsertObjMetaTarea.jsx';
import EditObjMetaTarea from  '../../../Dashboard/AdminObjetivos/containers/Modals/EditObjMetaTarea.jsx';
import ObjetivoView from '../../../Dashboard/AdminObjetivos/containers/Modals/ObjetivoView.jsx';
import MetaView from '../../../Dashboard/AdminObjetivos/containers/Modals/MetaView.jsx';
import TareaView from '../../../Dashboard/AdminObjetivos/containers/Modals/TareaView.jsx';

//Importando InputChange
import HandleInputChange from '../../../../Service/HandleInputChange';
import HandleInsertInputChange from '../../../../Service/HandleInsertInputChange';

//Fetch Data
import FetchGraphQLDataInfo from '../../../../apiService/FetchGraphQLDataInfo.js';

class ModalUser extends Component {

  constructor(props){
    super(props)

    this.state = {
      insertUserMensaje: null,
      updateUserMensaje: null,
      updateUserContrasenaMensaje: null,
      insertEmpresaMensaje: null,
      updateEmpresaMensaje: null,
      updateOrdenMensajeriaMensaje: null,
      mensajeDeCambioDeEstado: null,
      updateRutaMensajeriaMensaje: null,
      mensajeDeCambioDeEstadoYCantidadDeMuestras: null
    }

    // This binding is necessary to make `this` work in the callback
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSaveUser = this.handleSaveUser.bind(this);
    this.handleSaveEmpresa = this.handleSaveEmpresa.bind(this);
    this.handleSaveNewUser = this.handleSaveNewUser.bind(this);
    this.handleSaveNewEmpresa = this.handleSaveNewEmpresa.bind(this);
    this.handleChangePassWord = this.handleChangePassWord.bind(this);
    this.handleInputChangeInsert = this.handleInputChangeInsert.bind(this);
    //this.setInputRef = this.setInputRef.bind(this);
    this.handleSaveOrdenMensajeria = this.handleSaveOrdenMensajeria.bind(this);
    this.handleChangeEstadoSolicitud = this.handleChangeEstadoSolicitud.bind(this);
    this.handleChangeEstadoSolicitudTable = this.handleChangeEstadoSolicitudTable.bind(this);
    this.handleDeleteOrdenMensajeria = this.handleDeleteOrdenMensajeria.bind(this);
    //this.handleBeforeAddRemoveOrdenesRuta = this.handleBeforeAddRemoveOrdenesRuta.bind(this);
    this.handleAddOrdenesRuta = this.handleAddOrdenesRuta.bind(this);
    this.handleSaveRutaMensajeria = this.handleSaveRutaMensajeria.bind(this);
    this.handleRemoveOrdenesRuta = this.handleRemoveOrdenesRuta.bind(this);
    this.handleChangeEstadoYCantidadDeMuestras = this.handleChangeEstadoYCantidadDeMuestras.bind(this);
    this.onSubmitCancelarMuestras = this.onSubmitCancelarMuestras.bind(this);
	}

  componentDidMount() {
    FetchGraphQLDataInfo(this)
  }

  /*handleBeforeAddRemoveOrdenesRuta(ruta){
    this.props.dataMensajeria.rutasMensajeria.filter( route => {
      return 
    })
  }*/

  handleSaveRutaMensajeria(){
    let ruta = this.props.Modal.modal
    FetchUpdateMensajeriaRutasPromise(this, ruta)
  }

  handleAddOrdenesRuta(solicitudMensajeria){

    let ruta = this.props.Modal.modal

    let ordenesRuta = ruta.ordenesRuta
    ordenesRuta.push(solicitudMensajeria.id)
    //console.log(ruta)
    this.props.dispatch({
      type:'CHANGE_DATA_RUTA_MENSAJERIA',
      payload: {
        dataRuta: ruta
      }
    })

  }

  handleRemoveOrdenesRuta(solicitudMensajeria){

      let ruta = this.props.Modal.modal

      let ordenesRuta = ruta.ordenesRuta

      let newOrdenes = ordenesRuta.filter( order => order !== solicitudMensajeria.id)

      ruta.ordenesRuta = newOrdenes

      this.props.dispatch({
        type:'CHANGE_DATA_RUTA_MENSAJERIA',
        payload: {
          dataRuta: ruta
        }
      })

  }

  handleInputChangeStateRuta(event){
    //let type = event.target.dataset.valueType
    /*if(type === 'nombreRuta'){
      this.setState({
        stateRuta: {
          ...stateRuta,
          nombreRuta: event.target.value
        }
      });
    }*/
  }

  handleDeleteOrdenMensajeria(Orden){
		deleteOrden(this, Orden)
	}

  handleChangeEstadoSolicitud() {

    let orden = this.props.Modal.modal,
        idOrden = orden.id,
        nuevoEstado
    if ((this.props.usuario.colaborador === null) || (this.props.usuario.colaborador.puesto === 'Mensajero')) {

      nuevoEstado = {
        id: idOrden,
        estado: "Entregado a Mensajero"
      }

      FetchUpdateEsadoSolicitudGraphQL(this, nuevoEstado, orden)

    }else if( (this.props.usuario.permisos.Mensajeria.ActOrdenMuestra === true) || (this.props.usuario.permisos.Mensajeria.ActOrdenAdmin === true) ){

      if(this.props.usuario.permisos.Mensajeria.ActOrdenMuestra === true){
        if(orden.tipo === "RecoleccionMuestra"){
  
          nuevoEstado = {
            id: idOrden,
            estado: "Entregado a Laboratorio"
          }
    
          FetchUpdateEsadoSolicitudGraphQL(this, nuevoEstado, orden)
    
        }
      }

      if(this.props.usuario.permisos.Mensajeria.ActOrdenAdmin === true){
        if(orden.tipo === "Administrativa"){
          nuevoEstado = {
          id: idOrden,
          estado: "Finalizado"
          }
      
          FetchUpdateEsadoSolicitudGraphQL(this, nuevoEstado, orden)
        }
      }
  
  
    }

  }

  handleChangeEstadoSolicitudTable(orden) {

    let idOrden = orden.id,
        nuevoEstado
    if ((this.props.usuario.colaborador === null) || (this.props.usuario.colaborador.puesto === 'Mensajero')) {

      nuevoEstado = {
        id: idOrden,
        estado: "Entregado a Mensajero"
      }

      FetchUpdateEsadoSolicitudGraphQL(this, nuevoEstado, orden)

    }else if( (this.props.usuario.permisos.Mensajeria.ActOrdenMuestra === true) || (this.props.usuario.permisos.Mensajeria.ActOrdenAdmin === true) ){

      if(this.props.usuario.permisos.Mensajeria.ActOrdenMuestra === true){
        if(orden.tipo === "RecoleccionMuestra"){
          nuevoEstado = {
            id: idOrden,
            estado: "Entregado a Laboratorio"
          }
    
          FetchUpdateEsadoSolicitudGraphQL(this, nuevoEstado, orden)
    
        }
      }

      if(this.props.usuario.permisos.Mensajeria.ActOrdenAdmin === true){
        if(orden.tipo === "Administrativa"){
  
          nuevoEstado = {
          id: idOrden,
          estado: "Finalizado"
          }
      
          FetchUpdateEsadoSolicitudGraphQL(this, nuevoEstado, orden)
  
        }
      }

		}

  }

  handleSaveOrdenMensajeria(){

    let ordenJSON = this.props.Modal.modal;

    //console.log(ordenJSON)

    FetchUpdateMensajeriaOrdenPromise(this, ordenJSON)

  }

  handleSaveUser(){

    let usuarioJSON = this.props.Modal.modal;

    //console.log(usuarioJSON)

    FetchUpdateUserPromise(this, usuarioJSON)

  }

  handleSaveEmpresa(){

    let empresaJSON = this.props.Modal.modal;

    //console.log(empresaJSON)

    FetchUpdateEmpresaPromise(this, empresaJSON)

    //FetchUpdateUserPromise(this, usuarioJSON)

  }

  handleSaveNewUser(event){

    event.preventDefault()

    let usuarioJSON = this.props.dataUsers.nuevoUsuario;

    //console.log(usuarioJSON)

    FetchNewUserPromise(this, usuarioJSON)

  }

  handleSaveNewEmpresa(){

    let empresaJSON = this.props.dataEmpresas.nuevaEmpresa;

    //console.log(empresaJSON)

    FetchNewEmpresaPromise(this, empresaJSON)

  }

  handleChangePassWord(){
    let newPass = document.querySelector('#NuevaContraseña').value,
        idUser = this.props.Modal.modal.id

    FetchUpdatePass(this, newPass, idUser)

    //console.log(newPass)
  }

  handleInputChange(event){

    HandleInputChange(event, this)

  }

  handleInputChangeInsert(event){

    HandleInsertInputChange(event, this)

  }

  /*setInputRef = (element) => {

    //let type = element.dataset.valueType;

    this.input = element

    /*if(type === 'usuario'){
      this.input = element
    }else if(type === 'nombre'){
      this.input = element
    }*/

    //console.log(this.input.dataset.valueType)

  //}*/

  handleChangeEstadoYCantidadDeMuestras(orden){

    let cantidadDeMuestras = parseInt(document.querySelector('#cantidadDeMuestras').value)

    orden.cantidadDeMuestras = cantidadDeMuestras

    let idOrden = orden.id,
        nuevoEstado
    if ((this.props.usuario.colaborador === null) || (this.props.usuario.colaborador.puesto === 'Mensajero')) {

      nuevoEstado = {
        id: idOrden,
        estado: "Entregado a Mensajero",
        cantidadDeMuestras
      }

      //console.log(nuevoEstado)

      FetchUpdateEsadoSolicitudYCantidadGraphQL(this, nuevoEstado, orden)

    }else if( this.props.usuario.permisos.Mensajeria.ActOrdenMuestra === true ){

      nuevoEstado = {
        id: idOrden,
        estado: "Entregado a Laboratorio",
        cantidadDeMuestras
      }

      //console.log(nuevoEstado)

      FetchUpdateEsadoSolicitudYCantidadGraphQL(this, nuevoEstado, orden)
      
    }

  }

  onSubmitCancelarMuestras(orden){

    return event => {

      event.preventDefault();
    
      let razonDeCancelacion = document.querySelector('#razonDeCancelacion').value

      let data = {
        id: orden.id,
        estado: 'Cancelado',
        razonDeCancelacion
      }

      FetchCancelEstado(this, data, orden)

      //console.log(data)
      
    }

  }

  handleCloseModal(){
    this.props.dispatch({
			type: `CLOSE_MODAL`
    })
    
    this.setState({
      insertUserMensaje: null,
      updateUserMensaje: null,
      updateUserContrasenaMensaje: null,
      insertEmpresaMensaje: null,
      updateEmpresaMensaje: null,
      updateOrdenMensajeriaMensaje: null,
      mensajeDeCambioDeEstado: null,
      updateRutaMensajeriaMensaje: null,
      mensajeDeCambioDeEstadoYCantidadDeMuestras: null
    })

    this.props.dispatch({
      type: 'CLEAN_NEW_EMPRESA'
    })

    this.props.dispatch({
      type: 'CLEAN_NEW_USER'
    })

    //Revisar este Dispatch
    /*this.props.dispatch({
      type: 'CLEAR_FECHAS_RUTA_MENSAJERIA',
      payload: {
        dataRuta: this.props.Modal.modal
      }
    })*/

    /*this.props.dispatch({
			type: `UNFETCH_USERS`,
      payload: {
        dataUsers: []
      }
		})*/

  }

  handleOpenModal(modal, typeModal){
    this.props.dispatch({
			type: `OPEN_MODAL`,
			payload: {
				modal,
				typeModal
			}
		})

  }

  render() {
    let usuarioOEmpresa = this.props.Modal.modal,
        modalVisible = this.props.Modal.visible,
        typeModal = this.props.Modal.typeModal

        if(usuarioOEmpresa === null){
          return null
        }else if(typeModal === 'UserModalView'){

          return <UserView usuario={usuarioOEmpresa} modalVisible={modalVisible} handleCloseModal={this.handleCloseModal}/>

        }else if(typeModal === 'UserModalEdit'){

          return <UserEdit usuario={usuarioOEmpresa} logInUser={this.props.usuario} modalVisible={modalVisible} handleOpenModal={this.handleOpenModal} dataUsers={this.props.dataUsers} handleCloseModal={this.handleCloseModal} handleInputChange={this.handleInputChange} handleSaveUser={this.handleSaveUser} dataEmpresas={this.props.dataEmpresas} updateUserMensaje={this.state.updateUserMensaje}/>

        }else if(typeModal === 'EmpresaModalView'){

          return <EmpresaView empresa={usuarioOEmpresa} modalVisible={modalVisible} handleCloseModal={this.handleCloseModal} handleInputChange={this.handleInputChange} />

        }
        else if(typeModal === 'EmpresaModalEdit'){

          return <EmpresaEdit empresa={usuarioOEmpresa} modalVisible={modalVisible} handleOpenModal={this.handleOpenModal} handleCloseModal={this.handleCloseModal} handleInputChange={this.handleInputChange} handleSaveEmpresa={this.handleSaveEmpresa} dataEmpresas={this.props.dataEmpresas} updateEmpresaMensaje={this.state.updateEmpresaMensaje}/>

        }else if(typeModal === 'UserModalPass'){

          return <PassWordEdit usuario={usuarioOEmpresa} modalVisible={modalVisible} handleCloseModal={this.handleCloseModal} handleChangePassWord={this.handleChangePassWord} updateUserContrasenaMensaje={this.state.updateUserContrasenaMensaje} />

        }else if(typeModal === 'InsertUser'){

          return <InsertUser usuario={this.props.dataUsers.nuevoUsuario} logInUser={this.props.usuario} modalVisible={modalVisible} handleCloseModal={this.handleCloseModal} dataUsers={this.props.dataUsers} handleInputChange={this.handleInputChangeInsert} handleSaveNewUser={this.handleSaveNewUser} dataEmpresas={this.props.dataEmpresas} insertUserMensaje={this.state.insertUserMensaje}/>

        }else if(typeModal === 'InsertEmpresa'){

          return <InsertEmpresa empresa={this.props.dataEmpresas.nuevaEmpresa} modalVisible={modalVisible} handleOpenModal={this.handleOpenModal} handleCloseModal={this.handleCloseModal} handleInputChange={this.handleInputChangeInsert} handleSaveEmpresa={this.handleSaveEmpresa} handleSaveNewEmpresa={this.handleSaveNewEmpresa} dataEmpresas={this.props.dataEmpresas} insertEmpresaMensaje={this.state.insertEmpresaMensaje}/>

        }else if(typeModal === 'ordenMensariaModalView'){

          let orden = usuarioOEmpresa

          return <MensajeriaOrdenesView orden={orden} modalVisible={modalVisible} handleCloseModal={this.handleCloseModal} handleInputChange={this.handleInputChange} dataUsers={this.props.dataUsers} dataEmpresas={this.props.dataEmpresas} handleChangeEstadoSolicitud={this.handleChangeEstadoSolicitud} mensajeDeCambioDeEstado={this.state.mensajeDeCambioDeEstado} handleOpenModal={this.handleOpenModal} usuarioData={this.props.usuario}/>

        }else if(typeModal === 'ordenMensjeriaModalEdit'){

          let orden = usuarioOEmpresa

          return <MensajeriaOrdenesEdit orden={orden} logInUser={this.props.usuario} modalVisible={modalVisible} handleOpenModal={this.handleOpenModal} dataUsers={this.props.dataUsers} dataMensajeria={this.props.dataMensajeria} handleCloseModal={this.handleCloseModal} handleInputChange={this.handleInputChange} handleSaveOrdenMensajeria={this.handleSaveOrdenMensajeria} dataEmpresas={this.props.dataEmpresas} updateOrdenMensajeriaMensaje={this.state.updateOrdenMensajeriaMensaje} />

        }else if(typeModal === 'rutaMensariaModalView'){

          let ruta = usuarioOEmpresa

          return <MensajeriaRutasView ruta={ruta} modalVisible={modalVisible} handleCloseModal={this.handleCloseModal} handleOpenModal={this.handleOpenModal} handleChangeEstadoSolicitud={this.handleChangeEstadoSolicitudTable} handleDeleteOrdenMensajeria={this.handleDeleteOrdenMensajeria} dataUsers={this.props.dataUsers} dataEmpresas={this.props.dataEmpresas} mensajeDeCambioDeEstado={this.state.mensajeDeCambioDeEstado} dataRutas={this.props.dataMensajeria.rutasMensajeria} dataSolicitudesMensajeria={this.props.dataMensajeria.solicitudesMensajeria} usuario={this.props.usuario} />

        }else if(typeModal === 'rutaMensjeriaModalEdit'){

          let ruta = usuarioOEmpresa

          return <MensajeriaRutasEdit ruta={ruta} logInUser={this.props.usuario} modalVisible={modalVisible} handleOpenModal={this.handleOpenModal} dataUsers={this.props.dataUsers} dataEmpresas={this.props.dataEmpresas} dataMensajeria={this.props.dataMensajeria} handleCloseModal={this.handleCloseModal} handleInputChange={this.handleInputChange} handleSaveOrdenMensajeria={this.handleSaveOrdenMensajeria} updateRutaMensajeriaMensaje={this.state.updateRutaMensajeriaMensaje} stateRuta={this.state.stateRuta} handleAddOrdenesRuta={this.handleAddOrdenesRuta} handleRemoveOrdenesRuta={this.handleRemoveOrdenesRuta} handleSaveRutaMensajeria={this.handleSaveRutaMensajeria} />

        }else if(typeModal === 'PlantillaRutaMensariaModalView'){

          let plantillaRuta = usuarioOEmpresa

          //console.log(plantillaRuta)

          return <MensajeriaPlantillasRutasView plantillaRuta={plantillaRuta} modalVisible={modalVisible} handleCloseModal={this.handleCloseModal} handleOpenModal={this.handleOpenModal} handleDeleteOrdenMensajeria={this.handleDeleteOrdenMensajeria} handleInputChange={this.handleInputChange} dataUsers={this.props.dataUsers} dataEmpresas={this.props.dataEmpresas} mensajeDeCambioDeEstado={this.state.mensajeDeCambioDeEstado} dataRutas={this.props.dataMensajeria.rutasMensajeria} dataSolicitudesMensajeria={this.props.dataMensajeria.solicitudesMensajeria} usuario={this.props.usuario} />

        }else if(typeModal === 'mensajeriaPlantillaOrdenesRutasView'){

          let orden = usuarioOEmpresa

          //console.log(orden)

          return <MensajeriaPlantillaOrdenesRutasView orden={orden} modalVisible={modalVisible} handleCloseModal={this.handleCloseModal} dataUsers={this.props.dataUsers} dataEmpresas={this.props.dataEmpresas} />

        }else if(typeModal === 'changeEstadoYCantidadDeMuestras'){

          let orden = usuarioOEmpresa

          return <MensajeriaOrdenChangeEstadoYCantidadDeMuestras orden={orden} modalVisible={modalVisible} handleOpenModal={this.handleOpenModal} handleCloseModal={this.handleCloseModal} handleChangeEstadoYCantidadDeMuestras={this.handleChangeEstadoYCantidadDeMuestras} mensajeDeCambioDeEstadoYCantidadDeMuestras={this.state.mensajeDeCambioDeEstadoYCantidadDeMuestras} onSubmitCancelar={this.onSubmitCancelarMuestras}/>

        }else if(typeModal === 'InsertObjMetaTarea'){

          let data = usuarioOEmpresa

          return <InsertObjMetaTarea data={data} modalVisible={modalVisible} handleOpenModal={this.handleOpenModal} handleCloseModal={this.handleCloseModal} handleInputChange={this.handleInputChangeInsert} />

        }else if(typeModal === 'ObjetivoView'){

          let objetivo = usuarioOEmpresa

          return <ObjetivoView objetivo={objetivo} sucursales={this.props.dataEmpresas.sucursales} modalVisible={modalVisible} handleOpenModal={this.handleOpenModal} handleCloseModal={this.handleCloseModal} handleInputChange={this.handleInputChangeInsert} />

        }else if(typeModal === 'MetaView'){

          let meta = usuarioOEmpresa

          return <MetaView meta={meta} objetivos={this.props.dataAdminObjetivos.objetivos} sucursales={this.props.dataEmpresas.sucursales} modalVisible={modalVisible} handleOpenModal={this.handleOpenModal} handleCloseModal={this.handleCloseModal} handleInputChange={this.handleInputChangeInsert} />

        }else if(typeModal === 'TareaView'){

          let tarea = usuarioOEmpresa

          return <TareaView tarea={tarea} sucursales={this.props.dataEmpresas.sucursales} modalVisible={modalVisible} handleOpenModal={this.handleOpenModal} handleCloseModal={this.handleCloseModal} handleInputChange={this.handleInputChangeInsert} />

        }else if(typeModal === 'EditObjMetaTarea'){

          let data = usuarioOEmpresa

          return <EditObjMetaTarea data={data} modalVisible={modalVisible} handleOpenModal={this.handleOpenModal} handleCloseModal={this.handleCloseModal} />

        }else if(typeModal === 'AddPromocion'){

          return <AddPromocion modalVisible={modalVisible} handleOpenModal={this.handleOpenModal} handleCloseModal={this.handleCloseModal} />

        }else if(typeModal === 'DeleteQuestion'){

          let data = usuarioOEmpresa

          return <DeleteQuestion modalVisible={modalVisible} handleOpenModal={this.handleOpenModal} handleCloseModal={this.handleCloseModal} DeleteFunction={data.DeleteFunction} data={data.data} />


        }else if(typeModal === 'DeleteTipoCliente'){

          let data = usuarioOEmpresa

          return <DeleteQuestion modalVisible={modalVisible} handleOpenModal={this.handleOpenModal} handleCloseModal={this.handleCloseModal} DeleteFunction={data.DeleteFunction} data={data.data} />


        }
        
  }
}

let mapStateToProps = (state, props) => {
  return {
		conexion: state.logIn.conexion,
    usuario: state.logIn.usuario,
    Modal: state.Modal,
    dataUsers: state.dataUsers,
    dataEmpresas: state.dataEmpresas,
    dataMensajeria: state.dataMensajeria,
    dataAdminObjetivos: state.dataAdminObjetivos
  }
}

export default connect(mapStateToProps)(ModalUser);
