import React from 'react';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../../../Modal/Components/Modal.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TableAgregarOrden from '../../containers/Rutas/RealizarRutas/components/TableAgregarOrden.jsx';

import TableOrdenNoAgregada from '../../containers/Rutas/RealizarRutas/components/TableOrdenNoAgregada.jsx';

import Select from 'react-select';

import "react-toggle/style.css" // for ES6 modules

let MensajeriaRutasEdit = props => {
  let ruta = props.ruta,
      rutaString = JSON.stringify(ruta),
      modalVisible = props.modalVisible,
      handleCloseModal = props.handleCloseModal,
      mensajero = props.dataUsers.usuarios.filter(mensajero => {
        return mensajero.id === ruta.idMensajero
      })
      mensajero = mensajero[0]
  let defaultMensajeroValue = { value: mensajero, label: `${mensajero.nombre} ${mensajero.apellidos}` }

  //console.log(ruta)

  let SelectMensajeroArray = props.dataUsers.usuarios.filter( usuario => {
    return usuario.colaborador.puesto === 'Mensajero'
  })

  let SelectMensajero = Array.from(SelectMensajeroArray, mensajero => {
    return { value: mensajero, label: `${mensajero.nombre} ${mensajero.apellidos}`, target: { value: mensajero.id, dataset: { ruta: rutaString, valueType: 'mensajeroRuta' } } } //value: empresa.nombre_comercial
  })

  SelectMensajero.unshift({value: null, label: 'Ninguno'})

  let SelectedMensajero = SelectMensajero.filter( mensajero => {
    let mensajeroReturned
    if(mensajero.value !== null){
      mensajeroReturned = mensajero.value.id === ruta.idMensajero
    }
    return mensajeroReturned
  })

  let fechaDesde = new Date(ruta.fechaRutas.rutasDesde.replace(/-/g, '/')),
				fechaDesdeTimestamp = fechaDesde.setHours(0,0,0,0);
	let fechaHasta = new Date(ruta.fechaRutas.rutasHasta.replace(/-/g, '/')),
        fechaHastaTimestamp = fechaHasta.setHours(23,59,59,0);
              
	let fechaOrdenes = {
		ordenesDesde: fechaDesdeTimestamp,
		ordenesHasta: fechaHastaTimestamp
  }

  //console.log(ruta.ordenesRuta)

  let ordenesAddTableIds = ruta.ordenesRuta,
  ordenesAddTable = props.dataMensajeria.solicitudesMensajeria.filter(function(orden) {
      return ordenesAddTableIds.indexOf(orden.id) !== -1;
  }),
  ordenesRemoveTable = props.dataMensajeria.solicitudesMensajeria.filter( orden => {
      return (ordenesAddTableIds.indexOf(orden.id) === -1) && (orden.estado === "Pendiente de Entrega");
  })

      return(
        <ModalContainer>
          <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                <img className="brand-modal" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco" />
                <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                  <div></div>
                  <div></div>
                </button>
                </div>

                <div className="container-fluid">
                
                  <div className="row text-center">
                    <div className="col-12">
                      <FontAwesomeIcon icon="route" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                      <h1>Id de Ruta: {ruta.id}</h1>
                    </div>

                    <div className="col-12 col-md-10 mx-auto" style={{'marginTop': '25px'}}>
                      <form>
                        <div className="form-row">

                          <div className="col-md-6 col-12 mx-auto" style={{marginTop: '15px'}}>
                            <div className="row" style={{paddingRight: '15px'}}>
                              <label htmlFor="nombreRuta" className="col"><b>Nombre de Ruta:</b></label>
                              <input ref={props.setRef} data-ruta={rutaString} data-value-type="nombreRuta" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" id="nombreRuta" value={ruta.nombreRuta} style={{'top': '-3px'}}/>
                            </div>
                          </div>

                          <div className="col-md-6 col-12 mx-auto">
                            <div className="row">
                              <label htmlFor="react-select-3-input" className="col-lg-6 col-12"><b>Selecciona un Mensajero</b></label>
                              <Select
                                className="col-lg-6 col-12 mx-auto"
                                placeholder="Escoje un Mensajero"
                                defaultValue={defaultMensajeroValue}
                                onChange={props.handleInputChange}
                                options={SelectMensajero}
                              />
                            </div>
                          </div>

                          {/*<div className="col-md-4 col-12 row">
                            <label htmlFor="estado" className="col"><b>estado:</b></label>
                            <select id="estado" ref={props.setRef} data-ruta={rutaString}  className="inputUserVal" onChange={props.handleInputChange} data-value-type="estado" value={ruta.estado}>
                              <option value="En proceso">En proceso</option>
                              <option value="Finalizado">Finalizado</option>
                            </select>
                          </div>*/}

                        </div>

                        <div className="row">
                            <h2 className="col-12 text-center">Ordenes Agregadas a la Ruta</h2>
                            {
                                ordenesAddTable.length === 0 ? (<h4 className="col-12 text-center" style={{paddingTop: '25px', paddingBottom: '25px'}}>No hay ordenes</h4>) : (
                                    <div className="col-12">
                                        <TableAgregarOrden solicitudesMensajeria={ordenesAddTable} handleOpenModal={props.handleOpenModal} usuarios={props.dataUsers.usuarios} empresas={props.dataEmpresas.empresas} usuario={props.logInUser} fechaOrdenes={fechaOrdenes} mensajeroFilter={SelectedMensajero[0]} handleRemoveOrdenesRuta={props.handleRemoveOrdenesRuta}
                                        />
                                    </div>
                                )
                            }
                        </div>
                        <div className="row">
                            <h2 className="col-12 text-center">Ordenes No Agregadas a la Ruta</h2>
                            {
                                ( (ordenesRemoveTable.length === 0) /*|| ((ruta.selectedOptionMensajeros === null) || (ruta.selectedOptionMensajeros.value === null))*/ ) ? (<h4 className="col-12 text-center" style={{paddingTop: '25px', paddingBottom: '25px'}}>No hay ordenes</h4>) : (
                                    <div className="col-12">
                                        <TableOrdenNoAgregada solicitudesMensajeria={ordenesRemoveTable} handleOpenModal={props.handleOpenModal} usuarios={props.dataUsers.usuarios} empresas={props.dataEmpresas.empresas} usuario={props.logInUser} fechaOrdenes={fechaOrdenes} mensajeroFilter={SelectedMensajero[0]} handleAddOrdenesRuta={props.handleAddOrdenesRuta}
                                        />
                                    </div>
                                )
                            }
                        </div>

                      </form>
                    </div>
                  </div>
                  {
                    props.updateRutaMensajeriaMensaje !== null ? (
                      <div className="row" style={{marginTop: '25px'}}>
                        {
                          (props.updateRutaMensajeriaMensaje.response === 'success') ? (
                            <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.updateRutaMensajeriaMensaje.mensaje}</div>
                          ) : (
                            <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.updateRutaMensajeriaMensaje.mensaje}</div>
                          )
                        }
                      </div>
                    ) : null
                  }
                </div>

                <div className="modal-footer row" style={{height: 'auto'}}>

                    <div className="col-12 col-md" style={{marginTop: '15px'}}>
                      <button type="button" className="btn btn-default mx-auto" onClick={props.handleSaveRutaMensajeria}><FontAwesomeIcon icon="save" style={{'fontSize': '150%'}} /></button>
                    </div>

                    <div className="col-12 col-md" style={{marginTop: '15px'}}>
                      <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
                    </div>


                </div>
              </div>
            </div>
        </ModalB4>
      </ModalContainer>
      )
}

export default MensajeriaRutasEdit
