let consulta = (data) => {

  let string = `mutation{
    ordenesCompraMutation{
      addFacturaCompra(idOrden: "${data.idOrden}", noFactura: "${data.noFactura}", factura: "${data.factura}"){
        mensaje
        factura
      }
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
