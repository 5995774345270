import React from 'react';

import { NavLink } from 'react-router-dom'; //Link

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let AdminUser = props => {
  
  if( (Object.entries(props.usuario.permisos.Mensajeria).length > 0) === true){
    return(
      <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Components">
        <a className="nav-link nav-link-collapse collapsed dropdown-toggle" data-toggle="collapse" href="#collapseMensajeria" data-parent="#exampleAccordion" onClick={props.toggleSideOff} >
          <FontAwesomeIcon icon="motorcycle" /> <span className="nav-link-text">Mensajeria</span>
        </a>
        <ul className="sidenav-second-level collapse" id="collapseMensajeria">


          {
            props.usuario.permisos.Mensajeria.QRScanner === true ? (
              <li>
                <NavLink exact to="/dashboard/mensajeria/qr" activeClassName="active" className="nav-link"><FontAwesomeIcon icon="qrcode" /> QRScanner</NavLink>
              </li>
            ) : null
          }

          {
            ((props.usuario.permisos.Mensajeria.ViewOrden === true) || (props.usuario.permisos.Mensajeria.CreateOrden === true) || (props.usuario.permisos.Mensajeria.EditOrden === true) || (props.usuario.permisos.Mensajeria.DeleteOrden === true) || (props.usuario.permisos.Mensajeria.ActOrdenAdmin === true) || (props.usuario.permisos.Mensajeria.ActOrdenMuestra === true) || (props.usuario.permisos.Mensajeria.CheckListOrden === true)) ? (
              <li>
                <a className="nav-link-collapse collapsed dropdown-toggle" data-toggle="collapse" href="#collapseSubMensajeria"onClick={props.toggleSideOff}><FontAwesomeIcon icon="clipboard-list" /><span className="nav-link-text"> Ordenes </span></a>
                <ul className="sidenav-third-level collapse" id="collapseSubMensajeria">

                  {
                    props.usuario.permisos.Mensajeria.CreateOrden === true ? (
                      <li>
                        <NavLink exact to="/dashboard/mensajeria/realizar-orden" activeClassName="active" className="nav-link"><FontAwesomeIcon icon="dolly-flatbed" /> Realizar Orden</NavLink>
                      </li>
                    ) : null
                  }

                  {
                    ((props.usuario.permisos.Mensajeria.ViewOrden === true) || (props.usuario.permisos.Mensajeria.EditOrden === true) || (props.usuario.permisos.Mensajeria.DeleteOrden === true) || (props.usuario.permisos.Mensajeria.ActOrdenAdmin === true) || (props.usuario.permisos.Mensajeria.ActOrdenMuestra === true) || (props.usuario.permisos.Mensajeria.CheckListOrden === true)) ? (
                      <li>
                        <NavLink exact to="/dashboard/mensajeria/ordenes" activeClassName="active" className="nav-link"><FontAwesomeIcon icon="clipboard-list" /> Ver Ordenes</NavLink>
                      </li>
                    ) : null
                  }

                </ul>
              </li>
            ) : null
          }

          {
            ((props.usuario.permisos.Mensajeria.ViewRuta === true) || (props.usuario.permisos.Mensajeria.RealizarRuta === true) || (props.usuario.permisos.Mensajeria.EditRuta === true) || (props.usuario.permisos.Mensajeria.DeleteRuta === true) || (props.usuario.permisos.Mensajeria.CheckListRuta === true) || (props.usuario.permisos.Mensajeria.ViewPlantillaRuta === true) || (props.usuario.permisos.Mensajeria.RealizarPlantillaRuta === true) || (props.usuario.permisos.Mensajeria.EditPlantillaRuta === true) || (props.usuario.permisos.Mensajeria.DeletePlantillaRuta === true)) ? (

              <li>
                <a className="nav-link-collapse collapsed dropdown-toggle" data-toggle="collapse" href="#collapseSubRutasMensajeria"onClick={props.toggleSideOff}><FontAwesomeIcon icon="route" /><span className="nav-link-text"> Rutas </span></a>
                <ul className="sidenav-third-level collapse" id="collapseSubRutasMensajeria">

                  {
                    props.usuario.permisos.Mensajeria.RealizarRuta === true ? (
                      <li>
                        <NavLink exact to="/dashboard/mensajeria/realizar-ruta" activeClassName="active" className="nav-link"><FontAwesomeIcon icon="route" /> Realizar Rutas</NavLink>
                      </li>
                    ) : null
                  }

                  {
                    ((props.usuario.permisos.Mensajeria.ViewRuta === true) || (props.usuario.permisos.Mensajeria.EditRuta === true) || (props.usuario.permisos.Mensajeria.DeleteRuta === true) || (props.usuario.permisos.Mensajeria.CheckListRuta === true)) ? (
                      <li>
                        <NavLink exact to="/dashboard/mensajeria/rutas" activeClassName="active" className="nav-link"><FontAwesomeIcon icon="route" /> Ver Rutas</NavLink>
                      </li>
                    ) : null
                  }

                  {
                    props.usuario.permisos.Mensajeria.RealizarPlantillaRuta === true ? (
                      <li>
                        <NavLink exact to="/dashboard/mensajeria/realizar-plantilla-ruta" activeClassName="active" className="nav-link"><FontAwesomeIcon icon="route" /> Realizar Plantilla de Ruta</NavLink>
                      </li>
                    ) : null
                  }

                  {
                    ((props.usuario.permisos.Mensajeria.ViewPlantillaRuta === true) || (props.usuario.permisos.Mensajeria.EditPlantillaRuta === true) || (props.usuario.permisos.Mensajeria.DeletePlantillaRuta === true)) ? (
                      <li>
                        <NavLink exact to="/dashboard/mensajeria/plantillas-rutas" activeClassName="active" className="nav-link"><FontAwesomeIcon icon="route" />Ver Plantillas de Rutas</NavLink>
                      </li>
                    ) : null
                  }

                </ul>
              </li>
            ) : null
          }

        </ul>
      </li>
    )
  }else{
    return null
  }
}

/*<li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Charts">
  <Link to="/dashboard-admin-user" className="nav-link">
    <FontAwesomeIcon icon="users-cog" />
    <span className="nav-link-text"> Administración de Usuarios</span>
  </Link>
</li>*/


export default AdminUser
