import React from 'react';

import './css/analisisfisicoquimico.css';

const GaleriaAnalisisFisicoQuimico = props => (
	<section id="galeria-empresas-e-industria-analisis-fisico-quimico" className="container-fluid sin-margen-y-padding">
		{props.children}
	</section>
)

export default GaleriaAnalisisFisicoQuimico;
