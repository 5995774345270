import React from 'react';

import './css/DiagnosticoClinico.css';

const DiagnosticoClinico = (props) => (
	<section id="seccion-examenes-clinicos">
		{props.children}
		<div id="mensaje" className="row" style={{'marginRight': '0px'}}>
			<a className="col-12" href="contacto.html" rel="external" data-ajax="false">
			<h3 className="col-12 text-center"><span className="icon-light-bulb"></span> PARA MÁS INFORMACIÓN, CONTÁCTENOS</h3><br />
			</a>
		</div>
	</section>
)

export default DiagnosticoClinico;
