import GraphQL from './ConsultaGraphQL';

import url from '../../../../../../../apiService/ApiUrl.js';

import GraphQLOrdenesMensajeria from '../../../../../../../apiService/FetchGraphQLMensajeria.js';

import GraphQLEditRuta from '../../RutaEditFetch/fetchGraphQL.js';

// JavaScript Document
function handleError(err, This){
	console.log(`Request failed: ${err}`);
	This.setState({
		AddOrdenMensaje: {
			response: 'failure',
			mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
		}
	})
}

const AddOrden = (This, data, ruta) => {
	//console.log(usuarioJSON)
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(data)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {
		//console.log(res)
		//return res
		//console.log(res.data.insertCheckListRutaMensajeria)

		This.setState({
			AddOrdenMensaje: {
				response: 'success',
				mensaje: res.data.mensajeriaMutation.insertSolisitudDeAnalisis.mensaje
			}
		})

		let idOrden = res.data.mensajeriaMutation.insertSolisitudDeAnalisis.id

		ruta.ordenesRuta.push(idOrden)

		//console.log(idOrden)

		//console.log(ruta)

		GraphQLEditRuta(This, ruta)

		//Construyendo la Fecha
		let dateDesde = new Date(parseInt(ruta.fecha))

		let diaDesde 
		(dateDesde.getDate()) < 10 ? diaDesde = `0${dateDesde.getDate()}` : diaDesde = `${dateDesde.getDate()}`
		let mesDesde
        (dateDesde.getMonth() + 1) < 10 ? mesDesde = `0${dateDesde.getMonth() + 1}` : mesDesde = `${dateDesde.getMonth() + 1}`
		let yearDesde = dateDesde.getFullYear()
		//Fecha
		let fechaDesde = `${yearDesde}-${mesDesde}-${diaDesde}`

		//Construyendo la Fecha
		let dateHasta = new Date()

		let diaHasta 
		(dateHasta.getDate()) < 10 ? diaHasta = `0${dateHasta.getDate()}` : diaHasta = `${dateHasta.getDate()}`
		let mesHasta
        (dateHasta.getMonth() + 1) < 10 ? mesHasta = `0${dateHasta.getMonth() + 1}` : mesHasta = `${dateHasta.getMonth() + 1}`
		let yearHasta = dateHasta.getFullYear()
		//Fecha
		let fechaHasta = `${yearHasta}-${mesHasta}-${diaHasta}`

		let fechaToTimeStampDesde = new Date(fechaDesde.replace(/-/g, '/')),
			fechaToTimeStampHasta = new Date(fechaHasta.replace(/-/g, '/')),
			fechaDesdeTimestampString = fechaToTimeStampDesde.setHours(0,0,0,0),
			fechaHastaTimestampString = fechaToTimeStampHasta.setHours(23,59,59,0);
				
		let fechaDesdeString = new Date(parseInt(fechaDesdeTimestampString)),
			fechaHastaString = new Date(parseInt(fechaHastaTimestampString))
		
		GraphQLOrdenesMensajeria(This, fechaDesdeString, fechaHastaString);

		//Termina Construyendo la Fecha

		/*This.props.dispatch({
			type: 'CHANGE_DATA_RUTA_CHECKLIST_MENSAJERIA',
			payload: {
				data: res.data.insertCheckListRutaMensajeria,
				estado: data.estado
			}
		})*/

	})
	.then( () => {
		setTimeout( () => { 
			This.setState({
				instrucciones: "",
				cantidadDeMuestras: 0,
				fechaDeCreacion: "",
				tipo: "RecoleccionMuestra",
				estado: "Entregado a Laboratorio",
				newOrden: {
				  selectedOptionEmpresas: null,
				  selectedOptionUsuarios: null
				},
				AddOrdenMensaje: null
			})
		}, 3000);
	})
	.catch(err => handleError(err, This))
	.then( () => {
		setTimeout( () => { 
			This.setState({
				instrucciones: "",
				cantidadDeMuestras: 0,
				fechaDeCreacion: "",
				tipo: "RecoleccionMuestra",
				estado: "Entregado a Laboratorio",
				newOrden: {
				  selectedOptionEmpresas: null,
				  selectedOptionUsuarios: null
				},
				AddOrdenMensaje: null
			})
		}, 3000);
	});

	//termina fetch
}

export default AddOrden;
