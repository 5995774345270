import GraphQL from './ConsultaGraphQL';

import url from '../../../../../../apiService/ApiUrl.js';

function handleError(err, This){
	console.log(`Request failed: ${err}`);
	This.setState({
		mensajeUpdatePlantillaRuta: {
			response: 'failure',
			mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
		}
	})
}

const updatePlantillaRutaMensajeria = (This, data) => {

	const options = {
		method: 'POST',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(data)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		/*if(orden !== undefined){
			orden.estado = data.estado
			This.props.dispatch({
				type: `CHANGE_DATA_ORDEN_MENSAJERIA`,
				payload: {
					dataOrden: orden
				}
			})
		}*/

		//console.log(res)

		This.setState({
			mensajeUpdatePlantillaRuta: {
				response: 'success',
				mensaje: `${res.data.mensajeriaMutation.updatePlantillaRutaMensajeria.mensaje} de la ruta con id ${res.data.mensajeriaMutation.updatePlantillaRutaMensajeria.id}`
			}
		})

	}).then( ()=>{
		setTimeout( ()=>{ 
			This.setState({
				mensajeUpdatePlantillaRuta: null
			})
		}, 3000)
	})
	.catch(err => handleError(err, This))
	.then( ()=>{
		setTimeout( ()=>{ 
			This.setState({
				mensajeUpdatePlantillaRuta: null
			})
		}, 3000)
	});

	//termina fetch
}

export default updatePlantillaRutaMensajeria;
