import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FetchEditTipoCliente from '../Containers/FetchEditTipoCliente/FetchGraphQLEditTipoCliente';

import FetchGetTipoCliente from '../Containers/FetchGetTipoCliente/FetchGraphQLGetTipoCliente';

import DashboardComponent from '../../../dashboard/components/DashboardComponent.jsx';

let EditTipoCliente = props => {

    const [state, setState] = useState({
        tipoCliente: null,
        editTipoClienteMensaje: null
    });

    useEffect( () => {

        let fetchGetTipoCliente = async () => {
          FetchGetTipoCliente({ setState, state, idTipoCliente: props.match.params.idTipoCliente })
        }

        fetchGetTipoCliente()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let handleInputChange = (event) => {

        event.preventDefault()

        setState({
            ...state,
            tipoCliente: {
                ...state.tipoCliente,
                [event.target.id]: event.target.value
            }
        })

    }

    let handleSubmit = (event) => {

        event.preventDefault()

        FetchEditTipoCliente(setState, state, state.tipoCliente)

    }


    return(
        <DashboardComponent>
            <div className="row mx-5">
                <div className="col-12">
                    <h1 className="text-center">Editar Tipo de Cliente</h1>
                </div>
                <div className="col-12 d-none-print">
                    <Link to="/dashboard/productos/tipo-clientes">
                        <button style={{backgroundColor: 'transparent', border: 'none', color: '#2984be'}}><FontAwesomeIcon icon="arrow-left" style={{fontSize: '25px'}} /></button>
                    </Link>
                </div>
                {
                    state.tipoCliente !== null ? (
                        <form className="mx-auto" onSubmit={handleSubmit}>
                            
                            <div className="mx-auto text-center">
                                <div className="form-group">
                                    <label htmlFor="nombre"><b>Nombre:</b></label>
                                    <input type="text" value={state.tipoCliente.nombre} id="nombre" className="form-control" onChange={handleInputChange} required />
                                </div>
                            </div>

                                <div className="mx-auto text-center mb-4">
                                    <button className="btn btn-outline-info" type="submit">Editar Tipo Cliente</button>
                                </div>

                            {
                                state.editTipoClienteMensaje !== null ? (
                                    <div className="row">
                                        {
                                            (state.editTipoClienteMensaje.response === 'success') ? (
                                                <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{state.editTipoClienteMensaje.mensaje}</div>
                                            ) : (
                                                <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{state.editTipoClienteMensaje.mensaje}</div>
                                            )
                                        }
                                    </div>
                                ) : null
                            }
                        </form>
                    ) : null
                }
            </div>
        </DashboardComponent>
    )
}

export default EditTipoCliente