let consulta = () => {
    let string = `{
        appInfo{
         plantillasRutasMensajeria{
           id
           nombrePlantilla
           ordenes{
             idEmpresa
             idUsuario
             instrucciones
             cantidadDeMuestras
             personaQueLlamo
             telefono
             direccionDeRecepcion
             horaDeOrden
             tipo
             urgente
           }
         }
       }
       }`
  
    /*return {
      "query": string
    }*/

    return string
  
  }
  
  export default consulta;