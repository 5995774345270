import React from 'react';

import { NavLink } from 'react-router-dom'; //Link

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let AdminUser = props => {

  if( (Object.entries(props.usuario.permisos.AdminUser).length > 0) === true){
    return(
      <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right">
        <NavLink exact to="/dashboard/admin-user" activeClassName="active" className="nav-link">
          <FontAwesomeIcon icon="users" />
          <span className="nav-link-text"> Administración de Usuarios</span>
        </NavLink>
      </li>
    )
  }else{
    return null
  }
}

/*<li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Charts">
  <Link to="/dashboard-admin-user" className="nav-link">
    <FontAwesomeIcon icon="users-cog" />
    <span className="nav-link-text"> Administración de Usuarios</span>
  </Link>
</li>*/


export default AdminUser
