let consulta = (idProveedor) => {

  let string = `{
    appInfo{
      ordenesCompra{
        productosCompra(idProveedor: "${idProveedor}"){
          id
          idCreador
          idProveedor
          nombre
          valorUnitario
          NoCatalogo
          descripcion
          observaciones
          tipo
        }
      }
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
