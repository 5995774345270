import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let UsersTable = props => {

  const [usuarioActivo, setUsuarioActivo] = useState('activos')

  let usuarios = props.usuarios

  if(usuarioActivo === 'activos'){
    usuarios = props.usuarios.filter( usuario => usuario.usuario_activo === true)
  }else if(usuarioActivo === 'inactivos'){
    usuarios = props.usuarios.filter( usuario => usuario.usuario_activo === false)
  }

  return(
    <div className="table-responsive">
      {
        props.usuario.permisos.AdminUser.CreateUser === true ? (
          <div className="justify-content-end">
              <button
                onClick={() => {
                    let typeModal = 'InsertUser';
                    props.handleOpenModal(props.nuevoUsuario, typeModal)
                }}
                type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'float': 'right', 'color' : '#2984be'}}>
                Nuevo Usuario <FontAwesomeIcon icon="user-plus" />
              </button>
          </div>
        ) : null
      }

      <div className='col-md-6 col-lg-3 col-12 my-5 mx-auto'>
        Usuarios con Estado:
        <select className="custom-select col-xs-12 mx-auto" value={usuarioActivo} onChange={ (event) => {
          setUsuarioActivo(event.target.value)
        }} id="selectFilterActivo">
          <option value="activos">Activos</option>
          <option value="inactivos">Inactivos</option>
          <option value="todos">Todos</option>
        </select>
      </div>

      {
        ((props.usuario.permisos.AdminUser.ViewUser === true) || (props.usuario.permisos.AdminUser.EditUser === true) || (props.usuario.permisos.AdminUser.DeleteUser === true)) ? (
          <table className="table text-center table-bordered table-hover table-sm">
            <thead className="thead-light">
              <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Apellidos</th>
                <th scope="col" className="d-none d-md-table-cell" >Teléfono</th>
                <th scope="col" className="d-none d-lg-table-cell" >Correo</th>
                <th scope="col" className="d-none d-lg-table-cell" >Activo</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {
                usuarios.map( usuario => {
                  return (
                    <tr key={usuario.id}>
                      {/*<th scope="row">{usuario.nombre}</th>*/}
                      <td>{usuario.nombre}</td>
                      <td>{usuario.apellidos}</td>
                      <td className="d-none d-md-table-cell" >{usuario.telefono}</td>
                      <td className="d-none d-lg-table-cell" >{usuario.email}</td>
                      <td className="d-none d-lg-table-cell" >{usuario.usuario_activo === true ? 'Activo' : 'Inactivo'}</td>
                      <td>

                        {
                          props.usuario.permisos.AdminUser.ViewUser === true ? (
                            <button type="button" onClick={() => {
                                let typeModal = 'UserModalView';
                                props.handleOpenModal(usuario, typeModal)
                            }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#2984be'}}>
                              <FontAwesomeIcon icon="eye" />
                            </button>
                          ) : null
                        }

                        {
                          props.usuario.permisos.AdminUser.EditUser === true ? (
                            <button type="button" onClick={() => {
                                let typeModal = 'UserModalEdit';
                                props.handleOpenModal(usuario, typeModal)
                            }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#2984be'}}>
                              <FontAwesomeIcon icon="edit" />
                            </button>
                          ) : null
                        }

                        {
                          props.usuario.permisos.AdminUser.DeleteUser === true ? (
                            <button onClick={ () => {

                                let typeModal = 'DeleteQuestion';

                                let data = {
                                  DeleteFunction: props.handleDeleteUser,
                                  data: usuario
                                }

                                props.handleOpenModal(data, typeModal)

                              }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#2984be'}}>
                              <FontAwesomeIcon icon="trash-alt" />
                            </button>
                          ) : null
                        }

                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        ) : null
      }
    </div>
  )
}

export default UsersTable
