import GraphQL from './ConsultaGraphQL';

import url from '../../../../../apiService/ApiUrl.js';

// JavaScript Document
function handleError(err){
	console.log(`Request failed: ${err}`);
	return 'failure'
}

const deleteUser = (This, user) => {
	//console.log(newPass, idUser)
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(user)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	return fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		This.props.dispatch({
			type: 'DELETE_USER',
			payload: {
				dataUser: user
			}
		})

		return 'success'

	})
	.catch(err => handleError(err))

	//termina fetch
}

export default deleteUser;
