let consulta = (data) => {

  let string = `mutation UpdateRutasMensajeria {
    mensajeriaMutation {
      updateRutasMensajeria(ruta: {
        id: "${data.id}"
        idMensajero: "${data.idMensajero}"
        nombreRuta: "${data.nombreRuta}"
        ordenesRuta: "${data.ordenesRuta}"
        estado: "${data.estado}"
      }){
        id
        mensaje
      }
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
