/*Empresas E Industrias*/
//import QuienesSomosEmpresasEIndustrias from '../../../WebPage/EmpresasEIndustrias/QuienesSomos/Components/QuienesSomos.jsx';
import EmpresasEIndustriasAnalisisMicrobiologicos from '../../../WebPage/EmpresasEIndustrias/AnalisisMicrobiologico/Components/AnalisisMicrobiologico.jsx';
import EmpresasEIndustriasAnalisisFisicoQuimico from '../../../WebPage/EmpresasEIndustrias/AnalisisFisicoQuimico/Components/AnalisisFisicoQuimico.jsx';
import EmpresasEIndustriasServiciosMedicos from '../../../WebPage/EmpresasEIndustrias/ServiciosMedicos/Components/ServiciosMedicos.jsx';
import EmpresasEIndustriasSaludOcupacional from '../../../WebPage/EmpresasEIndustrias/SaludOcupacional/Containers/SaludOcupacional.jsx';
import EmpresasEIndustriasContacto from '../../../WebPage/EmpresasEIndustrias/Contacto/Components/Contacto.jsx';

/*const QUIENES_SOMOS_EPR_E_IND = {
    component: QuienesSomosEmpresasEIndustrias,
    path: "/empresas-e-indusrias"
}*/

const ANALISIS_MICROBIOLOGICO_EPR_E_IND = {
    component: EmpresasEIndustriasAnalisisMicrobiologicos,
    path: "/empresas-e-indusrias/analisis-microbiologico"
}

const ANALISIS_FISICO_QUIMICO_EPR_E_IND = {
    component: EmpresasEIndustriasAnalisisFisicoQuimico,
    path: "/empresas-e-indusrias/analisis-fisico-quimico"
}

const SERVICIOS_MEDICOS_EPR_E_IND = {
    component: EmpresasEIndustriasServiciosMedicos,
    path: "/empresas-e-indusrias/servicios-medicos"
}

const SALUD_OCUPACIONAL_EPR_E_IND = {
    component: EmpresasEIndustriasSaludOcupacional,
    path: "/empresas-e-indusrias/salud-ocupacional"
}

const CONTACTO_EPR_E_IND = {
    component: EmpresasEIndustriasContacto,
    path: "/empresas-e-indusrias/contacto"
}

const routesEmpEInd = [ ANALISIS_MICROBIOLOGICO_EPR_E_IND, ANALISIS_FISICO_QUIMICO_EPR_E_IND, SERVICIOS_MEDICOS_EPR_E_IND, SALUD_OCUPACIONAL_EPR_E_IND, CONTACTO_EPR_E_IND ]

export default routesEmpEInd