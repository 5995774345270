import React , { useState, useEffect } from 'react';

import { formatterTwoDecimals } from '../../../../MyModules/formatterDecimals';

import { Redirect } from 'react-router';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QRCreator from '../../Components/QRCreator';
import Loader from '../../../Components/SimpleLoader';

import DashboardComponent from '../../dashboard/components/DashboardComponent.jsx';

import FetchOrdenCompraOfPassPago from './FetchOrdenCompraOfPassPago/FetchGraphQLOrdenCompraPassPago';
import FetchUpdateStatePassPago from './FetchUpdateStatePassPago/FetchGraphQLUpdateStatePassPago';

let VerPassPago = props => {

    const [state, setState] = useState({
        ordenCompra: null,
        loading: false,
        mensaje: null
    })

    useEffect(() => {
        setState({
            ...state,
            loading: true
        })
        FetchOrdenCompraOfPassPago(setState, props.match.params.idPassPago, state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let handleChangeStatePassPago = (estado) => {

        let data = {
            idOrden: state.ordenCompra.id,
            idPassPago: state.ordenCompra.passPago.id,
            estado
        }
    
        setState({
            ...state,
            loading: true
        })
    
        FetchUpdateStatePassPago(setState, data, state)
    
    }

    let arrayOfComprasValues = [],
        allComprasValuesFalse = true,
        yearPassPago,
        mesPassPago,
        diaPassPago,
        yearPago,
        mesPago,
        diaPago,
        yearEmealTracking,
        mesEmealTracking,
        diaEmealTracking,
        horaEmealTracking,
        minutosEmealTracking

    if(props.usuario !== null){
        if(props.usuario.permisos !== null){
            arrayOfComprasValues = Object.values(props.usuario.permisos.Compras)
            allComprasValuesFalse = arrayOfComprasValues.every(v => v === false)
        }
    }

    if((state.ordenCompra !== null) && (state.ordenCompra.passPago !== null)){

        let fechaPass = state.ordenCompra.passPago.fechaPass

        yearPassPago = fechaPass.getFullYear()
        mesPassPago = (fechaPass.getMonth()+1) < 10 ? mesPassPago = `0${fechaPass.getMonth()+1}` : mesPassPago = `${fechaPass.getMonth()+1}`
        diaPassPago = fechaPass.getDate() < 10 ? diaPassPago = `0${fechaPass.getDate()}` : diaPassPago = `${fechaPass.getDate()}`

        let fechaDePago = state.ordenCompra.passPago.fechaDePago
        yearPago = fechaDePago.getFullYear()
        mesPago = (fechaDePago.getMonth()+1) < 10 ? mesPago = `0${fechaDePago.getMonth()+1}` : mesPago = `${fechaDePago.getMonth()+1}`
        diaPago = fechaDePago.getDate() < 10 ? diaPago = `0${fechaDePago.getDate()}` : diaPago = `${fechaDePago.getDate()}`

    }

    if (props.conexion === false) {
        return <Redirect to='/login' />
    }else if(props.usuario.permisos === null){
        return(
            <DashboardComponent>
                <h1>No te Han Asignado Permisos</h1>
            </DashboardComponent>
        )
    }else if( (props.usuario.permisos === null) || ((Object.entries(props.usuario.permisos.Compras).length > 0) !== true) || (allComprasValuesFalse === true) || (props.usuario.permisos.Compras.CreateOrdenCompra === false) ) {
        return <Redirect to='/dashboard' />
    }else if(props.usuario.permisos.Compras.CreateOrdenCompra === true){

        let proveedor,
            idAbreviado

        if((state.ordenCompra !== null) && (props.empresas.length > 0)){

            if(state.ordenCompra.proveedor === null){
                proveedor = props.empresas.filter( empresa => empresa.id === state.ordenCompra.idProveedor)[0].nombre_comercial
            }else{
                proveedor = state.ordenCompra.proveedor
            }

        }

        if(state.ordenCompra !== null){
            idAbreviado = state.ordenCompra.passPago.id.substr(state.ordenCompra.passPago.id.length - 6);
        }

        if(state.ordenCompra !== null && state.ordenCompra.passPago.emailTracking !== null){
            let fechaEmailTracking = state.ordenCompra.passPago.emailTracking.fecha
            yearEmealTracking = fechaEmailTracking.getFullYear()
            mesEmealTracking = (fechaEmailTracking.getMonth()+1) < 10 ? mesEmealTracking = `0${fechaEmailTracking.getMonth()+1}` : mesEmealTracking = `${fechaEmailTracking.getMonth()+1}`
            diaEmealTracking = fechaEmailTracking.getDate() < 10 ? diaEmealTracking = `0${fechaEmailTracking.getDate()}` : diaEmealTracking = `${fechaEmailTracking.getDate()}`
            horaEmealTracking = fechaEmailTracking.getHours() < 10 ? horaEmealTracking = `0${fechaEmailTracking.getHours()}` : horaEmealTracking = `${fechaEmailTracking.getHours()}`
            minutosEmealTracking = fechaEmailTracking.getMinutes() < 10 ? minutosEmealTracking = `0${fechaEmailTracking.getMinutes()}` : minutosEmealTracking = `${fechaEmailTracking.getMinutes()}`
        }

        return (
            <DashboardComponent>
                {
                ((state.ordenCompra !== null) && (state.ordenCompra.facturas !== null)) ? (
                    <div className="container">
                        <div className="col-12 d-none-print mt-4">
                            <button style={{backgroundColor: 'transparent', border: 'none', color: '#2984be'}}><FontAwesomeIcon icon="arrow-left" style={{fontSize: '25px'}} onClick={ () => props.history.goBack() } /></button>
                        </div>
                        <div className="table-responsive">
                          <h1 className="text-center">Contraseña de Pago</h1>

                          {
                            ((state.ordenCompra !== null) && (state.ordenCompra.passPago !== null)) ? (
                                <div className="col-12 col-md-6 col-lg-4 mx-auto">
                                    <QRCreator titulo="No. Contraseña de Pago" idOrden={state.ordenCompra.passPago.id}/>
                                    {
                                        idAbreviado !== undefined ? (
                                            <p className="text-center" style={{fontSize: '25px'}}><b>No. Contraseña de Pago Abreviado:</b> {idAbreviado}</p>
                                        ) : null
                                    }
                                    {
                                        proveedor !== undefined ? (
                                            <p className="text-center"><b>Proveedor:</b> {proveedor}</p>
                                        ) : null
                                    }
                                    <p className="text-center d-none-print"><b>Estado de Contraseña de Pago:</b> {state.ordenCompra.passPago.estado === 'Pagado' ? <b style={{color: '#0AEB24'}}>{state.ordenCompra.passPago.estado} <FontAwesomeIcon icon="check" style={{fontSize: '25px'}} /></b> : <b>{state.ordenCompra.passPago.estado}</b>}</p>
                                    <p className="text-center"><b>Fecha De Emisión de Contraseña de Pago:</b> {`${diaPassPago}/${mesPassPago}/${yearPassPago}`}</p>
                                    <p className="text-center"><b>Fecha de Pago:</b> {`${diaPago}/${mesPago}/${yearPago}`}</p>
                                    {
                                        (state.ordenCompra !== null) && (state.ordenCompra.passPago.emailTracking !== null) ? (
                                            <div className="col-12 mx-auto">
                                                <p className="text-center"><b>Envío de correo electrónico de contraseña de pago:</b> {state.ordenCompra.passPago.emailTracking.estado === 'success' ? <p>Exitoso <FontAwesomeIcon icon="check" style={{ color: '#77FF34', marginLeft: '5px' }} /> en fecha: {diaEmealTracking}/{mesEmealTracking}/{yearEmealTracking} a la {horaEmealTracking}:{minutosEmealTracking} horas {state.ordenCompra.passPago.emailTracking.correo !== null ? `al correo ${state.ordenCompra.passPago.emailTracking.correo}` : null}</p>:<p>Fallo en el envío <FontAwesomeIcon icon="times" style={{ color: '#FF2304', marginLeft: '5px' }} /> en fecha: {diaEmealTracking}/{mesEmealTracking}/{yearEmealTracking} a la {horaEmealTracking}:{minutosEmealTracking} horas {state.ordenCompra.passPago.emailTracking.correo !== null ? `al correo ${state.ordenCompra.passPago.emailTracking.correo}` : null}</p>}</p>
                                            </div>
                                        ) : null
                                    }
                                </div>
                            ) : null
                         }

                          <h2 className="text-center">Facturas</h2>
                          <table className="table text-center table-bordered table-hover table-sm">
                              <thead className="thead-light">
                                  <tr>
                                      <th scope="col">No. Factura</th>
                                      <th scope="col">Concepto</th>
                                      <th scope="col">Valor</th>
                                      <th scope="col" className="d-none-print">Documento Factura</th>
                                  </tr>
                              </thead>
                              <tbody>
                              {
                                  state.ordenCompra.facturas.map ( (factura, i) => {
    
                                      let productosOfFacturas =  state.ordenCompra.productos.filter( producto => factura.productos.includes(producto.nombre) )
                                      let valoresOfProductosFacturas = productosOfFacturas.map( producto => producto.valorUnitario * producto.cantidad )
    
                                      let valorTotalFactura = 0;
    
                                      valoresOfProductosFacturas.forEach( valor => {
                                          valorTotalFactura += valor;
                                      });
    
                                      return(
                                          <tr key={i}>
                                              <td>{factura.noFactura}</td>
                                              <td>{factura.concepto}</td>
                                              <td>{formatterTwoDecimals.format(valorTotalFactura)}</td>
                                              <td className="d-none-print">{factura.factura === null ? (
                                                  <div className="col-12">
                                                      <p className="text-center">No han subido un documento de factura.</p>
                                                  </div>
                                              ) : (
                                                  <a download="factura.pdf" href={factura.factura}><button style={{backgroundColor: 'transparent', border: 'none', color: '#2984be'}}><FontAwesomeIcon icon="file-download" style={{fontSize: '25px'}} /></button></a>
                                              )}</td>
                                          </tr>
                                      )
                                  })
                              }
                              </tbody>
                          </table>
                      </div>
                    </div>
                ) : null
              }

               {
                    state.ordenCompra !== null ? (
                        <div className="col-12 d-flex d-none-print" style={{ marginTop: '15px', marginBottom: '15px' }}>
                            <button className="btn btn-outline-info mx-auto" onClick={ () => window.print() }>Imprimir Contraseña de Pago {<FontAwesomeIcon icon="print" />}</button>
                        </div>
                    ) : null
               }
    
              {
                ((state.ordenCompra !== null) && (state.ordenCompra.passPago.estado === "No Pagado")) ? (
                  <div className="col-12 d-flex d-none-print" style={{ marginTop: '15px', marginBottom: '15px' }}>
                        <button className="btn btn-outline-info mx-auto" onClick={ () => handleChangeStatePassPago("Pagado") }>Actualizar Estado a Pagado {<FontAwesomeIcon icon="sync-alt" />}</button>
                    </div>
                ) : null
              }

              {
                  ((state.ordenCompra === null) && (state.loading === false)) ? (
                    <div className="col-12" style={{minHeight: '70vh'}}>
                        <h1 className="text-center">Ver Contraseña de Pago</h1>
                        <h2 className="text-center">No Se encontró ninguna contraseña de Pago con el id: {props.match.params.idPassPago}</h2>
                    </div>
                  ) : null
              }

              {
                state.loading === true ? (
                    <Loader />
                ) : null
              }

            </DashboardComponent>
        )
    }

}

let mapStateToProps = (state, props) => {
    return {
        conexion: state.logIn.conexion,
        usuario: state.logIn.usuario,
        empresas: state.dataEmpresas.empresas
    }
}

export default connect(mapStateToProps)(VerPassPago)