import React from 'react';

import ContactoContainer from '../../../Containers/Contacto.jsx';

import Veterinaria from '../../Components/Veterinaria.jsx';

let Contacto = props => (
  <Veterinaria>
    <ContactoContainer/>
  </Veterinaria>
)

export default Contacto
