import React, { Component } from 'react';

import { connect } from 'react-redux';

import Summernote from '../../Summernote/Containers/Summernote.jsx';

import ServiciosMedicosComponent from '../Components/ServiciosMedicos.jsx';

class ServiciosMedicos extends Component {

  render(){

    let renderVal = this.props.dataUI,
        textServicosMedicosObj = renderVal.textos.find( text => {
          return text.id === 'tex007'
        }),
        texto = textServicosMedicosObj.texto

    return(
      <ServiciosMedicosComponent>
        <Summernote itemID='#texto-servicios-medicos' textObj={textServicosMedicosObj} texto={texto} url='http://localhost:8080/updateText' />
      </ServiciosMedicosComponent>
    )

  }


}

let mapStateToProps = (state, props) => {
  return {
    dataUI: state.dataUI
  }
}

export default connect(mapStateToProps)(ServiciosMedicos);
