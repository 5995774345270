let consulta = (data) => {

  let stringOrdenArray = []

    data.forEach(orden => {
      delete orden.idProvisional
      let stringOrden = JSON.stringify(orden).replace(/{"/g, '{').replace(/":/g, ':').replace(/,"/g, ',')
      stringOrdenArray.push(stringOrden)
    });

  //console.log(stringOrdenArray)

  let string = `# Write your query or mutation here
  mutation insertOrdenes {
    mensajeriaMutation {
      insertOrdenes(
        insertOrdenes: {
          ordenes: [${stringOrdenArray}]
        }
      ){
        ids
        mensaje
      }
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
