// JavaScript Document
import GraphQL from './ConsultaGraphQLEditTipoCliente';

import url from '../../../../../../apiService/ApiUrl.js';

function handleError(err, state, setState){
	console.log(`Request failed: ${err}`);

	setState({
		...state,
		editTipoClienteMensaje: {
			response: "failure",
			mensaje: "Ha ocurrido un error al intentar editar un nuevo tipo de cliente."
		}
	})	

}

const fetchEditTipoCliente = (setState, state, tipoCliente) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(tipoCliente)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		if(res){
			setState({
				...state,
				editTipoClienteMensaje: {
					response: "success",
					mensaje: res.data.productosMutation.editTipoCliente.mensaje
				}
			})
		}else{
			setState({
				...state,
				editTipoClienteMensaje: {
					response: "failure",
					mensaje: "Ha ocurrido un error al intentar editar un nuevo tipo de cliente."
				}
			})
		}


	})
	.then( () => {
		/* istanbul ignore next */
		setTimeout(() => {
			setState({
				...state,
				editTipoClienteMensaje: null
			})
		}, 1500);
	})
	.catch(err => handleError(err, state, setState))
	.then( () => {
		/* istanbul ignore next */
		setTimeout(() => {
			setState({
				...state,
				editTipoClienteMensaje: null
			})
		}, 1500);
	});

	//termina fetch
}

export default fetchEditTipoCliente;
