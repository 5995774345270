import GraphQL from './ConsultaGraphQL';

import url from '../../../../../../apiService/ApiUrl.js';

function handleError(err, This){
	console.log(`Request failed: ${err}`);
	This.setState({
		mensajeDeCambioDeEstadoYCantidadDeMuestras: {
			response: 'failure',
			mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
		}
	})

	if(This.state.cancelMensaje !== undefined){
		This.setState({
			cancelMensaje: {
				response: 'failure',
				mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
			}
		})
	}

}

const cancelEstadoSolicitud = (This, data, orden) => {

	const options = {
		method: 'POST',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(data)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		orden.razonDeCancelacion = data.razonDeCancelacion

		orden.cantidadDeMuestras = null

		if(orden !== undefined){
			orden.estado = data.estado
			This.props.dispatch({
				type: `CHANGE_DATA_ORDEN_MENSAJERIA`,
				payload: {
					dataOrden: orden
				}
			})
		}

		This.setState({
			mensajeDeCambioDeEstadoYCantidadDeMuestras: {
				response: 'success',
				mensaje: `${res.data.mensajeriaMutation.cancelStateYRazonDeCancelacion.mensaje} de la orden con id ${res.data.mensajeriaMutation.cancelStateYRazonDeCancelacion.id}`
			}
		})

		if(This.state.cancelMensaje !== undefined){
			This.setState({
				cancelMensaje: {
					response: 'success',
					mensaje: `${res.data.mensajeriaMutation.cancelStateYRazonDeCancelacion.mensaje} de la orden con id ${res.data.cancelStateYRazonDeCancelacion.id}`
				}
			})
		}


	})
	.then( () => {
		if(This.state.cancelMensaje !== undefined){
			setTimeout( () => { 
				This.setState({
					cancelMensaje: null
				})
			}, 3000);
		}	
	})
	.catch(err => handleError(err, This));

	//termina fetch
}

export default cancelEstadoSolicitud;
