//import { gql } from "apollo-boost";

let NewTareaSubscription = (This) => {

    // use
    (async () => {
        try {
            const res = await This.props.suscribeGraphQL({
            query: `
            subscription {
                adminObjetivosSubscription{
                    newTarea(
                        idCreador: "${This.props.usuario.id}"
                    ){
                        id
                        nombre
                        idCreador
                        idSucursal
                        idMeta
                        idsInvolucrados
                        fechaDeInicio
                        fechaFinal
                        fechaFinalPlanificada
                        estado
                        tipo
                        nivelDeVisualizacion
                        observaciones
                    }
                }
            }
            `,
            }, This.props.suscriptionTareas);

            return res

            //console.log(res)
            // complete
            //next = result = { data: { hello: 'Hello World!' } }
        } catch (err) {
            // error
            console.log(err)
        }
    })();

}

export default NewTareaSubscription