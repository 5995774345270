import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { formatterTwoDecimals } from '../../../../../MyModules/formatterDecimals';

let ProductoTable = props => {
    if(props.productos.length > 0){

        let valoresTotalesDeProducto = props.productos.map( producto => producto.cantidad * producto.valorUnitario),
            reducer = (accumulator, currentValue) => accumulator + currentValue,
            valorTotal = valoresTotalesDeProducto.reduce(reducer)

        return(
            <div className="table-responsive">
                <table className="table text-center table-bordered table-hover table-sm">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Nombre Producto</th>
                            <th scope="col" className="d-none d-md-table-cell">Cantidad</th>
                            <th scope="col" className="d-none d-md-table-cell">Valor Unitario</th>
                            <th scope="col">Valor Total</th>
                            <th scope="col">Acciones</th>
                            {/*<th scope="col" className="d-none d-md-table-cell" >Teléfono</th>
                            {/*<th scope="col" className="d-none d-xl-table-cell" >Correo</th>
                            <th scope="col">Acciones</th>*/}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.productos.map( (producto,i) => {

                                let valorTotal = producto.cantidad * producto.valorUnitario

                                return(
                                    <tr key={i}>
                                        <td>{producto.nombre}</td>
                                        <td className="d-none d-md-table-cell">{producto.cantidad}</td>
                                        <td className="d-none d-md-table-cell">Q. {formatterTwoDecimals.format(producto.valorUnitario)}</td>
                                        <td>Q. {formatterTwoDecimals.format(valorTotal)}</td>
                                        <td>
                                            <button type="button" onClick={() => {
                                                    props.onQuitProducto(i)
                                                }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                                                <FontAwesomeIcon icon="minus-circle" />
                                            </button>
                                        </td>
                                    </tr>
                                )

                            })
                        }
                        <tr>
                            <td colSpan="4" className="d-none d-md-table-cell"><b>Valor de Orden Total</b></td>
                            <td colSpan="2" className="d-md-none"><b>Valor de Orden Total</b></td>
                            <td><b>Q. {formatterTwoDecimals.format(valorTotal)}</b></td>
                        </tr>

                    </tbody>
                </table>
            </div>
        )
    }else{
        return null
    }
}

export default ProductoTable

