// JavaScript Document
import GraphQL from './ConsultaGraphQLAddIdRecepcionArea';

import url from '../../../../../apiService/ApiUrl.js';

function handleError(err){
	console.log(`Request failed: ${err}`);
}

const fetchGraphQLUpdateStatePassPago = (This, data) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(data)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		This.setState({
			orden: {
				...This.state.orden,
				idUsuarioArea: res.data.addIdOfResponsableArea
			},
			loading: false
		})


	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchGraphQLUpdateStatePassPago;
