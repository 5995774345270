let consulta = ({ idOrden, correo}) => {

  let string = `mutation{
    ordenesCompraMutation{
      resendEmailOrdenCompra(idOrden: "${idOrden}", correo: "${correo}")
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
