import React, { useState, useEffect, FormEvent } from 'react';

import Toggle from 'react-toggle';

import FetchResendEmailOrdenCompra from '../Containers/FetchResendEmailOrdenCompra/FetchGraphQLResendEmailOrdenCompra';

interface IMessage {
    state: string,
    mensaje: string
}

const ReenvioCorreo = ({ correoProveedor, idOrden }: { correoProveedor: string, idOrden: string }) => {

    const [defaultCorreo, setDefaultCorreo] = useState<boolean>(false)

    const [formActive, setFormActive] = useState<boolean>(false)

    const [correo, setCorreo] = useState<string>("")

    const [message, setMessage] = useState<IMessage | null>(null)

    useEffect(() => {

        if(defaultCorreo === true){
            setCorreo(correoProveedor)
        }else{
            setCorreo("")
        }

    }, [defaultCorreo, correoProveedor]);

    const setOnHandleFormActive = () => {
        setFormActive(!formActive)
    }

    const setOnHandleSetDefaultCorreo = () => {
        setDefaultCorreo(!defaultCorreo)
    }

    const setOnHandleCorreoInputChange = (event:FormEvent<HTMLInputElement>) => {
        setCorreo(event.currentTarget.value)
    }

    const handleSubmit = async (event:React.SyntheticEvent) => {

        event.preventDefault()
        const data = {
            idOrden,
            correo
        }

        let res = await FetchResendEmailOrdenCompra(data)

        if(res === true){
            setMessage({
                state: "success",
                mensaje: "El Correo se envió exitosamente."
            })
        }else{
            setMessage({
                state: "failure",
                mensaje: "Error de envío de correo."
            })
        }

        setOnHandleFormActive()

        setTimeout(() => setMessage(null), 3000);

    }

    return(
        <div className="col-12 mx-auto text-center mb-5 d-none-print">



            {
                formActive === false && <button type="button" onClick={setOnHandleFormActive} className="btn btn-outline-info">Reenviar Correo</button>
            }

            {
                formActive && (
                    <form onSubmit={handleSubmit}>
                        <div className="form-group" style={{marginTop: '15px'}}>
                            <div className="row">
                                <p className="col-lg-6 col-12 text-center"><b>Usar Correo por Defecto</b></p>
                                <label className="mx-auto">
                                    <Toggle
                                        id="correoDefault"
                                        checked={defaultCorreo}
                                        onChange={setOnHandleSetDefaultCorreo} 
                                    />
                                </label>
                            </div>
                        </div>
                        {
                            defaultCorreo === false && (
                                <div className="form-group">
                                    <label htmlFor="correo">Coloca el Correo a enviar Orden de Compra</label>
                                    <input type="mail" id="correo" className="form-control" onChange={setOnHandleCorreoInputChange} value={correo} required />
                                </div>
                            )
                        }
                        <button type="button" onClick={setOnHandleFormActive} className="btn btn-outline-danger mr-2">Cancelar</button>
                        <button type="submit" className="btn btn-outline-info ml-2">Reenviar</button>
                    </form>
                )
            }

            {
                message !== null ? (
                <div className="row mt-3">
                    {
                        (message.state === 'success') ? (
                            <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{message.mensaje}</div>
                        ) : (
                            <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{message.mensaje}</div>
                        )
                    }
                </div>
                ) : null
            }

        </div>
    )
}

export default ReenvioCorreo