let consulta = () => {
  let string = `{ appInfo{
    empresas{
      id
      nombre_comercial
      nombre_razon_social
      telefono
      correo
      NIT
      direccion_fiscal
      direccion_comercial
      descripcion
      convenio
      credito_autorizado
      dias_de_credito_autorizado
      tipo_de_pago_comun
      tipo_empresa
      clasificacion_empresa
      proveedor_autorizado
      contacto_directo{
        nombre
        apellidos
        correo
        telefono
        celular
        direccion
        sexo
      }
    },
    sucursales{
      id
      nombre_comercial
      telefonos
      correo
      direccion
      convenio
      id_empresa
      id_contacto_directo
    }
  } }`

  /*return {
    "query": string
  }*/

  return string

}

export default consulta;
