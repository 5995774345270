let consulta = (data) => {

  let string = `mutation insertRutaMensajeria {
    mensajeriaMutation {
      insertRutaMensajeria(insertRutaMensajeria: {
        idMensajero: "${data.idMensajero}"
        nombreRuta: "${data.nombreRuta}"
        ordenesRuta: "${data.ordenesRuta}",
        fechaRuta: "${data.fechaRuta}"
      }){
        id
        mensaje
      }
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
