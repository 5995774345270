import React, { Component } from 'react';

import BotonDashboardComponent from '../components/BotonDashboard/BotonDashboard.jsx';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

class BotonDashboard extends Component{

	render(){
		if (this.props.conexion === false) {
			return <Redirect to='/login' />
		}else{
			return(
        		<BotonDashboardComponent usuario={this.props.usuario}/>
			)
		}
	}
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario
  }
}

export default connect(mapStateToProps)(BotonDashboard)
