let consulta = () => {
  let string = `{ appInfo{
    usuarios{
      id
      usuario
      email
      nombre
      apellidos
      telefono
      celular
      DPI
      fecha_de_nacimiento
      direccion
      NIT
      nota
      sexo
      tipo
      permisos {
        AdminUI
        AdminUser {
          ViewUser
          CreateUser
          EditUser
          DeleteUser
        }
        AdminEmpresa{
          ViewEmpresa
          CreateEmpresa
          EditEmpresa
          DeleteEmpresa
        }
        Mensajeria{
          QRScanner
          ViewOrden
          CreateOrden
          EditOrden
          DeleteOrden
          ActOrdenAdmin
          ActOrdenMuestra
          CheckListOrden
          ViewRuta
          RealizarRuta
          EditRuta
          DeleteRuta
          CheckListRuta
          ViewPlantillaRuta
          RealizarPlantillaRuta
          EditPlantillaRuta
          DeletePlantillaRuta
        }
        AdminObjetivos
        Compras{
          CreateOrdenCompra
          AddProductoCompra
          VerOrdenesCompra
          ViewProductosCompras
        }
        Productos{
          ViewProducto
          CreateProducto
          EditProducto
          DeleteProducto
          ViewTipoCliente
          CreateTipoCliente
          EditTipoCliente
          DeleteTipoCliente
          ViewAseguradora
          CreateAseguradora
          EditAseguradora
          DeleteAseguradora
        }
      }
      usuario_activo
      empresa_asociada{
        id
        nombre
        telefono
        correo
      }
      colaborador{
        id
        puesto
        departamento
      }
      idSucursal
    }
  } }`

  /*return {
    "query": string
  }*/

  return string

}

export default consulta;
