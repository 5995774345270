import React from 'react';
import './css/dashboard.min.css';

//ImportandoModal
import ModalPortal from '../../../Components/ModalPortal.jsx';
import Modal from '../../../Modal/Containers/ModalDashboard';

import Navbar from '../../navbar/Navbar.jsx';

import Footer from '../../../Components/NewFooter.jsx';

let DashboardComponent = props => (
  <div className="body fixed-nav sticky-footer" id="page-top">
    <Navbar />
    <div className="content-wrapper">
      {props.children}
      <Footer />
    </div>
    <ModalPortal>
      <Modal />
		</ModalPortal>
  </div>
)

export default DashboardComponent
