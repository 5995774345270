import React, { Component } from 'react';

import DashboardComponent from '../../../dashboard/components/DashboardComponent.jsx';

import GraphQLEmpresas from '../../../../../apiService/FetchGraphQLEmpresas.js';

import GraphQLUsuarios from '../../../../../apiService/FetchGraphQLUsuarios.js';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

import RealizarOrdenMensajeria from './RealizarOrdenAdministrativa.jsx';

class DashboardMuestras extends Component {

  componentDidMount(){

    let arrayOfMensajeriaValues = [],
        allMensajeriaValuesFalse = true

    if(this.props.usuario !== null){
      if(this.props.usuario.permisos !== null){
        arrayOfMensajeriaValues = Object.values(this.props.usuario.permisos.Mensajeria)
        allMensajeriaValuesFalse = arrayOfMensajeriaValues.every(v => v === false)
      }
    }

    if((this.props.conexion !== false) && ((this.props.usuario.permisos !== null) && ((Object.entries(this.props.usuario.permisos.Mensajeria).length > 0) === true) && (allMensajeriaValuesFalse === false) && (this.props.usuario.permisos.Mensajeria.CreateOrden === true))){
      GraphQLEmpresas(this);
      GraphQLUsuarios(this);
    }

  }

  render(){
    if (this.props.conexion === false) {
			return <Redirect to='/login' />
		}else{

      return(
        <DashboardComponent>
          <RealizarOrdenMensajeria />
        </DashboardComponent>
      )
    }
  }
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario
  }
}

export default connect(mapStateToProps)(DashboardMuestras)
