import React from 'react';

import ServiciosMedicosContainer from '../../../Containers/ServiciosMedicos.jsx';

import EmpresasEIndustrias from '../../Components/EmpresasEIndustrias.jsx';

let Contacto = props => (
  <EmpresasEIndustrias>
    <ServiciosMedicosContainer/>
  </EmpresasEIndustrias>
)

export default Contacto
