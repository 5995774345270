let consulta = (producto) => {

  let NoCatalogo = producto.NoCatalogo === "" ? null : producto.NoCatalogo 

  let string = `mutation{
    ordenesCompraMutation{
      insertProductoCompra(insertProductoCompra: {
        idCreador: "${producto.idCreador}",
        idProveedor: "${producto.idProveedor}"
        nombre: "${producto.nombre}",
        valorUnitario: ${producto.valorUnitario},
        NoCatalogo: "${NoCatalogo}",
        descripcion: "${producto.descripcion}",
        observaciones: "${producto.observaciones}",
        tipo: "${producto.tipo}",
      }){
        id
        result
        mensaje
      }
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
