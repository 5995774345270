import React, { useState, forwardRef, useEffect } from 'react';

import DatePicker, { registerLocale } from 'react-datepicker';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Toggle from 'react-toggle';

import "react-datepicker/dist/react-datepicker.css";

import es from 'date-fns/locale/es';
registerLocale('es', es)

let AddFactura = props => {

    const [state, setState] = useState({
        fechaDePago: "",
        AddFactura: false,
        base64MomentFactura: null,
        productosMoment: [],
        productosInFacturas: [],
        noFacturaMoment: "",
        conceptoMoment: "",
        razonDeEntregaParcial: "",
        facturas: [],
        sendCorreo: true,
        defaultCorreo: props.correoProveedor === null ? false : true,
        correo: props.correoProveedor,
        createPassPago: false
    });

    useEffect( () => {
        if(props.productosOfFacturasOrden !== undefined){
            setState({
                ...state,
                productosMoment: props.productosOfFacturasOrden
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect( () => {

        let arraysOfProductosFacturas = state.facturas.map( factura => factura.productos),
        productosOfFacturasInsert = Array.prototype.concat.apply([], arraysOfProductosFacturas)

        if((props.productosOfFacturasOrden.length + productosOfFacturasInsert.length) === props.productosOfOrden.length){
            setState({
                ...state,
                createPassPago: true
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.facturas, props.productosOfFacturasOrden, props.productosOfOrden])


    const CustomInput = forwardRef(
        ({ value, onClick, placeholder }, ref) => (
            <div className="mx-auto col-12">
                <label htmlFor="fechaDePago">Selecciona una Fecha de Pago: </label><br />
                <button className="btn btn-outline-info mx-auto text-center" onClick={onClick} ref={ref}>
                    {
                        value === "" ? placeholder : value
                    }
                </button>
            </div>
        ),
    );

    let onClickAddFacturaButton = e => {
        e.preventDefault()
        setState({
            ...state,
            AddFactura: true
        })
    }

    let handleInputFileChangeFactura = () => {

        function convertToBase64() {
            //Read File
            var selectedFile = document.getElementById("archivo").files;
            //Check File is not Empty
            if (selectedFile.length > 0) {
                // Select the very first file from list
                var fileToLoad = selectedFile[0];
                // FileReader function for read the file.
                var fileReader = new FileReader();
                var base64;
                // Onload of file read the file content
                fileReader.onload = function(fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    // Print data in console
                    //console.re.log(base64);
                    //console.log(base64);
                    setState({
                        ...state,
                        base64MomentFactura: base64
                    })
                };
                // Convert data to base64
                fileReader.readAsDataURL(fileToLoad);
            }
        }
        convertToBase64()
    }

    let handleAddRemoveProductoToFactura = (type, producto) => {
        if(type === "add"){
            let productosMoment = [...state.productosMoment, producto]
            setState({
                ...state,
                productosMoment
            })
        }else{
            let productosMoment = state.productosMoment.filter( productoOfArr => (productoOfArr !== producto) )
            setState({
                ...state,
                productosMoment
            })
        }
    }

    let handleInputChange = event => {
        setState({
            ...state,
            [event.target.id]: event.target.value
        })
    }

    let handleSubmitFactura = event => {

        event.preventDefault()

        if(state.base64MomentFactura === null){

            let factura = {
                noFactura: state.noFacturaMoment,
                concepto: state.conceptoMoment,
                productos: state.productosMoment.filter( producto => !props.productosOfFacturasOrden.includes(producto))
            }

            setState({
                ...state,
                facturas: [...state.facturas, factura],
                AddFactura: false,
                base64MomentFactura: null,
                productosMoment: [],
                productosInFacturas: [...state.productosInFacturas, ...state.productosMoment],
                noFacturaMoment: "",
                conceptoMoment: ""
            })

        }else{
            let factura = {
                noFactura: state.noFacturaMoment,
                factura: state.base64MomentFactura,
                concepto: state.conceptoMoment,
                productos: state.productosMoment
            }

            setState({
                ...state,
                facturas: [...state.facturas, factura],
                AddFactura: false,
                base64MomentFactura: null,
                productosMoment: [],
                productosInFacturas: [...state.productosInFacturas, ...state.productosMoment],
                noFacturaMoment: "",
                conceptoMoment: ""
            })
        }

    }

    let handleChangeSendCorreo = () => {

        if(state.sendCorreo === true){
            setState({
                ...state,
                sendCorreo: !state.sendCorreo,
                correo: null
            })
        }else{
            if(state.defaultCorreo === true){
                setState({
                    ...state,
                    sendCorreo: !state.sendCorreo,
                    correo: props.correoProveedor
                })
            }else{
                setState({
                    ...state,
                    sendCorreo: !state.sendCorreo,
                    correo: ""
                })
            }
        }

    }

    let handleChangeDefaultCorreo = () => {
        if(state.defaultCorreo === true){
            setState({
                ...state,
                defaultCorreo: !state.defaultCorreo,
                correo: ""
            })
        }else{
            setState({
                ...state,
                defaultCorreo: !state.defaultCorreo,
                correo: props.correoProveedor
            })
        }
    }

    let productosFilter = props.productos.filter( producto =>  state.productosInFacturas.includes(producto.nombre) === false  )
        productosFilter = productosFilter.filter( producto => state.productosMoment.includes(producto.nombre) === false )

    let productosCheckListAll = props.productos.filter( producto => producto.checkList !== null).length

    /*let facturasTotalValues = state.facturas.map( factura => {

        let productosOfFacturas =  props.productos.filter( producto => factura.productos.includes(producto.nombre) )
        let valoresOfProductosFacturas = productosOfFacturas.map( producto => producto.valorUnitario * producto.cantidad )

        let valorTotalFactura = 0;

        valoresOfProductosFacturas.forEach( valor => {
            valorTotalFactura += valor;
        });

        return valorTotalFactura

    })

    let valorTotalDeFacturas = 0

    facturasTotalValues.forEach( valor => {
        valorTotalDeFacturas += valor;
    });*/

    return(
        <div className="mx-auto text-center">

            {
                state.createPassPago === true ? (
                    <div className="col-12 my-2">
                        <DatePicker
                            id="fechaDePago"
                            selected={state.fechaDePago}
                            onChange={(date) => setState({ ...state, fechaDePago: date })}
                            name="fechaDePago"
                            filterDate={(date) => date.getDay() === 1}
                            placeholderText="Selecciona un Lunes para fecha de Pago"
                            customInput={<CustomInput />}
                            locale="es"
                            dateFormat="MMMM d, yyyy"
                            showMonthDropdown
                            showYearDropdown
                        />
                    </div>
                ) : null
            }

            {
               state.createPassPago === true ? (
                    <div className="form-group" style={{marginTop: '15px'}}>
                        <div className="row">
                            <p className="col-lg-6 col-12 text-center"><b>Mandar Contraseña de Pago por Correo</b></p>
                            <label className="mx-auto">
                            <Toggle
                                id="correoSend"
                                defaultChecked={state.sendCorreo}
                                onChange={handleChangeSendCorreo} />
                            </label>
                        </div>
                    </div>
               ) : null
            }


            {
                ((state.sendCorreo === true) && (props.correoProveedor !== null) && (state.createPassPago === true)) ? (
                    <div className="form-group" style={{marginTop: '15px'}}>
                        <div className="row">
                            <p className="col-lg-6 col-12 text-center"><b>Usar Correo por Defecto</b></p>
                            <label className="mx-auto">
                            <Toggle
                                id="correoDefault"
                                defaultChecked={state.defaultCorreo}
                                onChange={handleChangeDefaultCorreo} />
                            </label>
                        </div>
                    </div>
                ) : null
            }

            {
                ((state.sendCorreo === true) && (state.defaultCorreo === false) && (state.createPassPago === true)) ? (
                    <div className="form-group">
                        <label htmlFor="correo">Coloca el Correo a enviar Contraseña de Pago</label>
                        <input type="mail" id="correo" className="form-control" onChange={handleInputChange} required />
                    </div>
                ) : null
            }

            {
                state.facturas.length > 0 ? (
                    <div className="col-12 my-2">
                        <div className="table-responsive">
                            <h2 className="text-center">Facturas a Subir</h2>
                            <table className="table text-center table-bordered table-hover table-sm">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">No. Factura</th>
                                        <th scope="col">Concepto</th>
                                        <th scope="col">Valor</th>
                                        <th scope="col">Documento de Factura</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        state.facturas.map ( (factura, i) => {

                                            let productosOfFacturas =  props.productos.filter( producto => factura.productos.includes(producto.nombre) )
                                            let valoresOfProductosFacturas = productosOfFacturas.map( producto => producto.valorUnitario * producto.cantidad )

                                            let valorTotalFactura = 0;

                                            valoresOfProductosFacturas.forEach( valor => {
                                                valorTotalFactura += valor;
                                            });

                                            return(
                                                <tr key={i}>
                                                    <td>{factura.noFactura}</td>
                                                    <td>{factura.concepto}</td>
                                                    <td>{valorTotalFactura}</td>
                                                    <td>{factura.factura === undefined ? "No" : "Sí"}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : null
            }
            
            <div className="col-12 my-2">

                {
                    ((state.AddFactura === false) && (state.createPassPago === false)) ? (
                        <button className="btn btn-outline-info mx-auto text-center" onClick={onClickAddFacturaButton}>
                            Agrega una Factura
                        </button>
                    ) :  null
                }

                {
                    state.AddFactura === true ? (
                        <form className="mx-auto text-center mb-5" onSubmit={handleSubmitFactura}>

                            <div className="form-group">
                                <label htmlFor="noFacturaMoment">Coloca el No. Factura</label>
                                <input type="text" id="noFacturaMoment" className="form-control" onChange={handleInputChange} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="conceptoMoment">Coloca el Concepto de la Factura</label>
                                <input type="text" id="conceptoMoment" className="form-control" onChange={handleInputChange} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="archivo">Selecciona la Factura </label>
                                <input type="file" id="archivo" className="form-control-file" accept=".pdf" onChange={handleInputFileChangeFactura} />
                            </div>

                            

                            <div className="form-group">

                                    {
                                        state.productosMoment.length > 0 ? (
                                            <div className="table-responsive">
                                                <h2 className="text-center">Quitar Producto de Factura</h2>
                                                <table className="table text-center table-bordered table-hover table-sm">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col">Nombre Producto</th>
                                                            <th scope="col">Acciones</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            state.productosMoment.map ( (producto, i) => {



                                                                return(
                                                                    <tr key={i}>
                                                                        <td>{producto}</td>
                                                                        <td>
                                                                            {
                                                                                props.productosOfFacturasOrden.includes(producto) ? null : (
                                                                                    <button style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}} type="button" onClick={ () => handleAddRemoveProductoToFactura("remove", producto)}> <FontAwesomeIcon icon="minus-circle" /></button>
                                                                                )
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                                
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : null
                                    }
                                    {
                                        productosFilter.length > 0 ? (
                                            <div className="table-responsive">
                                                <h2 className="text-center">Agregar Producto de Factura</h2>
                                                <table className="table text-center table-bordered table-hover table-sm">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col">Nombre Producto</th>
                                                            <th scope="col">Acciones</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            productosFilter.map ( (producto, i) =>
                                                                <tr key={i}>
                                                                    <td>{producto.nombre}</td>
                                                                    <td>
                                                                        <button style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}} type="button" onClick={ () => handleAddRemoveProductoToFactura("add", producto.nombre)}> <FontAwesomeIcon icon="plus-circle" /></button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : null
                                    }

                            </div>

                            <div className="form-group text-center">
                                <button className="btn btn-outline-info" type="submit">Agregar Factura</button>
                            </div>

                        </form>
                    ) : null
                }

            </div>

            {
                ((state.facturas.length) && (productosCheckListAll === props.productos.length)) > 0 ? (
                    <div className="col-12 my-2">
                    <button className="btn btn-outline-info mx-auto text-center" onClick={ () => {

                        let data = {
                            facturas: state.facturas,
                            fechaDePago: state.fechaDePago === '' ? null : state.fechaDePago,
                            razonDeEntregaParcial: state.razonDeEntregaParcial === '' ? null : state.razonDeEntregaParcial,
                            correo: state.correo
                        }

                        if((state.createPassPago === true) && (state.fechaDePago === '')){
                            let alert = document.querySelector('#alertNoSelectedDate')
                            alert.classList.remove('d-none')
                            setTimeout( () => { 
                                alert.classList.add('d-none')
                            }, 3000);
                        }else if((state.razonDeEntregaParcial === '') && (state.createPassPago === false)){
                            let input = document.querySelector('#razondeentregaparcialcontainer')
                            let alert = document.querySelector('#alertRazonEntregaParcial')
                            input.classList.remove('d-none')
                            alert.classList.remove('d-none')
                            setTimeout( () => { 
                                alert.classList.add('d-none')
                            }, 3000);
                        }else{
                            props.handleSubmitFacturaYPassPago(data)
                        }
                        

                    }}>
                        Subir Facturas{ state.createPassPago === true ?  ' y Crear Contraseña de Pago' : null }
                    </button>

                    <div id="razondeentregaparcialcontainer" className="form-group d-none mt-3">
                        <label htmlFor="razonDeEntregaParcial">Coloca la Razón de Entrega Parcial</label>
                        <input type="text" id="razonDeEntregaParcial" className="form-control" onChange={handleInputChange} />
                    </div>

                    <div id="alertNoSelectedDate" className="alert alert-warning mt-3 d-none" role="alert">
                        No seleccionaste una fecha de pago.
                    </div>
                    <div id="alertRazonEntregaParcial" className="alert alert-info mt-3 d-none" role="alert">
                        Escribe la Razón de la entrega Parcial.
                    </div>
                </div>
                ) : null
            }


            {/*
                ((valorTotalDeFacturas === props.valorTotalProductos) && (state.fechaDePago !== "")) ? (
                    
                ) : null
            */}

        </div>
    )
}

export default AddFactura