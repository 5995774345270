import React from 'react';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../../../../Modal/Components/Modal.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Permisos from './Permisos.jsx'

let Insert = props => {
  let usuario = props.usuario,
      usuarioString = JSON.stringify(usuario),
      modalVisible = props.modalVisible,
      handleCloseModal = props.handleCloseModal,
      fechaInicial = new Date(usuario.fecha_de_nacimiento).getTime(),
      fecha_de_nacimiento_Convertido

      if(isNaN(fechaInicial) === true){
        fecha_de_nacimiento_Convertido = new Date(parseInt(usuario.fecha_de_nacimiento))
      }else if(typeof(fechaInicial) === 'number') {
        fecha_de_nacimiento_Convertido = new Date(fechaInicial)
      }

      let mesFecha
      (fecha_de_nacimiento_Convertido.getMonth()+1) < 10 ? mesFecha = `0${fecha_de_nacimiento_Convertido.getMonth()+1}` : mesFecha = `${fecha_de_nacimiento_Convertido.getMonth()+1}`
      let diaFecha
      fecha_de_nacimiento_Convertido.getDate() < 10 ? diaFecha = `0${fecha_de_nacimiento_Convertido.getDate()}` : diaFecha = `${fecha_de_nacimiento_Convertido.getDate()}`
      let fechaInput = `${fecha_de_nacimiento_Convertido.getFullYear()}-${mesFecha}-${diaFecha}`

      let empresas = props.dataEmpresas.empresas.filter(empresa => empresa.nombre_comercial !== "Biolab"),
          sucursales = props.dataEmpresas.sucursales.filter(sucursal => sucursal.id_empresa === usuario.empresa_asociada.id)

      return(
        <ModalContainer>
          <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                
                <div className="modal-header">
                  <img className="brand-modal" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco" />
                  <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                    <div></div>
                    <div></div>
                  </button>
                </div>

                {/* Modal Body */}
                <div className="modal-body">

                  <div className="container-fluid">
                    <div className="row text-center">
                      <div className="col-12">
                        <FontAwesomeIcon icon="user-circle" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                        <h1>{usuario.nombre} {usuario.apellidos}</h1>
                      </div>
                      <div className="col-12" style={{'marginTop': '25px'}}>

                        <form id="insert-user-form" className="row text-center" onSubmit={props.handleSaveNewUser}>

                          <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                            <label htmlFor="usuario" className="col"><b>Usuario:</b></label>
                            <input ref={props.setRef} data-usuario={usuarioString} data-value-type="usuario" onChange={props.handleInputChange} type="text"  className="form-control" id="usuario" value={usuario.usuario} />
                            <small id="usuarioSmall" className="form-text text-muted">Usuario.</small>
                          </div>

                          <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                            <label htmlFor="password" className="col"><b>Contraseña:</b></label>
                            <input ref={props.setRef} data-usuario={usuarioString} data-value-type="password" onChange={props.handleInputChange} type="password"  className="form-control" id="password" value={usuario.password} required/>
                            <small id="passwordSmall" className="form-text text-muted">Contraseña.</small>
                          </div>

                          <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                            <label htmlFor="nombre" className="col"><b>Nombre:</b></label>
                            <input ref={props.setRef} data-usuario={usuarioString} data-value-type="nombre" onChange={props.handleInputChange} type="text"  className="form-control" value={usuario.nombre}/>
                            <small id="nombreSmall" className="form-text text-muted">Nombre.</small>
                          </div>

                          <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                            <label htmlFor="apellidos" className="col"><b>Apellidos:</b></label>
                            <input ref={props.setRef} data-usuario={usuarioString} data-value-type="apellidos" onChange={props.handleInputChange} type="text"  className="form-control" value={usuario.apellidos}/>
                            <small id="apellidosSmall" className="form-text text-muted">Apellidos.</small>
                          </div>

                          <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                            <label htmlFor="telefono" className="col"><b>Teléfono:</b></label>
                            <input ref={props.setRef} data-usuario={usuarioString} data-value-type="telefono" onChange={props.handleInputChange} type="text"  className="form-control" id="telefono" value={usuario.telefono}/>
                            <small id="telefonoSmall" className="form-text text-muted">Teléfono.</small>
                          </div>

                          <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                            <label htmlFor="celular" className="col"><b>Celular:</b></label>
                            <input ref={props.setRef} data-usuario={usuarioString} data-value-type="celular" onChange={props.handleInputChange} type="text"  className="form-control" id="celular" value={usuario.celular} style={{'top': '-3px'}}/>
                            <small id="celularSmall" className="form-text text-muted">Celular.</small>
                          </div>

                          <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                            <label htmlFor="email" className="col"><b>Correo:</b></label>
                            <input ref={props.setRef} data-usuario={usuarioString} data-value-type="email" onChange={props.handleInputChange} type="email"  className="form-control" id="email" value={usuario.email} required/>
                            <small id="emailSmall" className="form-text text-muted">Correo.</small>
                          </div>

                          <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                            <label htmlFor="DPI" className="col"><b>DPI:</b></label>
                            <input ref={props.setRef} data-usuario={usuarioString} data-value-type="DPI" onChange={props.handleInputChange} type="text"  className="form-control" id="DPI" value={usuario.DPI} />
                            <small id="dpiSmall" className="form-text text-muted">DPI.</small>
                          </div>

                          <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                            <label htmlFor="fecha_de_nacimiento" className="col"><b>Cumpleaños:</b></label>
                            <input ref={props.setRef} data-usuario={usuarioString} data-value-type="fecha_de_nacimiento" onChange={props.handleInputChange} type="date"  className="form-control" id="fecha_de_nacimiento" value={fechaInput} required/>
                            <small id="birthDateSmall" className="form-text text-muted">Cumpleaños.</small>
                          </div>

                          <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                            <label htmlFor="direccion" className="col"><b>Dirección:</b></label>
                            <input ref={props.setRef} data-usuario={usuarioString} data-value-type="direccion" onChange={props.handleInputChange} type="text"  className="form-control" id="direccion" value={usuario.direccion} />
                            <small id="direccionSmall" className="form-text text-muted">Dirección.</small>
                          </div>

                          <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                            <label htmlFor="NIT" className="col"><b>NIT:</b></label>
                            <input ref={props.setRef} data-usuario={usuarioString} data-value-type="NIT" onChange={props.handleInputChange} type="text"  className="form-control" id="NIT" value={usuario.NIT} />
                            <small id="nitSmall" className="form-text text-muted">NIT.</small>
                          </div>

                          <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                            <label htmlFor="nota" className="col"><b>Nota:</b></label>
                            <textarea ref={props.setRef} data-usuario={usuarioString} data-value-type="nota" onChange={props.handleInputChange} type="text" className="form-control" id="nota" value={usuario.nota} />
                            <small id="notaSmall" className="form-text text-muted">Nota.</small>
                          </div>

                          <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                            <label htmlFor="sexo" className="col"><b>Sexo:</b></label>
                            <select id="sexo" ref={props.setRef} data-usuario={usuarioString} className="form-control" onChange={props.handleInputChange} data-value-type="sexo" value={usuario.sexo}>
                              {
                                props.dataUsers.dataInfo.sexoVals.map( (sexoVal, index) => {
                                  return <option key={index} value={sexoVal}>{sexoVal}</option>
                                })
                              }
                            </select>
                            <small id="sexoSmall" className="form-text text-muted">Sexo.</small>
                          </div>

                          <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                            <label htmlFor="tipo" className="col"><b>Tipo:</b></label>
                            <select id="tipo" ref={props.setRef} data-usuario={usuarioString} className="form-control" onChange={props.handleInputChange} data-value-type="tipo" value={usuario.tipo}>
                              {
                                props.dataUsers.dataInfo.tipoVals.map( (tipoVal, index) => {
                                  return <option key={index} value={tipoVal}>{tipoVal}</option>
                                })
                              }
                            </select>
                            <small id="tipoSmall" className="form-text text-muted">Tipo.</small>
                          </div>

                          {
                            usuario.tipo === 'cliente' ? (
                              <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                <label htmlFor="EmpresaAsociada" className="col"><b>Empresa Asociada:</b></label>
                                <select id="EmpresaAsociada" ref={props.setRef} data-usuario={usuarioString} className="form-control" onChange={props.handleInputChange} data-value-type="EmpresaAsociada" value={usuario.empresa_asociada.nombre === null ? ("") : usuario.empresa_asociada.nombre} >
                                  <option value=''>Ninguna</option>
                                  {
                                    empresas.map( (empresa) => {
                                      return <option key={empresa.id} value={empresa.nombre_comercial}>{empresa.nombre_comercial}</option>
                                    })
                                  }
                                </select>
                                <small id="empresaSmall" className="form-text text-muted">Empresa Asociada.</small>
                              </div>
                            ) : null
                          }
                           
                          {
                             sucursales.length > 0 ? (
                              <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                <label htmlFor="sucursal" className="col"><b>Sucursal:</b></label>
                                <select id="sucursal" ref={props.setRef} data-usuario={usuarioString} className="form-control" onChange={props.handleInputChange} data-value-type="sucursal" value={usuario.idSucursal === null ? ("") : usuario.idSucursal} required>
                                  <option value=''>Ninguna</option>
                                  {
                                    sucursales.map( (sucursal) => {
                                      return <option key={sucursal.id} value={sucursal.id}>{sucursal.nombre_comercial}</option>
                                    })
                                  }
                                </select>
                                <small id="empresaSmall" className="form-text text-muted">Empresa Asociada.</small>
                              </div>
                              ) : null
                          }

                          {
                            usuario.tipo === 'colaborador' ? (
                              <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                <label htmlFor="Departamento" className="col"><b>Departamento:</b></label>
                                <select id="Departamento" ref={props.setRef} data-usuario={usuarioString} className="form-control" onChange={props.handleInputChange} data-value-type="Departamento" value={usuario.colaborador.departamento === null ? ("") : usuario.colaborador.departamento} required>
                                  <option value=''>Ninguna</option>
                                  <option value='Direccion'>Dirección</option>
                                  <option value='Referencia'>Referencia</option>
                                  <option value='Industrial'>Industrial</option>
                                  <option value='Administracion'>Administración</option>
                                  <option value='Clinico'>Clínico</option>
                                  {/*
                                    props.dataUsers.dataInfo.departamentoVals.map( (permisoVal, index) => {
                                      return <option key={index} value={permisoVal}>{permisoVal}</option>
                                    })
                                  */}
                                </select>
                                <small id="departamentoSmall" className="form-text text-muted">Departamento.</small>
                              </div>
                            ) : null
                          }

                          {
                            usuario.tipo === 'colaborador' ? (
                              <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                <label htmlFor="Puesto" className="col"><b>Puesto:</b></label>
                                
                                {
                                  usuario.colaborador.departamento === 'Direccion' ? (
                                    <select id="Puesto" ref={props.setRef} data-usuario={usuarioString} className="form-control" onChange={props.handleInputChange} data-value-type="Puesto" value={usuario.colaborador.puesto === null ? ("") : usuario.colaborador.puesto} required >
                                      <option value=''>Ninguno</option>
                                      <option value='Director General'>Director General</option>
                                      <option value='Director de Calidad'>Director de Calidad</option>
                                      <option value='Director Administrativo'>Director Administrativo</option>
                                      <option value='Director de Laboratorios'>Director de Laboratorios</option>
                                    </select>
                                  ) : null
                                }

                                {
                                  usuario.colaborador.departamento === 'Administracion' ? (
                                    <select id="Puesto" ref={props.setRef} data-usuario={usuarioString} className="form-control" onChange={props.handleInputChange} data-value-type="Puesto" value={usuario.colaborador.puesto === null ? ("") : usuario.colaborador.puesto} required >
                                      <option value=''>Ninguno</option>
                                      <option value='Coordinador Administrativo'>Coordinador Administrativo</option>
                                      <option value='Asistente Administrativo'>Asistente Administrativo</option>
                                      <option value='Secretaria Recepcionista'>Secretaria Recepcionista</option>
                                      <option value='Mensajero'>Mensajero</option>
                                      <option value='Mantenimiento y Limpieza'>Mantenimiento y Limpieza</option>
                                      <option value='Agente de Seguridad'>Agente de Seguridad</option>
                                    </select>
                                  ) : null
                                }

                                {
                                  ( (usuario.colaborador.departamento === 'Referencia') || (usuario.colaborador.departamento === 'Industrial') ) ? (
                                    <select id="Puesto" ref={props.setRef} data-usuario={usuarioString} className="form-control" onChange={props.handleInputChange} data-value-type="Puesto" value={usuario.colaborador.puesto === null ? ("") : usuario.colaborador.puesto} required >
                                      <option value=''>Ninguno</option>
                                      <option value='Coordinador de Laboratorio'>Coordinador de Laboratorio</option>
                                      <option value='Tecnico de Laboratorio'>Técnico de Laboratorio</option>
                                      <option value='Asistente Tecnico'>Asistente Técnico</option>
                                    </select>
                                  ) : null
                                }

                                {
                                  usuario.colaborador.departamento === 'Clinico' ? (
                                    <select id="Puesto" ref={props.setRef} data-usuario={usuarioString} className="form-control" onChange={props.handleInputChange} data-value-type="Puesto" value={usuario.colaborador.puesto === null ? ("") : usuario.colaborador.puesto} required >
                                      <option value=''>Ninguno</option>
                                      <option value='Coordinador de Laboratorio'>Coordinador de Laboratorio</option>
                                      <option value='Tecnico de Laboratorio'>Técnico de Laboratorio</option>
                                      <option value='Asistente Tecnico'>Asistente Técnico</option>
                                      <option value='Secretaria Recepcionista'>Secretaria Recepcionista</option>
                                      <option value='Agente de Seguridad'>Agente de Seguridad</option>
                                    </select>
                                  ) : null
                                }
                                <small id="puestoSmall" className="form-text text-muted">Puesto.</small>
                              </div>
                            ) : null
                          }

                          {/*props.logInUser.permisos.includes("adminUser") ? (
                            <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                              <label htmlFor="permisos" className="col"><b>Permisos:</b></label>
                              <select multiple id="permisos" ref={props.setRef} data-usuario={usuarioString}  className="form-control" onChange={props.handleInputChange} data-value-type="permisos" value={usuario.permisos}>
                                {
                                  props.dataUsers.dataInfo.permisoVals.map( (permisoVal, index) => {
                                    return <option key={index} value={permisoVal}>{permisoVal}</option>
                                  })
                                }
                              </select>
                              <small id="permisosSmall" className="form-text text-muted">Permisos.</small>
                            </div>
                          ) : (
                            null
                          )*/}

                          <Permisos />

                          <button type="submit" className="btn btn-default mx-auto mb-3"><FontAwesomeIcon icon="save" style={{'fontSize': '150%', color: '#2984be' }} form="insert-user-form"/></button>

                        </form>
                      </div>
                    </div>
                    {
                      props.insertUserMensaje !== null ? (
                        <div className="row">
                          {
                            (props.insertUserMensaje.response === 'success') ? (
                              <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.insertUserMensaje.mensaje}</div>
                            ) : (
                              <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.insertUserMensaje.mensaje}</div>
                            )
                          }
                        </div>
                      ) : null
                    }
                  </div>

                </div>

                <div className="modal-footer">
                  <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
                </div>

              </div>
            </div>
        </ModalB4>
      </ModalContainer>
      )
}

export default Insert
