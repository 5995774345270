import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let CancelarOrdenAdministrativa = props => {
    let ordenString = JSON.stringify(props.orden)
    return(
        <div className="row text-center">
            <div className="col-12">
                <FontAwesomeIcon icon="clipboard-list" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                <h1>Id de Orden: {props.orden.id}</h1>
            </div>

            <div className="col-12 col-md-10 mx-auto" style={{'marginTop': '25px'}}>
                <form onSubmit={props.onSubmitCancelar(props.orden)}>

                <div className="form-row">

                    <div className="col-md-6 row mx-auto" style={{marginTop: '15px', marginBottom: '15px'}}>
                        <label htmlFor="razonDeCancelacion" className="col"><b>Razón de Cancelación:</b></label>
                        <textarea data-orden={ordenString} data-value-type="razonDeCancelacion" className="col form-control-plaintext form-control-sm inputUserVal" id="razonDeCancelacion" style={{'top': '-3px'}} required/>
                    </div>

                </div>

                <div className="form-row">
                    <button type="button" className="btn btn-outline-success mx-auto" onClick={props.handleCancelar}>No Cancelar</button>

                    <button type="submit" className="btn btn-outline-danger mx-auto">Cancelar</button>
                </div>

                </form>
            </div>

            <div className="col-12">
                {
                    props.cancelMensaje !== null ? (
                        <div className="row" style={{marginTop: '25px'}}>
                        {
                            (props.cancelMensaje.response === 'success') ? (
                            <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.cancelMensaje.mensaje}</div>
                            ) : (
                            <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.cancelMensaje.mensaje}</div>
                            )
                        }
                        </div>
                    ) : null
                }
            </div>
        </div>
    )
}

export default CancelarOrdenAdministrativa