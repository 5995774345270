import React from 'react';

import Navbar from '../NavbarEmpresasEIndustrias/Containers/NavbarEmpresasEIndustrias.jsx'

import Footer from '../../../Components/NewFooter.jsx';

//Import MenuCMR
import MenuCMR from '../../../Dashboard/dashboard/containers/MenuCMR.jsx';

let EmpresasEIndustrias = props => (
  <div>
    <Navbar />
    {props.children}
    <MenuCMR />
    <Footer />
  </div>
)

export default EmpresasEIndustrias
