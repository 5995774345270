import GraphQL from './ConsultaGraphQL';

import url from '../../../../../apiService/ApiUrl.js';

// JavaScript Document
function handleError(err){
	console.log(`Request failed: ${err}`);
}

const updateStateMeta = (This, tareaJSON, stateCheckBox) => {
	//console.log(usuarioJSON)
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(tareaJSON)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		This.props.dispatch({
			type: 'EDIT_TAREA_TO_TAREAS',
			payload: {
				dataTarea: {
					id: res.data.adminObjetivos.updateStateTarea.id,
					nombre: tareaJSON.nombre,
					idCreador: tareaJSON.idCreador,
					idSucursal: tareaJSON.idSucursal,
					idMeta: tareaJSON.idMeta,
					idsInvolucrados: tareaJSON.idsInvolucrados,
					fechaDeInicio: new Date(tareaJSON.fechaDeInicio),
					fechaFinal: tareaJSON.fechaFinal,
					fechaFinalPlanificada: new Date(tareaJSON.fechaFinalPlanificada),
					estado: tareaJSON.estado,
					tipo: tareaJSON.tipo,
					nivelDeVisualizacion: tareaJSON.nivelDeVisualizacion,
					observaciones: tareaJSON.observaciones,
					type: "Tarea"
				},
				stateCheckBox
			}
		})

		return res
	})
	.catch(err => handleError(err, This))

	//termina fetch
}

export default updateStateMeta;
