let consulta = (ordenesDesde, ordenesHasta) => {
  let string = `{
    appInfo{
      solicitudesMensajeria(ordenesDesde: "${ordenesDesde}", ordenesHasta: "${ordenesHasta}"){
        id
        idEmpresa
        idSucursal
        idUsuario
        idMensajero
        idCreador
        instrucciones
        cantidadDeMuestras
        personaQueLlamo
        telefono
        direccionDeRecepcion
        fechaDeCreacion
        fechaDeRecoleccion
        tipo
        urgente
        estado
        razonDeCancelacion
        checkList {
          nombreQuienRevisa
          fecha
          cantidadDeMuestrasTruly
          observaciones
        }
        notasDeOrden
        personaQueDejaLaMuestra
        telefonoDeQueDejaLaMuestra
        correoDeQueDejaLaMuestra
      }
    }
  }`

  /*return {
    "query": string
  }*/

  return string

}

export default consulta;
