let consulta = (empresaJSON) => {

  //console.log(usuarioJSON.empresa_asociada)

  const string =
    `mutation insertEmpresa {
      empresaMutation{
        insertEmpresa(
          insertEmpresa: {
            nombre_comercial: "${empresaJSON.nombre_comercial}"
            nombre_razon_social: "${empresaJSON.nombre_razon_social}"
            telefono: "${empresaJSON.telefono}"
            correo: "${empresaJSON.correo}"
            NIT: "${empresaJSON.NIT}"
            direccion_fiscal: "${empresaJSON.direccion_fiscal}"
            direccion_comercial: "${empresaJSON.direccion_comercial}"
            descripcion: "${empresaJSON.descripcion}"
            convenio: "${empresaJSON.convenio}"
            credito_autorizado: ${empresaJSON.credito_autorizado}
            dias_de_credito_autorizado: ${empresaJSON.dias_de_credito_autorizado}
            tipo_de_pago_comun: "${empresaJSON.tipo_de_pago_comun}"
          }
        ) {
          mensaje
          empresa{
            id
            nombre_comercial
            nombre_razon_social
            telefono
            correo
            NIT
            direccion_fiscal
            direccion_comercial
            descripcion
            convenio
            credito_autorizado
            dias_de_credito_autorizado
            tipo_de_pago_comun
            contacto_directo{
              nombre
              apellidos
              correo
              telefono
              celular
              direccion
              sexo
            }
          }
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
