import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import DashboardComponent from '../../dashboard/components/DashboardComponent.jsx';
import Select from 'react-select';
import { formatterTwoDecimals } from 'MyModules/formatterDecimals.js';
import FetchEmpresas from '../../../../apiService/FetchGraphQLEmpresas';

import FetchGetProductosProveedor from './RealizarOrden/getProductosProveedor/FetchGraphQLGetProductosProveedor';
import FetchDeleteProductoProveedor from './FetchDeleteProductoProveedor/FetchDeleteProductoProveedorPromise';

class VerProductosCompras extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            filter: {
                nombre: "",
                proveedor: "",
                valProveedor: null,
            },
            productosProveedor: []
        }

        this.onInputProveedorChange = this.onInputProveedorChange.bind(this);
        this.onClickResetProveedor = this.onClickResetProveedor.bind(this);
        this.onClickSearchProductosProveedor = this.onClickSearchProductosProveedor.bind(this);
        this.onDeleteProducto = this.onDeleteProducto.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
    }

    componentDidMount(){
        FetchEmpresas(this)
    }

    onFilterChange(event){
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                [event.target.id]: event.target.value
            }
        })
    }

    onInputProveedorChange(selectObj){

        this.setState({
            filter: {
                ...this.state.filter,
                proveedor: selectObj.label,
                valProveedor: selectObj
            } 
        })

    }

    onClickResetProveedor(){
        this.setState({
            filter: {
                ...this.state.filter,
                proveedor: "",
                valProveedor: null
            }
        })
    }

    onClickSearchProductosProveedor(){
        FetchGetProductosProveedor(this, this.state.filter.valProveedor.value)
    }

    onDeleteProducto(idProducto){
       FetchDeleteProductoProveedor(this, idProducto) 
    }

    render(){
        let selectProveedores  = Array.from(this.props.empresas.filter( empresa => empresa.clasificacion_empresa.includes('Proveedor') ), empresa => {
            return { value: empresa.id, label: `${empresa.nombre_comercial}` }
        })

        let productos = this.state.filter.nombre === "" ? this.state.productosProveedor : this.state.productosProveedor.filter( producto => producto.nombre.toLowerCase().includes(this.state.filter.nombre.toLowerCase()) )

        return(
            <DashboardComponent>
                <div className="row mx-0 px-3">
    
                    <div className="col-12">
                        <h1 className="text-center">Ver Productos de Proveedor</h1>
                    </div>
    
                    <div className="form-group col-12 col-md-4 col-lg-3 mx-auto text-center">
                        <label htmlFor="proveedor" className="col"><b>Proveedor:</b></label>
                        <Select id="proveedor" 
                            options={selectProveedores} 
                            onChange={this.onInputProveedorChange}
                            onInputChange={this.onInputTextProveedorChange}
                            placeholder="Busca un Proveedor"
                            value={this.state.filter.valProveedor}
                            onMenuClose={ () => this.setState({ filter: { ...this.state.filter } }) } 
                        />
                        <button type="button" className="mt-2 btn btn-outline-info" onClick={this.onClickResetProveedor}>Limpiar Filtro</button>
                        <small id="proveedorSmall" className="form-text text-muted">Proveedor.</small>
                    </div>

                    <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                        <label htmlFor="nombre" className="col"><b>Filtra por nombre de Producto:</b></label><input onChange={this.onFilterChange} type="text"  className="form-control" id="nombre" value={this.state.filter.nombre} placeholder="Escribe el Nombre de un Producto o Servicio" />
                        <small id="nombreSmall" className="form-text text-muted">Filtra por nombre de Producto.</small>
                    </div>

                    <div className="form-group col-12 text-center">
                        <button type="button" className="mt-2 btn btn-outline-success" onClick={this.onClickSearchProductosProveedor}>Buscar Productos</button> 
                    </div>

                    <div className="col-12">
                        <div className="table-responsive">
                            <table className="table text-center table-bordered table-hover table-sm">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Nombre Producto</th>
                                        <th scope="col" className="d-none d-md-table-cell">Descripción</th> 
                                        <th scope="col">Valor Unitario</th>
                                        <th scope="col">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        productos.map( (producto) => {
            
                                            return(
                                                <tr key={producto.id}>
                                                    <td>{producto.nombre}</td>
                                                    <td className="d-none d-md-table-cell">{producto.descripcion}</td>
                                                    <td>Q. {formatterTwoDecimals.format(producto.valorUnitario)}</td>
                                                    <td>
                                                        <NavLink exact to={`/dashboard/admin-compras/edit-producto/${producto.id}` } activeClassName="active" className="active" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be', marginRight: '5px'}} >
                                                            <FontAwesomeIcon icon="edit" /> 
                                                        </NavLink>

                                                        <button type="button" onClick={() => {
                                                                this.onDeleteProducto(producto.id)
                                                            }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                                                            <FontAwesomeIcon icon="trash-alt" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
            
                                        })
                                    }
            
                                </tbody>
                            </table>
                        </div>
                    </div>

    
                </div>
            </DashboardComponent>
        )
    }

}

let mapStateToProps = (state, props) => {
    return {
      usuario: state.logIn.usuario,
      empresas: state.dataEmpresas.empresas,
      usuarios: state.dataUsers.usuarios
    }
}

export default connect(mapStateToProps)(VerProductosCompras)