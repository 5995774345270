let consulta = (empresaJSON) => {

  let clasificacion_empresa = JSON.stringify(empresaJSON.clasificacion_empresa)

  const string =
    `mutation updateEmpresa {
      empresaMutation{
        updateEmpresa(
          empresaData: {
            id: "${empresaJSON.id}"
            nombre_comercial: "${empresaJSON.nombre_comercial}"
            nombre_razon_social: "${empresaJSON.nombre_razon_social}"
            telefono: "${empresaJSON.telefono}"
            correo: "${empresaJSON.correo}"
            NIT: "${empresaJSON.NIT}"
            direccion_fiscal: "${empresaJSON.direccion_fiscal}"
            direccion_comercial: "${empresaJSON.direccion_comercial}"
            descripcion: "${empresaJSON.descripcion}"
            convenio: "${empresaJSON.convenio}"
            credito_autorizado: ${empresaJSON.credito_autorizado}
            dias_de_credito_autorizado: ${empresaJSON.dias_de_credito_autorizado}
            tipo_de_pago_comun: "${empresaJSON.tipo_de_pago_comun}"
            clasificacion_empresa: ${clasificacion_empresa}
            proveedor_autorizado: ${empresaJSON.proveedor_autorizado}
          }
        ) {
          mensaje
          id
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
