let consulta = (usuarioJSON) => {

  //console.log(usuarioJSON.empresa_asociada)

  //let permisos

  let permisosAdminUI = usuarioJSON.permisos.AdminUI === null ? false : usuarioJSON.permisos.AdminUI,
  permisosAdminUser = usuarioJSON.permisos.AdminUser === null ? {
    ViewUser: false,
    CreateUser: false,
    EditUser: false,
    DeleteUser: false
  } : usuarioJSON.permisos.AdminUser,
  permisosAdminEmpresa = usuarioJSON.permisos.AdminEmpresa === null ? {
    ViewEmpresa: false,
    CreateEmpresa: false,
    EditEmpresa: false,
    DeleteEmpresa: false
  } : usuarioJSON.permisos.AdminEmpresa,
  permisosMensajeria = usuarioJSON.permisos.Mensajeria === null ? {
    QRScanner: false,
    ViewOrden: false,
    CreateOrden: false,
    EditOrden: false,
    DeleteOrden: false,
    ActOrdenAdmin: false,
    ActOrdenMuestra: false,
    CheckListOrden: false,
    ViewRuta: false,
    RealizarRuta: false,
    EditRuta: false,
    DeleteRuta: false,
    CheckListRuta: false,
    ViewPlantillaRuta: false,
    RealizarPlantillaRuta: false,
    EditPlantillaRuta: false,
    DeletePlantillaRuta: false
  } : usuarioJSON.permisos.Mensajeria,
  permisosAdminObjetivos = usuarioJSON.permisos.AdminObjetivos === null ? false : usuarioJSON.permisos.AdminObjetivos,
  permisosCompras = usuarioJSON.permisos.Compras === null ? {
    CreateOrdenCompra: false,
    AddProductoCompra: false,
    ViewProductosCompras: false,
  } : usuarioJSON.permisos.Compras,
  permisosProductos = usuarioJSON.permisos.Productos === null ? {
    ViewProducto: false,
    CreateProducto: false,
    EditProducto: false,
    DeleteProducto: false,
    ViewTipoCliente: false,
    CreateTipoCliente: false,
    EditTipoCliente: false,
    DeleteTipoCliente: false,
    ViewAseguradora: false,
    CreateAseguradora: false,
    EditAseguradora: false,
    DeleteAseguradora: false,
  } : usuarioJSON.permisos.Productos

  let permisos = {
    ...usuarioJSON.permisos,
    AdminUI: permisosAdminUI,
    AdminUser: permisosAdminUser,
    AdminEmpresa: permisosAdminEmpresa,
    Mensajeria: permisosMensajeria,
    AdminObjetivos: permisosAdminObjetivos,
    Compras: permisosCompras,
    Productos: permisosProductos,
  }
  
  permisos = JSON.stringify(permisos).replace(/{"/g, '{').replace(/":/g, ':').replace(/,"/g, ',')

  let empresa_asociada,
      colaborador

  if (usuarioJSON.empresa_asociada.id !== null) {
    empresa_asociada = `empresa_asociada: {
      id: "${usuarioJSON.empresa_asociada.id}"
      nombre: "${usuarioJSON.empresa_asociada.nombre}"
      telefono: ${usuarioJSON.empresa_asociada.telefono}
      correo: "${usuarioJSON.empresa_asociada.correo}"
    }`
  } else { 
    empresa_asociada = "empresa_asociada: null"
  }

  if ((usuarioJSON.colaborador.puesto !== null)) {

    /*let ids_empresas
    if ((usuarioJSON.colaborador.ids_empresas === null) || (usuarioJSON.colaborador.ids_empresas[0] === "")) { 
      ids_empresas = null
    } else {
      ids_empresas = usuarioJSON.colaborador.ids_empresas
      console.log(ids_empresas)
    }*/

    colaborador = `colaborador: {
      departamento: "${usuarioJSON.colaborador.departamento}"
      puesto: "${usuarioJSON.colaborador.puesto}"
    }`
  } else { 
    colaborador = "colaborador: null"
  }

  const string =
    `mutation insertUser {
      userMutation{
        insertUser(
          insertUser: {
            usuario: "${usuarioJSON.usuario}"
            password: "${usuarioJSON.password}"
            email: "${usuarioJSON.email}"
            nombre: "${usuarioJSON.nombre}"
            apellidos: "${usuarioJSON.apellidos}"
            telefono: ${usuarioJSON.telefono}
            celular: ${usuarioJSON.celular}
            DPI: "${usuarioJSON.DPI}"
            fecha_de_nacimiento: "${usuarioJSON.fecha_de_nacimiento}"
            direccion:"${usuarioJSON.direccion}"
            NIT: "${usuarioJSON.NIT}"
            nota: "${usuarioJSON.nota}"
            sexo: "${usuarioJSON.sexo}"
            tipo: "${usuarioJSON.tipo}"
            permisos: ${permisos}
            ${empresa_asociada}
            ${colaborador}
            idSucursal: ${usuarioJSON.idSucursal}
          }
        ) {
          mensaje
          usuario{
            id
            usuario
            nombre
            apellidos
            email
            telefono
            celular
            DPI
            fecha_de_nacimiento
            direccion
            NIT
            nota
            sexo
            tipo
            permisos {
              AdminUI
              AdminUser {
                ViewUser
                CreateUser
                EditUser
                DeleteUser
              }
              AdminEmpresa{
                ViewEmpresa
                CreateEmpresa
                EditEmpresa
                DeleteEmpresa
              }
              Mensajeria{
                QRScanner
                ViewOrden
                CreateOrden
                EditOrden
                DeleteOrden
                ActOrdenAdmin
                ActOrdenMuestra
                ViewRuta
                RealizarRuta
                EditRuta
                DeleteRuta
                ViewPlantillaRuta
                RealizarPlantillaRuta
                EditPlantillaRuta
                DeletePlantillaRuta
              }
              AdminObjetivos
              Compras{
                CreateOrdenCompra
                AddProductoCompra
                VerOrdenesCompra
                ViewProductosCompras
              }
            }
            usuario_activo
            empresa_asociada{
              nombre
              telefono
              correo
            }
            colaborador{
              puesto
              departamento
            }
            idSucursal
          }
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
