//funciones de React
import React, { Component } from 'react';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

import sessionService from '../../sessionService/sessionService';

import GraphQL from '../../apiService/fetchGraphQL.js';
import url from '../../apiService/ApiUrl.js';

import Loader from '../Components/Loader.jsx';

//import css
import '../../MyModules/css/slick.css';
import '../../MyModules/css/slick-theme.css';
//import icomoon
import '../Components/fonts/icomoon/style.css';

//Importando Jquery y módulos
//import $ from 'jquery';
import '../../MyModules/jquery.easing.1.3.js';


//Importando Bootstrap
import 'popper.js';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

//Importando summernote
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/monokai.css';
import 'codemirror/lib/codemirror';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/xml/xml';

//import summernote from 'summernote/dist/summernote-bs4';
import 'summernote/dist/summernote-bs4';
import 'summernote/dist/summernote-bs4.css'; // import styles
import 'summernote/dist/lang/summernote-es-ES'; // you can import any other locale*/

//Router
import { BrowserRouter as Router } from 'react-router-dom';

//Components
import PrincipalRoutes from '../Components/PrincipalRoutes';

//Importando Iconos de FontAwesome

import { library } from '@fortawesome/fontawesome-svg-core'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faUsersCog, faEdit, faTachometerAlt, faAngleLeft, faAngleRight, faBars, faTimes, faSave, faEye, faTrashAlt, faPlusCircle, faUserCircle, faSearch, faBuilding, faUnlockAlt, faUsers, faUserPlus, faQrcode, faDollyFlatbed, faClipboardList, faMotorcycle, faUser, faVials, faRoute, faMinusCircle, faSyncAlt, faFileAlt, faCheck, faArrowLeft, faCheckCircle, faCalendarCheck, faHistory, faCashRegister, faListAlt, faPhone, faEnvelopeOpenText, faCartPlus, faFileDownload, faFileUpload, faPrint, faFileMedicalAlt, faMicroscope, faDna, faFlask, faPaw, faUserMd, faBoxes } from '@fortawesome/free-solid-svg-icons';

import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(faSignOutAlt, faUsersCog, faEdit, faTachometerAlt, faAngleLeft, faAngleRight, faBars, faTimes, faSave, faEye, faTrashAlt, faPlusCircle, faUserCircle, faSearch, faBuilding, faUnlockAlt, faUsers, faUserPlus, faQrcode, faDollyFlatbed, faClipboardList, faMotorcycle, faUser, faVials, faRoute, faMinusCircle, faSyncAlt, faFileAlt, faCheck, faArrowLeft, faCheckCircle, faCalendarCheck, faHistory, faCashRegister, faListAlt, faPhone, faEnvelopeOpenText, faCartPlus, faFileDownload, faFileUpload, faPrint, faFileMedicalAlt, faMicroscope, faDna, faFlask, faPaw, faUserMd, faBoxes, fab )

class App extends Component{

	constructor(props) {

		super(props);



		this.inactivityTime = this.inactivityTime.bind(this);

	}

	componentDidMount(){

		sessionService.getLogIn(url, this)
		GraphQL(this)

	}

	inactivityTime () {

		let This = this

		var time;
		window.onload = resetTimer;
		// DOM Events
		document.onmousemove = resetTimer;
		document.onkeypress = resetTimer;
		document.onclick = resetTimer;
	
		function logout() {
			//alert("You are now logged out.")
			//location.href = 'logout.html'

			let sessionServiceCallback = () => {
      
				if(This.props.conexion === true){
				  return <Loader />
				}else{
				  return <Redirect to='/login' />
				}
			  }
		  
			  sessionService.logOut(url, This, sessionServiceCallback)

		}
	
		function resetTimer() {

			clearTimeout(time);

			if( (This.props.usuario !== null) && (This.props.usuario.colaborador !== null) && (This.props.usuario.colaborador.puesto === "Mensajero") ){
				time = setTimeout(logout, (((1000 * 60) * 60) * 24) )
				// 1000 milliseconds = 1 second
			}else if(This.props.usuario !== null){
				time = setTimeout(logout, ((1000 * 60) * 10))
				// 1000 milliseconds = 1 second
			}

		}
	};

	render(){

		if(this.props.conexion === true){
			this.inactivityTime()
		}

		if(this.props.dataUI === null){
			return <Loader />
		}else{
			//console.re.log(this.props.dataUI);
			return(
				<Router>
					<PrincipalRoutes />
				</Router>
			)
		}
	}
}

let mapStateToProps = (state, props) => {
  return {
	conexion: state.logIn.conexion,
    usuario: state.logIn.usuario,
    dataUI: state.dataUI
  }
}

export default connect(mapStateToProps)(App);
