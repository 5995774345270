import React, {Component} from 'react';

import { connect } from 'react-redux';

import DiagnositoClinicoComponent from '../../Components/AnalisisClinicos/DiagnosticoClinico.jsx';

class AnalisisPreventivos extends Component {
  constructor(props){
		super(props)

    // This binding is necessary to make `this` work in the callback
    this.handleOpenModal = this.handleOpenModal.bind(this);
	}

  handleOpenModal(modal){
    this.props.dispatch({
		//props.dispatch({
			type: `OPEN_MODAL`,
			payload: {
				modal,
        typeModal: 'analisis'
			}
		})

  }

  render(){
    let modalsClinicoPacientes = this.props.dataUI.AllModals.modalsClinicos.modalsClinicosPacientes,
        modalsClinicoLaboratorios = this.props.dataUI.AllModals.modalsClinicos.modalsClinicosLaboratorios,
        modalsClinicoVeterinaria = this.props.dataUI.AllModals.modalsClinicos.modalsClinicosVeterinaria
    if(this.props.path === '/pacientes/analisis-clinicos'){
      return(
        <DiagnositoClinicoComponent>
          <div className="container">
          	<div className="row">
          			<h4 className="col-12">DIAGNÓSTICO CLÍNICO</h4>
          	</div>
          	<div className="row justify-content-center">
                {
                  modalsClinicoPacientes.map( modal => {
                    return(
                      <span key={modal.id} onClick={() => this.handleOpenModal(modal)} data-toggle="modal" data-target={`#Modal-${modal.id}`} className="col-sm-6 col-12"><p className="examenes text-left"><span style={{'color': '#3dbcb2'}}>• </span> {modal.titulo}</p></span>
                    )
                  })
                }
          	</div>
          </div>
        </DiagnositoClinicoComponent>
      )
    }else if(this.props.path === '/laboratorios-y-hospitales/analisis-clinicos'){
      return(
        <DiagnositoClinicoComponent>
          <div className="container">
          	<div className="row">
          			<h4 className="col-12">DIAGNÓSTICO CLÍNICO</h4>
          	</div>
          	<div className="row justify-content-center">
                {
                  modalsClinicoLaboratorios.map( modal => {
                    return(
                      <span key={modal.id} data-toggle="modal" onClick={() => this.handleOpenModal(modal)} data-target={`#Modal-${modal.id}`} className="col-sm-6 col-12"><p className="examenes text-left"><span style={{'color': '#3dbcb2'}}>• </span> {modal.titulo}</p></span>
                    )
                  })
                }
          	</div>
          </div>
        </DiagnositoClinicoComponent>
      )
    }else if(this.props.path === '/veterinaria/analisis-clinicos'){
      return(
        <DiagnositoClinicoComponent>
          <div className="container">
          	<div className="row">
          			<h4 className="col-12">DIAGNÓSTICO CLÍNICO</h4>
          	</div>
          	<div className="row justify-content-center">
                {
                  modalsClinicoVeterinaria.map( modal => {
                    return(
                      <span key={modal.id} data-toggle="modal" onClick={() => this.handleOpenModal(modal)} data-target={`#Modal-${modal.id}`} className="col-sm-6 col-12"><p className="examenes text-left"><span style={{'color': '#3dbcb2'}}>• </span> {modal.titulo}</p></span>
                    )
                  })
                }
          	</div>
          </div>
        </DiagnositoClinicoComponent>
      )
    }
  }
}

let mapStateToProps = (state, props) => {
  return {
    dataUI: state.dataUI
  }
}


export default connect(mapStateToProps)(AnalisisPreventivos);
