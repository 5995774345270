let consulta = (idOrden, data) => {

  let correo = data.correo === null ? data.correo : `"${data.correo}"`

  let razonDeFueraDeCondiciones =  data.razonDeFueraDeCondiciones === undefined ? null : `"${data.razonDeFueraDeCondiciones}"`

  let string = `mutation{
    ordenesCompraMutation{
      updateStateOrdenCompra(idOrden: ${idOrden}, estado: "${data.estado}", correo: ${correo}, razonDeFueraDeCondiciones: ${razonDeFueraDeCondiciones} ){
        mensaje
        estado
        emailTracking{
          estado
          fecha
          correo
        }
      }
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
