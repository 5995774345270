import React from 'react';

import './css/NavbarInicio.css';

let NavbarPacientes = props => (
	<nav id="NavbarInicio" className="navbar navbar-expand-md navbar-default fixed-top">
		{props.children}
	</nav>
)

export default NavbarPacientes;
