let consulta = (rutasDesde, rutasHasta) => {
  let string = `{
    appInfo{
      rutasMensajeria(rutasDesde: "${rutasDesde}", rutasHasta: "${rutasHasta}"){
        id
        idMensajero
        nombreRuta
        ordenesRuta
        estado
        fecha
        checkList{
          nombreQuienRevisa
          gelRefrigerante
          condicionesHielera
          muestras
          cantidadDePaquetesTruly
          cantidadDePaquetes
          observaciones
          fecha
        }
      }
    }
  }`

  /*return {
    "query": string
  }*/

  return string

}

export default consulta;
