const initialState = {
  visible: false,
  modal: null,
  typeModal: null
}

let Modal = (state = initialState, action) => {
  switch (action.type) {

    case 'OPEN_MODAL': {

      let modal = action.payload.modal,
          typeModal = action.payload.typeModal,
          visible = true

      return {
        visible,
        modal,
        typeModal
      }

    }
    case 'CLOSE_MODAL': {

      let modal = null,
          visible = false

      return {
        visible,
        modal,
        typeModal: null
      }

    }
      //break;
    default:
      return state
  }
}

export default Modal
