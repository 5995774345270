let consulta = (data) => {
  let string = `
  mutation cancelStateYRazonDeCancelacion{
    mensajeriaMutation {
      cancelStateYRazonDeCancelacion(id: "${data.id}", estado: "${data.estado}", razonDeCancelacion: "${data.razonDeCancelacion}")
      {
        id
        mensaje
      }
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
