// JavaScript Document
import GraphQL from './ConsultaGraphQLPlantillasRutasMensajeria';

import ID from '../Service/ID.js';

import url from './ApiUrl.js';
import urlencode from 'urlencode';


function handleError(err){
	console.log(`Request failed: ${err}`);
}

const fetchPlantillasRutasMensajeria = (This) => {

	const options = {
		method: 'GET',
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(`${url}?query=query${urlencode(GraphQL())}`, options)
	.then(res => res.json())
	.then((res) => {

		let plantillasRutasMensajeria = res.data.appInfo.plantillasRutasMensajeria

		plantillasRutasMensajeria = plantillasRutasMensajeria.map( plantilla => {
			plantilla.ordenes.forEach( orden =>{
				return orden.idProvisional = ID()
			})
			return plantilla
		})

		This.props.dispatch({
			type: 'FETCH_PLANTILLAS_RUTAS_MENSAJERIA',
			payload: {
				plantillasRutasMensajeria
			}
		})

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchPlantillasRutasMensajeria;