import React, { Component } from 'react';

import { connect } from 'react-redux';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../../../../Modal/Components/Modal.jsx';

import EmpresaDataView from './EmpresaDataView.jsx'
import SucursalDataView from './SucursalDataView';

import FetchDeleteSucursal from '../DeleteSucursalFetch/FetchDeleteSucursal';

class EmpresaView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      route: "EmpresaDataView",
      sucursalViewData: null
    }

    this.handleChangeDisplay = this.handleChangeDisplay.bind(this);
    this.handleDeleteSucursal = this.handleDeleteSucursal.bind(this);
  }

  handleChangeDisplay(type, data){
    if(type === "EmpresaDataView"){
      this.setState({
        route: type,
        sucursalViewData: null
      })
    }else if(type === "SucursalDataView"){
      this.setState({
        route: type,
        sucursalViewData: data
      })
    }
  }

  handleDeleteSucursal(sucursal){
    FetchDeleteSucursal(this, sucursal)
  }

  render(){

    let empresa = this.props.empresa,
      modalVisible = this.props.modalVisible,
      handleCloseModal = this.props.handleCloseModal

    return(
        <ModalContainer>
          <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <img className="brand-modal" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco" />
                  <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                    <div></div>
                    <div></div>
                  </button>
                </div>

                {
                  this.state.route === "EmpresaDataView" ? (
                    <EmpresaDataView empresa={empresa} sucursales={this.props.sucursales} handleChangeDisplay={this.handleChangeDisplay} handleDeleteSucursal={this.handleDeleteSucursal}/>
                  ) : null
                }

                {
                  this.state.route ==="SucursalDataView" ? (
                    <SucursalDataView sucursal={this.state.sucursalViewData} handleChangeDisplay={this.handleChangeDisplay} usuarios={this.props.usuarios} empresa={empresa} />
                  ) : null
                }

                <div className="modal-footer">
                  <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
                </div>
              </div>
            </div>
        </ModalB4>
      </ModalContainer>
    )
  }

}

let mapStateToProps = (state, props) => {
  return {
    usuarios: state.dataUsers.usuarios,
    sucursales: state.dataEmpresas.sucursales
  }
}

export default connect(mapStateToProps)(EmpresaView);
