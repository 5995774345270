let consulta = (user) => {

  //console.log(usuarioJSON.sexo)

  const string =
    `mutation deleteUser {
      userMutation{
        deleteUser(
          idUser: "${user.id}"
        ) {
          idUser
          mensaje
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
