import React from 'react';
import './css/PortadaAnalisisClinicos.css';

const PortadaAnalisisClinicos = props => {

    return(
        <section id="portada-pacientes-analisis-clinicos"className="container-fluid">    
            <div className="row py-5">
                <div className="col-12 col-xl-6 d-flex flex-column mr-md-0 pr-md-0">
                    <h1 className="text-justify pl-5">Laboratorio Clínico</h1>
                    <div className="info-tab d-flex flex-column flex-md-row align-items-center mb-3">
                        <div id="servicio-icon" className="mx-2 mx-sm-3 my-2 mx-md-5 icon">
                            <img className="img-fluid p-3" src="/img/pacientes/analisisclinico/lab-icon.svg" alt="icono lab inicio pacientes" />
                        </div>
                        <div className="py-3 px-4 px-md-0">
                            <h3 className="text-center text-md-left">Servicio</h3>
                            <p className="mb-0">Servicio a personas particulares y colaboradores de empresas <br />
                                Servicio personalizado de toma de muestra</p>
                        </div>
                    </div>
                    <div className="info-tab d-flex flex-column flex-md-row align-items-center mb-3">
                        <div id="personal-icon" className="mx-2 mx-sm-3 my-2 mx-md-5 icon">
                            <img className="img-fluid p-3" src="/img/pacientes/analisisclinico/personal-icon.svg" alt="icono personal inicio pacientes" />
                        </div>
                        <div className="py-3 px-4 px-md-0">
                            <h3 className="text-center text-md-left">Personal</h3>
                            <p className="mb-0">Personal experimentado en la toma de muestra en niños y adultos</p>
                        </div>
                    </div>
                    <div className="info-tab d-flex flex-column flex-md-row align-items-center mb-3">
                        <div id="sucursales-icon" className="mx-2 mx-sm-3 my-2 mx-md-5 icon">
                            <img className="img-fluid p-3" src="/img/pacientes/analisisclinico/ubicacion-icon.svg" alt="icono lab inicio pacientes" />
                        </div>
                        <div className="py-3 px-4 px-md-0">
                            <h3 className="text-center text-md-left">Sucursales</h3>
                            <p className="mb-0">• Avenida Petapa<br/>
                                                • Paseo Cayalá<br/> 
                                                • Roosevelt<br/>
                                                • Atanasio </p>
                        </div>
                    </div>
                    <div className="info-tab d-flex flex-column flex-md-row align-items-center mb-3">
                        <div id="resultados-en-linea-icon" className="mx-2 mx-sm-3 my-2 mx-md-5 icon">
                            <img className="img-fluid p-3" src="/img/pacientes/analisisclinico/resultados-icon.svg" alt="icono lab inicio pacientes" />
                        </div>
                        <div className="py-3 px-4 px-md-0">
                            <h3 className="text-center text-md-left">Resultados en línea</h3>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-6 ml-0 pl-xl-0 d-flex justify-content-center">
                    <img id="img-portada" className="img-fluid" src="/img/pacientes/analisisclinico/PortadaInicio.png" alt="portada Inicio Pacientes" />
                </div>
            </div>
        </section>
    )

}

export default PortadaAnalisisClinicos