let consulta = (data) => {

  //console.log(data)

  let stringOrdenArray = []

  data.ordenes.forEach(orden => {

    if(orden.tipo === 'RecoleccionMuestra'){
      let stringOrden = JSON.stringify(orden).replace(/{"/g, '{').replace(/":/g, ':').replace(/,"/g, ',').replace(/",instrucciones/g, ',instrucciones').replace(/cantidadDeMuestras:"/g, 'cantidadDeMuestras:')
      stringOrdenArray.push(stringOrden)
    }else {
      let stringOrden = JSON.stringify(orden).replace(/":/g, ':').replace(/,"/g, ',').replace(/{"/g, '{')
      stringOrdenArray.push(stringOrden)
    }

  });

  let string = `mutation updatePlantillaRutaMensajeria {
    mensajeriaMutation {
      updatePlantillaRutaMensajeria(plantilla: {
        id: "${data.id}"
        nombrePlantilla: "${data.nombrePlantilla}"
        ordenes: [${stringOrdenArray}]
      }){
        id
        mensaje
      }
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
