import GraphQL from './ConsultaGraphQL';

import url from '../../../../../../apiService/ApiUrl.js';

// JavaScript Document
function handleError(err, This){
	console.log(`Request failed: ${err}`);
	This.setState({
		etiquetas: {
			...This.state.etiquetas,
			value: {
				label: "No Se pudo ingresar el tipo, prueba nuevamente"
			},
			isLoading: false
		}
	})
}

const insertEmpresa = (This, etiquetaJSON, newOption) => {
	//console.log(usuarioJSON)
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(etiquetaJSON)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		This.setState({
			etiquetas: {
				...This.state.etiquetas,
				value: newOption,
				isLoading: false
			} 
		})

		This.props.dispatch({
			type: 'ADD_ETIQUETA_TO_ETIQUETAS',
			payload: {
				dataEtiqueta: {
					id: res.data.adminObjetivos.insertEtiqueta.id,
					nombre: etiquetaJSON.nombre,
					tipoEvento: etiquetaJSON.tipoEvento
				}
			}
		})

		return res
	})
	.catch(err => handleError(err, This));

	//termina fetch
}

export default insertEmpresa;
