let consulta = (empresa) => {

  //console.log(usuarioJSON.sexo)

  const string =
    `mutation deleteEmpresa {
      empresaMutation{
        deleteEmpresa(
          idEmpresa: "${empresa.id}"
        ) {
          idEmpresa
          mensaje
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
