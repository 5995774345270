/*General*/
import AnalisisClinicos from '../../../WebPage/Components/AnalisisClinicos/AnalisisClinicos.jsx';

/*Laboratorios Y Hospitales*/
//import QuienesSomosLabsYHosp from '../../../WebPage/LaboratoriosYHospitales/QuienesSomos/Components/QuienesSomos.jsx';
import LabsYHospContacto from '../../../WebPage/LaboratoriosYHospitales/Contacto/Components/Contacto.jsx';

/*const QUIENES_SOMOS_HOSP_Y_LAB = {
    component: QuienesSomosLabsYHosp,
    path: "/laboratorios-y-hospitales"
}*/

const ANALISIS_CLINICOS_HOSP_Y_LAB = {
    component: AnalisisClinicos,
    path: "/laboratorios-y-hospitales/analisis-clinicos"
}

const CONTACTO_HOSP_Y_LAB = {
    component: LabsYHospContacto,
    path: "/laboratorios-y-hospitales/contacto"
}

const routesHospYLab = [ ANALISIS_CLINICOS_HOSP_Y_LAB,  CONTACTO_HOSP_Y_LAB ]


export default routesHospYLab