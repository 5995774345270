let consulta = () => {
  let string = `{
  dataUI {
    textos {
      id
      texto
    }
    galeriasImg {
      id
      imagen
      posicion
    }
    galeriasVid {
      id
      url
      posicion
    }
    imagenes {
      id
      imagen
    }
    imgMiembros {
      id
      imagen
    }
    imgReconocimiento {
      id
      imagen
    }
    AllModals {
      modalsPreventivos {
        modalsPreventivosPacientes {
          id
          titulo
          imagen
          tipo
          texto
          tabla
          ubicacion
        }
        modalsPreventivosLaboratorios {
          id
          titulo
          imagen
          tipo
          texto
          tabla
          ubicacion
        }
      }
      modalsClinicos {
        modalsClinicosPacientes {
          id
          titulo
          imagen
          tipo
          texto
          tabla
          ubicacion
        }
        modalsClinicosLaboratorios {
          id
          titulo
          imagen
          tipo
          texto
          tabla
          ubicacion
        }
        modalsClinicosVeterinaria {
          id
          titulo
          imagen
          tipo
          texto
          tabla
          ubicacion
        }
      }
    }
    promociones {
      id
      titulo
      promocion
      imagen
    }
  }
}`

  /*return {
    "query": string
  }*/

  return string

}

export default consulta;


/*Agregar a AllModals para la quiery si se quiere
modals {
  id
  titulo
  tipo
  imagen
  texto
  tabla
  ubicaciones
  nom_pag
  nom_ubi
}*/
