import React from 'react';

import './css/AnalisisPreventivos.css';

const AnalisisPreventivo = (props) => (
	<section id="seccion-examenes-preventivos">
		{props.children}
	</section>
)

export default AnalisisPreventivo;
