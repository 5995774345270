import React from 'react';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../../../Modal/Components/Modal.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let UserView = props => {

  let usuario = props.usuario,
      modalVisible = props.modalVisible,
      handleCloseModal = props.handleCloseModal

  let fecha_de_nacimiento_Convertido = new Date(parseInt(usuario.fecha_de_nacimiento))
  let mesFecha
  (fecha_de_nacimiento_Convertido.getMonth()+1) < 10 ? mesFecha = `0${fecha_de_nacimiento_Convertido.getMonth()+1}` : mesFecha = `${fecha_de_nacimiento_Convertido.getMonth()+1}`
  let diaFecha
  fecha_de_nacimiento_Convertido.getDate() < 10 ? diaFecha = `0${fecha_de_nacimiento_Convertido.getDate()}` : diaFecha = `${fecha_de_nacimiento_Convertido.getDate()}`
  let fechaFinal = `${diaFecha}/${mesFecha}/${fecha_de_nacimiento_Convertido.getFullYear()}`

  return(
      <ModalContainer>
        <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
              <img className="brand-modal" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco" />
              <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                <div></div>
                <div></div>
              </button>
              </div>

              <div className="modal-body sin-margen-y-padding container-fluid">
                <div className="row text-center">
                  <div className="col-12">
                    <FontAwesomeIcon icon="user-circle" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                    <h1>{usuario.nombre} {usuario.apellidos}</h1>
                  </div>
                  <div className="col-12" style={{'marginTop': '25px'}}>

                      <div className="row">

                        <div className="col-md-4 row">
                          <p className="col" id="usuario" style={{'marginTop': '0px'}}><b>Usuario:</b> {usuario.usuario}</p>
                        </div>

                        <div className="col-md-4 row">
                          <p className="col" id="nombre" style={{'marginTop': '0px'}}><b>Nombre:</b> {usuario.nombre}</p>
                        </div>

                        <div className="col-md-4 row">
                          <p className="col" id="apellidos" style={{'marginTop': '0px'}}><b>Apellidos:</b> {usuario.apellidos}</p>
                        </div>

                        <div className="col-md-4 row">
                          <p className="col" id="telefono" style={{'marginTop': '0px'}}><b>Teléfono:</b> {usuario.telefono}</p>
                        </div>

                        <div className="col-md-4 row">
                          <p className="col" id="celular" style={{'marginTop': '0px'}}><b>Celular:</b> {usuario.celular}</p>
                        </div>

                        <div className="col-md-4 row">
                          <p className="col" id="email" style={{'marginTop': '0px'}}><b>Correo:</b> {usuario.email}</p>
                        </div>

                        <div className="col-md-4 row">
                          <p className="col" id="DPI" style={{'marginTop': '0px'}}><b>DPI:</b> {usuario.DPI}</p>
                        </div>

                        <div className="col-md-4 row">
                          <p className="col" id="fecha-de-naciemiento" style={{'marginTop': '0px'}}><b>Fecha de Nacimiento:</b> {fechaFinal}</p>
                        </div>

                        <div className="col-md-4 row">
                          <p className="col" id="direccion" style={{'marginTop': '0px'}}><b>Dirección:</b> {usuario.direccion}</p>
                        </div>

                        <div className="col-md-4 row">
                          <p className="col" id="NIT" style={{'marginTop': '0px'}}><b>NIT:</b> {usuario.NIT}</p>
                        </div>

                        <div className="col-md-4 row">
                          <p className="col" id="nota" style={{'marginTop': '0px'}}><b>Nota:</b> {usuario.nota}</p>
                        </div>

                        <div className="col-md-4 row">
                          <p className="col" id="sexo" style={{'marginTop': '0px'}}><b>Sexo:</b> {usuario.sexo}</p>
                        </div>

                        <div className="col-md-4 row">
                          <p className="col" id="tipo" style={{'marginTop': '0px'}}><b>Tipo:</b> {usuario.tipo}</p>
                        </div>

                        <div className="col-md-4 row">
                          {/*<p className="col" id="tipo" style={{'marginTop': '0px'}}><b>Permisos:</b>
                            {
                              usuario.permisos.map( (permiso, index) => {
                                return <i key={index}> {permiso}, </i>
                              })
                            }
                          </p>*/}
                          {/*<ul>
                            {
                              usuario.permisos.map( (permiso, index) => {
                                return <li key={index}>{permiso}</li>
                              })
                            }
                          </ul>*/}
                        </div>

                      </div>

                  </div>

                  <div className="col-12">
                    <FontAwesomeIcon icon="building" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                    <h1>Empresa Asociada: {usuario.empresa_asociada.nombre}</h1>
                  </div>
                  <div className="col-12 row" style={{'marginTop': '25px'}}>

                    <div className="col-md-4 row">
                      <p className="col" id="nombreEmpresa" style={{'marginTop': '0px'}}><b>Nombre de Empresa:</b> {usuario.empresa_asociada.nombre}</p>
                    </div>

                    <div className="col-md-4 row">
                      <p className="col" id="TelefonoEmpresa" style={{'marginTop': '0px'}}><b>Teléfono de Empresa:</b> {usuario.empresa_asociada.telefono}</p>
                    </div>

                    <div className="col-md-4 row">
                      <p className="col" id="CorreoEmpresa" style={{'marginTop': '0px'}}><b>Correo de Empresa:</b> {usuario.empresa_asociada.correo}</p>
                    </div>

                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
              </div>
            </div>
          </div>
      </ModalB4>
    </ModalContainer>
  )
}

export default UserView
