import React from 'react';

import './css/Loader.css';

let Loader = props => (
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="lds-ring mx-auto d-block"><div></div><div></div><div></div><div></div></div>
      </div>
    </div>
  </div>
)

export default Loader
