// JavaScript Document
import GraphQL from './ConsultaGraphQLAddFacturaCompra';

import url from '../../../../../apiService/ApiUrl.js';

function handleError(err){
	console.log(`Request failed: ${err}`);
}

const fetchAddFacturaCompra = (This, data) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(data)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		let facturaRes = res.data.ordenesCompraMutation.addFacturaCompra.factura

		This.setState({
			orden: {
				...This.state.orden,
				facturas: This.state.orden.facturas.map( facturaObj => {

					let facturaReturn

					if(facturaObj.noFactura === data.noFactura){
						facturaReturn = {
							...facturaObj,
							factura: facturaRes
						}
					}else{
						facturaReturn = {
							...facturaObj
						}
					}

					return facturaReturn

				})
			},
			loading: false
		})

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchAddFacturaCompra;
