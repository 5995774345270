import React from 'react';

import { connect } from 'react-redux'

import Navbar from '../../Inicio/Components/NewNavbarInicio/Containers/NavbarInicio'

import Footer from '../../../Components/NewFooter.jsx';

//Import MenuCMR
import MenuCMR from '../../../Dashboard/dashboard/containers/MenuCMR.jsx';

let Pacientes = props => (
  <div>
    <Navbar conexion={props.conexion} />
    {props.children}
    <MenuCMR />
    <Footer />
  </div>
)

let mapStateToProps = (state, props) => {
	return {
	  conexion: state.logIn.conexion
	}
}

export default connect(mapStateToProps)(Pacientes)
