import { connect } from 'react-redux';

import React, { Component } from 'react';

import Jodit from '../../../Jodit/Components/Jodit';

class Mision extends Component {

  render(){

    let renderVal = this.props.dataUI,
				textMisionObj = renderVal.textos.find( text => {
					return text.id === 'tex003'
				})

    return(
      <div id="contenedor-mision" className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 contenedor-val-mis-pol">
        <div id="text-mision" className="row justify-content-md-center align-items-start" style={{height: '100%'}}>
          <div id="anchor-mision"></div>
          <div id="icono-mision" className="iconos-mis-vis-pol"><span className="icon-light-bulb"></span></div>  
          <h3 className="col-12">MISIÓN</h3>
          <Jodit textObj={textMisionObj} type='updateText' />
        </div>
        {/*<Summernote itemID='#text-mision' textObj={textMisionObj} texto={texto} url='http://localhost:8080/updateText' />*/}
      </div>
    )
  }

}

let mapStateToProps = (state, props) => {
  return {
    dataUI: state.dataUI
  }
}

export default connect(mapStateToProps)(Mision);
