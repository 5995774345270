let consulta = (data) => {
  let string = `mutation updateStateYCantidadDeMuestrasSolicitudDeAnalisis{
    mensajeriaMutation{
      updateStateYCantidadDeMuestrasSolisitudDeAnalisis(id: "${data.id}", estado: "${data.estado}", cantidadDeMuestras: ${data.cantidadDeMuestras})
      {
        id
        mensaje
      }
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
