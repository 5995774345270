// JavaScript Document
import GraphQL from './ConsultaGraphQLGetOrdenesCompra';

import url from '../../../../../apiService/ApiUrl.js';

function handleError(err){
	console.log(`Request failed: ${err}`);
}

const fetchGetOrdenesCompra = (This, fechaInicio, fechaFinal) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(fechaInicio, fechaFinal)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {
		This.props.dispatch({
			type: 'SET_ORDENES_COMPRA',
			payload:{
				ordenes: res.data.appInfo.ordenesCompra.ordenesCompra.map( ordenCompra => {
					let data = {
						...ordenCompra,
						fecha: new Date(ordenCompra.fecha),
						emailTracking: ordenCompra.emailTracking !== null ? {
							...ordenCompra.emailTracking,
							fecha: new Date(ordenCompra.emailTracking.fecha)
						} : null,
					}
					return data
				})
			}
		})
	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchGetOrdenesCompra;
