let consulta = (data) => {

  let NoCatalogo = data.NoCatalogo === null ? null : `"${data.NoCatalogo}"`

  let string = `mutation{
    ordenesCompraMutation{
      updateProductoCompra(producto: {
        id: "${data.id}"
        nombre: "${data.nombre}"
        valorUnitario: ${data.valorUnitario}
        NoCatalogo: ${NoCatalogo},
        descripcion: "${data.descripcion}",
        observaciones: "${data.observaciones}",
        tipo: "${data.tipo}"
      }){
        id
        mensaje
        result
      }
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
