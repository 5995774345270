import React from 'react';

import Select from 'react-select';

let OrdenUsuario = props => {
  if(props.active === "active"){
    return(
      <div className="col-12 tab-pane show active fade" id="nav-ord-usuario" role="tabpanel" aria-labelledby="nav-usuario-tab">
        <div className="row">
          <label htmlFor="react-select-3-input" className="col-lg-6 col-12"><b>Selecciona un Usuario</b></label>
          <Select
            className="col-lg-6 col-12"
            placeholder="Escoje un Usuario"
            defaultValue={props.defaultValue}
            onChange={props.handleChangeUsuario}
            options={props.SelectUsuarios}
          />
        </div>
      </div>
    )
  }else{
    return(
      <div className="col-12 tab-pane fade" id="nav-ord-usuario" role="tabpanel" aria-labelledby="nav-usuario-tab">
        <div className="row">
          <label htmlFor="react-select-3-input" className="col-lg-6 col-12"><b>Selecciona un Usuario</b></label>
          <Select
            className="col-lg-6 col-12"
            placeholder="Escoje un Usuario"
            defaultValue={props.defaultValue}
            onChange={props.handleChangeUsuario}
            options={props.SelectUsuarios}
          />
        </div>
      </div>
    )
  }
}

export default OrdenUsuario
