import React from 'react';

import './css/serviciosmedicos.css';

import Footer from '../../../Components/NewFooter.jsx';

import {Link} from 'react-router-dom';

//Import MenuCMR
import MenuCMR from '../../../Dashboard/dashboard/containers/MenuCMR.jsx';

let ServiciosMedicos = props => (
  <div>

    <nav className="navbar navbar-servicios-medicos navbar-default sin-margen-y-padding">

      <div className="container-fluid sin-margen-y-padding">

        <div className="navbar-header">

        <Link className="navbar-brand" to="/">
          <img id="brand" src="/img/Inicio/LogoBlancoConLetra.svg"  alt="Logo Biolab"/>
    		</Link>


         	<div id="icono-tipo-cliente" className="navbar-brand" href="#">
         		<span className="icon-medical-specialist"></span>
         		<p>Servicios<br /> Médicos</p>
         	</div>

         	<div id="icono-inicio">
         		<Link id="inicio-a" to="/">
         			<span className="icon-home"></span>
         			<p>INICIO</p>
         		</Link>
         	</div>
        </div>

      </div>

    </nav>

      <section id="servicios-medicos" className="container-fluid" style={{"marginTop": "25px"}}>
      	<div className="container">
          <div className="row">
            <div id="servicio-medico-izquierda" className="col-sm-6 col-12">
              <Link to="/pacientes/servicios-medicos">
                <img className="img-fluid" src="/img/Inicio/pacientes.jpg" alt="Imagen Servicios Medicos Pacientes"/>
                <div className="container-textos d-flex align-items-center">
                  <h3 className="text-center">PACIENTES</h3>
                </div>
              </Link>
            </div>
            <div id="servicio-medico-derecha" className="col-sm-6 col-12">
              <Link to="/empresas-e-indusrias/servicios-medicos">
                <div className="container-textos d-flex align-items-center">
                  <h3 className="text-center">COLABORADORES DE EMPRESAS</h3>
                </div>
                <img className="img-fluid" src="/img/Inicio/colaboradores.jpg" alt="Imagen SErvicios Medicos Colaboradores de Empresas"/>
              </Link>
            </div>
          </div>
      	</div>
      	{/*<div className="col-12 sin-margen-y-padding">
      		<h2 id="texto-proximamente" className="text-center">¡PRÓXIMAMENTE!</h2>
      	</div>*/}
      </section>

      <MenuCMR />

      <Footer />

  </div>
)

export default ServiciosMedicos
