import HandleInputChangeUsuario from './HandleInputChangeUsuario.js';
import HandleInputChangeEmpresa from './HandleInputChangeEmpresa.js';
import HandleInputChangeMensajeriaOrden from './HandleInputChangeMensajeriaOrden.js';
import HandleInputChangeMensajeriaRuta from './HandleInputChangeMensajeriaRuta.js';
import HandleInputChangePlantillaRuta from './HandleInputChangePlantillaRuta.js';

let handleInputChange = (event, This) => {

  //console.log(event)

  let usuario = event.target.dataset.usuario,
      empresa = event.target.dataset.empresa,
      orden = event.target.dataset.orden,
      ruta = event.target.dataset.ruta,
      plantilaOrden = event.target.dataset.plantillaOrden

  /*if(This.props.dataUsers.length === 0){


    if(JSON.parse(usuario).id === This.props.usuario.id){

      if(type === 'usuario'){

        let obj = JSON.parse(usuario)

        obj.usuario = value

        console.log(obj)

        This.props.dispatch({
          type:'LOGIN',
          payload: {
            logIn: {
              conexion: true,
              usuario: obj
            }
          }
        })

      }

    }
  }*/

  if(usuario !== undefined){
    HandleInputChangeUsuario(event, This)
  }

  if(empresa !== undefined){
    HandleInputChangeEmpresa(event, This)
  }

  if(orden !== undefined){
    HandleInputChangeMensajeriaOrden(event, This)
  }

  if(ruta !== undefined){
    HandleInputChangeMensajeriaRuta(event, This)
  }

  if(plantilaOrden !== undefined){
    HandleInputChangePlantillaRuta(event, This)
  }

}

export default handleInputChange;
