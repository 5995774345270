import React from 'react';

import Galeria from 'App/Components/Galeria.tsx';

import ImagenPortada from '../Components/Portada/ImagenPortada.jsx';

const urls = [
    {
        img: 'img/Inicio/Inicio1.png',
        alt: 'Imagen Portada 1'
    },
    {
        img: 'img/Inicio/Inicio2.png',
        alt: 'Imagen Portada 2'
    },
    {
        img: 'img/Inicio/Inicio3.png',
        alt: 'Imagen Portada 3'
    },
    {
        img: 'img/Inicio/Inicio4.png',
        alt: 'Imagen Portada 4'
    },
]

const RenderComponent = props => <div className="img-container">{props.children}</div>

const GaleriaPortada = props => {
    return(
        <ImagenPortada>
            <Galeria 
                RenderComponent={RenderComponent} 
                urlImgs={urls} 
                options={{
                    spaceBetween: 50,
                    slidesPerView: 1,
                    pagination: true,
                    navigation: true,
                    loop: true,
                }}
                styleSwiperContainer={{
                    marginBottom: '50px',
                    height: '100%',
                }}
            />
        </ImagenPortada>
    )
}

export default GaleriaPortada