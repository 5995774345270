import React from 'react';

import Navbar from '../NavbarVeterinaria/Containers/NavbarVeterinaria.jsx'

import Footer from '../../../Components/NewFooter.jsx';

//Import MenuCMR
import MenuCMR from '../../../Dashboard/dashboard/containers/MenuCMR.jsx';

let Veterinaria = props => (
  <div>
    <Navbar />
    {props.children}
    <MenuCMR />
    <Footer />
  </div>
)

export default Veterinaria
