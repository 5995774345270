import React from 'react';
import './css/Contacto.css';

import ContactoForm from '../Containers/ContactoForm.jsx'

let Contacto = props => (
  <div>
    <section id="seccion-contacto" className="container-fluid sin-margen-y-padding">
    		<h1 className="col-12">CONTÁCTENOS</h1>
        {props.children}
      </section>

      <section id="form-contacto" className="container">
      	<div className="row flex-row-reverse">

        	{/*<form id="form-contacto-form" method="post" className="col-12 col-lg-9 container offset-sm-0">
    				<input id="nombre" type="text" placeholder="Nombre:" className="col-sm-3 col-12" required />
    				<input id="correo" type="email" placeholder="Correo:" className="col-sm-4 offset-sm-1 col-12" required />
    				<input id="tel" type="tel" placeholder="Teléfono:" className="col-sm-3 offset-sm-1 col-12" required />
    				<textarea id="mensajeForm" placeholder="Mensaje:" className="col-12" required></textarea>
    				<button type="submit" className="col-sm-3 offset-sm-9 col-12 col-offset-4">Enviar</button>

    				<h4 id="mensajeenviado" className="text-center alert alert-success col-12 col-md-8 offset-md-2" style={{display: 'none'}}>Tu mensaje se ha enviado satisfactoriamente.</h4>
    				<h4 id="errormensaje" className="text-center alert alert-danger col-12 col-md-8 offset-md-2" style={{display: 'none'}}>Tu mensaje no se ha enviado, ha ocurrido un error, prueba enviarlo de nuevo.</h4>
    			</form>*/}

					<ContactoForm />

        <div id="red-social-form" className="col-12 col-lg-3">
          <div className="row">
            <div className="col-12">
              <h3 className="mx-auto text-center">ENCUENTRANOS</h3>
            </div>
          </div>
					<div id="iconos-redes-inicio" className="row">
						<div className="container-icons">
							<a href="https://www.facebook.com/biolabguate/" className="">
								<div id="fb" className="icono">
									<span className="icon-icono-fb"></span>
								</div>
							</a>
						</div>
						<div className="container-icons">
							<a href="https://twitter.com/Biolabguatemala" className="">
								<div id="tw" className="icono">
									<span className="icon-icono-tw"></span>
								</div>
							</a>
						</div>
						<div className="container-icons">
							<a href="https://www.instagram.com/biolabguatemala/" className="">
								<div id="ins" className="icono">
									<span className="icon-icono-ins"></span>
								</div>
							</a>
						</div>
						<div className="container-icons">
							<a href="https://www.youtube.com/channel/UCUuBScphxL7_0U0Dg7vmiJQ" className="">
								<div id="yt" className="icono">
									<span className="icon-icono-yt"></span>
								</div>
							</a>
						</div>
						<div className="container-icons">
							<a href="https://www.linkedin.com/company-beta/24782052/" className="">
								<div id="in" className="icono">
									<span className="icon-icono-in"></span>
								</div>
							</a>
						</div>
					</div>
				</div>

      </div>

  		<div id="mensaje" className="row">
  			<i className="col-12"><h3 className="col-12 text-center"><span className="icon-light-bulb"></span> ATENDEMOS COMPAÑÍAS DE SEGUROS, CONTÁCTENOS</h3></i>
  		</div>
      </section>
  </div>
)

export default Contacto
