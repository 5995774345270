import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';

import GraphQL from './FetchUpdateEstadoSolicitud/fetchGraphQL.js';

import Toggle from 'react-toggle';
import "react-toggle/style.css" // for ES6 modules

import { connect } from 'react-redux';

let codeReader;

class QRMensajeria extends Component {

  constructor(props){
    super(props)

    this.state = {
      usarQR: false,
      idOrden: null,
      mensajeDeCambioDeEstado: null
    }

    this.handleUsarQRChange = this.handleUsarQRChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeEstadoSolicitud = this.handleChangeEstadoSolicitud.bind(this);
  }

  handleInputChange(event) {
    this.setState({
      idOrden: event.target.value
    })
  }

  componentDidMount(){
    if(this.props.location.state !== null){
      this.setState({
        idOrden: this.props.location.state.idOrden
      })
    }
  }

  async handleChangeEstadoSolicitud() {
    let nuevoEstado
    if ((this.props.usuario.colaborador === null) || (this.props.usuario.colaborador.puesto === 'Mensajero')) {

      nuevoEstado = {
        id: this.state.idOrden,
        estado: "Entregado a Mensajero"
      }

      GraphQL(this, nuevoEstado)

    } else if ((this.props.usuario.colaborador.puesto === 'Asistente Administrativo') || (this.props.usuario.colaborador.puesto === 'Asistente de Laboratorio') || (this.props.usuario.colaborador.puesto === 'Director de Laboratorio') || (this.props.usuario.colaborador.puesto === 'Director Administrativo') || (this.props.usuario.colaborador.puesto === 'Coordinador de Laboratorio')) { 

      nuevoEstado = {
        id: this.state.idOrden,
        estado: "Finalizado"
      }

      GraphQL(this, nuevoEstado)

    }
  }

  async handleUsarQRChange() {
    
    let changeState = () => {
      if (this.state.usarQR === false) {
        this.setState({
          usarQR: true
        })
      }else{
        this.setState({
          usarQR: false
        })
        codeReader.reset()
      }
    }

    await changeState()

    if (this.state.usarQR === true) {
      
        if(this.props.continuouslyScann === false){
          codeReader = new BrowserMultiFormatReader();
    
          codeReader
          .decodeFromInputVideoDevice(undefined, 'video')
          .then(result => {
            this.setState({
              idOrden: result.text
            })
          })
          .catch(err => console.error(err));
        } else if (this.props.continuouslyScann === true) {
          //let scannDelay = parseInt(this.props.delay)

            codeReader = new BrowserMultiFormatReader();

            codeReader
            .decodeFromInputVideoDeviceContinuously(undefined, 'video', (result, err) => {
              if (result) {
                this.setState({
                  idOrden: result.text
                })
              }
              if (err && !(err instanceof NotFoundException)) {
                console.error(err)
                document.getElementById('valorCodigo').textContent = err
              }
              console.log(`Started continous decode from camera`)
            })
            .catch(err => console.error(err));

        }
      
    }
    

  }

  componentWillUnmount() {
    if(this.state.usarQR === true){
      codeReader.reset()
    }
  }

  render() {
    return(
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center">Escanea tu Código QR</h1>
          </div>
          <div className="mx-auto" style={{marginTop: '15px'}}>
            <div className="row">
              <p className="col-lg-6 col-12 text-center"><b>¿Quiere escanear QR?</b></p>
              <label className="mx-auto">
                <Toggle
                  defaultChecked={this.state.usarQR}
                  onChange={this.handleUsarQRChange} />
              </label>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              {
                this.state.usarQR === true ? (
                  <div className="col-12 d-flex">
                    <video
                      id="video"
                      width="300"
                      height="200"
                      style={{ border: '1px solid gray' }}
                      className="mx-auto"
                    ></video>
                  </div>
                ) : null
              }
              {
                this.state.usarQR === false ? (
                  <div className="col-12 d-flex justify-content-center" style={{ marginTop: '15px', marginBottom: '50px' }}>
                    <label htmlFor="numeroDeOrden" style={{marginRight: '5px'}}><b>Número de Orden: </b></label>
                    <input id="numeroDeOrden" type="text" placeholder="Número de Orden" onChange={this.handleInputChange} />
                  </div>
                ) : null
              }
              <div className="mx-auto">
                {
                  this.state.idOrden === null ? null : (
                    <div className="col-12">
                      <h4 className="text-center" style={{marginBottom: '0px'}}>Número de Orden:</h4>
                      <h5 className="text-center" style={{marginBottom: '0px'}}>{this.state.idOrden}</h5>
                    </div>
                  )
                }
              </div>
              {
                this.props.usuario.colaborador !== null ?
                (this.props.usuario.colaborador.puesto === 'Mensajero' ? (
                  <div className="col-12 d-flex" style={{marginTop: '15px', marginBottom: '25px'}}>
                    <button className="btn btn-outline-info mx-auto" onClick={this.handleChangeEstadoSolicitud}>Orden Realizada</button>
                  </div>
                  ) : (
                    <div className="col-12 d-flex" style={{ marginTop: '15px', marginBottom: '25px' }}>
                      <button className="btn btn-outline-info mx-auto" onClick={this.handleChangeEstadoSolicitud}>Cambiar el Estado</button>
                    </div>
                  ) )
                : (
                  <div className="col-12 d-flex" style={{ marginTop: '15px', marginBottom: '15px' }}>
                    <button className="btn btn-outline-info mx-auto" onClick={this.handleChangeEstadoSolicitud}>Cambiar el Estado <FontAwesomeIcon icon="sync-alt" /></button>
                  </div>
                )
              }
            </div>
            {
              this.state.mensajeDeCambioDeEstado !== null ? (
                <div className="row" style={{marginTop: '25px'}}>
                  {
                    (this.state.mensajeDeCambioDeEstado.response === 'success') ? (
                      <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.mensajeDeCambioDeEstado.mensaje}</div>
                    ) : (
                      <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.mensajeDeCambioDeEstado.mensaje}</div>
                    )
                  }
                </div>
              ) : null
            }
          </div>
        </div>
      </div>
    )
  }
}

let mapStateToProps = (state, props) => {
  return {
    usuario: state.logIn.usuario
  }
}

export default connect(mapStateToProps)(QRMensajeria)
