import React, { Component } from 'react';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Toggle from 'react-toggle';
import "react-toggle/style.css" // for ES6 modules

import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';

import { connect } from 'react-redux';

let codeReader;

class QRScanner extends Component {

    constructor(props){
        super(props)
    
        this.state = {
          usarQR: false,
          id: null
        }
    
        this.handleUsarQRChange = this.handleUsarQRChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
      }
    
      handleInputChange(event) {
        this.setState({
          id: event.target.value
        })
      }
    
      async handleUsarQRChange() {
        
        let changeState = () => {
          if (this.state.usarQR === false) {
            this.setState({
              usarQR: true,
              id: ""
            })
          }else{
            this.setState({
              usarQR: false,
              id: ""
            })
            codeReader.reset()
          }
        }
    
        await changeState()
    
        if (this.state.usarQR === true) {
          
            if(this.props.continuouslyScann === false){
              codeReader = new BrowserMultiFormatReader();
        
              codeReader
              .decodeFromInputVideoDevice(undefined, 'video')
              .then(result => {
                this.setState({
                  id: result.text
                })
              })
              .catch(err => console.error(err));
            } else if (this.props.continuouslyScann === true) {
              //let scannDelay = parseInt(this.props.delay)
    
                codeReader = new BrowserMultiFormatReader();
    
                codeReader
                .decodeFromInputVideoDeviceContinuously(undefined, 'video', (result, err) => {
                  if (result) {
                    this.setState({
                      id: result.text
                    })
                  }
                  if (err && !(err instanceof NotFoundException)) {
                    console.error(err)
                    document.getElementById('valorCodigo').textContent = err
                  }
                  console.log(`Started continous decode from camera`)
                })
                .catch(err => console.error(err));
    
            }
          
        }
        
    
      }
    
      componentWillUnmount() {
        if(this.state.usarQR === true){
          codeReader.reset()
        }
      }
    
      render() {
        return(
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="text-center">Escanea tu Código QR</h1>
              </div>
              <div className="mx-auto" style={{marginTop: '15px'}}>
                <div className="row">
                  <p className="col-lg-6 col-12 text-center"><b>¿Quiere escanear QR?</b></p>
                  <label className="mx-auto">
                    <Toggle
                      defaultChecked={this.state.usarQR}
                      onChange={this.handleUsarQRChange} />
                  </label>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  {
                    this.state.usarQR === true ? (
                      <div className="col-12 d-flex">
                        <video
                          id="video"
                          width="300"
                          height="200"
                          style={{ border: '1px solid gray' }}
                          className="mx-auto"
                        ></video>
                      </div>
                    ) : null
                  }
                  {
                    this.state.usarQR === false ? (
                      <div className="col-12 d-flex justify-content-center" style={{ marginTop: '15px', marginBottom: '50px' }}>
                        <label htmlFor="numeroDeOrden" style={{marginRight: '5px'}}><b>{this.props.tipo ? this.props.tipo : "Número de Orden:"}</b></label>
                        <input id="numeroDeOrden" type="text" placeholder={this.props.tipo ? this.props.tipo : "Número de Orden"} onChange={this.handleInputChange} />
                      </div>
                    ) : null
                  }
                    <div className="mx-auto">
                        {
                        this.state.id === null ? null : (
                            <div className="col-12">
                            <h4 className="text-center" style={{marginBottom: '0px'}}>{this.props.tipo ? this.props.tipo : "Número de Orden:"}</h4>
                            <h5 className="text-center" style={{marginBottom: '0px'}}>{this.state.id}</h5>
                            </div>
                        )
                        }
                    </div>

                    {
                        this.props.handleButtonClick ? (
                            <div className="col-12 d-flex" style={{ marginTop: '15px', marginBottom: '15px' }}>
                                <button className="btn btn-outline-info mx-auto" onClick={() => this.props.handleButtonClick(this.state.id)}>{ this.props.handleClickText ? this.props.handleClickText : "Traer Datos" }{/*<FontAwesomeIcon icon="sync-alt" />*/}</button>
                            </div>
                        ) : null
                    }


                </div>

              </div>
            </div>
          </div>
        )
      }

}

let mapStateToProps = (state, props) => {
    return {
      usuario: state.logIn.usuario
    }
  }
  
  export default connect(mapStateToProps)(QRScanner)