import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './css/newFooter.css';

const Footer = () => (
		<footer>

		<div className="container-fluid" id="contenedor-footer">

			<div className="row">

				<div id="info-footer-inicio" className="col-12">
					<div className="row mt-4">
						<div className="col-12">
							<img src="/img/Inicio/IconoLaboratorios.svg" alt="icono-laboratorios" className="d-inline mr-3" style={{maxWidth: '100px'}}/>
							<h5 className="d-inline">LABORATORIOS</h5>
						</div>

						<div className="col-12 col-md-6 col-lg-4 col-xl-3 mx-auto" style={{borderBottom: 'solid 1px #FFFFFF', marginTop: '15px'}}>
							<h4>PETAPA</h4>
							<p>
								Edificio "La Gran Vía" 28-98 zona 12<br />
								<FontAwesomeIcon icon={['fab', 'whatsapp']} /> WhatsApp: +(502) 4101-8475<br />
								clinicopetapa@biolab.com.gt
							</p>
						</div>
						<div className="col-12 col-md-6 col-lg-4 col-xl-3 mx-auto" style={{borderBottom: 'solid 1px #FFFFFF', marginTop: '15px'}}>
							<h4>CAYALÁ</h4>
							<p>
								Blvd. Austriaco 10-05 zona 16<br />
								<FontAwesomeIcon icon={['fab', 'whatsapp']} /> WhatsApp: +(502) 4179-3481<br />
								clinicocayala@biolab.com.gt
							</p>
						</div>
						<div className="col-12 col-md-6 col-lg-4 col-xl-3 mx-auto" style={{borderBottom: 'solid 1px #FFFFFF', marginTop: '15px'}}>
							<h4>ROOSEVELT</h4>
							<p>
								Calzada Roosevelt, 7-59 zona 3, Mixco<br />
								<FontAwesomeIcon icon={['fab', 'whatsapp']} /> WhatsApp: +(502) 5489-7358<br />
								plazamadero@biolab.com.gt
							</p>
						</div>
						<div className="col-12 col-md-6 col-lg-4 col-xl-3 mx-auto" style={{borderBottom: 'solid 1px #FFFFFF', marginTop: '15px'}}>
							<h4>ATANASIO</h4>
							<p>Calzada Atanasio Tzul, 16-67 zona 12</p>
							<ul>
								<li style={{color: '#FFFFFF'}}>
									Laboratorio Clínico:<br />
									<FontAwesomeIcon icon={['fab', 'whatsapp']} /> WhatsApp: +(502) 3760-3806 <br />
									clinicoatanasio@biolab.com.gt
								</li>
								<li style={{color: '#FFFFFF'}}>
									Laboratorio de Referencia:<br />
									<FontAwesomeIcon icon={['fab', 'whatsapp']} /> WhatsApp: +(502) 4215-3144 <br />
									recepcionreferencia@biolab.com.gt
								</li>
							</ul>
						</div>
						<div className="col-12 col-md-6 col-lg-4 col-xl-3 mx-auto" style={{borderBottom: 'solid 1px #FFFFFF', marginTop: '15px'}}>
							<h4>INDUSTRIAL</h4>
							<p>
								30 calle 17-50 zona 12, colonia Santa Rosa<br />
								<FontAwesomeIcon icon={['fab', 'whatsapp']} /> WhatsApp: +(502) 4179-2914<br />
								lab.industria@biolab.com.gt
							</p>
						</div>

					</div>
					<div className="row mt-4">
						<div className="col-12">
							<h5 className="text-center">PBX: +(502) 2506-3131</h5>
						</div>
					</div>
				</div>

				{/*<div id="logo-footer-inicio" className="col-md-6 col-xs-12 mx-auto mb-5">
					<div className="row">
						<div className="col-xs-12 col-sm-10 col-md-10 col-lg-8 mx-auto">
							<div className="row">
								<img className="img-fluid mx-auto" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco"/>
							</div>
						</div>
					</div>
				</div>*/}

				<div id="redes-inicio" className="col-lg-4 col-md-8 col-sm-12 mx-auto mb-5">
					<h3 className="text-center mb-3">SIGUENOS</h3>
					<div id="iconos-redes-inicio" className="row">
						<div className="container-icons">
							<a href="https://www.facebook.com/biolabguate/" className="">
								<div id="fb" className="icono">
									<span className="icon-icono-fb"></span>
								</div>
							</a>
						</div>
						{/*<div className="container-icons">
							<a href="https://twitter.com/Biolabguatemala" className="">
								<div id="tw" className="icono">
									<span className="icon-icono-tw"></span>
								</div>
							</a>
						</div>*/}
						<div className="container-icons">
							<a href="https://www.instagram.com/biolabguatemala/" className="">
								<div id="ins" className="icono">
									<span className="icon-icono-ins"></span>
								</div>
							</a>
						</div>
						<div className="container-icons">
							<a href="https://www.youtube.com/channel/UCUuBScphxL7_0U0Dg7vmiJQ" className="">
								<div id="yt" className="icono">
									<span className="icon-icono-yt"></span>
								</div>
							</a>
						</div>
						<div className="container-icons">
							<a href="https://www.linkedin.com/company-beta/24782052/" className="">
								<div id="in" className="icono">
									<span className="icon-icono-in"></span>
								</div>
							</a>
						</div>
					</div>
				</div>

			</div>

				<div id="copyright-inicio" className="row text-center callout clearfix">
					{/*<p className="col-12"  style={{'textAlign' : 'center', 'paddingBottom': '15px'}}>Sitio en Desarrollo</p>*/}
					<div className="col-xs-12 col-md-6 mt-5">
						<div className="mx-auto" style={{maxWidth: '250px'}}>
							<img id="footer-logo-biolab" className="img-fluid" src="/img/Inicio/LogoBiolabBlanco.png" alt="logo Biolab en Blanco" />
						</div>
					</div>
					<div className="col-xs-12 col-md-6 mt-5">
						{/* <div className="mx-auto" style={{maxWidth: '250px'}}>
							<img id="footer-logo-p-lao-inicio" className="img-fluid" src="/img/Inicio/LogoBlanco.png" alt="logo P-lao en Blanco" />
						</div> */}
						<div className="col mx-auto">
							<p id="" className="mt-3">Copyright &copy; 2021 Todos los Derechos Reservados</p>
						</div>
					</div>
				</div>

		</div>

	</footer>
	)

export default Footer;
