import React, { useState } from 'react';

import Toggle from 'react-toggle';

const DataTecnicaExamen = props => {

    const [ isClinico, setIsClinico ] = useState(true)

    return(
        <>
            <div className="row">

                <div className="col-12">
                    <h2 className="text-center">Datos Técnicos de Examen de Laboratorio</h2>
                </div>

                <div className="mx-auto col-12 col-sm-6 col-lg-4">
                    <div className="form-group row">
                        <div className="mx-auto" style={{ marginTop: '35px'}}>
                            <label htmlFor="isClinico" className="text-center mr-3" style={{ top: '-6px', position: 'relative'}}><b className="text-center">Es Análisis Clínico:</b></label>
                            <Toggle 
                                id="isClinico"
                                checked={isClinico}
                                onChange={ event => setIsClinico(!isClinico) }
                            />
                        </div>
                    </div>
                </div>

                <div className="mx-auto col-12 col-sm-6 col-lg-4">
                    <div className="form-group row">
                        <div className="mx-auto" style={{ marginTop: '35px'}}>
                            <label htmlFor="isClinico" className="text-center mr-3" style={{ top: '-6px', position: 'relative'}}><b className="text-center">Es Análisis Industrial:</b></label>
                            <Toggle 
                                id="isClinico"
                                checked={!isClinico}
                                onChange={ event => setIsClinico(!isClinico) }
                            />
                        </div>
                    </div>
                </div>

                <div className="mx-auto col-12 col-sm-6 col-lg-4">
                    <div className="form-group">
                        <label htmlFor="grupoAreaDeTrabajo"><b>Grupo/Área de Trabajo:</b></label>
                        <input type="text" value={props.state.dataTecnicaExamen.grupoAreaDeTrabajo} id="grupoAreaDeTrabajo" className="form-control" onChange={ event => props.handleInputChange({ event, type: 'input' }) } required />
                    </div>
                </div>

                <div className="mx-auto col-12 col-sm-6 col-lg-4">
                    <div className="form-group">
                        <label htmlFor="ordenDeReporte"><b>Orden de Reporte:</b></label>
                        <input type="text" value={props.state.dataTecnicaExamen.ordenDeReporte} id="ordenDeReporte" className="form-control" onChange={ event => props.handleInputChange({ event, type: 'input' }) } required />
                    </div>
                </div>

                <div className="mx-auto col-12 col-sm-6 col-lg-4">
                    <div className="form-group">
                        <label htmlFor="codigoLIS"><b>Código LIS:</b></label>
                        <input type="text" value={props.state.dataTecnicaExamen.codigoLIS} id="codigoLIS" className="form-control" onChange={ event => props.handleInputChange({ event, type: 'input' }) } required />
                    </div>
                </div>

                <div className="mx-auto col-12 col-sm-6 col-lg-4">
                    <div className="form-group row">
                        <div className="mx-auto" style={{ marginTop: '35px'}}>
                            <label htmlFor="apareceEnPedido" className="text-center mr-3" style={{ top: '-6px', position: 'relative'}}><b className="text-center">Aparece en Pedido:</b></label>
                            <Toggle 
                                id="apareceEnPedido"
                                checked={props.state.dataTecnicaExamen.apareceEnPedido}
                                onChange={ event => props.handleInputChange({ event, type: 'toggle' }) }
                            />
                        </div>
                    </div>
                </div>

                <div className="mx-auto col-12 col-sm-6 col-lg-4">
                    <div className="form-group">
                        <label htmlFor="alertaEspecial"><b>Alerta Especial:</b></label>
                        <input type="text" value={props.state.dataTecnicaExamen.alertaEspecial} id="alertaEspecial" className="form-control" onChange={ event => props.handleInputChange({ event, type: 'input' }) } required />
                    </div>
                </div>

                <div className="mx-auto col-12 col-sm-6 col-lg-4">
                    <div className="form-group">
                        <label htmlFor="informacionAdicional"><b>Información Adicional:</b></label>
                        <input type="text" value={props.state.dataTecnicaExamen.informacionAdicional} id="informacionAdicional" className="form-control" onChange={ event => props.handleInputChange({ event, type: 'input' }) } required />
                    </div>
                </div>

                <div className="mx-auto col-12 col-sm-6 col-lg-4">
                    <div className="form-group row">
                        <div className="mx-auto" style={{ marginTop: '35px'}}>
                            <label htmlFor="reportarEnLinea" className="text-center mr-3" style={{ top: '-6px', position: 'relative'}}><b className="text-center">Reportar en Línea:</b></label>
                            <Toggle 
                                id="reportarEnLinea"
                                checked={props.state.dataTecnicaExamen.reportarEnLinea}
                                onChange={ event => props.handleInputChange({ event, type: 'toggle' }) }
                            />
                        </div>
                    </div>
                </div>

                <div className="mx-auto col-12 col-sm-6 col-lg-4">
                    <div className="form-group row">
                        <div className="mx-auto" style={{ marginTop: '35px'}}>
                            <label htmlFor="pruebaAcreditada" className="text-center mr-3" style={{ top: '-6px', position: 'relative'}}><b className="text-center">Prueba Acreditada:</b></label>
                            <Toggle 
                                id="pruebaAcreditada"
                                checked={props.state.dataTecnicaExamen.pruebaAcreditada}
                                onChange={ event => props.handleInputChange({ event, type: 'toggle' }) }
                            />
                        </div>
                    </div>
                </div>

                <div className="mx-auto col-12 col-sm-6 col-lg-4">
                    <div className="form-group">
                        <label htmlFor="tiempoDeEntregaReferencia"><b>Tiempo De Entrega Referencia (minutos):</b></label>
                        <input type="number" value={props.state.dataTecnicaExamen.tiempoDeEntregaReferencia.toString()} id="tiempoDeEntregaReferencia" className="form-control" onChange={ event => props.handleInputChange({ event, type: 'number' }) } required />
                    </div>
                </div>

                <div className="mx-auto col-12 col-sm-6 col-lg-4">
                    <div className="form-group">
                        <label htmlFor="tiempoDeEntregaSucursales"><b>Tiempo De Entrega Sucursales (minutos):</b></label>
                        <input type="number" value={props.state.dataTecnicaExamen.tiempoDeEntregaSucursales.toString()} id="tiempoDeEntregaSucursales" className="form-control" onChange={ event => props.handleInputChange({ event, type: 'number' }) } required />
                    </div>
                </div>

                <div className="mx-auto col-12 col-sm-6 col-lg-4">
                    <div className="form-group">
                        <label htmlFor="metodologia"><b>Medotodogía:</b></label>
                        <input type="text" value={props.state.dataTecnicaExamen.metodologia} id="metodologia" className="form-control" onChange={ event => props.handleInputChange({ event, type: 'input' }) } required />
                    </div>
                </div>

                <div className="mx-auto col-12 col-sm-6 col-lg-4">
                    <div className="form-group">
                        <label htmlFor="tipoDeMuestra"><b>Tipo De Muestra:</b></label>
                        <input type="text" value={props.state.dataTecnicaExamen.tipoDeMuestra} id="tipoDeMuestra" className="form-control" onChange={ event => props.handleInputChange({ event, type: 'input' }) } required />
                    </div>
                </div>

                <div className="mx-auto col-12 col-sm-6 col-lg-4">
                    <div className="form-group">
                        <label htmlFor="recipienteUtilizado"><b>Recipiente Utilizado:</b></label>
                        <input type="text" value={props.state.dataTecnicaExamen.recipienteUtilizado} id="recipienteUtilizado" className="form-control" onChange={ event => props.handleInputChange({ event, type: 'input' }) } required />
                    </div>
                </div>

                <div className="mx-auto col-12 col-sm-6 col-lg-4">
                    <div className="form-group">
                        <label htmlFor="tipoDeResultado"><b>Tipo De Resultado:</b></label>
                        <input type="text" value={props.state.dataTecnicaExamen.tipoDeResultado} id="tipoDeResultado" className="form-control" onChange={ event => props.handleInputChange({ event, type: 'input' }) } required />
                    </div>
                </div>

                <div className="mx-auto col-12 col-sm-6 col-lg-4">
                    <div className="form-group">
                        <label htmlFor="sifrasSignificativas"><b>Sifras Significativas:</b></label>
                        <input type="number" step=".01" value={props.state.dataTecnicaExamen.sifrasSignificativas.toString()} id="sifrasSignificativas" className="form-control" onChange={ event => props.handleInputChange({ event, type: 'number' }) } required />
                    </div>
                </div>
                
                <div className="mx-auto col-12 col-sm-6 col-lg-4">
                    <div className="form-group">
                        <label htmlFor="dimencionales"><b>Dimencionales:</b></label>
                        <input type="text" value={props.state.dataTecnicaExamen.dimencionales} id="dimencionales" className="form-control" onChange={ event => props.handleInputChange({ event, type: 'input' }) } required />
                    </div>
                </div>

                <div className="mx-auto col-12 col-sm-6 col-lg-4">
                    <div className="form-group">
                        <label htmlFor="criterioDeCalidad"><b>Criterio de Calidad:</b></label>
                        <input type="text" value={props.state.dataTecnicaExamen.criterioDeCalidad} id="criterioDeCalidad" className="form-control" onChange={ event => props.handleInputChange({ event, type: 'input' }) } required />
                    </div>
                </div> 

                <div className="mx-auto col-12 col-sm-6 col-lg-4">
                    <div className="form-group">
                        <label htmlFor="datosParaTomaDeMuestra"><b>Datos para la toma de muestra:</b></label>
                        <input type="text" value={props.state.dataTecnicaExamen.datosParaTomaDeMuestra} id="datosParaTomaDeMuestra" className="form-control" onChange={ event => props.handleInputChange({ event, type: 'input' }) } required />
                    </div>
                </div> 

                <div className="mx-auto col-12 col-sm-6 col-lg-4">
                    <div className="form-group">
                        <label htmlFor="limiteDeDeteccion"><b>Límite de Detección:</b></label>
                        <input type="text" value={props.state.dataTecnicaExamen.limiteDeDeteccion} id="limiteDeDeteccion" className="form-control" onChange={ event => props.handleInputChange({ event, type: 'input' }) } required />
                    </div>
                </div>

            </div>

            {
                isClinico && (
                <div className="row">
                    <div className="col-12 text-center mb-4">
                        <button className="text-center">Agregar Valores de Referencia</button>
                    </div>
                </div> 
                )
            }

            {
                !isClinico && (
                    <div className="row">
                        
                    </div>
                )
            }

        </>
    )


}

export default DataTecnicaExamen