import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import FetchGraphQLGetTareas from '../../containers/FetchGetTareas/FetchGraphQLGetTareas';

import 'react-big-calendar/lib/css/react-big-calendar.css';

import 'moment/locale/es';

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment)

let EventComponentStyle = (event, start, end, isSelected)=>{

    let backgroundColor

    if(event.type === "Objetivo"){
        backgroundColor = "#3174ad"
    }else if(event.type === "Meta"){
        backgroundColor = "#A5AD1F"
    }else if(event.type === "Tarea"){
        backgroundColor= "#2DA6C4"
    }

    let style = {
        backgroundColor,
        color: "#FFFFFF"
    }

    let result = {style};

    return result
    
}

class CalendarComponent extends Component {

    constructor(props) {

        super(props);

        this.state={
            filter: {
                tarea: {
                    estado: "Todos",
                    sucursal: "",
                    tipo: "Todos",
                    nivelDeVisualizacion: "Cualquiera",
                    nombre: "",
                    meta: "",
                    tipoFecha: "fechaInicio",
                    fechaDesde: "",
                    fechaHasta: ""
                }
            }
        }
        
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount(){
        
        let daysInMonth = (month, year) => {
            return new Date(year, month, 0).getDate();
        }
 

        let fecha = new Date(),
        year = fecha.getFullYear()
        let mes
        (fecha.getMonth()+1) < 10 ? mes = `0${fecha.getMonth()+1}` : mes = `${fecha.getMonth()+1}`

        let fechaDesde = new Date(),
            fechaHasta = new Date()

        let fechaDesdeTimestampString = new Date(parseInt(fechaDesde.setDate(1))).setHours(0,0,0,0).toString(),
            fechaHastaTimestampString = new Date(parseInt(fechaHasta.setDate(daysInMonth(mes, year)))).setHours(23,59,59,0).toString(),
            fechaDesdeString = new Date(parseInt(fechaDesdeTimestampString)),
            fechaHastaString = new Date(parseInt(fechaHastaTimestampString))

        this.setState({
            filter: {
                ...this.state.filter,
                tarea: {
                    ...this.state.filter.tarea,
                    fechaDesde: `${year}-${mes}-01`,
                    fechaHasta: `${year}-${mes}-${daysInMonth(mes, year)}`
                }
            }
        })

        FetchGraphQLGetTareas(this, this.props.usuario, this.state.filter.tarea.tipoFecha, fechaDesdeString, fechaHastaString)
    }

    handleInputChange(event) {
        if(event.target.id === "fechaDesde"){
           this.setState({
                filter: {
                    ...this.state.filter,
                    tarea: {
                        ...this.state.filter.tarea,
                        fechaDesde:  event.target.value
                    }
                }
            })

            let fechaDesdeTimeStamp = new Date(event.target.value.replace(/-/g, '/')),
                fechaHastaTimeStamp = new Date(this.state.filter.tarea.fechaHasta.replace(/-/g, '/')),
                fechaDesdeTimestampString = fechaDesdeTimeStamp.setHours(0,0,0,0),
                fechaHastaTimestampString = fechaHastaTimeStamp.setHours(23,59,59,0),
                fechaDesdeString = new Date(parseInt(fechaDesdeTimestampString)),
                fechaHastaString = new Date(parseInt(fechaHastaTimestampString))
            
            FetchGraphQLGetTareas(this, this.props.usuario, this.state.filter.tarea.tipoFecha, fechaDesdeString, fechaHastaString)
        }else if(event.target.id === "fechaHasta"){
           this.setState({
                filter: {
                    ...this.state.filter,
                    tarea: {
                        ...this.state.filter.tarea,
                        fechaHasta:  event.target.value
                    }
                }
            })

            let fechaDesdeTimeStamp = new Date(this.state.filter.tarea.fechaDesde.replace(/-/g, '/')),
                fechaHastaTimeStamp = new Date(event.target.value.replace(/-/g, '/')),
                fechaDesdeTimestampString = fechaDesdeTimeStamp.setHours(0,0,0,0),
                fechaHastaTimestampString = fechaHastaTimeStamp.setHours(23,59,59,0),
                fechaDesdeString = new Date(parseInt(fechaDesdeTimestampString)),
                fechaHastaString = new Date(parseInt(fechaHastaTimestampString))
            
            FetchGraphQLGetTareas(this, this.props.usuario, this.state.filter.tarea.tipoFecha, fechaDesdeString, fechaHastaString)
        }else if(event.target.id === "tipoFecha"){
            this.setState({
                filter: {
                    ...this.state.filter,
                    tarea: {
                        ...this.state.filter.tarea,
                        tipoFecha:  event.target.value
                    }
                }
            })

            let fechaDesdeTimeStamp = new Date(this.state.filter.tarea.fechaDesde.replace(/-/g, '/')),
                fechaHastaTimeStamp = new Date(this.state.filter.tarea.fechaHasta.replace(/-/g, '/')),
                fechaDesdeTimestampString = fechaDesdeTimeStamp.setHours(0,0,0,0),
                fechaHastaTimestampString = fechaHastaTimeStamp.setHours(23,59,59,0),
                fechaDesdeString = new Date(parseInt(fechaDesdeTimestampString)),
                fechaHastaString = new Date(parseInt(fechaHastaTimestampString))
            
            FetchGraphQLGetTareas(this, this.props.usuario, event.target.value, fechaDesdeString, fechaHastaString)         
        }else if(event.target.id === "estado"){
            this.setState({
                filter: {
                    ...this.state.filter,
                    tarea: {
                        ...this.state.filter.tarea,
                        estado:  event.target.value
                    }
                }
            })
        }else if(event.target.id === "sucursal"){
            this.setState({
                filter: {
                    ...this.state.filter,
                    tarea: {
                        ...this.state.filter.tarea,
                        sucursal: event.target.value
                    }
                }
            })
        }else if(event.target.id === "tipo"){
            this.setState({
                filter: {
                    ...this.state.filter,
                    tarea: {
                        ...this.state.filter.tarea,
                        tipo: event.target.value
                    }
                }
            })
        }else if(event.target.id === "nivelDeVisualizacion"){
            this.setState({
                filter: {
                    ...this.state.filter,
                    tarea: {
                        ...this.state.filter.tarea,
                        nivelDeVisualizacion: event.target.value
                    }
                }
            })
        }else if(event.target.id === "nombre"){
            this.setState({
                filter: {
                    ...this.state.filter,
                    tarea: {
                        ...this.state.filter.tarea,
                        nombre: event.target.value
                    }
                }
            })
        }else if(event.target.id === "meta"){
            this.setState({
                filter: {
                    ...this.state.filter,
                    tarea: {
                        ...this.state.filter.tarea,
                        meta: event.target.value
                    }
                }
            })
        }
    }

    render(){

        let tareasEstadoFilter = this.props.dataAdminObjetivos.events.filter( tarea => {

            let tareaReturn = tarea
      
            if(this.state.filter.tarea.estado === "Todos"){
              tareaReturn = tarea
            }else {
              tareaReturn = tarea.estado === this.state.filter.tarea.estado
            }
      
            return tareaReturn
      
          }),
          tareasSucursalFilter = tareasEstadoFilter.filter( tarea => {
      
            let tareaReturn = tarea
      
            if(this.state.filter.tarea.sucursal === ""){
              tareaReturn = tarea
            }else {
              tareaReturn = tarea.idSucursal === this.state.filter.tarea.sucursal
            }
      
            return tareaReturn
      
          }),
          tareasTipoFilter = tareasSucursalFilter.filter( tarea => {
      
            let tareaReturn = tarea
      
            if(this.state.filter.tarea.tipo === "Todos"){
              tareaReturn = tarea
            }else {
              tareaReturn = tarea.tipo === this.state.filter.tarea.tipo
            }
      
            return tareaReturn
      
          }),
          tareasNivelDeVisualizacionFilter = tareasTipoFilter.filter( tarea => {
      
            let tareaReturn = tarea
      
            if(this.state.filter.tarea.nivelDeVisualizacion === "Cualquiera"){
              tareaReturn = tarea
            }else {
              tareaReturn = tarea.nivelDeVisualizacion === this.state.filter.tarea.nivelDeVisualizacion
            }
      
            return tareaReturn
      
          }),
          tareasNombreFilter = tareasNivelDeVisualizacionFilter.filter( tarea => {
      
            let tareaReturn = tarea
      
            if(this.state.filter.tarea.nombre === ""){
              tareaReturn = tarea
            }else {
              tareaReturn = tarea.nombre.toLowerCase().includes(this.state.filter.tarea.nombre.toLowerCase())
            }
      
            return tareaReturn
      
          }),
          tareasMetaFilter = tareasNombreFilter.filter( tarea => {
      
            let tareaReturn = tarea
      
            if(this.state.filter.tarea.meta === ""){
              tareaReturn = tarea
            }else {
              tareaReturn = tarea.idMeta === this.state.filter.tarea.meta
            }
      
            return tareaReturn
      
          }),
          tareasSelect = tareasMetaFilter

        return(
            <div className="row flex-column flex-xl-row" style={{marginBottom: "25px"}}>

                <div className="col-12 col-xl-10 order-1" style={{marginTop: '15px'}}>
                    <Calendar
                        localizer={localizer}
                        selectable
                        events={tareasSelect}
                        titleAccessor="nombre"
                        startAccessor="fechaDeInicio"
                        endAccessor="fechaFinalPlanificada"
                        style={{ height: 500 }}
                        culture="es"
                        messages={{ next:"Siguiente", previous:"Atrás", today:"Ahora", month: "Mes", week: "Semana", day: "Día", agenda: "Agenda" }}
                        onSelectSlot={ ({ start, end, slots }) => {
            
                            let data = {
                                start,
                                end,
                                slots,
                                stateCheckBox: this.props.state,
                                type: "Tarea"
                            }
        
                            //console.log(data)
            
                            this.props.handleOpenModal(data, "InsertObjMetaTarea")
            
                        } }
                        onSelectEvent={ (event, SyntheticEvent) => {

                            let tarea = event
                            let typeModal = 'TareaView';
                            this.props.handleOpenModal(tarea, typeModal)

                        }}
                        eventPropGetter={EventComponentStyle}
                    />
                </div>
                                
                <div className="col-12 col-xl-2 mx-auto text-center text-lg-left order-3 order-xl-2" style={{marginTop: '15px'}}>
                    <div className="container">
                    <div className="row">
                        
                           

                        <div className="form-group col-12 col-md-6 col-lg-4 col-xl-12 mx-auto">
                        <label htmlFor="estado">Filtrar por Estado</label>
                        <select className="form-control" id="estado" value={this.state.filter.tarea.estado} onChange={this.handleInputChange}>
                            <option value="Todos">Todos</option>
                            <option value="En Proceso">En Proceso</option>
                            <option value="Finalizado">Finalizado</option>
                        </select>
                        </div>
            
                        <div className="form-group col-12 col-md-6 col-lg-4 col-xl-12 mx-auto">
                        <label htmlFor="sucursal">Filtrar por Sucursal</label>
                        <select className="form-control" id="sucursal" value={this.state.filter.tarea.sucursal} onChange={this.handleInputChange}>
                            <option value="">Todos</option>
                            {
                            this.props.sucursales.map( sucursal => <option value={sucursal.id} key={sucursal.id}>{sucursal.nombre_comercial}</option>)
                            }
                        </select>
                        </div>
            
                        <div className="form-group col-12 col-md-6 col-lg-4 col-xl-12 mx-auto">
                        <label htmlFor="tipo">Filtrar por Tipo</label>
                        <select className="form-control" id="tipo" value={this.state.filter.tarea.tipo} onChange={this.handleInputChange}>
                            <option value="Todos">Todos</option>
                            {
                            this.props.etiquetas.map( etiqueta => <option value={etiqueta.nombre} key={etiqueta.id}>{etiqueta.nombre}</option>)
                            }
                        </select>
                        </div>

                        {
                            this.props.usuario.colaborador.puesto.includes('Director') ? (
                                <div className="form-group col-12 col-md-6 col-lg-4 col-xl-12 mx-auto">
                                <label htmlFor="nivelDeVisualizacion">Filtrar por Nivel De Visualización</label>
                                <select className="form-control" id="nivelDeVisualizacion" value={this.state.filter.tarea.nivelDeVisualizacion} onChange={this.handleInputChange}>
                                <option value="Cualquiera">Cualquiera</option>
                                    <option value="Todos">Todos</option>
                                    <option value="Coordinacion">Coordinación</option>
                                    <option value="Direccion">Dirección</option>
                                </select>
                                </div>
                            ) : null
                        }
            
            
                        <div className="form-group col-12 col-md-6 col-lg-4 col-xl-12 mx-auto">
                        <label htmlFor="meta">Filtrar por Meta</label>
                        <select className="form-control" id="meta" value={this.state.filter.tarea.meta} onChange={this.handleInputChange}>
                            <option value="">Todos</option>
                            {
                                this.props.dataAdminObjetivos.metas.map( meta => <option value={meta.id} key={meta.id}>{meta.nombre}</option>)
                            }
                        </select>
                        </div>
            
                        <div className="form-group col-12 col-md-6 col-lg-4 col-xl-12 mx-auto">
                        <label htmlFor="nombre">Filtrar por Nombre</label>
                        <input type="text" className="form-control" id="nombre" placeholder="Filtrar por Nombre" value={this.state.filter.tarea.nombre} onChange={this.handleInputChange}/>
                        </div>
            
                    </div>
                    </div>
                </div>
                
                <div className="col-12 mx-auto text-center order-2 order-xl-3" style={{marginTop: '15px'}}>

                   <div className="container">
                       <div className="row">
                          <div className="form-group col-12 col-md-7 col-lg-4 mx-auto">
                             <label htmlFor="tipoFecha">Tipo de Filtrado de Fecha</label>
                                <select id="tipoFecha" className="form-control" value={this.state.filter.tarea.tipoFecha} onChange={this.handleInputChange}>
                                    <option value="fechaInicio">Fecha Inicio</option>
                                    <option value="fechaFinal">Fecha Final</option>
                                    <option value="fechaFinalPlanificada">Fecha Final Planificada</option>
                                </select>
                          </div> 

                          <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
                            <label htmlFor="fechaDesde">Fecha Desde</label>
                            <input id="fechaDesde" className="form-control" type="date" value={this.state.filter.tarea.fechaDesde} onChange={this.handleInputChange}/>
                          </div>

                          <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
                            <label htmlFor="fechaHasta">Fecha Hasta</label>
                            <input id="fechaHasta" className="form-control" type="date" value={this.state.filter.tarea.fechaHasta} onChange={this.handleInputChange}/>
                          </div>

                       </div>
                   </div> 

                </div>

            </div>
        ) 
    }
    
}

let mapStateToProps = (state, props) => {
    return {
      usuario: state.logIn.usuario,
      sucursales: state.dataEmpresas.sucursales,
      dataAdminObjetivos: state.dataAdminObjetivos,
      etiquetas: state.dataAdminObjetivos.etiquetas.filter( etiqueta => etiqueta.tipoEvento === 'Tarea')
    }
  }
  
  export default connect(mapStateToProps)(CalendarComponent)