import React, { useState } from 'react';

import ModalContainer from '../../Modal/Components/Modal';

import ModalB4 from 'react-bootstrap4-modal';

let DeleteQuestion = props => {

    let modalVisible = props.modalVisible,
    handleCloseModal = props.handleCloseModal

    const [messageState, setMessageState] = useState('none')

    return (
        <ModalContainer>
            <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                    <img className="brand-modal" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco" />
                        <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                            <div></div>
                            <div></div>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p className="text-center">¿Estás segur@ que deseas eliminar el recurso?</p>

                        {
                            messageState === 'success' ? (
                                <div className="alert alert-danger text-center" role="alert">
                                    Se ha eliminado el recurso
                                </div>
                            ) : null
                        }

                        {
                            messageState === 'failure' ? (
                                <div className="alert alert-danger text-center" role="alert">
                                    hubo un error al eliminar
                                </div>
                            ) : null
                        }

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
                        <button type="button" className="btn btn-danger mx-auto" onClick={ () => {
                            
                            props.DeleteFunction(props.data)
                            .then(val => {
                                setMessageState(val)
                            })

                        }}>Eliminar</button>
                    </div>
                    </div>
                </div>
            </ModalB4>
        </ModalContainer>
    )
}

export default DeleteQuestion