import React from 'react';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../../../Modal/Components/Modal.jsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let MensajeriaPlantillaOrdenesRutasView = props => {

  let orden = props.orden,
      modalVisible = props.modalVisible,
      handleCloseModal = props.handleCloseModal,
      usuario,
      empresa

  if (orden.idUsuario !== null) {
    usuario = props.dataUsers.usuarios.filter(usuario => {
      return usuario.id === orden.idUsuario
    })
    usuario = usuario[0]
  } else {
    usuario = null
  }

  if (orden.idEmpresa !== null) {
    empresa = props.dataEmpresas.empresas.filter(empresa => {
      return empresa.id === orden.idEmpresa
    })
    empresa = empresa[0]
  } else {
    empresa = null
  }

  let urgente
  orden.urgente === true ? urgente = "Sí" : urgente = "No"

  return(
      <ModalContainer>
        <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
              <img className="brand-modal" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco" />
              <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                <div></div>
                <div></div>
              </button>
              </div>

              <div className="modal-body container-fluid">
                <div className="row text-center">
                  <div className="col-12">
                    <FontAwesomeIcon icon="clipboard-list" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                    <h1>Información de Orden</h1>
                  </div>
                  <div className="col-12" style={{'marginTop': '25px'}}>

                      <div className="row">

                        <div className="col-md-4 row">
                          <p className="col" id="idOrden" style={{'marginTop': '0px'}}><b>Orden Id Provisional:</b> {orden.idProvisional}</p>
                        </div>

                        {
                          orden.idUsuario === null ? null : (
                            <div className="col-md-4 row">
                              <p className="col" id="nombreUsuario" style={{'marginTop': '0px'}}><b>Nombre de Persona que realizó la orden:</b> {usuario.nombre} {usuario.apellidos}</p>
                            </div>
                          )
                        }
                        
                        {
                          orden.idEmpresa === null ? null : (
                            <div className="col-md-4 row">
                              <p className="col" id="nombreEmpresa" style={{'marginTop': '0px'}}><b>Nombre de Empresa que realizó la orden:</b> {empresa.nombre_comercial}</p>
                            </div>
                          )
                        }

                        {
                          orden.instrucciones === null ? null : (
                            <div className="col-md-4 row">
                              <p className="col" id="instrucciones" style={{'marginTop': '0px'}}><b>Instrucciones:</b> {orden.instrucciones}</p>
                            </div>
                          )
                        }
                        
                        {
                          orden.cantidadDeMuestras === null ? null : (
                            <div className="col-md-4 row">
                              <p className="col" id="cantidadDeMuestras" style={{'marginTop': '0px'}}><b>Cantidad de Muestras:</b> {orden.cantidadDeMuestras}</p>
                            </div>
                          )
                        }
                    
                        <div className="col-md-4 row">
                          <p className="col" id="personaQueLlamo" style={{'marginTop': '0px'}}><b>Persona que llamó:</b> {orden.personaQueLlamo}</p>
                        </div>
                    
                        <div className="col-md-4 row">
                          <p className="col" id="telefono" style={{'marginTop': '0px'}}><b>Teléfono de Contacto:</b> {orden.telefono}</p>
                        </div>
                        
                        <div className="col-md-4 row">
                          <p className="col" id="direccion" style={{'marginTop': '0px'}}><b>Dirección:</b> {orden.direccionDeRecepcion}</p>
                        </div>
                    
                        <div className="col-md-4 row">
                          <p className="col" id="tipo" style={{'marginTop': '0px'}}><b>Tipo:</b> {orden.tipo}</p>
                        </div>
                    
                        <div className="col-md-4 row">
                          <p className="col" id="urgente" style={{'marginTop': '0px'}}><b>Urgente:</b> {urgente}</p>
                        </div>

                      </div>

                  </div>

                </div>
                
              
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
              </div>
            </div>
          </div>
      </ModalB4>
    </ModalContainer>
  )
}

export default MensajeriaPlantillaOrdenesRutasView
