import React, { Component } from 'react';

import AdminObjetivos from '../components/AdminObjetivos';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

import NewObjetivoSubscription from './Subscriptions/NewObjetivoSubscription';
import NewMetaSubscription from './Subscriptions/NewMetaSubscription';
import NewTareaSubscription from './Subscriptions/NewTareaSubscription';

//import { gql } from "apollo-boost";

import GraphQLUsuarios from '../../../../apiService/FetchGraphQLUsuarios.js';
import GraphQLEmpresas from '../../../../apiService/FetchGraphQLEmpresas.js';

import updateStateTareaFetch from './UpdateStateTareaFetch/FetchUpdateStateTareaPromise.js';

import deleteObjetivoFetch from './DeleteObjetivoFetch/FetchDeleteObjetivoPromise.js';
import deleteMetaFetch from './DeleteMetaFetch/FetchDeleteMetaPromise.js';
import deleteTareaFetch from './DeleteTareaFetch/FetchDeleteTareaPromise.js';

class AdminObjetivosContainer extends Component{

	constructor(props) {
        
        super(props);

        this.state = {
            objetivosChecked: false,
            metasChecked: false,
            tareasChecked: true
        }

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
        this.handleDeleteObjetivo = this.handleDeleteObjetivo.bind(this);
        this.handleDeleteMeta = this.handleDeleteMeta.bind(this);
        this.handleDeleteTarea = this.handleDeleteTarea.bind(this);
        this.handleChangeStateTarea = this.handleChangeStateTarea.bind(this);
    }

    componentDidMount(){
        GraphQLUsuarios(this)
        GraphQLEmpresas(this)

        NewObjetivoSubscription(this)
        NewMetaSubscription(this)
        NewTareaSubscription(this)
    }

	handleOpenModal(modal, typeModal){
    	this.props.dispatch({
			type: `OPEN_MODAL`,
			payload: {
				modal,
				typeModal
			}
		})
    }
      
    onCheckBoxChange(event){
        if(event.target.value === "Objetivo"){
            if(this.state.objetivosChecked === true){
                this.setState({
                    objetivosChecked: false
                })
                this.props.dispatch({
                    type: 'REMOVE_EVENTS_ADMIN_OBJETIVOS',
                    payload: {
                        type: event.target.value
                    }
                })
            }else{
                this.setState({
                    objetivosChecked: true
                }) 
                this.props.dispatch({
                    type: 'ADD_EVENTS_ADMIN_OBJETIVOS',
                    payload: {
                        type: event.target.value
                    }
                })
            }
        }else if(event.target.value === "Meta"){
            if(this.state.metasChecked === true){
                this.setState({
                    metasChecked: false
                })
                this.props.dispatch({
                    type: 'REMOVE_EVENTS_ADMIN_OBJETIVOS',
                    payload: {
                        type: event.target.value
                    }
                })
            }else{
                this.setState({
                    metasChecked: true
                }) 
                this.props.dispatch({
                    type: 'ADD_EVENTS_ADMIN_OBJETIVOS',
                    payload: {
                        type: event.target.value
                    }
                })
            }
        }else if(event.target.value === "Tarea"){
            if(this.state.tareasChecked === true){
                this.setState({
                    tareasChecked: false
                })
                this.props.dispatch({
                    type: 'REMOVE_EVENTS_ADMIN_OBJETIVOS',
                    payload: {
                        type: event.target.value
                    }
                })
            }else{
                this.setState({
                    tareasChecked: true
                }) 
                this.props.dispatch({
                    type: 'ADD_EVENTS_ADMIN_OBJETIVOS',
                    payload: {
                        type: event.target.value
                    }
                })
            }
        }
    }

    handleDeleteObjetivo(objetivo){
        return deleteObjetivoFetch(this, objetivo)
    }

    handleDeleteMeta(meta){
        return deleteMetaFetch(this, meta)
    }

    handleDeleteTarea(tarea){
        return deleteTareaFetch(this, tarea)
    }

    handleChangeStateTarea(tarea){
        
        let newTarea = {
            ...tarea,
            estado: "Finalizado",
            fechaFinal: new Date()
        }

        updateStateTareaFetch(this, newTarea, this.state)
    }

	render(){
		if (this.props.conexion === false) {
			return <Redirect to='/login' />
		} else {
            
			return(
				<AdminObjetivos handleOpenModal={this.handleOpenModal} onCheckBoxChange={this.onCheckBoxChange} state={this.state} events={this.props.dataAdminObjetivos.events} objetivos={this.props.dataAdminObjetivos.objetivos} metas={this.props.dataAdminObjetivos.metas} tareas={this.props.dataAdminObjetivos.tareas} display={this.props.display} usuarios={this.props.usuarios} handleChangeDisplay={this.props.handleChangeDisplay} handleDeleteObjetivo={this.handleDeleteObjetivo} handleDeleteMeta={this.handleDeleteMeta} handleDeleteTarea={this.handleDeleteTarea} handleChangeStateTarea={this.handleChangeStateTarea} />
			)

		}
	}
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario,
    usuarios: state.dataUsers.usuarios,
    dataAdminObjetivos: state.dataAdminObjetivos,
    suscribeGraphQL: state.suscribeGraphQL.suscribeGraphQL
  }
}

let mapDispatchToProps = (dispatch) => {
	return {
		dispatch,
		suscriptionObjetivos: (objetivos) => { 
            if(objetivos.data.adminObjetivosSubscription.newObjetivo !== null){
                dispatch({
                    type: 'FETCH_ALL_OBJETIVOS',
                    payload: {
                        objetivosData: objetivos.data.adminObjetivosSubscription.newObjetivo
                    }
                }) 
            }
        },
        suscriptionMetas: (metas) => {
            if(metas.data.adminObjetivosSubscription.newMeta !== null){
                dispatch({
                    type: 'FETCH_ALL_METAS',
                    payload: {
                        metasData: metas.data.adminObjetivosSubscription.newMeta
                    }
                })
            }
        },
        suscriptionTareas: (tareas) => {
            if(tareas.data.adminObjetivosSubscription.newTarea !== null){
                dispatch({
                    type: 'FETCH_ALL_TAREAS',
                    payload: {
                        tareasData: tareas.data.adminObjetivosSubscription.newTarea
                    }
                }) 
            }
        }
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminObjetivosContainer)