import React from 'react';

import './css/ServiciosMedicos.css';

const ServiciosMedicos = (props) => (
	<section id="seccion-serviciosmedicos" className="container" style={{'marginTop' : '150px', 'marginBottom' : '100px'}}>

		<div className="row flex-row-reverse">

			<picture id="imagen" className="col-md-6 col-12">
				<source srcSet="/img/pacientes/serviciosmedicos/doctorhorizontal.jpg" media="(max-width: 768px)" className="col"/>
				<img srcSet="/img/pacientes/serviciosmedicos/doctor.jpg" className="col" alt="Imagen Servicios Medicos"/>
			</picture>

			<div className="col-md-6 col-12">
				<div id="texto-servicios-medicos"/>
				{props.children}
			</div>
			
		</div>
	</section>
)

export default ServiciosMedicos;
