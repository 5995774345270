import React from 'react';

import './css/ImagenPortada.css';

const ImagenPortada = (props) => (
	<div id="contenedor-img-inicio" className="col-12 mx-auto sin-margen-y-padding">
		{props.children}
	</div>
)

export default ImagenPortada;