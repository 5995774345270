import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NavLink } from 'react-router-dom';

let OrdenesTopNavbar = props => {

    let arrayOfMensajeriaValues = [],
    allMensajeriaValuesFalse = true

    if(props.usuario !== null){
      
      if(props.usuario.permisos !== null){
        arrayOfMensajeriaValues = Object.values(props.usuario.permisos.Mensajeria)
        allMensajeriaValuesFalse = arrayOfMensajeriaValues.every(v => v === false)
      }

      if(props.usuario.permisos === null) {
        return null
      }else if( ((props.usuario.permisos !== null) || ((Object.entries(props.usuario.permisos.Mensajeria).length > 0) === true) || (allMensajeriaValuesFalse === false)) && ((window.location.href.toString().split(window.location.host)[1] === '/dashboard/mensajeria/realizar-orden') || (window.location.href.toString().split(window.location.host)[1] === '/dashboard/mensajeria/realizar-orden/muestras') || (window.location.href.toString().split(window.location.host)[1] === '/dashboard/mensajeria/realizar-orden/administrativa'))){
        return(
          <li className="nav-item dropdown text-center text-md-left">
  
            <a className="nav-link dropdown-toggle mr-lg-2" href="#link" style={{'minWidth': '55px'}} data-toggle="dropdown" aria-expanded="false">
              Tipo de Orden
            </a>
  
            <div id="user-dropdown" className="dropdown-menu text-center" aria-labelledby="messagesDropdown">
  
              <NavLink exact to="/dashboard/mensajeria/realizar-orden/administrativa" activeClassName="active" className="nav-link"  style={{color: '#2984be'}}><FontAwesomeIcon icon="clipboard-list" /> Orden Administrativa</NavLink>
  
              <div className="dropdown-divider"  style={{color: '#2984be'}} ></div>
  
              <NavLink exact to="/dashboard/mensajeria/realizar-orden/muestras" activeClassName="active" className="nav-link"  style={{color: '#2984be'}}><FontAwesomeIcon icon="vials" /> Recolección de Muestras</NavLink>
  
            </div>
  
          </li>
        )
      }else{
        return null
      }

    }else{
      return null
    }

  }
  
  export default OrdenesTopNavbar

