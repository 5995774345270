import React, { Component } from 'react';

import { connect } from 'react-redux';

import FetchGraphQLGetObjetivos from '../../containers/FetchGetObjetivos/FetchGraphQLGetObjetivos';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ObjetivosTable extends Component {

  constructor(props) {
    super(props);

		this.state={
			filter: {
        estado: "Todos",
        sucursal: "",
        tipo: "Todos",
        nivelDeVisualizacion: "Cualquiera",
        nombre: "",
        tipoFecha: "fechaInicio",
        fechaDesde: "",
        fechaHasta: ""
      }
    }
    
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  
  componentDidMount() {

    let fecha = new Date(),
        year = fecha.getFullYear()
      let mes
      (fecha.getMonth()+1) < 10 ? mes = `0${fecha.getMonth()+1}` : mes = `${fecha.getMonth()+1}`
      let dia
      fecha.getDate() < 10 ? dia = `0${fecha.getDate()}` : dia = `${fecha.getDate()}`

      let fechaDesde = new Date(),
          fechaHasta = new Date()

      let fechaDesdeTimestampString = new Date(parseInt(fechaDesde.setDate(1))).setHours(0,0,0,0).toString(),
          fechaHastaTimestampString = fechaHasta.setHours(23,59,59,0).toString(),
          fechaDesdeString = new Date(parseInt(fechaDesdeTimestampString)),
          fechaHastaString = new Date(parseInt(fechaHastaTimestampString))

      FetchGraphQLGetObjetivos(this, this.props.usuario, this.state.filter.tipoFecha, fechaDesdeString, fechaHastaString)

      this.setState({
        filter: {
          ...this.state.filter,
          fechaDesde: `${year}-${mes}-01`,
          fechaHasta: `${year}-${mes}-${dia}`
        }
      })

  }
  
  handleInputChange(event) {
    if(event.target.id === "fechaDesde"){
      this.setState({
          filter: {
            ...this.state.filter,
            fechaDesde:  event.target.value
          }
      })

      let fechaDesdeTimeStamp = new Date(event.target.value.replace(/-/g, '/')),
          fechaHastaTimeStamp = new Date(this.state.filter.fechaHasta.replace(/-/g, '/')),
          fechaDesdeTimestampString = fechaDesdeTimeStamp.setHours(0,0,0,0),
          fechaHastaTimestampString = fechaHastaTimeStamp.setHours(23,59,59,0),
          fechaDesdeString = new Date(parseInt(fechaDesdeTimestampString)),
          fechaHastaString = new Date(parseInt(fechaHastaTimestampString))
      
      FetchGraphQLGetObjetivos(this, this.props.usuario, this.state.filter.tipoFecha, fechaDesdeString, fechaHastaString)
    }else if(event.target.id === "fechaHasta"){
      this.setState({
          filter: {
            ...this.state.filter,
            fechaHasta:  event.target.value
          }
      })

      let fechaDesdeTimeStamp = new Date(this.state.filter.fechaDesde.replace(/-/g, '/')),
          fechaHastaTimeStamp = new Date(event.target.value.replace(/-/g, '/')),
          fechaDesdeTimestampString = fechaDesdeTimeStamp.setHours(0,0,0,0),
          fechaHastaTimestampString = fechaHastaTimeStamp.setHours(23,59,59,0),
          fechaDesdeString = new Date(parseInt(fechaDesdeTimestampString)),
          fechaHastaString = new Date(parseInt(fechaHastaTimestampString))
      
      FetchGraphQLGetObjetivos(this, this.props.usuario, this.state.filter.tipoFecha, fechaDesdeString, fechaHastaString)
    }else if(event.target.id === "tipoFecha"){
      this.setState({
          filter: {
            ...this.state.filter,
            tipoFecha:  event.target.value
          }
      })

      let fechaDesdeTimeStamp = new Date(this.state.filter.fechaDesde.replace(/-/g, '/')),
          fechaHastaTimeStamp = new Date(this.state.filter.fechaHasta.replace(/-/g, '/')),
          fechaDesdeTimestampString = fechaDesdeTimeStamp.setHours(0,0,0,0),
          fechaHastaTimestampString = fechaHastaTimeStamp.setHours(23,59,59,0),
          fechaDesdeString = new Date(parseInt(fechaDesdeTimestampString)),
          fechaHastaString = new Date(parseInt(fechaHastaTimestampString))
      
      FetchGraphQLGetObjetivos(this, this.props.usuario, event.target.value, fechaDesdeString, fechaHastaString)         
    }else if(event.target.id === "estado"){
      this.setState({
        filter: {
          ...this.state.filter,
          estado: event.target.value
        }
      })
    }else if(event.target.id === "sucursal"){
      this.setState({
        filter: {
          ...this.state.filter,
          sucursal: event.target.value
        }
      })
    }else if(event.target.id === "tipo"){
      this.setState({
        filter: {
          ...this.state.filter,
          tipo: event.target.value
        }
      })
    }else if(event.target.id === "nivelDeVisualizacion"){
      this.setState({
        filter: {
          ...this.state.filter,
          nivelDeVisualizacion: event.target.value
        }
      })
    }else if(event.target.id === "nombre"){
      this.setState({
        filter: {
          ...this.state.filter,
          nombre: event.target.value
        }
      })
    }
  }

  render(){

    let objetivosEstadoFilter = this.props.objetivos.filter( objetivo => {

      let objetivoReturn = objetivo

      if(this.state.filter.estado === "Todos"){
        objetivoReturn = objetivo
      }else {
        objetivoReturn = objetivo.estado === this.state.filter.estado
      }

      return objetivoReturn

    }),
    objetivosSucursalFilter = objetivosEstadoFilter.filter( objetivo => {

      let objetivoReturn = objetivo

      if(this.state.filter.sucursal === ""){
        objetivoReturn = objetivo
      }else {
        objetivoReturn = objetivo.idSucursal === this.state.filter.sucursal
      }

      return objetivoReturn

    }),
    objetivosTipoFilter = objetivosSucursalFilter.filter( objetivo => {

      let objetivoReturn = objetivo

      if(this.state.filter.tipo === "Todos"){
        objetivoReturn = objetivo
      }else {
        objetivoReturn = objetivo.tipo === this.state.filter.tipo
      }

      return objetivoReturn

    }),
    objetivosNivelDeVisualizacionFilter = objetivosTipoFilter.filter( objetivo => {

      let objetivoReturn = objetivo

      if(this.state.filter.nivelDeVisualizacion === "Cualquiera"){
        objetivoReturn = objetivo
      }else {
        objetivoReturn = objetivo.nivelDeVisualizacion === this.state.filter.nivelDeVisualizacion
      }

      return objetivoReturn

    }),
    objetivosNombreFilter = objetivosNivelDeVisualizacionFilter.filter( objetivo => {

      let objetivoReturn = objetivo

      if(this.state.filter.nombre === ""){
        objetivoReturn = objetivo
      }else {
        objetivoReturn = objetivo.nombre.toLowerCase().includes(this.state.filter.nombre.toLowerCase())
      }

      return objetivoReturn

    }),
    objetivosSelect = objetivosNombreFilter

    return(
      <div className="table-responsive" style={{minHeight: "50vh"}}>
    
        <button style={{backgroundColor: 'transparent', border: 'none', color: '#2984be'}} onClick={ () => {
          let type = "CalendarButton"
          this.props.handleChangeDisplay(type)
        } }><FontAwesomeIcon icon="arrow-left" style={{fontSize: '25px'}} /></button>
    
        <div className="container" style={{marginTop: '25px'}}>
            <div className="row">

              <div className="form-group col-12 col-md-7 col-lg-4 mx-auto">
                  <label htmlFor="tipoFecha">Tipo de Filtrado de Fecha</label>
                    <select id="tipoFecha" className="form-control" value={this.state.filter.tipoFecha} onChange={this.handleInputChange}>
                        <option value="fechaInicio">Fecha Inicio</option>
                        <option value="fechaFinal">Fecha Final</option>
                        <option value="fechaFinalPlanificada">Fecha Final Planificada</option>
                    </select>
              </div> 

              <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
                <label htmlFor="fechaDesde">Fecha Desde</label>
                <input id="fechaDesde" className="form-control" type="date" value={this.state.filter.fechaDesde} onChange={this.handleInputChange}/>
              </div>

              <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
                <label htmlFor="fechaHasta">Fecha Hasta</label>
                <input id="fechaHasta" className="form-control" type="date" value={this.state.filter.fechaHasta} onChange={this.handleInputChange}/>
              </div>

              <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
                <label htmlFor="estado">Filtrar por Estado</label>
                <select className="form-control" id="estado" value={this.state.filter.estado} onChange={this.handleInputChange}>
                  <option value="Todos">Todos</option>
                  <option value="En Proceso">En Proceso</option>
                  <option value="Finalizado">Finalizado</option>
                </select>
              </div>

              <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
                <label htmlFor="sucursal">Filtrar por Sucursal</label>
                <select className="form-control" id="sucursal" value={this.state.filter.sucursal} onChange={this.handleInputChange}>
                  <option value="">Todos</option>
                  {
                    this.props.sucursales.map( sucursal => <option value={sucursal.id} key={sucursal.id}>{sucursal.nombre_comercial}</option>)
                  }
                </select>
              </div>

              <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
                <label htmlFor="tipo">Filtrar por Tipo</label>
                <select className="form-control" id="tipo" value={this.state.filter.tipo} onChange={this.handleInputChange}>
                  <option value="Todos">Todos</option>
                  {
                    this.props.etiquetas.map( etiqueta => <option value={etiqueta.nombre} key={etiqueta.id}>{etiqueta.nombre}</option>)
                  }
                </select>
              </div>

              {
                  this.props.usuario.colaborador.puesto.includes('Director') ? (
                    <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
                      <label htmlFor="nivelDeVisualizacion">Filtrar por Nivel De Visualización</label>
                      <select className="form-control" id="nivelDeVisualizacion" value={this.state.filter.nivelDeVisualizacion} onChange={this.handleInputChange}>
                      <option value="Cualquiera">Cualquiera</option>
                        <option value="Todos">Todos</option>
                        <option value="Coordinacion">Coordinación</option>
                        <option value="Direccion">Dirección</option>
                      </select>
                    </div>
                  ) : null
              }

              <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
                <label htmlFor="nombre">Filtrar por Nombre</label>
                <input type="text" className="form-control" id="nombre" placeholder="Filtrar por Nombre" value={this.state.filter.nombre} onChange={this.handleInputChange}/>
              </div>

            </div>
        </div>
    
        <div className="justify-content-end">
            <button onClick={() => {
              let fecha = new Date()
              let data ={
                start: fecha,
                end: fecha,
                type: "Objetivo"
              }
              this.props.handleOpenModal(data, "InsertObjMetaTarea")
            }}
            type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'float': 'right', 'color': '#2984be'}}>
              <FontAwesomeIcon icon="plus-circle" /> Nuevo Objetivo <FontAwesomeIcon icon="calendar-check" />
            </button>
        </div>
        <table className="table text-center table-bordered table-hover table-sm">
          <thead className="thead-light">
            <tr>
              <th scope="col">Nombre</th>
              <th className="d-none d-md-table-cell" scope="col">Avance Objetivo</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {
              objetivosSelect.map( objetivo => {
    
                let usuarios = this.props.usuarios
    
                objetivo.creador = usuarios.filter( usuario => usuario.id === objetivo.idCreador )[0]
    
                objetivo.invitados = usuarios.filter( usuario => objetivo.idsInvolucrados.includes(usuario.id) )
    
                objetivo.metas = this.props.metas.filter( meta => meta.idObjetivo === objetivo.id)
    
                let porcentaje = 0
    
                if(objetivo.metas.length > 0){
    
                  let metasFinalizadas = objetivo.metas.filter( meta => meta.estado === "Finalizado").length,
                      metasFinalizadasSinAlcanzarMeta = objetivo.metas.filter( meta => meta.estado === "Finalizado Sin Alcanzar Meta").length,
                      metasLength = objetivo.metas.length,
                      totalFinalizadas = metasFinalizadas + metasFinalizadasSinAlcanzarMeta
            
                  porcentaje = (totalFinalizadas * 100) / metasLength
            
                }
    
                return (
                  <tr key={objetivo.id}>
                    {
                      objetivo.estado === "Finalizado" ? <td style={{textDecoration: "line-through"}}>{objetivo.nombre}</td> : <td>{objetivo.nombre}</td>
                    }
                    <td className="d-none d-md-table-cell" >
                      {
                        objetivo.metas.length > 0 ? (
                          <div className="col-12 col-md-9 mx-auto" style={{marginTop: '5px'}}>
                            <div className="progress">
                              <div className="progress-bar bg-info" style={{ width: `${porcentaje}%` }} role="progressbar" aria-valuenow={porcentaje} aria-valuemin="0" aria-valuemax="100">{porcentaje}%</div>
                            </div>
                          </div>
                        ) : null
                      }
                    </td>
                    <td>
                      <button type="button" onClick={() => {
                          let typeModal = 'ObjetivoView';
                          this.props.handleOpenModal(objetivo, typeModal)
                      }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                        <FontAwesomeIcon icon="eye" />
                      </button> {/*ref={this.props.setButtonViewUser}*/}
    
                      <button type="button" onClick={() => {
                          let typeModal = 'EditObjMetaTarea';
                          let data = {
                            type: 'Objetivo',
                            objetivo
                          }
                          this.props.handleOpenModal(data, typeModal)
                      }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                        <FontAwesomeIcon icon="edit" />
                      </button>{/*ref={this.props.setButtonEditUser}*/}
    
                      <button onClick={ () => {

                          let typeModal = 'DeleteQuestion';

                          let data = {
                            DeleteFunction: this.props.handleDeleteObjetivo,
                            data: objetivo
                          }

                          this.props.handleOpenModal(data, typeModal)

                        }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                        <FontAwesomeIcon icon="trash-alt" />
                      </button>
    
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    )
  }

}

let mapStateToProps = (state, props) => {
  return {
    usuario: state.logIn.usuario,
    sucursales: state.dataEmpresas.sucursales,
    objetivos: state.dataAdminObjetivos.objetivos,
    etiquetas: state.dataAdminObjetivos.etiquetas.filter( etiqueta => etiqueta.tipoEvento === 'Objetivo')
  }
}

export default connect(mapStateToProps)(ObjetivosTable)
