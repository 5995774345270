import React, { Component } from 'react';

import { connect } from 'react-redux';

import Loader from '../../Components/Loader.jsx';

import EditSave from './EditSave.jsx';

import $ from 'jquery';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/monokai.css';
import 'codemirror/lib/codemirror';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/xml/xml';

//import summernote from 'summernote/dist/summernote-bs4';
import 'summernote/dist/summernote-bs4';
import 'summernote/dist/summernote-bs4.css'; // import styles
import 'summernote/dist/lang/summernote-es-ES'; // you can import any other locale*/

class Summernote extends Component{

  componentDidMount(){
    $(this.props.itemID).summernote('code', this.props.texto);
    $(this.props.itemID).summernote('destroy');
  }

  render(){

    if(((this.props.usuario === null) && (this.props.conexion === false)) || ((this.props.conexion === true) && (this.props.usuario.permisos.AdminUI === false)) || ((this.props.conexion === true) && (this.props.usuario.permisos.AdminUI === null))){
      return null
    }else if(this.props.usuario === null){
      return <Loader />
    }else if((this.props.conexion === true) && (this.props.usuario.permisos.AdminUI === true)){//.replace(/"/g,"").indexOf('adminUI') === 1
      if(this.props.path === '/'){
        return(
          <div className="container">
            <div className="row">
            <div className="col d-flex justify-content-center" style={{top: '-70px'}}>
              <EditSave textObj={this.props.textObj} itemID={this.props.itemID} url={this.props.url} path={this.props.path}/>
            </div>
            </div>
          </div>
        )
      }else{
        return(
          <div className="container">
            <div className="row">
            <div className="col d-flex justify-content-center">
              <EditSave textObj={this.props.textObj} itemID={this.props.itemID} url={this.props.url}/>
            </div>
            </div>
          </div>
        )
      }
    }

  }

}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario
  }
}

export default connect(mapStateToProps)(Summernote)
