import React from 'react';

import './css/NavbarVeterinaria.css';

let NavbarPacientes = props => (
	<nav id="NavbarVeterinaria" className="navbar navbar-expand-md navbar-default section">
		{props.children}
	</nav>
)

export default NavbarPacientes;
