import React from 'react';

import './css/MisionVisionValores.css';

import Mision from '../../Containers/MisionVisionValores/Mision.jsx';

import Vision from '../../Containers/MisionVisionValores/Vision.jsx';

import Politica from '../../Containers/MisionVisionValores/Politica.jsx';

const MisionVisionValores = props => (
	<section id="seccion-mision-vision-valores" className="container-fluid sin-margen-y-padding row">
		<Mision />
		<Vision />
		<Politica />
	</section>
)

export default MisionVisionValores;
