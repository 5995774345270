// JavaScript Document
import GraphQL from './ConsultaGraphQLGetOrdenCompra';

import url from '../../../../../apiService/ApiUrl.js';

function handleError(err){
	console.log(`Request failed: ${err}`);
}

const fetchGetOrdenCompra = (This, idOrden) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(idOrden)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {
		//console.log(res)

		let orden = {
			...res.data.appInfo.ordenesCompra.ordenCompra,
			fecha: new Date(res.data.appInfo.ordenesCompra.ordenCompra.fecha),
			emailTracking: res.data.appInfo.ordenesCompra.ordenCompra.emailTracking !== null ? {
				...res.data.appInfo.ordenesCompra.ordenCompra.emailTracking,
				fecha: new Date(res.data.appInfo.ordenesCompra.ordenCompra.emailTracking.fecha)
			} : null,
			passPago: res.data.appInfo.ordenesCompra.ordenCompra.passPago !== null ? {
				...res.data.appInfo.ordenesCompra.ordenCompra.passPago,
				fechaPass: new Date(parseInt(res.data.appInfo.ordenesCompra.ordenCompra.passPago.fechaPass)),
				fechaDePago: new Date(parseInt(res.data.appInfo.ordenesCompra.ordenCompra.passPago.fechaDePago))
			} : null
		}

		let proveedor

		if((This.props.empresas.length > 0) && (orden.idProveedor !== null)){
			proveedor = This.props.empresas.filter( empresa => empresa.id === orden.idProveedor)[0]
		}else if(orden.proveedor !== null){
			proveedor = orden.proveedor
		}

		This.setState({
			orden,
			defaultCorreo: orden.idProveedor === null ? false : true,
			correo: orden.idProveedor !== null ? proveedor.correo : ''
		})

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchGetOrdenCompra;
