import React, { Component } from 'react';

import { connect } from 'react-redux';

import Summernote from '../../Summernote/Containers/Summernote.jsx';

import ContactoComponent from '../Components/Contacto.jsx';

class contacto extends Component {

  render(){

    let renderVal = this.props.dataUI,
        textContacto1Obj = renderVal.textos.find( text => {
          return text.id === 'tex008'
        }),
        textContacto2Obj = renderVal.textos.find( text => {
          return text.id === 'tex009'
        }),
        textContacto3Obj = renderVal.textos.find( text => {
          return text.id === 'tex010'
        }),
        textContacto4Obj = renderVal.textos.find( text => {
          return text.id === 'tex017'
        }),
        textoContacto1 = textContacto1Obj.texto,
        textoContacto2 = textContacto2Obj.texto,
        textoContacto3 = textContacto3Obj.texto,
        textoContacto4 = textContacto4Obj.texto

    return(
      <ContactoComponent>
        <div className="container">
    			<div id="laboratorios-analisis-clinicos" className="col-12">
    				<div className="row">
    					<h4 className="col-12">LABORATORIOS DE ANÁLISIS CLÍNICOS</h4>
    				</div>
    				<div className="row">

              <div className="texto col-sm-6">
                <div id="texto-contacto1" className="texto" />
                <Summernote itemID='#texto-contacto1' textObj={textContacto1Obj} texto={textoContacto1} url='http://localhost:8080/updateText' />
              </div>
    					<div className="mapa col-sm-6">
    						<iframe className="col-12" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3860.7899542760656!2d-90.48636768515965!3d14.611038089795734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a301e6716a49%3A0xa03781016a02f97b!2sBiolab!5e0!3m2!1ses-419!2s!4v1498355293723" frameBorder="0" style={{border: '0'}} allowFullScreen title="Mapa Biolab Cayalá"></iframe>
    					</div>
    				</div>

            <div className="row">
              <div className="texto col-sm-6">
                <div id="texto-contacto4" className="texto" />
                <Summernote itemID='#texto-contacto4' textObj={textContacto4Obj} texto={textoContacto4} url='http://localhost:8080/updateText' />
              </div>
    					<div className="mapa col-sm-6">
    						<iframe className="col-12" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3860.3683250360327!2d-90.5833898852364!3d14.635021480156013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a10f6a628d2f%3A0x8e48960a35c6564b!2sBiolab%20Plaza%20Madero!5e0!3m2!1ses-419!2sgt!4v1574987339530!5m2!1ses-419!2sgt" frameBorder="0" style={{border: '0'}} allowFullScreen title="Mapa Biolab Petaba"></iframe>
    					</div>
    				</div>

    				<div className="row">
              <div className="texto col-sm-6">
                <div id="texto-contacto2" className="texto" />
                <Summernote itemID='#texto-contacto2' textObj={textContacto2Obj} texto={textoContacto2} url='http://localhost:8080/updateText' />
              </div>
    					<div className="mapa col-sm-6">
    						<iframe className="col-12" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.1173389006635!2d-90.54718768467647!3d14.592388989807686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a114c97f6f13%3A0x6263339a2046e0a1!2sBiolab!5e0!3m2!1ses!2sgt!4v1497371757111" frameBorder="0" style={{border: '0'}} allowFullScreen title="Mapa Biolab Petaba"></iframe>
    					</div>
    				</div>

    			</div>
    			<div id="laboratorios-de-referencia" className="col-12">
    				<div className="row">
    					<h4 className="col-12">LABORATORIOS DE REFERENCIAS DE ANÁLISIS CLÍNICOS E INDUSTRIALES</h4>
    				</div>
    				<div className="row">
              <div className="texto col-sm-6">
                <div id="texto-contacto3" className="texto" />
                <Summernote itemID='#texto-contacto3' textObj={textContacto3Obj} texto={textoContacto3} url='http://localhost:8080/updateText' />
              </div>
    					<div className="mapa col-sm-6">
    						<iframe className="col-12" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.1421782934335!2d-90.54957778515985!3d14.590973089808537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf1198a3ab984ab3c!2sBiolab+Referencia+E+Industrial!5e0!3m2!1ses!2sus!4v1497371478205" frameBorder="0" style={{border: '0'}} allowFullScreen title="Mapa Biolab Referencia"></iframe>
    					</div>
    				</div>
    			</div>
    		</div>
      </ContactoComponent>
    )

  }


}

let mapStateToProps = (state, props) => {
  return {
    dataUI: state.dataUI
  }
}

export default connect(mapStateToProps)(contacto);
