import React from 'react';

import LeftMenuTodos from './LeftMenuTodos';

let ColaboradorMenu = props => {

    return(<LeftMenuTodos usuario={props.usuario}/>)

    /*if(props.usuario.colaborador.puesto.includes("Director") === true){
        return(<LeftMenuTodos usuario={props.usuario}/>)
    }*/

}

export default ColaboradorMenu

