import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let MiCuenta = props => {
  let usuario = props.usuario

  //console.log(usuario)

  /*let fechaInicial = new Date(usuario.fecha_de_nacimiento).getTime(),
      fechaString = fechaInicial.toString();*/

  //console.log(usuario)

  let fecha_de_nacimiento_Convertido = new Date(parseInt(usuario.fecha_de_nacimiento))
  let mesFecha
  (fecha_de_nacimiento_Convertido.getMonth()+1) < 10 ? mesFecha = `0${fecha_de_nacimiento_Convertido.getMonth()+1}` : mesFecha = `${fecha_de_nacimiento_Convertido.getMonth()+1}`
  let diaFecha
  fecha_de_nacimiento_Convertido.getDate() < 10 ? diaFecha = `0${fecha_de_nacimiento_Convertido.getDate()}` : diaFecha = `${fecha_de_nacimiento_Convertido.getDate()}`
  let fechaFinal = `${diaFecha}/${mesFecha}/${fecha_de_nacimiento_Convertido.getFullYear()}`

  //console.log(fechaString)

  return(
      <div className="sin-margen-y-padding container-fluid">
        <div className="row text-center">
          <div className="col-12">
            <FontAwesomeIcon icon="user-circle" style={{'fontSize': '400%', 'marginTop': '25px'}} />
            <h1>{usuario.nombre} {usuario.apellidos}</h1>
          </div>
          <div className="col-12" style={{'marginTop': '25px'}}>

              <div className="row">

                <div className="col-md-4 row">
                  <p className="col" id="usuario" style={{'marginTop': '0px'}}><b>Usuario:</b> {usuario.usuario}</p>
                </div>

                <div className="col-md-4 row">
                  <p className="col" id="nombre" style={{'marginTop': '0px'}}><b>Nombre:</b> {usuario.nombre}</p>
                </div>

                <div className="col-md-4 row">
                  <p className="col" id="apellidos" style={{'marginTop': '0px'}}><b>Apellidos:</b> {usuario.apellidos}</p>
                </div>

                <div className="col-md-4 row">
                  <p className="col" id="telefono" style={{'marginTop': '0px'}}><b>Teléfono:</b> {usuario.telefono}</p>
                </div>

                <div className="col-md-4 row">
                  <p className="col" id="celular" style={{'marginTop': '0px'}}><b>Celular:</b> {usuario.celular}</p>
                </div>

                <div className="col-md-4 row">
                  <p className="col" id="email" style={{'marginTop': '0px'}}><b>Correo:</b> {usuario.email}</p>
                </div>

                <div className="col-md-4 row">
                  <p className="col" id="DPI" style={{'marginTop': '0px'}}><b>DPI:</b> {usuario.DPI}</p>
                </div>

                <div className="col-md-4 row">
                  <p className="col" id="fecha-de-naciemiento" style={{'marginTop': '0px'}}><b>Fecha de Nacimiento:</b> {fechaFinal}</p>
                </div>

                <div className="col-md-4 row">
                  <p className="col" id="direccion" style={{'marginTop': '0px'}}><b>Dirección:</b> {usuario.direccion}</p>
                </div>

                <div className="col-md-4 row">
                  <p className="col" id="NIT" style={{'marginTop': '0px'}}><b>NIT:</b> {usuario.NIT}</p>
                </div>

                <div className="col-md-4 row">
                  <p className="col" id="nota" style={{'marginTop': '0px'}}><b>Nota:</b> {usuario.nota}</p>
                </div>

                <div className="col-md-4 row">
                  <p className="col" id="sexo" style={{'marginTop': '0px'}}><b>Sexo:</b> {usuario.sexo}</p>
                </div>

              </div>

          </div>

          <div className="col-12">
            <FontAwesomeIcon icon="building" style={{'fontSize': '400%', 'marginTop': '25px'}} />
            <h1>Empresa Asociada: {usuario.empresa_asociada.nombre}</h1>
          </div>
          <div className="col-12 row" style={{'marginTop': '25px'}}>

            <div className="col-md-4 row">
              <p className="col" id="nombreEmpresa" style={{'marginTop': '0px'}}><b>Nombre de Empresa:</b> {usuario.empresa_asociada.nombre}</p>
            </div>

            <div className="col-md-4 row">
              <p className="col" id="TelefonoEmpresa" style={{'marginTop': '0px'}}><b>Teléfono de Empresa:</b> {usuario.empresa_asociada.telefono}</p>
            </div>

            <div className="col-md-4 row">
              <p className="col" id="CorreoEmpresa" style={{'marginTop': '0px'}}><b>Correo de Empresa:</b> {usuario.empresa_asociada.correo}</p>
            </div>

          </div>

          <div className="col col-md-3 mx-auto" style={{'marginBottom' : '15px'}}>
            <button type="button" onClick={() => {
                let typeModal = 'UserModalEdit';
                props.handleOpenModal(usuario, typeModal)
            }} style={{'textDecoration': 'none', 'color': '#FFFFFF', 'border': 'none', 'backgroundColor': '#2984be', 'cursor': 'pointer'}}>
              <FontAwesomeIcon icon="edit" />
            </button>
          </div>

        </div>

      </div>
  )
}

export default MiCuenta
