import React from 'react';

import './css/BotonDashboard.css';

import BotonAdminUI from './BotonAdminUI';
import BotonAdminUser from './BotonAdminUser';
//import BotonQR from './BotonQR';

let BotonDashboard = props => {
  return(
    <div>
      <BotonAdminUI usuario={props.usuario} />
      <BotonAdminUser usuario={props.usuario} />
      {/*<BotonQR usuario={props.usuario} />*/}
    </div>
  )
}

export default BotonDashboard
