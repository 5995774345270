let consulta = (objetivoJSON) => {

  const string =
    `mutation {
      adminObjetivos {
        deleteObjetivo(idObjetivo: "${objetivoJSON.id}"){
          id
          mensaje
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
