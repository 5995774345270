let handleInputChange = (event, This) => {

  let value = event.target.value,
      empresa = event.target.dataset.empresa,
      type = event.target.dataset.valueType

  This.props.dataEmpresas.empresas.filter( obj => {

    if((obj.id === JSON.parse(empresa).id) === true){

      //console.log(empresa)

      if(type === 'nombre_comercial'){

        //console.log(obj)

        obj.nombre_comercial = value

        This.props.dispatch({
          type:'CHANGE_DATA_EMPRESAS',
          payload: {
            dataEmpresa: obj
          }
        })

      }else if(type === 'nombre_razon_social'){

        //console.log(obj)

        obj.nombre_razon_social = value

        This.props.dispatch({
          type:'CHANGE_DATA_EMPRESAS',
          payload: {
            dataEmpresa: obj
          }
        })

      }else if(type === 'telefono'){

        //console.log(obj)

        obj.telefono = value

        This.props.dispatch({
          type:'CHANGE_DATA_EMPRESAS',
          payload: {
            dataEmpresa: obj
          }
        })

      }else if(type === 'correo'){

        //console.log(obj)

        obj.correo = value

        This.props.dispatch({
          type:'CHANGE_DATA_EMPRESAS',
          payload: {
            dataEmpresa: obj
          }
        })

      }else if(type === 'NIT'){

        //console.log(obj)

        obj.NIT = value

        This.props.dispatch({
          type:'CHANGE_DATA_EMPRESAS',
          payload: {
            dataEmpresa: obj
          }
        })

      }else if(type === 'direccion_fiscal'){

        //console.log(obj)

        obj.direccion_fiscal = value

        This.props.dispatch({
          type:'CHANGE_DATA_EMPRESAS',
          payload: {
            dataEmpresa: obj
          }
        })

      }else if(type === 'direccion_comercial'){

        //console.log(obj)

        obj.direccion_comercial = value

        This.props.dispatch({
          type:'CHANGE_DATA_EMPRESAS',
          payload: {
            dataEmpresa: obj
          }
        })

      }else if(type === 'descripcion'){

        //console.log(obj)

        obj.descripcion = value

        This.props.dispatch({
          type:'CHANGE_DATA_EMPRESAS',
          payload: {
            dataEmpresa: obj
          }
        })

      }else if(type === 'convenio'){

        //console.log(obj)

        obj.convenio = value

        This.props.dispatch({
          type:'CHANGE_DATA_EMPRESAS',
          payload: {
            dataEmpresa: obj
          }
        })

      }else if(type === 'credito_autorizado'){

        //console.log(obj)

        obj.credito_autorizado = value

        This.props.dispatch({
          type:'CHANGE_DATA_EMPRESAS',
          payload: {
            dataEmpresa: obj
          }
        })

      }else if(type === 'dias_de_credito_autorizado'){

        //console.log(obj)

        obj.dias_de_credito_autorizado = value

        This.props.dispatch({
          type:'CHANGE_DATA_EMPRESAS',
          payload: {
            dataEmpresa: obj
          }
        })

      }else if(type === 'tipo_de_pago_comun'){

        //console.log(obj)

        obj.tipo_de_pago_comun = value

        This.props.dispatch({
          type:'CHANGE_DATA_EMPRESAS',
          payload: {
            dataEmpresa: obj
          }
        })

      }else if(type === 'clasificacionEmpresa'){

        let jsonEmpresa = JSON.parse(empresa)
        let actualClasificacion = jsonEmpresa.clasificacion_empresa

        let includesClasificacion = actualClasificacion.includes(value)

        let newArr

        if(includesClasificacion === true){
          newArr = actualClasificacion.filter( clasificacion => clasificacion !== value)
        }else{
          newArr = [...actualClasificacion, value]
        }

        obj.clasificacion_empresa = newArr

        This.props.dispatch({
          type:'CHANGE_DATA_EMPRESAS',
          payload: {
            dataEmpresa: obj
          }
        })

      }else if(type === 'proveedor_autorizado'){

        let val = value === 'true' ? true : false

        obj.proveedor_autorizado = !val
        This.props.dispatch({
          type:'CHANGE_DATA_EMPRESAS',
          payload: {
            dataEmpresa: obj
          }
        }) 
      }

    }

    return null

  })

}

export default handleInputChange;
