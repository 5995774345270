import React, { Component } from 'react';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../../../Modal/Components/Modal.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class MensajeriaOrdenChangeEstadoYCantidadDeMuestras extends Component {

  constructor(props) {

    super(props);

    // No llames this.setState() aquí!
    this.state = {
      cancelar: false
    };

    this.handleCancelar = this.handleCancelar.bind(this);
  }

  handleCancelar(){
    if(this.state.cancelar === false){
      this.setState({
        cancelar: true
      })
    }else{
      this.setState({
        cancelar: false
      })
    }
  }

  render(){

    let orden = this.props.orden,
        ordenString = JSON.stringify(orden),
        modalVisible = this.props.modalVisible,
        handleCloseModal = this.props.handleCloseModal

    if(this.state.cancelar === false){

      return(
        <ModalContainer>
          <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                <img className="brand-modal" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco" />
                <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                  <div></div>
                  <div></div>
                </button>
                </div>
  
                <div className="sin-margen-y-padding container-fluid">
                
                  <div className="row text-center">
                    <div className="col-12">
                      <FontAwesomeIcon icon="clipboard-list" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                      <h1>Id de Orden: {orden.id}</h1>
                    </div>
  
                    <div className="col-12 col-md-10" style={{'marginTop': '25px'}}>
                      <form>
                        <div className="form-group row">
  
                          <div className="col-md-4 row mx-auto" style={{marginTop: '15px'}}>
                                <label htmlFor="cantidadDeMuestras" className="col"><b>Cantidad de Muestras:</b></label>
                                {
                                  (orden.cantidadDeMuestras === null) ? (
                                    <input ref={this.props.setRef} data-orden={ordenString} data-value-type="cantidadDeMuestras" type="number"  className="col form-control-plaintext form-control-sm inputUserVal" id="cantidadDeMuestras" min="0" max="30" defaultValue="0" style={{'top': '-3px'}}/>
                                  ) : (
                                    <input ref={this.props.setRef} data-orden={ordenString} data-value-type="cantidadDeMuestras" type="number"  className="col form-control-plaintext form-control-sm inputUserVal" id="cantidadDeMuestras" min="1" max="30" defaultValue={orden.cantidadDeMuestras} style={{'top': '-3px'}}/>
                                  ) 
                                }
                          </div>
  
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="row">
                    <button type="button" className="btn btn-outline-danger mx-auto" onClick={this.handleCancelar}>Cancelar</button>
                  </div>

                  {
                    this.props.mensajeDeCambioDeEstadoYCantidadDeMuestras !== null ? (
                      <div className="row" style={{marginTop: '25px'}}>
                        {
                          (this.props.mensajeDeCambioDeEstadoYCantidadDeMuestras.response === 'success') ? (
                            <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.props.mensajeDeCambioDeEstadoYCantidadDeMuestras.mensaje}</div>
                          ) : (
                            <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.props.mensajeDeCambioDeEstadoYCantidadDeMuestras.mensaje}</div>
                          )
                        }
                      </div>
                    ) : null
                  }
                </div>
  
                <div className="modal-footer row" style={{height: 'auto'}}>
  
                    <div className="col-12 col-md" style={{marginTop: '15px'}}>
                      <button type="button" className="btn btn-default mx-auto" onClick={ () => {
                        this.props.handleChangeEstadoYCantidadDeMuestras(orden)
                      }}><FontAwesomeIcon icon="sync-alt" style={{'fontSize': '150%'}} /></button>
                    </div>
  
                    <div className="col-12 col-md" style={{marginTop: '15px'}}>
                      <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
                    </div>
  
  
                </div>
              </div>
            </div>
          </ModalB4>
        </ModalContainer>
      )

    } else {

      return (
        <ModalContainer>
          <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                <img className="brand-modal" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco" />
                <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                  <div></div>
                  <div></div>
                </button>
                </div>
  
                <div className="sin-margen-y-padding container-fluid">
                
                  <div className="row text-center">
                    <div className="col-12">
                      <FontAwesomeIcon icon="clipboard-list" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                      <h1>Id de Orden: {orden.id}</h1>
                    </div>
  
                    <div className="col-12 col-md-10 mx-auto" style={{'marginTop': '25px'}}>
                      <form onSubmit={this.props.onSubmitCancelar(orden)}>

                        <div className="form-row">
  
                          <div className="col-md-6 row mx-auto" style={{marginTop: '15px', marginBottom: '15px'}}>
                                <label htmlFor="razonDeCancelacion" className="col"><b>Razón de Cancelación:</b></label>
                                <textarea ref={this.props.setRef} data-orden={ordenString} data-value-type="razonDeCancelacion" className="col form-control-plaintext form-control-sm inputUserVal" id="razonDeCancelacion" style={{'top': '-3px'}} required/>
                          </div>
  
                        </div>

                        <div className="form-row">
                          <button type="button" className="btn btn-outline-success mx-auto" onClick={this.handleCancelar}>No Cancelar</button>

                          <button type="submit" className="btn btn-outline-danger mx-auto">Cancelar</button>
                        </div>

                      </form>
                    </div>
                  </div>

                  {
                    this.props.mensajeDeCambioDeEstadoYCantidadDeMuestras !== null ? (
                      <div className="row" style={{marginTop: '25px'}}>
                        {
                          (this.props.mensajeDeCambioDeEstadoYCantidadDeMuestras.response === 'success') ? (
                            <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.props.mensajeDeCambioDeEstadoYCantidadDeMuestras.mensaje}</div>
                          ) : (
                            <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.props.mensajeDeCambioDeEstadoYCantidadDeMuestras.mensaje}</div>
                          )
                        }
                      </div>
                    ) : null
                  }
                </div>
  
                <div className="modal-footer row" style={{height: 'auto'}}>
  
                    <div className="col-12 col-md" style={{marginTop: '15px'}}>
                      <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
                    </div>
  
  
                </div>
              </div>
            </div>
          </ModalB4>
        </ModalContainer>
      )

    }

    

  }

}

export default MensajeriaOrdenChangeEstadoYCantidadDeMuestras;
