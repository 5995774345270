import React, { Component } from 'react';

class ContactoForm extends Component {

    constructor(props) {
        super(props);
        // No llames this.setState() aquí!
        this.state = { 
            sendForm: null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleSubmit(event) {

		function handleError(err, This){
            console.log(`Request failed: ${err}`);
            This.setState({
                sendForm: 'no enviado'
            })
		}

		event.preventDefault();

		let nombre = document.querySelector('#nombreContacto').value,
			correo = document.querySelector('#correoContacto').value,
			tel = document.querySelector('#telContacto').value,
			mensaje = document.querySelector('#mensajeContacto').value,
			serializeinfo = `nombre=${nombre}&correo=${correo}&tel=${tel}&mensaje=${mensaje}`;

		const options = {
		method: 'POST',
		headers: {
			"Content-type": "application/x-www-form-urlencoded"
		},
		body: serializeinfo
		}

		//document.querySelector(".sending").style.display = 'block'

		//llamada de datos con Fetch
		//fetch('https://blconsultores.com.gt/mail/contacto', options)
        //fetch('https://dev-back-end.p-lao.com/mail/contacto', options)
        fetch('https://biolab.com.gt/mail/contacto', options)
		.then( res => {
			if(res.status === 200){
				this.setState({
                    sendForm: 'enviado' 
                })
			}else{
				this.setState({
                    sendForm: 'no enviado'
                })
			}
        })
        .then( () =>{
            setTimeout( () => { 
                this.setState({
                    sendForm: null
                })
            }, 3000);
        })
        .catch(err => handleError(err, this))
        .then( () =>{
            setTimeout( () => { 
                this.setState({
                    sendForm: null
                })
            }, 3000);
        });

    }

    render(){
        return(
            <form id="form-contacto-form" method="post" onSubmit={this.handleSubmit} className="col-12 col-lg-9 container offset-sm-0">
    			<input id="nombreContacto" type="text" placeholder="Nombre:" className="col-sm-3 col-12" required />
    			<input id="correoContacto" type="email" placeholder="Correo:" className="col-sm-4 offset-sm-1 col-12" required />
    			<input id="telContacto" type="tel" placeholder="Teléfono:" className="col-sm-3 offset-sm-1 col-12" required />
    			<textarea id="mensajeContacto" placeholder="Mensaje:" className="col-12" required></textarea>
    			<button type="submit" className="col-sm-3 offset-sm-9 col-12 col-offset-4">Enviar</button>

                {
                    this.state.sendForm === 'enviado' ? (
                        <h6 id="mensajeenviado" className="text-center alert alert-success col-12 col-md-8 offset-md-2" style={{marginTop: '15px'}}>Tu mensaje se ha enviado satisfactoriamente.</h6>
                    ) : null
                }

                {
                    this.state.sendForm === 'no enviado' ? (
                        <h6 id="errormensaje" className="text-center alert alert-danger col-12 col-md-8 offset-md-2" style={{marginTop: '15px'}}>Tu mensaje no se ha enviado, ha ocurrido un error, prueba enviarlo de nuevo.</h6>
                    ) : null
                }

    		</form>
        )
    }
}

export default ContactoForm