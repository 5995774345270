import React, { Component } from 'react';

import DashboardComponent from '../../dashboard/components/DashboardComponent.jsx';

import GraphQL from '../../../../apiService/FetchGraphQLUsuarios.js';

import GraphQLEmpresas from '../../../../apiService/FetchGraphQLEmpresas.js';

import deleteUser from './DeleteUserFetch/FetchDeleteUser.js';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

import UsersTable from '../components/UsersTable.jsx';

class AdminUserDashboard extends Component{

	constructor(props) {
		super(props);

		this.handleOpenModal = this.handleOpenModal.bind(this);
		this.handleDeleteUser = this.handleDeleteUser.bind(this);
  }

	handleOpenModal(modal, typeModal){
    this.props.dispatch({
			type: `OPEN_MODAL`,
			payload: {
				modal,
				typeModal
			}
		})

  }

	handleDeleteUser(user){

		return deleteUser(this, user)

	}

	componentDidMount() {

		let arrayOfAdminUserValues = [],
		allAdminUserValuesFalse = true
		
		if(this.props.usuario !== null){

			if(this.props.usuario.permisos !== null){
				arrayOfAdminUserValues = Object.values(this.props.usuario.permisos.AdminUser)
				allAdminUserValuesFalse = arrayOfAdminUserValues.every(v => v === false)
			}

			if(this.props.conexion !== false && this.props.usuario.permisos !== null && ((Object.entries(this.props.usuario.permisos.AdminUser).length > 0) === true) && (allAdminUserValuesFalse === false)){

				GraphQL(this);

				GraphQLEmpresas(this)

			}

		}

  	}

	setButton = element => {
		if(element.dataset.modalType === 'viewUser'){
			this.button = element.id;
			console.log(element.id);
		}else if(element.dataset.modalType === 'editUser'){
			this.button = element.id;
			//console.log(element);
		}
	}

	render(){

		let arrayOfAdminUserValues = [],
		allAdminUserValuesFalse = true
		
		if(this.props.usuario !== null){
			if(this.props.usuario.permisos !== null){
				arrayOfAdminUserValues = Object.values(this.props.usuario.permisos.AdminUser)
				allAdminUserValuesFalse = arrayOfAdminUserValues.every(v => v === false)
			}
		}

		if (this.props.conexion === false || this.props.usuario.permisos === null || ((Object.entries(this.props.usuario.permisos.AdminUser).length > 0) !== true) || (allAdminUserValuesFalse === true)) {
			return <Redirect to='/login' />
		} else {
			
			let usuarios

			if(this.props.dataUsers.search && this.props.dataUsers.search.length){
				usuarios = this.props.dataUsers.search
			} else if(this.props.dataUsers.emptyFilter === true) {
				usuarios = []
			}else {
				usuarios = this.props.dataUsers.usuarios
			}
			return(
				<DashboardComponent>
					<div className="container-fluid" style={{minHeight: '78vh'}}>
						<UsersTable setButtonViewUser={this.setButton} setButtonEditUser={this.setButton} usuarios={usuarios} usuario={this.props.usuario} handleOpenModal={this.handleOpenModal} handleDeleteUser={this.handleDeleteUser}/>
		      		</div>
				</DashboardComponent>
			)
		}
	}
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario,
	dataUsers: state.dataUsers
  }
}

export default connect(mapStateToProps)(AdminUserDashboard)
