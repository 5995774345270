import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let SucursalDataView = props => {
    
    let contacto_directo = props.usuarios.filter( usuario => usuario.id === props.sucursal.id_contacto_directo)[0]

    return(
        <div className="modal-body container-fluid">

            <div className="row">
                <div className="col-12">
                    <button style={{backgroundColor: 'transparent', border: 'none', color: '#2984be'}} onClick= { () => {
                        let type = "EmpresaDataView"
                        props.handleChangeDisplay(type)
                    } }><FontAwesomeIcon icon="arrow-left" style={{fontSize: '25px', marginTop: '25px', marginLeft: '25px'}} /></button>
                </div>
            </div>

            <div className="row text-center">
                <div className="col-12">
                    <FontAwesomeIcon icon="building" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                    <h1>Información Sucursal</h1>
                </div>
            </div>

            <div className="row text-center">

                <div className="col-md-4 row mx-auto">
                    <p className="col" id="nombreSucursal" style={{'marginTop': '0px'}}><b>Nombre Comercial:</b> {props.sucursal.nombre_comercial}</p>
                </div>

                <div className="col-md-4 row mx-auto">
                    <p className="col" id="telefonosSucursal" style={{'marginTop': '0px'}}><b>Teléfonos:</b> {props.sucursal.telefonos}</p>
                </div>

                <div className="col-md-4 row mx-auto">
                    <p className="col" id="correoSucursal" style={{'marginTop': '0px'}}><b>Correo:</b> {props.sucursal.correo}</p>
                </div>

                <div className="col-md-4 row mx-auto">
                    <p className="col" id="direccionSucursal" style={{'marginTop': '0px'}}><b>Dirección:</b> {props.sucursal.direccion}</p>
                </div>

                <div className="col-md-4 row mx-auto">
                    <p className="col" id="convenioSucursal" style={{'marginTop': '0px'}}><b>Convenio:</b> {props.sucursal.convenio}</p>
                </div>

                <div className="col-md-4 row mx-auto">
                    <p className="col" id="empresaSucursal" style={{'marginTop': '0px'}}><b>Empresa:</b> {props.empresa.nombre_comercial}</p>
                </div>

            </div>

            {
                props.sucursal.id_contacto_directo !== null ? (
                    <div className="row text-center">
                        <div className="col-12">
                            <h4>Contacto Directo</h4>
                        </div>

                        <div className="col-md-4 row mx-auto">
                            <p className="col" id="nombreContacto" style={{'marginTop': '0px'}}><b>Nombre:</b> {contacto_directo.nombre} {contacto_directo.apellidos}</p>
                        </div>

                        <div className="col-md-4 row mx-auto">
                            <p className="col" id="telefonoContacto" style={{'marginTop': '0px'}}><b>Teléfono:</b> {contacto_directo.telefono}</p>
                        </div>

                        <div className="col-md-4 row mx-auto">
                            <p className="col" id="correoContacto" style={{'marginTop': '0px'}}><b>Correo:</b> {contacto_directo.email}</p>
                        </div>

                        <div className="col-md-4 row mx-auto">
                            <p className="col" id="direccionContacto" style={{'marginTop': '0px'}}><b>Dirección:</b> {contacto_directo.direccion}</p>
                        </div>

                    </div>
                ) : null
            }

        </div>
    )
}

export default SucursalDataView