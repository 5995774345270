import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let MensajeroTable = props => {

    return(
          <tbody>
            {
              props.ordenesMensajero.map(solicitudMensajeria => {

                let fechaDeRecoleccion = new Date(parseInt(solicitudMensajeria.fechaDeRecoleccion))
    
                //Creando la fecha
                let dia
                (fechaDeRecoleccion.getDate()) < 10 ? dia = `0${fechaDeRecoleccion.getDate()}` : dia = `${fechaDeRecoleccion.getDate()}`
                let mes
                (fechaDeRecoleccion.getMonth() + 1) < 10 ? mes = `0${fechaDeRecoleccion.getMonth() + 1}` : mes = `${fechaDeRecoleccion.getMonth() + 1}`
                let year = fechaDeRecoleccion.getFullYear()
                //Fecha
                let fecha = `${year}-${mes}-${dia}`
                //Creando Hora
                let hora
                (fechaDeRecoleccion.getHours()) < 10 ? hora = `0${fechaDeRecoleccion.getHours()}` : hora = `${fechaDeRecoleccion.getHours()}`
                let minutos
                (fechaDeRecoleccion.getMinutes()) < 10 ? minutos = `0${fechaDeRecoleccion.getMinutes()}` : minutos = `${fechaDeRecoleccion.getMinutes()}`
                //Hora
                let time = `${hora}:${minutos}`

                let colorFondo;

                if(solicitudMensajeria.estado === 'Pendiente de Entrega'){
                  colorFondo = 'transparent'
                }else if(solicitudMensajeria.estado === 'Entregado a Mensajero'){
                  colorFondo = '#FFF4A3'
                }else if(solicitudMensajeria.estado === 'Entregado a Laboratorio'){
                  colorFondo = '#50D0FF'
                }else if(solicitudMensajeria.estado === 'Fuera de Condiciones'){
                  colorFondo = '#FFB376'
                }else if(solicitudMensajeria.estado === 'Finalizado'){
                  colorFondo = '#9BEBA9'
                }else if(solicitudMensajeria.estado === 'Cancelado'){
                  colorFondo = '#EB8484'
                }
    
                return (
                  <tr key={solicitudMensajeria.id} style={{backgroundColor: colorFondo}}>
                    {/*<th scope="row">{usuario.nombre}</th>*/}
                    <td className="d-none d-md-table-cell">{solicitudMensajeria.id}</td>
                    {
                      (solicitudMensajeria.idUsuario !== null) ? (
                        <td>
                          {
                            props.usuarios.map(usuario => {
                              return ((usuario.id === solicitudMensajeria.idUsuario) === true) ? `${usuario.nombre} ${usuario.apellidos}`: null
                            })
                          }
                        </td>
                      ) : null
                    }
                    {
                      (solicitudMensajeria.idEmpresa !== null) ? (
                        <td>
                          {
                            props.empresas.map(empresa => {
                              return ((empresa.id === solicitudMensajeria.idEmpresa) === true) ? `${empresa.nombre_comercial}`: null
                            })
                          }
                        </td>
                      ) : null
                    }
                    <td>{solicitudMensajeria.instrucciones}</td>
                    <td className="d-none d-sm-table-cell" >{fecha} a {time} horas</td>
                    <td className="d-sm-none d-xs-table-cell" >{time} horas</td>
                    <td className="d-none d-sm-table-cell" >{solicitudMensajeria.estado}</td>
                    <td>
    
                      <button type="button" onClick={() => {
                          let typeModal = 'ordenMensariaModalView';
                          props.handleOpenModal(solicitudMensajeria, typeModal)
                      }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#2984be'}}>
                        <FontAwesomeIcon icon="eye" />
                      </button> {/*ref={props.setButtonViewUser}*/}

                      <button type="button" onClick={() => {
                        
                          if(solicitudMensajeria.tipo === 'Administrativa'){
                            props.handleChangeEstadoSolicitud(solicitudMensajeria)
                          }else{

                            if((solicitudMensajeria.estado === 'Entregado a Mensajero') && (props.usuarioData.colaborador.puesto !== 'Mensajero')){
                              props.handleChangeEstadoSolicitud(solicitudMensajeria)
                            }else{
                              let typeModal = 'changeEstadoYCantidadDeMuestras';
                            props.handleOpenModal(solicitudMensajeria, typeModal)
                            }

                          }

                      }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#2984be'}}>
                        <FontAwesomeIcon icon="sync-alt" />
                      </button>
    
    
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
    )
}

export default MensajeroTable