import React, { Component } from 'react'
import QRCode from "react-qr-code";
import { formatterTwoDecimals } from '../../../../MyModules/formatterDecimals';

class PrintOrdenCompra extends Component {

    
    render(){

        return(
            <div id="printOrdenCompra" className="row d-none p-4">
                <div className="col-12">
                    <img className="img-fluid mb-4" src="/img/Inicio/logoAzul.svg" style={{maxWidth: '200px'}} alt="Logo Biolab"/>
                </div>
                <div className="col-12">
                    <h1 className="text-center">Orden Compra</h1>
                </div>
                <div className="table-responsive" style={{color: "#000000"}}>
                    <table className="table text-center table-bordered table-hover table-sm" style={{border: '1px solid black', borderCollapse: 'collapse'}}>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col" colSpan="6" style={{border: '1px solid black', textAlign: 'center', verticalAlign: 'middle', padding: '5px', color: '#000000'}}>
                                    <b>Proveedor: </b>
                                    {
                                        ((this.props.orden !== null) && (this.props.empresas.length > 0) && (this.props.proveedor !== undefined)) ? (
                                            this.props.proveedor.nombre_comercial
                                        ) : (
                                           this.props.orden !== null ? this.props.orden.proveedor : ""
                                        )
                                    }
                                </th>
                            </tr>
                            {this.props.orden.observaciones && (
                                <tr>
                                    <th scope="col" colSpan="6" style={{border: '1px solid black', textAlign: 'center', verticalAlign: 'middle',padding: '5px', color: '#000000'}}>
                                        <b>Observaciones:</b> {this.props.orden.observaciones}
                                    </th>
                                </tr>
                            )}
                            <tr>
                                <th scope="col" style={{border: '1px solid black', textAlign: 'center', verticalAlign: 'middle', padding: '5px', color: '#000000'}}>Nombre Producto</th>
                                <th scope="col" style={{border: '1px solid black', textAlign: 'center', verticalAlign: 'middle', padding: '5px', color: '#000000'}}>Descripción</th>
                                <th scope="col" style={{border: '1px solid black', textAlign: 'center', verticalAlign: 'middle', padding: '5px', color: '#000000'}}>Observaciones</th>
                                <th scope="col" style={{border: '1px solid black', textAlign: 'center', verticalAlign: 'middle', padding: '5px', color: '#000000'}}>Cantidad</th>
                                <th scope="col" style={{border: '1px solid black', textAlign: 'center', verticalAlign: 'middle', padding: '5px', color: '#000000'}}>Valor Unitario</th>
                                <th scope="col" style={{border: '1px solid black', textAlign: 'center', verticalAlign: 'middle', padding: '5px', color: '#000000'}}>Valor Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.orden.productos.map( (producto, i) => {
                                    let valorTotal = producto.cantidad * producto.valorUnitario
                                    return(
                                        <tr key={i}>
                                            <td style={{border: '1px solid black', textAlign: 'center', verticalAlign: 'middle', padding: '5px', color: '#000000'}}>{producto.nombre}</td>
                                            <td style={{border: '1px solid black', textAlign: 'center', verticalAlign: 'middle', padding: '5px', color: '#000000'}}>{producto.descripcion}</td>
                                            <td style={{border: '1px solid black', textAlign: 'center', verticalAlign: 'middle', padding: '5px', color: '#000000'}}>{producto.observaciones}</td>
                                            <td style={{border: '1px solid black', textAlign: 'center', verticalAlign: 'middle', padding: '5px', color: '#000000'}}>{producto.cantidad}</td>
                                            <td style={{border: '1px solid black', textAlign: 'center', verticalAlign: 'middle', padding: '5px', color: '#000000'}}>Q. {producto.valorUnitario}</td>
                                            <td style={{border: '1px solid black', textAlign: 'center', verticalAlign: 'middle', padding: '5px', color: '#000000'}}>Q. {formatterTwoDecimals.format(valorTotal)}</td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td colSpan="5" style={{border: '1px solid black', textAlign: 'center', verticalAlign: 'middle', padding: '5px', color: '#000000'}}><b>Valor de Orden Total</b></td>
                                <td colSpan="1" style={{border: '1px solid black', textAlign: 'center', verticalAlign: 'middle', padding: '5px', color: '#000000'}}><b>Q. {formatterTwoDecimals.format(this.props.valorDeOrdenTotal)}</b></td>
                            </tr>
                            <tr>
                                <td colSpan="3" style={{border: '1px solid black', textAlign: 'center', verticalAlign: 'middle', padding: '5px', color: '#000000'}}>
                                    <p style={{textAlign: 'center', color: '#000000'}}>
                                    <b>Fecha de Emisión de Orden de Compra:</b><br/>
                                        {this.props.fechaDeCreacionOrden.dia}/{this.props.fechaDeCreacionOrden.mes}/{this.props.fechaDeCreacionOrden.year}
                                    </p>
                                </td>
                                <td colSpan="3" style={{border: '1px solid black', textAlign: 'center', verticalAlign: 'middle', padding: '5px', color: '#000000'}}>
                                    <div>
                                        <div className="col-12 mb-3">
                                            <b>Id de Orden de Compra:</b> {this.props.orden.id}
                                        </div>
                                        <div className="col-12 mb-3">
                                            <QRCode value={this.props.orden.id} size={100} />                                    
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default PrintOrdenCompra