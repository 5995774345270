import React, { Component } from 'react';

import { Redirect } from 'react-router';

import DashboardComponent from '../../../dashboard/components/DashboardComponent.jsx';

import Loader from '../../../../Components/SimpleLoader';

//import { Redirect } from 'react-router';

import Select from 'react-select';

import { connect } from 'react-redux';

import FetchGraphQLRealizarProducto from './realizarProductoCompraFetch/FetchGraphQLRealizarProducto';

import GraphQLUsuarios from '../../../../../apiService/FetchGraphQLUsuarios';
import GraphQLEmpresas from '../../../../../apiService/FetchGraphQLEmpresas';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AgregarProductoCompra extends Component {

    constructor(props) {

        super(props);
        
        this.state = {
            mensajeInsert: null,
            loading: false,
            newProducto: {
                idCreador: "",
                idProveedor: "",
                nombre: "",
                valorUnitario: "0",
                NoCatalogo: "",
                descripcion: "",
                observaciones: "",
                tipo: "Insumo de Laboratorio"
            }
        }

        this.onInputProveedorChange = this.onInputProveedorChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    componentDidMount(){

        let arrayOfComprasValues = [],
        allComprasValuesFalse = true

		if(this.props.usuario !== null){
			if(this.props.usuario.permisos !== null){
				arrayOfComprasValues = Object.values(this.props.usuario.permisos.Compras)
				allComprasValuesFalse = arrayOfComprasValues.every(v => v === false)
			}
        }
        
        if( (this.props.conexion !== false) && (this.props.usuario.permisos !== null) && ((Object.entries(this.props.usuario.permisos.Compras).length > 0) === true) && (allComprasValuesFalse === false) && (this.props.usuario.permisos.Compras.AddProductoCompra === true) ){

            GraphQLUsuarios(this)
            GraphQLEmpresas(this)

            this.setState({
                ...this.state,
                newProducto: {
                    ...this.state.newProducto,
                    idCreador: this.props.usuario.id
                }
            })

        }

    }

    onInputChange(event){

        this.setState({
            ...this.state,
            newProducto: {
                ...this.state.newProducto,
                [event.target.id]: event.target.value
            }
        })

    }

    onInputProveedorChange(selectObj){

        this.setState({
            ...this.state,
            newProducto: {
                ...this.state.newProducto,
                idProveedor: selectObj.value
            }
        })

    }

    onSubmit(event){

        event.preventDefault()

        this.setState({
            loading: true
        })

        FetchGraphQLRealizarProducto(this, this.state.newProducto)

    }

    render(){

        let arrayOfComprasValues = [],
        allComprasValuesFalse = true

		if(this.props.usuario !== null){
			if(this.props.usuario.permisos !== null){
				arrayOfComprasValues = Object.values(this.props.usuario.permisos.Compras)
				allComprasValuesFalse = arrayOfComprasValues.every(v => v === false)
			}
        }

        if (this.props.conexion === false) {
			return <Redirect to='/login' />
		}else if(this.props.usuario.permisos === null){
			return(
				<h1>No te Han Asignado Permisos</h1>
			)
		}else if( (this.props.usuario.permisos === null) || ((Object.entries(this.props.usuario.permisos.Compras).length > 0) !== true) || (allComprasValuesFalse === true) || ((this.props.usuario.permisos.Compras.AddProductoCompra === false) || (this.props.usuario.permisos.Compras.AddProductoCompra === null)) ) {
            return <Redirect to='/dashboard' />
        }else if(this.props.usuario.permisos.Compras.AddProductoCompra === true){

            let proveedores = this.props.empresas.filter( proveedor => {
                return proveedor.clasificacion_empresa.includes('Proveedor')
            })

            let selectProveedores  = Array.from(proveedores, empresa => {
                return { value: empresa.id, label: `${empresa.nombre_comercial}` }
            })
    
            return(
                <DashboardComponent>
                    <div className="container">   
                        <form className="row text-center" onSubmit={this.onSubmit} >
    
                            <div className="col-12">
                                <h2 className="text-center">Agregar Producto de Compra</h2>
                            </div>
    
                            <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                <label htmlFor="proveedor" className="col"><b>Proveedor:</b></label>
                                    <Select id="proveedor" options={selectProveedores} onChange={this.onInputProveedorChange} placeholder="Escoge un Proveedor" /> 
                                <small id="proveedorSmall" className="form-text text-muted">Proveedor.</small>
                            </div>
    
                            <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                <label htmlFor="nombre" className="col"><b>Nombre de Producto o Servicio:</b></label><input onChange={this.onInputChange} type="text"  className="form-control" id="nombre" value={this.state.newProducto.nombre} placeholder="Escribe el Nombre de un Producto o Servicio" />
                                <small id="nombreSmall" className="form-text text-muted">Nombre de Producto o Servicio.</small>
                            </div>
    
                            <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                <label htmlFor="descripcion" className="col"><b>Descripción de Producto o Servicio:</b></label><input onChange={this.onInputChange} type="text"  className="form-control" id="descripcion" value={this.state.newProducto.descripcion} placeholder="Escribe la descripción del Producto o Servicio" />
                                <small id="descripcionSmall" className="form-text text-muted">Descripción de Producto o Servicio.</small>
                            </div>
    
                            <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                <label htmlFor="observaciones" className="col"><b>Observación de Producto o Servicio:</b></label><input onChange={this.onInputChange} type="text"  className="form-control" id="observaciones" value={this.state.newProducto.observaciones} placeholder="Escribe la observación del Producto o Servicio" />
                                <small id="descripcionSmall" className="form-text text-muted">Observación de Producto o Servicio.</small>
                            </div>
    
                            <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                <label htmlFor="tipo" className="col"><b>Tipo de Producto o Servicio:</b></label>
                                <select id="tipo" className="form-control" onChange={this.onInputChange} value={this.state.newProducto.tipo}>
                                    <option value="Insumo de Laboratorio">Insumo de Laboratorio</option>
                                    <option value="Producto General">Producto General</option>
                                    <option value="Servicio">Servicio</option>
                                </select>
                                <small id="tipoSmall" className="form-text text-muted">Descripción de Producto o Servicio.</small>
                            </div>
    
                            {
                                (this.state.newProducto.tipo === "Insumo de Laboratorio") ? (
                                    <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                        <label htmlFor="NoCatalogo" className="col"><b>No. de Catálogo:</b></label><input onChange={this.onInputChange} type="text"  className="form-control" id="NoCatalogo" value={this.state.newProducto.NoCatalogo} placeholder="Escribe el No. de Catálogo" />
                                        <small id="NoCatalogoSmall" className="form-text text-muted">No. de Lote</small>
                                    </div> 
                                ) : null
                            }
    
                            <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                <label htmlFor="valorUnitario" className="col"><b>Valor Unitario de Producto o Servicio:</b></label><input onChange={this.onInputChange} type="number" min="0"  className="form-control" id="valorUnitario" value={this.state.newProducto.valorUnitario} placeholder="Valor Unitario" step=".01" />
                                <small id="valorUnitarioSmall" className="form-text text-muted">Valor Unitario de Producto o Servicio</small>
                            </div>
    
                            <div className="form-group col-12 mx-auto">
                                <button type="submit" className="mx-auto btn btn-outline-info" >Agregar <FontAwesomeIcon icon="plus-circle" /></button>
                            </div>
    
                        </form>
                        {
                            this.state.loading === true ? (
                                <div className="row">
                                    <Loader />
                                </div>
                            ) : null
                        }
                        {
                            this.state.mensajeInsert !== null ? (
                            <div className="row">
                                {
                                (this.state.mensajeInsert.response === 'success') ? (
                                    <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.mensajeInsert.mensaje}</div>
                                ) : (
                                    <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.mensajeInsert.mensaje}</div>
                                )
                                }
                            </div>
                            ) : null
                        }
                    </div>
                </DashboardComponent>
            )
        }else{
            return null
        }

    }

}

let mapStateToProps = (state, props) => {
    let empresaBiolab = state.dataEmpresas.empresas.filter( empresa => empresa.nombre_comercial === 'Biolab')[0]
    return {
      conexion: state.logIn.conexion,
      usuario: state.logIn.usuario,
      usuarios: state.dataUsers.usuarios,
      empresas: state.dataEmpresas.empresas,
      sucursalesBiolab: state.dataEmpresas.sucursales.filter( sucursal => sucursal.id_empresa === empresaBiolab.id )
    }
  }
  
export default connect(mapStateToProps)(AgregarProductoCompra)