let filter = (This, data, type, select, value, clasificacion, isClasificacion) => {

  value = value.toLowerCase()

  if(type === 'user'){

      if(select === 'Nombre'){
  
        let usuariosNombre = data.filter( usuario => {
          return usuario.nombre.toLowerCase().includes(value)
        })

        if (usuariosNombre.length === 0) {
          This.props.dispatch({
            type: 'EMPTY_USER_FILTER',
            payload: {
              dataEmpty: true
            }
          })
        } else {
          This.props.dispatch({
            type: 'EMPTY_USER_FILTER',
            payload: {
              dataEmpty: false
            }
          })
        }
    
        This.props.dispatch({
          type: 'FILTER_USERS',
          payload: {
            dataUsers: usuariosNombre
          }
        })
  
      }else if(select === 'Apellidos'){

        let usuariosApellidos = data.filter( usuario => {
          return usuario.apellidos.toLowerCase().includes(value)
        })

        if (usuariosApellidos.length === 0) {
          This.props.dispatch({
            type: 'EMPTY_USER_FILTER',
            payload: {
              dataEmpty: true
            }
          })
        } else {
          This.props.dispatch({
            type: 'EMPTY_USER_FILTER',
            payload: {
              dataEmpty: false
            }
          })
        }
    
        This.props.dispatch({
          type: 'FILTER_USERS',
          payload: {
            dataUsers: usuariosApellidos
          }
        })
    
          
      }else if(select === 'Usuario'){

        let usuariosUsuario = data.filter( usuario => {
          return usuario.usuario.toLowerCase().includes(value)
        })

        if (usuariosUsuario.length === 0) {
          This.props.dispatch({
            type: 'EMPTY_USER_FILTER',
            payload: {
              dataEmpty: true
            }
          })
        } else {
          This.props.dispatch({
            type: 'EMPTY_USER_FILTER',
            payload: {
              dataEmpty: false
            }
          })
        }
    
        This.props.dispatch({
          type: 'FILTER_USERS',
          payload: {
            dataUsers: usuariosUsuario
          }
        })
    
          
      }else if(select === 'Correo'){

        let usuariosCorreo = data.filter( usuario => {
          return usuario.email.toLowerCase().includes(value)
        })

        if (usuariosCorreo.length === 0) {
          This.props.dispatch({
            type: 'EMPTY_USER_FILTER',
            payload: {
              dataEmpty: true
            }
          })
        } else {
          This.props.dispatch({
            type: 'EMPTY_USER_FILTER',
            payload: {
              dataEmpty: false
            }
          })
        }
    
        This.props.dispatch({
          type: 'FILTER_USERS',
          payload: {
            dataUsers: usuariosCorreo
          }
        })
    
          
      }else if(select === 'Tipo'){

        let usuariosTipo = data.filter( usuario => {
          return usuario.tipo.toLowerCase().includes(value)
        })

        if (usuariosTipo.length === 0) {
          This.props.dispatch({
            type: 'EMPTY_USER_FILTER',
            payload: {
              dataEmpty: true
            }
          })
        } else {
          This.props.dispatch({
            type: 'EMPTY_USER_FILTER',
            payload: {
              dataEmpty: false
            }
          })
        }
    
        This.props.dispatch({
          type: 'FILTER_USERS',
          payload: {
            dataUsers: usuariosTipo
          }
        })
    
          
      }
        
    }else if(type === 'company'){

      let empresasClasificacion = data

      empresasClasificacion = data.filter( empresa => {
        let clasificacion_empresa
        if(empresa.clasificacion_empresa !== null){
          let clasificacion_empresa_lowercase =  empresa.clasificacion_empresa.map( clasificacion => clasificacion.toLowerCase())
          clasificacion_empresa = clasificacion_empresa_lowercase.includes(clasificacion.toLowerCase())
        }
        return clasificacion_empresa
      })

      let dataFilter = empresasClasificacion.length > 0 ? empresasClasificacion : data

      if(select === 'Nombre Comercial'){

        let empresasNombreComercial = dataFilter.filter( empresa => {
          return empresa.nombre_comercial.toLowerCase().includes(value)
        })

        if (empresasNombreComercial.length === 0) {
          This.props.dispatch({
            type: 'EMPTY_EMPRESA_FILTER',
            payload: {
              dataEmpty: true
            }
          })
        } else {
          This.props.dispatch({
            type: 'EMPTY_EMPRESA_FILTER',
            payload: {
              dataEmpty: false
            }
          })
        }
    
        This.props.dispatch({
          type: 'FILTER_EMPRESAS',
          payload: {
            dataEmpresas: empresasNombreComercial
          }
        })
  
      }else if(select === 'Nombre Razon Social'){
  
        let empresasNombreRazonSocial = dataFilter.filter( empresa => {
          return empresa.nombre_razon_social.toLowerCase().includes(value)
        })

        if (empresasNombreRazonSocial.length === 0) {
          This.props.dispatch({
            type: 'EMPTY_EMPRESA_FILTER',
            payload: {
              dataEmpty: true
            }
          })
        } else {
          This.props.dispatch({
            type: 'EMPTY_EMPRESA_FILTER',
            payload: {
              dataEmpty: false
            }
          })
        }
    
        This.props.dispatch({
          type: 'FILTER_EMPRESAS',
          payload: {
            dataEmpresas: empresasNombreRazonSocial
          }
        })
  
      }else if(select === 'Correo'){
  
        let empresasCorreo = dataFilter.filter( empresa => {
          let correo
          if(empresa.correo !== null){
            correo = empresa.correo.toLowerCase().includes(value)
          }
          return correo
        })

        if (empresasCorreo.length === 0) {
          This.props.dispatch({
            type: 'EMPTY_EMPRESA_FILTER',
            payload: {
              dataEmpty: true
            }
          })
        } else {
          This.props.dispatch({
            type: 'EMPTY_EMPRESA_FILTER',
            payload: {
              dataEmpty: false
            }
          })
        }
    
        This.props.dispatch({
          type: 'FILTER_EMPRESAS',
          payload: {
            dataEmpresas: empresasCorreo
          }
        })
  
      }
      
      if(isClasificacion === true){
        This.props.dispatch({
          type: 'FILTER_EMPRESAS',
          payload: {
            dataEmpresas: dataFilter
          }
        }) 
      }


    }

    if(value === '' && clasificacion === ''){
      This.props.dispatch({
        type: 'CLEAN_FILTER_USERS'
      })
      This.props.dispatch({
        type: 'CLEAN_FILTER_EMPRESAS'
      })
    }

}

export default filter