import React, { Component } from 'react';

import { connect } from 'react-redux';

import Summernote from '../../../../Summernote/Containers/Summernote.jsx';

class ListaAnalisisMicrobiologicos extends Component {

  render(){
    let renderVal = this.props.dataUI,
        textListaAnalisisMicrobiologicos1Obj = renderVal.textos.find( text => {
          return text.id === 'tex011'
        }),
        textListaAnalisisMicrobiologicos2Obj = renderVal.textos.find( text => {
          return text.id === 'tex012'
        }),
        texto1 = textListaAnalisisMicrobiologicos1Obj.texto,
        texto2 = textListaAnalisisMicrobiologicos2Obj.texto

    return(
      <section id="seccion-lista-analisisMicrobiologicos">
    		<div className="container">
    			<h4 className="col-12">ANÁLISIS MICROBIOLÓGICOS</h4>
    		</div>

    		<div id="text-lista-analisis-microbiologicos-1"  className="container" />
        <Summernote itemID='#text-lista-analisis-microbiologicos-1' textObj={textListaAnalisisMicrobiologicos1Obj} texto={texto1} url='http://localhost:8080/updateText' />

    		<br />
    		<div className="container">
    			<h4 className="col-12">Recuento / detección de microorganismos:</h4>
    		</div>

    		<div id="text-lista-analisis-microbiologicos-2" className="container" />
        <Summernote itemID='#text-lista-analisis-microbiologicos-2' textObj={textListaAnalisisMicrobiologicos2Obj} texto={texto2} url='http://localhost:8080/updateText' />

    		<div className="container contenedor-acreditacion">
          <div className="row">
            <div className="col-md-5 col-12">
              <div className="row">
                <span className="icon-approve-invoice col-sm-3 col-12"></span>
        				<h4 className="col-sm-4 col-12 sin-margen-y-padding">ANÁLISIS ACREDITADO:</h4>
              </div>
      			</div>
      			<div className="col-md-5 col-12 text-center">
      				<h5 className="col-lg-9 col-12 sin-margen-y-padding"><strong>ACREDITADO ISO/IEC 17025:2005  OGA–LE–044–11</strong></h5>
      			</div>
      			<div className="col-md-2 col-12 text-center" style={{"paddingTop": "5px", "paddingBottom": "5px"}}>
      				<div className="col-12 sin-margen-y-padding" style={{"paddingTop": "0px", "paddingBottom": "0px"}}>
      					<img src="/img/Inicio/OGA Final.png" alt="Imagen Logo OGA" className="img-fluid rounded-circle" style={{"marginRight": "auto", "marginLeft": "auto"}} />
      				</div>
      			</div>
          </div>
    		</div>
    	</section>
    )
  }

}

let mapStateToProps = (state, props) => {
  return {
    dataUI: state.dataUI
  }
}

export default connect(mapStateToProps)(ListaAnalisisMicrobiologicos);
