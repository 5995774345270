import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import GraphQLUsuarios from '../../../../../../../apiService/FetchGraphQLUsuarios.js';

import GraphQLEmpresas from '../../../../../../../apiService/FetchGraphQLEmpresas.js';

import OrdenEmpresa from '../../../../components/RealizarOrdenMuestras/OrdenEmpresa.jsx';

import OrdenUsuario from '../../../../components/RealizarOrdenMuestras/OrdenUsuario.jsx';

import RealizarPlantillaRutaTable from '../components/RealizarPlantillaRutaTable.jsx';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

import Toggle from 'react-toggle';
import "react-toggle/style.css" // for ES6 modules

import DashboardComponent from '../../../../../dashboard/components/DashboardComponent.jsx';

import ID from '../../../../../../../Service/ID.js';

import GraphQLInsertPlantillaRuta from './FetchInsertPlantillaRuta/fetchGraphQL.js';

class RealizarRutas extends Component {

    constructor(props) {
		super(props);
		
		this.state = {
			nombrePlantilla: "",
            ordenes: [],
            showForm: false,
            tipoOrden: "Administrativa",
            newOrden: {
                selectedOptionEmpresas: null,
                selectedOptionUsuarios: null,
                selectedOptionMensajeros: null,
                DatosPredefinidos: true,
                urgente: false,
                fechaInputDate: {
                    date: new Date(),
                    //dateValue: "",
                    timeValue: "",
                    minDateValue: "",
                    minTimeValue: "00:00"
                }
            },
            mensajeInsertPlantillaRuta: null
		}

        this.handleInputNombrePlantillaChange = this.handleInputNombrePlantillaChange.bind(this);
        this.handleChangeEmpresa = this.handleChangeEmpresa.bind(this);
        this.handleChangeUsuario = this.handleChangeUsuario.bind(this);
        this.handleAddOrden = this.handleAddOrden.bind(this);
        this.handleRemoveOrden = this.handleRemoveOrden.bind(this);
        this.handleDisplayInputNewOrder = this.handleDisplayInputNewOrder.bind(this);
        this.handleTipoOrdenChange = this.handleTipoOrdenChange.bind(this);
        this.handleUrgenteChange = this.handleUrgenteChange.bind(this);
        this.handleDatosPredefinidos = this.handleDatosPredefinidos.bind(this);
        this.handleChangeEmpresaDatosPredefinidos = this.handleChangeEmpresaDatosPredefinidos.bind(this);
        this.handleChangeUsuarioDatosPredefinidos = this.handleChangeUsuarioDatosPredefinidos.bind(this);
        this.handleChangefechaInputDate = this.handleChangefechaInputDate.bind(this);
        this.handleChangefechaInputTime = this.handleChangefechaInputTime.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){

        //Creando la fecha minima
        let date = this.state.newOrden.fechaInputDate.date
        let dia
        (date.getDate()) < 10 ? dia = `0${date.getDate()}` : dia = `${date.getDate()}`
        let mes
        (date.getMonth() + 1) < 10 ? mes = `0${date.getMonth() + 1}` : mes = `${date.getMonth() + 1}`
        let year = date.getFullYear()
        //Fecha minima
        let minDate = `${year}-${mes}-${dia}`

        this.setState({
            newOrden:{
                ...this.state.newOrden,
                fechaInputDate: {
                    ...this.state.newOrden.fechaInputDate,
                    minDateValue: minDate
                } 
            }
        })

        //redirect
        let arrayOfMensajeriaValues = [],
            allMensajeriaValuesFalse = true

        if(this.props.usuario !== null){
            if(this.props.usuario.permisos !== null){
                arrayOfMensajeriaValues = Object.values(this.props.usuario.permisos.Mensajeria)
                allMensajeriaValuesFalse = arrayOfMensajeriaValues.every(v => v === false)
            }
        }

        if( (this.props.conexion !== false) && (this.props.usuario.permisos !== null) && ((Object.entries(this.props.usuario.permisos.Mensajeria).length > 0) === true) && (allMensajeriaValuesFalse === false) && (this.props.usuario.permisos.Mensajeria.RealizarPlantillaRuta === true) ){
            GraphQLUsuarios(this);
            GraphQLEmpresas(this);  
        }

    }

    handleChangeEmpresa = selectedOptionEmpresas => {

        this.setState({
            newOrden:{
                ...this.state.newOrden,
                selectedOptionEmpresas,
                selectedOptionUsuarios: null
            }
        })
    
        //console.log(`Option selected:`, selectedOption);
    
    }
    
    handleChangeUsuario = selectedOptionUsuarios => {

        this.setState({
            newOrden:{
                ...this.state.newOrden,
                selectedOptionUsuarios,
                selectedOptionEmpresas: null
            }
        })
    
        //console.log(`Option selected:`, selectedOption);
    
    }

    handleAddOrden(){

        /*let diaDeRecoleccion = this.state.newOrden.fechaInputDate.dateValue.replace(/-/g, ','),
        diaDeRecoleccionArray = diaDeRecoleccion.split(","),
        diaDeRecoleccionArrayNumbers = diaDeRecoleccionArray.map(function(item) {
            return parseInt(item, 10);
        }),
        mes = diaDeRecoleccionArrayNumbers[1] - 1,
        fechaDeRecoleccionArray = [diaDeRecoleccionArrayNumbers[0], mes, diaDeRecoleccionArrayNumbers[2]],
        horaDeRecoleccion = this.state.newOrden.fechaInputDate.timeValue.replace(/:/g, ','),
        horaDeRecoleccionArray = horaDeRecoleccion.split(","),
        horaDeRecoleccionArrayNumbers = horaDeRecoleccionArray.map(function(item) {
            return parseInt(item, 10);
        })
        fechaDeRecoleccionArray.push(horaDeRecoleccionArrayNumbers[0], horaDeRecoleccionArrayNumbers[1])
        
        let fechaDeRecoleccion = new Date(...fechaDeRecoleccionArray)*/

        let orden

        if (this.state.newOrden.selectedOptionEmpresas !== null) {
      
            if (this.state.newOrden.DatosPredefinidos === true) {
                if(this.state.tipoOrden === "RecoleccionMuestra"){
                    orden = {
                        idProvisional: ID(),
                        idEmpresa: this.state.newOrden.selectedOptionEmpresas.value.id,
                        idUsuario: null,
                        //cantidadDeMuestras: document.querySelector('#cantidadDeMuestras').value,
                        instrucciones: document.querySelector('#instruccionesDeOrden').value,
                        personaQueLlamo: document.querySelector('#PersonaQueLlamo').value,
                        telefono: this.state.newOrden.selectedOptionEmpresas.value.telefono,
                        direccionDeRecepcion: this.state.newOrden.selectedOptionEmpresas.value.direccion_comercial,
                        horaDeOrden: this.state.newOrden.fechaInputDate.timeValue,
                        tipo: this.state.tipoOrden,
                        urgente: this.state.newOrden.urgente
                    }
                }else{
                    orden = {
                      idProvisional: ID(),
                      idEmpresa: this.state.newOrden.selectedOptionEmpresas.value.id,
                      idUsuario: null,
                      instrucciones: document.querySelector('#instruccionesDeOrden').value,
                      personaQueLlamo: document.querySelector('#PersonaQueLlamo').value,
                      telefono: this.state.newOrden.selectedOptionEmpresas.value.telefono,
                      direccionDeRecepcion: this.state.newOrden.selectedOptionEmpresas.value.direccion_comercial,
                      horaDeOrden: this.state.newOrden.fechaInputDate.timeValue,
                      tipo: this.state.tipoOrden,
                      urgente: this.state.newOrden.urgente
                    }
                }
            } else {
            
                if(this.state.tipoOrden === "RecoleccionMuestra"){
                    orden = {
                        idProvisional: ID(),
                        idEmpresa: this.state.newOrden.selectedOptionEmpresas.value.id,
                        idUsuario: null,
                        //cantidadDeMuestras: document.querySelector('#cantidadDeMuestras').value,
                        instrucciones: document.querySelector('#instruccionesDeOrden').value,
                        personaQueLlamo: document.querySelector('#PersonaQueLlamo').value,
                        telefono: document.querySelector('#telefono').value,
                        direccionDeRecepcion: document.querySelector('#direccionDeRecepcion').value,
                        horaDeOrden: this.state.newOrden.fechaInputDate.timeValue,
                        tipo: this.state.tipoOrden,
                        urgente: this.state.newOrden.urgente
                      }
                }else{
                    orden = {
                      idProvisional: ID(),
                      idEmpresa: this.state.newOrden.selectedOptionEmpresas.value.id,
                      idUsuario: null,
                      instrucciones: document.querySelector('#instruccionesDeOrden').value,
                      personaQueLlamo: document.querySelector('#PersonaQueLlamo').value,
                      telefono: document.querySelector('#telefono').value,
                      direccionDeRecepcion: document.querySelector('#direccionDeRecepcion').value,
                      horaDeOrden: this.state.newOrden.fechaInputDate.timeValue,
                      tipo: this.state.tipoOrden,
                      urgente: this.state.newOrden.urgente
                    }
                }
            
            }
      
            
        } else if (this.state.newOrden.selectedOptionUsuarios !== null) {
            
            if (this.state.newOrden.DatosPredefinidos === true) {
                if(this.state.tipoOrden === "RecoleccionMuestra"){
                    orden = {
                        idProvisional: ID(),
                        idEmpresa: null,
                        idUsuario: this.state.newOrden.selectedOptionUsuarios.value.id,
                        //cantidadDeMuestras: document.querySelector('#cantidadDeMuestras').value,
                        instrucciones: document.querySelector('#instruccionesDeOrden').value,
                        personaQueLlamo: document.querySelector('#PersonaQueLlamo').value,
                        telefono: this.state.newOrden.selectedOptionUsuarios.value.telefono.toString(),
                        direccionDeRecepcion: this.state.newOrden.selectedOptionUsuarios.value.direccion,
                        horaDeOrden: this.state.newOrden.fechaInputDate.timeValue,
                        tipo: this.state.tipoOrden,
                        urgente: this.state.newOrden.urgente
                    } 
                }else{
                    orden = {
                      idProvisional: ID(),
                      idEmpresa: null,
                      idUsuario: this.state.newOrden.selectedOptionUsuarios.value.id,
                      instrucciones: document.querySelector('#instruccionesDeOrden').value,
                      personaQueLlamo: document.querySelector('#PersonaQueLlamo').value,
                      telefono: this.state.newOrden.selectedOptionUsuarios.value.telefono.toString(),
                      direccionDeRecepcion: this.state.newOrden.selectedOptionUsuarios.value.direccion,
                      horaDeOrden: this.state.newOrden.fechaInputDate.timeValue,
                      tipo: this.state.tipoOrden,
                      urgente: this.state.newOrden.urgente
                    }
                }

            } else {
                if(this.state.tipoOrden === "RecoleccionMuestra"){
                    orden = {
                        idProvisional: ID(),
                        idEmpresa: null,
                        idUsuario: this.state.newOrden.selectedOptionUsuarios.value.id,
                        //cantidadDeMuestras: document.querySelector('#cantidadDeMuestras').value,
                        instrucciones: document.querySelector('#instruccionesDeOrden').value,
                        personaQueLlamo: document.querySelector('#PersonaQueLlamo').value,
                        telefono: document.querySelector('#telefono').value,
                        direccionDeRecepcion: document.querySelector('#direccionDeRecepcion').value,
                        horaDeOrden: this.state.newOrden.fechaInputDate.timeValue,
                        tipo: this.state.tipoOrden,
                        urgente: this.state.newOrden.urgente
                      }  
                }else{
                    orden = {
                      idProvisional: ID(),
                      idEmpresa: null,
                      idUsuario: this.state.newOrden.selectedOptionUsuarios.value.id,
                      instrucciones: document.querySelector('#instruccionesDeOrden').value,
                      personaQueLlamo: document.querySelector('#PersonaQueLlamo').value,
                      telefono: document.querySelector('#telefono').value,
                      direccionDeRecepcion: document.querySelector('#direccionDeRecepcion').value,
                      horaDeOrden: this.state.newOrden.fechaInputDate.timeValue,
                      tipo: this.state.tipoOrden,
                      urgente: this.state.newOrden.urgente
                    }
                  }
                }
      
        }

        this.state.ordenes.push(orden)

        this.setState({
            ordenes: this.state.ordenes,
            showForm: false,
            tipoOrden: "Administrativa",
            newOrden: {
                selectedOptionEmpresas: null,
                selectedOptionUsuarios: null,
                selectedOptionMensajeros: null,
                DatosPredefinidos: true,
                urgente: false,
                fechaInputDate: {
                    ...this.state.newOrden.fechaInputDate,
                    date: new Date(),
                    //dateValue: "",
                    timeValue: ""
                }
            }
        })

    }

    handleRemoveOrden(orden){
        let newOrdenes = this.state.ordenes.filter( order => order.idProvisional !== orden.idProvisional )
        this.setState({
            ordenes: newOrdenes
        })
    }

    handleDisplayInputNewOrder(){
        if(this.state.showForm === true){
            this.setState({
                showForm: false,
                tipoOrden: "Administrativa",
                newOrden: {
                    selectedOptionEmpresas: null,
                    selectedOptionUsuarios: null,
                    selectedOptionMensajeros: null,
                    DatosPredefinidos: true,
                    urgente: false,
                    fechaInputDate: {
                        ...this.state.newOrden.fechaInputDate,
                        date: new Date(),
                        //dateValue: "",
                        timeValue: ""
                    }
                }
            })  
        }else{
            this.setState({
                showForm: true
            })
        }
    }

    handleTipoOrdenChange(event){
        this.setState({
            tipoOrden: event.target.value
        })
    }

    handleUrgenteChange(){
        if(this.state.newOrden.urgente === false){
          this.setState({
              newOrden:{
                  ...this.state.newOrden,
                  urgente: true
              }
          })
        }else{
          this.setState({
              newOrden:{
                  ...this.state.newOrden,
                  urgente: false
              }
          })
        }
    }

    handleDatosPredefinidos(){
        if(this.state.newOrden.DatosPredefinidos === true){
          this.setState({
            newOrden:{
                ...this.state.newOrden,
                DatosPredefinidos: false
            }
          })
        }else{
            this.setState({
                newOrden:{
                    ...this.state.newOrden,
                    DatosPredefinidos: true
                }
            })
        }
    }

    handleChangeEmpresaDatosPredefinidos() {

        let dirYTelPre = document.getElementById("dirYTelPre");

        this.setState({
            newOrden:{
                ...this.state.newOrden,
                DatosPredefinidos: true
            }
        })
    
        dirYTelPre.checked = true;
    
        dirYTelPre.parentNode.classList.add('react-toggle--checked');
    
    }
    
    handleChangeUsuarioDatosPredefinidos() {
    
        let dirYTelPre = document.getElementById("dirYTelPre");
    
        this.setState({
            newOrden:{
                ...this.state.newOrden,
                DatosPredefinidos: false
            }
        })
    
        dirYTelPre.checked = false;
    
        dirYTelPre.parentNode.classList.remove('react-toggle--checked');
    
    }

    handleChangefechaInputDate = (event) => {

        //Creando la fecha minima
        let date = this.state.newOrden.fechaInputDate.date
        let dia
        (date.getDate()) < 10 ? dia = `0${date.getDate()}` : dia = `${date.getDate()}`
        let mes
        (date.getMonth() + 1) < 10 ? mes = `0${date.getMonth() + 1}` : mes = `${date.getMonth() + 1}`
        let year = date.getFullYear()
        //Fecha minima
        let minDate = `${year}-${mes}-${dia}`
        //Creando Hora minima
        let hora
        (date.getHours()) < 10 ? hora = `0${date.getHours()}` : hora = `${date.getHours()}`
        let minutos
        (date.getMinutes()) < 10 ? minutos = `0${date.getMinutes()}` : minutos = `${date.getMinutes()}`
        //Hora minima
        let minTime
        //((this.state.fechaInputDate === minDate) === true) ? minTime = `${hora}:${minutos}` : minTime = "00:00"
        ((event.target.value === minDate) === true) ? minTime = `${hora}:${minutos}` : minTime = "00:00"

        this.setState({
            newOrden:{
                ...this.state.newOrden,
                fechaInputDate: {
                    ...this.state.newOrden.fechaInputDate,
                    dateValue: event.target.value,
                    minTimeValue: minTime
                } 
            }
        })
    
    }

    handleChangefechaInputTime = (event) => {

      this.setState({
          newOrden:{
              ...this.state.newOrden,
              fechaInputDate: {
                  ...this.state.newOrden.fechaInputDate,
                  timeValue: event.target.value
              } 
          }
      })

    }

    handleSubmit(event){
            
        event.preventDefault()

        let ordenes = this.state.ordenes.filter( orden =>{
            return delete orden.idProvisional
        }),
            plantilla = {
                nombrePlantilla: this.state.nombrePlantilla,
                ordenes
            }

        //console.log(plantilla)

        GraphQLInsertPlantillaRuta(this, plantilla)

    }

    handleInputNombrePlantillaChange(event){
        this.setState({
            nombrePlantilla: event.target.value
        })
    }

    render(){

        let SelectEmpresa = Array.from(this.props.dataEmpresas.empresas, empresa => {
            return { value: empresa, label: empresa.nombre_comercial } //value: empresa.nombre_comercial
          })
      
          //let { selectedOptionUsuarios } = this.state;
      
        let SelectUsuarios = Array.from(this.props.dataUsers.usuarios, usuario => {
          return { value: usuario, label: `${usuario.nombre} ${usuario.apellidos}` } //value: empresa.nombre_comercial
        })

		let SelectMensajeroArray = this.props.dataUsers.usuarios.filter( usuario => {
            return usuario.colaborador.puesto === 'Mensajero'
          })
      
        let SelectMensajero = Array.from(SelectMensajeroArray, usuario => {
            return { value: usuario, label: `${usuario.nombre} ${usuario.apellidos}` } //value: empresa.nombre_comercial
        })

        SelectMensajero.unshift({value: null, label: 'Ninguno'})

        /*let ordenesAddTableIds = this.state.ordenesRuta,
            ordenesAddTable = this.props.solicitudesMensajeria.filter(function(orden) {
                return ordenesAddTableIds.indexOf(orden.id) !== -1;
            }),
            ordenesRemoveTable = this.props.solicitudesMensajeria.filter( orden => {
                return ordenesAddTableIds.indexOf(orden.id) === -1;
            })*/

            /*ordenesRemoveTable = array1.filter(function(e) {
                return array2.indexOf(e) > -1;
            });*/
        
        //console.log(this.state)

        //redirect
        let arrayOfMensajeriaValues = [],
            allMensajeriaValuesFalse = true

        if(this.props.usuario !== null){
            if(this.props.usuario.permisos !== null){
                arrayOfMensajeriaValues = Object.values(this.props.usuario.permisos.Mensajeria)
                allMensajeriaValuesFalse = arrayOfMensajeriaValues.every(v => v === false)
            }
        }

        if (this.props.conexion === false) {
			return <Redirect to='/login' />
		}else if((this.props.usuario.permisos === null) || ((Object.entries(this.props.usuario.permisos.Mensajeria).length > 0) !== true) || (allMensajeriaValuesFalse === true) || (this.props.usuario.permisos.Mensajeria.RealizarPlantillaRuta === false)){
            return <Redirect to='/dashboard' />
        }else{
            return(
                <DashboardComponent>
                    <div className="container-fluid">
                        <form onSubmit={this.handleSubmit} style={{marginBottom: '50px'}}>
    
                            <div className="form-row">
                                <h1 className="text-center col-12">Crear Plantilla</h1>
                                <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                    <label htmlFor="nombreRuta">Nombre de la Plantilla</label>
                                    <input onChange={this.handleInputNombrePlantillaChange} value={this.state.nombrePlantilla} type="text" className="form-control" id="nombreRuta" aria-describedby="nombreRutaHelp" placeholder="Ingresa el nombre de la Plantilla" />
                                    <small id="nombreRutaHelp" className="form-text text-muted">El Nombre de la Plantilla.</small>
                                </div>
                            </div>
                            
                            {
                                this.state.ordenes.length === 0 ? null : <RealizarPlantillaRutaTable solicitudesMensajeria={this.state.ordenes} empresas={this.props.dataEmpresas.empresas} usuarios={this.props.dataUsers.usuarios} handleRemoveOrden={this.handleRemoveOrden} />
                            }


                            {
                                this.state.showForm === true ? (
                                    <div className="mx-auto col-12 col-md-7" style={{marginTop: '15px'}}>
                                        <div className="row">
                                            <p className="col-lg-6 col-12 text-center"><b>¿Qué tipo de órden es?</b></p>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" className="custom-control-input" id="customRadio" name="tipoOrden" value="Administrativa" checked={this.state.tipoOrden === "Administrativa"} onChange={this.handleTipoOrdenChange} />
                                                <label className="custom-control-label" htmlFor="customRadio">Administrativa</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" className="custom-control-input" id="customRadio2" name="tipoOrden" value="RecoleccionMuestra" checked={this.state.tipoOrden === "RecoleccionMuestra"} onChange={this.handleTipoOrdenChange} />
                                                <label className="custom-control-label" htmlFor="customRadio2">Recolección de Muestra</label>
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            }

                            {
                                this.state.showForm === true ? (
                                    <div className="form-row">

                                        <nav className="col-12 col-md-2 flex-md-column" style={{marginTop: '30px'}}>
                                            <div className="nav nav-pills nav-fill" id="nav-tab" role="tablist">
                                            <a className="nav-item nav-link active" id="nav-empresa-tab" data-toggle="tab" href="#nav-ord-empresa" role="tab" aria-controls="nav-ord-empresa" aria-selected="true" onClick={this.handleChangeEmpresaDatosPredefinidos}><FontAwesomeIcon icon="building" /> Orden Empresa</a>
                                            <a className="nav-item nav-link" id="nav-usuario-tab" data-toggle="tab" href="#nav-ord-usuario" role="tab" aria-controls="nav-ord-usuario" aria-selected="false" onClick={this.handleChangeUsuarioDatosPredefinidos}><FontAwesomeIcon icon="user" /> Orden Usuario</a>
                                            </div>
                                        </nav>

                                        <div className="col-12 col-md-10" style={{marginTop: '30px'}}>

                                            <div className="row" style={{marginBottom: '30px'}}>

                                            <div className="tab-content col-sm-6 col-12 " id="nav-tabContent">

                                                <OrdenEmpresa active="active" selectedOptionEmpresas={this.state.newOrden.selectedOptionEmpresas} SelectEmpresa={SelectEmpresa} handleChangeEmpresa={this.handleChangeEmpresa} />

                                                <OrdenUsuario selectedOptionUsuarios={this.state.newOrden.selectedOptionUsuarios} SelectUsuarios={SelectUsuarios} handleChangeUsuario={this.handleChangeUsuario} />

                                            </div>

                                            {/*
                                                this.state.tipoOrden === "RecoleccionMuestra" ? (
                                                    <div className="col-sm-6 col-12">
                                                        <div className="row">
                                                        <label htmlFor="cantidadDeMuestras" className="col-lg-6 col-12"><b>Selecciona la cantidad de Muestras</b></label>
                                                        <input id="cantidadDeMuestras" type="number" min="1" max="30" className="mx-auto"/>
                                                        </div>
                                                    </div>
                                                ) : null
                                            */}

                                            <div className="col-sm-6 col-12">
                                                <div className="row">
                                                <label htmlFor="instruccionesDeOrden" className="col-lg-6 col-12"><b>Instrucciones de Orden:</b></label>
                                                <textarea id="instruccionesDeOrden" type="text" className="mx-auto" placeholder="Instrucciones de Orden"/>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-12">
                                                <div className="row">
                                                <label htmlFor="PersonaQueLlamo" className="col-lg-6 col-12"><b>Nombre de la Persona que llamó</b></label>
                                                <input id="PersonaQueLlamo" type="text" className="mx-auto" placeholder="Nombre de Quién llamó"/>
                                                </div>
                                            </div>

                                            <div className="mx-auto col-12 col-md-7" style={{marginTop: '15px'}}>
                                                <div className="row">
                                                <p className="col-lg-6 col-12 text-center"><b>Usar la dirección y teléfono predefinidos</b></p>
                                                <label className="mx-auto">
                                                    <Toggle
                                                    id="dirYTelPre"
                                                    defaultChecked={this.state.newOrden.DatosPredefinidos}
                                                    onChange={this.handleDatosPredefinidos} />
                                                </label>
                                                </div>
                                            </div>

                                            {
                                                (this.state.newOrden.DatosPredefinidos === true) ? null : (
                                                <div className="col-sm-6 col-12">
                                                    <div className="row">
                                                    <label htmlFor="telefono" className="col-lg-6 col-12"><b>Número de Teléfono la Persona que llamó</b></label>
                                                    <input id="telefono" type="text" className="mx-auto" placeholder="Teléfono de Quién llamó"/>
                                                    </div>
                                                </div>
                                                )
                                            }

                                            {
                                                (this.state.newOrden.DatosPredefinidos === true) ? null : (
                                                <div className="col-sm-6 col-12">
                                                    <div className="row">
                                                    <label htmlFor="direccionDeRecepcion" className="col-lg-6 col-12"><b>Dirección de Recolección de Muestra</b></label>
                                                    <input id="direccionDeRecepcion" type="text" className="mx-auto" placeholder="Dirección de Recolección de Muestra"/>
                                                    </div>
                                                </div>
                                                )
                                            }

                                            {/*<div className="col-sm-6 col-12">
                                                <div className="row">
                                                <label htmlFor="diaDeRecoleccion" className="col-lg-6 col-12"><b>Día de Recolección</b></label>
                                                <input id="diaDeRecoleccion" value={this.state.newOrden.fechaInputDate.dateValue} onChange={this.handleChangefechaInputDate} type="date" min={this.state.newOrden.fechaInputDate.minDateValue} className="mx-auto" placeholder="Día de Recolección"/>
                                                </div>
                                            </div>*/}

                                            <div className="col-sm-6 col-12">
                                                <div className="row">
                                                <label htmlFor="horaDeRecoleccion" className="col-lg-6 col-12"><b>Hora de Recolección</b></label>
                                                <input id="horaDeRecoleccion" type="time" value={this.state.newOrden.fechaInputDate.timeValue} onChange={this.handleChangefechaInputTime} min={this.state.newOrden.fechaInputDate.minTimeValue} className="mx-auto" placeholder="Hora de Recolección"/>
                                                </div>
                                            </div>

                                            <div className="mx-auto col-12 col-md-7" style={{marginTop: '15px'}}>
                                                <div className="row">
                                                <p className="col-lg-6 col-12 text-center"><b>¿Es Urgente?</b></p>
                                                <label className="mx-auto">
                                                    <Toggle
                                                    defaultChecked={this.state.newOrden.urgente}
                                                    onChange={this.handleUrgenteChange} />
                                                </label>
                                                </div>
                                            </div>

                                            {/*<div className="col-sm-6 col-12" style={{marginTop: '25px', marginBottom: '25px'}}>
                                                <div className="row">
                                                <label htmlFor="react-select-4-input" className="col-lg-6 col-12"><b>Selecciona un Mensajero</b></label>
                                                <Select
                                                    className="col-lg-6 col-12"
                                                    placeholder="Escoje un Mensajero"
                                                    value={this.state.selectedOptionMensajeros}
                                                    onChange={this.handleChangeMensajero}
                                                    options={SelectMensajero}
                                                />
                                                </div>
                                            </div>*/}

                                                <div className="col-md-6 col-12" style={{marginTop: '15px'}}>
                                                    <div className="row">
                                                    <button type="button" className="btn btn-outline-info col-lg-6 col-10 mx-auto" onClick={this.handleAddOrden}>Agregar Orden</button>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-12" style={{marginTop: '15px'}}>
                                                    <div className="row">
                                                    <button type="button" className="btn btn-outline-danger col-lg-6 col-10 mx-auto" onClick={this.handleDisplayInputNewOrder}>Cancelar Agregar Orden</button>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                ) : null
                            }

                            {
                                this.state.showForm === false ? (
                                    <div className="form-row">
                                        <div className="mx-auto">
                                            <button type="button" onClick={() => {
                                            //props.handleAddOrdenesRuta(solicitudMensajeria)
                                            this.handleDisplayInputNewOrder()
                                            }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#2984be'}}>
                                            Agregar Orden Nueva <FontAwesomeIcon icon="plus-circle" />
                                            </button>
                                        </div>
                                    </div>
                                ) : null
                            }

                            {
                                this.state.ordenes.length <= 0 ? null : (
                                    <div className="col-12" style={{marginTop: '15px'}}>
                                        <div className="row">
                                        <button type="submit" className="btn btn-outline-info col-lg-4 col-md-6 col-10 mx-auto">Crear Plantilla</button>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                this.state.mensajeInsertPlantillaRuta !== null ? (
                                    <div className="row" style={{marginTop: '25px'}}>
                                    {
                                        (this.state.mensajeInsertPlantillaRuta.response === 'success') ? (
                                        <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.mensajeInsertPlantillaRuta.mensaje}</div>
                                        ) : (
                                        <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.mensajeInsertPlantillaRuta.mensaje}</div>
                                        )
                                    }
                                    </div>
                                ) : null
                            }

                                
                        </form>
                    </div>
                </DashboardComponent>
            )
        }

    }

}

let mapStateToProps = (state, props) => {
    return {
      conexion: state.logIn.conexion,
      usuario: state.logIn.usuario,
      dataUsers: state.dataUsers,
      dataEmpresas: state.dataEmpresas
    }
}
  
export default connect(mapStateToProps)(RealizarRutas)