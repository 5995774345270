import React from 'react';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../../../Modal/Components/Modal.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let EmpresaEdit = props => {

  let empresa = props.empresa,
      empresaString = JSON.stringify(empresa),
      modalVisible = props.modalVisible,
      handleCloseModal = props.handleCloseModal

  return(
      <ModalContainer>
        <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
              <img className="brand-modal" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco" />
              <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                <div></div>
                <div></div>
              </button>
              </div>

              <div className="modal-body sin-margen-y-padding container-fluid">
                <div className="row text-center">


                  <div className="col-12">
                    <FontAwesomeIcon icon="building" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                    <h1>Empresa: {empresa.nombre_comercial}</h1>
                  </div>
                  <div className="col-12 row" style={{'marginTop': '25px'}}>

                    <div className="col-md-4 row">
                      <label htmlFor="nombreComercialEmpresa" className="col"><b>Nombre Comercial:</b></label>
                      <input id="nombreComercialEmpresa" ref={props.setRef} data-empresa={empresaString} data-value-type="nombre_comercial" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.nombre_comercial} style={{'top': '-3px'}}/>
                    </div>

                    <div className="col-md-4 row">
                      <label htmlFor="nombreRazonSocial" className="col"><b>Nombre Razón Social:</b></label>
                      <input id="nombreRazonSocial" ref={props.setRef} data-empresa={empresaString} data-value-type="nombre_razon_social" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.nombre_razon_social} style={{'top': '-3px'}}/>
                    </div>

                    <div className="col-md-4 row">
                      <label htmlFor="TelefonoEmpresa" className="col"><b>Teléfono:</b></label>
                      <input id="TelefonoEmpresa" ref={props.setRef} data-empresa={empresaString} data-value-type="telefono" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.telefono} style={{'top': '-3px'}}/>
                    </div>

                    <div className="col-md-4 row">
                      <label htmlFor="correo" className="col"><b>Correo:</b></label>
                      <input id="correo" ref={props.setRef} data-empresa={empresaString} data-value-type="correo" onChange={props.handleInputChange} type="email"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.correo} style={{'top': '-3px'}}/>
                    </div>

                    <div className="col-md-4 row">
                      <label htmlFor="NIT" className="col"><b>NIT:</b></label>
                      <input id="NIT" ref={props.setRef} data-empresa={empresaString} data-value-type="NIT" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.NIT} style={{'top': '-3px'}}/>
                    </div>

                    <div className="col-md-4 row">
                      <label htmlFor="DireccionFiscal" className="col"><b>Dirección Físcal:</b></label>
                      <input id="DireccionFiscal" ref={props.setRef} data-empresa={empresaString} data-value-type="direccion_fiscal" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.direccion_fiscal} style={{'top': '-3px'}}/>
                    </div>

                    <div className="col-md-4 row">
                      <label htmlFor="DireccionComercial" className="col"><b>Dirección Comercial:</b></label>
                      <input id="DireccionComercial" ref={props.setRef} data-empresa={empresaString} data-value-type="direccion_comercial" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.direccion_comercial} style={{'top': '-3px'}}/>
                    </div>

                    <div className="col-md-4 row">
                      <label htmlFor="Descripcion" className="col"><b>Descripción:</b></label>
                      <textarea id="Descripcion" ref={props.setRef} data-empresa={empresaString} data-value-type="descripcion" onChange={props.handleInputChange}  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.descripcion} style={{'top': '-3px'}}/>
                    </div>

                    <div className="col-md-4 row">
                      <label htmlFor="Convenio" className="col"><b>Convenio:</b></label>
                      <textarea id="Convenio" ref={props.setRef} data-empresa={empresaString} data-value-type="convenio" onChange={props.handleInputChange}  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.convenio} style={{'top': '-3px'}}/>
                    </div>

                    <div className="col-md-4 row">
                      <label htmlFor="CreditoAutorizado" className="col"><b>Crédito Autorizado:</b></label>
                      <input id="CreditoAutorizado" ref={props.setRef} data-empresa={empresaString} data-value-type="credito_autorizado" onChange={props.handleInputChange} type="number"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.credito_autorizado} style={{'top': '-3px'}}/>
                    </div>

                    <div className="col-md-4 row">
                      <label htmlFor="DiasCreditoAutorizado" className="col"><b>Días de Crédito Autorizado:</b></label>
                      <input id="DiasCreditoAutorizado" ref={props.setRef} data-empresa={empresaString} data-value-type="dias_de_credito_autorizado" onChange={props.handleInputChange} type="number"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.dias_de_credito_autorizado} style={{'top': '-3px'}}/>
                    </div>

                    <div className="col-md-4 row">
                      <label htmlFor="TipoDePagoComun" className="col"><b>Tipo de Pago Común:</b></label>
                      <select id="TipoDePagoComun" ref={props.setRef} data-empresa={empresaString} data-value-type="tipo_de_pago_comun" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={empresa.tipo_de_pago_comun} style={{'top': '-3px'}}>
                        <option value="Cheque">Cheque</option>
                        <option value="Deposito">Deposito</option>
                        <option value="Efectivo">Efectivo</option>
                      </select>
                    </div>

                  </div>

                </div>
                {
                    props.insertEmpresaMensaje !== null ? (
                      <div className="row">
                        {
                          (props.insertEmpresaMensaje.response === 'success') ? (
                            <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.insertEmpresaMensaje.mensaje}</div>
                          ) : (
                            <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.insertEmpresaMensaje.mensaje}</div>
                          )
                        }
                      </div>
                    ) : null
                  }
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default mx-auto" onClick={props.handleSaveNewEmpresa}><FontAwesomeIcon icon="save" style={{'fontSize': '150%'}} /></button>
                <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
              </div>
            </div>
          </div>
      </ModalB4>
    </ModalContainer>
  )
}

export default EmpresaEdit
