import GraphQL from './ConsultaGraphQL';

import url from '../../../apiService/ApiUrl.js';

// JavaScript Document
function handleError(err){
	console.log(`Request failed: ${err}`);
}

const logIn = (textId, itemCode, type, dispatch) => {
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(textId, itemCode, type)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {
		if(type === 'updateText'){
			//console.log(res.mensaje)
			//console.log(res.data.updateText.newTexto)
			dispatch({
				type: 'UPDATE_TEXT',
				payload: {
					id: res.data.dataUIMutation.updateText.idTexto,
					data: res.data.dataUIMutation.updateText.newTexto
				}
			})
			//console.log(res)
		}/*else if(type === 'http://localhost:8080/updateModalText'){
			This.props.dispatch({
				type: 'UPDATE_TEXT_MODAL',
				payload: {
					id: res.data.dataUIMutation.updateModalText.idModal,
					data: res.data.dataUIMutation.updateModalText.newTexto
				}
			})
		}else if(type === 'http://localhost:8080/updateModalTitulo'){
			console.log()
			This.props.dispatch({
				type: 'UPDATE_TITULO_MODAL',
				payload: {
					id: res.data.dataUIMutation.updateModalTitulo.idModal,
					data: res.data.dataUIMutation.updateModalTitulo.newTitulo
				}
			})
		}*/
	})
	.catch(err => handleError(err));

	//termina fetch
}

export default logIn;
