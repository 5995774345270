let consulta = (usuarioOEmail, password) => {
  let string = `{ auth { logIn( usuarioOEmail: "${usuarioOEmail}" password: "${password}") { conexion usuario { id
      usuario
      email
      nombre
      apellidos
      telefono
      celular
      DPI
      fecha_de_nacimiento
      direccion
      NIT
      nota
      sexo
      tipo
      permisos {
        AdminUI
        AdminUser {
          ViewUser
          CreateUser
          EditUser
          DeleteUser
        }
        AdminEmpresa{
          ViewEmpresa
          CreateEmpresa
          EditEmpresa
          DeleteEmpresa
        }
        Mensajeria{
          QRScanner
          ViewOrden
          CreateOrden
          EditOrden
          DeleteOrden
          ActOrdenAdmin
          ActOrdenMuestra
          CheckListOrden
          ViewRuta
          RealizarRuta
          EditRuta
          DeleteRuta
          CheckListRuta
          ViewPlantillaRuta
          RealizarPlantillaRuta
          EditPlantillaRuta
          DeletePlantillaRuta
        }
        AdminObjetivos
        Compras{
          CreateOrdenCompra
          AddProductoCompra
          ViewProductosCompras
        }
        Productos{
          ViewProducto
          CreateProducto
          EditProducto
          DeleteProducto
          ViewTipoCliente
          CreateTipoCliente
          EditTipoCliente
          DeleteTipoCliente
          ViewAseguradora
          CreateAseguradora
          EditAseguradora
          DeleteAseguradora
        }
      }
      usuario_activo
      empresa_asociada{
        id
        nombre
        telefono
        correo
      }
      colaborador{
        id_colaborador
        puesto
        numero_IGGS
        contrato
        cuenta_banco
        tipo_de_cuenta
        banco
        otro_banco
        departamento
      }
     } } } }`

  return {
    "query": string
  }

}

export default consulta;
