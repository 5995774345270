import React, { Component } from 'react';

import touchGalerias from '../../../../../MyModules/touch-galerias.js';

import GaleriaAnalisisClinicosComponent from '../Components/GaleriaAnalisisClinicos.jsx';

class GaleriaAnalisisClinicos extends Component{

	componentDidMount(){
		touchGalerias.mount();
	}

	componentWillUnmount(){
		touchGalerias.unMount();
	}

	render() {
		return (
			<GaleriaAnalisisClinicosComponent>
				<h1 className="col-xs-12">ANÁLISIS CLÍNICOS VETERINARIOS</h1>
				  <div id="carousel-analisis-clinicos-pacientes" className="carousel slide" data-ride="carousel">
						<div className="carousel-inner" role="listbox">

						  <div className="carousel-item active"><img src="/img/gim020.png" alt="First slide" className="center-block img-responsive" />
								<div className="carousel-caption">
								  <h3 id="sub1-carousel">No se toman muestras a mascotas, consulte su veterinario de cabecera</h3>
								</div>
						  </div>


						</div>
				  </div>
			</GaleriaAnalisisClinicosComponent>
		)
	}
}

export default GaleriaAnalisisClinicos;
