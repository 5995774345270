import React, { Component } from 'react';

import { BrowserQRCodeSvgWriter  } from '@zxing/library';

class QRCreator extends Component {

  /*constructor(props) {
    super(props);

    // No llames this.setState() aquí!
    this.state = {
    }

    this.handleChange = this.handleChange.bind(this);

  }*/

  componentDidMount(){
    const codeWriter = new BrowserQRCodeSvgWriter()
    codeWriter.writeToDom('#result', this.props.idOrden, 300, 300)
  }

  render(){
    return(
      <div className="row">
        <div className="col-12">
          <h4 className="text-center" style={{marginBottom: '0px'}}>{this.props.titulo ? this.props.titulo :"Número de Orden:"}</h4>
          <h5 className="text-center" style={{marginBottom: '0px'}}>{this.props.idOrden}</h5>
        </div>
        <div className="col-12">
          <div id="result" className="mx-auto" style={{maxWidth: '300px'}}></div>
        </div>
      </div>
    )
  }
}

export default QRCreator
