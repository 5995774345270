let handleInputChange = (event, This) => {

    let value = event.target.value,
        plantilla = event.target.dataset.plantillaOrden,
        type = event.target.dataset.valueType
  
    //console.log(JSON.parse(plantilla))
  
    This.props.dataMensajeria.plantillasRutasMensajeria.filter( obj => {
  
      if((obj.id === JSON.parse(plantilla).id) === true){
  
        //console.log(empresa)
  
        if(type === 'nombrePlantilla'){
  
          //console.log(obj)
  
          obj.nombrePlantilla = value
  
          This.props.dispatch({
            type:'CHANGE_DATA_PLANTILLA_RUTA_MENSAJERIA',
            payload: {
              dataPlantilla: obj
            }
          })
  
        }
  
      }
  
      return null
  
    })
  
  }
  
  export default handleInputChange;
  