let consulta = (data) => {

  let stringOrdenArray = []

    data.ordenes.forEach(orden => {

      if(orden.tipo === 'RecoleccionMuestra'){
        let stringOrden = JSON.stringify(orden).replace(/{"/g, '{').replace(/":/g, ':').replace(/,"/g, ',').replace(/",instrucciones/g, ',instrucciones').replace(/cantidadDeMuestras:"/g, 'cantidadDeMuestras:')
        stringOrdenArray.push(stringOrden)
      }else {
        let stringOrden = JSON.stringify(orden).replace(/":/g, ':').replace(/,"/g, ',').replace(/{"/g, '{')
        stringOrdenArray.push(stringOrden)
      }

    });

  //console.log(stringOrdenArray)

  let string = `mutation insertPlantillaRutaMensajeria {
    mensajeriaMutation {
      insertPlantillaRutaMensajeria(
        insertPlantillaRutaMensajeria: {
          nombrePlantilla: "${data.nombrePlantilla}"
          ordenes: [${stringOrdenArray}]
        }
      ){
        id
        mensaje
      }
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
