import moment from 'moment';

const initialState = {
    objetivos: [],
    metas: [],
    tareas: [],
    etiquetas: [],
    events: []
  }
  
  let dataAdminObjetivos = (state = initialState, action) => {
    switch (action.type) {
  
      case 'FETCH_ALL_DATA_ADMIN_OBJETIVOS': {
  
        let adminObjetivosData = action.payload.adminObjetivosData

        //var events = adminObjetivosData.objetivos.concat(adminObjetivosData.metas).concat(adminObjetivosData.tareas)
        //var events = adminObjetivosData.metas.concat(adminObjetivosData.tareas)
        var events = adminObjetivosData.tareas
  
        let newState = {
          ...state,
          objetivos: adminObjetivosData.objetivos,
          metas: adminObjetivosData.metas,
          tareas: adminObjetivosData.tareas,
          etiquetas: adminObjetivosData.etiquetas,
          events
        }
  
        return newState

      }

      case 'FETCH_ALL_OBJETIVOS': {
        
        let objetivosData = action.payload.objetivosData
  
        let newState = {
          ...state,
          objetivos: objetivosData
        }
  
        return newState
      }

      case 'FETCH_ALL_METAS': {
        
        let metasData = action.payload.metasData
  
        let newState = {
          ...state,
          metas: metasData
        }
  
        return newState
      }

      case 'FETCH_ALL_TAREAS': {
        
        let tareasData = action.payload.tareasData

        let events = tareasData.map( tarea => {
          tarea.fechaDeInicio = moment(parseInt(tarea.fechaDeInicio))._d
          tarea.fechaFinal = tarea.fechaFinal !== null ? moment(parseInt(tarea.fechaFinal))._d : tarea.fechaFinal
          tarea.fechaFinalPlanificada = moment(parseInt(tarea.fechaFinalPlanificada))._d
          tarea.type = "Tarea"
          return tarea
        })
  
        let newState = {
          ...state,
          tareas: tareasData,
          events
        }
  
        return newState
      }

      case 'REMOVE_EVENTS_ADMIN_OBJETIVOS': {

        let type = action.payload.type

        let newEvents = state.events.filter( event => event.type !== type)

        let newState = {
          ...state,
          events: newEvents
        }

        return newState
      }

      case 'ADD_EVENTS_ADMIN_OBJETIVOS': {

        let type = action.payload.type

        let newEvents

        if(type === "Objetivo"){
          newEvents = state.events.concat(state.objetivos)
          //newEvents = [...state.events, state.objetivos]
        }else if(type === "Meta"){
          newEvents = state.events.concat(state.metas)
          //newEvents = [...state.events, state.metas]
        }else if(type === "Tarea"){
          newEvents = state.events.concat(state.tareas)
          //newEvents = [...state.events, state.tareas]
        }

        let newState = {
          ...state,
          events: newEvents
        }

        return newState
      }

      case 'ADD_ETIQUETA_TO_ETIQUETAS': {

        let dataEtiqueta = action.payload.dataEtiqueta

        let newEtiquetas = [...state.etiquetas, dataEtiqueta]

        let newState = {
          ...state,
          etiquetas: newEtiquetas
        }

        return newState
      }

      case 'ADD_OBJETIVO_TO_OBJETIVOS': {

        let dataObjetivo = action.payload.dataObjetivo,
            stateCheckBox = action.payload.stateCheckBox

        let newObjetivos = [...state.objetivos, dataObjetivo]

        newObjetivos.sort(function(a,b){
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(a.fechaDeInicio) - new Date(b.fechaDeInicio);
        });

        let newState

        if((stateCheckBox !== undefined) && (stateCheckBox.objetivosChecked === true)){

          let newEvents = [...state.events, dataObjetivo]

          newState = {
            ...state,
            objetivos: newObjetivos,
            events: newEvents
          }

        }else{
          newState = {
            ...state,
            objetivos: newObjetivos
          }
        }

        return newState
      }

      case 'EDIT_OBJETIVO_TO_OBJETIVOS': {

        let dataObjetivo = action.payload.dataObjetivo,
            stateCheckBox = action.payload.stateCheckBox

        let newState

        let dataObjetivoArr = [dataObjetivo]
  
        let newStateObjetivos = state.objetivos.map( obj => dataObjetivoArr.find(o => o.id === obj.id) || obj )

        if((stateCheckBox !== undefined) && (stateCheckBox.objetivosChecked === true)){

          let newStateEvents = state.events.map( obj => dataObjetivoArr.find(o => o.id === obj.id) || obj )
    
          newState = {
            ...state,
            objetivos: newStateObjetivos,
            events: newStateEvents
          }

        }else{
          newState = {
            ...state,
            objetivos: newStateObjetivos
          }
        }

        return newState

      }

      case 'EDIT_META_TO_METAS': {

        let dataMeta = action.payload.dataMeta,
            stateCheckBox = action.payload.stateCheckBox

        let newState

        let dataMetaArr = [dataMeta]
  
        let newStateMetas = state.metas.map( obj => dataMetaArr.find(o => o.id === obj.id) || obj )

        if((stateCheckBox !== undefined) && (stateCheckBox.metasChecked === true)){

          let newStateEvents = state.events.map( obj => dataMetaArr.find(o => o.id === obj.id) || obj )
    
          newState = {
            ...state,
            metas: newStateMetas,
            events: newStateEvents
          }

        }else{
          newState = {
            ...state,
            metas: newStateMetas
          }
        }

        return newState

      }

      case 'EDIT_TAREA_TO_TAREAS': {

        let dataTarea = action.payload.dataTarea,
            stateCheckBox = action.payload.stateCheckBox

        let newState

        let dataTareaArr = [dataTarea]
  
        let newStateTareas = state.tareas.map( obj => dataTareaArr.find(o => o.id === obj.id) || obj )

        if((stateCheckBox !== undefined) && (stateCheckBox.tareasChecked === true)){

          let newStateEvents = state.events.map( obj => dataTareaArr.find(o => o.id === obj.id) || obj )
    
          newState = {
            ...state,
            tareas: newStateTareas,
            events: newStateEvents
          }

        }else{
          newState = {
            ...state,
            tareas: newStateTareas
          }
        }

        return newState

      }

      case 'REMOVE_OBJETIVO_TO_OBJETIVOS': {

        let objetivoId = action.payload.objetivoId

        let newObjetivos = state.objetivos.filter( objetivo => objetivo.id !== objetivoId)

        let newState = {
          ...state,
          objetivos: newObjetivos
        }

        return newState

      }

      case 'ADD_META_TO_METAS': {

        let dataMeta = action.payload.dataMeta,
            stateCheckBox = action.payload.stateCheckBox

        let newMetas = [...state.metas, dataMeta]

        newMetas.sort(function(a,b){
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(a.fechaDeInicio) - new Date(b.fechaDeInicio);
        });

        let newState

        if((stateCheckBox !== undefined) && (stateCheckBox.metasChecked === true)){

          let newEvents = [...state.events, dataMeta]

          newState = {
            ...state,
            metas: newMetas,
            events: newEvents
          }

        }else{
          newState = {
            ...state,
            metas: newMetas
          }
        }

        return newState
      }

      case 'REMOVE_META_TO_METAS': {

        let metaId = action.payload.metaId

        let newMetas = state.metas.filter( meta => meta.id !== metaId)

        let newState = {
          ...state,
          metas: newMetas
        }

        return newState

      }

      case 'ADD_TAREA_TO_TAREAS': {

        let dataTarea = action.payload.dataTarea,
            stateCheckBox = action.payload.stateCheckBox

        let newTareas = [...state.tareas, dataTarea]

        newTareas.sort(function(a,b){
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(a.fechaDeInicio) - new Date(b.fechaDeInicio);
        });

        let newState

        if((stateCheckBox !== undefined) && (stateCheckBox.tareasChecked === true)){

          let newEvents = [...state.events, dataTarea]

          newState = {
            ...state,
            tareas: newTareas,
            events: newEvents
          }

        }else{
          newState = {
            ...state,
            tareas: newTareas
          }
        }

        return newState
      }

      case 'REMOVE_TAREA_TO_TAREAS': {

        let tareaId = action.payload.tareaId,
            stateCheckBox = action.payload.stateCheckBox

        let newState

        let newTareas = state.tareas.filter( tarea => tarea.id !== tareaId)

        if((stateCheckBox !== undefined) && (stateCheckBox.tareasChecked === true)){

          let newEvents = state.events.filter( event => event.id !== tareaId)

          newState = {
            ...state,
            tareas: newTareas,
            events: newEvents
          }

        }else{
          
          newState = {
            ...state,
            tareas: newTareas
          }

        }

        return newState

      }
  
      //break;
      default:
        return state
    }
  }
  
  export default dataAdminObjetivos