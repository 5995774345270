let consulta = (tareaJSON) => {

  const string =
    `mutation {
      adminObjetivos {
        updateStateTarea(idTarea: "${tareaJSON.id}", estado: "${tareaJSON.estado}", fechaFinal: "${tareaJSON.fechaFinal}"){
          id
          mensaje
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
