import React from 'react';

import { NavLink } from 'react-router-dom'; //Link

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let AdminCompras = props => {
  
  if( (Object.entries(props.usuario.permisos.Compras).length > 0) === true){
    return(
      <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Components">
        <a className="nav-link nav-link-collapse collapsed dropdown-toggle" data-toggle="collapse" href="#collapseCompras" data-parent="#exampleAccordion" onClick={props.toggleSideOff} >
          <FontAwesomeIcon icon="cash-register" /> <span className="nav-link-text">Administracion de Compras</span>
        </a>
        <ul className="sidenav-second-level collapse" id="collapseCompras">

          <li>
            <NavLink exact to="/dashboard/compras/qr" activeClassName="active" className="nav-link"><FontAwesomeIcon icon="qrcode" /> QRScanner</NavLink>
          </li>

          {
            ( (Object.entries(props.usuario.permisos.Compras).length > 0) ) ? (
              <li>
                <a className="nav-link-collapse collapsed dropdown-toggle" data-toggle="collapse" href="#collapseSubCompras"onClick={props.toggleSideOff}><FontAwesomeIcon icon="clipboard-list" /><span className="nav-link-text"> Ordenes </span></a>
                <ul className="sidenav-third-level collapse" id="collapseSubCompras">

                  {
                    props.usuario.permisos.Compras.CreateOrdenCompra === true ? (
                      <li>
                        <NavLink exact to="/dashboard/admin-compras/realizar-orden" activeClassName="active" className="nav-link"><FontAwesomeIcon icon="list-alt" /> Realizar Orden</NavLink>
                      </li>
                    ) : null
                  }

                  <li>
                    <NavLink exact to="/dashboard/admin-compras/ordenes" activeClassName="active" className="nav-link"><FontAwesomeIcon icon="list-alt" /> Ver Ordenes</NavLink>
                  </li>

                </ul>
              </li>
            ) : null
          }

          {
            ( (Object.entries(props.usuario.permisos.Compras).length > 0) ) ? (
              <li>
                <NavLink exact to="/dashboard/admin-compras/pass-pagos" activeClassName="active" className="nav-link"><FontAwesomeIcon icon="list-alt" /> Ver Contraseñas de Pago</NavLink>
              </li>
            ) : null
          }

          {
            ((Object.entries(props.usuario.permisos.Compras).length > 0) && (props.usuario.permisos.Compras.AddProductoCompra === true)) ? (
              <li>
                <NavLink exact to="/dashboard/admin-compras/agregar-producto" activeClassName="active" className="nav-link"><FontAwesomeIcon icon="cart-plus" /> Agregar Producto</NavLink>
              </li>
            ) : null
          }

          {/*
           ((Object.entries(props.usuario.permisos.Compras).length > 0) && (props.usuario.permisos.Compras.ViewProductosCompras === true)) ? ( */}
            <li>
              <NavLink exact to="/dashboard/admin-compras/productos" activeClassName="active" className="nav-link"><FontAwesomeIcon icon="boxes" /> Ver Productos Proveedores</NavLink>
            </li>
           {/*) : null
          */}



        </ul>
      </li>
    )
  }else{
    return null
  }
}

/*<li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Charts">
  <Link to="/dashboard-admin-user" className="nav-link">
    <FontAwesomeIcon icon="users-cog" />
    <span className="nav-link-text"> Administración de Usuarios</span>
  </Link>
</li>*/


export default AdminCompras
