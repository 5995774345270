/*Import AdminProductosDashboard*/

// Productos
import CreateProducto from '../../Dashboard/AdminProductos/Productos/Components/CreateProducto';

// TipoClientes
import TipoClientesDashboard from '../../Dashboard/AdminProductos/TipoClientes/Components/TipoClientesDashboard';
import CreateTipoCliente from '../../Dashboard/AdminProductos/TipoClientes/Components/CreateTipoCliente';
import EditTipoCliente from '../../Dashboard/AdminProductos/TipoClientes/Components/EditTipoCliente';
import ViewTipoCliente from '../../Dashboard/AdminProductos/TipoClientes/Components/ViewTipoCliente';

const PRODUCTO_CREATE = {
    component: CreateProducto,
    path: "/dashboard/productos/producto/create"
}

const TIPO_CLIENTES_DASHBOARD = {
    component: TipoClientesDashboard,
    path: "/dashboard/productos/tipo-clientes"
}

const TIPO_CLIENTE_CREATE = {
    component: CreateTipoCliente,
    path: "/dashboard/productos/tipo-clientes/create"
}

const TIPO_CLIENTE_EDIT = {
    component: EditTipoCliente,
    path: "/dashboard/productos/tipo-clientes/edit/:idTipoCliente"
}

const TIPO_CLIENTE_VIEW = {
    component: ViewTipoCliente,
    path: "/dashboard/productos/tipo-clientes/view/:idTipoCliente"
}

const routingProductos = [ PRODUCTO_CREATE, TIPO_CLIENTES_DASHBOARD, TIPO_CLIENTE_CREATE, TIPO_CLIENTE_EDIT, TIPO_CLIENTE_VIEW ]

export default routingProductos