import { connect } from 'react-redux';

import React, { Component } from 'react';

import Jodit from '../../../Jodit/Components/Jodit';

class Politica extends Component {

  render(){

    let renderVal = this.props.dataUI,
				textPoliticaObj = renderVal.textos.find( text => {
					return text.id === 'tex005'
				})

    return(
      <div id="contenedor-politica" className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 contenedor-val-mis-pol">
        <div id="text-politica" className="row justify-content-md-center" style={{height: '100%'}}>
          <div id="anchor-politica"></div><div id="icono-politica" className="iconos-mis-vis-pol"><span className="icon-approve-invoice"></span></div>
          <h3 className="col-12">POLÍTICA DE CALIDAD</h3>
          <Jodit textObj={textPoliticaObj} type='updateText' />
        </div>
        {/*<Summernote itemID='#text-politica' textObj={textPoliticaObj} texto={texto} url='http://localhost:8080/updateText' />*/}
      </div>
    )
  }

}

let mapStateToProps = (state, props) => {
  return {
    dataUI: state.dataUI
  }
}

export default connect(mapStateToProps)(Politica);
