import React from 'react';
import './css/PortadaAnalisisClinicos.css';

const PortadaAnalisisClinicos = props => {

    return(
        <section id="portada-referencia-analisis-clinicos"className="container-fluid">    
            <div className="row py-5">
                <div className="col-12 col-xl-6 d-flex flex-column mr-md-0 pr-md-0">
                    <h1 className="text-justify pl-5">Laboratorio Industrial</h1>
                    <div className="info-tab d-flex flex-column flex-md-row align-items-center mb-3">
                        <div id="servicio-icon" className="mx-2 mx-sm-3 my-2 mx-md-5 icon">
                            <img className="img-fluid p-3" src="/img/pacientes/analisisclinico/lab-icon.svg" alt="icono lab inicio pacientes" />
                        </div>
                        <div className="py-3 px-4 px-md-0">
                            <h3 className="text-center text-md-left">Servicio A:</h3>
                            <p className="mb-0">Empresas e industrias a nivel nacional e internacional</p>
                        </div>
                    </div>
                    <div className="info-tab d-flex flex-column flex-md-row align-items-center mb-3">
                        <div id="acreditaciones-icon" className="mx-2 mx-sm-3 my-2 mx-md-5 icon">
                            <img className="img-fluid p-3" src="/img/analisismicrobiologico/acreditaciones-icon.svg" alt="icono personal inicio pacientes" />
                        </div>
                        <div className="py-3 px-4 px-md-0">
                            <h3 className="text-center text-md-left">Acreditaciones:</h3>
                            <p className="mb-0">Más de 40 métodos acreditados</p>
                        </div>
                    </div>
                    <div className="info-tab d-flex flex-column flex-md-row align-items-center mb-3">
                        <div id="asesoria-icon" className="mx-2 mx-sm-3 my-2 mx-md-5 icon">
                            <img className="img-fluid p-3" src="/img/analisismicrobiologico/asesoria-icon.svg" alt="icono lab inicio pacientes" />
                        </div>
                        <div className="py-3 px-4 px-md-0">
                            <h3 className="text-center text-md-left">Asesoría y capacitaciones</h3>
                        </div>
                    </div>
                    <div className="info-tab d-flex flex-column flex-md-row align-items-center mb-3">
                        <div id="muestra-icon" className="mx-2 mx-sm-3 my-2 mx-md-5 icon">
                            <img className="img-fluid p-3" src="/img/analisismicrobiologico/muestras-icon.svg" alt="icono lab inicio pacientes" />
                        </div>
                        <div className="py-3 px-4 px-md-0">
                            <h3 className="text-center text-md-left">Toma de muestra en empresas</h3>
                        </div>
                    </div>
                    <div className="info-tab d-flex flex-column flex-md-row align-items-center mb-3">
                        <div id="message-icon" className="mx-2 mx-sm-3 my-2 mx-md-5 icon">
                            <img className="img-fluid p-3" src="/img/pacientes/analisisclinico/message-icon.svg" alt="icono lab inicio pacientes" />
                        </div>
                        <div className="py-3 px-4 px-md-0">
                            <h3 className="text-center text-md-left">Mensajería</h3>
                            <p className="mb-0">Para la recolección de muestras</p>
                        </div>
                    </div>
                    <div className="info-tab d-flex flex-column flex-md-row align-items-center mb-3">
                        <div id="resultados-en-linea-icon" className="mx-2 mx-sm-3 my-2 mx-md-5 icon">
                            <img className="img-fluid p-3" src="/img/pacientes/analisisclinico/resultados-icon.svg" alt="icono lab inicio pacientes" />
                        </div>
                        <div className="py-3 px-4 px-md-0">
                            <h3 className="text-center text-md-left">Resultados en línea</h3>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-6 ml-0 pl-xl-0 d-flex justify-content-center">
                    <img id="img-portada" className="img-fluid" src="/img/analisismicrobiologico/portadaIndustrial.jpg" alt="portada Inicio Pacientes" />
                </div>
            </div>
        </section>
    )

}

export default PortadaAnalisisClinicos