import React, { Component } from 'react';

import { Redirect } from 'react-router';

import { NavLink } from 'react-router-dom';

import { connect } from 'react-redux';

import sessionService from '../../../../sessionService/sessionService';

import url from '../../../../apiService/ApiUrl.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//import AdminUsers from '../components/TopMenu/AdminUserMenu/AdminUsers.jsx';

//import AdminEmpresas from '../components/TopMenu/AdminUserMenu/AdminEmpresas.jsx';

import Buscador from './Buscador.jsx';

import OrdenesMensajeria from '../components/TopMenu/AdminMensajeriaMenu/Ordenes.jsx';

import Loader from '../../../Components/Loader.jsx';

class TopNavbar extends Component{

  constructor(props) {
    super(props);

    this.OnLogOut = this.OnLogOut.bind(this);

  }

  OnLogOut() {

    let sessionServiceCallback = () => {
      
      if(this.props.conexion === true){
        return <Loader />
      }else{
        return <Redirect to='/login' />
      }
    }

    sessionService.logOut(url, this, sessionServiceCallback)

  }

  render(){
    return(
      <div className="col row" style={{marginLeft: '0', marginTop: '0'}}>
        {/*<div className="col-md">
          <ul className="navbar-nav mr-auto">
            <AdminUsers usuario={this.props.usuario} />
            <AdminEmpresas usuario={this.props.usuario} />*/}
            {/*<li className="nav-item dropdown text-center text-md-right">
              <a className="nav-link dropdown-toggle mr-lg-2" id="messagesDropdown" href="#link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fa fa-fw fa-envelope"></i>
                <span className="d-md-none">Messages
                  <span className="badge badge-pill badge-primary">12 New</span>
                </span>
                <span className="indicator text-primary d-none d-md-block">
                  <i className="fa fa-fw fa-circle"></i>
                </span>
              </a>
              <div className="dropdown-menu" aria-labelledby="messagesDropdown">
                <h6 className="dropdown-header">New Messages:</h6>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#link">
                  <strong>David Miller</strong>
                  <span className="small float-right text-muted">11:21 AM</span>
                  <div className="dropdown-message small">Hey there! This new version of SB Admin is pretty awesome! These messages clip off when they reach the end of the box so they don't overflow over to the sides!</div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#link">
                  <strong>Jane Smith</strong>
                  <span className="small float-right text-muted">11:21 AM</span>
                  <div className="dropdown-message small">I was wondering if you could meet for an appointment at 3:00 instead of 4:00. Thanks!</div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#link">
                  <strong>John Doe</strong>
                  <span className="small float-right text-muted">11:21 AM</span>
                  <div className="dropdown-message small">I've sent the final files over to you for review. When you're able to sign off of them let me know and we can discuss distribution.</div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item small" href="#link">View all messages</a>
              </div>
            </li>
            <li className="nav-item dropdown text-center text-md-right">
              <a className="nav-link dropdown-toggle mr-lg-2" id="alertsDropdown" href="#link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fa fa-fw fa-bell"></i>
                <span className="d-md-none">Alerts
                  <span className="badge badge-pill badge-warning">6 New</span>
                </span>
                <span className="indicator text-warning d-none d-md-block">
                  <i className="fa fa-fw fa-circle"></i>
                </span>
              </a>
              <div className="dropdown-menu" aria-labelledby="alertsDropdown">
                <h6 className="dropdown-header">New Alerts:</h6>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#link">
                  <span className="text-success">
                    <strong>
                      <i className="fa fa-long-arrow-up fa-fw"></i>Status Update</strong>
                  </span>
                  <span className="small float-right text-muted">11:21 AM</span>
                  <div className="dropdown-message small">This is an automated server response message. All systems are online.</div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#link">
                  <span className="text-danger">
                    <strong>
                      <i className="fa fa-long-arrow-down fa-fw"></i>Status Update</strong>
                  </span>
                  <span className="small float-right text-muted">11:21 AM</span>
                  <div className="dropdown-message small">This is an automated server response message. All systems are online.</div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#link">
                  <span className="text-success">
                    <strong>
                      <i className="fa fa-long-arrow-up fa-fw"></i>Status Update</strong>
                  </span>
                  <span className="small float-right text-muted">11:21 AM</span>
                  <div className="dropdown-message small">This is an automated server response message. All systems are online.</div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item small" href="#link">View all alerts</a>
              </div>
            </li>
          </ul>
        </div>*/}
        <div className="col-md">
          <ul className="navbar-nav ml-auto float-md-left">
            <OrdenesMensajeria usuario={this.props.usuario} />
          </ul>
          <ul className="navbar-nav ml-auto float-md-right">

            <Buscador usuario={this.props.usuario} usuarios={this.props.usuarios} empresas={this.props.empresas}/>

            <li className="nav-item dropdown text-center text-md-right">
              <a className="nav-link dropdown-toggle mr-lg-2" href="#link" style={{'minWidth': '55px'}} data-toggle="dropdown" aria-expanded="false">
                <FontAwesomeIcon icon="user-circle" style={{fontSize: '22px'}}/>
              </a>

              <div id="user-dropdown" className="dropdown-menu text-center" aria-labelledby="messagesDropdown">

                <NavLink exact to="/dashboard/mi-cuenta" activeClassName="active" className="nav-link" style={{color: '#2984be'}}>Mi Cuenta <FontAwesomeIcon icon="user-circle" style={{color: '#2984be'}}/></NavLink>

                <div className="dropdown-divider"></div>

                <NavLink onClick={this.OnLogOut} exact to="/login" activeClassName="active" className="nav-link" style={{color: '#2984be'}}>Logout <FontAwesomeIcon icon="sign-out-alt" style={{color: '#2984be'}}/></NavLink>

              </div>

            </li>

            {/*<li className="nav-item text-center text-md-right">
              <NavLink onClick={this.OnLogOut} exact to="/login" activeClassName="active" className="nav-link">Logout <FontAwesomeIcon icon="sign-out-alt" /></NavLink>
            </li>*/}

          </ul>
        </div>
      </div>
    )
  }

}

let mapStateToProps = (state, props) => {
  return {
		conexion: state.logIn.conexion,
    usuario: state.logIn.usuario,
    usuarios: state.dataUsers.usuarios,
    empresas: state.dataEmpresas.empresas
  }
}

export default connect(mapStateToProps)(TopNavbar)
