import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Edit = (props) => (
	<button onClick={props.handleEdit} className="EditButton" style={{'position' : 'relative', 'color' : '#FFFFFF', 'backgroundColor' : '#2984be', 'cursor' : 'pointer', 'border' : 'solid 1px #FFFFFF', 'borderRadius' : '5px', 'margin': '10px'}}>
		<FontAwesomeIcon icon="edit" style={{'fontSize' : '25px'}} />
	</button>
)

export default Edit;
