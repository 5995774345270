let handleInputChange = (event, This) => {

  let value = event.target.value,
      orden = event.target.dataset.orden,
      type = event.target.dataset.valueType

  //console.log(orden)

  This.props.dataMensajeria.solicitudesMensajeria.filter( obj => {

    if((obj.id === JSON.parse(orden).id) === true){

      //console.log(empresa)

      if(type === 'tipoOrden'){

        //console.log(obj)

        obj.tipo = value

        This.props.dispatch({
          type:'CHANGE_DATA_ORDEN_MENSAJERIA',
          payload: {
            dataOrden: obj
          }
        })

      }else if(type === 'empresaOrden'){

        //console.log(obj)

        obj.idEmpresa = value
        obj.idUsuario = null

        This.props.dispatch({
          type:'CHANGE_DATA_ORDEN_MENSAJERIA',
          payload: {
            dataOrden: obj
          }
        })

      }else if(type === 'usuarioOrden'){

        //console.log(obj)

        obj.idUsuario = value
        obj.idEmpresa = null

        This.props.dispatch({
          type:'CHANGE_DATA_ORDEN_MENSAJERIA',
          payload: {
            dataOrden: obj
          }
        })

      }else if(type === 'instrucciones'){

        //console.log(obj)

        obj.instrucciones = value
        //obj.cantidadDeMuestras = null

        This.props.dispatch({
          type:'CHANGE_DATA_ORDEN_MENSAJERIA',
          payload: {
            dataOrden: obj
          }
        })

      }else if(type === 'cantidadDeMuestras'){

        //console.log(obj)

        obj.cantidadDeMuestras = parseInt(value)
        //obj.instrucciones = null

        This.props.dispatch({
          type:'CHANGE_DATA_ORDEN_MENSAJERIA',
          payload: {
            dataOrden: obj
          }
        })

      }if(type === 'mensajeroOrden'){

        //console.log(obj)

        obj.idMensajero = value

        This.props.dispatch({
          type:'CHANGE_DATA_ORDEN_MENSAJERIA',
          payload: {
            dataOrden: obj
          }
        })

      }if(type === 'personaQueLlamo'){

        //console.log(obj)

        obj.personaQueLlamo = value

        This.props.dispatch({
          type:'CHANGE_DATA_ORDEN_MENSAJERIA',
          payload: {
            dataOrden: obj
          }
        })

      }if(type === 'telefono'){

        //console.log(obj)

        obj.telefono = value

        This.props.dispatch({
          type:'CHANGE_DATA_ORDEN_MENSAJERIA',
          payload: {
            dataOrden: obj
          }
        })

      }if(type === 'direccion'){

        //console.log(obj)

        obj.direccionDeRecepcion = value

        This.props.dispatch({
          type:'CHANGE_DATA_ORDEN_MENSAJERIA',
          payload: {
            dataOrden: obj
          }
        })

      }if(type === 'estado'){

        //console.log(obj)

        obj.estado = value

        This.props.dispatch({
          type:'CHANGE_DATA_ORDEN_MENSAJERIA',
          payload: {
            dataOrden: obj
          }
        })

      }if(type === 'diaDeRecoleccionYHora'){

        //console.log(obj)

        let diaDeRecoleccion = document.querySelector('#diaDeRecoleccion').value.replace(/-/g, ','),
        diaDeRecoleccionArray = diaDeRecoleccion.split(","),
        diaDeRecoleccionArrayNumbers = diaDeRecoleccionArray.map(function(item) {
          return parseInt(item, 10);
        }),
        mes = diaDeRecoleccionArrayNumbers[1] - 1,
        fechaDeRecoleccionArray = [diaDeRecoleccionArrayNumbers[0], mes, diaDeRecoleccionArrayNumbers[2]],
        horaDeRecoleccion = document.querySelector('#horaDeRecoleccion').value.replace(/:/g, ','),
        horaDeRecoleccionArray = horaDeRecoleccion.split(","),
        horaDeRecoleccionArrayNumbers = horaDeRecoleccionArray.map(function(item) {
          return parseInt(item, 10);
        })

        fechaDeRecoleccionArray.push(horaDeRecoleccionArrayNumbers[0], horaDeRecoleccionArrayNumbers[1])
      
        let fechaDeRecoleccion = new Date(...fechaDeRecoleccionArray),

        timestamp = fechaDeRecoleccion.getTime()/1000,
        timestampString = timestamp.toString() + "000"

        obj.fechaDeRecoleccion = timestampString

        This.props.dispatch({
          type:'CHANGE_DATA_ORDEN_MENSAJERIA',
          payload: {
            dataOrden: obj
          }
        })

      }if(type === 'urgente'){

        if(obj.urgente === false){
          obj.urgente = true
        }else{
          obj.urgente = false
        }

        This.props.dispatch({
          type:'CHANGE_DATA_ORDEN_MENSAJERIA',
          payload: {
            dataOrden: obj
          }
        })

      }

    }

    return null

  })

}

export default handleInputChange;
