let consulta = (tipoCliente) => {

  let string = `mutation{
    productosMutation{
      deleteTipoCliente(id: "${tipoCliente.id}")
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
