import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DashboardComponent from '../../dashboard/components/DashboardComponent.jsx';

import GraphQL from '../../../../apiService/FetchGraphQLMensajeria.js';

import GraphQLUsuarios from '../../../../apiService/FetchGraphQLUsuarios.js';

import GraphQLEmpresas from '../../../../apiService/FetchGraphQLEmpresas.js';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

import MensajeriaTable from '../components/MensajeriaTable';

import NewMensajeriaOrdenSubscription from './Subscriptions/NewMensajeriaOrdenSubscription';

import deleteOrden from './DeleteOrdenFetch/FetchDeleteOrden.js';

import FetchUpdateEsadoSolicitudGraphQL from './QRScanner/FetchUpdateEstadoSolicitud/fetchGraphQL.js';

import Select from 'react-select';

import OrdenEmpresa from '../components/RealizarOrdenMuestras/OrdenEmpresa.jsx';

import OrdenUsuario from '../components/RealizarOrdenMuestras/OrdenUsuario.jsx';

class AdminUserDashboard extends Component{

	constructor(props) {
		super(props);
		
		this.state = {
			orden: null,
			ordenesDesde: "",
			ordenesHasta: "",
			numeroDeOrdenFilter: '',
			selectedOptionMensajeros: null,
			selectedOptionEmpresas: null,
      		selectedOptionUsuarios: null,
			selectedOptionEstado: null
		}

		this.handleOpenModal = this.handleOpenModal.bind(this);
		this.handleDeleteOrdenMensajeria = this.handleDeleteOrdenMensajeria.bind(this);
		this.setDate = this.setDate.bind(this);
		this.handleChangeMensajero = this.handleChangeMensajero.bind(this);
		this.handleChangeEstadoSolicitud = this.handleChangeEstadoSolicitud.bind(this);
		this.handleSelectEstadoChange = this.handleSelectEstadoChange.bind(this);
		this.handleChangeEmpresa = this.handleChangeEmpresa.bind(this);
		this.handleChangeUsuario = this.handleChangeUsuario.bind(this);
		this.handleInputNumeroDeOrdenFilter = this.handleInputNumeroDeOrdenFilter.bind(this);
	}

	handleInputNumeroDeOrdenFilter(event){
		this.setState({
			numeroDeOrdenFilter: event.target.value 
		})
	}

	handleSelectEstadoChange(event){
		if(event.target.value === ""){
			this.setState({
				selectedOptionEstado: null
			})
		}else{
			this.setState({
				selectedOptionEstado: event.target.value
			})
		}
	}

	handleChangeEstadoSolicitud(orden) {

    let idOrden = orden.id,
        nuevoEstado
    if ((this.props.usuario.colaborador === null) || (this.props.usuario.colaborador.puesto === 'Mensajero') ) {

      nuevoEstado = {
        id: idOrden,
        estado: "Entregado a Mensajero"
	  }

      FetchUpdateEsadoSolicitudGraphQL(this, nuevoEstado, orden)

    }else if( (this.props.usuario.permisos.Mensajeria.ActOrdenMuestra === true) || (this.props.usuario.permisos.Mensajeria.ActOrdenAdmin === true) ){


		if(this.props.usuario.permisos.Mensajeria.ActOrdenMuestra === true){
			if(orden.tipo === "RecoleccionMuestra"){
				nuevoEstado = {
					id: idOrden,
					estado: "Entregado a Laboratorio"
				}
	
				FetchUpdateEsadoSolicitudGraphQL(this, nuevoEstado, orden)
	
			}
		}

		if(this.props.usuario.permisos.Mensajeria.ActOrdenAdmin === true){
			if(orden.tipo === "Administrativa"){
	
				nuevoEstado = {
				  id: idOrden,
				  estado: "Finalizado"
				}
				
				FetchUpdateEsadoSolicitudGraphQL(this, nuevoEstado, orden)
		  
			}
		}


	}

  }
	
	handleDeleteOrdenMensajeria(Orden){
		return deleteOrden(this, Orden)
	}

	handleOpenModal(modal, typeModal){
    this.props.dispatch({
			type: `OPEN_MODAL`,
			payload: {
				modal,
				typeModal
			}
		})

	}
	
	handleChangeMensajero = selectedOptionMensajeros => {

    this.setState({
      selectedOptionMensajeros
    });

    //console.log(`Option selected:`, selectedOption);

  }

	componentDidMount() {

		//Nuevo Componente
		let date = new Date()

		let dia 
		(date.getDate()) < 10 ? dia = `0${date.getDate()}` : dia = `${date.getDate()}`
		let mes
    	(date.getMonth() + 1) < 10 ? mes = `0${date.getMonth() + 1}` : mes = `${date.getMonth() + 1}`
		let year = date.getFullYear()
		//Fecha
		let fecha = `${year}-${mes}-${dia}`

		this.setState({
			ordenesDesde: fecha,
			ordenesHasta: fecha
		})

		let arrayOfMensajeriaValues = [],
        allMensajeriaValuesFalse = true

		if(this.props.usuario !== null){
			if(this.props.usuario.permisos !== null){
				arrayOfMensajeriaValues = Object.values(this.props.usuario.permisos.Mensajeria)
				allMensajeriaValuesFalse = arrayOfMensajeriaValues.every(v => v === false)
			}
		}

		if( (this.props.conexion !== false) && (this.props.usuario.permisos !== null) && ((Object.entries(this.props.usuario.permisos.Mensajeria).length > 0) === true) && (allMensajeriaValuesFalse === false) && ((this.props.usuario.permisos.Mensajeria.ViewOrden === true) || (this.props.usuario.permisos.Mensajeria.EditOrden === true) || (this.props.usuario.permisos.Mensajeria.DeleteOrden === true) || (this.props.usuario.permisos.Mensajeria.ActOrdenAdmin === true) || (this.props.usuario.permisos.Mensajeria.ActOrdenMuestra === true) || (this.props.usuario.permisos.Mensajeria.CheckListOrden === true)) ){

			GraphQLUsuarios(this);
	
			GraphQLEmpresas(this);
	
			let fechaToTimeStamp = new Date(fecha.replace(/-/g, '/')),
					fechaDesdeTimestampString = fechaToTimeStamp.setHours(0,0,0,0),
					fechaHastaTimestampString = fechaToTimeStamp.setHours(23,59,59,0);
					
					let fechaDesdeString = new Date(parseInt(fechaDesdeTimestampString)),
							fechaHastaString = new Date(parseInt(fechaHastaTimestampString))
							
					//console.log(fechaDesdeString)
					//console.log(fechaHastaString)
			
			GraphQL(this, fechaDesdeString, fechaHastaString);

			NewMensajeriaOrdenSubscription(this, fechaDesdeString, fechaHastaString)

			//Codigo de Subscription
			//let ThisSubscription = this
	
			//ordenesDesde: "Wed Jun 17 2020 00:00:00 GMT-0600 (hora estándar central)"
			//ordenesHasta: "Wed Jun 17 2020 23:59:59 GMT-0600 (hora estándar central)"

			// use
			/*(async () => {
				try {
				  const res = await this.props.suscribeGraphQL({
					query: `
					subscription {
					  newOrden(
						ordenesDesde: "${fechaDesdeString}"
						ordenesHasta: "${fechaHastaString}"
					  ){
						id
						idEmpresa
						idUsuario
						idMensajero
						instrucciones
						cantidadDeMuestras
						personaQueLlamo
						telefono
						direccionDeRecepcion
						fechaDeCreacion
						fechaDeRecoleccion
						horaDeOrden
						tipo
						urgente
						estado
						razonDeCancelacion
						checkList {
						  nombreQuienRevisa
						  fecha
						  cantidadDeMuestrasTruly
						  observaciones
						}
						notasDeOrden
						personaQueDejaLaMuestra
						telefonoDeQueDejaLaMuestra
						correoDeQueDejaLaMuestra
					  }
					}
					`,
				  });

				  this.props.suscriptionOrdenes(res.data.newOrden)
		  
				  //console.log(res)
				  // complete
				  //next = result = { data: { hello: 'Hello World!' } }
				} catch (err) {
				  // error
				  console.log(err)
				}
			})();*/
	
			/*this.props.client.subscribe({
				query: gql`
				subscription {
			  
				  newOrden(
					ordenesDesde: "${fechaDesdeString}"
					ordenesHasta: "${fechaHastaString}"
				  ){
					id
					idEmpresa
					idUsuario
					idMensajero
					instrucciones
					cantidadDeMuestras
					personaQueLlamo
					telefono
					direccionDeRecepcion
					fechaDeCreacion
					fechaDeRecoleccion
					horaDeOrden
					tipo
					urgente
					estado
					razonDeCancelacion
					checkList {
					  nombreQuienRevisa
					  fecha
					  cantidadDeMuestrasTruly
					  observaciones
					}
					notasDeOrden
					personaQueDejaLaMuestra
					telefonoDeQueDejaLaMuestra
					correoDeQueDejaLaMuestra
				  }
				}
				`,
				variables: {}
			  }).subscribe({
				next (data) {
					This.props.suscriptionOrdenes(data.data.newOrden)
					// Notify your application with the new arrived data
				}
			  })*/

		}

  	}

	/*componentDidUpdate(){
		//NewMensajeriaOrdenSubscription(this, fechaDesdeString, fechaHastaString)
	}*/

	setButton = element => {
		if(element.dataset.modalType === 'viewUser'){
			this.button = element.id;
			console.log(element.id);
		}else if(element.dataset.modalType === 'editUser'){
			this.button = element.id;
			//console.log(element);
		}
	}

	setDate(event, type) {
		
		let fechaToTimeStampDesde = new Date(this.state.ordenesDesde.replace(/-/g, '/')),
				fechaToTimeStampHasta = new Date(this.state.ordenesHasta.replace(/-/g, '/'))

		if(type === "ordenDesde"){
			this.setState({
				ordenesDesde: event.target.value
			})
			fechaToTimeStampDesde = new Date(event.target.value.replace(/-/g, '/'))
		}else if(type === "ordenHasta"){
			this.setState({
				ordenesHasta: event.target.value
			})
			fechaToTimeStampHasta = new Date(event.target.value.replace(/-/g, '/'))
		}

		let fechaDesdeTimestampString = fechaToTimeStampDesde.setHours(0,0,0,0),
				fechaHastaTimestampString = fechaToTimeStampHasta.setHours(23,59,59,0);
				
				let fechaDesdeString = new Date(parseInt(fechaDesdeTimestampString)),
						fechaHastaString = new Date(parseInt(fechaHastaTimestampString))
						
				//console.log(fechaDesdeString)
				//console.log(fechaHastaString)
		
		GraphQL(this, fechaDesdeString, fechaHastaString);

	}

	handleChangeEmpresa = selectedOptionEmpresas => {

    this.setState({
      selectedOptionEmpresas,
      selectedOptionUsuarios: null
    });

    //console.log(`Option selected:`, selectedOption);

  }

  handleChangeUsuario = selectedOptionUsuarios => {

    this.setState({
      selectedOptionUsuarios,
      selectedOptionEmpresas: null
    });

    //console.log(`Option selected:`, selectedOption);

  }

	render(){

		let arrayOfMensajeriaValues = [],
        allMensajeriaValuesFalse = true

		if(this.props.usuario !== null){
			if(this.props.usuario.permisos !== null){
				arrayOfMensajeriaValues = Object.values(this.props.usuario.permisos.Mensajeria)
				allMensajeriaValuesFalse = arrayOfMensajeriaValues.every(v => v === false)
			}
		}

		if (this.props.conexion === false) {
			return <Redirect to='/login' />
		}else if(this.props.usuario.permisos === null){
			return(
				<h1>No te Han Asignado Permisos</h1>
			)
		}else if( (this.props.usuario.permisos === null) || ((Object.entries(this.props.usuario.permisos.Mensajeria).length > 0) !== true) || (allMensajeriaValuesFalse === true) ){
			return <Redirect to='/dashboard' />
		}else if((this.props.usuario.permisos.Mensajeria.ViewOrden === true) || (this.props.usuario.permisos.Mensajeria.EditOrden === true) || (this.props.usuario.permisos.Mensajeria.DeleteOrden === true) || (this.props.usuario.permisos.Mensajeria.ActOrdenAdmin === true) || (this.props.usuario.permisos.Mensajeria.ActOrdenMuestra === true) || (this.props.usuario.permisos.Mensajeria.CheckListOrden === true)){

			let fechaDesde = new Date(this.state.ordenesDesde.replace(/-/g, '/')),
				fechaDesdeTimestamp = fechaDesde.setHours(0,0,0,0);
			let fechaHasta = new Date(this.state.ordenesHasta.replace(/-/g, '/')),
				fechaHastaTimestamp = fechaHasta.setHours(23,59,59,0);
			let fechaOrdenes = {
				ordenesDesde: fechaDesdeTimestamp,
				ordenesHasta: fechaHastaTimestamp
			}

			let SelectMensajeroArray = this.props.usuarios.filter( usuario => {
				return usuario.colaborador.puesto === 'Mensajero'
			})
	
			let SelectMensajero = Array.from(SelectMensajeroArray, usuario => {
				return { value: usuario, label: `${usuario.nombre} ${usuario.apellidos}` } //value: empresa.nombre_comercial
			})

			SelectMensajero.unshift({value: null, label: 'Ninguno'})

			let SelectEmpresa = Array.from(this.props.empresas, empresa => {
				return { value: empresa, label: empresa.nombre_comercial } //value: empresa.nombre_comercial
			})
	
			//let { selectedOptionUsuarios } = this.state;
	
			let SelectUsuarios = Array.from(this.props.usuarios, usuario => {
				return { value: usuario, label: `${usuario.nombre} ${usuario.apellidos}` } //value: empresa.nombre_comercial
			})

			SelectEmpresa.unshift({value: null, label: 'Ninguno'})
			SelectUsuarios.unshift({value: null, label: 'Ninguno'})

			return(
				<DashboardComponent>
					<div className="container-fluid" style={{minHeight: '78vh'}}>
						{
							( ((this.props.usuario.tipo === 'colaborador') && (this.props.usuario.colaborador.puesto === 'Mensajero')) ) ? null : (
								<form className="row" style={{marginBottom: '25px'}}>
									<div className="col">
										<label htmlFor="ordenesDesde">Ordenes desde:</label>
										<input id="ordenesDesde" name="ordenesDesde" className="col" type="date" value={this.state.ordenesDesde} 
										onChange={(event) => {
											let typeDate = 'ordenDesde';
											this.setDate(event,typeDate)
										}} />
									</div>
									<div className="col">
										<label htmlFor="ordenesHasta">Ordenes hasta:</label>
										<input id="ordenesHasta" name="ordenesHasta" className="col" type="date" value={this.state.ordenesHasta} 
										onChange={(event) => {
											let typeDate = 'ordenHasta';
											this.setDate(event,typeDate)
										}} />
									</div>
									<div className="col-12" style={{marginTop: '25px'}}>
										<div className="row">
											<label htmlFor="react-select-3-input" className="col-lg-6 col-12"><b>Selecciona un Mensajero</b></label>
											<Select
													className="col-lg-6 col-12"
													placeholder="Escoje un Mensajero"
													value={this.state.selectedOptionMensajeros}
													onChange={this.handleChangeMensajero}
													options={SelectMensajero}
											/>
										</div>
									</div>
									<div className="col-12" style={{marginTop: '25px'}}>
										<div className="row">
											<label htmlFor="selectEstado" className="col-lg-6 col-12"><b>Selecciona un Estado</b></label>
											<select name="selectEstado" id="selectEstado" onChange={this.handleSelectEstadoChange} className="col-lg-5 col-11 mx-auto">
												<option value="">Ninguno</option>
												<option value="Pendiente de Entrega">Pendiente de Entrega</option>
												<option value="Entregado a Mensajero">Entregado a Mensajero</option>
												<option value="Entregado en Secretaria">Entregado en Secretaria</option>
												<option value="Entregado a Laboratorio">Entregado a Laboratorio</option>
												<option value="Fuera de Condiciones">Fuera de Condiciones</option>
												<option value="Finalizado">Finalizado</option>
												<option value="Cancelado">Cancelado</option>
											</select>
										</div>
									</div>
									<div className="col-12" style={{marginTop: '25px'}}>
										<div className="row">
											<label htmlFor="numerodeOrdenFilter" className="col-lg-6 col-12"><b>Escribe un numero de orden</b></label>
											<input id="numerodeOrdenFilter" className="col-lg-5 col-11 mx-auto" type="text" value={this.state.numeroDeOrdenFilter} onChange={this.handleInputNumeroDeOrdenFilter} placeholder="Busca por Número de Orden" />
										</div>
									</div>

									<div className="col-12">

										<div className="row">

											<nav className="col-12 col-md-2 flex-md-column" style={{marginTop: '30px'}}>
													<div className="nav nav-pills nav-fill" id="nav-tab" role="tablist">
														<a className="nav-item nav-link active" id="nav-empresa-tab" data-toggle="tab" href="#nav-ord-empresa" role="tab" aria-controls="nav-ord-empresa" aria-selected="true" onClick={this.handleChangeEmpresaDatosPredefinidos}><FontAwesomeIcon icon="building" /> Orden Empresa</a>
														<a className="nav-item nav-link" id="nav-usuario-tab" data-toggle="tab" href="#nav-ord-usuario" role="tab" aria-controls="nav-ord-usuario" aria-selected="false" onClick={this.handleChangeUsuarioDatosPredefinidos}><FontAwesomeIcon icon="user" /> Orden Usuario</a>
													</div>
											</nav>

											<div className="col-12 col-md-10" style={{marginTop: '30px'}}>
												<div className="row">
													<div className="tab-content col-12 " id="nav-tabContent">

														<OrdenEmpresa active="active" selectedOptionEmpresas={this.state.selectedOptionEmpresas} SelectEmpresa={SelectEmpresa} handleChangeEmpresa={this.handleChangeEmpresa} />

														<OrdenUsuario selectedOptionUsuarios={this.state.selectedOptionUsuarios} SelectUsuarios={SelectUsuarios} handleChangeUsuario={this.handleChangeUsuario} />

													</div>
												</div>
											</div>

										</div>

									</div>


								</form>
							)
						}
						<MensajeriaTable setButtonViewUser={this.setButton} setButtonEditUser={this.setButton} solicitudesMensajeria={this.props.solicitudesMensajeria} handleOpenModal={this.handleOpenModal} usuarios={this.props.usuarios} empresas={this.props.empresas} usuario={this.props.usuario}
						handleDeleteOrdenMensajeria={this.handleDeleteOrdenMensajeria} fechaOrdenes={fechaOrdenes} mensajeroFilter={this.state.selectedOptionMensajeros} handleChangeEstadoSolicitud={this.handleChangeEstadoSolicitud} estadoFilter={this.state.selectedOptionEstado} empresaFilter={this.state.selectedOptionEmpresas} usuarioFilter={this.state.selectedOptionUsuarios} numeroDeOrdenFilter={this.state.numeroDeOrdenFilter}
						/>
					</div>
				</DashboardComponent>	
			)
		}else{
			return <Redirect to='/dashboard' />
		}
	}
}

let mapStateToProps = (state, props) => {

  return {
    conexion: state.logIn.conexion,
	usuario: state.logIn.usuario,
	solicitudesMensajeria: state.dataMensajeria.solicitudesMensajeria,
	usuarios: state.dataUsers.usuarios,
	empresas: state.dataEmpresas.empresas,
	suscribeGraphQL: state.suscribeGraphQL.suscribeGraphQL
  }
  
}

let mapDispatchToProps = (dispatch) => {
	return {
		dispatch,
		suscriptionOrdenes: (ordenes) => { 
			dispatch({
				type: 'FETCH_SOLICITUDES_MENSAJERIA',
				payload: {
					dataMensajeria: ordenes.data.newOrden
				}
		}) }
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserDashboard)
