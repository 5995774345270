/*Import Log In*/
import LogIn from '../../Dashboard/login/containers/LogIn.jsx';
/*Import Dashboard*/
import Dashboard from '../../Dashboard/dashboard/containers/Dashboard.jsx';
/*Import AdminUserDashboard*/
import AdminUserDashboard from '../../Dashboard/AdminUser/containers/AdminUserDashboard.jsx';
/*Import AdminUserDashboard*/
import AdminEmpresaDashboard from '../../Dashboard/AdminEmpresa/containers/AdminEmpresaDashboard.jsx';
/*Import MiCuenta*/
import MiCuentaDashboard from '../../Dashboard/MiCuenta/containers/MiCuentaDashboard.jsx';

const LOGIN = {
    component: LogIn,
    path: "/LogIn"
}

const DASHBOARD = {
    component: Dashboard,
    path: "/dashboard"
}

const DASHBOARD_ADMIN_USER = {
    component: AdminUserDashboard,
    path: "/dashboard/admin-user"
}

const DASHBOARD_ADMIN_EMPRESA = {
    component: AdminEmpresaDashboard,
    path: "/dashboard/admin-company"
}

const DASHBOARD_MI_CUENTA = {
    component: MiCuentaDashboard,
    path: "/dashboard/mi-cuenta"
}

let routesLogIn = [ LOGIN, DASHBOARD, DASHBOARD_ADMIN_USER, DASHBOARD_ADMIN_EMPRESA, DASHBOARD_MI_CUENTA ]

export default routesLogIn