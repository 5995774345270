let consulta = (etiquetaJSON) => {

  const string =
    `mutation insertAdminObjetivos{
      adminObjetivos{
        insertEtiqueta(nombre: "${etiquetaJSON.nombre}", tipoEvento: "${etiquetaJSON.tipoEvento}")
        {
          id
          mensaje
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
