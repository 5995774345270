let consulta = (tareaJSON) => {

  const string =
    `mutation {
      adminObjetivos {
        deleteTarea(idTarea: "${tareaJSON.id}"){
          id
          mensaje
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
