let HandleInsertInputChangeUsuario = (event, This) => {
  let value = event.target.value,
      usuario = event.target.dataset.usuario,
      type = event.target.dataset.valueType

  if((This.props.dataUsers.nuevoUsuario.id === JSON.parse(usuario).id) === true){

    if(type === 'usuario'){

      This.props.dataUsers.nuevoUsuario.usuario = value

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })

    }else if(type === 'password'){
      This.props.dataUsers.nuevoUsuario.password = value

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'nombre'){
      This.props.dataUsers.nuevoUsuario.nombre = value

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'apellidos'){
      This.props.dataUsers.nuevoUsuario.apellidos = value

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'telefono'){
      This.props.dataUsers.nuevoUsuario.telefono = value

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'celular'){
      This.props.dataUsers.nuevoUsuario.celular = value

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'email'){
      This.props.dataUsers.nuevoUsuario.email = value

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'DPI'){
      This.props.dataUsers.nuevoUsuario.DPI = value

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'fecha_de_nacimiento'){

      //var string = "1993-04-01";
      var d = Date.parse(value);
      var d2 = d.toString();
      var d3 = new Date(parseInt(d2));
      d3.setDate(d3.getDate() + 1)

      var d4 = Date.parse(d3);

      This.props.dataUsers.nuevoUsuario.fecha_de_nacimiento = d4

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'direccion'){
      This.props.dataUsers.nuevoUsuario.direccion = value

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'NIT'){
      This.props.dataUsers.nuevoUsuario.NIT = value

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'nota'){
      This.props.dataUsers.nuevoUsuario.nota = value

      This.props.dispatch({
        type:'NEW_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'sexo'){
      This.props.dataUsers.nuevoUsuario.sexo = value

      This.props.dispatch({
        type:'CHANGE_DATA_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'tipo'){

      This.props.dataUsers.nuevoUsuario.tipo = value

      let biolab = This.props.dataEmpresas.empresas.filter(empresa => empresa.nombre_comercial === 'Biolab')[0]

      if(value === "colaborador"){
        This.props.dataUsers.nuevoUsuario.empresa_asociada = {
          id: biolab.id,
          nombre: biolab.nombre_comercial,
          telefono: biolab.telefono,
          correo: biolab.correo
        }
      }else{
        This.props.dataUsers.nuevoUsuario.empresa_asociada = {
          id: null,
          nombre: null,
          telefono: null,
          correo: null
        }
        This.props.dataUsers.nuevoUsuario.colaborador.departamento = null
        This.props.dataUsers.nuevoUsuario.colaborador.puesto = null
      }

      This.props.dispatch({
        type:'CHANGE_DATA_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'permisos'){

      //console.log(event.target.options.length)

      var opts = [], opt;

      // loop through options in select list
      for (var i=0, len=event.target.options.length; i<len; i++) {

          opt = event.target.options[i];

          // check if selected
          if ( opt.selected ) {
              // add to array of option elements to return from this function
              opts.push(opt.value);

              // invoke optional callback function if provided
              /*if (fn) {
                  fn(opt);
              }*/
          }
      }

      //console.log(opts)

      // return array containing references to selected option elements
      This.props.dataUsers.nuevoUsuario.permisos = opts
      //return opts;

      //console.log(This.props.dataUsers.nuevoUsuario)

      This.props.dispatch({
        type:'CHANGE_DATA_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'EmpresaAsociada'){
      //obj.empresa_asociada.nombre = value

      //This.props.dataUsers.nuevoUsuario.tipo = value

      let empresa = This.props.dataEmpresas.empresas.filter( empresa => empresa.nombre_comercial === value )[0]

      if(empresa !== undefined){
        This.props.dataUsers.nuevoUsuario.empresa_asociada.id = empresa.id
  
        This.props.dataUsers.nuevoUsuario.empresa_asociada.nombre = empresa.nombre_comercial
  
        This.props.dataUsers.nuevoUsuario.empresa_asociada.telefono = empresa.telefono
  
        This.props.dataUsers.nuevoUsuario.empresa_asociada.correo = empresa.correo
      }

      //console.log(empresa[0].nombre_comercial)

      This.props.dispatch({
        type:'CHANGE_DATA_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'sucursal'){
      //obj.empresa_asociada.nombre = value

      //This.props.dataUsers.nuevoUsuario.tipo = value

      let sucursal = This.props.dataEmpresas.sucursales.filter( sucursal => sucursal.id === value )[0]

      if(sucursal !== undefined){
        This.props.dataUsers.nuevoUsuario.idSucursal = sucursal.id
      }else{
        This.props.dataUsers.nuevoUsuario.idSucursal = null
      }

      //console.log(empresa[0].nombre_comercial)

      This.props.dispatch({
        type:'CHANGE_DATA_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'Puesto'){

      if(value === ''){
        This.props.dataUsers.nuevoUsuario.colaborador.puesto = null
      }else{
        This.props.dataUsers.nuevoUsuario.colaborador.puesto = value
      }

      This.props.dispatch({
        type:'CHANGE_DATA_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }else if(type === 'Departamento'){

      if(value === ''){
        This.props.dataUsers.nuevoUsuario.colaborador.departamento = null
      }else{
        This.props.dataUsers.nuevoUsuario.colaborador.departamento = value
      }

      This.props.dispatch({
        type:'CHANGE_DATA_USER',
        payload: {
          dataUser: This.props.dataUsers.nuevoUsuario
        }
      })
    }

  }
}

export default HandleInsertInputChangeUsuario
