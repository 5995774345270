import React, { Component } from 'react';

import DashboardComponent from '../../../../../dashboard/components/DashboardComponent.jsx';

//import Select from 'react-select';

import GraphQL from '../../../../../../../apiService/FetchGraphQLPlantillasRutasMensajeria.js';

import TableVerPlantillasRutas from '../components/TableVerPlantillasRutas.jsx';

import GraphQLUsuarios from '../../../../../../../apiService/FetchGraphQLUsuarios.js';

import GraphQLEmpresas from '../../../../../../../apiService/FetchGraphQLEmpresas.js';

import FetchDeletePlantillaRuta from './DeletePlantillaRutaFetch/FetchDeletePlantillaRuta.js';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

class VerPlantillasRutas extends Component {

    constructor(props) {
        super(props);
        
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleDeletePlantillaRuta = this.handleDeletePlantillaRuta.bind(this);

	}

    componentDidMount(){

        //Codigo de redirect
		let arrayOfMensajeriaValues = [],
        allMensajeriaValuesFalse = true

		if(this.props.usuario !== null){
			if(this.props.usuario.permisos !== null){
				arrayOfMensajeriaValues = Object.values(this.props.usuario.permisos.Mensajeria)
				allMensajeriaValuesFalse = arrayOfMensajeriaValues.every(v => v === false)
			}
        }
        
        if( (this.props.conexion !== false) && (this.props.usuario.permisos !== null) && ((Object.entries(this.props.usuario.permisos.Mensajeria).length > 0) === true) && (allMensajeriaValuesFalse === false) && ( (this.props.usuario.permisos.Mensajeria.ViewPlantillaRuta === true) || (this.props.usuario.permisos.Mensajeria.EditPlantillaRuta === true) || (this.props.usuario.permisos.Mensajeria.DeletePlantillaRuta === true) ) ){

            GraphQLUsuarios(this);

            GraphQLEmpresas(this);
            
            GraphQL(this);

        }

    }

	handleOpenModal(modal, typeModal){
        this.props.dispatch({
            type: `OPEN_MODAL`,
            payload: {
                modal,
                typeModal
            }
        })
    }

    handleDeletePlantillaRuta(plantilla){
        return FetchDeletePlantillaRuta(this, plantilla)
    }

	render(){

    let arrayOfMensajeriaValues = [],
        allMensajeriaValuesFalse = true

    if(this.props.usuario !== null){
        if(this.props.usuario.permisos !== null){
            arrayOfMensajeriaValues = Object.values(this.props.usuario.permisos.Mensajeria)
            allMensajeriaValuesFalse = arrayOfMensajeriaValues.every(v => v === false)
        }
    }

	if (this.props.conexion === false) {
		return <Redirect to='/login' />
	}else if(this.props.usuario.permisos === null){
        return(
            <h1>No te Han Asignado Permisos</h1>
        )
    }else if( (this.props.usuario.permisos === null) || ((Object.entries(this.props.usuario.permisos.Mensajeria).length > 0) !== true) || (allMensajeriaValuesFalse === true) ){
        return <Redirect to='/dashboard' />
    }else if((this.props.usuario.permisos.Mensajeria.ViewPlantillaRuta === true) || (this.props.usuario.permisos.Mensajeria.EditPlantillaRuta === true) || (this.props.usuario.permisos.Mensajeria.DeletePlantillaRuta === true)){
        return(
            <DashboardComponent>
                <div className="container-fluid" style={{minHeight: '78vh'}}>

                    <TableVerPlantillasRutas usuarios={this.props.usuarios} plantillasRutasMensajeria={this.props.plantillasRutasMensajeria} handleOpenModal={this.handleOpenModal} handleDeletePlantillaRuta={this.handleDeletePlantillaRuta} fechaRutas={this.state} usuario={this.props.usuario} />

                </div>
            </DashboardComponent>
			)
		}
	}
	
}

let mapStateToProps = (state, props) => {
    return {
      conexion: state.logIn.conexion,
      usuario: state.logIn.usuario,
      plantillasRutasMensajeria: state.dataMensajeria.plantillasRutasMensajeria,
      usuarios: state.dataUsers.usuarios,
      empresas: state.dataEmpresas.empresas
    }
}
  
export default connect(mapStateToProps)(VerPlantillasRutas)