let consulta = (objetivoJSON) => {

  let idsInvolucrados = objetivoJSON.idsInvolucrados.toString()

  const string =
    `mutation insertAdminObjetivos{
      adminObjetivos{
        insertObjetivo(objetivoData: {
          nombre: "${objetivoJSON.nombre}",
          idCreador: ${objetivoJSON.idCreador},
          idSucursal: ${objetivoJSON.idSucursal},
          idsInvolucrados: [${idsInvolucrados}],
          fechaDeInicio: "${objetivoJSON.fechaDeInicio}",
          fechaFinalPlanificada: "${objetivoJSON.fechaFinalPlanificada}",
          estado: "${objetivoJSON.estado}",
          tipo: "${objetivoJSON.tipo}"
          nivelDeVisualizacion: "${objetivoJSON.nivelDeVisualizacion}",
          observaciones: "${objetivoJSON.observaciones}"
        })
        {
          id
          mensaje
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
