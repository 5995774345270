//import { gql } from "apollo-boost";

let NewMensajeriaOrdenSubscription = (This, fechaDesdeString, fechaHastaString) => {

    // use
    return (async () => {
        try {
            const res = await This.props.suscribeGraphQL({
            query: `
            subscription {
                newOrden(
                  ordenesDesde: "${fechaDesdeString}"
                  ordenesHasta: "${fechaHastaString}"
                ){
                  id
                  idEmpresa
                  idUsuario
                  idMensajero
                  instrucciones
                  cantidadDeMuestras
                  personaQueLlamo
                  telefono
                  direccionDeRecepcion
                  fechaDeCreacion
                  fechaDeRecoleccion
                  horaDeOrden
                  tipo
                  urgente
                  estado
                  razonDeCancelacion
                  checkList {
                    nombreQuienRevisa
                    fecha
                    cantidadDeMuestrasTruly
                    observaciones
                  }
                  notasDeOrden
                  personaQueDejaLaMuestra
                  telefonoDeQueDejaLaMuestra
                  correoDeQueDejaLaMuestra
                }
              }
            `,
            }, This.props.suscriptionOrdenes);

            return res

            // complete
            //next = result = { data: { hello: 'Hello World!' } }
        } catch (err) {
            // error
            console.log(err)
        }
    })();
    
}

export default NewMensajeriaOrdenSubscription