import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Select from 'react-select';

import { formatterTwoDecimals } from '../../../../MyModules/formatterDecimals';

import FetchEmpresas from '../../../../apiService/FetchGraphQLEmpresas';

import FetchUsuarios from '../../../../apiService/FetchGraphQLUsuarios';

import FetchDeleteOrdenCompra from '../Containers/FetchDeleteOrdenCompra/FetchDeleteOrdenCompraPromise';

import FetchOrdenesCompra from './FetchGetOrdenesCompra/FetchGraphQLGetOrdenesCompra';

import DashboardComponent from '../../dashboard/components/DashboardComponent.jsx';

class ListOrdenesCompra extends Component {

    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onInputProveedorChange = this.onInputProveedorChange.bind(this);
        this.onInputTextProveedorChange = this.onInputTextProveedorChange.bind(this);
        this.onClickResetProveedor = this.onClickResetProveedor.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleDeleteOrdenCompra = this.handleDeleteOrdenCompra.bind(this)
        this.onInputCreadorChange = this.onInputCreadorChange.bind(this);
        this.onInputTextCreadorChange = this.onInputTextCreadorChange.bind(this);
        this.onClickResetCreador = this.onClickResetCreador.bind(this);
    }

    componentDidMount(){

        let fecha = new Date(),
        year = fecha.getFullYear()
        let mes
        (fecha.getMonth()+1) < 10 ? mes = `0${fecha.getMonth()+1}` : mes = `${fecha.getMonth()+1}`
        //let dia
        //fecha.getDate() < 10 ? dia = `0${fecha.getDate()}` : dia = `${fecha.getDate()}`

        let fechaInicio = new Date(),
            fechaFinal = new Date(new Date(fecha.getFullYear(), fecha.getMonth()+1, 0))

        let diaFinal
            fechaFinal.getDate() < 10 ? diaFinal = `0${fechaFinal.getDate()}` : diaFinal = `${fechaFinal.getDate()}`

        let fechaInicioTimestampString = new Date(parseInt(fechaInicio.setDate(1))).setHours(0,0,0,0).toString(),
            fechaFinalTimestampString = fechaFinal.setHours(23,59,59,0).toString(),
            fechaInicioString = new Date(parseInt(fechaInicioTimestampString)),
            fechaFinalString = new Date(parseInt(fechaFinalTimestampString))

        if(this.props.filter.fechaFinal === "" && this.props.filter.fechaInicio === ""){
            this.props.dispatch({
                type: 'FILTER_ORDENES_COMPRA',
                payload: {
                    filter: {
                        ...this.props.filter,
                        fechaInicio: `${year}-${mes}-01`,
                        fechaFinal: `${year}-${mes}-${diaFinal}`
                    }
                }
            })
        }

        FetchOrdenesCompra(this, fechaInicioString, fechaFinalString)
        FetchEmpresas(this)
        FetchUsuarios(this)
    
    }

    handleInputChange(event){

        if(event.target.id === "fechaInicio"){

            this.props.dispatch({
                type: 'FILTER_ORDENES_COMPRA',
                payload: {
                    filter: {
                        ...this.props.filter,
                        fechaInicio:  event.target.value
                    }
                }
            })
      
            let fechaInicioTimeStamp = new Date(event.target.value.replace(/-/g, '/')),
                fechaFinalTimeStamp = new Date(this.props.filter.fechaFinal.replace(/-/g, '/')),
                fechaInicioTimestampString = fechaInicioTimeStamp.setHours(0,0,0,0),
                fechaFinalTimestampString = fechaFinalTimeStamp.setHours(23,59,59,0),
                fechaInicioString = new Date(parseInt(fechaInicioTimestampString)),
                fechaFinalString = new Date(parseInt(fechaFinalTimestampString))
            
            FetchOrdenesCompra(this, fechaInicioString, fechaFinalString)

        }else if(event.target.id === "fechaFinal"){

            this.props.dispatch({
                type: 'FILTER_ORDENES_COMPRA',
                payload: {
                    filter: {
                        ...this.props.filter,
                        fechaFinal:  event.target.value
                    }
                }
            })
      
            let fechaInicioTimeStamp = new Date(this.props.filter.fechaInicio.replace(/-/g, '/')),
                fechaFinalTimeStamp = new Date(event.target.value.replace(/-/g, '/')),
                fechaInicioTimestampString = fechaInicioTimeStamp.setHours(0,0,0,0),
                fechaFinalTimestampString = fechaFinalTimeStamp.setHours(23,59,59,0),
                fechaInicioString = new Date(parseInt(fechaInicioTimestampString)),
                fechaFinalString = new Date(parseInt(fechaFinalTimestampString))
            
            FetchOrdenesCompra(this, fechaInicioString, fechaFinalString)

        }else{
            this.props.dispatch({
                type: 'FILTER_ORDENES_COMPRA',
                payload: {
                    filter: {
                        ...this.props.filter,
                        [event.target.id]: event.target.value
                    }
                }
            })
        }

    }

    onInputProveedorChange(selectObj){
        this.props.dispatch({
            type: 'FILTER_ORDENES_COMPRA',
            payload: {
                filter: {
                    ...this.props.filter,
                    proveedor: selectObj.label,
                    valProveedor: selectObj
                }
            }
        })

    }

    onInputCreadorChange(selectObj){
        this.props.dispatch({
            type: 'FILTER_ORDENES_COMPRA',
            payload: {
                filter: {
                    ...this.props.filter,
                    creador: selectObj.label,
                    valCreador: selectObj
                }
            }
        })

    }

    onInputTextProveedorChange(value){
        this.props.dispatch({
            type: 'FILTER_ORDENES_COMPRA',
            payload: {
                filter: {
                    ...this.props.filter,
                    proveedor: value,
                    valProveedor: this.props.filter.proveedor === "" ? null : {value, label: value}
                }
            }
        })
            
    }

    onInputTextCreadorChange(value){
        this.props.dispatch({
            type: 'FILTER_ORDENES_COMPRA',
            payload: {
                filter: {
                    ...this.props.filter,
                    creador: value,
                    valCreador: this.props.filter.creador === "" ? null : {value, label: value}
                }
            }
        })
            
    }

    onClickResetProveedor(){
        this.props.dispatch({
            type: 'FILTER_ORDENES_COMPRA',
            payload: {
                filter: {
                    ...this.props.filter,
                    proveedor: "",
                    valProveedor: null
                }
            }
        })

    }

    onClickResetCreador(){
        this.props.dispatch({
            type: 'FILTER_ORDENES_COMPRA',
            payload: {
                filter: {
                    ...this.props.filter,
                    creador: "",
                    valCreador: null
                }
            }
        })

    }

    handleOpenModal(modal, typeModal){
    	this.props.dispatch({
			type: `OPEN_MODAL`,
			payload: {
				modal,
				typeModal
			}
		})
    }

    handleDeleteOrdenCompra(orden){
        return FetchDeleteOrdenCompra(this, orden)
    }

    render(){

        let selectProveedores  = Array.from(this.props.empresas.filter( empresa => empresa.clasificacion_empresa.includes('Proveedor') ), empresa => {
            return { value: empresa.id, label: `${empresa.nombre_comercial}` }
        })

        let colaboradores = this.props.usuarios.filter( usuario => usuario.tipo === 'colaborador'),
            selectColaboradores =  Array.from(colaboradores, colaborador => ({ value: colaborador.id, label: `${colaborador.nombre} ${colaborador.apellidos}` }))


        let ordenesProveedorFilter = this.props.filter.proveedor === "" ? this.props.ordenes : this.props.ordenes.filter( orden => {
            
            let proveedorIdFilter = selectProveedores.filter( proveedor => proveedor.label === this.props.filter.proveedor )[0]

            return proveedorIdFilter !== undefined ? orden.idProveedor === proveedorIdFilter.value : orden.proveedor !== null ? orden.proveedor.toLocaleLowerCase().includes(this.props.filter.proveedor.toLocaleLowerCase()) : null

        })

        let ordenesCreadorFilter = this.props.filter.creador === "" ? ordenesProveedorFilter : ordenesProveedorFilter.filter( orden => {

            let creadorIdFilter = selectColaboradores.filter( creador => creador.label === this.props.filter.creador )[0]

            return creadorIdFilter !== undefined ? orden.idCreador === creadorIdFilter.value : orden.creador === this.props.filter.creador

        })

        let ordenesIdFilter = this.props.filter.idOrden === "" ? ordenesCreadorFilter : ordenesCreadorFilter.filter( orden => orden.id.includes(this.props.filter.idOrden))

        let ordenes = ordenesIdFilter


        return(
            <DashboardComponent >
                <div className="row mx-0">
                    <div className="col-12">
                        <h1 className="text-center">Ver Ordenes de Compra</h1>
                    </div>
                    <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
                        <label htmlFor="fechaInicio">Fecha de Inicio</label>
                        <input id="fechaInicio" className="form-control" type="date" value={this.props.filter.fechaInicio} onChange={this.handleInputChange}/>
                    </div>

                    <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
                        <label htmlFor="fechaFinal">Fecha Final</label>
                        <input id="fechaFinal" className="form-control" type="date" value={this.props.filter.fechaFinal} onChange={this.handleInputChange}/>
                    </div>

                    <div className="form-group col-12 col-md-4 col-lg-3 mx-auto text-center">
                        <label htmlFor="proveedor" className="col"><b>Proveedor:</b></label>
                        <Select id="proveedor" 
                            options={selectProveedores} 
                            onChange={this.onInputProveedorChange}
                            onInputChange={this.onInputTextProveedorChange}
                            placeholder="Busca un Proveedor"
                            value={this.props.filter.valProveedor}
                            onMenuClose={() => this.props.dispatch({
                                type: 'FILTER_ORDENES_COMPRA',
                                payload: {
                                    filter: {
                                        ...this.props.filter
                                    }
                                }
                            })}
                        />
                        <button type="button" className="mt-2 btn btn-outline-info" onClick={this.onClickResetProveedor}>Limpiar Busqueda</button>
                        <small id="proveedorSmall" className="form-text text-muted">Proveedor.</small>
                    </div>

                    <div className="form-group col-12 col-md-6 mx-auto text-center">
                        <label htmlFor="creador" className="col"><b>Creador:</b></label>
                        <Select id="creador" 
                            options={selectColaboradores} 
                            onChange={this.onInputCreadorChange}
                            onInputChange={this.onInputTextCreadorChange}
                            placeholder="Busca un Creador"
                            value={this.props.filter.valCreador}
                            onMenuClose={() => this.props.dispatch({
                                type: 'FILTER_ORDENES_COMPRA',
                                payload: {
                                    filter: {
                                        ...this.props.filter
                                    }
                                }
                            })}
                        />
                        <button type="button" className="mt-2 btn btn-outline-info" onClick={this.onClickResetCreador}>Limpiar Busqueda</button>
                        <small id="creadorSmall" className="form-text text-muted">Creador.</small>
                    </div>

                    <div className="form-group col-12 col-md-6 col-lg-4 mx-auto">
                        <label htmlFor="idOrden">No. de Orden Compra</label>
                        <input id="idOrden" className="form-control" type="text" value={this.props.filter.idOrden} onChange={this.handleInputChange}/>
                    </div>

                    <div className="col-12">
                    {
                        ordenes.length > 0 ? (
                            <table className="table text-center table-bordered table-hover table-sm">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col" className="d-none d-md-table-cell">No. Abreviado</th>
                                        <th scope="col">Proveedor</th>
                                        <th scope="col" className="d-none d-md-table-cell">Creador</th>
                                        <th scope="col">Estado de Orden</th>
                                        <th scope="col">Se envió por correo</th>
                                        <th scope="col">Valor Total</th>
                                        <th scope="col">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    ordenes.map( orden => {

                                        //console.log(orden)

                                        let productosOfFacturasOrden = []

                                        let creador = this.props.usuarios.filter( usuario => usuario.id === orden.idCreador)[0]

                                        //console.log(creador)

                                        if(orden.facturas !== null){
                                            let productosOfFacturasOrdenArr = orden.facturas.map( factura => factura.productos)
                                            productosOfFacturasOrden = Array.prototype.concat.apply([], productosOfFacturasOrdenArr)
                                        }

                                        let valoresTotalesDeProducto = orden.productos.map( producto => producto.cantidad * producto.valorUnitario)
                                        let reducer = (accumulator, currentValue) => accumulator + currentValue
                                        let valorTotal 
                                        
                                        if(valoresTotalesDeProducto.length > 0){
                                            valorTotal= valoresTotalesDeProducto.reduce(reducer)
                                        }


                                        let colorFondo;

                                        if(orden.estado === 'Pendiente de Autorizacion'){
                                            colorFondo = 'transparent'
                                        }else if((orden.estado === 'Autorizado') && ((productosOfFacturasOrden.length !== 0) && (productosOfFacturasOrden.length < orden.productos.length))){
                                            colorFondo = '#FCB656'
                                        }else if((orden.estado === 'Autorizado') && (orden.passPago === null)){
                                            colorFondo = '#50D0FF'
                                        }else if(orden.estado === 'No Autorizado'){
                                            colorFondo = '#EB8484'
                                        }else if((orden.estado === 'Autorizado') && ((orden.passPago !== null) && (orden.passPago.estado === 'No Pagado'))){
                                            colorFondo = '#FFF4A3'
                                        }else if((orden.estado === 'Autorizado') && ((orden.passPago !== null) && (orden.passPago.estado === 'Pagado'))){
                                            colorFondo = '#9BEBA9'
                                        }else if(orden.estado === 'Fuera De Condiciones'){
                                            colorFondo = '#EB8484'
                                        }

                                        if(this.props.empresas.length > 0){
                                            
                                            let proveedor = orden.proveedor === null ? 
                                                this.props.empresas.filter( empresa => empresa.id === orden.idProveedor)[0] === undefined ? null : this.props.empresas.filter( empresa => empresa.id === orden.idProveedor)[0].nombre_comercial
                                                : orden.proveedor

                                            let idAbreviado = orden.id.substr(orden.id.length - 6);
    
                                            return (
                                                <tr key={orden.id} style={{backgroundColor: colorFondo}}>
                                                    <td className="d-none d-md-table-cell">{idAbreviado}</td>
                                                    <td>{proveedor}</td>
                                                    <td className="d-none d-md-table-cell">{ ((creador === undefined) || (creador === null)) ? <b>No tiene Creador Registrado</b>  : `${creador.nombre} ${creador.apellidos}`}</td>
                                                    <td>{orden.estado} { ((orden.estado === 'Autorizado') && ((orden.passPago !== null) && (orden.passPago.estado === 'Pagado'))) ? 'Con Contraseña Pagada' : null } { ((orden.estado === 'Autorizado') && ((orden.passPago !== null) && (orden.passPago.estado === 'No Pagado'))) ? 'Con Contraseña No Pagada' : null } {((orden.estado === 'Autorizado') && ((productosOfFacturasOrden.length !== 0) && (productosOfFacturasOrden.length < orden.productos.length))) ? 'Con Entrega Parcial' : null} { ((orden.idUsuarioRecepcion !== null) && (orden.idUsuarioArea === null)) ? `y Recibido en Recepcion` : null } { ((orden.idUsuarioRecepcion !== null) && (orden.idUsuarioArea !== null)) ? `y Recibido en Area` : null }</td>
                                                    <td>{orden.emailTracking !== null ? (
                                                        <>
                                                            {orden.emailTracking.estado === "success" ? <FontAwesomeIcon icon="check" style={{ color: '#77FF34', marginLeft: '5px' }} /> : <FontAwesomeIcon icon="times" style={{ color: '#FF2304', marginLeft: '5px' }} />}
                                                        </>
                                                    ) : "No se ha enviado por correo" }</td>
                                                    <td>Q. {formatterTwoDecimals.format(valorTotal)}</td>
                                                    <td>

                                                        <Link to={`/dashboard/admin-compras/orden-compra/${orden.id}`}>
                                                            <button type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                                                                <FontAwesomeIcon icon="eye" />
                                                            </button>
                                                        </Link>

                                                        {
                                                            ((this.props.usuario.colaborador !== null) && (this.props.usuario.colaborador.puesto.includes('Director'))) ? (
                                                                <button onClick={ () => {

                                                                    let typeModal = 'DeleteQuestion';

                                                                    let data = {
                                                                        DeleteFunction: this.handleDeleteOrdenCompra,
                                                                        data: orden
                                                                    }

                                                                    this.handleOpenModal(data, typeModal)

                                                                    }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                                                                    <FontAwesomeIcon icon="trash-alt" />

                                                                </button>
                                                            ) : null
                                                        }


                                                    </td>
                                                </tr>
                                            )
                                        }else{
                                            return null
                                        }
                                    })
                                }
                                </tbody>
                            </table>
                        ) : (
                            <h2 className="text-center">No hay Ordenes que Mostrar</h2>
                        ) 
                    }
                    </div>
                </div>
            </DashboardComponent>
        )
    }
}

let mapStateToProps = (state, props) => {
    return {
      usuario: state.logIn.usuario,
      empresas: state.dataEmpresas.empresas,
      usuarios: state.dataUsers.usuarios,
      filter: state.dataOrdenesCompra.filter,
      ordenes: state.dataOrdenesCompra.ordenes
    }
}

export default connect(mapStateToProps)(ListOrdenesCompra)