import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let TableVerRutas = (props) => {

    return(
        <div className="table-responsive">
            <table className="table text-center table-bordered table-hover table-sm">
                <thead className="thead-light">
                <tr>
                    <th scope="col" className="d-none d-md-table-cell" >Id de Ruta</th>
                    <th scope="col">Nombre de Ruta</th>
                    <th scope="col" className="d-none d-sm-table-cell" >Estado de Ruta</th>
                    <th scope="col" className="d-none d-sm-table-cell" >Mensajero</th>
                    <th scope="col">Acciones</th>
                </tr>
                </thead>
                <tbody>
                {
                props.rutasMensajeria.map(rutaMensajeria => {

                    /*let fechaDeRuta = new Date(parseInt(rutaMensajeria.fecha))
        
                    //Creando la fecha
                    let dia
                    (fechaDeRuta.getDate()) < 10 ? dia = `0${fechaDeRuta.getDate()}` : dia = `${fechaDeRuta.getDate()}`
                    let mes
                    (fechaDeRuta.getMonth() + 1) < 10 ? mes = `0${fechaDeRuta.getMonth() + 1}` : mes = `${fechaDeRuta.getMonth() + 1}`
                    let year = fechaDeRuta.getFullYear()
                    //Fecha
                    let fecha = `${year}-${mes}-${dia}`
                    //Creando Hora
                    let hora
                    (fechaDeRuta.getHours()) < 10 ? hora = `0${fechaDeRuta.getHours()}` : hora = `${fechaDeRuta.getHours()}`
                    let minutos
                    (fechaDeRuta.getMinutes()) < 10 ? minutos = `0${fechaDeRuta.getMinutes()}` : minutos = `${fechaDeRuta.getMinutes()}`
                    //Hora
                    let time = `${hora}:${minutos}`*/

                    let colorFondo = 'transparent';

                    if(rutaMensajeria.estado === 'En proceso'){
                        colorFondo = 'transparent'
                    }else if(rutaMensajeria.estado === 'Validado'){
                        colorFondo = '#50D0FF'
                    }else if(rutaMensajeria.estado === 'Validado Fuera de Condiciones'){
                        colorFondo = '#FFB376'
                    }else if(rutaMensajeria.estado === 'Finalizado con Condiciones Inadecuadas'){
                        colorFondo = '#FFB376'
                    }else if(rutaMensajeria.estado === 'Finalizado'){
                        colorFondo = '#9BEBA9'
                    }

                    let mensajero = props.usuarios.filter( mensajero => {
                        return mensajero.id === rutaMensajeria.idMensajero
                    })[0]

                    if(rutaMensajeria.estado !== ('Finalizado' || 'En proceso' || 'Finalizado con Condiciones Inadecuadas')){
                        props.handleFinalizarRuta(rutaMensajeria)
                    }
        
                    return (
                    <tr key={rutaMensajeria.id} style={{backgroundColor: colorFondo}}>
                        {/*<th scope="row">{usuario.nombre}</th>*/}
                        <td className="d-none d-md-table-cell">{rutaMensajeria.id}</td>
                        <td>{rutaMensajeria.nombreRuta}</td>
                        <td className="d-none d-sm-table-cell">{rutaMensajeria.estado}</td>
                        <td className="d-none d-sm-table-cell">{mensajero.nombre} {mensajero.apellidos}</td>
                        <td>

                            {
                                props.usuario.permisos.Mensajeria.ViewRuta === true ? (
                                    <button type="button" onClick={() => {
                                        let typeModal = 'rutaMensariaModalView';
                                        props.handleOpenModal(rutaMensajeria, typeModal)
                                    }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                                        <FontAwesomeIcon icon="eye" />
                                    </button>
                                ) : null
                            }

                            {
                               props.usuario.permisos.Mensajeria.EditRuta === true ? (
                                    <button type="button" onClick={() => {
                                        let typeModal = 'rutaMensjeriaModalEdit';
                                        rutaMensajeria.fechaRutas = props.fechaRutas
                                        props.handleOpenModal(rutaMensajeria, typeModal)
                                    }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                                        <FontAwesomeIcon icon="edit" />
                                    </button>
                               ) : null
                            }


                            {
                                props.usuario.permisos.Mensajeria.DeleteRuta === true ? (
                                    <button onClick={ () => {

                                        let typeModal = 'DeleteQuestion';

                                        let data = {
                                            DeleteFunction: props.handleDeleteRuta,
                                            data: rutaMensajeria
                                        }
            
                                        props.handleOpenModal(data, typeModal)

                                    }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                                        <FontAwesomeIcon icon="trash-alt" />
                                    </button>
                                ) : null
                            }

                        </td>
                    </tr>
                    )
                })
                }
            </tbody>
          </table>
        </div>
    )
}

export default TableVerRutas