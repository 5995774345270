import React from 'react';

// Core modules imports are same as usual
import SwiperCore, { Navigation, Pagination } from 'swiper';
// Direct React component imports
// import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Swiper, SwiperSlide } from "swiper/react";

// Styles must use direct files imports
import 'swiper/swiper-bundle.min.css';
//import 'swiper/modules/navigation/navigation.min.css'; // Navigation module
//import 'swiper/modules/pagination/pagination.min.css'; // Pagination module
import './css/Galeria.css';

interface IOptions {
    spaceBetween: number
    slidesPerView: number
    pagination: boolean
    navigation: boolean
    loop: boolean
}

interface IUrl {
    img: string
    alt: string
}

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

const Galeria = ({ urlImgs, texts, options, RenderComponent, styleSwiperContainer }: { urlImgs?: IUrl[], texts?: string[], options: IOptions, RenderComponent: React.FunctionComponent, styleSwiperContainer: React.CSSProperties }) => {

    const { spaceBetween, slidesPerView, pagination, navigation, loop } = options

    return(
        <div style={styleSwiperContainer}>
            <Swiper
                spaceBetween={spaceBetween}
                slidesPerView={slidesPerView}
                pagination={pagination}
                navigation={navigation}
                loop={loop}
                //onSlideChange={() => console.log('slide change')}
                //onSwiper={(swiper) => console.log(swiper)}
                >
                    
                {
                    texts?.map( (text: string) => (
                        <SwiperSlide key={`Swiper-Slide-${text}`}>
                            <RenderComponent>
                                {text}
                            </RenderComponent>
                        </SwiperSlide>
                    ))
                }

                {
                    urlImgs?.map( ({ img, alt }) => (
                        <SwiperSlide key={`Swiper-Slide-${img}`}>
                            <RenderComponent>
                                <img src={img} alt={alt} className="center-block img-fluid mx-auto" style={{width: '100vw'}}/>
                            </RenderComponent>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    )


}

export default Galeria