let consulta = (orden) => {

  let string;
    
  if(orden.estado === "Entregado en Secretaria"){
    string = `mutation insertSolicitudDeAnalisis {
      mensajeriaMutation {
        insertSolisitudDeAnalisis(insertSolisitudDeAnalisis: {
          idCreador: "${orden.idCreador}",
          notasDeOrden: "${orden.notasDeOrden}",
          personaQueDejaLaMuestra: "${orden.personaQueDejaLaMuestra}",
          telefonoDeQueDejaLaMuestra: "${orden.telefonoDeQueDejaLaMuestra}",
          correoDeQueDejaLaMuestra: "${orden.correoDeQueDejaLaMuestra}",
          cantidadDeMuestras: ${orden.cantidadDeMuestras}
          estado: "${orden.estado}",
          tipo: "${orden.tipo}",
        })
        {
          id
          mensaje
        }
      }
    }`
  }else if(orden.idEmpresa !== null){
    string = `mutation insertSolicitudDeAnalisis {
      mensajeriaMutation {
        insertSolisitudDeAnalisis(insertSolisitudDeAnalisis: {
          idEmpresa: "${orden.idEmpresa}",
          idSucursal: ${orden.idSucursal},
          idMensajero: "${orden.idMensajero}",
          idCreador: "${orden.idCreador}",
          instrucciones: "${orden.instrucciones}",
          personaQueLlamo: "${orden.personaQueLlamo}",
          telefono: "${orden.telefono}",
          direccionDeRecepcion: "${orden.direccionDeRecepcion}",
          fechaDeRecoleccion: "${orden.fechaDeRecoleccion}",
          tipo: "${orden.tipo}",
          urgente: ${orden.urgente}
        })
        {
          id
          mensaje
        }
      }
    }`
  }else if(orden.idUsuario !== null){
    string = `mutation insertSolicitudDeAnalisis {
      mensajeriaMutation {
        insertSolisitudDeAnalisis(insertSolisitudDeAnalisis: {
          idUsuario: "${orden.idUsuario}",
          idMensajero: "${orden.idMensajero}",
          idCreador: "${orden.idCreador}",
          instrucciones: "${orden.instrucciones}",
          personaQueLlamo: "${orden.personaQueLlamo}",
          telefono: "${orden.telefono}",
          direccionDeRecepcion: "${orden.direccionDeRecepcion}",
          fechaDeRecoleccion: "${orden.fechaDeRecoleccion}",
          tipo: "${orden.tipo}",
          urgente: ${orden.urgente}
        })
        {
          id
          mensaje
        }
      }
    }`
  }

  return {
    "query": string
  }

}

export default consulta;
