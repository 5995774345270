import React from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let BotonAdminUI = props => {
  if(props.usuario.permisos.AdminUI === true) {
    return(
      <Link to="/">
        <button className="BotonDashboard">
          <div className="contenedorIcono">
            <FontAwesomeIcon icon="edit" />
          </div>
          <span className="nav-link-text">Plataforma Autogestionable</span>
        </button>
      </Link>
    )
  }else{
    return null
  }
}

export default BotonAdminUI
