let consulta = (sucursalJSON) => {

  //console.log(usuarioJSON.empresa_asociada)

  const string =
    `mutation InsertSucursal {
      empresaMutation{
        insertSucursal(insertSucursal: {
          nombre_comercial: "${sucursalJSON.nombreComercial}",
          telefonos: "${sucursalJSON.telefonos}",
          correo: "${sucursalJSON.correo}",
          direccion: "${sucursalJSON.direccion}",
          convenio: "${sucursalJSON.convenio}",
          FK_empresa: "${sucursalJSON.FK_empresa}",
          FK_contacto_directo_usuario: "${sucursalJSON.FK_contacto_directo_usuario}"
        }){
          mensaje
          sucursal{
            id
            nombre_comercial
            telefonos
            correo
            direccion
            convenio
            id_empresa
            id_contacto_directo
          }
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
