import React, { useState } from 'react';

import Toggle from 'react-toggle';

let CheckList = props => {

    let [state, setState ] = useState({
        valorDelProducto: true,
        integridadDeEmpaque: true,
        temperaturaDeTransporte: 'Refrigerada',
        observacionDeRecibido: ''
    })

    let handleChangeValorDelProducto = () => {
        setState({
            ...state,
            valorDelProducto: !state.valorDelProducto
        })
    }

    let handleChangeIntegridadDeEmpaque = () => {
        setState({
            ...state,
            integridadDeEmpaque: !state.integridadDeEmpaque
        })
    }

    let handleInputChange = event => {
        let id = event.target.id.replace(/-.*$/g, '')
        setState({
            ...state,
            [id]: event.target.value
        })
    }

    return(
        <div className="col-12 mx-auto">
             <div className="mx-auto d-none-print">

                    {
                        ((props.producto.tipo === 'Insumo de Laboratorio') || (props.producto.tipo === 'Producto General') || (props.producto.tipo === 'Servicio')) ? (
                            <div className="form-group" style={{marginTop: '15px'}}>
                                <div className="row">
                                    <p className="col-12 text-center"><b>Valor del Producto:</b></p>
                                    <label className="mx-auto">
                                        <Toggle
                                        id={`valorDelProducto-${props.producto.nombre.replace(/\s+/g, '').replace(/[^\w\s]+/g, '')}`}
                                        defaultChecked={state.valorDelProducto}
                                        onChange={handleChangeValorDelProducto} />
                                    </label>
                                </div>
                            </div>
                        ) : null
                    }

                    {
                        ((props.producto.tipo === 'Insumo de Laboratorio') || (props.producto.tipo === 'Producto General')) ? (
                            <div className="form-group" style={{marginTop: '15px'}}>
                                <div className="row">
                                    <p className="col-12 text-center"><b>Integirdad del Empaque:</b></p>
                                    <label className="mx-auto">
                                        <Toggle
                                        id={`integridadDeEmpaque-${props.producto.nombre.replace(/\s+/g, '').replace(/[^\w\s]+/g, '')}`}
                                        defaultChecked={state.integridadDeEmpaque}
                                        onChange={handleChangeIntegridadDeEmpaque} />
                                    </label>
                                </div>
                            </div>
                        ) : null
                    }

                    {
                        ((props.producto.tipo === 'Insumo de Laboratorio')) ? (
                            <div className="form-group" style={{marginTop: '15px'}}>
                                <div className="row">
                                    <div className="mx-auto">
                                        <label><b>Temperatura de Transporte: </b></label>
                                        <select id={`temperaturaDeTransporte-${props.producto.nombre.replace(/\s+/g, '').replace(/[^\w\s]+/g, '')}`} value={state.temperaturaDeTransporte} onChange={handleInputChange} className="form-control">
                                            <option value="Refrigerada">Refrigerada</option>
                                            <option value="Temperatura Ambiente">Temperatura Ambiente</option>
                                            <option value="Congelado">Congelado</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }

                    {
                        ((props.producto.tipo === 'Insumo de Laboratorio') || (props.producto.tipo === 'Producto General') || (props.producto.tipo === 'Servicio')) ? (
                            <div className="form-group" style={{marginTop: '15px'}}>
                                <div className="row">
                                    <div className="mx-auto">
                                        <label><b>Observaciones: </b></label>
                                        <input id={`observacionDeRecibido-${props.producto.nombre.replace(/\s+/g, '').replace(/[^\w\s]+/g, '')}`} type="text" value={state.observacionDeRecibido} onChange={handleInputChange} className="form-control" />
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }

                    <div id={`mensajeObservacion-${props.producto.nombre.replace(/\s+/g, '').replace(/[^\w\s]+/g, '')}`} className="alert alert-info text-center d-none" role="alert">
                        Debes Agregar una Observación
                    </div>

                    {
                        props.producto.checkList === null ? (
                            <button className="btn btn-outline-info mx-auto" onClick={() => {
        
                                let checkList = state

                                let mensajeObservacion = document.querySelector(`#mensajeObservacion-${props.producto.nombre.replace(/\s+/g, '').replace(/[^\w\s]+/g, '')}`)
        
                                if(props.producto.tipo === 'Servicio'){
                                   delete checkList['integridadDeEmpaque']
                                   delete checkList['temperaturaDeTransporte']
                                }else if(props.producto.tipo === 'Producto General'){
                                    delete checkList['temperaturaDeTransporte']
                                }

                                if((checkList.integridadDeEmpaque !== undefined) && (checkList.integridadDeEmpaque === false) && (checkList.observacionDeRecibido === '')){

                                    mensajeObservacion.classList.remove('d-none')

                                    setTimeout( () => { mensajeObservacion.classList.add('d-none') }, 3000);

                                }else if((checkList.valorDelProducto !== undefined) && (checkList.valorDelProducto === false) && (checkList.observacionDeRecibido === '')){
                                    mensajeObservacion.classList.remove('d-none')

                                    setTimeout( () => { mensajeObservacion.classList.add('d-none') }, 3000);
                                }else{
                                    props.handleCheckListProducto(props.producto.nombre, checkList)
                                }
        
                                
                            }}>Validar Check List</button>
                        ) : null
                    }

                    

                </div>
        </div>
    )

} 

export default CheckList