import GraphQL from './ConsultaGraphQL';

import url from '../../../../../../apiService/ApiUrl.js';

// JavaScript Document
function handleError(err, This){
	console.log(`Request failed: ${err}`);
	This.setState({
		updateOrdenMensajeriaMensaje: {
			response: 'failure',
			mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
		}
	})
}

const updateEmpresa = (This, ordenJSON) => {
	//console.log(usuarioJSON)
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(ordenJSON)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {
		//console.log(res)
		//return res
		This.setState({
			updateOrdenMensajeriaMensaje: {
				response: 'success',
				mensaje: res.data.mensajeriaMutation.updateSolisitudDeAnalisis.mensaje
			}
		})
		return res
	})
	.catch(err => handleError(err, This));

	//termina fetch
}

export default updateEmpresa;
