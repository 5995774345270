let consulta = (orden) => {

  //console.log(usuarioJSON.sexo)

  const string =
    `mutation deleteSolicitud {
      mensajeriaMutation {
        deleteSolicitud(idOrden: "${orden.id}")
        {
          id
          mensaje
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
