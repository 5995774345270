import $ from 'jquery';

let touchGalerias = () => {}

touchGalerias.mount = () => {
  $('.carousel-inner').slick({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    initialSlide: 0,
    adaptiveHeight: true
  });

  $('.nav-link').click(function(){
    $('.carousel-inner').slick('unslick');
    $('.carousel-inner').slick({
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      initialSlide: 0,
      adaptiveHeight: true
    });
  });

}

touchGalerias.unMount = () => {
  $('.carousel-inner').slick('unslick');
}

export default touchGalerias
