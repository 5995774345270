// JavaScript Document
import GraphQL from './ConsultaGraphQLGetObjetivos';

import url from '../../../../../apiService/ApiUrl';
import urlencode from 'urlencode';

import moment from 'moment';

function handleError(err){
	console.log(`Request failed: ${err}`);
}

const fetchUsers = (This, usuario, tipoFecha, fechaDesde, fechaHasta) => {

	const options = {
		method: 'GET',
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(`${url}?query=query${urlencode(GraphQL(usuario, tipoFecha, fechaDesde, fechaHasta))}`, options)
	.then(res => res.json())
	.then( (res) => {

		//console.log(res)

		if(res.data.appInfo.adminObjetivos.objetivos !== null){

			res.data.appInfo.adminObjetivos.objetivos.objetivos.forEach(objetivo => {
				objetivo.fechaDeInicio = moment(parseInt(objetivo.fechaDeInicio))._d
				objetivo.fechaFinal = objetivo.fechaFinal !== null ? moment(parseInt(objetivo.fechaFinal))._d : objetivo.fechaFinal
				objetivo.fechaFinalPlanificada = moment(parseInt(objetivo.fechaFinalPlanificada))._d
				objetivo.type = "Objetivo"
			});
	
			res.data.appInfo.adminObjetivos.objetivos.metas.forEach(meta => {
				meta.fechaDeInicio = moment(parseInt(meta.fechaDeInicio))._d
				meta.fechaFinal = meta.fechaFinal !== null ? moment(parseInt(meta.fechaFinal))._d : meta.fechaFinal
				meta.fechaFinalPlanificada = moment(parseInt(meta.fechaFinalPlanificada))._d
				meta.type = "Meta"
			});
	
			res.data.appInfo.adminObjetivos.objetivos.tareas.forEach(tarea => {
				tarea.fechaDeInicio = moment(parseInt(tarea.fechaDeInicio))._d
				tarea.fechaFinal = tarea.fechaFinal !== null ? moment(parseInt(tarea.fechaFinal))._d : tarea.fechaFinal
				tarea.fechaFinalPlanificada = moment(parseInt(tarea.fechaFinalPlanificada))._d
				tarea.type = "Tarea"
			});
			
		}

		let adminObjetivos = {
			objetivos: res.data.appInfo.adminObjetivos.objetivos.objetivos,
			metas: res.data.appInfo.adminObjetivos.objetivos.metas,
			tareas: res.data.appInfo.adminObjetivos.objetivos.tareas,
			etiquetas: res.data.appInfo.adminObjetivos.etiquetas
		}

		if((fechaDesde === null) || (fechaHasta === null)){

			This.props.dispatch({
				type: 'FETCH_ALL_OBJETIVOS',
				payload: {
					objetivosData: adminObjetivos.objetivos
				}
			})

		}else{
			This.props.dispatch({
				type: 'FETCH_ALL_DATA_ADMIN_OBJETIVOS',
				payload: {
					adminObjetivosData: adminObjetivos
				}
			})
		}



	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchUsers;
