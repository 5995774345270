import React from 'react';

import { NavLink } from 'react-router-dom'; //Link

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let AdminProductos = props => {

  let permisosProductos = {
        ViewProducto: props.usuario.permisos.Productos.ViewProducto,
        CreateProducto: props.usuario.permisos.Productos.CreateProducto,
        EditProducto: props.usuario.permisos.Productos.EditProducto,
        DeleteProducto: props.usuario.permisos.Productos.DeleteProducto
      },
      permisosTipoClientes = {
        ViewTipoCliente: props.usuario.permisos.Productos.ViewTipoCliente,
        CreateTipoCliente: props.usuario.permisos.Productos.CreateTipoCliente,
        EditTipoCliente: props.usuario.permisos.Productos.EditTipoCliente,
        DeleteTipoCliente: props.usuario.permisos.Productos.DeleteTipoCliente
      },
      permisosAseguradoras = {
        ViewAseguradora: props.usuario.permisos.Productos.ViewAseguradora,
        CreateAseguradora: props.usuario.permisos.Productos.CreateAseguradora,
        EditAseguradora: props.usuario.permisos.Productos.EditAseguradora,
        DeleteTipoCliente: props.usuario.permisos.Productos.DeleteAseguradora
      },
      arrayOfProductosValues = [],
      allProductosValuesFalse = true,
      arrayOfTiposClientesValues = [],
      allTiposClientesValuesFalse = true,
      arrayOfAseguradorasValues = [],
      allAseguradorasValuesFalse = true

      arrayOfProductosValues =  props.usuario.permisos.Productos !== null ? Object.values(permisosProductos) : []
      allProductosValuesFalse = arrayOfProductosValues.every(v => v === false)
      arrayOfTiposClientesValues =  props.usuario.permisos.Productos !== null ? Object.values(permisosTipoClientes) : []
      allTiposClientesValuesFalse = arrayOfTiposClientesValues.every(v => v === false)
      arrayOfAseguradorasValues =  props.usuario.permisos.Productos !== null ? Object.values(permisosAseguradoras) : []
      allAseguradorasValuesFalse = arrayOfAseguradorasValues.every(v => v === false)

  if( (Object.entries(props.usuario.permisos.Productos).length > 0) === true){
    return(
      <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Components">
        <a className="nav-link nav-link-collapse collapsed dropdown-toggle" data-toggle="collapse" href="#collapseSubProductos" data-parent="#exampleAccordion" onClick={props.toggleSideOff} >
          <FontAwesomeIcon icon="boxes" /> <span className="nav-link-text">Administracion de Productos</span>
        </a>
        <ul className="sidenav-second-level collapse" id="collapseSubProductos">

          {
            allProductosValuesFalse === false ? (
              <li>
                <a className="nav-link-collapse collapsed dropdown-toggle" data-toggle="collapse" href="#collapseProductos" onClick={props.toggleSideOff}><FontAwesomeIcon icon="clipboard-list" /><span className="nav-link-text"> Productos </span></a>
                <ul className="sidenav-third-level collapse" id="collapseProductos">

                  {
                    props.usuario.permisos.Productos.CreateProducto === true ? (
                      <li>
                        <NavLink exact to="/dashboard/productos/producto/create" activeClassName="active" className="nav-link">{/*<FontAwesomeIcon icon="list-alt" />*/} Crear Producto</NavLink>
                      </li>
                    ) : null
                  }

                  <li>
                    <NavLink exact to="/dashboard/admin-compras/ordenes" activeClassName="active" className="nav-link">{/*<FontAwesomeIcon icon="list-alt" />*/} Ver Productos</NavLink>
                  </li>

                </ul>
              </li>
            ) : null
          }

          {
            allTiposClientesValuesFalse === false ? (
              <li>
                <a className="nav-link-collapse collapsed dropdown-toggle" data-toggle="collapse" href="#collapseSubTipoClientes" onClick={props.toggleSideOff}><FontAwesomeIcon icon="clipboard-list" /><span className="nav-link-text"> Tipo de Clientes </span></a>
                <ul className="sidenav-third-level collapse" id="collapseSubTipoClientes">

                  {
                    props.usuario.permisos.Productos.CreateTipoCliente === true ? (
                      <li>
                        <NavLink exact to="/dashboard/productos/tipo-clientes/create" activeClassName="active" className="nav-link">{/*<FontAwesomeIcon icon="list-alt" />*/} Crear Tipo de Cliente</NavLink>
                      </li>
                    ) : null
                  }

                  <li>
                    <NavLink exact to="/dashboard/productos/tipo-clientes" activeClassName="active" className="nav-link">{/*<FontAwesomeIcon icon="list-alt" />*/} Ver Tipos de Clientes</NavLink>
                  </li>

                </ul>
              </li>
            ) : null
          }

          {
            allAseguradorasValuesFalse === false ? (
              <li>
                <a className="nav-link-collapse collapsed dropdown-toggle" data-toggle="collapse" href="#collapseSubAseguradoras" onClick={props.toggleSideOff}><FontAwesomeIcon icon="clipboard-list" /><span className="nav-link-text"> Aseguradoras </span></a>
                <ul className="sidenav-third-level collapse" id="collapseSubAseguradoras">

                  {
                    props.usuario.permisos.Productos.CreateAseguradora === true ? (
                      <li>
                        <NavLink exact to="/dashboard/admin-compras/realizar-orden" activeClassName="active" className="nav-link">{/*<FontAwesomeIcon icon="list-alt" />*/} Crear Aseguradora</NavLink>
                      </li>
                    ) : null
                  }

                  <li>
                    <NavLink exact to="/dashboard/admin-compras/ordenes" activeClassName="active" className="nav-link">{/*<FontAwesomeIcon icon="list-alt" />*/} Ver Aseguradoras</NavLink>
                  </li>

                </ul>
              </li>
            ) : null
          }

        </ul>
      </li>
    )
  }else{
    return null
  }
}

/*<li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Charts">
  <Link to="/dashboard-admin-user" className="nav-link">
    <FontAwesomeIcon icon="users-cog" />
    <span className="nav-link-text"> Administración de Usuarios</span>
  </Link>
</li>*/


export default AdminProductos
