import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TableSucursales from './TableSucursales.jsx';

let EmpresaDataView = props => {

    let empresa = props.empresa,
        sucursales = props.sucursales.filter( sucursal => sucursal.id_empresa === empresa.id)

    return(
        <div className="modal-body sin-margen-y-padding container-fluid">
            <div className="row text-center">

                <div className="col-12">
                <FontAwesomeIcon icon="building" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                <h1>Empresa: {empresa.nombre_comercial}</h1>
                </div>
                <div className="col-12 row" style={{'marginTop': '25px'}}>

                <div className="col-md-4 row">
                    <p className="col" id="nombreEmpresa" style={{'marginTop': '0px'}}><b>Nombre Comercial:</b> {empresa.nombre_comercial}</p>
                </div>

                <div className="col-md-4 row">
                    <p className="col" id="nombreRazonSocial" style={{'marginTop': '0px'}}><b>Nombre Razón Social:</b> {empresa.nombre_razon_social}</p>
                </div>

                <div className="col-md-4 row">
                    <p className="col" id="TelefonoEmpresa" style={{'marginTop': '0px'}}><b>Teléfono:</b> {empresa.telefono}</p>
                </div>

                <div className="col-md-4 row">
                    <p className="col" id="CorreoEmpresa" style={{'marginTop': '0px'}}><b>Correo:</b> {empresa.correo}</p>
                </div>

                <div className="col-md-4 row">
                    <p className="col" id="NIT" style={{'marginTop': '0px'}}><b>NIT:</b> {empresa.NIT}</p>
                </div>

                <div className="col-md-4 row">
                    <p className="col" id="DireccionFiscal" style={{'marginTop': '0px'}}><b>Dirección Físcal:</b> {empresa.direccion_fiscal}</p>
                </div>

                <div className="col-md-4 row">
                    <p className="col" id="DireccionComercial" style={{'marginTop': '0px'}}><b>Dirección Comercial:</b> {empresa.direccion_comercial}</p>
                </div>

                <div className="col-md-4 row">
                    <p className="col" id="Descripcion" style={{'marginTop': '0px'}}><b>Descripción:</b> {empresa.descripcion}</p>
                </div>

                <div className="col-md-4 row">
                    <p className="col" id="Convenio" style={{'marginTop': '0px'}}><b>Convenio:</b> {empresa.convenio}</p>
                </div>

                <div className="col-md-4 row">
                    <p className="col" id="CreditoAutorizado" style={{'marginTop': '0px'}}><b>Crédito Autorizado:</b> {empresa.credito_autorizado}</p>
                </div>

                <div className="col-md-4 row">
                    <p className="col" id="DiasCreditoAutorizado" style={{'marginTop': '0px'}}><b>Días de Crédito Autorizado:</b> {empresa.dias_de_credito_autorizado}</p>
                </div>

                <div className="col-md-4 row">
                    <p className="col" id="TipoDePagoComún" style={{'marginTop': '0px'}}><b>Tipo de Pago Común:</b> {empresa.tipo_de_pago_comun}</p>
                </div>

                {
                    empresa.clasificacion_empresa.length > 0 && (
                        <div className="col-md-4 row mx-auto">
                            <p className="col" id="ClasificacionEmpresa" style={{'marginTop': '0px'}}><b>Clasificación Empresa:</b></p>
                            {
                                empresa.clasificacion_empresa.map( (clasificacion, i) => <li key={`${clasificacion}-${i}` } className="col" style={{'marginTop': '0px'}}>{clasificacion}</li>  )
                            }
                            
                        </div>
                    )
                }

                {
                   (empresa.clasificacion_empresa.length > 0 ) && (empresa.clasificacion_empresa.includes('Proveedor')) && (
                        <div className="col-md-4 row mx-auto">
                            <p className="col" id="ProveedorAutorizado" style={{'marginTop': '0px'}}><b>Proveedor Autorizado:</b> {empresa.proveedor_autorizado === true ? <FontAwesomeIcon icon="check" style={{ color: '#77FF34', marginLeft: '5px' }} /> : <FontAwesomeIcon icon="times" style={{ color: '#FF2304', marginLeft: '5px' }} />} </p>
                        </div>
                   ) 
                }


                </div>

                {
                sucursales.length > 0 ? (
                    <TableSucursales sucursales={sucursales} handleChangeDisplay={props.handleChangeDisplay} handleDeleteSucursal={props.handleDeleteSucursal} />
                ) : null
                }

            </div>
        </div>
    )
}

export default EmpresaDataView