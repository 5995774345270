import React from 'react';
import './css/Promociones.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let Promociones = props => (
  <div className="col-12 col-sm-6 col-lg-4 mx-auto">
    {props.children}
    {
      ((props.conexion === true) && (props.usuario.permisos.AdminUI === true)) ? (
        <div className="text-center mt-2">
          <button type="button" className="btn btn-outline-info" onClick={ () => {
            props.handleDeletePromocion(props.promoId)
          }}><FontAwesomeIcon icon="trash-alt" /></button>
        </div>
      ) : null
    }
  </div>
)

export default Promociones
