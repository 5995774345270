const initialState = {
  solicitudesMensajeria: [],
  rutasMensajeria: [],
  plantillasRutasMensajeria: []
}

let dataMensajeria = (state = initialState, action) => {
  switch (action.type) {

    case 'FETCH_SOLICITUDES_MENSAJERIA': {

      let solicitudesMensajeria = action.payload.dataMensajeria

      let newState = {
        ...state,
        solicitudesMensajeria: solicitudesMensajeria
      }

      return newState
    }

    case 'CHANGE_DATA_ORDEN_MENSAJERIA': {

      let orden = action.payload.dataOrden

      let ordenArr = [orden]

      let newStateOrdenes = state.solicitudesMensajeria.map( obj => ordenArr.find(o => o.id === obj.id) || obj )

      let newState = {
        ...state,
        solicitudesMensajeria: newStateOrdenes
      }

      return newState
    }

    case 'CHANGE_DATA_ORDEN_CHECKLIST_MENSAJERIA': {

      let data = action.payload.data,
          estado = action.payload.estado

      let ordenArr = state.solicitudesMensajeria.filter(orden => orden.id === data.id)

      ordenArr[0].checkList = data.checkList
      ordenArr[0].estado = estado

      let newStateOrdenes = state.solicitudesMensajeria.map( obj => ordenArr.find(o => o.id === obj.id) || obj )

      let newState = {
        ...state,
        solicitudesMensajeria: newStateOrdenes
      }

      return newState
    }

    case 'DELETE_ORDEN': {

      let orden = action.payload.dataOrden

      let newOrdenes = state.solicitudesMensajeria.filter( order => order.id !== orden.id)

      let newState = {
        ...state,
        solicitudesMensajeria: newOrdenes
      }

      return newState

    }

    case 'FETCH_RUTAS_MENSAJERIA': {

      let rutasMensajeria = action.payload.rutasMensajeria

      let newState = {
        ...state,
        rutasMensajeria
      }

      return newState
    }

    case 'FETCH_PLANTILLAS_RUTAS_MENSAJERIA': {

      let plantillasRutasMensajeria = action.payload.plantillasRutasMensajeria

      let newState = {
        ...state,
        plantillasRutasMensajeria
      }

      return newState
    }

    case 'CHANGE_DATA_PLANTILLA_RUTA_MENSAJERIA': {

        let plantilla = action.payload.dataPlantilla
  
        let plantillaArr = [plantilla]
  
        let newStatePlantilla = state.plantillasRutasMensajeria.map( obj => plantillaArr.find(o => o.id === obj.id) || obj )
  
        let newState = {
          ...state,
          plantillasRutasMensajeria: newStatePlantilla
        }

      return newState

    }

    case 'DELETE_PLANTILLA_RUTA_MENSAJERIA': {

      let plantilla = action.payload.dataPlantilla

      let newPlantillas = state.plantillasRutasMensajeria.filter( template => template.id !== plantilla.id)

      let newState = {
        ...state,
        plantillasRutasMensajeria: newPlantillas
      }

      return newState

    }

    case 'CHANGE_DATA_RUTA_MENSAJERIA': {

      let ruta = action.payload.dataRuta

      let rutaArr = [ruta]

      let newStateRutas = state.rutasMensajeria.map( obj => rutaArr.find(o => o.id === obj.id) || obj )

      let newState = {
        ...state,
        rutasMensajeria: newStateRutas
      }

      return newState
    }

    case 'CLEAR_FECHAS_RUTA_MENSAJERIA': {
      
      let ruta = action.payload.dataRuta
      delete ruta.fechaRutas
      
      let rutaArr = [ruta]

      let newStateRutas = state.rutasMensajeria.map( obj => rutaArr.find(o => o.id === obj.id) || obj )

      let newState = {
        ...state,
        rutasMensajeria: newStateRutas
      }

      return newState
    }

    case 'DELETE_RUTA': {

      let ruta = action.payload.dataRuta

      let newRutas = state.rutasMensajeria.filter( route => route.id !== ruta.id)

      let newState = {
        ...state,
        rutasMensajeria: newRutas
      }

      return newState

    }

    case 'CHANGE_DATA_RUTA_CHECKLIST_MENSAJERIA': {

      let data = action.payload.data,
          estado = action.payload.estado

      let rutaArr = state.rutasMensajeria.filter(ruta => ruta.id === data.id)

      rutaArr[0].checkList = data.checkList
      rutaArr[0].estado = estado

      let newStateRutas = state.rutasMensajeria.map( obj => rutaArr.find(o => o.id === obj.id) || obj )

      let newState = {
        ...state,
        rutasMensajeria: newStateRutas
      }

      return newState
    }

    //break;
    default:
      return state
  }
}

export default dataMensajeria
