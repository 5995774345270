import { createClient } from 'graphql-ws';

import wsurl from './apiService/WssApiUrl';

const client = createClient({
    url: wsurl,
});

async function suscribeGraphQL(payload, cb) {
    return new Promise((resolve, reject) => {
        let result;
        client.subscribe(payload, {
            next: (data) => cb(data),
            error: reject,
            complete: () => resolve(result),
        });
    });
}

export default suscribeGraphQL