let consulta = (sucursal) => {

  //console.log(usuarioJSON.sexo)

  const string =
    `mutation deleteSucursal {
      empresaMutation{
        deleteSucursal(
          idSucursal: "${sucursal.id}"
        ) {
          idSucursal
          mensaje
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
