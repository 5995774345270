import React, { Component } from 'react';

import { Redirect } from 'react-router';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NavLink } from 'react-router-dom';

import OrdenEmpresa from '../../components/RealizarOrdenMuestras/OrdenEmpresa.jsx';

import OrdenUsuario from '../../components/RealizarOrdenMuestras/OrdenUsuario.jsx';

import GraphQL from './realizarOrdenFetch/FetchGraphQLRealizarOrden.js';

import QRCreator from '../../../Components/QRCreator';

import Select from 'react-select';

import Toggle from 'react-toggle';
import "react-toggle/style.css" // for ES6 modules

import { connect } from 'react-redux';

class RealizarOrdenMensajeria extends Component {

  constructor(props) {
    super(props);

    // No llames this.setState() aquí!
    this.state = {
      selectedOptionEmpresas: null,
      selectedOptionUsuarios: null,
      selectedOptionMensajeros: null,
      selectedSucursal: "",
      DatosPredefinidos: true,
      urgente: false,
      id_Orden: null,
      idCreador: this.props.usuario.id,
      fechaInputDate: {
        date: new Date(),
        dateValue: "",
        timeValue: "",
        minDateValue: "",
        minTimeValue: "00:00"
      },
      showForm: true
    }

    this.handleChangeEmpresa = this.handleChangeEmpresa.bind(this);
    this.handleChangeSucursal = this.handleChangeSucursal.bind(this);
    this.handleChangeUsuario = this.handleChangeUsuario.bind(this);
    this.handleChangeMensajero = this.handleChangeMensajero.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUrgenteChange = this.handleUrgenteChange.bind(this);
    this.handleDatosPredefinidos = this.handleDatosPredefinidos.bind(this);
    this.handleChangefechaInputDate = this.handleChangefechaInputDate.bind(this);
    this.handleChangefechaInputTime = this.handleChangefechaInputTime.bind(this);
    this.handleChangeUsuarioDatosPredefinidos = this.handleChangeUsuarioDatosPredefinidos.bind(this);
    this.handleChangeEmpresaDatosPredefinidos = this.handleChangeEmpresaDatosPredefinidos.bind(this);
  }

  componentDidMount() {

    //Creando la fecha minima
    let date = this.state.fechaInputDate.date
    let dia
    (date.getDate()) < 10 ? dia = `0${date.getDate()}` : dia = `${date.getDate()}`
    let mes
    (date.getMonth() + 1) < 10 ? mes = `0${date.getMonth() + 1}` : mes = `${date.getMonth() + 1}`
    let year = date.getFullYear()
    //Fecha minima
    let minDate = `${year}-${mes}-${dia}`

    this.setState({
      fechaInputDate: {
        ...this.state.fechaInputDate,
        minDateValue: minDate
      } 
    })

  }

  handleChangeEmpresaDatosPredefinidos() {

    let dirYTelPre = document.getElementById("dirYTelPre");
    
    this.setState({
      DatosPredefinidos: true
    })

    dirYTelPre.checked = true;

    dirYTelPre.parentNode.classList.add('react-toggle--checked');

  }

  handleChangeUsuarioDatosPredefinidos() {

    let dirYTelPre = document.getElementById("dirYTelPre");

    this.setState({
      DatosPredefinidos: false
    })

    dirYTelPre.checked = false;

    dirYTelPre.parentNode.classList.remove('react-toggle--checked');

  }

  handleChangeMensajero = selectedOptionMensajeros => {

    this.setState({
      selectedOptionMensajeros
    });

    //console.log(`Option selected:`, selectedOption);

  }

  handleChangeEmpresa = selectedOptionEmpresas => {

    this.setState({
      selectedOptionEmpresas,
      selectedOptionUsuarios: null,
      selectedSucursal: ""
    });

    //console.log(`Option selected:`, selectedOption);

  }

  handleChangeSucursal = (event) => {
    this.setState({
      selectedSucursal: event.target.value
    })
  }

  handleChangeUsuario = selectedOptionUsuarios => {

    this.setState({
      selectedOptionUsuarios,
      selectedOptionEmpresas: null
    });

    //console.log(`Option selected:`, selectedOption);

  }

  handleUrgenteChange(){
    if(this.state.urgente === false){
      this.setState({
        urgente: true
      })
    }else{
      this.setState({
        urgente: false
      })
    }
  }

  handleDatosPredefinidos(){
    if(this.state.DatosPredefinidos === true){
      this.setState({
        DatosPredefinidos: false
      })
    }else{
      this.setState({
        DatosPredefinidos: true
      })
    }
  }

  handleChangefechaInputDate = (event) => {

    //Creando la fecha minima
    let date = this.state.fechaInputDate.date
    let dia
    (date.getDate()) < 10 ? dia = `0${date.getDate()}` : dia = `${date.getDate()}`
    let mes
    (date.getMonth() + 1) < 10 ? mes = `0${date.getMonth() + 1}` : mes = `${date.getMonth() + 1}`
    let year = date.getFullYear()
    //Fecha minima
    let minDate = `${year}-${mes}-${dia}`
    //Creando Hora minima
    let hora
    (date.getHours()) < 10 ? hora = `0${date.getHours()}` : hora = `${date.getHours()}`
    let minutos
    (date.getMinutes()) < 10 ? minutos = `0${date.getMinutes()}` : minutos = `${date.getMinutes()}`
    //Hora minima
    let minTime
    //((this.state.fechaInputDate === minDate) === true) ? minTime = `${hora}:${minutos}` : minTime = "00:00"
    ((event.target.value === minDate) === true) ? minTime = `${hora}:${minutos}` : minTime = "00:00"

    this.setState({
      fechaInputDate: {
        ...this.state.fechaInputDate,
        dateValue: event.target.value,
        minTimeValue: minTime
      } 
    });

  }

  handleChangefechaInputTime = (event) => {

    this.setState({
      fechaInputDate: {
        ...this.state.fechaInputDate,
        timeValue: event.target.value
      } 
    });

  }

  handleSubmit(event){
    event.preventDefault();

    let diaDeRecoleccion = this.state.fechaInputDate.dateValue.replace(/-/g, ','),
      diaDeRecoleccionArray = diaDeRecoleccion.split(","),
      diaDeRecoleccionArrayNumbers = diaDeRecoleccionArray.map(function(item) {
        return parseInt(item, 10);
      }),
      mes = diaDeRecoleccionArrayNumbers[1] - 1,
      fechaDeRecoleccionArray = [diaDeRecoleccionArrayNumbers[0], mes, diaDeRecoleccionArrayNumbers[2]],
      horaDeRecoleccion = this.state.fechaInputDate.timeValue.replace(/:/g, ','),
      horaDeRecoleccionArray = horaDeRecoleccion.split(","),
      horaDeRecoleccionArrayNumbers = horaDeRecoleccionArray.map(function(item) {
        return parseInt(item, 10);
      })
      fechaDeRecoleccionArray.push(horaDeRecoleccionArrayNumbers[0], horaDeRecoleccionArrayNumbers[1])
    
    let fechaDeRecoleccion = new Date(...fechaDeRecoleccionArray)

    this.setState({
      id_Orden: null
    })

    let orden

    if (this.state.selectedOptionEmpresas !== null) {
      
      if (this.state.DatosPredefinidos === true) {
        orden = {
          idEmpresa: this.state.selectedOptionEmpresas.value.id,
          idUsuario: null,
          idSucursal: this.state.selectedSucursal === "" ? null : this.state.selectedSucursal,
          idMensajero: this.state.selectedOptionMensajeros.value.id,
          idCreador: this.state.idCreador,
          instrucciones: document.querySelector('#instruccionesDeOrdenAdministrativa').value,
          personaQueLlamo: document.querySelector('#PersonaQueLlamo').value,
          telefono: this.state.selectedOptionEmpresas.value.telefono,
          direccionDeRecepcion: this.state.selectedSucursal === "" ? this.state.selectedOptionEmpresas.value.direccion_comercial : this.props.dataEmpresas.sucursales.filter( sucursal => {
            return sucursal.id === this.state.selectedSucursal
          })[0].direccion,
          fechaDeRecoleccion,
          tipo: 'Administrativa',
          urgente: this.state.urgente
        }
      } else {
        orden = {
          idEmpresa: this.state.selectedOptionEmpresas.value.id,
          idSucursal: this.state.selectedSucursal === "" ? null : this.state.selectedSucursal,
          idUsuario: null,
          idMensajero: this.state.selectedOptionMensajeros.value.id,
          idCreador: this.state.idCreador,
          instrucciones: document.querySelector('#instruccionesDeOrdenAdministrativa').value,
          personaQueLlamo: document.querySelector('#PersonaQueLlamo').value,
          telefono: document.querySelector('#telefono').value,
          direccionDeRecepcion: document.querySelector('#direccionDeRecepcion').value,
          fechaDeRecoleccion,
          tipo: 'Administrativa',
          urgente: this.state.urgente
        }
      }

      
    } else if (this.state.selectedOptionUsuarios !== null) {
      
      if (this.state.DatosPredefinidos === true) {
        orden = {
          idEmpresa: null,
          idUsuario: this.state.selectedOptionUsuarios.value.id,
          idMensajero: this.state.selectedOptionMensajeros.value.id,
          idCreador: this.state.idCreador,
          instrucciones: document.querySelector('#instruccionesDeOrdenAdministrativa').value,
          personaQueLlamo: document.querySelector('#PersonaQueLlamo').value,
          telefono: this.state.selectedOptionUsuarios.value.telefono,
          direccionDeRecepcion: this.state.selectedOptionUsuarios.value.direccion,
          fechaDeRecoleccion,
          tipo: 'Administrativa',
          urgente: this.state.urgente
        }
      } else { 
        orden = {
          idEmpresa: null,
          idUsuario: this.state.selectedOptionUsuarios.value.id,
          idMensajero: this.state.selectedOptionMensajeros.value.id,
          idCreador: this.state.idCreador,
          instrucciones: document.querySelector('#instruccionesDeOrdenAdministrativa').value,
          personaQueLlamo: document.querySelector('#PersonaQueLlamo').value,
          telefono: document.querySelector('#telefono').value,
          direccionDeRecepcion: document.querySelector('#direccionDeRecepcion').value,
          fechaDeRecoleccion,
          tipo: 'Administrativa',
          urgente: this.state.urgente
        }
      }

    }

    GraphQL(this, orden)

    this.setState({
      showForm: false
    })

  }

  render() {

    let SelectEmpresa = Array.from(this.props.dataEmpresas.empresas, empresa => {
      return { value: empresa, label: empresa.nombre_comercial } //value: empresa.nombre_comercial
    })

    let SelectSucursal = this.props.dataEmpresas.sucursales.filter( sucursal => {
      let option

      if(this.state.selectedOptionEmpresas !== null){
        option = this.state.selectedOptionEmpresas.value.id === sucursal.id_empresa
      }

      return option

    })

    let SelectUsuarios = Array.from(this.props.dataUsers.usuarios, usuario => {
      return { value: usuario, label: `${usuario.nombre} ${usuario.apellidos}` } //value: empresa.nombre_comercial
    })

    let SelectMensajeroArray = this.props.dataUsers.usuarios.filter( usuario => {
      return usuario.colaborador.puesto === 'Mensajero'
    })

    let SelectMensajero = Array.from(SelectMensajeroArray, usuario => {
      return { value: usuario, label: `${usuario.nombre} ${usuario.apellidos}` } //value: empresa.nombre_comercial
    })

    let arrayOfMensajeriaValues = [],
        allMensajeriaValuesFalse = true

    if(this.props.usuario !== null){
      if(this.props.usuario.permisos !== null){
        arrayOfMensajeriaValues = Object.values(this.props.usuario.permisos.Mensajeria)
        allMensajeriaValuesFalse = arrayOfMensajeriaValues.every(v => v === false)
      }
    }

    if (this.props.conexion === false) {
			return <Redirect to='/login' />
		}else if((this.props.usuario.permisos === null) || ((Object.entries(this.props.usuario.permisos.Mensajeria).length > 0) !== true) || (allMensajeriaValuesFalse === true) || (this.props.usuario.permisos.Mensajeria.CreateOrden === false)){
      return <Redirect to='/dashboard' />
    }else{
      return (
        <div className="container">
  
          <div className="row" style={{marginBottom: '20px'}}>
            <div className="col-12">
              <h1 className="text-center">Realiza una Orden Administrativa</h1>
            </div>
          </div>
  
          {
            (this.state.showForm === true) ? (
              <div className="row">
            
                <nav className="col-12 col-md-2 flex-md-column" style={{marginTop: '30px'}}>
                    <div className="nav nav-pills nav-fill" id="nav-tab" role="tablist">
                      <a className="nav-item nav-link active" id="nav-empresa-tab" data-toggle="tab" href="#nav-ord-empresa" role="tab" aria-controls="nav-ord-empresa" aria-selected="true" onClick={this.handleChangeEmpresaDatosPredefinidos}><FontAwesomeIcon icon="building" /> Orden Empresa</a>
                      <a className="nav-item nav-link" id="nav-usuario-tab" data-toggle="tab" href="#nav-ord-usuario" role="tab" aria-controls="nav-ord-usuario" aria-selected="false" onClick={this.handleChangeUsuarioDatosPredefinidos}><FontAwesomeIcon icon="user" /> Orden Usuario</a>
                    </div>
                </nav>
  
                <div className="col-12 col-md-10" style={{marginTop: '30px'}}>
  
                  <form className="row" onSubmit={this.handleSubmit} style={{marginBottom: '30px'}}>
  
                  
                  <div className="tab-content col-sm-6 col-12 " id="nav-tabContent">
  
                    <OrdenEmpresa active="active" selectedOptionEmpresas={this.state.selectedOptionEmpresas} SelectEmpresa={SelectEmpresa} handleChangeEmpresa={this.handleChangeEmpresa} />
  
                    <OrdenUsuario selectedOptionUsuarios={this.state.selectedOptionUsuarios} SelectUsuarios={SelectUsuarios} handleChangeUsuario={this.handleChangeUsuario} />
  
                  </div>

                  {
                    SelectSucursal.length > 0 ? (
                      <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                        <label htmlFor="Sucursal" className="col"><b>Sucursal:</b></label>
                        <select id="Sucursal" className="form-control" onChange={this.handleChangeSucursal} value={this.state.selectedSucursal}>
                          <option value=''>Ninguna</option>
                          {
                            SelectSucursal.map( (sucursal, index) => {
                              return <option key={index} value={sucursal.id}>{sucursal.nombre_comercial}</option>
                            })
                          }
                        </select>
                        <small id="sucursalSmall" className="form-text text-muted">Sucursal.</small>
                      </div>
                    ) : null
                  }

  
                    <div className="col-sm-6 col-12">
                      <div className="row">
                        <label htmlFor="instruccionesDeOrdenAdministrativa" className="col-lg-6 col-12"><b>Instrucciones de Orden Administrativa</b></label>
                        <textarea id="instruccionesDeOrdenAdministrativa" type="text" className="mx-auto" placeholder="Instrucciones de Orden"/>
                      </div>
                    </div>
  
                    <div className="col-sm-6 col-12">
                      <div className="row">
                        <label htmlFor="PersonaQueLlamo" className="col-lg-6 col-12"><b>Nombre de la Persona que llamó</b></label>
                        <input id="PersonaQueLlamo" type="text" className="mx-auto" placeholder="Nombre de Quién llamó"/>
                      </div>
                    </div>
  
                    <div className="mx-auto col-12 col-md-7" style={{marginTop: '15px'}}>
                      <div className="row">
                        <p className="col-lg-6 col-12 text-center"><b>Usar la dirección y teléfono predefinidos</b></p>
                        <label className="mx-auto">
                          <Toggle
                            id="dirYTelPre"
                            defaultChecked={this.state.DatosPredefinidos}
                            onChange={this.handleDatosPredefinidos} />
                        </label>
                      </div>
                    </div>
  
                    {
                      (this.state.DatosPredefinidos === true) ? null : (
                        <div className="col-sm-6 col-12">
                          <div className="row">
                            <label htmlFor="telefono" className="col-lg-6 col-12"><b>Número de Teléfono la Persona que llamó</b></label>
                            <input id="telefono" type="text" className="mx-auto" placeholder="Teléfono de Quién llamó"/>
                          </div>
                        </div>
                      )
                    }
  
                    {
                      (this.state.DatosPredefinidos === true) ? null : (
                        <div className="col-sm-6 col-12">
                          <div className="row">
                            <label htmlFor="direccionDeRecepcion" className="col-lg-6 col-12"><b>Dirección de Actividad</b></label>
                            <input id="direccionDeRecepcion" type="text" className="mx-auto" placeholder="Dirección Recepción de Documento"/>
                          </div>
                        </div>
                      )
                    }
  
                    <div className="col-sm-6 col-12">
                      <div className="row">
                        <label htmlFor="diaDeRecoleccion" className="col-lg-6 col-12"><b>Día de Recolección</b></label>
                        <input id="diaDeRecoleccion" value={this.state.fechaInputDate.dateValue} onChange={this.handleChangefechaInputDate} type="date" min={this.state.fechaInputDate.minDateValue} className="mx-auto" placeholder="Día de Recolección"/>
                      </div>
                    </div>
  
                    <div className="col-sm-6 col-12">
                      <div className="row">
                        <label htmlFor="horaDeRecoleccion" className="col-lg-6 col-12"><b>Hora de Recolección</b></label>
                        <input id="horaDeRecoleccion" type="time" value={this.state.fechaInputDate.timeValue} onChange={this.handleChangefechaInputTime} min={this.state.fechaInputDate.minTimeValue} className="mx-auto" placeholder="Hora de Recolección"/>
                      </div>
                    </div>
  
                    <div className="mx-auto" style={{marginTop: '15px'}}>
                      <div className="row">
                        <p className="col-lg-6 col-12 text-center"><b>¿Es Urgente?</b></p>
                        <label className="mx-auto">
                          <Toggle
                            defaultChecked={this.state.urgente}
                            onChange={this.handleUrgenteChange} />
                        </label>
                      </div>
                    </div>
  
                    <div className="col-sm-6 col-12" style={{marginTop: '25px', marginBottom: '25px'}}>
                      <div className="row">
                        <label htmlFor="react-select-3-input" className="col-lg-6 col-12"><b>Selecciona un Mensajero</b></label>
                        <Select
                          className="col-lg-6 col-12"
                          placeholder="Escoje un Mensajero"
                          value={this.state.selectedOptionMensajeros}
                          onChange={this.handleChangeMensajero}
                          options={SelectMensajero}
                        />
                      </div>
                    </div>
  
                    <div className="col-12">
                      <div className="row">
                        <button type="submit" className="btn btn-outline-info col-lg-4 col-10 mx-auto">CrearOrden</button>
                      </div>
                    </div>
                  </form>
  
                </div>
  
              </div>
            ) : null
          }
  
          
  
          {
            (this.state.showForm === true) ? null : (
              <div className="row">
                <div className="col-12">
                  {
                    (this.state.id_Orden !== null) ? <QRCreator idOrden={this.state.id_Orden}/> : null
                  }
                </div>
              </div>
            )
          }
  
          {
            (this.state.showForm === true) ? null : (
              <div className="row">
                <NavLink exact to="/dashboard/mensajeria/realizar-orden" activeClassName="active" className="btn btn-outline-info mx-auto col-lg-4 col-10 col-md-8" style={{ marginBottom: '50px' }}>Realizar Nueva Orden</NavLink>
              </div>
            )
          }
  
        </div>
      );
    }

  }
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario,
    dataEmpresas: state.dataEmpresas,
    dataUsers: state.dataUsers
  }
}

export default connect(mapStateToProps)(RealizarOrdenMensajeria)
