let consulta = (data) => {

  let string

  if(data.idEmpresa !== undefined){
    string =
      `mutation insertSolisitudDeAnalisis {
        mensajeriaMutation {
          insertSolisitudDeAnalisis(insertSolisitudDeAnalisis: {
            idMensajero: "${data.idMensajero}",
            idEmpresa: "${data.idEmpresa}",
            instrucciones: "${data.instrucciones}",
            cantidadDeMuestras: ${data.cantidadDeMuestras},
            tipo: "${data.tipo}",
            estado: "${data.estado}"
          }){
            id
            mensaje
          }
        }
      }`
  }else if(data.idUsuario !== undefined){
    string =
      `mutation insertSolisitudDeAnalisis {
        insertSolisitudDeAnalisis(insertSolisitudDeAnalisis: {
          idMensajero: "${data.idMensajero}",
          idUsuario: "${data.idUsuario}",
          instrucciones: "${data.instrucciones}",
          cantidadDeMuestras: ${data.cantidadDeMuestras},
          tipo: "${data.tipo}",
          estado: "${data.estado}"
        }){
          id
          mensaje
        }
      }`
  }


    return {
      "query": string
    }

}

export default consulta
