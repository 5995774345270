import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let ChangeProductosToNewOrden = props => {

    let [ newProductos, setNewProductos ] = useState([])

    let productos = props.productos.filter( producto => !newProductos.includes(producto) )

    let handleAddProductos = producto => {

        let addProducto = [...newProductos, producto]

        setNewProductos(addProducto)

    }

    let handleRemoveProductos = producto => {

        let removeProducto = newProductos.filter( productoOld => producto !== productoOld )

        setNewProductos(removeProducto)

    }

    return(
        <div className="table-responsive">
            <h2 className="text-center">Mover Productos a una nueva orden</h2>

            {
                productos.length > 0 ? (

                <table className="table text-center table-bordered table-hover table-sm">
                    <thead className="thead-light">
                        <tr>
                            <th colSpan="2"><h3>Productos Para Agregar a Nueva Orden</h3></th>
                        </tr>
                        <tr>
                            <th scope="col">Nombre Producto</th>
                            <th scope="col">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            productos.map((producto, i) => {
                                return(
                                    <tr key={i}>
                                        <td>{producto.nombre}</td>
                                        <td>
                                            <button style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}} type="button" onClick={ () => handleAddProductos(producto) }> <FontAwesomeIcon icon="plus-circle" /></button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                ) : null
            }

            {
                newProductos.length > 0 ? (

                <table className="table text-center table-bordered table-hover table-sm">
                    <thead className="thead-light">
                        <tr>
                            <th colSpan="2"><h3>Productos Agregados a Nueva Orden</h3></th>
                        </tr>
                        <tr>
                            <th scope="col">Nombre Producto</th>
                            <th scope="col">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            newProductos.map((producto, i) => {
                                return(
                                    <tr key={i}>
                                        <td>{producto.nombre}</td>
                                        <td>
                                            <button style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}} type="button" onClick={ () => handleRemoveProductos(producto) }> <FontAwesomeIcon icon="minus-circle" /></button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                ) : null
            }

            <div className="col-12 mx-auto text-center mb-5 d-none-print">
                <button className="btn btn-outline-info" onClick={ () => props.handleChangeProductoToNewOrden(newProductos) }>Cambiar Productos a una Nueva Orden</button>
            </div>

        </div>
    )
}

export default ChangeProductosToNewOrden