import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let EmpresaTable = props => (
  <div className="table-responsive">

    {
       props.usuario.permisos.AdminEmpresa.CreateEmpresa === true ? (
          <div className="justify-content-end">
              <button onClick={() => {
                  let typeModal = 'InsertEmpresa';
                  props.handleOpenModal(props.dataEmpresas.nuevaEmpresa, typeModal)
              }}
              type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'float': 'right', 'color': '#2984be'}}>
                <FontAwesomeIcon icon="plus-circle" /> Nueva Empresa <FontAwesomeIcon icon="building" />
              </button>
          </div>
       ) : null
    }

    {
      ((props.usuario.permisos.AdminEmpresa.ViewEmpresa === true) || (props.usuario.permisos.AdminEmpresa.EditEmpresa === true) || (props.usuario.permisos.AdminEmpresa.DeleteEmpresa === true)) ? (
        <table className="table text-center table-bordered table-hover table-sm">
          <thead className="thead-light">
            <tr>
              <th scope="col">Nombre Comercial</th>
              <th scope="col" className="d-none d-md-table-cell" >Nombre Razón Social</th>
              <th scope="col" className="d-none d-md-table-cell" >Teléfono</th>
              <th scope="col" className="d-none d-md-table-cell" >Proveedor Autorizado / Clasificación</th> 
              {/*<th scope="col" className="d-none d-xl-table-cell" >Correo</th>*/}
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {
              props.empresas.map( empresa => {

                let hasClasificacion = empresa.clasificacion_empresa.length > 0
                let isProveedor = hasClasificacion && empresa.clasificacion_empresa.includes('Proveedor')
                let isCliente = hasClasificacion && empresa.clasificacion_empresa.includes('Cliente')

                return (
                  <tr key={empresa.id}>
                    {/*<th scope="row">{usuario.nombre}</th>*/}
                    <td>{empresa.nombre_comercial}</td>
                    <td className="d-none d-md-table-cell" >{empresa.nombre_razon_social}</td>
                    <td className="d-none d-md-table-cell" >{empresa.telefono}</td>
                    <td className="d-none d-md-table-cell" >
                      {hasClasificacion === false && 'No tiene Clasificacion'}
                      {isProveedor && 'Proveedor:'}{isProveedor ? empresa.proveedor_autorizado === true ? <FontAwesomeIcon icon="check" style={{ color: '#77FF34', marginLeft: '5px' }} /> : <FontAwesomeIcon icon="times" style={{ color: '#FF2304', marginLeft: '5px' }} /> : null} {isProveedor && isCliente && ' - '} {isCliente && 'Cliente'}
                    </td>
                    {/*<td className="d-none d-xl-table-cell" >{empresa.correo}</td>*/}
                    <td>

                      {
                        props.usuario.permisos.AdminEmpresa.ViewEmpresa === true ? (
                          <button type="button" onClick={() => {
                              let typeModal = 'EmpresaModalView';
                              props.handleOpenModal(empresa, typeModal)
                          }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                            <FontAwesomeIcon icon="eye" />
                          </button>
                        ) : null
                      }

                      {
                        props.usuario.permisos.AdminEmpresa.EditEmpresa === true ? (
                          <button type="button" onClick={() => {
                              let typeModal = 'EmpresaModalEdit';
                              props.handleOpenModal(empresa, typeModal)
                          }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                            <FontAwesomeIcon icon="edit" />
                          </button>
                        ) : null
                      }

                      {
                        props.usuario.permisos.AdminEmpresa.DeleteEmpresa === true ? (
                          <button onClick={ () => {

                              let typeModal = 'DeleteQuestion';

                              let data = {
                                DeleteFunction: props.handleDeleteEmpresa,
                                data: empresa
                              }

                              props.handleOpenModal(data, typeModal)

                              //props.handleDeleteEmpresa(empresa)

                            }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                            <FontAwesomeIcon icon="trash-alt" />
                          </button>
                        ) : null
                      }

                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      ) : null
    }
  </div>
)

export default EmpresaTable
