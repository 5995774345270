let consulta = (plantilla) => {

  //console.log(usuarioJSON.sexo)

  const string =
    `mutation deletePlantillaRutaMensajeria {
      mensajeriaMutation {
        deletePlantillaRutaMensajeria(idPlantilla: "${plantilla.id}"){
          id
          mensaje
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
