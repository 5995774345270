import GraphQL from './ConsultaGraphQL';

import url from '../../../../../../../../apiService/ApiUrl.js';

function handleError(err, This){
	console.log(`Request failed: ${err}`);
	This.setState({
		mensajeInsertPlantillaRuta: {
			response: 'failure',
			mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
		}
	})
}

const insertRutaMensajeria = (This, data) => {

	const options = {
		method: 'POST',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(data)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		//console.log(res)

		This.setState({
			mensajeInsertPlantillaRuta: {
				response: 'success',
				mensaje: `${res.data.mensajeriaMutation.insertPlantillaRutaMensajeria.mensaje} de la ruta con id ${res.data.mensajeriaMutation.insertPlantillaRutaMensajeria.id}`
			}
		})

	}).then( ()=>{
		setTimeout( ()=>{ 
			This.setState({
				nombrePlantilla: "",
				ordenes: [],
				showForm: false,
				tipoOrden: "Administrativa",
				newOrden: {
					selectedOptionEmpresas: null,
					selectedOptionUsuarios: null,
					selectedOptionMensajeros: null,
					DatosPredefinidos: true,
					urgente: false,
					fechaInputDate: {
						date: new Date(),
						//dateValue: "",
						timeValue: "",
						minDateValue: "",
						minTimeValue: "00:00"
					}
				},
				mensajeInsertPlantillaRuta: null
			})
		}, 3000)
	})
	.catch(err => handleError(err, This))
	.then( ()=>{
		setTimeout( ()=>{ 
			This.setState({
				nombrePlantilla: "",
				ordenes: [],
				showForm: false,
				tipoOrden: "Administrativa",
				newOrden: {
					selectedOptionEmpresas: null,
					selectedOptionUsuarios: null,
					selectedOptionMensajeros: null,
					DatosPredefinidos: true,
					urgente: false,
					fechaInputDate: {
						date: new Date(),
						//dateValue: "",
						timeValue: "",
						minDateValue: "",
						minTimeValue: "00:00"
					}
				},
				mensajeInsertPlantillaRuta: null
			})
		}, 3000)
	});

	//termina fetch
}

export default insertRutaMensajeria;
