const initialState = {
  empresas: [],
  sucursales: [],
  search: [],
  emptyFilter: false,
  nuevaEmpresa: {
    nombre_comercial: "",
    nombre_razon_social: "",
    telefono: "",
    correo: "",
    NIT: "",
    direccion_fiscal: "",
    direccion_comercial: "",
    descripcion: "",
    convenio: "",
    credito_autorizado: 0,
    dias_de_credito_autorizado: 0,
    tipo_de_pago_comun: "Cheque"
  }
}

let dataEmpresas = (state = initialState, action) => {
  switch (action.type) {

    case 'FETCH_EMPRESAS': {

      let empresas = action.payload.dataEmpresas,
          sucursales = action.payload.dataSucursales

      let newState = {
        ...state,
        empresas,
        sucursales
      }

      return newState
    }
      
    case 'EMPTY_EMPRESA_FILTER': {

      let emptyFilter = action.payload.dataEmpty

      let newState = {
        ...state,
        emptyFilter
      }

      return newState

    }

    case 'FILTER_EMPRESAS': {

      let empresas = action.payload.dataEmpresas

      let newState = {
        ...state,
        search: empresas
      }

      return newState
      
    }

    case 'CLEAN_FILTER_EMPRESAS': {

      let newState = {
        ...state,
        search: []
      }

      return newState
      
    }

    case 'CHANGE_DATA_EMPRESAS': {

      let empresa = action.payload.dataEmpresa

      let empresaArr = [empresa]

      let newStateEmpresas = state.empresas.map( obj => empresaArr.find(o => o.id === obj.id) || obj )

      let newState = {
        ...state,
        empresas: newStateEmpresas
      }

      return newState
    }

    case 'NEW_EMPRESA': {

      let nuevaEmpresa = action.payload.dataEmpresa

      let newState = {
        ...state,
        nuevaEmpresa
      }

      return newState

    }

    case 'DELETE_EMPRESA': {

      let empresa = action.payload.dataEmpresa

      let newEmpresas = state.empresas.filter( company => company.id !== empresa.id)

      let newState = {
        ...state,
        empresas: newEmpresas
      }

      return newState

    }

    case 'DELETE_SUCURSAL': {

      let sucursal = action.payload.dataSucursal

      let newSucursales = state.sucursales.filter( stateSucursal => stateSucursal.id !== sucursal.id)

      let newState = {
        ...state,
        sucursales: newSucursales
      }

      return newState

    }

    case 'ADD_EMPRESA_TO_EMPRESAS': {

      let empresa = action.payload.dataEmpresa,
          empresaArr = [empresa]

      let empresas = state.empresas.concat(empresaArr)

      let newState = {
        ...state,
        empresas
      }

      return newState
      //return state
    }

    case 'ADD_SUCURSAL_TO_SUCURSALES': {

      let sucursal = action.payload.dataSucursal,
          sucursalArr = [sucursal]

      let sucursales = state.sucursales.concat(sucursalArr)

      let newState = {
        ...state,
        sucursales
      }

      return newState
      //return state
    }

    case 'CLEAN_NEW_EMPRESA': {
      let newState = {
        ...state,
        nuevaEmpresa: {
          nombre_comercial: "",
          nombre_razon_social: "",
          telefono: "",
          correo: "",
          NIT: "",
          direccion_fiscal: "",
          direccion_comercial: "",
          descripcion: "",
          convenio: "",
          credito_autorizado: 0,
          dias_de_credito_autorizado: 0,
          tipo_de_pago_comun: ""
        }
      }

      return newState
    }

    //break;
    default:
      return state
  }
}

export default dataEmpresas
