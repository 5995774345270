import GraphQL from './ConsultaGraphQL';

import url from '../../../../../../apiService/ApiUrl.js';

// JavaScript Document
function handleError(err){
	console.log(`Request failed: ${err}`);
}

const deleteSucursal = (This, sucursal) => {
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(sucursal)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		This.props.dispatch({
			type: 'DELETE_SUCURSAL',
			payload: {
				dataSucursal: sucursal
			}
		})

		//console.log(res)
		//return res
	})
	.catch(err => handleError(err));

	//termina fetch
}

export default deleteSucursal;
