import React from 'react';

import ContactoContainer from '../../../Containers/Contacto.jsx';

import Pacientes from '../../Components/Pacientes.jsx';

let Contacto = props => (
  <Pacientes>
    <ContactoContainer/>
  </Pacientes>
)

export default Contacto
