import React, {Component} from 'react';

import { connect } from 'react-redux';

import AnailisisPreventivosComponent from '../../Components/AnalisisClinicos/AnalisisPreventivos.jsx';

class AnalisisPreventivos extends Component {
  constructor(props){
		super(props)

    // This binding is necessary to make `this` work in the callback
    this.handleOpenModal = this.handleOpenModal.bind(this);
	}

  handleOpenModal(modal){
    this.props.dispatch({
			type: `OPEN_MODAL`,
			payload: {
				modal,
        typeModal: 'analisis'
			}
		})

  }


  render(){
    let modalsPreventivosPacientes = this.props.dataUI.AllModals.modalsPreventivos.modalsPreventivosPacientes,
        modalsPreventivosLaboratorios = this.props.dataUI.AllModals.modalsPreventivos.modalsPreventivosLaboratorios
    if(this.props.path === '/pacientes/analisis-clinicos'){
      return(
        <AnailisisPreventivosComponent>
          <div className="container">
      			<h4 className="col-xs-12">ANÁLISIS PREVENTIVO</h4>
      		</div>
      		<div className="container">
      			<div className="row">
              {
                modalsPreventivosPacientes.map( modal => {
                  return(
                    <span key={modal.id} data-toggle="modal" onClick={() => this.handleOpenModal(modal)} data-target={`#Modal-${modal.id}`} className="col-12 col-md-6 examenes">
            					<div className="col-12">
            						<div className="col-12"></div>
            						<em><h3 className="text-center">{modal.titulo}</h3></em>
            					</div>
            					<img className="img-fluid" src={modal.imagen} alt={modal.titulo}/>
            				</span>
                  )
                })
              }
      			</div>
      		</div>
        </AnailisisPreventivosComponent>
      )
    }else if(this.props.path === '/laboratorios-y-hospitales/analisis-clinicos'){
      return(
        <AnailisisPreventivosComponent>
          <div className="container">
      			<h4 className="col-xs-12">ANÁLISIS PREVENTIVO</h4>
      		</div>
      		<div className="container">
      			<div className="row">
              {
                modalsPreventivosLaboratorios.map( modal => {
                  return(
                    <span key={modal.id} data-toggle="modal" onClick={() => this.handleOpenModal(modal)} data-target={`#Modal-${modal.id}`} className="col-12 col-md-6 examenes">
            					<div className="col-12">
            						<div className="col-12"></div>
            						<em><h3 className="text-center">{modal.titulo}</h3></em>
            					</div>
            					<img className="img-fluid" src={modal.imagen} alt={modal.titulo}/>
            				</span>
                  )
                })
              }
      			</div>
      		</div>
        </AnailisisPreventivosComponent>
      )
    }
  }
}

let mapStateToProps = (state, props) => {
  return {
    dataUI: state.dataUI
  }
}


export default connect(mapStateToProps)(AnalisisPreventivos);
