import React from 'react';

import './css/Reconocimientos.css';

const Reconocimientos = (props) => (
	<section id="seccion-reconocimiento-inicio" className="container-fluid sin-margen-y-padding">
		{props.children}
	</section>
)

export default Reconocimientos;
