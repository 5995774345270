let consulta = (idProducto) => {

  let string = `{
    appInfo{
      ordenesCompra{
        productoCompra(idProducto: "${idProducto}"){
          id
          nombre
          NoCatalogo
          descripcion
          observaciones
          tipo
          valorUnitario
        }
      }
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
