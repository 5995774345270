import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FetchGraphQlGetTipoClientes from '../Containers/FetchGetTipoClientes/FetchGraphQLGetTipoClientes';

import FetchGraphQLDeleteTipoClientes from '../Containers/FetchDeleteTipoCliente/FetchGraphQLDeleteTipoCliente';

import DashboardComponent from '../../../dashboard/components/DashboardComponent.jsx';

let TipoClientesDashboard = props => {

   const [state, setState] = useState({
        tipoClientes: [],
    });

    useEffect( () => {

        let fetchGetTipoClientes = async () => {
          FetchGraphQlGetTipoClientes(setState)
        }

        fetchGetTipoClientes()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

   let handleOpenModal = (modal, typeModal) => {
    props.dispatch({
			type: `OPEN_MODAL`,
			payload: {
				modal,
				typeModal
			}
		})
   }

   let handleDeleteTipoCliente = (tipoCliente) => {

      return FetchGraphQLDeleteTipoClientes(setState, state, tipoCliente)

   }

   return (
      <DashboardComponent>
         <div className="row">
            <div className="col-12">
               <h1 className="text-center">Ver Tipos de Cliente</h1>
            </div>
            <div className="col-12 px-5">
               {
                  props.usuario.permisos.Productos.CreateTipoCliente === true ? (
                  <div className="justify-content-end">
                        <Link to="/dashboard/productos/tipo-clientes/create">
                           <button type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'float': 'right', 'color' : '#2984be'}}>
                           Nuevo Tipo de Cliente <FontAwesomeIcon icon="plus-circle" />
                           </button>
                        </Link>
                  </div>
                  ) : null
               }
               {
                  state.tipoClientes.length > 0 ? (
                     <table className="table text-center table-bordered table-hover table-sm">
                        <thead className="thead-light">
                           <tr>
                              <th scope="col">Id</th>
                              <th scope="col">Nombre</th>
                              <th scope="col">Acciones</th>
                           </tr>
                        </thead>
                        <tbody>
                           {
                              state.tipoClientes.map( tipoCliente => (
                                 <tr key={tipoCliente.id}>
                                    <td>{tipoCliente.id}</td>
                                    <td>{tipoCliente.nombre}</td>
                                    <td>

                                       {
                                          props.usuario.permisos.Productos.ViewTipoCliente === true ? (
                                             <Link to={`/dashboard/productos/tipo-clientes/view/${tipoCliente.id}`}>
                                                <button type="button"style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#2984be'}}>
                                                   <FontAwesomeIcon icon="eye" />
                                                </button>
                                             </Link>
                                          ) : null
                                       }

                                       {
                                          props.usuario.permisos.Productos.EditTipoCliente === true ? (
                                             <Link to={`/dashboard/productos/tipo-clientes/edit/${tipoCliente.id}`}>
                                                <button type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#2984be'}}>
                                                   <FontAwesomeIcon icon="edit" />
                                                </button>
                                             </Link>
                                          ) : null
                                       }

                                       {
                                          props.usuario.permisos.Productos.DeleteTipoCliente === true ? (
                                          <button onClick={ () => {

                                                let typeModal = 'DeleteTipoCliente';

                                                let data = {
                                                   DeleteFunction: handleDeleteTipoCliente,
                                                   data: tipoCliente
                                                }

                                                handleOpenModal(data, typeModal)
                                                

                                             }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#2984be'}}>
                                             <FontAwesomeIcon icon="trash-alt" />
                                          </button>
                                          ) : null
                                       }

                                    </td>
                                 </tr>
                              ))
                           }
                        </tbody>
                     </table>
                  ) : null
               }
            </div>
         </div>
      </DashboardComponent> 
   )

}

let mapStateToProps = (state, props) => {
  return {
   conexion: state.logIn.conexion,
   usuario: state.logIn.usuario,
	dataUsers: state.dataUsers
  }
}

export default connect(mapStateToProps)(TipoClientesDashboard)