import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FetchGetTipoCliente from '../Containers/FetchGetTipoCliente/FetchGraphQLGetTipoCliente';

import DashboardComponent from '../../../dashboard/components/DashboardComponent.jsx';

let EditTipoCliente = props => {

    const [state, setState] = useState({
        tipoCliente: null,
    });

    useEffect( () => {

        let fetchGetTipoCliente = async () => {
          FetchGetTipoCliente({ setState, state, idTipoCliente: props.match.params.idTipoCliente })
        }

        fetchGetTipoCliente()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return(
        <DashboardComponent>
            <div className="row mx-5">
                <div className="col-12">
                    <h1 className="text-center">Ver Tipo de Cliente</h1>
                </div>
                <div className="col-12 d-none-print">
                    <Link to="/dashboard/productos/tipo-clientes">
                        <button style={{backgroundColor: 'transparent', border: 'none', color: '#2984be'}}><FontAwesomeIcon icon="arrow-left" style={{fontSize: '25px'}} /></button>
                    </Link>
                </div>

                {
                    state.tipoCliente !== null ? (
                        <div className="col-12">
                            <div className="mx-auto"><p className="text-center"><b>Id: </b> {state.tipoCliente.id}</p></div>
                            <div className="mx-auto"><p className="text-center"><b>Nombre: </b> {state.tipoCliente.nombre}</p></div>
                        </div>
                    ) : null
                }

            </div>
        </DashboardComponent>
    )
}

export default EditTipoCliente