import React from 'react';

import './css/GaleriaAnalisisClinicos.css';

const GaleriaAnalisisClinicos = props => (
	<section id="galeria-pacientes-analisis-clinicos" className="container-fluid sin-margen-y-padding">
		{props.children}
	</section>
)

export default GaleriaAnalisisClinicos;
