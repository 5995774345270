let consulta = (FactYPassPago) => {

  let facturas = FactYPassPago.facturas.map( factura => {

    let facturaReturn

    if(factura.factura === undefined){
      facturaReturn = `{
        noFactura: "${factura.noFactura}",
        concepto: "${factura.concepto}",
        productos: ${JSON.stringify(factura.productos)}
      }`
    }else{
      facturaReturn = `{
        noFactura: "${factura.noFactura}",
        factura: "${factura.factura}",
        concepto: "${factura.concepto}",
        productos: ${JSON.stringify(factura.productos)}
      }`
    }

    return facturaReturn

  })

  let correo = FactYPassPago.correo === null ? null : `"${FactYPassPago.correo}"`
  let fechaPass = FactYPassPago.fechaPass === null ? null : `"${FactYPassPago.fechaPass}"`
  let fechaDePago = FactYPassPago.fechaDePago === null ? null : `"${FactYPassPago.fechaDePago}"`
  let razonDeEntregaParcial = FactYPassPago.razonDeEntregaParcial === null ? null : `"${FactYPassPago.razonDeEntregaParcial}"`

  let productos = JSON.stringify(FactYPassPago.productos).replace(/{"/g, '{').replace(/":/g, ':').replace(/,"/g, ',')

  let string = `mutation {
    ordenesCompraMutation{
      addFacturasCompra(facturasCompraFullObj: {
        idOrden: ${FactYPassPago.idOrden}
        idUsuarioRecepcion: ${FactYPassPago.idUsuarioRecepcion}
        productos: ${productos}
        facturas: [${facturas.toString()}]
        fechaPass: ${fechaPass}
        fechaDePago: ${fechaDePago}
        correo: ${correo}
        razonDeEntregaParcial: ${razonDeEntregaParcial}
      }){
        mensaje
        idUsuarioRecepcion
        productos{
          nombre
          cantidad
          NoCatalogo
          descripcion
          observaciones
          tipo
          valorUnitario
          checkList {
            integridadDeEmpaque
            valorDelProducto
            temperaturaDeTransporte
            observacionDeRecibido
          }
        }
        facturas{
          noFactura
          factura
          concepto
          productos
        }
        passPago{
          id
          fechaPass
          fechaDePago
          estado
          emailTracking{
            estado
            fecha
            correo
          }
        }
        razonDeEntregaParcial
      }
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
