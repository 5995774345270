/*Import AdminObjetivosDashboard*/
import AdminObjetivosDashboard from '../../Dashboard/AdminObjetivos/containers/AdminObjetivosDashboard.jsx';

const DASHBOARD_ADMIN_OBJETIVOS = {
    component: AdminObjetivosDashboard,
    path: "/dashboard/admin-objetivos"
}

const routingAdminObjetivos = [ DASHBOARD_ADMIN_OBJETIVOS ]

export default routingAdminObjetivos