let consulta = (data) => {

  let string = `mutation{
    ordenesCompraMutation{
      addIdOfResponsableArea(idOrden: "${data.idOrden}", idUsuarioArea: "${data.idUsuarioArea}")
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
