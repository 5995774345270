import React from 'react';

import ContactoContainer from '../../../Containers/Contacto.jsx';

import LaboratoriosYHospitales from '../../Components/LaboratoriosYHospitales.jsx';

let Contacto = props => (
  <LaboratoriosYHospitales>
    <ContactoContainer/>
  </LaboratoriosYHospitales>
)

export default Contacto
