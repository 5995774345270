import React from 'react';

import Toggle from 'react-toggle';
import "react-toggle/style.css" // for ES6 modules

let CheckList = props => (
    <div className="row">

        <div className="mx-auto" style={{marginTop: '15px'}}>
          <div className="row">
            <p className="col-12 text-center" style={{marginTop: '0px'}}><b>¿La Cantidad de Muestras es {props.cantidadDeMuestras}?</b></p>
            <label className="mx-auto">
              <Toggle
                data-value-type="cantidadDeMuestrasTruly"
                defaultChecked={props.cantidadDeMuestrasTruly}
                onChange={props.handleInputChange} />
            </label>
          </div>
        </div>

        <div className="mx-auto" style={{marginTop: '15px'}}>
          <div className="row">
            <label htmlFor="Observaciones" className="col"><b>Observaciones:</b></label>
              <textarea ref={props.setRef} onChange={props.handleInputChange} data-value-type="observaciones"  className="col form-control-plaintext form-control-sm inputUserVal" id="Observaciones" value={props.observaciones} style={{'top': '-3px', 'border': 'solid 1px'}}/>
          </div>
        </div>

        {
          ( (props.usuario.tipo === 'colaborador') && ( props.usuario.permisos.Mensajeria.CheckListOrden === true ) ) ? 
          ( <div className="col-12">
              <div className="row">
                <button type="button" className="btn btn-outline-danger mx-auto" onClick={props.handleCheckList} style={{marginTop: '15px'}}>Cancelar Check List Ruta</button>
                <button type="button" className="btn btn-outline-success mx-auto" onClick={props.handleSubmitCheckList} style={{marginTop: '15px'}}>Guardar Check List</button>
              </div>
            </div>) : null
        }

        {
          props.checkListMensaje !== null ? (
            <div className="col-12 col-md-10 col-lg-8 mx-auto">
              <div className="row" style={{marginTop: '25px'}}>
                {
                  (props.checkListMensaje.response === 'success') ? (
                    <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.checkListMensaje.mensaje}</div>
                  ) : (
                    <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.checkListMensaje.mensaje}</div>
                  )
                }
              </div>
            </div>
          ) : null
        }

    </div>
)

export default CheckList