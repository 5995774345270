import React, { Component } from 'react';

import DashboardComponent from '../../dashboard/components/DashboardComponent.jsx';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

import MiCuenta from '../components/MiCuenta.jsx';

import GraphQL from '../../../../apiService/FetchGraphQLUsuarios.js';

import HandleUserInputChange from '../../../../Service/HandleInputChange';

class MiCuentaDashboard extends Component{

	constructor(props) {
    super(props);

		this.handleOpenModal = this.handleOpenModal.bind(this);
  }

	handleOpenModal(modal, typeModal){
    this.props.dispatch({
			type: `OPEN_MODAL`,
			payload: {
				modal,
				typeModal
			}
		})

  }

	handleInputChange(event){

    let value = event.target.value,
        usuario = event.target.dataset.usuario,
        type = event.target.dataset.valueType

    HandleUserInputChange(event, this, value, usuario, type)

  }

	componentDidMount() {

		if(this.props.conexion !== false){
			GraphQL(this);
		}

	}

	render(){
		if (this.props.conexion === false) {
			return <Redirect to='/login' />
		}else{
			return(
				<DashboardComponent>
					<div className="container-fluid" style={{minHeight: '78vh'}}>
						{
							this.props.dataUsers.map( (user) => {

								if((user.id === this.props.usuario.id) === true){
									return <MiCuenta key={user.id} usuario={user} handleOpenModal={this.handleOpenModal}/>
								}

								return null

							})
						}
					 </div>
				</DashboardComponent>
			)
		}
	}
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario,
		Modal: state.Modal,
    dataUsers: state.dataUsers.usuarios
  }
}

export default connect(mapStateToProps)(MiCuentaDashboard)
