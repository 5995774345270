import GraphQL from './ConsultaGraphQL';

import url from '../../../../../../apiService/ApiUrl';

// JavaScript Document
function handleError(err){
	console.log(`Request failed: ${err}`);
}

const deletePromocion = (This, idPromocion) => {
	//console.log(newPass, idUser)
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(idPromocion)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		This.props.dispatch({
			type: 'REMOVE_PROMOCION',
			payload: {
				idPromocion: res.data.dataUIMutation.deletePromocion.idPromocion
			}
		})

		//console.log(res)
		//return res
	})
	.catch(err => handleError(err));

	//termina fetch
}

export default deletePromocion;
