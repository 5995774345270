import React from 'react';

import Calendar from './Calendar.jsx';
import ObjetivosTable from './ObjetivosTable.jsx';
import MetasTable from './MetasTable.jsx';
import TareasTable from './TareasTable.jsx';

let AdminObjetivos = props => {

    return(
        <div className="container-fluid">
            {
                props.display === "Calendar" ? (
                    <Calendar handleOpenModal={props.handleOpenModal} onCheckBoxChange={props.onCheckBoxChange} state={props.state} display={props.display}/> 
                ) : null
            }
            {
                props.display === "Objetivos" ? (
                    <ObjetivosTable handleOpenModal={props.handleOpenModal} display={props.display} metas={props.metas} handleChangeDisplay={props.handleChangeDisplay} usuarios={props.usuarios} handleDeleteObjetivo={props.handleDeleteObjetivo} />
                ) : null
            }
            {
                props.display === "Metas" ? (
                    <MetasTable handleOpenModal={props.handleOpenModal} display={props.display} objetivos={props.objetivos} tareas={props.tareas} handleChangeDisplay={props.handleChangeDisplay} usuarios={props.usuarios} handleDeleteMeta={props.handleDeleteMeta} />
                ) : null
            }
            {
                props.display === "Tareas" ? (
                    <TareasTable handleOpenModal={props.handleOpenModal} display={props.display} metas={props.metas} handleChangeDisplay={props.handleChangeDisplay} usuarios={props.usuarios} handleDeleteTarea={props.handleDeleteTarea} stateCheckBox={props.state} handleChangeStateTarea={props.handleChangeStateTarea}/>
                ) : null
            }
        </div>
    )
}

export default AdminObjetivos