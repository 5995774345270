import React from 'react';

import { Route, Switch } from 'react-router-dom'; //Agregar Redirect despues

//Components
import AppLayout from '../AppLayout.jsx';

/*Web Page Routes*/
import RoutesPaginaWeb from './RoutesPaginaWeb';

/*Routes Login*/
import RoutesLogIn from './RoutesLogIn';

/*Routes Mensajeria*/
import RoutesMensajeria from './RoutesMensajeria';

/*Routes AdminObjeitvos*/
import RoutesAdminObjetivos from './RoutesAdminObjetivos';

/*Routes AdminCompras*/
import RoutesAdminCompras from './RoutesAdminCompras';

/*Routes Admin Productos*/
import RoutesAdminProductos from './RoutesAdminProductos';

/*Error404*/
import Error404 from '../Error404.jsx';

//import FacebookMuro from '../WebPage/Components/FacebookMuro.jsx';

const PrincipalRoutes = props => (
	<AppLayout>
		<Switch>

			{/*Inicio*/}
			{/*Route Inicio*/}
			<Route path="/" exact render={ () => <RoutesPaginaWeb.Inicio /> }/>

			{/*Route Muro de Facebook*/}
			{/*<Route path="/facebook-muro" exact render={ () =>  <FacebookMuro /> }/>*/}

			{/*Pacientes*/}
			{
				RoutesPaginaWeb.RoutesPacientes.map( (route, i) => <Route key={i} path={route.path} exact render={ ({ location }) => <route.component path={location.pathname} /> }/>)
			}

			{/*Laboratorios Y Hospitales*/}
			{
				RoutesPaginaWeb.RoutesHospYLab.map( (route, i) => <Route key={i} path={route.path} exact render={ ({ location }) => <route.component path={location.pathname} /> }/>)
			}

			{/*Empresas E EmpresasEIndustrias*/}
			{
				RoutesPaginaWeb.RoutesEmpEInd.map( (route, i) => <Route key={i} path={route.path} exact render={ ({ location }) => <route.component path={location.pathname} /> }/>)
			}

			{/*Veterinaria*/}
			{
				RoutesPaginaWeb.RoutesVeterinaria.map( (route, i) => <Route key={i} path={route.path} exact render={ ({ location }) => <route.component path={location.pathname} /> }/>)
			}

			{/*Servicios Medicos*/}
			<Route path="/servicios-medicos" exact render={ () => <RoutesPaginaWeb.ServiciosMedicos /> }/>

			{/*Route LogIn*/}
			{
				RoutesLogIn.map( (route, i) => <Route key={i} path={route.path} exact render={ ({ location }) => <route.component path={location.pathname} /> }/>)
			}

			{/*Mensajeria*/}
			{
				RoutesMensajeria.map( (route, i) => <Route key={i} path={route.path} exact render={ ({ location }) => <route.component location={location} path={location.pathname} /> }/>)
			}

			{/*Admin Objetivos*/}
			{
				RoutesAdminObjetivos.map( (route, i) => <Route key={i} path={route.path} exact render={ ({ location }) => <route.component path={location.pathname} /> }/>)
			}

			{/*Admin Compras*/}
			{
				RoutesAdminCompras.map( (route, i) => <Route key={i} path={route.path} exact render={ ({ location, match, history }) => <route.component location={location} match={match} history={history} /> }/>)
			}

			{/*Admin Productos*/}
			{
				RoutesAdminProductos.map( (route, i) => <Route key={i} path={route.path} exact render={ ({ location, match, history }) => <route.component location={location} match={match} history={history} /> }/>)
			}

			{/*Route Error404*/}
			<Route component={Error404} />

		</Switch>

	</AppLayout>
)

export default PrincipalRoutes;
