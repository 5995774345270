import React from 'react';
import './css/PortadaAnalisisClinicos.css';

const PortadaAnalisisClinicos = props => {

    return(
        <section id="portada-referencia-analisis-clinicos"className="container-fluid">    
            <div className="row py-5">
                <div className="col-12 col-xl-6 d-flex flex-column mr-md-0 pr-md-0">
                    <h1 className="text-justify pl-5">Laboratorio de Referencia Clínico</h1>
                    <div className="info-tab d-flex flex-column flex-md-row align-items-center mb-3">
                        <div id="servicio-icon" className="mx-2 mx-sm-3 my-2 mx-md-5 icon">
                            <img className="img-fluid p-3" src="/img/pacientes/analisisclinico/lab-icon.svg" alt="icono lab inicio pacientes" />
                        </div>
                        <div className="py-3 px-4 px-md-0">
                            <h3 className="text-center text-md-left">Servicio A:</h3>
                            <p className="mb-0">• Laboratorios<br/>
                                                • Sanatorios<br/> 
                                                • Hospitales<br/>
                                                • Veterinarias</p>
                        </div>
                    </div>
                    <div className="info-tab d-flex flex-column flex-md-row align-items-center mb-3">
                        <div id="calendar-icon" className="mx-2 mx-sm-3 my-2 mx-md-5 icon">
                            <img className="img-fluid p-3" src="/img/pacientes/analisisclinico/calendar-icon.svg" alt="icono personal inicio pacientes" />
                        </div>
                        <div className="py-3 px-4 px-md-0">
                            <h3 className="text-center text-md-left">Servicio De:</h3>
                            <p className="mb-0">Lunes a domingo</p>
                        </div>
                    </div>
                    <div className="info-tab d-flex flex-column flex-md-row align-items-center mb-3">
                        <div id="equipo-icon" className="mx-2 mx-sm-3 my-2 mx-md-5 icon">
                            <img className="img-fluid p-3" src="/img/pacientes/analisisclinico/microscope-icon.svg" alt="icono lab inicio pacientes" />
                        </div>
                        <div className="py-3 px-4 px-md-0">
                            <h3 className="text-center text-md-left">Equipo</h3>
                            <p className="mb-0">Equipo de última generación</p>
                        </div>
                    </div>
                    <div className="info-tab d-flex flex-column flex-md-row align-items-center mb-3">
                        <div id="message-icon" className="mx-2 mx-sm-3 my-2 mx-md-5 icon">
                            <img className="img-fluid p-3" src="/img/pacientes/analisisclinico/message-icon.svg" alt="icono lab inicio pacientes" />
                        </div>
                        <div className="py-3 px-4 px-md-0">
                            <h3 className="text-center text-md-left">Mensajería</h3>
                            <p className="mb-0">Para la recolección de muestras</p>
                        </div>
                    </div>
                    <div className="info-tab d-flex flex-column flex-md-row align-items-center mb-3">
                        <div id="wire-people-icon" className="mx-2 mx-sm-3 my-2 mx-md-5 icon">
                            <img className="img-fluid p-3" src="/img/pacientes/analisisclinico/wire-people.svg" alt="icono lab inicio pacientes" />
                        </div>
                        <div className="py-3 px-4 px-md-0">
                            <h3 className="text-center text-md-left">Alianzas</h3>
                            <p className="mb-0">Alianzas estratégicas con laboratorios internacionales</p>
                        </div>
                    </div>
                    <div className="info-tab d-flex flex-column flex-md-row align-items-center mb-3">
                        <div id="resultados-en-linea-icon" className="mx-2 mx-sm-3 my-2 mx-md-5 icon">
                            <img className="img-fluid p-3" src="/img/pacientes/analisisclinico/resultados-icon.svg" alt="icono lab inicio pacientes" />
                        </div>
                        <div className="py-3 px-4 px-md-0">
                            <h3 className="text-center text-md-left">Resultados en línea</h3>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-6 ml-0 pl-xl-0 d-flex justify-content-center">
                    <img id="img-portada" className="img-fluid" src="/img/pacientes/analisisclinico/labYHospPortada.jpg" alt="portada Inicio Pacientes" />
                </div>
            </div>
        </section>
    )

}

export default PortadaAnalisisClinicos