// JavaScript Document
import GraphQL from './ConsultaGraphQLChangeProductos';

import url from '../../../../../apiService/ApiUrl.js';

function handleError(err, This){
	This.setState({
		loading: false
	})
	console.log(`Request failed: ${err}`);
}

const fetchGraphQChangeProductos = (This, data) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(data)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		This.setState({
			loading: false,
			orden: {
				...This.state.orden,
				productos:res.data.ordenesCompraMutation.changeProductosToNewOrden.oldOrden.productos
			},
			displayCambiarProductos: false
		})

	})
	.catch(err => handleError(err, This));

	//termina fetch
}

export default fetchGraphQChangeProductos;
