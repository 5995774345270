import React from 'react';

import './css/Historia.css';

const Historia = (props) => (
	<section id="seccion-historia" className="container-fluid sin-margen-y-padding section">
		<div id="anchor-historia" className="section"></div>
		<div className="col-lg-4 col-md-3 col-sm-4 sin-margen-y-padding" id="contenedor-sub-historia">
			<h3>Quienes Somos</h3>
		</div>
		<div className="container-fluid">
			<div className="row justify-content-md-center">
				<div id="contenedor-texto-historia" className="col-xs-offset-1 col-xs-10 col-md-offset-2 col-md-8">
					<div className="col d-flex justify-content-center">
						{props.children}
					</div>
				</div>
			</div>
		</div>
	</section>
)


export default Historia;
