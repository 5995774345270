let consulta = (tipoCliente) => {

  let string = `mutation{
    productosMutation{
      editTipoCliente(id: "${tipoCliente.id}", nombre: "${tipoCliente.nombre}"){
        mensaje
        tipoCliente{
          id
          nombre
        }
      }
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
