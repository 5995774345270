import GraphQL from './ConsultaGraphQL';

import url from './ApiUrl.js';
import urlencode from 'urlencode';

function handleError(err){
	//console.log(`Request failed: ${err}`);
}

const logIn = (This) => {

	const options = {
		method: 'GET',
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(`${url}?query=query${urlencode(GraphQL())}`, options)
	.then(res => res.json())
	.then( (res) => {

    This.props.dispatch({
		//props.dispatch({
			type: `FIRST_LOAD`,
			payload: {
				data: res.data.dataUI
			}
		})

    //console.log(res.data.dataUI)

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default logIn;
