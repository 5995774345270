let consulta = (tipoCliente) => {

  let string = `mutation{
    productosMutation{
      insertTipoCliente(nombre: "${tipoCliente.nombre}"){
        id
        nombre
      }
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
