import React from 'react';

import ContactoContainer from '../../../Containers/Contacto.jsx';

import EmpresasEIndustrias from '../../Components/EmpresasEIndustrias.jsx';

let Contacto = props => (
  <EmpresasEIndustrias>
    <ContactoContainer/>
  </EmpresasEIndustrias>
)

export default Contacto
