/*General*/
import AnalisisClinicos from '../../../WebPage/Components/AnalisisClinicos/AnalisisClinicos.jsx';

/*Pacientes*/
//import QuienesSomosPacientes from '../../../WebPage/Pacientes/QuienesSomos/Components/QuienesSomos.jsx';
import PacientesServiciosMedicos from '../../../WebPage/Pacientes/ServiciosMedicos/Components/ServiciosMedicos.jsx';
import PacientesResultadosEnLinea from '../../../WebPage/Pacientes/ResultadosEnLinea/Containers/ResultadosEnLinea.jsx';
import PacientesPromociones from '../../../WebPage/Pacientes/Promociones/Containers/Promociones';
import PacientesContacto from '../../../WebPage/Pacientes/Contacto/Components/Contacto.jsx';

/*const QUIENES_SOMOS_PACIENTES = {
    component: QuienesSomosPacientes,
    path: "/pacientes"
}*/

const ANALISIS_CLINICOS_PACIENTES = {
    component: AnalisisClinicos,
    path: "/pacientes/analisis-clinicos"
}

const SERVICIOS_MEDICOS_PACIENTES = {
    component: PacientesServiciosMedicos,
    path: "/pacientes/servicios-medicos"
}

const RESULTADOS_EN_LINEA_PACIENTES = {
    component: PacientesResultadosEnLinea,
    path: "/pacientes/resultados-en-linea"
}

const PROMOCIONES_PACIENTES = {
    component: PacientesPromociones,
    path: "/pacientes/promociones"
}

const CONTACTO_PACIENTES = {
    component: PacientesContacto,
    path: "/pacientes/contacto"
}

let routesPacientes = [ ANALISIS_CLINICOS_PACIENTES, SERVICIOS_MEDICOS_PACIENTES, RESULTADOS_EN_LINEA_PACIENTES, PROMOCIONES_PACIENTES, CONTACTO_PACIENTES ]

export default routesPacientes