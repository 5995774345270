import GraphQL from './ConsultaGraphQL';

import url from '../../../../../../../apiService/ApiUrl';

// JavaScript Document
function handleError(err, This){
	console.log(`Request failed: ${err}`);
	This.setState({
		loading: false,
		addPromocionMensaje: {
			response: 'failure',
			mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
		}
	})
}

const addPromocion = (This, usuarioJSON) => {
	//console.log(usuarioJSON)
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(usuarioJSON)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		This.setState({
			titulo: "",
			promocion: "",
			imagen: "",
			loading: false,
			addPromocionMensaje: {
				response: 'success',
				mensaje: res.data.dataUIMutation.addPromocion.mensaje
			}
		})

		This.props.dispatch({
			type: 'ADD_PROMOCION',
			payload: {
				promocion: res.data.dataUIMutation.addPromocion.promocion
			}
		})
		
	})
	.then( () => setTimeout( () => This.setState({
		addPromocionMensaje: null	
	}), 3000) )
	.catch(err => handleError(err, This))
	.then( () => setTimeout( () => This.setState({
		addPromocionMensaje: null	
	}), 3000) );

	//termina fetch
}

export default addPromocion;
