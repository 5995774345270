/*Import AdminComprasDashboard*/
import DashboardQR from '../../Dashboard/AdminCompras/Containers/QRScanner/DashboardQR';
import ListOrdenesCompras from '../../Dashboard/AdminCompras/Containers/ListOrdenesCompra';
import VerOrdeneCompra from '../../Dashboard/AdminCompras/Containers/VerOrdenCompra';
import RealizarOrdenCompras from '../../Dashboard/AdminCompras/Containers/RealizarOrden/RealizarOrdenDashboard';
import AgregarProductoCompra from '../../Dashboard/AdminCompras/Containers/RealizarOrden/AgregarProductoCompra';
import ListPassPagos from '../../Dashboard/AdminCompras/Containers/ListPassPago';
import VerPassPago from '../../Dashboard/AdminCompras/Containers/VerPassPago';
import VerProductosCompras from 'App/Dashboard/AdminCompras/Containers/VerProductosCompras';
import EditProductoCompra from '../../Dashboard/AdminCompras/Containers/EditProductoCompra';

const QR_COMPRAS = {
    component: DashboardQR,
    path: "/dashboard/compras/qr"
}

const LIST_ORDENES_COMPRAS = {
    component: ListOrdenesCompras,
    path: "/dashboard/admin-compras/ordenes"
}

const ORDEN_COMPRA = {
    component: VerOrdeneCompra,
    path: "/dashboard/admin-compras/orden-compra/:idOrden"
}

const REALIZAR_ORDENES_COMPRAS = {
    component: RealizarOrdenCompras,
    path: "/dashboard/admin-compras/realizar-orden"
}

const AGREGAR_PRODUCTO_COMPRAS = {
    component: AgregarProductoCompra,
    path: "/dashboard/admin-compras/agregar-producto"
}

const LIST_PASS_PAGOS = {
    component: ListPassPagos,
    path: "/dashboard/admin-compras/pass-pagos"
}

const PASS_PAGO = {
    component: VerPassPago,
    path: "/dashboard/admin-compras/pass-pago/:idPassPago"
}

const LIST_PRODUCTOS_COMPRAS = {
    component: VerProductosCompras,
    path:"/dashboard/admin-compras/productos" 
}

const EDIT_PRODUCTO_COMPRAS = {
    component: EditProductoCompra,
    path:"/dashboard/admin-compras/edit-producto/:idProducto" 
}

const routingCompras = [ QR_COMPRAS, 
    LIST_ORDENES_COMPRAS, 
    REALIZAR_ORDENES_COMPRAS, 
    AGREGAR_PRODUCTO_COMPRAS, 
    ORDEN_COMPRA, 
    LIST_PASS_PAGOS, 
    PASS_PAGO,
    LIST_PRODUCTOS_COMPRAS,
    EDIT_PRODUCTO_COMPRAS
]

export default routingCompras