import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import PromocionesContainer from '../Components/Promociones.jsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DeletePromocionFetch from './DeletePromocionFetch/FetchDeletePromocion';

class Promociones extends Component {

  constructor(props) {
		super(props);

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleDeletePromocion = this.handleDeletePromocion.bind(this);

  }

  handleOpenModal(modal, typeModal){

    this.props.dispatch({
			type: `OPEN_MODAL`,
			payload: {
				modal,
				typeModal
			}
		})

  }

  handleDeletePromocion(idPromocion){
    DeletePromocionFetch(this, idPromocion)
  }

  render(){
    return(
      <section id="seccion-promociones" className="container-fluid sin-margen-y-padding">

        <div style={{position: 'relative', top: '0px', width: '100%'}}>

          <h1 className="col-12">PROMOCIONES</h1>

            <div className="container">

              <div className="row">

                {
                  this.props.promociones.map( promocion => (
                    <PromocionesContainer key={promocion.id} promoId={promocion.id} conexion={this.props.conexion} usuario={this.props.usuario} handleDeletePromocion={this.handleDeletePromocion} >
                      <div className="flip-card">
                        <div className="flip-card-inner">
                          <div className="flip-card-front">
                            <img className="img-fluid" src={promocion.imagen} alt={promocion.titulo} style={{zIndex: 1}}/>
                            <div className="fondo-promocion" style={{zIndex: 2}}>
                              <h3>{promocion.titulo}</h3>
                            </div>
                          </div>
                          <div className="flip-card-back">
                            <div className="borde-izquierdo-azul col-1"></div>
                            <div className="contenidoProm col-11 d-flex flex-column">
                              <h3 className="text-justify col-12">{promocion.titulo}</h3>
                              <p className="text-justify col-12">{promocion.promocion}</p>
                              <div className="col-12 text-center">
                                <Link to="/pacientes/contacto" className="btn btn-primary">
                                  Contáctanos por Formulario <FontAwesomeIcon icon="envelope-open-text"/>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </PromocionesContainer>
                  ) )
                }
                

                {
                  ((this.props.conexion === true) && (this.props.usuario.permisos.AdminUI === true)) ? (
                    <div className="col-12 text-center mt-5">
                      <button type="button" className="btn btn-outline-info" onClick={ () => {
                        this.handleOpenModal('_','AddPromocion')
                      }}>Agregar Promoción <FontAwesomeIcon icon="plus-circle" /></button>
                    </div>
                  ) : null
                }


              </div>

            </div>

        </div>
      </section>
    )
  }
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario,
    promociones: state.dataUI.promociones
  }
}

export default connect(mapStateToProps)(Promociones)
