import React, { Component } from 'react';

import GraphQL from '../../../../../../../apiService/FetchGraphQLMensajeria.js';

import GraphQLUsuarios from '../../../../../../../apiService/FetchGraphQLUsuarios.js';

import GraphQLEmpresas from '../../../../../../../apiService/FetchGraphQLEmpresas.js';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

import TableAgregarOrden from '../components/TableAgregarOrden.jsx';

import TableOrdenNoAgregada from '../components/TableOrdenNoAgregada.jsx';

import Select from 'react-select';

import Toggle from 'react-toggle';
import "react-toggle/style.css" // for ES6 modules

import DashboardComponent from '../../../../../dashboard/components/DashboardComponent.jsx';

import GraphQLInsertRuta from './FetchInsertRuta/fetchGraphQL.js';

import GraphQLPlantillasRutasMensajeria from '../../../../../../../apiService/FetchGraphQLPlantillasRutasMensajeria.js';

import GraphQLInsertOrdenes from './FetchInsertOrdenes/fetchGraphQL.js';

class RealizarRutas extends Component {

    constructor(props) {
		super(props);
		
		this.state = {
			ordenesDesde: "",
			ordenesHasta: "",
            selectedOptionMensajeros: null,
            selectedOptionPlantillas: null,
            nombreRuta: "",
            ordenesRuta: [],
            mensajeInsertRuta: null,
            fechaRuta: null,
            usarPlantilla: false
        }
        
        this.setDate = this.setDate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUsarPlantilla = this.handleUsarPlantilla.bind(this);
        this.handleChangePlantillas = this.handleChangePlantillas.bind(this);
        this.handleChangeMensajero = this.handleChangeMensajero.bind(this);
        this.handleAddOrdenesRuta = this.handleAddOrdenesRuta.bind(this);
        this.handleRemoveOrdenesRuta = this.handleRemoveOrdenesRuta.bind(this);
        this.handleInputNombreRutaChange = this.handleInputNombreRutaChange.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
    }

    handleSubmit(event){
            
        event.preventDefault()

        //let stringRutas = this.state.ordenesRuta.toString()

        if(this.state.usarPlantilla === true){

           let ordenes = this.state.selectedOptionPlantillas.value.ordenes

           ordenes.forEach( orden => {

                let hora = parseInt(orden.horaDeOrden.replace(/:(.*)/g,'')), //Se quita lo que está despues de los : y los puntos
                minutos = parseInt(orden.horaDeOrden.replace(/^(.*?):/g,'')), //Se quita lo que esta antes de los : y los puntos
                fecha = this.state.fechaRuta

                orden.fechaDeRecoleccion = new Date(fecha.setHours(hora, minutos));

                orden.idMensajero = this.state.selectedOptionMensajeros.value.id

                orden.idCreador = this.props.usuario.id

                delete orden.horaDeOrden

           })

           GraphQLInsertOrdenes(this, ordenes)

        }else{
            let data = {
                idMensajero: this.state.selectedOptionMensajeros.value.id,
                nombreRuta: this.state.nombreRuta,
                ordenesRuta: this.state.ordenesRuta,
                fechaRuta: this.state.fechaRuta
            }
    
            GraphQLInsertRuta(this, data)
        }

    }

    handleUsarPlantilla(){
        if(this.state.usarPlantilla === false){
            this.setState({
                usarPlantilla: true,
                ordenesRuta: []
            })
        }else{
            this.setState({
                usarPlantilla: false,
                selectedOptionPlantillas: null,
                ordenesRuta: []
            })
        }
    }

    handleInputNombreRutaChange(event){
        this.setState({nombreRuta: event.target.value});
    }
    
    handleChangeMensajero = selectedOptionMensajeros => {

        this.setState({
          selectedOptionMensajeros,
          ordenesRuta: []
        });
    
        //console.log(`Option selected:`, selectedOption);
    
    }

    handleChangePlantillas = selectedOptionPlantillas => {

        this.setState({
          selectedOptionPlantillas
        });
    
        //console.log(`Option selected:`, selectedOption);
    
    }

    handleAddOrdenesRuta(solicitudMensajeria){
        let ordenesRuta = this.state.ordenesRuta
        ordenesRuta.push(solicitudMensajeria.id)
        this.setState({
            ordenesRuta
        })
    }

    handleRemoveOrdenesRuta(solicitudMensajeria){

        let ordenesRuta = this.state.ordenesRuta

        let newOrdenes = ordenesRuta.filter( order => order !== solicitudMensajeria.id)

        this.setState({
            ordenesRuta: newOrdenes
        })
    }

    componentDidMount(){

        let date = new Date()

		let dia 
		(date.getDate()) < 10 ? dia = `0${date.getDate()}` : dia = `${date.getDate()}`
		let mes
        (date.getMonth() + 1) < 10 ? mes = `0${date.getMonth() + 1}` : mes = `${date.getMonth() + 1}`
		let year = date.getFullYear()
		//Fecha
        let fecha = `${year}-${mes}-${dia}`
        
        let FechaRutaArray = fecha.split('-'),
            mesFechaRuta = FechaRutaArray[1] - 1
            FechaRutaArray[1] = mesFechaRuta
            let fechaRuta = new Date(...FechaRutaArray)

		this.setState({
			ordenesDesde: fecha,
            ordenesHasta: fecha,
            fechaRuta
        })
        

        let arrayOfMensajeriaValues = [],
        allMensajeriaValuesFalse = true

        if(this.props.usuario !== null){
            if(this.props.usuario.permisos !== null){
                arrayOfMensajeriaValues = Object.values(this.props.usuario.permisos.Mensajeria)
                allMensajeriaValuesFalse = arrayOfMensajeriaValues.every(v => v === false)
            }
        }

        if( (this.props.conexion !== false) && (this.props.usuario.permisos !== null) && ((Object.entries(this.props.usuario.permisos.Mensajeria).length > 0) === true) && (allMensajeriaValuesFalse === false) && (this.props.usuario.permisos.Mensajeria.RealizarRuta === true) ){

            GraphQLUsuarios(this);

            GraphQLEmpresas(this);
            
            GraphQLPlantillasRutasMensajeria(this);

            let fechaToTimeStamp = new Date(fecha.replace(/-/g, '/')),
                    fechaDesdeTimestampString = fechaToTimeStamp.setHours(0,0,0,0),
                    fechaHastaTimestampString = fechaToTimeStamp.setHours(23,59,59,0);
                    
                    let fechaDesdeString = new Date(parseInt(fechaDesdeTimestampString)),
                            fechaHastaString = new Date(parseInt(fechaHastaTimestampString))
                            
                    //console.log(fechaDesdeString)
                    //console.log(fechaHastaString)
            
            GraphQL(this, fechaDesdeString, fechaHastaString);

        }

    }

    setButton = element => {
		if(element.dataset.modalType === 'viewUser'){
			this.button = element.id;
			console.log(element.id);
		}else if(element.dataset.modalType === 'editUser'){
			this.button = element.id;
			//console.log(element);
		}
	}

    setDate(event) {

        let FechaRutaArray = event.target.value.split('-'),
            mes = FechaRutaArray[1] - 1
            FechaRutaArray[1] = mes
            let fechaRuta = new Date(...FechaRutaArray)
		
		let fechaToTimeStampDesde = new Date(this.state.ordenesDesde.replace(/-/g, '/')),
            fechaToTimeStampHasta = new Date(this.state.ordenesHasta.replace(/-/g, '/'))
                
        this.setState({
            ordenesDesde: event.target.value,
            ordenesHasta: event.target.value,
            ordenesRuta: [],
            fechaRuta
        })
        fechaToTimeStampDesde = new Date(event.target.value.replace(/-/g, '/'))
        fechaToTimeStampHasta = new Date(event.target.value.replace(/-/g, '/'))

		/*if(type === "ordenDesde"){
			this.setState({
				ordenesDesde: event.target.value
			})
			fechaToTimeStampDesde = new Date(event.target.value.replace(/-/g, '/'))
		}else if(type === "ordenHasta"){
			this.setState({
				ordenesHasta: event.target.value
			})
			fechaToTimeStampHasta = new Date(event.target.value.replace(/-/g, '/'))
        }*/

		let fechaDesdeTimestampString = fechaToTimeStampDesde.setHours(0,0,0,0),
			fechaHastaTimestampString = fechaToTimeStampHasta.setHours(23,59,59,0);
			
		let fechaDesdeString = new Date(parseInt(fechaDesdeTimestampString)),
			fechaHastaString = new Date(parseInt(fechaHastaTimestampString))
						
				//console.log(fechaDesdeString)
				//console.log(fechaHastaString)
		
        GraphQL(this, fechaDesdeString, fechaHastaString);

    }
    
    handleOpenModal(modal, typeModal){
        this.props.dispatch({
            type: `OPEN_MODAL`,
            payload: {
                modal,
                typeModal
            }
        })
    }

    render(){

        let fechaDesde = new Date(this.state.ordenesDesde.replace(/-/g, '/')),
				fechaDesdeTimestamp = fechaDesde.setHours(0,0,0,0);
		let fechaHasta = new Date(this.state.ordenesHasta.replace(/-/g, '/')),
                fechaHastaTimestamp = fechaHasta.setHours(23,59,59,0);
                
		let fechaOrdenes = {
			ordenesDesde: fechaDesdeTimestamp,
			ordenesHasta: fechaHastaTimestamp
        }

		let SelectMensajeroArray = this.props.usuarios.filter( usuario => {
			return usuario.colaborador.puesto === 'Mensajero'
		})
	
		let SelectMensajero = Array.from(SelectMensajeroArray, usuario => {
			return { value: usuario, label: `${usuario.nombre} ${usuario.apellidos}` } //value: empresa.nombre_comercial
        })

        SelectMensajero.unshift({value: null, label: 'Ninguno'})

        let SelectPlantillaArray = Array.from(this.props.plantillasRutasMensajeria, plantilla => {
			return { value: plantilla, label: `${plantilla.nombrePlantilla}` } //value: empresa.nombre_comercial
        })

        SelectPlantillaArray.unshift({value: null, label: 'Ninguna'})

        //console.log(SelectPlantillaArray)

        let ordenesAddTableIds = this.state.ordenesRuta,
            ordenesAddTable = this.props.solicitudesMensajeria.filter(function(orden) {
                return ordenesAddTableIds.indexOf(orden.id) !== -1;
            }),
            ordenesRemoveTable = this.props.solicitudesMensajeria.filter( orden => {
                return ordenesAddTableIds.indexOf(orden.id) === -1;
            })

            /*ordenesRemoveTable = array1.filter(function(e) {
                return array2.indexOf(e) > -1;
            });*/
        
        //console.log(this.state)

        //Codigo de Redirect
        let arrayOfMensajeriaValues = [],
        allMensajeriaValuesFalse = true

        if(this.props.usuario !== null){
            if(this.props.usuario.permisos !== null){
                arrayOfMensajeriaValues = Object.values(this.props.usuario.permisos.Mensajeria)
                allMensajeriaValuesFalse = arrayOfMensajeriaValues.every(v => v === false)
            }
        }

        if (this.props.conexion === false) {
			return <Redirect to='/login' />
		}else if((this.props.usuario.permisos === null) || ((Object.entries(this.props.usuario.permisos.Mensajeria).length > 0) !== true) || (allMensajeriaValuesFalse === true) || (this.props.usuario.permisos.Mensajeria.RealizarRuta === false)){
            return <Redirect to='/dashboard' />
        }else{
            return(
                <DashboardComponent>
                    <div className="container-fluid">
                        <form onSubmit={this.handleSubmit} style={{marginBottom: '50px'}}>
    
                            <div className="form-row">
                                <h1 className="text-center col-12">Crear Rutas</h1>
                                <div className="form-group col-12 col-md-6 mx-auto">
                                    <label htmlFor="nombreRuta">Nombre de la Ruta</label>
                                    <input onChange={this.handleInputNombreRutaChange} value={this.state.nombreRuta} type="text" className="form-control" id="nombreRuta" aria-describedby="nombreRutaHelp" placeholder="Ingresa el nombre de la ruta" required />
                                    <small id="nombreRutaHelp" className="form-text text-muted">El Nombre de la Ruta.</small>
                                </div>
                                <div className="form-group col-12 col-sm-6 mx-auto">
                                    <label htmlFor="ordenesDesde">Fecha de Ruta:</label>
                                    <input id="ordenesDesde" name="ordenesDesde" className="col" type="date" value={this.state.ordenesDesde} 
                                        onChange={(event) => {
                                            this.setDate(event)
                                        }} />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="mx-auto col-12 col-md-7" style={{marginTop: '15px'}}>
                                    <div className="row">
                                    <p className="col-lg-6 col-12 text-center"><b>¿Quiere usar alguna plantilla?</b></p>
                                    <label className="mx-auto">
                                        <Toggle
                                        id="usarPlantilla"
                                        defaultChecked={this.state.usarPlantilla}
                                        onChange={this.handleUsarPlantilla} />
                                    </label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-row">
                                {/*<h3 className="text-center col-12">Filtrar Ordenes Por Mensajero</h3>*/}
                                <div className="form-group col-12 col-md-6 mx-auto" style={{marginTop: '25px'}}>
                                    <div className="row">
                                        <label htmlFor="react-select-3-input" className="col-lg-6 col-12"><b>Selecciona un Mensajero</b></label>
                                        <Select
                                                className="col-lg-6 col-12"
                                                placeholder="Escoje un Mensajero"
                                                value={this.state.selectedOptionMensajero}
                                                onChange={this.handleChangeMensajero}
                                                options={SelectMensajero}
                                        />
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.usarPlantilla === false ? (
                                    <div className="row">
                                        <h2 className="col-12 text-center">Ordenes Agregadas a la Ruta</h2>
                                        {
                                            ordenesAddTable.length === 0 ? (<h4 className="col-12 text-center" style={{paddingTop: '25px', paddingBottom: '25px'}}>No hay ordenes</h4>) : (
                                                <div className="col-12">
                                                    <TableAgregarOrden setButtonViewUser={this.setButton} setButtonEditUser={this.setButton} solicitudesMensajeria={ordenesAddTable} handleOpenModal={this.handleOpenModal} usuarios={this.props.usuarios} empresas={this.props.empresas} usuario={this.props.usuario} fechaOrdenes={fechaOrdenes} mensajeroFilter={this.state.selectedOptionMensajeros} handleRemoveOrdenesRuta={this.handleRemoveOrdenesRuta}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                ) : null
                            }

                            {
                                this.state.usarPlantilla === true ? (
                                    <div className="form-row">
                                        <div className="form-group col-12 col-md-6 mx-auto" style={{marginTop: '25px'}}>
                                            <div className="row">
                                                <label htmlFor="react-select-3-input" className="col-lg-6 col-12"><b>Selecciona una Ruta</b></label>
                                                <Select
                                                        className="col-lg-6 col-12"
                                                        placeholder="Escoje una Plantilla"
                                                        value={this.state.selectedOptionPlantillas}
                                                        onChange={this.handleChangePlantillas}
                                                        options={SelectPlantillaArray}
                                                />
                                            </div>
                                        </div>
                                    </div> 
                                ) : null
                            }

                            {
                                this.state.usarPlantilla === false ? (
                                    <div className="row">
                                        <h2 className="col-12 text-center">Ordenes No Agregadas a la Ruta</h2>
                                        {
                                            ( (ordenesRemoveTable.length === 0) || ((this.state.selectedOptionMensajeros === null) || (this.state.selectedOptionMensajeros.value === null)) ) ? (<h4 className="col-12 text-center" style={{paddingTop: '25px', paddingBottom: '25px'}}>No hay ordenes</h4>) : (
                                                <div className="col-12">
                                                    <TableOrdenNoAgregada setButtonViewUser={this.setButton} setButtonEditUser={this.setButton} solicitudesMensajeria={ordenesRemoveTable} handleOpenModal={this.handleOpenModal} usuarios={this.props.usuarios} empresas={this.props.empresas} usuario={this.props.usuario} fechaOrdenes={fechaOrdenes} mensajeroFilter={this.state.selectedOptionMensajeros} handleAddOrdenesRuta={this.handleAddOrdenesRuta}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                ) : null
                            }

                            <div className="row">
                                <div className="col-12 col-sm-8 col-md-5 col-lg-3 mx-auto">
                                    <button type="submit" className="btn btn-outline-primary col-12">Crear Ruta</button>
                                </div>
                            </div>
                            {
                            this.state.mensajeInsertRuta !== null ? (
                                <div className="row" style={{marginTop: '25px'}}>
                                {
                                    (this.state.mensajeInsertRuta.response === 'success') ? (
                                    <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.mensajeInsertRuta.mensaje}</div>
                                    ) : (
                                    <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.mensajeInsertRuta.mensaje}</div>
                                    )
                                }
                                </div>
                            ) : null
                            }
                        </form>
                    </div>
                </DashboardComponent>
            )
        }

    }

}

let mapStateToProps = (state, props) => {
    return {
      conexion: state.logIn.conexion,
      usuario: state.logIn.usuario,
      solicitudesMensajeria: state.dataMensajeria.solicitudesMensajeria,
      plantillasRutasMensajeria: state.dataMensajeria.plantillasRutasMensajeria,
      usuarios: state.dataUsers.usuarios,
      empresas: state.dataEmpresas.empresas
    }
}
  
export default connect(mapStateToProps)(RealizarRutas)