import React, { Component } from 'react';

//import miembrosdeslick from '../../../MyModules/miembrosdeslick.js';

import MiembrosComponent from '../Components/Miembros';

import Galeria from '../../Components/Galeria';

const RenderComponent = props => (
	<div>
		<div>
			<div className="contenedor-de-texto-reconocimiento">
				<span>{props.children}</span>
			</div>
		</div>
	</div>
)

class Miembros extends Component{

	constructor(props){
		super(props)

		this.state = {
			width: 769
		}

	}

	handleResize = () => {
		this.setState({ width: window.innerWidth });
	};

	componentDidMount(){
		//miembrosdeslick.mountMiembros();
		window.addEventListener("resize", this.handleResize);
	}

	componentWillUnmount(){
		//miembrosdeslick.unMountMiembros();
		window.removeEventListener("resize", this.handleResize);
	}

	render(){

		let elementsToShow

		const urls = [
			{
				img: '/img/Inicio/glabsFinal.png',
				alt: 'Logo Glabs'
			},
			{
				img: '/img/Inicio/agexportlabs.png',
				alt: 'Logo Agexport'
			},
			{
				img: '/img/Inicio/agexportguate.png',
				alt: 'Logo Agexport Guatemala'
			}
		]

		if(this.state.width < 576){
			elementsToShow = 1
		}else if(this.state.width >= 576 && this.state.width < 768){
			elementsToShow = 2
		}else if(this.state.width >= 768){
			elementsToShow = 3
		}

		return(
			<MiembrosComponent id="seccion-miembros-inicio" className="container-fluid sin-margen-y-padding">

				<div className="col-lg-4 col-md-3 col-sm-4 sin-margen-y-padding" id="contenedor-sub-miembros-inicio">
					<h3>Participamos En:</h3>
				</div>

				<div id="slider-container-miembros" className="container slider-container">

					<div className="col-xs-12">

						<div className="slider-miembros col-xs-12">

							<Galeria RenderComponent={RenderComponent} urlImgs={urls} options={{
								spaceBetween: 0,
								slidesPerView: elementsToShow,
								pagination: false,
								navigation: false,
								loop: true,
							}} />

						</div>
					</div>
				</div>
			</MiembrosComponent>
		)
	}

}

export default Miembros;
