import { connect } from 'react-redux';

import React, { Component } from 'react';

import Jodit from '../../../Jodit/Components/Jodit';

class Vision extends Component {

  render(){

    let renderVal = this.props.dataUI,
				textVisionObj = renderVal.textos.find( text => {
					return text.id === 'tex004'
				})

    return(
      <div id="contenedor-vision" className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 contenedor-val-mis-pol">
        <div id="text-vision" className="row justify-content-md-center" style={{height: '100%'}}>
          <div id="anchor-vision"></div><div id="icono-vision" className="iconos-mis-vis-pol"><span className="icon-ojo"></span></div>
          <h3 className="col-12">VISIÓN</h3>
          <Jodit textObj={textVisionObj} type='updateText' />
        </div>
      </div>
    )
  }

}

let mapStateToProps = (state, props) => {
  return {
    dataUI: state.dataUI
  }
}

export default connect(mapStateToProps)(Vision);
