import React, { Component } from 'react';

import { connect } from 'react-redux';

import MensajeroTable  from './MensajeroTable.jsx';

import EveryoneTable from './EveryoneTable.jsx';

class UsersTable extends Component {

  constructor(props) {
		super(props);

		this.onLoadSubscription = this.onLoadSubscription.bind(this);
	}

  componentDidMount(){
    if(this.props.newDataOrden !== undefined){
      this.props.dispatch({
        type: 'FETCH_SOLICITUDES_MENSAJERIA',
        payload: {
          dataMensajeria: this.props.newDataOrden
        }
      })
    }
  }

  onLoadSubscription(){
    this.props.dispatch({
      type: 'FETCH_SOLICITUDES_MENSAJERIA',
      payload: {
        dataMensajeria: this.props.newDataOrden
      }
    })
  }

  render(){
    let usuario = this.props.usuario,
      solicitudesMensajeria,
      ordenesMensajero

    if(this.props.typeTable === 'ModalTable'){
      solicitudesMensajeria = this.props.solicitudesMensajeriaRutas
    }else{
    
      if((this.props.mensajeroFilter === null) || (this.props.mensajeroFilter.value === null)){

        solicitudesMensajeria = this.props.solicitudesMensajeria
        
        /*solicitudesMensajeria = this.props.solicitudesMensajeria.filter( solicitud => {
          return parseInt(solicitud.fechaDeRecoleccion) >= this.props.fechaOrdenes.ordenesDesde &&
                parseInt(solicitud.fechaDeRecoleccion) <= this.props.fechaOrdenes.ordenesHasta
        });*/

        /*solicitudesEntregadasEnSecretaria = this.props.solicitudesMensajeria.filter( solicitud => { 
          return solicitud.estado === "Entregado en Secretaria"
        })*/

        //console.log(solicitudesEntregadasEnSecretaria)
    
        if(this.props.usuarioFilter !== null){
          if(this.props.usuarioFilter.value !== null){
            solicitudesMensajeria = solicitudesMensajeria.filter( solicitud => {
              return solicitud.idUsuario === this.props.usuarioFilter.value.id
            })
            if(this.props.numeroDeOrdenFilter !== ''){
              solicitudesMensajeria = solicitudesMensajeria.filter( solicitud =>{
                return solicitud.id.includes(this.props.numeroDeOrdenFilter)
              })
            }
          }
        }else if(this.props.empresaFilter !== null){
          if(this.props.empresaFilter.value !== null){
            solicitudesMensajeria = solicitudesMensajeria.filter( solicitud => {
              return solicitud.idEmpresa === this.props.empresaFilter.value.id
            })
            if(this.props.numeroDeOrdenFilter !== ''){
              solicitudesMensajeria = solicitudesMensajeria.filter( solicitud =>{
                return solicitud.id.includes(this.props.numeroDeOrdenFilter)
              })
            }
          }
        }
    
        if(this.props.estadoFilter !== null){
    
          solicitudesMensajeria = solicitudesMensajeria.filter( solicitud => {
            return solicitud.estado === this.props.estadoFilter
          })
    
        }
    
        if(this.props.numeroDeOrdenFilter !== ''){
          solicitudesMensajeria = solicitudesMensajeria.filter( solicitud =>{
            return solicitud.id.includes(this.props.numeroDeOrdenFilter)
          })
        }
    
      }else if((this.props.mensajeroFilter !== null) || (this.props.mensajeroFilter.value !== null)){
    
        let solicitudesMensajeriaSinFilterMensajeria = this.props.solicitudesMensajeria.filter( solicitud => {
          return parseInt(solicitud.fechaDeRecoleccion) >= this.props.fechaOrdenes.ordenesDesde &&
                parseInt(solicitud.fechaDeRecoleccion) <= this.props.fechaOrdenes.ordenesHasta
        });
    
        solicitudesMensajeria = solicitudesMensajeriaSinFilterMensajeria.filter( solicitud => {
          return solicitud.idMensajero === this.props.mensajeroFilter.value.id
        })
    
        if(this.props.numeroDeOrdenFilter !== ''){
          solicitudesMensajeria = solicitudesMensajeria.filter( solicitud =>{
            return solicitud.id.includes(this.props.numeroDeOrdenFilter)
          })
        }
    
        if(this.props.usuarioFilter !== null){
          if(this.props.usuarioFilter.value !== null){
            solicitudesMensajeria = solicitudesMensajeria.filter( solicitud => {
              return solicitud.idUsuario === this.props.usuarioFilter.value.id
            })
            if(this.props.numeroDeOrdenFilter !== ''){
              solicitudesMensajeria = solicitudesMensajeria.filter( solicitud =>{
                return solicitud.id.includes(this.props.numeroDeOrdenFilter)
              })
            }
          }
        }else if(this.props.empresaFilter !== null){
          if(this.props.empresaFilter.value !== null){
            solicitudesMensajeria = solicitudesMensajeria.filter( solicitud => {
              return solicitud.idEmpresa === this.props.empresaFilter.value.id
            })
            if(this.props.numeroDeOrdenFilter !== ''){
              solicitudesMensajeria = solicitudesMensajeria.filter( solicitud =>{
                return solicitud.id.includes(this.props.numeroDeOrdenFilter)
              })
            }
          }
        }
    
        if(this.props.estadoFilter !== null){
    
          solicitudesMensajeria = solicitudesMensajeria.filter( solicitud => {
            return solicitud.estado === this.props.estadoFilter
          })
    
          if(this.props.numeroDeOrdenFilter !== ''){
            solicitudesMensajeria = solicitudesMensajeria.filter( solicitud =>{
              return solicitud.id.includes(this.props.numeroDeOrdenFilter)
            })
          }
    
        }
    
      }
      /*let ordenesMensajero = this.props.solicitudesMensajeria.filter( Solicitud => {
          return Solicitud.idMensajero === this.props.usuario.id
        })*/
      
      ordenesMensajero = solicitudesMensajeria.filter( Solicitud => {
        return Solicitud.idMensajero === this.props.usuario.id
      })
    }


    return(
      <div className="table-responsive">
        <table className="table text-center table-bordered table-hover table-sm">
          <thead className="thead-light">
            <tr>
              <th scope="col" className="d-none d-md-table-cell" >Id de Orden</th>
              <th scope="col">Nombre Empresa o Usuario</th>
              {
                ((this.props.usuario.tipo === 'colaborador') && (this.props.usuario.colaborador.puesto === 'Mensajero')) ? (
                  <th scope="col">Instrucciones</th>
                ) : null
              }
              {
                ((this.props.usuario.tipo === 'colaborador') && (this.props.usuario.colaborador.puesto !== 'Mensajero')) ? (
                  <th scope="col" className="d-none d-sm-table-cell" >Instrucciones O Notas</th>
                ) : null
              }
              <th scope="col" className="d-xs-table-cell d-sm-none" >Hora</th>
              <th scope="col" className="d-none d-sm-table-cell" >Fecha</th>
              {
                ((this.props.usuario.tipo === 'colaborador') && (this.props.usuario.colaborador.puesto === 'Mensajero')) ? (
                  <th scope="col" className="d-none d-sm-table-cell" >Estado</th>
                ) : null
              }
              {
                ((this.props.usuario.tipo === 'colaborador') && (this.props.usuario.colaborador.puesto !== 'Mensajero')) ? (
                  <th scope="col">Estado</th>
                ) : null
              }
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          {
            ( ((this.props.usuario.tipo === 'colaborador') && (this.props.usuario.colaborador.puesto === 'Mensajero')) ) ? (
              <MensajeroTable ordenesMensajero={ordenesMensajero} handleOpenModal={this.props.handleOpenModal} handleChangeEstadoSolicitud={this.props.handleChangeEstadoSolicitud} usuarios={this.props.usuarios} empresas={this.props.empresas} fechaOrdenes={this.props.fechaOrdenes} usuarioData={usuario} />
            ) : (
              <EveryoneTable ruta={this.props.ruta} solicitudesMensajeria={solicitudesMensajeria} handleOpenModal={this.props.handleOpenModal} handleDeleteOrdenMensajeria={this.props.handleDeleteOrdenMensajeria} handleChangeEstadoSolicitud={this.props.handleChangeEstadoSolicitud} usuario={this.props.usuario} usuarios={this.props.usuarios} empresas={this.props.empresas} fechaOrdenes={this.props.fechaOrdenes} usuarioData={usuario} />
            )
          }
        </table>
      </div>
    )
  }
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario,
    solicitudesMensajeria: state.dataMensajeria.solicitudesMensajeria,
    usuarios: state.dataUsers.usuarios,
    empresas: state.dataEmpresas.empresas
  }
}

export default connect(mapStateToProps)(UsersTable)
