import React from 'react';

import Navbar from '../../Inicio/Components/NewNavbarInicio/Containers/NavbarInicio'

import Footer from '../../../Components/NewFooter.jsx';

//Import MenuCMR
import MenuCMR from '../../../Dashboard/dashboard/containers/MenuCMR.jsx';

let LaboratoriosYHospitales = props => (
  <div>
    <Navbar />
    {props.children}
    <MenuCMR />
    <Footer />
  </div>
)

export default LaboratoriosYHospitales
