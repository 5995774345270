import React from 'react';

import './css/NavbarEmpresasEIndustrias.css';

let NavbarEmpresasEIndustrias = props => (
	<nav id="NavbarEmpresasEIndustrias" className="navbar navbar-expand-md navbar-default section">
		{props.children}
	</nav>
)

export default NavbarEmpresasEIndustrias;
