// JavaScript Document
import GraphQL from './ConsultaGraphQLDeleteTipoCliente';

import url from '../../../../../../apiService/ApiUrl.js';

function handleError(err){
	console.log(`Request failed: ${err}`);	
}

const fetchCreateTipoCliente = (setState, state, tipoCliente) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(tipoCliente)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	return fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		if(res){

			let newTiposClientes = state.tipoClientes.filter( tipoClienteFilter => tipoClienteFilter.id !== tipoCliente.id )

			setState({
				...state,
				tipoClientes: newTiposClientes
			})

			return 'success'

		}

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchCreateTipoCliente;
