import React from 'react';

import './css/SaludOcupacional.css';

const SaludOcupacional = (props) => (
  <div>
    <section id="seccion-salud-ocupacional-titulo" className="container-fluid sin-margen-y-padding">
    		<h1 className="col-12">SALUD OCUPACIONAL Y SEGURIDAD INDUSTRIAL</h1>
    </section>
    <section id="seccion-salud-ocupacional" className="container" style={{'marginTop' : '150px', 'marginBottom' : '100px'}}>

  		<div className="row flex-row-reverse">

  			<picture id="imagen" className="col-md-6 col-12">
  				<source srcSet="/img/saludocupacional/quimicahorizontal.jpg" media="(max-width: 768px)" className="col"/>
  				<img srcSet="/img/saludocupacional/quimica.jpg" className="col" alt="Imagen Servicios Medicos"/>
  			</picture>

  			<div className="col-md-6 col-12">
  				<div id="texto-salud-ocupacional" />
  				{props.children}
  			</div>

  		</div>
  	</section>

    <section id="seccion-mensaje" style={{"marginTop": "0px", "marginBottom": "25px"}}>
      <div className="container">
        <div id="mensaje" className="row" style={{"marginRight": "0px", "marginTop": "0px"}}>
          <span className="col-12" href="contacto.html" rel="external" data-ajax="false">
          <h3 className="col-12 text-center"><span className="icon-light-bulb"></span> CONSULTE NUESTROS SERVICIOS ADICIONALES</h3><br />
          <em><p className="col-12 text-center" style={{"textAlign": "center", "paddingLeft": "15px"}}>(Servicios de mensajería, asesorías, capacitaciones, entre otros)</p></em>
          </span>
        </div>
      </div>
    </section>

  </div>
)

export default SaludOcupacional;
