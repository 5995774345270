import React, { useState, useEffect } from 'react';

import Select from 'react-select'

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Toggle from 'react-toggle';
import "react-toggle/style.css" // for ES6 m

import FetchGraphQlGetTipoClientes from '../../TipoClientes/Containers/FetchGetTipoClientes/FetchGraphQLGetTipoClientes';

import DashboardComponent from '../../../dashboard/components/DashboardComponent.jsx';

import DataTecnicaExamen from './DataTecnicaExamen'; 

const optionsGruposObjetivos = [
    { value: 'pacientes', label: 'Pacientes' },
    { value: 'laboratoriosYHospitales', label: 'laboratorios y Hospitales' },
    { value: 'empresasEIndustrias', label: 'Empresas e Industrias' },
    { value: 'veterinaria', label: 'Veterinaria' },
]

let CreateProducto = props => {

    const [state, setState] = useState({
        idBiolabLogic: "",
        nombreCompleto: "",
        nombreAbreviado: "",
        nombreIngles: "",
        sinonimosNombre: "",
        descripcion: "",
        gruposObjetivos: [],
        activo: true,
        unidadDeNegocio: "",
        tipo: "Analisis de Laboratorio",
        clasificacion: "",
        costo: 0,
        aplicaDescuento: false,
        preciosTipoCliente: [],
        dataTecnicaExamen: {
            grupoAreaDeTrabajo: "",
            ordenDeReporte: "",
            codigoLIS: "",
            apareceEnPedido: true,
            alertaEspecial: "",
            informacionAdicional: "",
            reportarEnLinea: true,
            pruebaAcreditada: false,
            tiempoDeEntregaReferencia: 0,
            tiempoDeEntregaSucursales: 0,
            metodologia: "",
            tipoDeMuestra: "",
            recipienteUtilizado: "",
            tipoDeResultado: "",
            sifrasSignificativas: 0,
            dimensionales: "",
            valoresDeAnalisisClinicos: [],
            valoresDeAnalisisIndustriales: [],
            criterioDeCalidad: "",
            datosParaTomaDeMuestra: "",
            datosDeLaMuestra: "",
            limiteDeDeteccion: "",
        },
        idTipoClienteDefault: "",
        insertTipoClienteMensaje: null,
    });

    const [getData, setGetData] = useState({
        tipoClientes: []
    })

    useEffect( () => {

        let fetchGetTipoClientes = async () => {
            FetchGraphQlGetTipoClientes(setGetData)
        }

        fetchGetTipoClientes()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let handleInputChange = (event) => {

        event.preventDefault()

        setState({
            ...state,
            [event.target.id]: event.target.id === 'costo' ? parseInt(event.target.value) : event.target.value
        })

    }

    let handleSelectTipo = (event) => {

        setState({
            ...state,
            [event.target.id]: event.target.value,
            dataTecnicaExamen: event.target.value === 'Analisis de Laboratorio' ? ({
                grupoAreaDeTrabajo: "",
                ordenDeReporte: "",
                codigoLIS: "",
                apareceEnPedido: true,
                alertaEespecial: "",
                informacionAdicional: "",
                reportarEnLinea: true,
                pruebaAcreditada: false,
                tiempoDeEntregaReferencia: 0,
                tiempoDeEntregaSucursales: 0,
                metodologia: "",
                tipoDeMuestra: "",
                recipienteutilizado: "",
                tipoDeResultado: "",
                sifrasSignificativas: 0,
                dimensionales: "",
                valoresDeAnalisisClinicos: [],
                valoresDeAnalisisIndustriales: [],
                criterioDeCalidad: "",
                datosParaTomaDeMuestra: "",
                datosDeLaMuestra: "",
                limiteDeDetecion: "",
            }) : null
        })


    }

    let handleChangeInputDataTecnicaExamen = ({ event, type, data }) => {

        if(type === 'input'){
            setState({
                ...state,
                dataTecnicaExamen: {
                    ...state.dataTecnicaExamen,
                    [event.target.id]: event.target.value
                }
            })
        }else if(type === 'toggle'){
            setState({
                ...state,
                dataTecnicaExamen: {
                    ...state.dataTecnicaExamen,
                    [event.target.id]: !state.dataTecnicaExamen[event.target.id]
                }
            }) 
        }else if(type === 'number'){
            setState({
                ...state,
                dataTecnicaExamen: {
                    ...state.dataTecnicaExamen,
                    [event.target.id]: parseFloat(event.target.value)
                }
            }) 
        }else if(type === 'valoresDeAnalisisClinicos'){

            // Aqui me quede trabajando

            /* let newValue = data

            let stateValoresActuales = state.dataTecnicaExamen.valoresDeAnalisisClinicos.find( value => value === newValue ) */

        }


    }

    let handleSelect = ({ type, arrSelected }) => {

        let values = arrSelected.map( arr => arr.value )

        setState({
            ...state,
            [type]: values
        })

    }

    let handleToggle = ({ event }) => {
        setState({
           ...state,
           [event.target.id]: !state[event.target.id] 
        })
    }

    let handleSubmit = (event) => {

        event.preventDefault()

        /* let tipoCliente = {
            nombre: state.nombre
        } */

        // FetchCreateTipoCliente(setState, state, tipoCliente)

    }

    let handleSelectTipoClientes = arrSelected => {

        let preciosTipoClienteState = [...state.preciosTipoCliente]

        let idsPreciosTipoClienteArr = preciosTipoClienteState.map( precioTipoCliente => precioTipoCliente.idTipoCliente )

        let preciosTipoCliente = arrSelected.map( element => {

            let elementIsInPreciosTipoClientes = idsPreciosTipoClienteArr.includes(element.value.id)

            let precioTipoCliente

            if(elementIsInPreciosTipoClientes){
                precioTipoCliente = preciosTipoClienteState.filter( precioType => precioType.idTipoCliente === element.value.id )[0]
            }else{
                precioTipoCliente = {
                    idTipoCliente: element.value.id,
                    nombre: element.value.nombre,
                    precio: 0
                }
            }

            return precioTipoCliente
        })

        setState({
            ...state,
            preciosTipoCliente
        })

    }

    let handlePrecioTipoClienteChange = ({ idTipoCliente, event }) => {

        let preciosTipoClienteState = [...state.preciosTipoCliente]

        let tipoCliente = preciosTipoClienteState.filter( precioTipoCliente => precioTipoCliente.idTipoCliente === idTipoCliente)[0]

        tipoCliente[`${event.target.id}`] = parseFloat(event.target.value)

        let tipoClienteArr = [tipoCliente]

        let newStatePreciosTipoCliente = preciosTipoClienteState.map( obj => tipoClienteArr.find(o => o.idTipoCliente === obj.idTipoCliente) || obj )

        setState({
            ...state,
            preciosTipoCliente: newStatePreciosTipoCliente
        })

    }

    let tipoClientesOptions = getData.tipoClientes.map( tipoCliente => ({ label: tipoCliente.nombre, value: tipoCliente }))

    return(
        <DashboardComponent>
            <div className="row mx-5">
                <div className="col-12">
                    <h1 className="text-center">Crear Producto</h1>
                </div>
                <div className="col-12 d-none-print">
                    <Link to="/dashboard/productos/tipo-clientes">
                        <button style={{backgroundColor: 'transparent', border: 'none', color: '#2984be'}}><FontAwesomeIcon icon="arrow-left" style={{fontSize: '25px'}} /></button>
                    </Link>
                </div>
                <form className="col-12 my-5" onSubmit={handleSubmit}>

                    <div className="row">

                        <div className="mx-auto col-12 col-sm-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="idBiolabLogic"><b>Id Biolab:</b></label>
                                <input type="text" value={state.idBiolabLogic} id="idBiolabLogic" className="form-control" onChange={handleInputChange} required />
                            </div>
                        </div>

                        <div className="mx-auto col-12 col-sm-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="nombreCompleto"><b>Nombre Completo:</b></label>
                                <input type="text" value={state.nombreCompleto} id="nombreCompleto" className="form-control" onChange={handleInputChange} required />
                            </div>
                        </div>

                        <div className="mx-auto col-12 col-sm-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="nombreAbreviado"><b>Nombre Abreviado:</b></label>
                                <input type="text" value={state.nombreAbreviado} id="nombreAbreviado" className="form-control" onChange={handleInputChange} required />
                            </div>
                        </div>

                        <div className="mx-auto col-12 col-sm-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="nombreIngles"><b>Nombre Ingles:</b></label>
                                <input type="text" value={state.nombreIngles} id="nombreIngles" className="form-control" onChange={handleInputChange} required />
                            </div>
                        </div>

                        <div className="mx-auto col-12 col-sm-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="sinonimosNombre"><b>Sinónimos de Nombre:</b></label>
                                <input type="text" value={state.sinonimosNombre} id="sinonimosNombre" className="form-control" onChange={handleInputChange} required />
                            </div>
                        </div>

                        <div className="mx-auto col-12 col-sm-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="descripcion"><b>Descripción:</b></label>
                                <input type="text" value={state.descripcion} id="descripcion" className="form-control" onChange={handleInputChange} required />
                            </div>
                        </div>

                        <div className="mx-auto col-12 col-sm-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="descripcion"><b>Grupos Objetivos:</b></label>
                                <Select 
                                    options={optionsGruposObjetivos}
                                    onChange={ (arrSelected) => handleSelect({ type: "gruposObjetivos", arrSelected }) }
                                    placeholder="Selecciona Grupos Objetivos"
                                    isMulti
                                />
                            </div>
                        </div>

                        <div className="mx-auto col-12 col-sm-6 col-lg-4">
                            <div className="form-group row">
                                <div className="mx-auto" style={{ marginTop: '35px'}}>
                                    <label htmlFor="activo" className="text-center mr-3" style={{ top: '-6px', position: 'relative'}}><b className="text-center">Activo:</b></label>
                                    <Toggle 
                                        id="activo"
                                        checked={state.activo}
                                        onChange={ event => handleToggle({ event })}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mx-auto col-12 col-sm-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="unidadDeNegocio"><b>Unidad de Negocio:</b></label>
                                <input type="text" value={state.unidadDeNegocio} id="unidadDeNegocio" className="form-control" onChange={handleInputChange} required />
                            </div>
                        </div>

                        <div className="mx-auto col-12 col-sm-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="tipo"><b>Tipo:</b></label>
                                <select id="tipo" className="form-control" value={state.tipo} onChange={handleSelectTipo} required>
                                    <option value="Analisis de Laboratorio">Análisis de Laboratorio</option>
                                    <option value="Producto General">Producto General</option>
                                    <option value="Servicio">Servicio</option>
                                </select>
                            </div>
                        </div>

                        <div className="mx-auto col-12 col-sm-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="clasificacion"><b>Clasificación:</b></label>
                                <input type="text" value={state.clasificacion} id="clasificacion" className="form-control" onChange={handleInputChange} required />
                            </div>
                        </div>

                        <div className="mx-auto col-12 col-sm-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="costo"><b>Costo(Q.):</b></label>
                                <input type="number" min="0" value={state.costo.toString()} id="costo" className="form-control" onChange={handleInputChange} required />
                            </div>
                        </div>

                        <div className="mx-auto col-12 col-sm-6 col-lg-4">
                            <div className="form-group row">
                                <div className="mx-auto" style={{ marginTop: '35px'}}>
                                    <label htmlFor="activo" className="text-center mr-3" style={{ top: '-6px', position: 'relative'}}><b className="text-center">Aplica Descuento:</b></label>
                                    <Toggle 
                                        id="aplicaDescuento"
                                        checked={state.aplicaDescuento}
                                        onChange={ event => handleToggle({ event })}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mx-auto col-12 col-sm-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="TipoDeCliente"><b>Tipos de Cliente:</b></label>
                                <Select 
                                    options={tipoClientesOptions}
                                    onChange={ (arrSelected) => handleSelectTipoClientes(arrSelected) }
                                    placeholder="Selecciona TipoCliente"
                                    isMulti
                                />
                            </div>
                        </div>

                        {
                            state.preciosTipoCliente.length > 0 ? (
                                <div className="mx-auto col-12 col-sm-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="descripcion"><b>Cliente por Defecto:</b></label>
                                        <select id="idTipoClienteDefault" className="form-control" onChange={handleInputChange}>
                                            {
                                                state.preciosTipoCliente.map( precioTipoCliente => (
                                                    <option key={precioTipoCliente.idTipoCliente} value={precioTipoCliente.idTipoCliente} >{precioTipoCliente.nombre}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div> 
                            ) : null
                        }


                        <div className="form-group col-12 mx-auto mt-5">
                            {
                                state.preciosTipoCliente.length > 0 ? (
                                    <>
                                        <h2 className="text-center">Agregar Tipos de Cliente</h2>
                                        <div className="table-responsive mt-2">
                                            <table className="table text-center table-bordered table-hover table-sm">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">Nombre Tipo Cliente</th>
                                                        <th scope="col">Precio (Q.)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        state.preciosTipoCliente.map( precioTipoCliente => (
                                                            <tr key={precioTipoCliente.idTipoCliente}>
                                                                <td>{precioTipoCliente.nombre}</td>
                                                                <td>
                                                                    <label htmlFor="precio" className="form-label"><b>Precio:</b></label>
                                                                    <input id="precio" type="number" min="0" step=".01" className="form-control" placeholder="Precio Q." value={precioTipoCliente.precio.toString()}  onChange={ (e) => handlePrecioTipoClienteChange({ idTipoCliente: precioTipoCliente.idTipoCliente, event: e })}/>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                ) : null
                            }
                        </div>

                    </div>

                    {
                        state.tipo === 'Analisis de Laboratorio' ? <DataTecnicaExamen state={state} handleInputChange={handleChangeInputDataTecnicaExamen} /> : null
                    }

                    <div className="mx-auto text-center mb-4">
                        <button className="btn btn-outline-info" type="submit">Crear Producto</button>
                    </div>

                    {
                        state.insertTipoClienteMensaje !== null ? (
                            <div className="row">
                                {
                                    (state.insertTipoClienteMensaje.response === 'success') ? (
                                        <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{state.insertTipoClienteMensaje.mensaje}</div>
                                    ) : (
                                        <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{state.insertTipoClienteMensaje.mensaje}</div>
                                    )
                                }
                            </div>
                        ) : null
                    }
                </form>
            </div>
        </DashboardComponent>
    )
}

export default CreateProducto