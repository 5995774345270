import React, { Component } from 'react';

import { connect } from 'react-redux';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../../../../Modal/Components/Modal.jsx';

import ModalView  from './ModalView.jsx';

import CheckList from './CheckList.jsx';

import fetchCheckList from './CheckListFetch/FetchCheckListPromise.js';

import CheckListView from './CheckListView.jsx';

import AddOrdenRuta from './AddOrdenRuta.jsx';

class index extends Component {

  constructor(props){
    super(props)

    let solicitudesRuta = this.props.dataMensajeria.solicitudesMensajeria.filter( solicitud => {
      return this.props.ruta.ordenesRuta.includes(solicitud.id)
    }),
        solicitudesRutaRecoleccion = solicitudesRuta.filter( solicitud => {
          return solicitud.tipo === "RecoleccionMuestra"
        }),
        cantidadDePaquetesRecoleccion = solicitudesRutaRecoleccion.length,
        cantidadDePaquetesCancelados = solicitudesRutaRecoleccion.filter( orden => orden.estado === "Cancelado").length,
        cantidadDePaquetes = cantidadDePaquetesRecoleccion - cantidadDePaquetesCancelados

        /*cantidadDePaquetes = 0

        arrayCantidadDeMuestras.forEach( cantidad => {
          cantidadDePaquetes += cantidad;
        });*/

    this.state = {
      display: "ModalView",
      gelRefrigerante: false,
      condicionesHielera: false,
      muestras: false,
      cantidadDePaquetes: cantidadDePaquetes,
      cantidadDePaquetesTruly: false,
      observaciones: "",
      checkListMensaje: null
    }

    // This binding is necessary to make `this` work in the callback
    this.handleCheckList = this.handleCheckList.bind(this);
    this.handleCheckListView = this.handleCheckListView.bind(this);
    this.handleAddOrdenView = this.handleAddOrdenView.bind(this);
    this.handleChekListData = this.handleChekListData.bind(this);
    this.handleSubmitCheckList = this.handleSubmitCheckList.bind(this);
    this.handleFinalizarConCondicionesInadecuadas = this.handleFinalizarConCondicionesInadecuadas.bind(this);
  }

  componentDidMount(){

    if(this.props.ruta.checkList !== null){

      this.setState({
        gelRefrigerante: this.props.ruta.checkList.gelRefrigerante,
        condicionesHielera: this.props.ruta.checkList.condicionesHielera,
        muestras: this.props.ruta.checkList.muestras,
        cantidadDePaquetesTruly: this.props.ruta.checkList.cantidadDePaquetesTruly,
        observaciones: this.props.ruta.checkList.observaciones
      })
    }
  }

  handleCheckList(){

    let solicitudesRuta = this.props.dataMensajeria.solicitudesMensajeria.filter( solicitud => {
      return this.props.ruta.ordenesRuta.includes(solicitud.id)
    }),
        solicitudesRutaRecoleccion = solicitudesRuta.filter( solicitud => {
          return solicitud.tipo === "RecoleccionMuestra"
        }),
        cantidadDePaquetesRecoleccion = solicitudesRutaRecoleccion.length,
        cantidadDePaquetesCancelados = solicitudesRutaRecoleccion.filter( orden => orden.estado === "Cancelado").length,
        cantidadDePaquetes = cantidadDePaquetesRecoleccion - cantidadDePaquetesCancelados

    if(this.state.display === "ModalView"){
      this.setState({
        display: "CheckList"
      })
    }else{

      if(this.props.ruta.checkList !== null){
        this.setState({
          display: "ModalView",
          gelRefrigerante: this.props.ruta.checkList.gelRefrigerante,
          condicionesHielera: this.props.ruta.checkList.condicionesHielera,
          muestras: this.props.ruta.checkList.muestras,
          cantidadDePaquetesTruly: this.props.ruta.checkList.cantidadDePaquetesTruly,
          cantidadDePaquetes: cantidadDePaquetes,
          observaciones: this.props.ruta.checkList.observaciones,
          checkListMensaje: null
        })
      }else{
        this.setState({
          display: "ModalView",
          gelRefrigerante: false,
          condicionesHielera: false,
          muestras: false,
          cantidadDePaquetesTruly: false,
          cantidadDePaquetes: cantidadDePaquetes,
          observaciones: ""
        })
      }

    }
  }

  handleCheckListView(){
    if(this.state.display === "ModalView"){
      this.setState({
        display: "CheckListView"
      })
    }else{
      this.setState({
        display: "ModalView"
      })
    }
  }

  handleAddOrdenView(){
    if(this.state.display === "ModalView"){
      this.setState({
        display: "AddOrdenRuta"
      })
    }else{
      this.setState({
        display: "ModalView"
      })
    }
  }

  handleChekListData(event){

    let type = event.target.dataset.valueType

    let setStates = (This, type, value) => {

      let Obj = JSON.parse(`{"${type}": ${value}}`)

      This.setState(Obj)

    }

    if(type === "gelRefrigerante"){
      setStates(this, type, this.state.gelRefrigerante === false ? true : false)
    }else if(type === "condicionesHielera"){
      setStates(this, type, this.state.condicionesHielera === false ? true : false)
    }else if(type === "muestras"){
      setStates(this, type, this.state.muestras === false ? true : false)
    }else if(type === "cantidadDePaquetesTruly"){
      setStates(this, type, this.state.cantidadDePaquetesTruly === false ? true : false)
    }else if(type === "observaciones"){
      this.setState({
        observaciones: event.target.value
      })
    }

  }

  handleSubmitCheckList(){

    //Creando la Data del CheckList
    let dataCheckList = {
      ...this.state
    }

    //Eliminando del CheckList el display del modal y el Mensaje
    delete dataCheckList.display
    delete dataCheckList.checkListMensaje

    //Creando el objeto para Validar si el checklist tiene todo true
    let dataCheckListTrueValidate = {
      ...dataCheckList
    }

    //Eliminando de la Validacion del checklist Cantidad de Paquetes y Observaciones
    delete dataCheckListTrueValidate.cantidadDePaquetes
    delete dataCheckListTrueValidate.observaciones

    //Creando el Array de valores del objeto para validar
    let valuesOfDataCheckListTrueValidate = Object.values(dataCheckListTrueValidate)

    //filtrando del array los valores que sean true y creando un nuevo array con ellos
    let trueValuesOfDataCheckListTrueValidate = valuesOfDataCheckListTrueValidate.filter( value => {
      return value === true
    })

    //Comparando que el largo de los dos arrays sea el mismo para establecer un estado
    let estado
    if(valuesOfDataCheckListTrueValidate.length === trueValuesOfDataCheckListTrueValidate.length){
      estado = "Validado"
    }else{
      estado = "Validado Fuera de Condiciones"
    }

    //Agregando el Nombre de quien revisa al CheckList y asignando null a observaciones si es un array vacio
    dataCheckList.nombreQuienRevisa = `${this.props.usuario.nombre} ${this.props.usuario.apellidos}`
    dataCheckList.observaciones = dataCheckList.observaciones === "" ? dataCheckList.observaciones = null : dataCheckList.observaciones

    let data = {
      idRuta: this.props.ruta.id,
      estado,
      checkList: dataCheckList
    }

    fetchCheckList(this, data)

  }

  handleFinalizarConCondicionesInadecuadas(){

    //Creando la Data del CheckList
    let dataCheckList = {
      ...this.state
    }

    //Eliminando del CheckList el display del modal y el Mensaje
    delete dataCheckList.display
    delete dataCheckList.checkListMensaje

    //Creando el objeto para Validar si el checklist tiene todo true
    let dataCheckListTrueValidate = {
      ...dataCheckList
    }

    //Eliminando de la Validacion del checklist Cantidad de Paquetes y Observaciones
    delete dataCheckListTrueValidate.cantidadDePaquetes
    delete dataCheckListTrueValidate.observaciones

    //Agregando el Nombre de quien revisa al CheckList y asignando null a observaciones si es un array vacio
    dataCheckList.nombreQuienRevisa = `${this.props.usuario.nombre} ${this.props.usuario.apellidos}`
    dataCheckList.observaciones = dataCheckList.observaciones === "" ? dataCheckList.observaciones = null : dataCheckList.observaciones

    let data = {
      idRuta: this.props.ruta.id,
      estado: "Finalizado con Condiciones Inadecuadas",
      checkList: dataCheckList
    }

    fetchCheckList(this, data)

  }

  render(){

    let modalVisible = this.props.modalVisible,
    handleCloseModal = this.props.handleCloseModal

    return(
      <ModalContainer>
        <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
              <img className="brand-modal" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco" />
              <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                <div></div>
                <div></div>
              </button>
              </div>

              <div className="container-fluid" style={{marginTop: '25px'}}>

                {
                this.state.display === "ModalView" ? <ModalView ruta={this.props.ruta} dataUsers={this.props.dataUsers} dataSolicitudesMensajeria={this.props.dataSolicitudesMensajeria} handleOpenModal={this.props.handleOpenModal} usuarios={this.props.dataUsers.usuarios} dataEmpresas={this.props.dataEmpresas} handleDeleteOrdenMensajeria={this.props.handleDeleteOrdenMensajeria} handleChangeEstadoSolicitud={this.props.handleChangeEstadoSolicitud} usuario={this.props.usuario} handleCheckList={this.handleCheckList} handleCheckListView={this.handleCheckListView} handleFinalizarConCondicionesInadecuadas={this.handleFinalizarConCondicionesInadecuadas} handleAddOrdenView={this.handleAddOrdenView}/> : null
                }

                {
                  this.state.display === "CheckList" ? <CheckList handleCheckList={this.handleCheckList} usuario={this.props.usuario} gelRefrigerante={this.state.gelRefrigerante} condicionesHielera={this.state.condicionesHielera} muestras={this.state.muestras} cantidadDePaquetes={this.state.cantidadDePaquetes.toString()} cantidadDePaquetesTruly={this.state.cantidadDePaquetesTruly} observaciones={this.state.observaciones} handleInputChange={this.handleChekListData} handleSubmitCheckList={this.handleSubmitCheckList} checkListMensaje={this.state.checkListMensaje} /> : null
                }

                {
                  this.state.display === "CheckListView"  ? <CheckListView checkList={this.props.ruta.checkList} handleCheckListView={this.handleCheckListView} /> : null
                }

                {
                  this.state.display === "AddOrdenRuta"  ? <AddOrdenRuta handleAddOrdenView={this.handleAddOrdenView} usuario={this.props.usuario} ruta={this.props.ruta} usuarios={this.props.dataUsers.usuarios} empresas={this.props.dataEmpresas.empresas}/> : null
                }   
              
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
              </div>
            </div>
          </div>
        </ModalB4>
      </ModalContainer>
    )
  }
}

let mapStateToProps = (state, props) => {
  return {
		conexion: state.logIn.conexion,
    usuario: state.logIn.usuario,
    Modal: state.Modal,
    dataUsers: state.dataUsers,
    dataEmpresas: state.dataEmpresas,
    dataMensajeria: state.dataMensajeria
  }
}

export default connect(mapStateToProps)(index);
