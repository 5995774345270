import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { formatterTwoDecimals } from '../../../../../MyModules/formatterDecimals';

class ProductoTable extends Component {

    constructor(props){
        super(props)

        this.state = {
            view: "Table",
            producto: {
                id: "",
                nombre: "",
                valorUnitario: "0",
                cantidad: "0",
                NoCatalogo: "",
                descripcion: "",
                observaciones: "",
                tipo: "Insumo de Laboratorio"
            },
            filter: {
                nombre: ""
            }
        }

        this.onInputChange = this.onInputChange.bind(this);
        this.onSelectProducto = this.onSelectProducto.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);

    }

    onFilterChange(event){
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                [event.target.id]: event.target.value
            }
        })
    }

    onSelectProducto(producto){
        this.setState({
            ...this.state,
            view: "AddProducto",
            producto: {
                ...this.state.producto,
                nombre: producto.nombre,
                valorUnitario: producto.valorUnitario,
                NoCatalogo: producto.NoCatalogo,
                descripcion: producto.descripcion,
                observaciones: producto.observaciones,
                tipo: producto.tipo
            }
        })
    }

    onInputChange(event){
        this.setState({
            ...this.state,
            producto:{
                ...this.state.producto,
                [event.target.id]: event.target.value
            }
        })
    }

    render(){
        if((this.props.productos.length > 0) && (this.state.view === "Table")){

            let productos = this.state.filter.nombre === "" ? this.props.productos : this.props.productos.filter( producto => producto.nombre.toLowerCase().includes(this.state.filter.nombre.toLowerCase()) )
    
            return(
                <div className="row">
                    <div className="col-12">
                        <form>
                            <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                <label htmlFor="nombre" className="col"><b>Filtra por nombre de Producto:</b></label><input onChange={this.onFilterChange} type="text"  className="form-control" id="nombre" value={this.state.filter.nombre} placeholder="Escribe el Nombre de un Producto o Servicio" />
                                <small id="nombreSmall" className="form-text text-muted">Filtra por nombre de Producto.</small>
                            </div>
                        </form>
                    </div>
                    <div className="col-12">
                        <div className="table-responsive">
                            <table className="table text-center table-bordered table-hover table-sm">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Nombre Producto</th>
                                        <th scope="col" className="d-none d-md-table-cell">Descripción</th> 
                                        <th scope="col">Valor Unitario</th>
                                        <th scope="col">Acciones</th>
                                        {/*<th scope="col" className="d-none d-md-table-cell" >Teléfono</th>
                                        {/*<th scope="col" className="d-none d-xl-table-cell" >Correo</th>
                                        <th scope="col">Acciones</th>*/}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        productos.map( (producto) => {
            
                                            return(
                                                <tr key={producto.id}>
                                                    <td>{producto.nombre}</td>
                                                    <td className="d-none d-md-table-cell">{producto.descripcion}</td>
                                                    <td>Q. {formatterTwoDecimals.format(producto.valorUnitario)}</td>
                                                    <td>
                                                        <button type="button" onClick={() => {
                                                                this.onSelectProducto(producto)
                                                            }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                                                            <FontAwesomeIcon icon="plus-circle" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
            
                                        })
                                    }
            
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )
        }else if((this.props.productos.length > 0) && (this.state.view === "AddProducto")){
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4 col-lg-3 mx-auto">
                            <p className="text-center"><b>Nombre: </b>{this.state.producto.nombre}</p>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 mx-auto">
                            <p className="text-center"><b>Valor Unitario: </b>Q. {formatterTwoDecimals.format(this.state.producto.valorUnitario)}</p>
                        </div>
                        {
                            this.state.producto.NoCatalogo === null ? null : (
                                <div className="col-12 col-md-4 col-lg-3 mx-auto">
                                    <p className="text-center"><b>No Lote: </b>{this.state.producto.NoCatalogo}</p>
                                </div>
                            )
                        }
                        <div className="col-12 col-md-4 col-lg-3 mx-auto">
                            <p className="text-center"><b>Descripcion: </b>{this.state.producto.descripcion}</p>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 mx-auto">
                            <p className="text-center"><b>Observaciones: </b>{this.state.producto.observaciones}</p>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 mx-auto">
                            <p className="text-center"><b>Tipo: </b>{this.state.producto.tipo}</p>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 mx-auto">
                            <label>Cantidad: </label>
                            <input id="cantidad" type="number" value={this.state.producto.cantidad} onChange={this.onInputChange} />
                        </div>
                    </div>
                    <button className="mx-auto btn btn-outline-info" onClick={()=>{
                        delete this.state.producto.id
                        this.props.onAddProducto(this.state.producto)
                        this.setState({
                            ...this.state,
                            view: "Table",
                            producto: {
                                id: "",
                                nombre: "",
                                valorUnitario: "0",
                                cantidad: "0",
                                NoCatalogo: "",
                                descripcion: "",
                                observaciones: "",
                                tipo: "Insumo de Laboratorio"
                            }
                        })
                    }}>Agregar Producto a Orden</button>
                </div>
            )
        }else{
            return null
        }
    }
}

export default ProductoTable

