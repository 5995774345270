import HandleInsertInputChangeUsuario from './HandleInsertUserInputChange';
import HandleInsertInputChangeEmpresa from './HandleInsertInputChangeEmpresa';

let handleInsertInputChange = (event, This) => {

  let usuario = event.target.dataset.usuario,
      empresa = event.target.dataset.empresa

  //console.log(empresa)

  if(usuario !== undefined){
    HandleInsertInputChangeUsuario(event, This)
  }else if(empresa !== undefined){
    HandleInsertInputChangeEmpresa(event, This)
  }

  return null

}

export default handleInsertInputChange;
