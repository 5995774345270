import React from 'react';

import Select from 'react-select';

let OrdenEmpresa = props => {
  if(props.active === "active"){
    return(
        <div className="tab-pane fade show active col-12"  id="nav-ord-empresa" role="tabpanel" aria-labelledby="nav-empresa-tab">
          <div className="row">
            <label htmlFor="react-select-2-input" className="col-lg-6 col-12"><b>Selecciona una Empresa</b></label>
              <Select
                className="col-lg-6 col-12"
                placeholder="Escoje una empresa"
                defaultValue={props.defaultValue}
                onChange={props.handleChangeEmpresa}
                options={props.SelectEmpresa}
              />
          </div>
        </div>
    )
  }else{
    return(
      <div className="tab-pane fade col-12"  id="nav-ord-empresa" role="tabpanel" aria-labelledby="nav-empresa-tab">
        <div className="row">
          <label htmlFor="react-select-2-input" className="col-lg-6 col-12"><b>Selecciona una Empresa</b></label>
            <Select
              className="col-lg-6 col-12"
              placeholder="Escoje una empresa"
              defaultValue={props.defaultValue}
              onChange={props.handleChangeEmpresa}
              options={props.SelectEmpresa}
            />
        </div>
      </div>
    )
  }
}

export default OrdenEmpresa
