import React, { Component } from 'react';

import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import OrdenEmpresa from '../../../components/RealizarOrdenMuestras/OrdenEmpresa.jsx';

import OrdenUsuario from '../../../components/RealizarOrdenMuestras/OrdenUsuario.jsx';

import FetchAddOrden from './AddOrdenRutaFetch/FetchAddOrdenPromise.js';

class AddOrdenRuta extends Component {

  constructor(props){
    super(props)

    this.state = {
      instrucciones: "",
      cantidadDeMuestras: 0,
      fechaDeCreacion: "",
      tipo: "RecoleccionMuestra",
      estado: "Entregado a Laboratorio",
      newOrden: {
        selectedOptionEmpresas: null,
        selectedOptionUsuarios: null
      },
      AddOrdenMensaje: null
    }

    // This binding is necessary to make `this` work in the callback
    this.handleChangeEmpresaDatosPredefinidos = this.handleChangeEmpresaDatosPredefinidos.bind(this);
    this.handleChangeEmpresa = this.handleChangeEmpresa.bind(this);
    this.handleChangeUsuario = this.handleChangeUsuario.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAddOrden = this.handleAddOrden.bind(this);
  }

  handleChangeEmpresaDatosPredefinidos() {

    this.setState({
        newOrden:{
            ...this.state.newOrden,
            DatosPredefinidos: true
        }
    })

  }

  handleChangeEmpresa = selectedOptionEmpresas => {

    this.setState({
        newOrden:{
            ...this.state.newOrden,
            selectedOptionEmpresas,
            selectedOptionUsuarios: null
        }
    })

    //console.log(`Option selected:`, selectedOption);

  }

  handleChangeUsuario = selectedOptionUsuarios => {

    this.setState({
        newOrden:{
            ...this.state.newOrden,
            selectedOptionUsuarios,
            selectedOptionEmpresas: null
        }
    })

    //console.log(`Option selected:`, selectedOption);

  }

  handleInputChange(event){

    let type = event.target.dataset.valueType
    let value = event.target.value

    if(type === "instrucciones"){
      this.setState({
        instrucciones: value
      })
    }else if(type === "cantidadDeMuestras"){
      this.setState({
        cantidadDeMuestras: parseInt(value)
      })
    }

  }

  handleAddOrden(){

    let orden = Object.create(this.state)

    if(orden.newOrden.selectedOptionEmpresas !== null){
      orden.idEmpresa = orden.newOrden.selectedOptionEmpresas.value.id
      delete orden.newOrden
    }else if(orden.newOrden.selectedOptionUsuarios !== null){
      orden.idUsuario = orden.newOrden.selectedOptionUsuarios.value.id
      delete orden.newOrden
    }

    orden.fechaDeCreacion = Date.now().toString()
    orden.idMensajero =  this.props.ruta.idMensajero

    FetchAddOrden(this, orden, this.props.ruta)

  }


  render(){

    let SelectEmpresa = Array.from(this.props.empresas, empresa => {
      return { value: empresa, label: empresa.nombre_comercial }
    })

    let SelectUsuarios = Array.from(this.props.usuarios, usuario => {
      return { value: usuario, label: `${usuario.nombre} ${usuario.apellidos}` } 
    })

    return (
      <div className="row">
  
        <button style={{backgroundColor: 'transparent', border: 'none', color: '#2984be', marginLeft: '15px'}} onClick={ () => {

          this.setState({
            instrucciones: "",
            cantidadDeMuestras: 0,
            fechaDeCreacion: "",
            tipo: "RecoleccionMuestra",
            estado: "Entregado a Laboratorio",
            newOrden: {
              selectedOptionEmpresas: null,
              selectedOptionUsuarios: null
            },
            AddOrdenMensaje: null
          })

          this.props.handleAddOrdenView()

        }}><FontAwesomeIcon icon="arrow-left" style={{fontSize: '25px'}} /></button>

        <div className="col-12">
          
          <div className="row">

            <nav className="col-12 col-md-6 flex-md-column" style={{marginTop: '30px'}}>
                <div className="nav nav-pills nav-fill" id="nav-tab" role="tablist">
                <a className="nav-item nav-link active" id="nav-empresa-tab" data-toggle="tab" href="#nav-ord-empresa" role="tab" aria-controls="nav-ord-empresa" aria-selected="true" onClick={this.handleChangeEmpresaDatosPredefinidos}><FontAwesomeIcon icon="building" /> Orden Empresa</a>
                <a className="nav-item nav-link" id="nav-usuario-tab" data-toggle="tab" href="#nav-ord-usuario" role="tab" aria-controls="nav-ord-usuario" aria-selected="false" onClick={this.handleChangeUsuarioDatosPredefinidos}><FontAwesomeIcon icon="user" /> Orden Usuario</a>
                </div>
            </nav>

            <div className="tab-content col-md-6 col-12 " id="nav-tabContent" style={{marginTop: '30px'}} >

              <OrdenEmpresa active="active" selectedOptionEmpresas={this.state.newOrden.selectedOptionEmpresas} SelectEmpresa={SelectEmpresa} handleChangeEmpresa={this.handleChangeEmpresa} />

              <OrdenUsuario selectedOptionUsuarios={this.state.newOrden.selectedOptionUsuarios} SelectUsuarios={SelectUsuarios} handleChangeUsuario={this.handleChangeUsuario} />

            </div>

          </div>
        </div>

        <div className="col-11 mx-auto">
          <div className="row">

            <div className="col-12 col-md-6 mx-auto" style={{marginTop: '15px'}}>
              <div className="row">
                <label htmlFor="Instrucciones" className="col"><b>Instrucciones:</b></label>
                <input type="text" ref={this.props.setRef} onChange={this.handleInputChange} data-value-type="instrucciones"  className="col form-control-plaintext form-control-sm inputUserVal" id="Instrucciones" value={this.state.instrucciones} style={{'top': '-3px', 'border': 'solid 1px'}} required/>
              </div>
            </div>

            <div className="col-12 col-md-6 mx-auto" style={{marginTop: '15px'}}>
              <div className="row">
                <label htmlFor="cantidadDeMuestras" className="col"><b>Cantidad de Muestras:</b></label>
                <input type="number" ref={this.props.setRef} onChange={this.handleInputChange} data-value-type="cantidadDeMuestras"  className="col form-control-plaintext form-control-sm inputUserVal" id="cantidadDeMuestras" value={this.state.cantidadDeMuestras.toString()} style={{'top': '-3px', 'border': 'solid 1px'}} required/>
              </div>
            </div>

          </div>
        </div>


  
          {
            ( (this.props.usuario.tipo === 'colaborador') && ( this.props.usuario.permisos.Mensajeria.CreateOrden === true ) ) ? 
            ( <div className="col-12">
                <div className="row">
                  <button type="button" className="btn btn-outline-success mx-auto" onClick={this.handleAddOrden} style={{marginTop: '15px'}}>Agregar Orden</button>
                </div>
              </div>) : null
          }
  
          {
            this.state.AddOrdenMensaje !== null ? (
              <div className="col-12 col-md-10 col-lg-8 mx-auto">
                <div className="row" style={{marginTop: '25px'}}>
                  {
                    (this.state.AddOrdenMensaje.response === 'success') ? (
                      <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.AddOrdenMensaje.mensaje}</div>
                    ) : (
                      <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.AddOrdenMensaje.mensaje}</div>
                    )
                  }
                </div>
              </div>
            ) : null
          }
  
      </div>
    )
  }

}

let mapStateToProps = (state, props) => {
  return {
		conexion: state.logIn.conexion,
    usuario: state.logIn.usuario
  }
}

export default connect(mapStateToProps)(AddOrdenRuta)