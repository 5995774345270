import updateModalText from './updateModalText';
import updateModalTitulo from './updateModalTitulo';

let initialState = null,
    dataUI = (state = initialState, action) => {
    switch(action.type) {
      case 'FIRST_LOAD':
      return action.payload.data
      case 'UPDATE_TEXT':
        state.textos.map( texto => {
          if(texto.id === action.payload.id){
            texto.texto = action.payload.data
          }
          return texto
        })
        return {
            ...state,
            textos: state.textos
        }
      case 'UPDATE_TEXT_MODAL':
        return updateModalText(state, action)
        /*state.modals.map( modal => {
          if(modal.id === action.payload.id){
            modal.texto = action.payload.data
          }
          return modal
        })
        return {
            ...state,
            modals: state.modals
        }*/
      case 'UPDATE_TITULO_MODAL':
        return updateModalTitulo(state, action)
      case 'ADD_PROMOCION': {

        let promociones = state.promociones,
            newPromocion = action.payload.promocion,
            newPromociones = [...promociones, newPromocion],
            newState = {
              ...state,
              promociones: newPromociones
            }

        return newState

      }
      case 'REMOVE_PROMOCION': {

        let idPromocion = action.payload.idPromocion

        let newPromociones = state.promociones.filter( prom => prom.id !== idPromocion)

        let newState = {
          ...state,
          promociones: newPromociones
        }

        return newState
        
      }
      default:
        return state
    }
  }


export default dataUI
