import React, {Component} from 'react';

import ReconocimentosComponent from '../Components/Reconocimientos.jsx';
import Galeria from '../../Components/Galeria';

const RenderComponent = props => (
	<div>
		<div>
			<div className="contenedor-de-texto-reconocimiento">
				<div>
					<p>{props.children}</p>
				</div>
			</div>
		</div>
	</div>
)

class Reconocimientos extends Component{

	constructor(props){
		super(props)

		this.state = {
			width: 769
		}
	}

	handleResize = () => {
		this.setState({ width: window.innerWidth });
	}

	componentDidMount(){
		//miembrosdeslick.mountReconocimento();
		window.addEventListener("resize", this.handleResize);
	}

	componentWillUnmount(){
		//miembrosdeslick.unMountReconocimento();
		window.removeEventListener("resize", this.handleResize);
	}

	render(){
		let elementsToShow

		let texts = [
			'ACREDITADO NTG/ISO 15189:2012 OGA-LE-061-15 Laboratorios clínicos',
			'ACREDITADO NTG/ISO/IEC 17025:2017 OGA-LE-044-11 Laboratorios de ensayo'
		]

		if(this.state.width < 576){
			elementsToShow = 1
		}else if(this.state.width >= 576 && this.state.width < 768){
			elementsToShow = 2
		}else if(this.state.width >= 768){
			elementsToShow = 2
		}

		return(
			<ReconocimentosComponent id="seccion-reconocimiento-inicio" className="container-fluid sin-margen-y-padding">

				<div className="col-lg-4 col-md-3 col-sm-4 sin-margen-y-padding" id="contenedor-sub-reconocimiento-inicio">
							<h3>Reconocimiento de Calidad Internacional:</h3>
				</div>

				<div id="slider-container-reconocimiento" className="container slider-container">

					<div className="col-xs-12">
						<div className="controller">
							<button className="prev-reconocimiento"><span className="glyphicon glyphicon-chevron-left"></span></button>
							<button className="next-reconocimiento"><span className="glyphicon glyphicon-chevron-right"></span></button>
						</div>

						<div className="slider-reconocimiento col-xs-12">

							<Galeria RenderComponent={RenderComponent} texts={texts} options={{
								spaceBetween: 0,
								slidesPerView: elementsToShow,
								pagination: false,
								navigation: false,
								loop: true,
							}} />

						</div>
					</div>
				</div>

			</ReconocimentosComponent>
		)
	}

}

export default Reconocimientos;
