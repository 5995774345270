import React, { Component } from 'react';

import DashboardComponent from '../../dashboard/components/DashboardComponent.jsx';

import GraphQL from '../../../../apiService/FetchGraphQLEmpresas.js';

import GraphQLUsuarios from '../../../../apiService/FetchGraphQLUsuarios.js';

import deleteEmpresa from './DeleteEmpresaFetch/FetchDeleteEmpresa.js';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

import EmpresaTable from '../components/EmpresaTable.jsx';

class AdminEmpresaDashboard extends Component{

	constructor(props) {
    super(props);

		this.handleOpenModal = this.handleOpenModal.bind(this);
		this.handleDeleteEmpresa = this.handleDeleteEmpresa.bind(this);
  }

	handleOpenModal(modal, typeModal){
    this.props.dispatch({
			type: `OPEN_MODAL`,
			payload: {
				modal,
				typeModal
			}
		})

  }

	handleDeleteEmpresa(empresa){

		return deleteEmpresa(this, empresa)

	}

	componentDidMount() {

		let arrayOfAdminEmpresaValues = [],
		allAdminEmpresaValuesFalse = true

		if(this.props.usuario !== null){

			if(this.props.usuario.permisos !== null){
				arrayOfAdminEmpresaValues = Object.values(this.props.usuario.permisos.AdminEmpresa)
				allAdminEmpresaValuesFalse = arrayOfAdminEmpresaValues.every(v => v === false)
			}

			if(this.props.conexion === true && this.props.usuario.permisos !== null && ((Object.entries(this.props.usuario.permisos.AdminEmpresa).length > 0) === true) && (allAdminEmpresaValuesFalse === false) ){
	
				GraphQL(this);
		
				GraphQLUsuarios(this);
				
			}

		}



  	}

	render(){

		let arrayOfAdminEmpresaValues = [],
		allAdminEmpresaValuesFalse = true

		if(this.props.usuario !== null){
			if(this.props.usuario.permisos !== null){
				arrayOfAdminEmpresaValues = Object.values(this.props.usuario.permisos.AdminEmpresa)
				allAdminEmpresaValuesFalse = arrayOfAdminEmpresaValues.every(v => v === false)
			}
		}

		if (this.props.conexion === false || this.props.usuario.permisos === null || ((Object.entries(this.props.usuario.permisos.AdminEmpresa).length > 0) !== true) || (allAdminEmpresaValuesFalse === true) ) {
			return <Redirect to='/login' />
		}else{
			let empresas
			if(this.props.dataEmpresas.search && this.props.dataEmpresas.search.length){
				empresas = this.props.dataEmpresas.search
			}else if(this.props.dataEmpresas.emptyFilter === true) {
				empresas = []
			}else{
				empresas = this.props.dataEmpresas.empresas
			}
			return(
				<DashboardComponent>
					<div className="container-fluid" style={{minHeight: '78vh'}}>
						<EmpresaTable setButtonViewEmpresa={this.setButton} setButtonEditEmpresa={this.setButton} dataEmpresas={this.props.dataEmpresas} empresas={empresas} usuario={this.props.usuario} handleOpenModal={this.handleOpenModal} handleDeleteEmpresa={this.handleDeleteEmpresa}/>
		      		</div>
				</DashboardComponent>
			)
		}
	}
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario,
	dataEmpresas: state.dataEmpresas
  }
}

export default connect(mapStateToProps)(AdminEmpresaDashboard)
