import React, { Component } from 'react';

import { connect } from 'react-redux';

import moment from 'moment';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../../../Modal/Components/Modal.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import updateStateTareaFetch from '../UpdateStateTareaFetch/FetchUpdateStateTareaPromise';

class TareaView extends Component {

  constructor(props){
    super(props)

    this.state = {
      objetivosChecked: false,
      metasChecked: false,
      tareasChecked: true
    }

    // This binding is necessary to make `this` work in the callback
    this.handleChangeStateTarea = this.handleChangeStateTarea.bind(this);

  }
  
  handleChangeStateTarea(tarea){
        
    let newTarea = {
        ...tarea,
        estado: "Finalizado",
        fechaFinal: new Date()
    }

    updateStateTareaFetch(this, newTarea, this.state)

  }

  render(){

    let tarea = this.props.tareas.filter( tareaState => tareaState.id === this.props.tarea.id)[0],

    //let tarea = this.props.tarea,
      sucursal = tarea.idSucursal !== null ? this.props.sucursales.filter( sucursal => sucursal.id === tarea.idSucursal)[0] : null,
      modalVisible = this.props.modalVisible,
      handleCloseModal = this.props.handleCloseModal,

      usuarios = this.props.usuarios

      tarea.creador = usuarios.filter( usuario => usuario.id === tarea.idCreador )[0]

      tarea.invitados = usuarios.filter( usuario => tarea.idsInvolucrados.includes(usuario.id) )

      tarea.meta = this.props.metas.filter( meta => tarea.idMeta === meta.id)[0]

    return(
        <ModalContainer>
          <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                <img className="brand-modal" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco" />
                <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                  <div></div>
                  <div></div>
                </button>
                </div>

                <div className="modal-body sin-margen-y-padding container-fluid">
                  <div className="row text-center">


                    <div className="col-12">
                      <FontAwesomeIcon icon="calendar-check" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                      <h1>Nombre de Tarea: {tarea.nombre}</h1>
                    </div>

                    <div className="col-12 row" style={{'marginTop': '25px'}}>

                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="nombreTarea" style={{'marginTop': '0px'}}><b>Nombre:</b> {tarea.nombre}</p>
                      </div>

                      {
                        tarea.idMeta !== null ? (
                        <div className="col-md-4 row mx-auto">
                          <p className="col" id="nombreMeta" style={{'marginTop': '0px'}}><b>Meta al que Pertenece:</b> {tarea.meta.nombre}</p>
                        </div>
                        ) : null
                      }

                      {
                        tarea.idSucursal !== null ? (
                          <div className="col-md-4 row mx-auto">
                            <p className="col" id="nombreSucursal" style={{'marginTop': '0px'}}><b>Sucursal:</b> {sucursal.nombre_comercial}</p>
                          </div>
                        ) : null
                      }


                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="creadorTarea" style={{'marginTop': '0px'}}><b>Creador de Tarea:</b> {tarea.creador.nombre} {tarea.creador.apellidos}</p>
                      </div>

                      <div className="col-md-4 row mx-auto">
                        <b className="text-center col-12">Involucrados:</b>
                        <ul>
                          {
                            tarea.invitados.map( (invitado, i) => <li key={i} className="col text-left" style={{'marginTop': '0px'}}>{invitado.nombre} {invitado.apellidos}</li>)
                          }
                        </ul>
                      </div>

                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="FechaInicioTarea" style={{'marginTop': '0px'}}><b>Fecha de Inicio:</b> {moment(tarea.fechaDeInicio).format("DD/MM/YYYY")} a las {moment(tarea.fechaDeInicio).format("HH:mm")} horas</p>
                      </div>

                      {
                        tarea.fechaFinal !== null ? (
                          <div className="col-md-4 row mx-auto">
                            <p className="col" id="FechaFinalTarea" style={{'marginTop': '0px'}}><b>Fecha Final:</b> {moment(tarea.fechaFinal).format("DD/MM/YYYY")} a las {moment(tarea.fechaFinal).format("HH:mm")} horas</p>
                          </div>
                        ) : null
                      }

                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="FechaFinalPlanificadaTarea" style={{'marginTop': '0px'}}><b>Fecha Final Planificada:</b> {moment(tarea.fechaFinalPlanificada).format("DD/MM/YYYY")} a las {moment(tarea.fechaFinalPlanificada).format("HH:mm")} horas</p>
                      </div>

                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="estadoTarea" style={{'marginTop': '0px'}}><b>Estado:</b> {tarea.estado} {tarea.estado === 'Finalizado' ? <FontAwesomeIcon icon="check" style={{'fontSize': '25px', color: 'rgb(119, 255, 52)'}} /> : <FontAwesomeIcon icon="history" style={{'fontSize': '25px', color: 'rgb(45, 166, 196)'}} />}</p>
                      </div>

                      {
                        tarea.tipo !== null ? (
                          <div className="col-md-4 row">
                            <p className="col" id="tipoTarea" style={{'marginTop': '0px'}}><b>Tipo de Tarea:</b> {tarea.tipo}</p>
                          </div>
                        ) : null
                      }

                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="nivelDeVisualizacionTarea" style={{'marginTop': '0px'}}><b>Nivel de Vizualización:</b> {tarea.nivelDeVisualizacion}</p>
                      </div>

                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="observacionesTarea" style={{'marginTop': '0px'}}><b>Observaciones de Tarea:</b> {tarea.observaciones}</p>
                      </div>

                    </div>

                    {
                      tarea.estado !== "Finalizado" ? (

                        <div className="col-12 row">
                          <div className="col-12 col-sm-8 col-md-4 col-lg-3 mx-auto">
                              <button type="button" className="btn btn-outline-info" onClick={ () => {
                                this.handleChangeStateTarea(tarea)
                              }}>Finalizar Tarea <FontAwesomeIcon icon="check"/></button>
                          </div>
                        </div>

                      ) : null
                    }

                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
                </div>
              </div>
            </div>
        </ModalB4>
      </ModalContainer>
    )

  }

}

let mapStateToProps = (state, props) => {
  return {
    usuarios: state.dataUsers.usuarios,
    metas: state.dataAdminObjetivos.metas,
    tareas: state.dataAdminObjetivos.tareas
  }
}

export default connect(mapStateToProps)(TareaView)
