import React, {Component} from 'react';

import { OptionAirModeUI, OptionUI } from './OptionsSummernote.js'; //OptionAirModeCodeUI, OptionCodeUI

import { connect } from 'react-redux';

import $ from 'jquery';

import Edit from '../Components/Edit.jsx';

import Save from '../Components/Save.jsx';

import FetchUpdateTextPromise from './FetchUpdateTextPromise.js';

class EditSave extends Component {

  constructor(props){
		super(props)

    this.state = {
      edit: false
    }

    // This binding is necessary to make `this` work in the callback
    this.handleEdit = this.handleEdit.bind(this);
    this.handleSave = this.handleSave.bind(this);

	}

  handleEdit() {
    if(this.props.path === '/'){
      /*if((this.props.usuario.permisos.includes('dev') === true) && (this.props.usuario.permisos.includes('adminUI') === true)){
        $(this.props.itemID).summernote(OptionAirModeCodeUI);
        this.setState({
          edit: true
        })
      }else */if(this.props.usuario.permisos.AdminUI === true){
        $(this.props.itemID).summernote(OptionAirModeUI);
        this.setState({
          edit: true
        })
      }
    }else{
      /*if((this.props.usuario.permisos.includes('dev') === true) && (this.props.usuario.permisos.includes('adminUI') === true)){
        $(this.props.itemID).summernote(OptionCodeUI);
        this.setState({
          edit: true
        })
      }else */if(this.props.usuario.permisos.AdminUI === true){
        $(this.props.itemID).summernote(OptionUI);
        this.setState({
          edit: true
        })
      }
    }
  }

  handleSave(){

    let itemCode = $(this.props.itemID).summernote('code'),
        textId = this.props.textObj.id,
        type = this.props.url;

    FetchUpdateTextPromise(textId, itemCode, type, this)

    this.setState({
      edit: false
    })

    $(this.props.itemID).summernote('destroy');
    //console.log(itemCode);

  }

  render(){
    if(this.state.edit === false){
      return <Edit handleEdit={this.handleEdit} />
    }else if(this.state.edit === true){
      return <Save handleSave={this.handleSave} />
    }
  }

}

let mapStateToProps = (state, props) => {
  return {
    usuario: state.logIn.usuario
  }
}

export default connect(mapStateToProps)(EditSave)
