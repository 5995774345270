let consulta = (fechaInicio, fechaFinal) => {

  let string = `{
    appInfo{
      ordenesCompra{
        ordenesCompra(fechaInicio: "${fechaInicio}", fechaFinal: "${fechaFinal}"){
          id
          idCreador
          idAutorizador
          idUsuarioRecepcion
          idUsuarioArea
          idProveedor
          idSucursal
          proveedor
          idSucursal
          observaciones
          proveedor
          fecha
          estado
          productos{
            nombre
            cantidad
            NoCatalogo
            descripcion
            observaciones
            tipo
            valorUnitario
            checkList{
              integridadDeEmpaque
              valorDelProducto
              temperaturaDeTransporte
              observacionDeRecibido
            }
          }
          facturas{
            noFactura
            concepto
            productos
          }
          emailTracking{
            estado
            fecha
            correo
          }
          passPago{
            id
            fechaPass
            fechaDePago
            estado
          }
        }
      } 
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
