import React, { Component } from 'react';

import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loader from '../../../Components/SimpleLoader';

import { Link } from 'react-router-dom';

import DashboardComponent from '../../dashboard/components/DashboardComponent.jsx';

import GraphQLUsuarios from '../../../../apiService/FetchGraphQLUsuarios';
import FetchGetProductoCompra from './FetchGetProductoCompra/FetchGraphQLGetProductoCompra';
import FetchUpdateProductoCompra from './FetchUpdateProductoCompra/FetchGraphQLUpdateProductoCompra';

class EditProductoCompra extends Component {

    constructor(props) {

        super(props);
        
        this.state = {
            mensajeEdit: null,
            loading: false,
            producto: {
                id: this.props.match.params.idProducto,
                nombre: "",
                valorUnitario: "0",
                NoCatalogo: "",
                descripcion: "",
                observaciones: "",
                tipo: "Insumo de Laboratorio"
            }
        }

        this.onInputProveedorChange = this.onInputProveedorChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    async componentDidMount(){

        let arrayOfComprasValues = [],
        allComprasValuesFalse = true

		if(this.props.usuario !== null){
			if(this.props.usuario.permisos !== null){
				arrayOfComprasValues = Object.values(this.props.usuario.permisos.Compras)
				allComprasValuesFalse = arrayOfComprasValues.every(v => v === false)
			}
        }
        
        if( (this.props.conexion !== false) && (this.props.usuario.permisos !== null) && ((Object.entries(this.props.usuario.permisos.Compras).length > 0) === true) && (allComprasValuesFalse === false) && (this.props.usuario.permisos.Compras.AddProductoCompra === true) ){

            GraphQLUsuarios(this)

            let { data: { appInfo: { ordenesCompra: { productoCompra: producto } } } } = await FetchGetProductoCompra(this.props.match.params.idProducto)

            this.setState({
                ...this.state,
                producto
            })

        }

    }

    onInputChange(event){

        this.setState({
            ...this.state,
            producto: {
                ...this.state.producto,
                NoCatalogo: ((event.target.id ==='Insumo de Laboratorio')) ? this.state.producto.NoCatalogo : null,
                [event.target.id]: event.target.value
            }
        })

    }

    onInputProveedorChange(selectObj){

        this.setState({
            ...this.state,
            producto: {
                ...this.state.producto,
                idProveedor: selectObj.value
            }
        })

    }

    async onSubmit(event){

        event.preventDefault()

        this.setState({
            loading: true
        })

        let {data: { ordenesCompraMutation: { updateProductoCompra: { mensaje, result: response } }} } = await FetchUpdateProductoCompra(this.state.producto)

        this.setState({
            mensajeEdit: {
                response,
                mensaje
            },
            loading: false,
        })

        setTimeout(() => {
            this.setState({
                mensajeEdit: null
            })
        }, 3000);

    }

    render(){

        return(
            <DashboardComponent >
                <div className="container">   
                    <div className="col-12 d-none-print">
                        <Link to="/dashboard/admin-compras/productos">
                            <button style={{backgroundColor: 'transparent', border: 'none', color: '#2984be'}}><FontAwesomeIcon icon="arrow-left" style={{fontSize: '25px'}} /></button>
                        </Link>
                    </div>
                    <form className="row text-center" onSubmit={this.onSubmit} >

                        <div className="col-12">
                            <h2 className="text-center">Editar Producto de Compra</h2>
                        </div>


                        <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                            <label htmlFor="nombre" className="col"><b>Nombre de Producto o Servicio:</b></label><input onChange={this.onInputChange} type="text"  className="form-control" id="nombre" value={this.state.producto.nombre} placeholder="Escribe el Nombre de un Producto o Servicio" />
                            <small id="nombreSmall" className="form-text text-muted">Nombre de Producto o Servicio.</small>
                        </div>

                        <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                            <label htmlFor="descripcion" className="col"><b>Descripción de Producto o Servicio:</b></label><input onChange={this.onInputChange} type="text"  className="form-control" id="descripcion" value={this.state.producto.descripcion} placeholder="Escribe la descripción del Producto o Servicio" />
                            <small id="descripcionSmall" className="form-text text-muted">Descripción de Producto o Servicio.</small>
                        </div>

                        <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                            <label htmlFor="observaciones" className="col"><b>Observación de Producto o Servicio:</b></label><input onChange={this.onInputChange} type="text"  className="form-control" id="observaciones" value={this.state.producto.observaciones} placeholder="Escribe la observación del Producto o Servicio" />
                            <small id="descripcionSmall" className="form-text text-muted">Observación de Producto o Servicio.</small>
                        </div>

                        <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                            <label htmlFor="tipo" className="col"><b>Tipo de Producto o Servicio:</b></label>
                            <select id="tipo" className="form-control" onChange={this.onInputChange} value={this.state.producto.tipo}>
                                <option value="Insumo de Laboratorio">Insumo de Laboratorio</option>
                                <option value="Producto General">Producto General</option>
                                <option value="Servicio">Servicio</option>
                            </select>
                            <small id="tipoSmall" className="form-text text-muted">Descripción de Producto o Servicio.</small>
                        </div>

                        {
                            (this.state.producto.tipo === "Insumo de Laboratorio") ? (
                                <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                    <label htmlFor="NoCatalogo" className="col"><b>No. de Catálogo:</b></label><input onChange={this.onInputChange} type="text"  className="form-control" id="NoCatalogo" value={this.state.producto.NoCatalogo} placeholder="Escribe el No. de Catálogo" />
                                    <small id="NoCatalogoSmall" className="form-text text-muted">No. de Lote</small>
                                </div> 
                            ) : null
                        }

                        <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                            <label htmlFor="valorUnitario" className="col"><b>Valor Unitario de Producto o Servicio:</b></label><input onChange={this.onInputChange} type="number" min="0"  className="form-control" id="valorUnitario" value={this.state.producto.valorUnitario} placeholder="Valor Unitario" step=".01" />
                            <small id="valorUnitarioSmall" className="form-text text-muted">Valor Unitario de Producto o Servicio</small>
                        </div>

                        <div className="form-group col-12 mx-auto">
                            <button type="submit" className="mx-auto btn btn-outline-info" >Editar <FontAwesomeIcon icon="edit" /></button>
                        </div>

                    </form>
                    {
                        this.state.loading === true ? (
                            <div className="row">
                                <Loader />
                            </div>
                        ) : null
                    }
                    {
                        this.state.mensajeEdit !== null ? (
                        <div className="row">
                            {
                            (this.state.mensajeEdit.response === 'success') ? (
                                <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.mensajeEdit.mensaje}</div>
                            ) : (
                                <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.mensajeEdit.mensaje}</div>
                            )
                            }
                        </div>
                        ) : null
                    }
                </div>
            </DashboardComponent>
        )
    }
}

let mapStateToProps = (state, props) => {
    return {
      conexion: state.logIn.conexion,
      usuario: state.logIn.usuario,
      usuarios: state.dataUsers.usuarios,
    }
  }
  
export default connect(mapStateToProps)(EditProductoCompra)