// JavaScript Document
import GraphQL from './ConsultaGraphQLOrdenCompraPassPago';

import url from '../../../../../apiService/ApiUrl.js';

function handleError(err, This, state){
	console.log(`Request failed: ${err}`);

	if(This.setState !== undefined){

		This.setState({
			loading: false,
			mensaje: {
				response: "failure",
				mensaje: "Hubo un error de Conexion"
			}
		})
	
		setTimeout(() => {
			This.setState({
				mensaje: null
			})
		}, 2000)

	}else{
		This({
			...state,
			loading: false,
			mensaje: {
				response: "failure",
				mensaje: "Hubo un error de Conexion"
			}
		})

		setTimeout(() => {
			This({
				...state,
				mensaje: null
			})
		}, 2000)
	}

}

const fetchGetOrdenCompraPassPago = (This, idPassPago, state) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(idPassPago)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		if(res.data.appInfo.ordenesCompra.ordenCompraOfPassPago !== null){

			let orden = {
				...res.data.appInfo.ordenesCompra.ordenCompraOfPassPago,
				fecha: new Date(res.data.appInfo.ordenesCompra.ordenCompraOfPassPago.fecha),
				passPago: {
					...res.data.appInfo.ordenesCompra.ordenCompraOfPassPago.passPago,
					fechaPass: new Date(parseInt(res.data.appInfo.ordenesCompra.ordenCompraOfPassPago.passPago.fechaPass)),
					fechaDePago: new Date(parseInt(res.data.appInfo.ordenesCompra.ordenCompraOfPassPago.passPago.fechaDePago)),
					emailTracking: res.data.appInfo.ordenesCompra.ordenCompraOfPassPago.passPago.emailTracking !== null ? {
						...res.data.appInfo.ordenesCompra.ordenCompraOfPassPago.passPago.emailTracking,
						fecha: new Date(res.data.appInfo.ordenesCompra.ordenCompraOfPassPago.passPago.emailTracking.fecha)
					} : null,
				}
			}
			
			if(This.setState !== undefined){

				This.setState({
					ordenCompra: orden,
					loading: false,
					mensaje: {
						response: "success",
						mensaje: "Se encontro una Contraseña de Pago"
					}
				})

				setTimeout(() => {
					This.setState({
						mensaje: null
					})
				}, 2000)

			}else{

				This({
					...state,
					ordenCompra: orden,
					loading: false,
					mensaje: {
						response: "success",
						mensaje: "Se encontro una Contraseña de Pago"
					}
				})

				setTimeout(() => {
					This({
						ordenCompra: orden,
						loading: false,
						mensaje: null
					})
				}, 2000)

			}


		}else{

			if(This.setState !== undefined){

				This.setState({
					ordenCompra: res.data.appInfo.ordenesCompra.ordenCompraOfPassPago,
					loading: false,
					mensaje: {
						response: "failure",
						mensaje: "No se encontro una Contraseña de Pago"
					}
				})

				setTimeout(() => {
					This.setState({
						mensaje: null
					})
				}, 2000)

			}else{

				This({
					...state,
					ordenCompra: res.data.appInfo.ordenesCompra.ordenCompraOfPassPago,
					loading: false,
					mensaje: {
						response: "success",
						mensaje: "Se encontro una Contraseña de Pago"
					}
				})

				setTimeout(() => {
					This({
						ordenCompra: res.data.appInfo.ordenesCompra.ordenCompraOfPassPago,
						loading: false,
						mensaje: null
					})
				}, 2000)

			}


		}

	})
	.catch(err => handleError(err, This, state));

	//termina fetch
}

export default fetchGetOrdenCompraPassPago;
