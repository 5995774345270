import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let TableSucursales = props => {

    return(
        <div className="col-12">
            <div className="table-responsive" style={{paddingRight: '25px', paddingLeft: '25px'}}>
                <table className="table text-center table-bordered table-hover table-sm">
                <thead className="thead-light">
                    <tr>
                        <th scope="col">Nombre Comercial</th>
                        <th scope="col" className="d-none d-sm-table-cell" >Teléfonos</th>
                        <th scope="col" className="d-none d-sm-table-cell" >Correo</th>
                        <th scope="col">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {
                    props.sucursales.map( sucursal => {
                        return (
                        <tr key={sucursal.id}>
                            {/*<th scope="row">{usuario.nombre}</th>*/}
                            <td>{sucursal.nombre_comercial}</td>
                            <td className="d-none d-sm-table-cell" >{sucursal.telefonos}</td>
                            <td className="d-none d-sm-table-cell" >{sucursal.correo}</td>
                            <td>
                            <button type="button" onClick={() => {
                                let type="SucursalDataView"
                                props.handleChangeDisplay(type, sucursal)
                            }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                                <FontAwesomeIcon icon="eye" />
                            </button> {/*ref={props.setButtonViewUser}*/}

                            {/*<button type="button" onClick={() => {
                                let typeModal = 'EmpresaModalEdit';
                                props.handleOpenModal(empresa, typeModal)
                            }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                                <FontAwesomeIcon icon="edit" />
                            </button>*/}{/*ref={props.setButtonEditUser}*/}

                            <button onClick={ () => {
                                    props.handleDeleteSucursal(sucursal)
                                }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                                <FontAwesomeIcon icon="trash-alt" />
                            </button>

                            </td>
                        </tr>
                        )
                    })
                    }
                </tbody>
                </table>
            </div>
        </div>
    )
}

export default TableSucursales