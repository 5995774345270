import React, { Component } from 'react';

import ResultadosEnLineaContainer from '../Components/ResultadosEnLinea.jsx';

import Pacientes from '../../Components/Pacientes.jsx';

class ResultadosEnLinea extends Component {

  constructor(props) {
    super(props);

    this.onChangeUsuario = this.onChangeUsuario.bind(this);
    this.onChangePin = this.onChangePin.bind(this);
    this.submit = this.submit.bind(this);
  }

  onChangeUsuario = (element) => {
    return "this.value=this.value.toUpperCase(); if(check_only_chars_digits_space(this)) document.form1.id.focus();"
  }

  onChangePin = () => {
    return "this.value=this.value.toUpperCase(); if(check_only_chars_digits_space(this)) document.form1.enviar.focus();"
  }

  submit = () => {
    return `if (check_only_chars_digits_space(document.form1.fec_nac) && document.form1.fec_nac.value!=''&& document.form1.id.value!='') {
    if (document.form1.fec_nac.value.substring(0,1)!='F' && document.form1.fec_nac.value.substring(0,1)!='L')
    { alert('El usuario debe iniciar con la letra L o la letra F. Favor verificar su usuario y probar de nuevo.');}
    else {
    document.form1.submit();}
    }
    else alert('Hay que mencionar el numero PIN y el usuario.');`
  }

  render(){
    return(
      <Pacientes>
        <ResultadosEnLineaContainer>
            <h1 className="col-12">RESULTADOS EN LÍNEA</h1>
            {/*<!--<iframe src="//biolab.clinsis.com/Inicio" className="col-12"></iframe>-->*/}
                <form name="form1" className="col-12 col-md-10 mx-auto" action="https://biolab.clinsis.com:81/PatientOnlineResult" method="post" target="_blank">

                  <div className="row">
                    <div className="col-12 col-md-6">
                      <input type="text" name="fec_nac" placeholder="Usuario:" className="mdt input-resultados col-12" size="10" maxLength="20" autoComplete="off" style={{'fontSize':'14px', 'fontWeight':'bold'}} onChange={this.onChangeUsuario}  required />
                    </div>


                    <div className="col-12 col-md-6">
                      <input type="text" name="id" placeholder="Pin:" className="mdt input-resultados col-12" style={{'fontSize':'14px', 'fontWeight':'bold'}} size="10" maxLength="20" autoComplete="off" onChange={this.onChangePin} required />
                    </div>

                    <div className="col-12 text-center">
                      <input id="boton-resultados" type="submit" value="Ver mis resultados" name="enviar" className="guardar" style={{'fontSize':'14px', 'fontWeight':'bold'}} onClick={this.submit} />
                    </div>
                  </div>

              </form>

          </ResultadosEnLineaContainer>
      </Pacientes>
    )
  }

}

export default ResultadosEnLinea;
