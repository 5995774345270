let consulta = (data) => {

  let oldOrden = JSON.stringify(data.oldOrden).replace(/{"/g, '{').replace(/":/g, ':').replace(/,"/g, ',')
  let productos = JSON.stringify(data.productos).replace(/{"/g, '{').replace(/":/g, ':').replace(/,"/g, ',')

  let string = `mutation{
    ordenesCompraMutation{
      changeProductosToNewOrden(
        idOldOrden: ${data.idOldOrden},
        idCreador: ${data.idCreador},
        oldOrden: ${oldOrden}
        productos: ${productos}
      ){
        oldOrden{
          id
          idCreador
          idAutorizador
          idUsuarioRecepcion
          idUsuarioArea
          idProveedor
          idSucursal
          observaciones
          proveedor
          fecha
          estado
          productos{
            nombre
            cantidad
            NoCatalogo
            descripcion
            observaciones
            tipo
            valorUnitario
          }
          facturas{
            factura
            noFactura
            concepto
            productos
          }
          razonDeEntregaParcial
          razonDeFueraDeCondiciones
          passPago{
            id
            fechaPass
            fechaDePago
            estado
            idProveedor
            proveedor
          }
        }
        newOrden{
          id
          idCreador
          idAutorizador
          idUsuarioRecepcion
          idUsuarioArea
          idProveedor
          idSucursal
          observaciones
          proveedor
          fecha
          estado
          productos{
            nombre
            cantidad
            NoCatalogo
            descripcion
            observaciones
            tipo
            valorUnitario
          }
          facturas{
            factura
            noFactura
            concepto
            productos
          }
          razonDeEntregaParcial
          razonDeFueraDeCondiciones
          passPago{
            id
            fechaPass
            fechaDePago
            estado
            idProveedor
            proveedor
          }
        }
      }
    }
  }`

  //console.log(string)

  return {
    "query": string
  }

}

export default consulta;
