let consulta = (data) => {
  let string = `mutation updateStateSolicitudDeAnalisis{
    mensajeriaMutation {
      updateEstadoSolicitudDeAnalisis(id: "${data.id}", estado: "${data.estado}")
      {
        id
        mensaje
      }
    }
  }`

  return {
    "query": string
  }

}

export default consulta;
