import suscribeGraphQL from '../WebSocketClient';

const initialState = {
    suscribeGraphQL
}
  
let suscribeGraphQLReducer = (state = initialState, action) => {
    switch (action.type) {

        /*case 'ALGUN_CASO': {

            //lOGICA DEL CASO
        }*/

        //break;
        default:
        return state
    }
}
  
export default suscribeGraphQLReducer