import React from 'react';

import './css/ResultadosEnLinea.css';

let ResultadosEnLinea = props => (
  <section id="seccion-iframe-resultados-en-linea" className="container-fluid sin-margen-y-padding">
    {props.children}
  </section>
)

export default ResultadosEnLinea;
