import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let RealizarPlantillaRutaTable = props => {
    return(
      <div className="table-responsive">
        <table className="table text-center table-bordered table-hover table-sm">
          <thead className="thead-light">
            <tr>
              {/*<th scope="col" className="d-none d-md-table-cell" >Id de Orden</th>*/}
              <th scope="col">Nombre Empresa o Usuario</th>
              <th scope="col">Instrucciones</th>
              <th scope="col" className="d-none d-sm-table-cell" >Hora</th>
              <th scope="col" className="d-none d-md-table-cell">tipo</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {
              props.solicitudesMensajeria.map((solicitudMensajeria, index) => {
    
                return (
                  <tr key={ solicitudMensajeria.idProvisional ? solicitudMensajeria.idProvisional : index } style={{backgroundColor: 'transparent'}}>
                    {/*<th scope="row">{usuario.nombre}</th>*/}
                    {/*<td className="d-none d-md-table-cell">{solicitudMensajeria.idProvisional}</td>*/}
                    {
                      (solicitudMensajeria.idUsuario !== null) ? (
                        <td>
                          {
                            props.usuarios.map(usuario => {
                              return ((usuario.id === solicitudMensajeria.idUsuario) === true) ? `${usuario.nombre} ${usuario.apellidos}`: null
                            })
                          }
                        </td>
                      ) : null
                    }
                    {
                      (solicitudMensajeria.idEmpresa !== null) ? (
                        <td>
                          {
                            props.empresas.map(empresa => {
                              return ((empresa.id === solicitudMensajeria.idEmpresa) === true) ? `${empresa.nombre_comercial}`: null
                            })
                          }
                        </td>
                      ) : null
                    }
                    <td>{solicitudMensajeria.instrucciones}</td>
                    <td className="d-none d-sm-table-cell" >{solicitudMensajeria.horaDeOrden} horas</td>
                    <td className="d-none d-md-table-cell">{solicitudMensajeria.tipo}</td>
                    <td>
    
                        <button type="button" onClick={() => {
                            props.handleRemoveOrden(solicitudMensajeria)
                        }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#2984be'}}>
                          <FontAwesomeIcon icon="minus-circle" />
                        </button>
    
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    )
}

export default RealizarPlantillaRutaTable