let handleInputChange = (event, This) => {

  let value = event.target.value,
      usuario = event.target.dataset.usuario,
      type = event.target.dataset.valueType

  This.props.dataUsers.usuarios.filter( obj => {

    if((obj.id === JSON.parse(usuario).id) === true){

      if(type === 'usuario'){

        obj.usuario = value

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })

      }else if(type === 'nombre'){
        obj.nombre = value

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'apellidos'){
        obj.apellidos = value

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'telefono'){
        obj.telefono = value

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'celular'){
        obj.celular = value

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'email'){
        obj.email = value

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'DPI'){
        obj.DPI = value

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'fecha_de_nacimiento'){

        //var string = "1993-04-01";
        var d = Date.parse(value);
        var d2 = d.toString();
        var d3 = new Date(parseInt(d2));
        d3.setDate(d3.getDate() + 1)

        var d4 = Date.parse(d3);

        obj.fecha_de_nacimiento = d4

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'direccion'){
        obj.direccion = value

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'NIT'){
        obj.NIT = value

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'nota'){
        obj.nota = value

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'sexo'){
        obj.sexo = value

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'tipo'){

        obj.tipo = value
  
        let biolab = This.props.dataEmpresas.empresas.filter(empresa => empresa.nombre_comercial === 'Biolab')[0]
  
        if(value === "colaborador"){
          obj.empresa_asociada = {
            id: biolab.id,
            nombre: biolab.nombre_comercial,
            telefono: biolab.telefono,
            correo: biolab.correo
          }
        }/*else{
          This.props.dataUsers.nuevoUsuario.empresa_asociada = {
            id: null,
            nombre: null,
            telefono: null,
            correo: null
          }
          This.props.dataUsers.nuevoUsuario.colaborador.departamento = null
          This.props.dataUsers.nuevoUsuario.colaborador.puesto = null
        }*/
  
        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'permisos'){

        //console.log(event.target.options.length)

        var opts = [], opt;

        // loop through options in select list
        for (var i=0, len=event.target.options.length; i<len; i++) {
            opt = event.target.options[i];

            // check if selected
            if ( opt.selected ) {
                // add to array of option elements to return from this function
                opts.push(opt.value);

                // invoke optional callback function if provided
                /*if (fn) {
                    fn(opt);
                }*/
            }
        }

        // return array containing references to selected option elements
        obj.permisos = opts
        //return opts;

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'EmpresaAsociada'){
        //obj.empresa_asociada.nombre = value

        let empresa = This.props.dataEmpresas.empresas.filter( empresa => empresa.nombre_comercial === value )[0]

        obj.empresa_asociada.id = empresa.id

        obj.empresa_asociada.nombre = empresa.nombre_comercial

        obj.empresa_asociada.telefono = empresa.telefono

        obj.empresa_asociada.correo = empresa.correo

        //console.log(empresa[0].id)

        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'sucursal'){
        //obj.empresa_asociada.nombre = value
  
        //This.props.dataUsers.nuevoUsuario.tipo = value
  
        let sucursal = This.props.dataEmpresas.sucursales.filter( sucursal => sucursal.id === value )[0]
  
        if(sucursal !== undefined){
          obj.idSucursal = sucursal.id
        }else{
          obj.idSucursal = null
        }
  
        //console.log(empresa[0].nombre_comercial)
  
        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'Puesto'){

        if(value === ''){
          obj.colaborador.puesto = null
        }else{
          obj.colaborador.puesto = value
        }
  
        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'Departamento'){

        if(value === ''){
          obj.colaborador.departamento = null
        }else{
          obj.colaborador.departamento = value
        }
  
        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }else if(type === 'usuarioActivo'){

        value = value === 'true' ? true : false

        obj.usuario_activo = value
  
        This.props.dispatch({
          type:'CHANGE_DATA_USER',
          payload: {
            dataUser: obj
          }
        })
      }

    }

    return null

  })

}

export default handleInputChange;
