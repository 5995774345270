import React from 'react';

import ServiciosMedicosContainer from '../../../Containers/ServiciosMedicos.jsx';

import Pacientes from '../../Components/Pacientes.jsx';

let Contacto = props => (
  <Pacientes>
    <ServiciosMedicosContainer/>
  </Pacientes>
)

export default Contacto
