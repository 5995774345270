import GraphQL from './ConsultaGraphQL';

import url from '../../../../../../apiService/ApiUrl.js';

import moment from 'moment';

// JavaScript Document
function handleError(err, This){
	console.log(`Request failed: ${err}`);
	This.setState({
		mensajeInsert: {
			response: 'failure',
			mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
		}
	})
}

const insertTarea = (This, tareaJSON, stateCheckBox) => {
	//console.log(usuarioJSON)
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(tareaJSON)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		This.setState({
			mensajeInsert: {
				response: 'success',
				mensaje: res.data.adminObjetivos.insertTarea.mensaje
			}
		})

		This.props.dispatch({
			type: 'ADD_TAREA_TO_TAREAS',
			payload: {
				dataTarea: {
					id: res.data.adminObjetivos.insertTarea.id,
					nombre: tareaJSON.nombre,
					idCreador: tareaJSON.idCreador,
					idSucursal: tareaJSON.idSucursal,
					idMeta: tareaJSON.idMeta,
					idsInvolucrados: tareaJSON.idsInvolucrados,
					fechaDeInicio: new Date(tareaJSON.fechaDeInicio),
					fechaFinal: null,
					fechaFinalPlanificada: new Date(tareaJSON.fechaFinalPlanificada),
					estado: tareaJSON.estado,
					tipo: tareaJSON.tipo,
					nivelDeVisualizacion: tareaJSON.nivelDeVisualizacion,
					observaciones: tareaJSON.observaciones,
					type: "Tarea"
				},
				stateCheckBox
			}
		})

		//console.log(res)

		return res
	})
	.then( () =>{
		setTimeout( () => {
			This.setState({
				selectedOptionUsuarios: null,
				tipoEvento: "Objetivo",
				objetivoMetaTarea: {
				  nombre: "",
				  idCreador: This.props.usuario.id,
				  idSucursal: "",
				  idsInvolucrados: [This.props.usuario.id],
				  fechaDeInicio: `${moment(This.props.data.start).format("YYYY-MM-DD")}`,
				  horaDeInicio: `${moment(This.props.data.start).format("HH:mm")}`,
				  fechaFinalPlanificada: `${moment(This.props.data.end).format("YYYY-MM-DD")}`,
				  horaFinalPlanificada: `${moment(This.props.data.end).format("HH:mm")}`,
				  estado: "En Proceso",
				  tipo: null,
				  tipoIndicador: "Mas Que o Igual",
				  indicador: "0",
				  nivelDeVisualizacion: "Todos",
				  idObjetivo: "",
				  idMeta: "",
				  observaciones: "",
				},
				etiquetas: {  
				  isLoading: false,
				  value: null
				},
				mensajeInsert: null
			})
		}, 3000);
	})
	.catch(err => handleError(err, This))
	.then(() =>{
		setTimeout( () => {
			This.setState({
				mensajeInsert: null
			})
		}, 5000);
	});;

	//termina fetch
}

export default insertTarea;
