import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let EditDataEmpresa = props => {
    return(
        <div className="modal-body container-fluid">
            <div className="row text-center">

                <div className="col-12">
                <FontAwesomeIcon icon="building" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                <h1>Empresa: {props.empresa.nombre_comercial}</h1>
                </div>
                <div className="col-12 row" style={{'marginTop': '25px'}}>

                <div className="col-md-4 row">
                    <label htmlFor="nombreComercialEmpresa" className="col"><b>Nombre Comercial:</b></label>
                    <input id="nombreComercialEmpresa" ref={props.setRef} data-empresa={props.empresaString} data-value-type="nombre_comercial" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={props.empresa.nombre_comercial} style={{'top': '-3px'}}/>
                </div>

                <div className="col-md-4 row">
                    <label htmlFor="nombreRazonSocial" className="col"><b>Nombre Razón Social:</b></label>
                    <input id="nombreRazonSocial" ref={props.setRef} data-empresa={props.empresaString} data-value-type="nombre_razon_social" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={props.empresa.nombre_razon_social} style={{'top': '-3px'}}/>
                </div>

                <div className="col-md-4 row">
                    <label htmlFor="TelefonoEmpresa" className="col"><b>Teléfono:</b></label>
                    <input id="TelefonoEmpresa" ref={props.setRef} data-empresa={props.empresaString} data-value-type="telefono" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={props.empresa.telefono} style={{'top': '-3px'}}/>
                </div>

                <div className="col-md-4 row">
                    <label htmlFor="correo" className="col"><b>Correo:</b></label>
                    <input id="correo" ref={props.setRef} data-empresa={props.empresaString} data-value-type="correo" onChange={props.handleInputChange} type="email"  className="col form-control-plaintext form-control-sm inputUserVal" value={props.empresa.correo} style={{'top': '-3px'}}/>
                </div>

                <div className="col-md-4 row">
                    <label htmlFor="NIT" className="col"><b>NIT:</b></label>
                    <input id="NIT" ref={props.setRef} data-empresa={props.empresaString} data-value-type="NIT" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={props.empresa.NIT} style={{'top': '-3px'}}/>
                </div>

                <div className="col-md-4 row">
                    <label htmlFor="DireccionFiscal" className="col"><b>Dirección Físcal:</b></label>
                    <input id="DireccionFiscal" ref={props.setRef} data-empresa={props.empresaString} data-value-type="direccion_fiscal" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={props.empresa.direccion_fiscal} style={{'top': '-3px'}}/>
                </div>

                <div className="col-md-4 row">
                    <label htmlFor="DireccionComercial" className="col"><b>Dirección Comercial:</b></label>
                    <input id="DireccionComercial" ref={props.setRef} data-empresa={props.empresaString} data-value-type="direccion_comercial" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={props.empresa.direccion_comercial} style={{'top': '-3px'}}/>
                </div>

                <div className="col-md-4 row">
                    <label htmlFor="Descripcion" className="col"><b>Descripción:</b></label>
                    <textarea id="Descripcion" ref={props.setRef} data-empresa={props.empresaString} data-value-type="descripcion" onChange={props.handleInputChange}  className="col form-control-plaintext form-control-sm inputUserVal" value={props.empresa.descripcion} style={{'top': '-3px'}}/>
                </div>

                <div className="col-md-4 row">
                    <label htmlFor="Convenio" className="col"><b>Convenio:</b></label>
                    <textarea id="Convenio" ref={props.setRef} data-empresa={props.empresaString} data-value-type="convenio" onChange={props.handleInputChange}  className="col form-control-plaintext form-control-sm inputUserVal" value={props.empresa.convenio} style={{'top': '-3px'}}/>
                </div>

                <div className="col-md-4 row">
                    <label htmlFor="CreditoAutorizado" className="col"><b>Crédito Autorizado:</b></label>
                    <input id="CreditoAutorizado" ref={props.setRef} data-empresa={props.empresaString} data-value-type="credito_autorizado" onChange={props.handleInputChange} type="number"  className="col form-control-plaintext form-control-sm inputUserVal" value={props.empresa.credito_autorizado} style={{'top': '-3px'}}/>
                </div>

                <div className="col-md-4 row">
                    <label htmlFor="DiasCreditoAutorizado" className="col"><b>Días de Crédito Autorizado:</b></label>
                    <input id="DiasCreditoAutorizado" ref={props.setRef} data-empresa={props.empresaString} data-value-type="dias_de_credito_autorizado" onChange={props.handleInputChange} type="number"  className="col form-control-plaintext form-control-sm inputUserVal" value={props.empresa.dias_de_credito_autorizado} style={{'top': '-3px'}}/>
                </div>

                <div className="col-md-4 row">
                    <label htmlFor="TipoDePagoComun" className="col"><b>Tipo de Pago Común:</b></label>
                    <select id="TipoDePagoComun" ref={props.setRef} data-empresa={props.empresaString} data-value-type="tipo_de_pago_comun" onChange={props.handleInputChange} type="text"  className="col form-control-plaintext form-control-sm inputUserVal" value={props.empresa.tipo_de_pago_comun} style={{'top': '-3px'}}>
                        <option value="Cheque">Cheque</option>
                        <option value="Deposito">Deposito</option>
                        <option value="Efectivo">Efectivo</option>
                    </select>
                </div>

                <div className="col-md-4 mx-auto row">
                    <div className="col-12">
                        <p><b>Clasificación Empresa</b></p>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="Cliente" ref={props.setRef} data-empresa={props.empresaString} onChange={props.handleInputChange} data-value-type="clasificacionEmpresa" checked={props.empresa.clasificacion_empresa.includes('Cliente')} value='Cliente' />
                            <label className="form-check-label" htmlFor="Cliente">
                                Cliente
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="Proveedor" ref={props.setRef} data-empresa={props.empresaString} onChange={props.handleInputChange} data-value-type="clasificacionEmpresa" checked={props.empresa.clasificacion_empresa.includes('Proveedor')} value='Proveedor' />
                            <label className="form-check-label" htmlFor="Proveedor">
                                Proveedor
                            </label>
                        </div>
                    </div>
                </div>

                {
                   (props.empresa.clasificacion_empresa.length > 0 ) && (props.empresa.clasificacion_empresa.includes('Proveedor')) && (
                        <div className="col-md-4 row">
                            <label htmlFor="ProveedorAutorizado" className="form-check-label"><b>Proveedor Autorizado:</b></label>
                            <input id="ProveedorAutorizado" ref={props.setRef} data-empresa={props.empresaString} data-value-type="proveedor_autorizado" onChange={props.handleInputChange} type="checkbox" checked={props.empresa.proveedor_autorizado} value={props.empresa.proveedor_autorizado} style={{position: 'relative', 'top': '6px', marginLeft: '5px'}}/>
                        </div>
                   )
                }


                </div>

            </div>

            <div className="row">
                <button type="button" className="btn btn-outline-info mx-auto" onClick={()=>{
                    let type = "InsertSucursal"
                    props.handleChangeDisplay(type)
                }} style={{marginTop: '25px'}}>Insertar Sucursal</button>
            </div>

            {
                props.updateEmpresaMensaje !== null ? (
                    <div className="row">
                    {
                        (props.updateEmpresaMensaje.response === 'success') ? (
                        <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.updateEmpresaMensaje.mensaje}</div>
                        ) : (
                        <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{props.updateEmpresaMensaje.mensaje}</div>
                        )
                    }
                    </div>
                ) : null
                }
        </div>
    )
}

export default EditDataEmpresa