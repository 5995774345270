import React, { Component } from 'react';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../../../../Modal/Components/Modal.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import EditDataEmpresa from './EditDataEmpresa.jsx';
import InsertSucursal from './InsertSucursal.jsx';

class EmpresaEdit extends Component{

  constructor(props) {
    super(props);

    this.state = {
      route: "EditDataEmpresa"
    }

    this.handleChangeDisplay = this.handleChangeDisplay.bind(this);

  }

  handleChangeDisplay(type){
    if(type === "EditDataEmpresa"){
      this.setState({
        route: type
      })
    }else if(type === "InsertSucursal"){
      this.setState({
        route: type
      })
    }
  }

  render(){
    let empresa = this.props.empresa,
      empresaString = JSON.stringify(empresa),
      modalVisible = this.props.modalVisible,
      handleCloseModal = this.props.handleCloseModal

    return(
      <ModalContainer>
        <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <img className="brand-modal" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco" />
                <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                  <div></div>
                  <div></div>
                </button>
                </div>

                {
                  this.state.route === "EditDataEmpresa" ? (
                    <EditDataEmpresa empresa={empresa} empresaString={empresaString} handleInputChange={this.props.handleInputChange} updateEmpresaMensaje={this.props.updateEmpresaMensaje} handleChangeDisplay={this.handleChangeDisplay}/>
                  ) : null
                }

                {
                  this.state.route === "InsertSucursal" ? (
                    <InsertSucursal empresa={empresa} handleChangeDisplay={this.handleChangeDisplay}/>
                  ) : null
                }

                <div className="modal-footer">
                  {
                    this.state.route === "EditDataEmpresa" ? (
                      <button type="button" className="btn btn-default mx-auto" onClick={this.props.handleSaveEmpresa}><FontAwesomeIcon icon="save" style={{'fontSize': '150%'}} /></button>
                    ) : null
                  }
                  <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
                </div>

              </div>
            </div>
        </ModalB4>
      </ModalContainer>
    )
  }
}

export default EmpresaEdit
