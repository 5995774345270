let consulta = (data) => {

  let checkList = JSON.stringify(data.checkList).replace(/{"/g, '{').replace(/":/g, ':').replace(/,"/g, ',')

  const string =
    `mutation insertCheckListRutaMensajeria {
      mensajeriaMutation {
        insertCheckListRutaMensajeria(idRuta: "${data.idRuta}", estado:"${data.estado}", checkList: ${checkList}){
          id
          checkList{
            nombreQuienRevisa
            gelRefrigerante
            condicionesHielera
            muestras
            cantidadDePaquetesTruly
            cantidadDePaquetes
            observaciones
            fecha
          }
          mensaje
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
