let consulta = (metaJSON) => {

  let idsInvolucrados = metaJSON.idsInvolucrados.toString()

  //console.log(metaJSON)

  const string =
    `mutation insertAdminObjetivos{
      adminObjetivos{
        insertMeta(metaData: {
          nombre: "${metaJSON.nombre}",
          idCreador: ${metaJSON.idCreador},
          idSucursal: ${metaJSON.idSucursal},
          idObjetivo: "${metaJSON.idObjetivo}",
          idsInvolucrados: [${idsInvolucrados}],
          fechaDeInicio: "${metaJSON.fechaDeInicio}",
          fechaFinalPlanificada: "${metaJSON.fechaFinalPlanificada}",
          estado: "${metaJSON.estado}",
          tipo: "${metaJSON.tipo}",
          tipoIndicador: "${metaJSON.tipoIndicador}",
          indicador: ${metaJSON.indicador},
          contador: ${metaJSON.contador},
          nivelDeVisualizacion: "${metaJSON.nivelDeVisualizacion}",
          observaciones: "${metaJSON.observaciones}"
        })
        {
          id
          mensaje
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
