let consulta = () => {

  let string = `{
    appInfo{
      productos{
        tipoClientes{
          id
          nombre
        }
      }
    }
  }`

  /*return {
    "query": string
  }*/

  return string

}

export default consulta;
