import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let TableVerPlantillasRutas = (props) => {

    return(
        <div className="table-responsive">
            <table className="table text-center table-bordered table-hover table-sm">
                <thead className="thead-light">
                <tr>
                    <th scope="col" className="d-none d-md-table-cell" >Id de Plantilla</th>
                    <th scope="col">Nombre de Plantilla</th>
                    <th scope="col">Acciones</th>
                </tr>
                </thead>
                <tbody>
                {
                props.plantillasRutasMensajeria.map(plantillaRutaMensajeria => {

                    let colorFondo = 'transparent';

                    //console.log(mensajero[0].id)
        
                    return (
                    <tr key={plantillaRutaMensajeria.id} style={{backgroundColor: colorFondo}}>
                        {/*<th scope="row">{usuario.nombre}</th>*/}
                        <td className="d-none d-md-table-cell">{plantillaRutaMensajeria.id}</td>
                        <td>{plantillaRutaMensajeria.nombrePlantilla}</td>
                        <td>

                            {
                                ((props.usuario.permisos.Mensajeria.ViewPlantillaRuta === true) || (props.usuario.permisos.Mensajeria.EditPlantillaRuta === true)) ? (
                                    <button type="button" onClick={() => {
                                        let typeModal = 'PlantillaRutaMensariaModalView';
                                        props.handleOpenModal(plantillaRutaMensajeria, typeModal)
                                    }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                                        <FontAwesomeIcon icon="eye" />
                                    </button>
                                ) : null
                            }

                            {/*<button type="button" onClick={() => {
                                let typeModal = 'rutaMensjeriaModalEdit';
                                plantillaRutaMensajeria.fechaRutas = props.fechaRutas
                                props.handleOpenModal(plantillaRutaMensajeria, typeModal)
                            }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                                <FontAwesomeIcon icon="edit" />
                            </button>*/}{/*ref={props.setButtonEditUser}*/}

                            {
                                props.usuario.permisos.Mensajeria.DeletePlantillaRuta === true ? (
                                    <button onClick={ () => {

                                            let typeModal = 'DeleteQuestion';

                                            let data = {
                                                DeleteFunction: props.handleDeletePlantillaRuta,
                                                data: plantillaRutaMensajeria
                                            }
                
                                            props.handleOpenModal(data, typeModal)

                                        }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                                        <FontAwesomeIcon icon="trash-alt" />
                                    </button>
                                ) : null
                            }


                        </td>
                    </tr>
                    )
                })
                }
            </tbody>
          </table>
        </div>
    )
}

export default TableVerPlantillasRutas