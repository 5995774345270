import React, { Component } from 'react';

import BotonDashboard from './BotonDashboard.jsx';

import DashboardComponent from '../components/DashboardComponent.jsx';

import AdminObjetivos from '../../AdminObjetivos/containers/AdminObjetivos.jsx';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

import url from '../../../../apiService/ApiUrl.js';

class Dashboard extends Component{

	constructor(props) {
		super(props);

		this.state={
			display: "Calendar"
		}

		this.handleOpenModal = this.handleOpenModal.bind(this);
		this.startNotifications = this.startNotifications.bind(this)

	}
	  
	componentDidMount(){

		if ((this.props.conexion !== false) && (this.props.usuario)) {

			this.startNotifications()
			
		}

	}

	startNotifications = async () => {

		//Hace check para ver si tiene ServiceWorker
		if(('Notification' in window) && ('serviceWorker' in navigator)){

			//console.log("tiene Service Worker")

			if(Notification.permission === 'default'){
				await Notification.requestPermission()
			}

			if(Notification.permission === 'denied'){
				alert("Bloqueaste las notificaciones")
			}

			if(Notification.permission === 'granted'){

				const PUBLIC_VAPID_KEY = "BNDxsHuHXLnvpFmFKyeMFJ21rEd9Y9Qf5ZugNLfRFU-U8p9i56eFFvaxOIKp8SyB_bItWSr58WFRvg0B9xlNVW0";

				const registration = await navigator.serviceWorker.getRegistration()

				let urlBase64ToUint8Array = (base64String) => {
					const padding = '='.repeat((4 - base64String.length % 4) % 4);
					const base64 = (base64String + padding)
					  .replace(/-/g, '+')
					  .replace(/_/g, '/');
				   
					const rawData = window.atob(base64);
					const outputArray = new Uint8Array(rawData.length);
				   
					for (let i = 0; i < rawData.length; ++i) {
					  outputArray[i] = rawData.charCodeAt(i);
					}
					return outputArray;
				}

				if( ! registration ) {
					return alert("No hay un Service Worker ")
				}

				let subscription = await registration.pushManager.subscribe({
					userVisibleOnly: true,
					applicationServerKey: urlBase64ToUint8Array(PUBLIC_VAPID_KEY)
				})

				let string = `mutation suscribeWebPushNotification{
					suscribeWebPushNotifications(idUsuario: ${this.props.usuario.id}, subscriptionWebPusNotification: ${JSON.stringify(subscription).replace(/{"/g, '{').replace(/":/g, ':').replace(/,"/g, ',')})
				}`

				let consulta = {
					"query": string
				}

				const options = {
					method: 'post',
					headers: {
						"Content-type": "application/json"
					},
					body: JSON.stringify(consulta),
					credentials: 'include'
				}

				await fetch(url, options)

				console.log("Suscrito")

			}

		}

	}

	handleOpenModal(modal, typeModal){
    	this.props.dispatch({
			type: `OPEN_MODAL`,
			payload: {
				modal,
				typeModal
			}
		})
  	}

	render(){
		if (this.props.conexion === false || (this.props.usuario.usuario_activo === false)) {
			return <Redirect to='/login' />
		}else if(this.props.usuario.permisos === null && (this.props.usuario.usuario_activo === true)){
			return(
				<DashboardComponent>
					<div className="container" style={{height: '80vh'}}>
						<h1 className="text-center">No te Han Asignado Permisos</h1>
					</div>
				</DashboardComponent>
			)
		}else if( ((this.props.usuario.tipo === 'colaborador') && (this.props.usuario.usuario_activo === true)) && (this.props.usuario.colaborador.puesto === 'Mensajero') ){
			return <Redirect to='/dashboard/mensajeria/ordenes' />
		}else if(this.props.usuario.tipo === 'colaborador' && (this.props.usuario.permisos.AdminObjetivos === true) && (this.props.usuario.usuario_activo === true) ){

			return(
				<DashboardComponent>
					<AdminObjetivos handleOpenModal={this.handleOpenModal} display={this.state.display} />
				</DashboardComponent>
			)
			
		}else{
			return(
				<DashboardComponent>
					<div className="container-fluid" style={{minHeight: '78vh'}}>
						<BotonDashboard />
		        	{/*<h1>Este es el cuerpo</h1>*/}
		      		</div>
				</DashboardComponent>
			)
		}
	}
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
	usuario: state.logIn.usuario
  }
}

export default connect(mapStateToProps)(Dashboard)
