let consulta = (usuario, tipoFecha, fechaDesde, fechaHasta) => {

  let string = `{
    appInfo{
      adminObjetivos{
        objetivos(idCreador: ${usuario.id}, tipoFecha: "${tipoFecha}", fechaDesde: ${fechaDesde === null ? fechaDesde : `"${fechaDesde}"`}, fechaHasta: ${fechaHasta === null ? fechaHasta : `"${fechaHasta}"`}){
          objetivos{
            id
            nombre
            idCreador
            idSucursal
            idsInvolucrados
            fechaDeInicio
            fechaFinal
            fechaFinalPlanificada
            estado
            tipo
            nivelDeVisualizacion
            observaciones
          }
          metas{
            id
            nombre
            idCreador
            idSucursal
            idObjetivo
            idsInvolucrados
            fechaDeInicio
            fechaFinal
            fechaFinalPlanificada
            estado
            tipo
            tipoIndicador
            indicador
            contador
            nivelDeVisualizacion
            observaciones
          }
          tareas{
            id
            nombre
            idCreador
            idSucursal
            idMeta
            idsInvolucrados
            fechaDeInicio
            fechaFinal
            fechaFinalPlanificada
            estado
            tipo
            nivelDeVisualizacion
            observaciones
          }
        }
        etiquetas{
          id
          nombre
          tipoEvento
        }
      }
    }
  }`

  /*return {
    "query": string
  }*/

  return string

}

export default consulta;
