// JavaScript Document
import GraphQL from './ConsultaGraphQLGetProductoCompra';

import url from '../../../../../apiService/ApiUrl.js';

function handleError(err){
	console.log(`Request failed: ${err}`);
}

const fetchGetProductoCompra = (idProducto) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(idProducto)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	return fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		return res

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchGetProductoCompra;
