import React, { Component } from 'react';

import moment from 'moment';

import { connect } from 'react-redux';

import GraphQLEmpresas from '../../../../../apiService/FetchGraphQLEmpresas.js';
import GraphQLUsuarios from '../../../../../apiService/FetchGraphQLUsuarios';
import GraphQLGetObjetivos from '../FetchGetObjetivos/FetchGraphQLGetObjetivos';
import GraphQLGetMetas from '../FetchGetMetas/FetchGraphQLGetMetas';

import Select, { components } from 'react-select';
import AsyncCreatable  from 'react-select/creatable';

import FetchNewEtiqueta from './NewEtiquetaFetch/FetchNewEtiquetaPromise.js';

import FetchNewObjetivo from './NewObjetivoFetch/FetchNewObjetivoPromise.js';
import FetchNewMeta from './NewMetaFetch/FetchNewMetaPromise.js';
import FetchNewTarea from './NewTareaFetch/FetchNewTareaPromise.js';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../../../Modal/Components/Modal.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Insert extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      selectedOptionUsuarios: null,
      tipoEvento: this.props.data.type,
      objetivoMetaTarea: {
        nombre: "",
        idCreador: this.props.usuario.id,
        idSucursal: '',
        idsInvolucrados: [this.props.usuario.id],
        fechaDeInicio: `${moment(this.props.data.start).format("YYYY-MM-DD")}`,
        horaDeInicio: `${moment(this.props.data.start).format("HH:mm")}`,
        fechaFinalPlanificada: `${moment(this.props.data.end).format("YYYY-MM-DD")}`,
        horaFinalPlanificada: `${moment(this.props.data.end).format("HH:mm")}`,
        estado: "En Proceso",
        tipo: null,
        tipoIndicador: "Mas Que o Igual",
        indicador: "0",
        nivelDeVisualizacion: "Todos",
        idObjetivo: "",
        idMeta: "",
        observaciones: "",
      },
      etiquetas: {  
        isLoading: false,
        value: null
      },
      mensajeInsert: null
    }

    this.onInputChange = this.onInputChange.bind(this);
    this.onInputChangeSelect = this.onInputChangeSelect.bind(this);
    this.MyValueContainer = this.MyValueContainer.bind(this);
    this.handleOnCreateobjetivoMetaTarea = this.handleOnCreateobjetivoMetaTarea.bind(this);
  }

  componentDidMount(){
    GraphQLEmpresas(this)
    GraphQLUsuarios(this)
    GraphQLGetObjetivos(this, this.props.usuario, null, null, null)
    GraphQLGetMetas(this, this.props.usuario, null, null, null)
  }

  onInputChangeSelect(selectedOptionUsuarios){

    let idsInvolucrados

    if(selectedOptionUsuarios !== null){

      idsInvolucrados = selectedOptionUsuarios.map(selectedOptionUsuario => {
        return selectedOptionUsuario.value.id
      })

      idsInvolucrados.unshift(this.props.usuario.id)

    }

    //console.log(idsInvolucrados)

    this.setState({
      selectedOptionUsuarios: selectedOptionUsuarios,
      objetivoMetaTarea: {
        ...this.state.objetivoMetaTarea,
        idsInvolucrados: idsInvolucrados !== undefined ? idsInvolucrados : [this.props.usuario.id],
      }
    });

  }

  MyValueContainer = ({ children, ...props }) => {
    if(children[0] !== null){
      if (children[0].length) {
        const newChilds = children[0].map(child =>
          React.cloneElement(child, { key: child.props.children })
        );
        newChilds.forEach((newChild, index) => {
          children[0][index] = newChild;
        });
        // children[0] = newChilds;  wont work. children[0] is readonly
      }
    }
    return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
  }

  onInputChange(event){

    if(event.target.id === "tipoEvento"){
      this.setState({
        tipoEvento: event.target.value
      })
    }else if(event.target.id === "nombre"){
      this.setState({
        objetivoMetaTarea: {
          ...this.state.objetivoMetaTarea,
          nombre: event.target.value
        }
      })
    }else if(event.target.id === "sucursal"){
      this.setState({
        objetivoMetaTarea: {
          ...this.state.objetivoMetaTarea,
          idSucursal: event.target.value
        }
      })
    }else if(event.target.id === "fechaInicio"){
      this.setState({
        objetivoMetaTarea: {
          ...this.state.objetivoMetaTarea,
          fechaDeInicio: event.target.value
        }
      })
    }else if(event.target.id === "horaInicio"){
      this.setState({
        objetivoMetaTarea: {
          ...this.state.objetivoMetaTarea,
          horaDeInicio: event.target.value
        }
      })
    }else if(event.target.id === "fechaFin"){
      this.setState({
        objetivoMetaTarea: {
          ...this.state.objetivoMetaTarea,
          fechaFinalPlanificada: event.target.value
        }
      })
    }else if(event.target.id === "horaFin"){
      this.setState({
        objetivoMetaTarea: {
          ...this.state.objetivoMetaTarea,
          horaFinalPlanificada: event.target.value
        }
      })
    }else if(event.target.id === "tipoIndicador"){
      this.setState({
        objetivoMetaTarea: {
          ...this.state.objetivoMetaTarea,
          tipoIndicador: event.target.value
        }
      })
    }else if(event.target.id === "indicador"){
      this.setState({
        objetivoMetaTarea: {
          ...this.state.objetivoMetaTarea,
          indicador: event.target.value
        }
      })
    }else if(event.target.id === "nivelDeVisualizacion"){
      this.setState({
        objetivoMetaTarea: {
          ...this.state.objetivoMetaTarea,
          nivelDeVisualizacion: event.target.value
        }
      })
    }else if(event.target.id === "observaciones"){
      this.setState({
        objetivoMetaTarea: {
          ...this.state.objetivoMetaTarea,
          observaciones: event.target.value
        }
      })
    }else if(event.target.id === "idObjetivo"){
      this.setState({
        objetivoMetaTarea: {
          ...this.state.objetivoMetaTarea,
          idObjetivo: event.target.value
        }
      })
    }else if(event.target.id === "idMeta"){
      this.setState({
        objetivoMetaTarea: {
          ...this.state.objetivoMetaTarea,
          idMeta: event.target.value
        }
      })
    }

  }

  handleChange = (newValue, actionMeta) => {

    this.setState({
      objetivoMetaTarea: {
        ...this.state.objetivoMetaTarea,
        tipo: newValue !== null ? newValue.value : newValue
      },
      etiquetas: {
        ...this.state.etiquetas,
        value: newValue
      }
    })

  }

  handleCreate = (inputValue) => {

    let createOption = (label) => ({
        label,
        value: label
    });

    const newOption = createOption(inputValue);

    //let value = this.state.etiquetas.value

    this.setState({ 
      etiquetas: {
        ...this.state.etiquetas,
        isLoading: true
      } 
    });

    let etiqueta = {
      nombre: inputValue,
      tipoEvento: this.state.tipoEvento
    }

    FetchNewEtiqueta(this, etiqueta, newOption)

  }

  handleOnCreateobjetivoMetaTarea = () => {

    if(this.state.tipoEvento === "Objetivo"){

      let objetivo = {
        nombre: this.state.objetivoMetaTarea.nombre,
        idCreador: this.state.objetivoMetaTarea.idCreador,
        idSucursal: this.state.objetivoMetaTarea.idSucursal !== '' ? this.state.objetivoMetaTarea.idSucursal : null,
        idsInvolucrados: this.state.objetivoMetaTarea.idsInvolucrados,
        fechaDeInicio: moment(`${this.state.objetivoMetaTarea.fechaDeInicio}:${this.state.objetivoMetaTarea.horaDeInicio}`, "YYYY-MM-DD:HH:mm")._d.toString(),
        fechaFinalPlanificada: moment(`${this.state.objetivoMetaTarea.fechaFinalPlanificada}:${this.state.objetivoMetaTarea.horaFinalPlanificada}`, "YYYY-MM-DD:HH:mm")._d.toString(),
        estado: this.state.objetivoMetaTarea.estado,
        tipo: this.state.objetivoMetaTarea.tipo,
        nivelDeVisualizacion: this.state.objetivoMetaTarea.nivelDeVisualizacion,
        observaciones: this.state.objetivoMetaTarea.observaciones
      }

      FetchNewObjetivo(this, objetivo, this.props.data.stateCheckBox)

    }else if(this.state.tipoEvento === "Meta"){

      let meta = {
        nombre: this.state.objetivoMetaTarea.nombre,
        idCreador: this.state.objetivoMetaTarea.idCreador,
        idSucursal: this.state.objetivoMetaTarea.idSucursal !== '' ? this.state.objetivoMetaTarea.idSucursal : null,
        idObjetivo: this.state.objetivoMetaTarea.idObjetivo,
        idsInvolucrados: this.state.objetivoMetaTarea.idsInvolucrados,
        fechaDeInicio: moment(`${this.state.objetivoMetaTarea.fechaDeInicio}:${this.state.objetivoMetaTarea.horaDeInicio}`, "YYYY-MM-DD:HH:mm")._d.toString(),
        fechaFinalPlanificada: moment(`${this.state.objetivoMetaTarea.fechaFinalPlanificada}:${this.state.objetivoMetaTarea.horaFinalPlanificada}`, "YYYY-MM-DD:HH:mm")._d.toString(),
        estado: this.state.objetivoMetaTarea.estado,
        tipo: this.state.objetivoMetaTarea.tipo,
        tipoIndicador: this.state.objetivoMetaTarea.tipoIndicador,
        indicador: parseInt(this.state.objetivoMetaTarea.indicador),
        contador: 0,
        nivelDeVisualizacion: this.state.objetivoMetaTarea.nivelDeVisualizacion,
        observaciones: this.state.objetivoMetaTarea.observaciones
      }

      FetchNewMeta(this, meta, this.props.data.stateCheckBox)

    }else if(this.state.tipoEvento === "Tarea"){

      let tarea = {
        nombre: this.state.objetivoMetaTarea.nombre,
        idCreador: this.state.objetivoMetaTarea.idCreador,
        idSucursal: this.state.objetivoMetaTarea.idSucursal !== '' ? this.state.objetivoMetaTarea.idSucursal : null,
        idMeta: this.state.objetivoMetaTarea.idMeta !== "" ? this.state.objetivoMetaTarea.idMeta : null,
        idsInvolucrados: this.state.objetivoMetaTarea.idsInvolucrados,
        fechaDeInicio: moment(`${this.state.objetivoMetaTarea.fechaDeInicio}:${this.state.objetivoMetaTarea.horaDeInicio}`, "YYYY-MM-DD:HH:mm")._d.toString(),
        fechaFinalPlanificada: moment(`${this.state.objetivoMetaTarea.fechaFinalPlanificada}:${this.state.objetivoMetaTarea.horaFinalPlanificada}`, "YYYY-MM-DD:HH:mm")._d.toString(),
        estado: this.state.objetivoMetaTarea.estado,
        tipo: this.state.objetivoMetaTarea.tipo,
        nivelDeVisualizacion: this.state.objetivoMetaTarea.nivelDeVisualizacion,
        observaciones: this.state.objetivoMetaTarea.observaciones
      }

      FetchNewTarea(this, tarea, this.props.data.stateCheckBox)

    }

  }

  render(){
    let modalVisible = this.props.modalVisible,
        handleCloseModal = this.props.handleCloseModal

    let SelectUsuarios = Array.from(this.props.usuarios, usuario => {
      return { value: usuario, label: `${usuario.nombre} ${usuario.apellidos}` } //value: empresa.nombre_comercial
    }).filter( selectUsuario =>  selectUsuario.value.id !== this.props.usuario.id )

    //Etiquetas
    const isLoading = this.state.etiquetas.isLoading,
          value = this.state.etiquetas.value

    let createOption = (label) => ({
        label,
        value: label
    });

    let optionsFilter = this.props.dataAdminObjetivos.etiquetas.filter( etiqueta => {
        return etiqueta.tipoEvento === this.state.tipoEvento
      }),
      options = optionsFilter.map( etiqueta => {
        return createOption(etiqueta.nombre)
      })
    //Termina Etiquetas

    let empresaBiolab = this.props.dataEmpresas.empresas.filter( empresa => {
      return empresa.nombre_comercial === "Biolab"
    })[0],
        sucursales = this.props.dataEmpresas.sucursales.filter( sucursal => {
          return sucursal.id_empresa === empresaBiolab.id
        })

      return(
        <ModalContainer>
          <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                <img className="brand-modal" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco" />
                <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                  <div></div>
                  <div></div>
                </button>
                </div>

                <div className="sin-margen-y-padding container-fluid">
                  <div className="row text-center">
                    <div className="col-12">
                      <FontAwesomeIcon icon="calendar-check" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                      <h1>Crear Objetivo, Meta o Tarea</h1>
                    </div>
                    <div className="col-12" style={{'marginTop': '25px'}}>

                      <form className="form-row">

                        <div className="form-group col-12 col-md-6 col-lg-4 mx-auto" style={{marginTop: '25px'}}>
                          <label htmlFor="tipoEvento" style={{marginRight: '5px'}}>Tipo Evento: </label>
                          <select value={this.state.tipoEvento} id="tipoEvento" className="custom-select" style={{width: 'auto'}} onChange={this.onInputChange}>
                            <option value="Objetivo">Objetivo</option>
                            <option value="Meta">Meta</option>
                            <option value="Tarea">Tarea</option>
                          </select>
                        </div>

                        <div className="form-group col-12 col-md-6 col-lg-4 mx-auto" style={{marginTop: '25px'}}>
                          <label htmlFor="nombre" style={{marginRight: '5px'}}>Nombre: </label>
                          <input id="nombre" type="text" value={this.state.objetivoMetaTarea.nombre} onChange={this.onInputChange} />
                        </div>

                        <div className="form-group col-12 col-md-6 col-lg-4 mx-auto" style={{marginTop: '25px'}}>
                          <label htmlFor="fechaInicio" style={{marginRight: '5px'}}>Fecha de Inicio: </label>
                          <input id="fechaInicio" type="date" value={this.state.objetivoMetaTarea.fechaDeInicio} onChange={this.onInputChange} />
                          <input id="horaInicio" type="time" value={this.state.objetivoMetaTarea.horaDeInicio} onChange={this.onInputChange}/>
                        </div>

                        <div className="form-group col-12 col-md-6 col-lg-4 mx-auto" style={{marginTop: '25px'}}>
                          <label htmlFor="fechaFin" style={{marginRight: '5px'}}>Fecha de Fin: </label>
                          <input id="fechaFin" type="date" value={this.state.objetivoMetaTarea.fechaFinalPlanificada} onChange={this.onInputChange} />
                          <input id="horaFin" type="time" value={this.state.objetivoMetaTarea.horaFinalPlanificada} onChange={this.onInputChange}/>
                        </div>

                        <div className="form-group col-12 col-md-6 col-lg-4 mx-auto" style={{marginTop: '25px'}}>
                          <label htmlFor="sucursal" style={{marginRight: '5px'}}>Sucursal: </label>
                          <select id="sucursal" className="custom-select" style={{width: 'auto'}} onChange={this.onInputChange}>
                            <option value="">Ninguna</option>
                            {
                              sucursales.map( sucursal => {
                               return <option key={sucursal.id} value={sucursal.id}>{sucursal.nombre_comercial}</option>
                              })
                            }
                          </select>
                        </div>

                        <div className="form-group col-12 col-md-6 col-lg-4 mx-auto" style={{marginTop: '25px'}}>
                          <Select
                            id="selectUsuarios"
                            className="col-12"
                            placeholder="Compartir con Usuarios"
                            isMulti={true} 
                            isClearable
                            value={this.state.selectedOptionUsuarios}
                            onChange={this.onInputChangeSelect}
                            options={SelectUsuarios}
                            components={{
                              ValueContainer: this.MyValueContainer
                            }}
                          />
                        </div>

                        {
                          this.state.tipoEvento === "Meta" ? (
                            <div className="form-group col-12 col-md-6 col-lg-4 mx-auto" style={{marginTop: '25px'}}>
                              <label htmlFor="tipoIndicador" style={{marginRight: '5px'}}>Tipo de Indicador: </label>
                              <select id="tipoIndicador" className="custom-select" style={{width: 'auto'}} onChange={this.onInputChange}>
                                <option value="Mas Que o Igual">Más Que o Igual</option>
                                <option value="Menos Que o Igual">Menos Que o Igual</option>
                              </select>
                            </div>
                          ) : null
                        }

                        {
                          this.state.tipoEvento === "Meta" ? (
                            <div className="form-group col-12 col-md-6 col-lg-4 mx-auto" style={{marginTop: '25px'}}>
                              <label htmlFor="indicador" style={{marginRight: '5px'}}>Indicador: </label>
                              <input id="indicador" type="number" value={this.state.objetivoMetaTarea.indicador} onChange={this.onInputChange} />
                            </div>
                          ) : null
                        }

                        {
                          this.state.tipoEvento === "Meta" ? (
                            <div className="form-group col-12 col-md-6 col-lg-4 mx-auto" style={{marginTop: '25px'}}>
                              <label htmlFor="idObjetivo" style={{marginRight: '5px'}}>Selecciona un Objetivo: </label>
                              <select id="idObjetivo" defaultValue="" className="custom-select" style={{width: 'auto'}} onChange={this.onInputChange}>
                                <option value="" disabled>Selecciona un Objetivo</option>
                                {
                                  this.props.dataAdminObjetivos.objetivos.map( objetivo => {
                                    return <option key={objetivo.id} value={objetivo.id}>{objetivo.nombre}</option>
                                  })
                                }
                              </select>
                            </div>
                          ) : null
                        }

                        {
                          this.state.tipoEvento === "Tarea" ? (
                            <div className="form-group col-12 col-md-6 col-lg-4 mx-auto" style={{marginTop: '25px'}}>
                              <label htmlFor="idMeta" style={{marginRight: '5px'}}>Selecciona un Objetivo: </label>
                              <select id="idMeta" defaultValue="" className="custom-select" style={{width: 'auto'}} onChange={this.onInputChange}>
                                <option value="" disabled>Selecciona una Meta</option>
                                {
                                  this.props.dataAdminObjetivos.metas.map( meta => {
                                    return <option key={meta.id} value={meta.id}>{meta.nombre}</option>
                                  })
                                }
                              </select>
                            </div>
                          ) : null
                        }

                        <div className="form-group col-12 col-md-6 col-lg-4 mx-auto" style={{marginTop: '25px'}}>
                          <label htmlFor="nivelDeVisualizacion" style={{marginRight: '5px'}}>Nivel de Visualización: </label>
                          <select id="nivelDeVisualizacion" className="custom-select" style={{width: 'auto'}} onChange={this.onInputChange}>
                            <option value="Todos">Todos</option>
                            {
                              ( (this.props.usuario.colaborador.puesto.includes("Director")) || (this.props.usuario.colaborador.puesto.includes("Coordinador")) || (this.props.usuario.colaborador.puesto.includes("Asistente")) ) ? <option value="Coordinacion">Coordinacion</option> : null
                            }
                            {
                              this.props.usuario.colaborador.puesto.includes("Director") ? <option value="Direccion">Dirección</option> : null
                            }
                          </select>
                        </div>

                        <div className="form-group col-12 col-md-6 col-lg-4 mx-auto" style={{marginTop: '25px'}}>
                          <label htmlFor="observaciones" style={{marginRight: '5px'}}>Observaciones: </label>
                          <input id="observaciones" type="text" value={this.state.objetivoMetaTarea.observaciones} onChange={this.onInputChange} />
                        </div>

                        <div className="form-group col-12 col-md-6 col-lg-4 mx-auto" style={{marginTop: '25px'}}>
                            {/*<label htmlFor="etiquetasInput" style={{marginRight: '5px'}}>Tipo: </label>*/}
                            <AsyncCreatable
                                className="col-12"
                                isClearable
                                isDisabled={isLoading}
                                isLoading={isLoading}
                                onChange={this.handleChange}
                                onCreateOption={this.handleCreate}
                                options={options}
                                value={value}
                                isMulti={false} 
                                id="etiquetasInput"
                                placeholder="Busca o Crea un Tipo"/>
                            {/*<small id="etiquetas" className="form-text text-muted">Etiquetas de puestos persona.</small>*/}
                        </div>

                      </form>
                      {/*
                        data.slots.map( (slot, index) => <li key={index}>{slot.toString()}</li> )
                      */}
                      <form>
                        
                      </form>
                    </div>
                  </div>
                  {
                    this.state.mensajeInsert !== null ? (
                      <div className="row">
                        {
                          (this.state.mensajeInsert.response === 'success') ? (
                            <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.mensajeInsert.mensaje}</div>
                          ) : (
                            <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.mensajeInsert.mensaje}</div>
                          )
                        }
                      </div>
                    ) : null
                  }
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default mx-auto" onClick={this.handleOnCreateobjetivoMetaTarea}><FontAwesomeIcon icon="save" style={{'fontSize': '150%'}} /></button>

                  <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
                </div>
              </div>
            </div>
        </ModalB4>
      </ModalContainer>
    )
  }
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario,
    usuarios: state.dataUsers.usuarios,
    dataAdminObjetivos: state.dataAdminObjetivos,
    dataEmpresas: state.dataEmpresas
  }
}

export default connect(mapStateToProps)(Insert)