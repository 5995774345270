// JavaScript Document
import GraphQL from './ConsultaGraphQLGetTipoCliente';

import url from '../../../../../../apiService/ApiUrl.js';
import urlencode from 'urlencode';

function handleError(err){
	console.log(`Request failed: ${err}`);
}

const fetchGetTipoCliente = ({ setState, state, idTipoCliente }) => {

	const options = {
		method: 'GET',
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(`${url}?query=query${urlencode(GraphQL(idTipoCliente))}`, options)
	.then(res => res.json())
	.then( (res) => {

		setState({
			...state,
			tipoCliente: res.data.appInfo.productos.tipoCliente
		})

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchGetTipoCliente;
