import React, { Component } from 'react';

import NavbarPacientesComponent from '../Components/NavbarInicio.jsx';

//Importando Link de React Router
import { NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

import $ from 'jquery';

import menu from '../../../../../../MyModules/menu.js';

class NavbarPacientes extends Component {

	componentDidMount(){
		menu.mountMenuInicio();
	}

	toScroll(el) {
	  //console.log(el.id);
  	  $('html, body').stop().animate({
  	  	scrollTop: $(`#${el.id}`).offset().top
  	  }, 1500, 'easeInOutExpo');
  }

	render(){
		return(
			<NavbarPacientesComponent id="NavbarInicio" className="navbar navbar-expand-md navbar-default fixed-top container-fluid">

				<NavLink className="navbar-brand py-2" to="/" rel="external" data-ajax="false">
					<img id="brand" src="/img/Inicio/LogoBlancoConLetra.svg" alt="Logo Biolab Blanco" />
				</NavLink>

				<button type="button" className="navbar-toggler x collapsed float-right" data-toggle="collapse" data-target="#NavbarInicio-collapse" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
					<span className="icon-bar"></span>
					<span className="icon-bar"></span>
					<span className="icon-bar"></span>
					<span className="sr-only">Toggle navigation</span>
				</button>


				{/*<div className="navbar-header">

					<div id="icono-tipo-cliente" className="navbar-brand" href="#page-top">
						<span className="icon-icono-de-familia"></span>
						<p>Pacientes</p>
					</div>

				</div>*/}

				<ul className="navbar-nav" style={{zIndex: '10'}}>
					{
						this.props.conexion === false ? (
							<li className="nav-item login" style={{position: "absolute", right: "45px", top: "18px"}}>
								<NavLink to="/login" className="page-scroll nav-link d-none d-md-block" rel="external" data-ajax="false" >LogIn</NavLink>{/*target="_self"*/}
							</li>
						) : (
							<li className="nav-item login" style={{position: "absolute", right: "45px", top: "18px"}}>
								<NavLink to="/dashboard" className="page-scroll nav-link d-none d-md-block" rel="external" data-ajax="false" >Dashboard</NavLink>{/*target="_self"*/}
							</li>
						)
					}

				</ul>

				<div className="collapse navbar-collapse sin-margen-y-padding fixed-top" id="NavbarInicio-collapse">
					<ul id="navbar-nav-inicio" className="navbar-nav" style={{zIndex: '10'}}>
						<li className="nav-item">
							<NavHashLink className="nav-link" to="/#inicio-portada" rel="external" data-ajax="false" activeStyle={{'color': '#2984be'}} activeClassName="active" scroll={this.toScroll}>Inicio</NavHashLink>
						</li>
						<li className="dropdown" style={{'display' : 'inline-block'}}>
			  				<div id="dropdown-container" className="row justify-content-center">
								<a className="dropdown-toggle dropdown-toggle-split nav-link col-12" data-toggle="dropdown" role="button" aria-expanded="false" rel="external" data-ajax="false" style={{'textAlign' : 'center', minWidth: '100px'}} href="#QuienesSomos">Servicios <span className="caret"></span></a>
								<div className="dropdown-menu col-12">
									<NavLink exact to="/pacientes/analisis-clinicos" activeClassName="active" className="nav-link dropdown-item" >Laboratorio Clínico</NavLink>
									<NavLink exact to="/laboratorios-y-hospitales/analisis-clinicos" activeClassName="active" className="nav-link dropdown-item" >Laboratorio De Referencia</NavLink>
									<NavLink exact to="/empresas-e-indusrias/analisis-microbiologico" activeClassName="active" className="nav-link dropdown-item" >Laboratorio Industrial</NavLink>
									<NavLink exact to="/veterinaria/analisis-clinicos" activeClassName="active" className="nav-link dropdown-item" >Laboratorio Veterinario</NavLink>
									<NavLink exact to="/servicios-medicos" activeClassName="active" className="nav-link dropdown-item" >Servicios Médicos</NavLink>
								</div>
			  				</div>
						</li>
						<li className="nav-item">
							<NavHashLink className="nav-link" to="/#seccion-reconocimiento-inicio" rel="external" data-ajax="false" activeStyle={{'color': '#2984be'}} activeClassName="active" scroll={this.toScroll}>Acreditaciones</NavHashLink>
						</li>
						<li className="nav-item">
							<NavHashLink className="nav-link" to="/#seccion-promociones" rel="external" data-ajax="false" activeStyle={{'color': '#2984be'}} activeClassName="active" scroll={this.toScroll}>Promociones</NavHashLink>
						</li>
						<li className="nav-item">
							<NavLink className="nav-link" to="/pacientes/contacto" rel="external" data-ajax="false" activeStyle={{'color': '#2984be'}}>Contáctenos</NavLink>
						</li>
						{
							this.props.conexion === false ? (
							<li className="nav-item d-sm-block d-md-none">
								<NavLink className="nav-link" to="/login" rel="external" data-ajax="false" activeStyle={{'color': '#2984be'}}>LogIn</NavLink>
							</li>
							) : (
							<li className="nav-item d-sm-block d-md-none">
								<NavLink className="nav-link" to="/dashboard" rel="external" data-ajax="false" activeStyle={{'color': '#2984be'}}>Dashboard</NavLink>
							</li>
							)
						}
					</ul>

				</div>

			</NavbarPacientesComponent>
		)
	}

}

export default NavbarPacientes;
