import React, { Component } from 'react';

import { connect } from 'react-redux';

import Summernote from '../../../../Summernote/Containers/Summernote.jsx';

class AnalisisDeAlimentos extends Component {

  render(){
    let renderVal = this.props.dataUI,
    textFisicoQuimicoAlimentosObj = renderVal.textos.find( text => {
      return text.id === 'tex015'
    }),
    texto = textFisicoQuimicoAlimentosObj.texto
    return(
      <div>
        <div className="container">
    			<h4 className="col-12"><span className="icon-restaurant-cutlery" style={{"color": "#2984be", "fontSize": "60px", "top": "15px", "position": "relative"}}></span> ALIMENTOS</h4>
    		</div>
    		<div id="texto-fisico-quimico-alimentos" className="container" />
        <Summernote itemID='#texto-fisico-quimico-alimentos' textObj={textFisicoQuimicoAlimentosObj} texto={texto} url='http://localhost:8080/updateText' />
      </div>
    )
  }

}

let mapStateToProps = (state, props) => {
  return {
    dataUI: state.dataUI
  }
}

export default connect(mapStateToProps)(AnalisisDeAlimentos);
