import React, { Component} from 'react';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../../../Modal/Components/Modal.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { connect } from 'react-redux';

import OrdenEmpresa from '../../components/RealizarOrdenMuestras/OrdenEmpresa.jsx';

import OrdenUsuario from '../../components/RealizarOrdenMuestras/OrdenUsuario.jsx';

import Toggle from 'react-toggle';
import "react-toggle/style.css" // for ES6 modules

import ID from '../../../../../Service/ID.js';

import PlantillaRutaEditFetch from './PlantillaRutaEditFetch/fetchGraphQL.js';

class PlantillaRutaMensajeriaView extends Component {

  constructor(props) {

		super(props);
		
		this.state = {
      showForm: false,
      tipoOrden: "Administrativa",
      newOrden: {
          selectedOptionEmpresas: null,
          selectedOptionUsuarios: null,
          selectedOptionMensajeros: null,
          DatosPredefinidos: true,
          urgente: false,
          fechaInputDate: {
              date: new Date(),
              //dateValue: "",
              timeValue: "",
              minDateValue: "",
              minTimeValue: "00:00"
          }
      },
      mensajeUpdatePlantillaRuta: null
    }
    
    this.handleDisplayInputNewOrder = this.handleDisplayInputNewOrder.bind(this);
    this.handleTipoOrdenChange = this.handleTipoOrdenChange.bind(this);
    this.handleChangeEmpresa = this.handleChangeEmpresa.bind(this);
    this.handleChangeUsuario = this.handleChangeUsuario.bind(this);
    this.handleChangeEmpresaDatosPredefinidos = this.handleChangeEmpresaDatosPredefinidos.bind(this);
    this.handleChangeUsuarioDatosPredefinidos = this.handleChangeUsuarioDatosPredefinidos.bind(this);
    this.handleDatosPredefinidos = this.handleDatosPredefinidos.bind(this);
    this.handleChangefechaInputTime = this.handleChangefechaInputTime.bind(this);
    this.handleUrgenteChange = this.handleUrgenteChange.bind(this);
    this.handleUrgenteChange = this.handleUrgenteChange.bind(this);
    this.handleAddOrden = this.handleAddOrden.bind(this);
    this.handleRemoveOrden = this.handleRemoveOrden.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  componentDidMount(){

    //Creando la fecha minima
    let date = this.state.newOrden.fechaInputDate.date
    let dia
    (date.getDate()) < 10 ? dia = `0${date.getDate()}` : dia = `${date.getDate()}`
    let mes
    (date.getMonth() + 1) < 10 ? mes = `0${date.getMonth() + 1}` : mes = `${date.getMonth() + 1}`
    let year = date.getFullYear()
    //Fecha minima
    let minDate = `${year}-${mes}-${dia}`

    this.setState({
        newOrden:{
            ...this.state.newOrden,
            fechaInputDate: {
                ...this.state.newOrden.fechaInputDate,
                minDateValue: minDate
            } 
        }
    })

  }

  handleDisplayInputNewOrder(){
    if(this.state.showForm === true){
        this.setState({
            showForm: false,
            tipoOrden: "Administrativa",
            newOrden: {
                selectedOptionEmpresas: null,
                selectedOptionUsuarios: null,
                selectedOptionMensajeros: null,
                DatosPredefinidos: true,
                urgente: false,
                fechaInputDate: {
                    ...this.state.newOrden.fechaInputDate,
                    date: new Date(),
                    //dateValue: "",
                    timeValue: ""
                }
            }
        })  
    }else{
        this.setState({
            showForm: true
        })
    }
  }

  handleTipoOrdenChange(event){
    this.setState({
        tipoOrden: event.target.value
    })
  }

  handleChangeEmpresa = selectedOptionEmpresas => {

    this.setState({
        newOrden:{
            ...this.state.newOrden,
            selectedOptionEmpresas,
            selectedOptionUsuarios: null
        }
    })

    //console.log(`Option selected:`, selectedOption);

  }

  handleChangeUsuario = selectedOptionUsuarios => {

    this.setState({
        newOrden:{
            ...this.state.newOrden,
            selectedOptionUsuarios,
            selectedOptionEmpresas: null
        }
    })

    //console.log(`Option selected:`, selectedOption);

  }

  handleChangeEmpresaDatosPredefinidos() {

    let dirYTelPre = document.getElementById("dirYTelPre");

    this.setState({
        newOrden:{
            ...this.state.newOrden,
            DatosPredefinidos: true
        }
    })

    dirYTelPre.checked = true;

    dirYTelPre.parentNode.classList.add('react-toggle--checked');

  }

  handleChangeUsuarioDatosPredefinidos() {
    
    let dirYTelPre = document.getElementById("dirYTelPre");

    this.setState({
        newOrden:{
            ...this.state.newOrden,
            DatosPredefinidos: false
        }
    })

    dirYTelPre.checked = false;

    dirYTelPre.parentNode.classList.remove('react-toggle--checked');

  }

  handleDatosPredefinidos(){
    if(this.state.newOrden.DatosPredefinidos === true){
      this.setState({
        newOrden:{
            ...this.state.newOrden,
            DatosPredefinidos: false
        }
      })
    }else{
        this.setState({
            newOrden:{
                ...this.state.newOrden,
                DatosPredefinidos: true
            }
        })
    }
  }

  handleChangefechaInputTime = (event) => {

    this.setState({
        newOrden:{
            ...this.state.newOrden,
            fechaInputDate: {
                ...this.state.newOrden.fechaInputDate,
                timeValue: event.target.value
            } 
        }
    })

  }

  handleUrgenteChange(){
    if(this.state.newOrden.urgente === false){
      this.setState({
          newOrden:{
              ...this.state.newOrden,
              urgente: true
          }
      })
    }else{
      this.setState({
          newOrden:{
              ...this.state.newOrden,
              urgente: false
          }
      })
    }
  }

  handleAddOrden(){

    let orden

    if (this.state.newOrden.selectedOptionEmpresas !== null) {
  
        if (this.state.newOrden.DatosPredefinidos === true) {
            if(this.state.tipoOrden === "RecoleccionMuestra"){
                orden = {
                    idProvisional: ID(),
                    idEmpresa: this.state.newOrden.selectedOptionEmpresas.value.id,
                    idUsuario: null,
                    cantidadDeMuestras: document.querySelector('#cantidadDeMuestras').value,
                    instrucciones: document.querySelector('#instruccionesDeOrden').value,
                    personaQueLlamo: document.querySelector('#PersonaQueLlamo').value,
                    telefono: this.state.newOrden.selectedOptionEmpresas.value.telefono,
                    direccionDeRecepcion: this.state.newOrden.selectedOptionEmpresas.value.direccion_comercial,
                    horaDeOrden: this.state.newOrden.fechaInputDate.timeValue,
                    tipo: this.state.tipoOrden,
                    urgente: this.state.newOrden.urgente
                }
            }else{
                orden = {
                  idProvisional: ID(),
                  idEmpresa: this.state.newOrden.selectedOptionEmpresas.value.id,
                  idUsuario: null,
                  instrucciones: document.querySelector('#instruccionesDeOrden').value,
                  personaQueLlamo: document.querySelector('#PersonaQueLlamo').value,
                  telefono: this.state.newOrden.selectedOptionEmpresas.value.telefono,
                  direccionDeRecepcion: this.state.newOrden.selectedOptionEmpresas.value.direccion_comercial,
                  horaDeOrden: this.state.newOrden.fechaInputDate.timeValue,
                  tipo: this.state.tipoOrden,
                  urgente: this.state.newOrden.urgente
                }
            }
        } else {
        
            if(this.state.tipoOrden === "RecoleccionMuestra"){
                orden = {
                    idProvisional: ID(),
                    idEmpresa: this.state.newOrden.selectedOptionEmpresas.value.id,
                    idUsuario: null,
                    cantidadDeMuestras: document.querySelector('#cantidadDeMuestras').value,
                    instrucciones: document.querySelector('#instruccionesDeOrden').value,
                    personaQueLlamo: document.querySelector('#PersonaQueLlamo').value,
                    telefono: document.querySelector('#telefono').value,
                    direccionDeRecepcion: document.querySelector('#direccionDeRecepcion').value,
                    horaDeOrden: this.state.newOrden.fechaInputDate.timeValue,
                    tipo: this.state.tipoOrden,
                    urgente: this.state.newOrden.urgente
                  }
            }else{
                orden = {
                  idProvisional: ID(),
                  idEmpresa: this.state.newOrden.selectedOptionEmpresas.value.id,
                  idUsuario: null,
                  instrucciones: document.querySelector('#instruccionesDeOrden').value,
                  personaQueLlamo: document.querySelector('#PersonaQueLlamo').value,
                  telefono: document.querySelector('#telefono').value,
                  direccionDeRecepcion: document.querySelector('#direccionDeRecepcion').value,
                  horaDeOrden: this.state.newOrden.fechaInputDate.timeValue,
                  tipo: this.state.tipoOrden,
                  urgente: this.state.newOrden.urgente
                }
            }
        
        }
  
        
    } else if (this.state.newOrden.selectedOptionUsuarios !== null) {
        
        if (this.state.newOrden.DatosPredefinidos === true) {
            if(this.state.tipoOrden === "RecoleccionMuestra"){
                orden = {
                    idProvisional: ID(),
                    idEmpresa: null,
                    idUsuario: this.state.newOrden.selectedOptionUsuarios.value.id,
                    cantidadDeMuestras: document.querySelector('#cantidadDeMuestras').value,
                    instrucciones: document.querySelector('#instruccionesDeOrden').value,
                    personaQueLlamo: document.querySelector('#PersonaQueLlamo').value,
                    telefono: this.state.newOrden.selectedOptionUsuarios.value.telefono.toString(),
                    direccionDeRecepcion: this.state.newOrden.selectedOptionUsuarios.value.direccion,
                    horaDeOrden: this.state.newOrden.fechaInputDate.timeValue,
                    tipo: this.state.tipoOrden,
                    urgente: this.state.newOrden.urgente
                } 
            }else{
                orden = {
                  idProvisional: ID(),
                  idEmpresa: null,
                  idUsuario: this.state.newOrden.selectedOptionUsuarios.value.id,
                  instrucciones: document.querySelector('#instruccionesDeOrden').value,
                  personaQueLlamo: document.querySelector('#PersonaQueLlamo').value,
                  telefono: this.state.newOrden.selectedOptionUsuarios.value.telefono.toString(),
                  direccionDeRecepcion: this.state.newOrden.selectedOptionUsuarios.value.direccion,
                  horaDeOrden: this.state.newOrden.fechaInputDate.timeValue,
                  tipo: this.state.tipoOrden,
                  urgente: this.state.newOrden.urgente
                }
            }

        } else {
            if(this.state.tipoOrden === "RecoleccionMuestra"){
                orden = {
                    idProvisional: ID(),
                    idEmpresa: null,
                    idUsuario: this.state.newOrden.selectedOptionUsuarios.value.id,
                    cantidadDeMuestras: document.querySelector('#cantidadDeMuestras').value,
                    instrucciones: document.querySelector('#instruccionesDeOrden').value,
                    personaQueLlamo: document.querySelector('#PersonaQueLlamo').value,
                    telefono: document.querySelector('#telefono').value,
                    direccionDeRecepcion: document.querySelector('#direccionDeRecepcion').value,
                    horaDeOrden: this.state.newOrden.fechaInputDate.timeValue,
                    tipo: this.state.tipoOrden,
                    urgente: this.state.newOrden.urgente
                  }  
            }else{
                orden = {
                  idProvisional: ID(),
                  idEmpresa: null,
                  idUsuario: this.state.newOrden.selectedOptionUsuarios.value.id,
                  instrucciones: document.querySelector('#instruccionesDeOrden').value,
                  personaQueLlamo: document.querySelector('#PersonaQueLlamo').value,
                  telefono: document.querySelector('#telefono').value,
                  direccionDeRecepcion: document.querySelector('#direccionDeRecepcion').value,
                  horaDeOrden: this.state.newOrden.fechaInputDate.timeValue,
                  tipo: this.state.tipoOrden,
                  urgente: this.state.newOrden.urgente
                }
              }
            }
  
    }

    this.props.plantillaRuta.ordenes.push(orden)

    this.props.dispatch({
      type:'CHANGE_DATA_PLANTILLA_RUTA_MENSAJERIA',
      payload: {
        dataPlantilla: this.props.plantillaRuta
      }
    })

    this.setState({
      showForm: false,
      tipoOrden: "Administrativa",
      newOrden: {
          selectedOptionEmpresas: null,
          selectedOptionUsuarios: null,
          selectedOptionMensajeros: null,
          DatosPredefinidos: true,
          urgente: false,
          fechaInputDate: {
              ...this.state.newOrden.fechaInputDate,
              date: new Date(),
              //dateValue: "",
              timeValue: ""
          }
      }
    })

  }

  handleRemoveOrden(orden){

    let newOrdenes = this.props.plantillaRuta.ordenes.filter( order => order.idProvisional !== orden.idProvisional )

    this.props.plantillaRuta.ordenes = newOrdenes

    this.props.dispatch({
      type:'CHANGE_DATA_PLANTILLA_RUTA_MENSAJERIA',
      payload: {
        dataPlantilla: this.props.plantillaRuta
      }
    })

  }

  handleSubmit(event){

    event.preventDefault()

    let ordenes = this.props.plantillaRuta.ordenes.filter( orden =>{
      return delete orden.idProvisional
    }),
    plantilla = {
      id: this.props.plantillaRuta.id,
      nombrePlantilla: this.props.plantillaRuta.nombrePlantilla,
      ordenes
    }

    PlantillaRutaEditFetch(this, plantilla)

  }

  render(){

    let plantilla = this.props.plantillaRuta,
        plantillaString = JSON.stringify(plantilla),
        modalVisible = this.props.modalVisible,
        handleCloseModal = this.props.handleCloseModal

      let SelectEmpresa = Array.from(this.props.dataEmpresas.empresas, empresa => {
        return { value: empresa, label: empresa.nombre_comercial } //value: empresa.nombre_comercial
      })
  
      //let { selectedOptionUsuarios } = this.state;
  
    let SelectUsuarios = Array.from(this.props.dataUsers.usuarios, usuario => {
      return { value: usuario, label: `${usuario.nombre} ${usuario.apellidos}` } //value: empresa.nombre_comercial
    })

    let SelectMensajeroArray = this.props.dataUsers.usuarios.filter( usuario => {
        return usuario.colaborador.puesto === 'Mensajero'
      })
  
    let SelectMensajero = Array.from(SelectMensajeroArray, usuario => {
        return { value: usuario, label: `${usuario.nombre} ${usuario.apellidos}` } //value: empresa.nombre_comercial
    })

    SelectMensajero.unshift({value: null, label: 'Ninguno'})

      return(
        <ModalContainer>
          <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                <img className="brand-modal" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco" />
                <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                  <div></div>
                  <div></div>
                </button>
                </div>
  
                <form onSubmit={this.handleSubmit} className="sin-margen-y-padding container-fluid">

                  <div className="form-row text-center">
                      <div className="col-12">
                        <FontAwesomeIcon icon="file-alt" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                        <h1>Nombre de Plantilla: {plantilla.nombrePlantilla}</h1>
                      </div>

                      {
                        this.props.usuario.permisos.Mensajeria.EditPlantillaRuta === true ? (
                        <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                            <label htmlFor="nombreRuta">Nombre de la Plantilla</label>
                            <input onChange={this.props.handleInputChange} ref={this.props.setRef} value={plantilla.nombrePlantilla} data-plantilla-orden={plantillaString} data-value-type="nombrePlantilla" type="text" className="form-control" id="nombreRuta" aria-describedby="nombreRutaHelp" placeholder="Ingresa el nombre de la Plantilla" />
                            <small id="nombreRutaHelp" className="form-text text-muted">El Nombre de la Plantilla.</small>
                        </div>
                        ) : null
                      }


                  </div>

                  <div className="form-row text-center">

                      <div className="col-12" style={{marginTop: '25px'}}>
                        <h2>Ordenes de Plantilla</h2>
                      </div>
    
                      <div className="col-11 col-md-10 mx-auto">
                      
                        <div className="table-responsive">
                            <table className="table text-center table-bordered table-hover table-sm">
                                <thead className="thead-light">
                                <tr>
                                    <th scope="col" className="d-none d-md-table-cell" >Id Provisional de Orden</th>
                                    <th scope="col">Nombre Empresa o Usuario</th>
                                    <th scope="col">Instrucciones</th>
                                    <th scope="col" className="d-none d-sm-table-cell" >Hora</th>
                                    <th scope="col" className="d-none d-md-table-cell" >tipo</th>
                                    <th scope="col">Acciones</th>
                                </tr>
                                </thead>
                                <tbody>

                                {
                                  plantilla.ordenes.map((orden, index) => {
      
                                    let colorFondo = 'transparent';
      
                                    //console.log(mensajero[0].id)
                        
                                    return (
                                    <tr key={orden.idProvisional ? orden.idProvisional : index} style={{backgroundColor: colorFondo}}>
                                        {/*<th scope="row">{usuario.nombre}</th>*/}
                                        <td className="d-none d-md-table-cell">{orden.idProvisional ? orden.idProvisional : index}</td>
                                        {
                                          (orden.idUsuario !== null) ? (
                                            <td>
                                              {
                                                this.props.dataUsers.usuarios.map(usuario => {
                                                  return ((usuario.id === orden.idUsuario) === true) ? `${usuario.nombre} ${usuario.apellidos}`: null
                                                })
                                              }
                                            </td>
                                          ) : null
                                        }
                                        {
                                          (orden.idEmpresa !== null) ? (
                                            <td>
                                              {
                                                this.props.dataEmpresas.empresas.map(empresa => {
                                                  return ((empresa.id === orden.idEmpresa) === true) ? `${empresa.nombre_comercial}`: null
                                                })
                                              }
                                            </td>
                                          ) : null
                                        }
                                        <td>{orden.instrucciones}</td>
                                        <td className="d-none d-sm-table-cell" >{orden.horaDeOrden} horas</td>
                                        <td className="d-none d-md-table-cell">{orden.tipo}</td>
                                        <td>

                                            <button type="button" onClick={() => {
                                                let typeModal = 'mensajeriaPlantillaOrdenesRutasView';
                                                this.props.handleOpenModal(orden, typeModal)
                                            }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                                                <FontAwesomeIcon icon="eye" />
                                            </button> {/*ref={props.setButtonViewUser}*/}
      
                                            {/*<button type="button" onClick={() => {
                                                let typeModal = 'rutaMensjeriaModalEdit';
                                                orden.fechaRutas = this.props.fechaRutas
                                                this.props.handleOpenModal(orden, typeModal)
                                            }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                                                <FontAwesomeIcon icon="edit" />
                                            </button>*/}{/*ref={props.setButtonEditUser}*/}

                                            {
                                              this.props.usuario.permisos.Mensajeria.EditPlantillaRuta === true ? (
                                                <button onClick={ () => {
                                                        this.handleRemoveOrden(orden)
                                                    }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                                                    <FontAwesomeIcon icon="minus-circle" />
                                                </button>
                                              ) : null
                                            }
      
      
                                        </td>
                                    </tr>
                                    )
                                })
                              }
                            </tbody>
                          </table>
                        </div>
    
    
                      </div>
                      
                  </div>

                  {
                      this.state.showForm === true ? (
                        <div className="form-row text-center">
                          <div className="mx-auto col-12 col-md-7" style={{marginTop: '15px'}}>
                              <div className="row">
                                  <p className="col-12 text-center"><b>¿Qué tipo de órden es?</b></p>
                                  <div className="custom-control custom-radio custom-control-inline mx-auto">
                                      <input type="radio" className="custom-control-input" id="customRadio" name="tipoOrden" value="Administrativa" checked={this.state.tipoOrden === "Administrativa"} onChange={this.handleTipoOrdenChange} />
                                      <label className="custom-control-label" htmlFor="customRadio">Administrativa</label>
                                  </div>
                                  <div className="custom-control custom-radio custom-control-inline mx-auto">
                                      <input type="radio" className="custom-control-input" id="customRadio2" name="tipoOrden" value="RecoleccionMuestra" checked={this.state.tipoOrden === "RecoleccionMuestra"} onChange={this.handleTipoOrdenChange} />
                                      <label className="custom-control-label" htmlFor="customRadio2">Recolección de Muestra</label>
                                  </div>
                              </div>
                          </div>
                        </div>
                      ) : null
                  }

                  {
                    this.state.showForm === true ? (
                        <div className="form-row">

                            <nav className="col-12 col-md-2 flex-md-column" style={{marginTop: '30px'}}>
                                <div className="nav nav-pills nav-fill" id="nav-tab" role="tablist">
                                <a className="nav-item nav-link active" id="nav-empresa-tab" data-toggle="tab" href="#nav-ord-empresa" role="tab" aria-controls="nav-ord-empresa" aria-selected="true" onClick={this.handleChangeEmpresaDatosPredefinidos}><FontAwesomeIcon icon="building" /> Orden Empresa</a>
                                <a className="nav-item nav-link" id="nav-usuario-tab" data-toggle="tab" href="#nav-ord-usuario" role="tab" aria-controls="nav-ord-usuario" aria-selected="false" onClick={this.handleChangeUsuarioDatosPredefinidos}><FontAwesomeIcon icon="user" /> Orden Usuario</a>
                                </div>
                            </nav>

                            <div className="col-12 col-md-10" style={{marginTop: '30px'}}>

                                <div className="row" style={{marginBottom: '30px'}}>

                                <div className="tab-content col-sm-6 col-12 " id="nav-tabContent">

                                    <OrdenEmpresa active="active" selectedOptionEmpresas={this.state.newOrden.selectedOptionEmpresas} SelectEmpresa={SelectEmpresa} handleChangeEmpresa={this.handleChangeEmpresa} />

                                    <OrdenUsuario selectedOptionUsuarios={this.state.newOrden.selectedOptionUsuarios} SelectUsuarios={SelectUsuarios} handleChangeUsuario={this.handleChangeUsuario} />

                                </div>

                                {
                                    this.state.tipoOrden === "RecoleccionMuestra" ? (
                                        <div className="col-sm-6 col-12">
                                            <div className="row">
                                            <label htmlFor="cantidadDeMuestras" className="col-lg-6 col-12"><b>Selecciona la cantidad de Muestras</b></label>
                                            <input id="cantidadDeMuestras" type="number" min="1" max="30" className="mx-auto"/>
                                            </div>
                                         </div>
                                    ) : null
                                }

                                <div className="col-sm-6 col-12">
                                    <div className="row">
                                    <label htmlFor="instruccionesDeOrden" className="col-lg-6 col-12"><b>Instrucciones de Orden:</b></label>
                                    <textarea id="instruccionesDeOrden" type="text" className="mx-auto" placeholder="Instrucciones de Orden"/>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-12">
                                    <div className="row">
                                    <label htmlFor="PersonaQueLlamo" className="col-lg-6 col-12"><b>Nombre de la Persona que llamó</b></label>
                                    <input id="PersonaQueLlamo" type="text" className="mx-auto" placeholder="Nombre de Quién llamó"/>
                                    </div>
                                </div>

                                <div className="mx-auto col-12 col-md-7" style={{marginTop: '15px'}}>
                                    <div className="row">
                                    <p className="col-lg-6 col-12 text-center"><b>Usar la dirección y teléfono predefinidos</b></p>
                                    <label className="mx-auto">
                                        <Toggle
                                        id="dirYTelPre"
                                        defaultChecked={this.state.newOrden.DatosPredefinidos}
                                        onChange={this.handleDatosPredefinidos} />
                                    </label>
                                    </div>
                                </div>

                                {
                                    (this.state.newOrden.DatosPredefinidos === true) ? null : (
                                    <div className="col-sm-6 col-12">
                                        <div className="row">
                                        <label htmlFor="telefono" className="col-lg-6 col-12"><b>Número de Teléfono la Persona que llamó</b></label>
                                        <input id="telefono" type="text" className="mx-auto" placeholder="Teléfono de Quién llamó"/>
                                        </div>
                                    </div>
                                    )
                                }

                                {
                                    (this.state.newOrden.DatosPredefinidos === true) ? null : (
                                    <div className="col-sm-6 col-12">
                                        <div className="row">
                                        <label htmlFor="direccionDeRecepcion" className="col-lg-6 col-12"><b>Dirección de Recolección de Muestra</b></label>
                                        <input id="direccionDeRecepcion" type="text" className="mx-auto" placeholder="Dirección de Recolección de Muestra"/>
                                        </div>
                                    </div>
                                    )
                                }

                                <div className="col-sm-6 col-12">
                                    <div className="row">
                                    <label htmlFor="horaDeRecoleccion" className="col-lg-6 col-12"><b>Hora de Recolección</b></label>
                                    <input id="horaDeRecoleccion" type="time" value={this.state.newOrden.fechaInputDate.timeValue} onChange={this.handleChangefechaInputTime} min={this.state.newOrden.fechaInputDate.minTimeValue} className="mx-auto" placeholder="Hora de Recolección"/>
                                    </div>
                                </div>

                                <div className="mx-auto col-12 col-md-7" style={{marginTop: '15px'}}>
                                    <div className="row">
                                    <p className="col-lg-6 col-12 text-center"><b>¿Es Urgente?</b></p>
                                    <label className="mx-auto">
                                        <Toggle
                                        defaultChecked={this.state.newOrden.urgente}
                                        onChange={this.handleUrgenteChange} />
                                    </label>
                                    </div>
                                </div>

                                    <div className="col-md-6 col-12" style={{marginTop: '15px'}}>
                                        <div className="row">
                                        <button type="button" className="btn btn-outline-info col-lg-6 col-10 mx-auto" onClick={this.handleAddOrden}>Agregar Orden</button>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-12" style={{marginTop: '15px'}}>
                                        <div className="row">
                                        <button type="button" className="btn btn-outline-danger col-lg-6 col-10 mx-auto" onClick={this.handleDisplayInputNewOrder}>Cancelar Agregar Orden</button>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    ) : null
                  }

                  {
                    (this.state.showForm === false) && (this.props.usuario.permisos.Mensajeria.EditPlantillaRuta === true) ? (
                        <div className="form-row">
                            <div className="mx-auto">
                                <button type="button" onClick={() => {
                                //props.handleAddOrdenesRuta(solicitudMensajeria)
                                this.handleDisplayInputNewOrder()
                                }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#2984be'}}>
                                Agregar Orden Nueva <FontAwesomeIcon icon="plus-circle" />
                                </button>
                            </div>
                        </div>
                    ) : null
                  }

                  {
                    this.props.usuario.permisos.Mensajeria.EditPlantillaRuta === true ? (
                      <div className="form-row">
                        <div className="col-12 mx-auto text-center" style={{marginTop: '15px'}}>
                          <button type="submit" className="btn btn-default mx-auto"><FontAwesomeIcon icon="save" style={{'fontSize': '150%', 'color': '#2984be'}} /></button>
                        </div>
                      </div>
                    ) : null
                  }


                </form>
                {
                    this.state.mensajeUpdatePlantillaRuta !== null ? (
                        <div className="row" style={{marginTop: '25px'}}>
                        {
                            (this.state.mensajeUpdatePlantillaRuta.response === 'success') ? (
                            <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.mensajeUpdatePlantillaRuta.mensaje}</div>
                            ) : (
                            <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.mensajeUpdatePlantillaRuta.mensaje}</div>
                            )
                        }
                        </div>
                    ) : null
                }
                <div className="modal-footer">
                  <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
                </div>
              </div>
            </div>
        </ModalB4>
      </ModalContainer>
    )

  }

}

let mapStateToProps = (state, props) => {
  return {
    dataMensajeria: state.dataMensajeria
  }
}

export default connect(mapStateToProps)(PlantillaRutaMensajeriaView)
