import React from 'react';

import QRCreator from '../../../../Components/QRCreator';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let ModalView = props => {

  let orden = props.orden,
  usuario,
  empresa,
  creador,
  sucursal = null,
  mensajero = props.dataUsers.usuarios.filter(mensajero => {
    return mensajero.id === orden.idMensajero
  })

  if (orden.idUsuario !== null) {
    usuario = props.dataUsers.usuarios.filter(usuario => {
      return usuario.id === orden.idUsuario
    })
    usuario = usuario[0]
  } else {
    usuario = null
  }

  if (orden.idEmpresa !== null) {
    empresa = props.dataEmpresas.empresas.filter(empresa => {
      return empresa.id === orden.idEmpresa
    })
    empresa = empresa[0]
  } else {
    empresa = null
  }

  if (orden.idCreador !== null) {
    creador = props.dataUsers.usuarios.filter(usuario => {
      return usuario.id === orden.idCreador
    })
    creador = creador[0]
  } else {
    creador = null
  }

  if (orden.idSucursal !== null) {
    sucursal = props.dataEmpresas.sucursales.filter( sucursal => {
      return sucursal.id === orden.idSucursal
    })
    sucursal = sucursal[0]
  } else {
    sucursal = null
  }

  let fechaDeCreacion = new Date(parseInt(orden.fechaDeCreacion))

  //Creando la fecha
  let diaCreacion
  (fechaDeCreacion.getDate()) < 10 ? diaCreacion = `0${fechaDeCreacion.getDate()}` : diaCreacion = `${fechaDeCreacion.getDate()}`
  let mesCreacion
  (fechaDeCreacion.getMonth() + 1) < 10 ? mesCreacion = `0${fechaDeCreacion.getMonth() + 1}` : mesCreacion = `${fechaDeCreacion.getMonth() + 1}`
  let yearCreacion = fechaDeCreacion.getFullYear()
  //Fecha
  let fechaCreacion = `${yearCreacion}-${mesCreacion}-${diaCreacion}`
  //Creando Hora
  let horaCreacion
  (fechaDeCreacion.getHours()) < 10 ? horaCreacion = `0${fechaDeCreacion.getHours()}` : horaCreacion = `${fechaDeCreacion.getHours()}`
  let minutosCreacion
  (fechaDeCreacion.getMinutes()) < 10 ? minutosCreacion = `0${fechaDeCreacion.getMinutes()}` : minutosCreacion = `${fechaDeCreacion.getMinutes()}`
  //Hora
  let timeCreacion = `${horaCreacion}:${minutosCreacion}`

  //Termina Fecha de Creacion

  let fechaDeRecoleccion = new Date(parseInt(orden.fechaDeRecoleccion))

  //Creando la fecha
  let dia
  (fechaDeRecoleccion.getDate()) < 10 ? dia = `0${fechaDeRecoleccion.getDate()}` : dia = `${fechaDeRecoleccion.getDate()}`
  let mes
  (fechaDeRecoleccion.getMonth() + 1) < 10 ? mes = `0${fechaDeRecoleccion.getMonth() + 1}` : mes = `${fechaDeRecoleccion.getMonth() + 1}`
  let year = fechaDeRecoleccion.getFullYear()
  //Fecha
  let fecha = `${year}-${mes}-${dia}`
  //Creando Hora
  let hora
  (fechaDeRecoleccion.getHours()) < 10 ? hora = `0${fechaDeRecoleccion.getHours()}` : hora = `${fechaDeRecoleccion.getHours()}`
  let minutos
  (fechaDeRecoleccion.getMinutes()) < 10 ? minutos = `0${fechaDeRecoleccion.getMinutes()}` : minutos = `${fechaDeRecoleccion.getMinutes()}`
  //Hora
  let time = `${hora}:${minutos}`

  let urgente
  orden.urgente === true ? urgente = "Sí" : urgente = "No"

    return(
      <div className="row text-center">
        
        <div className="col-12">
          <FontAwesomeIcon icon="clipboard-list" style={{'fontSize': '400%', 'marginTop': '25px'}} />
          <h1>Información de Orden</h1>
        </div>
        <div className="col-12" style={{'marginTop': '25px'}}>

            <div className="row">

              <div className="col-md-4 row">
                <p className="col" id="idOrden" style={{'marginTop': '0px'}}><b>Orden Id:</b> {orden.id}</p>
              </div>

              {
                orden.idUsuario === null ? null : (
                  <div className="col-md-4 row">
                    <p className="col" id="nombreUsuario" style={{'marginTop': '0px'}}><b>Nombre de Persona que realizó la orden:</b> {usuario.nombre} {usuario.apellidos}</p>
                  </div>
                )
              }
              
              {
                orden.idEmpresa === null ? null : (
                  <div className="col-md-4 row">
                    <p className="col" id="nombreEmpresa" style={{'marginTop': '0px'}}><b>Nombre de Empresa que realizó la orden:</b> {empresa.nombre_comercial}</p>
                  </div>
                )
              }

              {
                creador === null ? null : (
                  <div className="col-md-4 row">
                    <p className="col" id="nombreEmpresa" style={{'marginTop': '0px'}}><b>Nombre del Creador de la orden:</b> {creador.nombre} {creador.apellidos}</p>
                  </div>
                )
              }

              {
                orden.idSucursal === null ? null : (
                  <div className="col-md-4 row">
                    <p className="col" id="nombreEmpresa" style={{'marginTop': '0px'}}><b>Nombre de Sucursal que realizó la orden:</b> {sucursal.nombre_comercial}</p>
                  </div>
                )
              }

              {
                orden.instrucciones === null ? null : (
                  <div className="col-md-4 row">
                    <p className="col" id="instrucciones" style={{'marginTop': '0px'}}><b>Instrucciones:</b> {orden.instrucciones}</p>
                  </div>
                )
              }

              {
                orden.notasDeOrden === null ? null : (
                  <div className="col-md-4 row">
                    <p className="col" id="notasDeOrden" style={{'marginTop': '0px'}}><b>Notas de Orden:</b> {orden.notasDeOrden}</p>
                  </div>
                )
              }

              {
                orden.personaQueDejaLaMuestra === null ? null : (
                  <div className="col-md-4 row">
                    <p className="col" id="personaQueDejaLaMuestra" style={{'marginTop': '0px'}}><b>Persona que Deja la Muestra:</b> {orden.personaQueDejaLaMuestra}</p>
                  </div>
                )
              }

              {
                orden.telefonoDeQueDejaLaMuestra === null ? null : (
                  <div className="col-md-4 row">
                    <p className="col" id="telefonoDeQueDejaLaMuestra" style={{'marginTop': '0px'}}><b>Telefono de Quien Deja la Muestra:</b> {orden.telefonoDeQueDejaLaMuestra}</p>
                  </div>
                )
              }

              {
                orden.correoDeQueDejaLaMuestra === null ? null : (
                  <div className="col-md-4 row">
                    <p className="col" id="correoDeQueDejaLaMuestra" style={{'marginTop': '0px'}}><b>Correo De Quien Deja la Muestra:</b> {orden.correoDeQueDejaLaMuestra}</p>
                  </div>
                )
              }
              
              {
                orden.cantidadDeMuestras === null ? null : (
                  <div className="col-md-4 row">
                    <p className="col" id="cantidadDeMuestras" style={{'marginTop': '0px'}}><b>Cantidad de Muestras:</b> {orden.cantidadDeMuestras}</p>
                  </div>
                )
              }

              {
                orden.razonDeCancelacion === null ? null : (
                  <div className="col-md-4 row">
                    <p className="col" id="razonDeCancelacion" style={{'marginTop': '0px'}}><b>Razón de Cancelación:</b> {orden.razonDeCancelacion}</p>
                  </div>
                )
              }

              {
                mensajero.length > 0 ? (
                  <div className="col-md-4 row">
                    <p className="col" id="mensajero" style={{'marginTop': '0px'}}><b>Mensajero:</b> {mensajero[0].nombre} {mensajero[0].apellidos}</p>
                  </div>
                ) : null
              }

              {
                orden.personaQueLlamo === null ? null : (
                  <div className="col-md-4 row">
                    <p className="col" id="personaQueLlamo" style={{'marginTop': '0px'}}><b>Persona que llamó:</b> {orden.personaQueLlamo}</p>
                  </div>
                )
              }
          
              {
                orden.telefono === null ? null : (
                  <div className="col-md-4 row">
                    <p className="col" id="telefono" style={{'marginTop': '0px'}}><b>Teléfono de Contacto:</b> {orden.telefono}</p>
                  </div>
                )
              }

              {
                orden.direccionDeRecepcion === null ? null : (
                  <div className="col-md-4 row">
                    <p className="col" id="direccion" style={{'marginTop': '0px'}}><b>Dirección:</b> {orden.direccionDeRecepcion}</p>
                  </div>
                )
              }

              
              <div className="col-md-4 row mx-auto">
                <p className="col" id="fechaCreacion" style={{ 'marginTop': '0px' }}><b>Fecha de Creación:</b> {fechaCreacion} a {timeCreacion} horas</p>
              </div>

              {
                orden.fechaDeRecoleccion !== null ? (
                  <div className="col-md-4 row">
                    <p className="col" id="fechaProgramada" style={{ 'marginTop': '0px' }}><b>Fecha Programada:</b> {fecha} a {time} horas</p>
                  </div>
                ) : null
              }
          
          
              <div className="col-md-4 row mx-auto">
                <p className="col" id="tipo" style={{'marginTop': '0px'}}><b>Tipo:</b> {orden.tipo}</p>
              </div>

              {
                orden.urgente === null ? null : (
                  <div className="col-md-4 row">
                    <p className="col" id="urgente" style={{'marginTop': '0px'}}><b>Urgente:</b> {urgente}</p>
                  </div>
                )
              }
          

            </div>

        </div>

        <div className="col-12">
            <QRCreator idOrden={orden.id}/>
        </div>

        <div className="col-xs-12 col-md" style={{marginTop: '15px'}}>
          <div className="row">
            <button type="button" className="btn btn-outline-info mx-auto" onClick={ () => {

              if((orden.tipo === 'Administrativa') && (props.usuario.permisos.Mensajeria.ActOrdenAdmin === true)){
                props.handleChangeEstadoSolicitud()
              }else{
                if((orden.estado === 'Entregado a Mensajero') && (props.usuario.colaborador.puesto !== 'Mensajero') && (props.usuario.permisos.Mensajeria.ActOrdenMuestra === true)){
                  props.handleChangeEstadoSolicitud(orden)
                }else if((orden.tipo === "RecoleccionMuestra") && (props.usuario.permisos.Mensajeria.ActOrdenMuestra === true)){
                  let typeModal = 'changeEstadoYCantidadDeMuestras';
                  props.handleOpenModal(orden, typeModal)
                }
              }

            }} >Cambiar Estado <FontAwesomeIcon icon="sync-alt" /> </button>
          </div>
        </div>

        {
          orden.tipo === "Administrativa" ? (
            <div className="col-xs-12 col-md" style={{marginTop: '15px'}}>
              <div className="row">
                <button type="button" className="btn btn-outline-danger mx-auto" onClick={props.handleCancelar}>Cancelar Orden</button>
              </div>
            </div>
          ) : null
        }

        {
          ( ((props.usuario.tipo === 'colaborador') && ( (props.usuario.colaborador.puesto === "Asistente de Laboratorio") || (props.usuario.colaborador.puesto === "Director de Laboratorio") || (props.usuario.colaborador.puesto === "Coordinador de Laboratorio") || (props.usuario.colaborador.puesto === "Director Administrativo") ) && (orden.tipo === 'RecoleccionMuestra') && (orden.cantidadDeMuestras !== null)) && ((orden.estado === 'Entregado a Laboratorio') || (orden.estado === 'Fuera de Condiciones') || (orden.estado === 'Finalizado')) ) ? 
          ( <div className="col-xs-12 col-md" style={{marginTop: '15px'}}>
              <div className="row">
                <button type="button" className="btn btn-outline-info mx-auto" onClick={props.handleCheckList}>Check List Orden</button>
              </div>
            </div>) : null
        }

        {
          orden.checkList !== null ? (
            <div className="col">
              <div className="row">
                <button type="button" className="btn btn-outline-info mx-auto" onClick={props.handleCheckListView}>Ver Check List Orden</button>
              </div>
            </div>
          ) : null
        }

      </div> 
    )
}

export default ModalView