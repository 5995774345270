import React, { Component } from 'react';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

import Toggle from 'react-toggle';

import Select from 'react-select';

import GraphQLUsuarios from '../../../../../apiService/FetchGraphQLUsuarios';
import GraphQLEmpresas from '../../../../../apiService/FetchGraphQLEmpresas';

import FetchCreteOrdenCompra from './realizarOrdenFetch/FetchGraphQLRealizarOrden';

import getProductosProveedor from './getProductosProveedor/FetchGraphQLGetProductosProveedor';

import DashboardComponent from '../../../dashboard/components/DashboardComponent.jsx';
import ProductoTable from './ProductoTable';
import QRCreator from '../../../Components/QRCreator';

import ProductoProveedorTable from './ProductoProveedorTable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AdminComprasDashboard extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            id_Orden: "",
            idCreador: "",
            idAutorizador: "",
            idProveedor: "",
            idSucursal: "",
            observaciones: "",
            proveedor: "",
            estado: "Pendiente de Autorizacion",
            productos: [],
            productosProveedor: [],
            proveedorRegistrado: true,
            addProducto: false,
            newProducto: {
                nombre: "",
                valorUnitario: "0",
                cantidad: "0",
                NoCatalogo: "",
                descripcion: "",
                observaciones: "",
                tipo: "Insumo de Laboratorio"
            }
        }

        this.onInputChange = this.onInputChange.bind(this);
        this.onInputAutorizadorChange = this.onInputAutorizadorChange.bind(this);
        this.onInputProveedorChange = this.onInputProveedorChange.bind(this);
        this.onAddProductoChange = this.onAddProductoChange.bind(this);
        this.onInputAddProductoChange = this.onInputAddProductoChange.bind(this);
        this.onAddProducto = this.onAddProducto.bind(this);
        this.onQuitProducto = this.onQuitProducto.bind(this);
        this.onSubmitOrdenCompra = this.onSubmitOrdenCompra.bind(this);
        this.onReturnToCreateNewOrdenCompra = this.onReturnToCreateNewOrdenCompra.bind(this);
    }

    componentDidMount(){

        let arrayOfComprasValues = [],
        allComprasValuesFalse = true

		if(this.props.usuario !== null){
			if(this.props.usuario.permisos !== null){
				arrayOfComprasValues = Object.values(this.props.usuario.permisos.Compras)
				allComprasValuesFalse = arrayOfComprasValues.every(v => v === false)
			}
        }
        
        if( (this.props.conexion !== false) && (this.props.usuario.permisos !== null) && ((Object.entries(this.props.usuario.permisos.Compras).length > 0) === true) && (allComprasValuesFalse === false) && (this.props.usuario.permisos.Compras.CreateOrdenCompra === true) ){

            GraphQLUsuarios(this)
            GraphQLEmpresas(this)

            this.setState({
                idCreador: this.props.usuario.id
            })

        }

    }

    onInputChange(event){

        if(event.target.id === "proveedorRegistrado"){
            this.setState({
                [event.target.id]: this.state.proveedorRegistrado === true ? false : true
            })
        }else if(event.target.id === "proveedor"){
            this.setState({
                idProveedor: "",
                [event.target.id]: event.target.value
            })
        }else{
            this.setState({
                [event.target.id]: event.target.value  
            })
        }

    }

    onInputAutorizadorChange(selectObj){

        this.setState({
            idAutorizador: selectObj.value
        })

    }

    onInputProveedorChange(selectObj){

        this.setState({
            idProveedor: selectObj.value,
            proveedor: ""
        })

        getProductosProveedor(this, selectObj.value)

    }

    onAddProductoChange(){
        this.setState({
            addProducto: this.state.addProducto === false ? true : false
        })
    }

    onInputAddProductoChange(event){
        this.setState({
            newProducto: {
                ...this.state.newProducto,
                [event.target.id]: event.target.value
            }
        })
    }

    onAddProducto(productoProveedor){

        if(productoProveedor === undefined){

            let producto = {
                ...this.state.newProducto
            }
            
            producto.valorUnitario = parseFloat(producto.valorUnitario)
            producto.cantidad = parseFloat(producto.cantidad)
    
            if(producto.tipo !== "Insumo de Laboratorio"){
                delete producto["NoCatalogo"]
            }
    
            let productos = [...this.state.productos, producto]
    
            this.setState({
                productos,
                addProducto: false,
                newProducto: {
                    nombre: "",
                    valorUnitario: "0",
                    cantidad: "0",
                    NoCatalogo: "",
                    descripcion: "",
                    observaciones: "",
                    tipo: "Insumo de Laboratorio"
                }
            })
        }else{

            productoProveedor.cantidad = parseInt(productoProveedor.cantidad)

            let productos = [...this.state.productos, productoProveedor]

            this.setState({
                productos,
                addProducto: false,
                newProducto: {
                    nombre: "",
                    valorUnitario: "0",
                    cantidad: "0",
                    NoCatalogo: "",
                    descripcion: "",
                    observaciones: "",
                    tipo: "Insumo de Laboratorio"
                }
            })
        }


    }

    onQuitProducto(i){

        let productos = [...this.state.productos]
        productos.splice(i, 1)

        this.setState({
            productos
        })

    }

    onSubmitOrdenCompra(event){

        event.preventDefault()

        let ordenCompra = {
            ...this.state
        }

        ordenCompra.fecha = new Date().toString()

        delete ordenCompra["addProducto"]
        delete ordenCompra["newProducto"]
        delete ordenCompra["proveedorRegistrado"]

        FetchCreteOrdenCompra(this, ordenCompra)

        this.setState({
            idAutorizador: "",
            idProveedor: "",
            idSucursal: "",
            observaciones: "",
            proveedor: "",
            estado: "Pendiente de Autorizacion",
            productos: [],
            proveedorRegistrado: true,
            addProducto: false,
            newProducto: {
                nombre: "",
                valorUnitario: "0",
                cantidad: "0",
                NoCatalogo: "",
                descripcion: "",
                observaciones: "",
                tipo: "Insumo de Laboratorio"
            }
        })

    }

    onReturnToCreateNewOrdenCompra(){

        this.setState({
            id_Orden: ""
        })

    }

    render(){

        let arrayOfComprasValues = [],
        allComprasValuesFalse = true

		if(this.props.usuario !== null){
			if(this.props.usuario.permisos !== null){
				arrayOfComprasValues = Object.values(this.props.usuario.permisos.Compras)
				allComprasValuesFalse = arrayOfComprasValues.every(v => v === false)
			}
        }

        if (this.props.conexion === false) {
			return <Redirect to='/login' />
		}else if(this.props.usuario.permisos === null){
			return(
				<h1>No te Han Asignado Permisos</h1>
			)
		}else if( (this.props.usuario.permisos === null) || ((Object.entries(this.props.usuario.permisos.Compras).length > 0) !== true) || (allComprasValuesFalse === true) || (this.props.usuario.permisos.Compras.CreateOrdenCompra === false) ) {
            return <Redirect to='/dashboard' />
        }else if(this.props.usuario.permisos.Compras.CreateOrdenCompra === true){

            /*let SelectUsuarios = Array.from(this.props.usuarios, usuario => {
                return { value: usuario.id, label: `${usuario.nombre} ${usuario.apellidos}` }
            }).filter( selectUsuario =>  ((selectUsuario.value.id !== this.props.usuario.id) && (selectUsuario)) )*/

            let usuariosAutorizadores = this.props.usuarios.filter( usuario => {
                let usuarioReturn
                if(usuario.colaborador !== null){
                    if(usuario.colaborador.puesto !== null){
                        usuarioReturn = (usuario.colaborador.puesto.includes('Director')) || (usuario.colaborador.puesto.includes('Coordinador'))
                    }
                }
                return usuarioReturn
            }),
                selectUsuariosAutorizadores  = Array.from(usuariosAutorizadores, usuario => {
                    return { value: usuario.id, label: `${usuario.nombre} ${usuario.apellidos}` }
                }),
                selectProveedores  = Array.from(this.props.empresas.filter( empresa => empresa.clasificacion_empresa.includes('Proveedor') ), empresa => {
                    return { value: empresa.id, label: `${empresa.nombre_comercial}` }
                })

            return(
                <DashboardComponent>

                    <div className="container">
                        {
                            this.state.id_Orden === "" ? (

                                <form className="row text-center" onSubmit={this.onSubmitOrdenCompra} >

                                    <div className="col-12">
                                        <h2 className="text-center">Crear Orden de Compra</h2>
                                    </div>

                                    <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                        <label htmlFor="autorizador" className="col"><b>Autorizador:</b></label>
                                            <Select id="autorizador" options={selectUsuariosAutorizadores} onChange={this.onInputAutorizadorChange} placeholder="Escoge un Autorizador" />
                                        <small id="autorizadorSmall" className="form-text text-muted">Autorizador.</small>
                                    </div>

                                    <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                        <label htmlFor="proveedorRegistrado" className="col"><b>Usar Proveedor Registrado:</b></label>
                                        <Toggle id="proveedorRegistrado" defaultChecked={this.state.proveedorRegistrado} onChange={this.onInputChange}/>
                                        <small id="proveedorSmall" className="form-text text-muted">Proveedor Registrado.</small>
                                    </div>

                                    <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                        <label htmlFor="proveedor" className="col"><b>Proveedor:</b></label>
                                            {
                                                this.state.proveedorRegistrado === true ? <Select id="proveedor" options={selectProveedores} onChange={this.onInputProveedorChange} placeholder="Escoge un Proveedor" /> : <input onChange={this.onInputChange} type="text"  className="form-control" id="proveedor" value={this.state.proveedor} placeholder="Escribe el Nombre de un Proveedor" />
                                            }
                                        <small id="proveedorSmall" className="form-text text-muted">Proveedor.</small>
                                    </div>

                                    <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                        <label htmlFor="idSucursal" className="col"><b>Sucursal:</b></label>
                                        <select id="idSucursal" className="form-control" onChange={this.onInputChange} value={this.state.newProducto.idSucursal}>
                                            <option value="">Ninguna</option>
                                            {
                                                this.props.sucursalesBiolab.map( sucursal =>  <option key={sucursal.id} value={sucursal.id}>{sucursal.nombre_comercial}</option> )
                                            }
                                        </select>
                                        <small id="idSucursalSmall" className="form-text text-muted">Sucursal.</small>
                                    </div>

                                    <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                        <label htmlFor="observaciones" className="col"><b>Observaciones:</b></label>
                                            <input id="observaciones" className="form-control" type="text" value={this.state.observaciones} onChange={this.onInputChange} />
                                        <small id="observacionesSmall" className="form-text text-muted">Observaciones.</small>
                                    </div>

                                    {
                                        this.state.addProducto === false ? (
                                            <div className="form-group col-12 mx-auto">
                                                <button type="button" className="mx-auto btn btn-outline-info" onClick={this.onAddProductoChange} >Agregar Producto o Servicio <FontAwesomeIcon icon="plus-circle" /></button>
                                            </div>
                                        ) : null
                                    }

                                    {
                                        this.state.addProducto === true ? (
                                            <div className="form-group col-12 mx-auto">
                                                <ProductoProveedorTable onAddProducto={this.onAddProducto} productos={this.state.productosProveedor}/>
                                            </div>
                                        ) : null
                                    }

                                    {
                                        this.state.addProducto === true ? (
                                            <div className="form-group col-12 mx-auto">
                                                <h3 className="text-center">Agregar Nuevo Producto</h3>
                                            </div>
                                        ) : null
                                    }

                                    {
                                        this.state.addProducto === true ? (
                                            <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                                <label htmlFor="nombre" className="col"><b>Nombre de Producto o Servicio:</b></label><input onChange={this.onInputAddProductoChange} type="text"  className="form-control" id="nombre" value={this.state.newProducto.nombre} placeholder="Escribe el Nombre de un Producto o Servicio" />
                                                <small id="nombreSmall" className="form-text text-muted">Nombre de Producto o Servicio.</small>
                                            </div> 
                                        ) : null
                                    }

                                    {
                                        this.state.addProducto === true ? (
                                            <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                                <label htmlFor="descripcion" className="col"><b>Descripción de Producto o Servicio:</b></label><input onChange={this.onInputAddProductoChange} type="text"  className="form-control" id="descripcion" value={this.state.newProducto.descripcion} placeholder="Escribe la descripción del Producto o Servicio" />
                                                <small id="descripcionSmall" className="form-text text-muted">Descripción de Producto o Servicio.</small>
                                            </div> 
                                        ) : null
                                    }

                                    {
                                        this.state.addProducto === true ? (
                                            <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                                <label htmlFor="observaciones" className="col"><b>Observación de Producto o Servicio:</b></label><input onChange={this.onInputAddProductoChange} type="text"  className="form-control" id="observaciones" value={this.state.newProducto.observaciones} placeholder="Escribe la observación del Producto o Servicio" />
                                                <small id="descripcionSmall" className="form-text text-muted">Observación de Producto o Servicio.</small>
                                            </div> 
                                        ) : null
                                    }

                                    {
                                        this.state.addProducto === true ? (
                                            <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                                <label htmlFor="tipo" className="col"><b>Tipo de Producto o Servicio:</b></label>
                                                <select id="tipo" className="form-control" onChange={this.onInputAddProductoChange} value={this.state.newProducto.tipo}>
                                                    <option value="Insumo de Laboratorio">Insumo de Laboratorio</option>
                                                    <option value="Producto General">Producto General</option>
                                                    <option value="Servicio">Servicio</option>
                                                </select>
                                                <small id="tipoSmall" className="form-text text-muted">Descripción de Producto o Servicio.</small>
                                            </div> 
                                        ) : null
                                    }

                                    {
                                        ((this.state.addProducto === true) && (this.state.newProducto.tipo === "Insumo de Laboratorio")) ? (
                                            <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                                <label htmlFor="NoCatalogo" className="col"><b>No. de Lote de Catálogo:</b></label><input onChange={this.onInputAddProductoChange} type="text"  className="form-control" id="NoCatalogo" value={this.state.newProducto.NoCatalogo} placeholder="Escribe el No. de Catálogo" />
                                                <small id="NoCatalogoSmall" className="form-text text-muted">No. de Lote</small>
                                            </div> 
                                        ) : null
                                    }

                                    {
                                        this.state.addProducto === true ? (
                                            <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                                <label htmlFor="valorUnitario" className="col"><b>Valor Unitario de Producto o Servicio:</b></label><input onChange={this.onInputAddProductoChange} type="number" min="0"  className="form-control" id="valorUnitario" value={this.state.newProducto.valorUnitario} placeholder="Valor Unitario" step=".01" />
                                                <small id="valorUnitarioSmall" className="form-text text-muted">Valor Unitario de Producto o Servicio</small>
                                            </div> 
                                        ) : null
                                    }

                                    {
                                        this.state.addProducto === true ? (
                                            <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                                <label htmlFor="cantidad" className="col"><b>Cantidad de Producto o Servicio:</b></label><input onChange={this.onInputAddProductoChange} type="number" min="0"  className="form-control" id="cantidad" value={this.state.newProducto.cantidad} placeholder="Cantidad" />
                                                <small id="cantidadSmall" className="form-text text-muted">Cantidad de Producto o Servicio</small>
                                            </div> 
                                        ) : null
                                    }

                                    {
                                        this.state.addProducto === true ? (
                                            <div className="form-group col-12 mx-auto">
                                                <button type="button" className="mx-auto btn btn-outline-info" onClick={()=>{
                                                    this.onAddProducto()
                                                }} >Agregar <FontAwesomeIcon icon="plus-circle" /></button>
                                            </div>
                                        ) : null
                                    }


                                    <div className="form-group col-12 mx-auto">
                                        <ProductoTable productos={this.state.productos} onQuitProducto={this.onQuitProducto}/>
                                    </div>

                                    {
                                        ((this.state.addProducto === false) && (this.state.productos.length > 0)) ? (
                                            <div className="form-group col-12 mx-auto">
                                                <button type="submit" className="mx-auto btn btn-outline-info">Crear Orden de Compra</button>
                                            </div>
                                        ) : null
                                    }

                                </form>

                            ) : (
                                <div className="row">
                                    <div className="col-12">
                                        <QRCreator idOrden={this.state.id_Orden}/>
                                    </div>
                                    <button style={{marginBottom: '25px'}} type="button" className="mx-auto btn btn-outline-info" onClick={this.onReturnToCreateNewOrdenCompra} >Crear Nueva Orden <FontAwesomeIcon icon="plus-circle" /></button>
                                </div>
                            )
                        }

                    </div>

                    
                </DashboardComponent>
            )
        }else{
            return null
        }
    }
}

let mapStateToProps = (state, props) => {
    let empresaBiolab = state.dataEmpresas.empresas.filter( empresa => empresa.nombre_comercial === 'Biolab')[0]
    return {
      conexion: state.logIn.conexion,
      usuario: state.logIn.usuario,
      usuarios: state.dataUsers.usuarios,
      empresas: state.dataEmpresas.empresas,
      sucursalesBiolab: state.dataEmpresas.sucursales.filter( sucursal => sucursal.id_empresa === empresaBiolab.id )
    }
  }
  
  export default connect(mapStateToProps)(AdminComprasDashboard)