import React from 'react';

import Promociones from '../../../Components/Promociones/Containers/Promociones';

import Pacientes from '../../Components/Pacientes.jsx';

//Import de Modal
import ModalPortal from '../../../../Components/ModalPortal';
import Modal from '../../../../Modal/Containers/ModalDashboard';

let PromocionesPaciente = props => (
  <Pacientes>
    <Promociones />
    <ModalPortal>
      <Modal />
    </ModalPortal>
  </Pacientes>
)

export default PromocionesPaciente
