import React, { Component } from 'react';

import DashboardComponent from '../../dashboard/components/DashboardComponent.jsx';

import AdminObjetivos from './AdminObjetivos.jsx';

import FetchGraphQLEmpresas from '../../../../apiService/FetchGraphQLEmpresas';

import FetchEditMeta from '../containers/Modals/EditMetaFetch/FetchEditMetaPromise.js';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

class AdminUserDashboard extends Component{

	constructor(props) {
		super(props);

		this.state={
			display: "Calendar"
		}

		this.handleOpenModal = this.handleOpenModal.bind(this);
		this.handleChangeDisplay = this.handleChangeDisplay.bind(this);
	}
	  
	componentDidMount() {

		let This = this

		if( (this.props.conexion !== false) && (this.props.usuario.permisos.AdminObjetivos === true) ){

			FetchGraphQLEmpresas(this)

			this.props.dataAdminObjetivos.metas.forEach(meta => {

				if(meta.contador === null){
					meta.contador = 0
					FetchEditMeta(This,meta)
				}
				
			});

		}

	}

	handleOpenModal(modal, typeModal){
    	this.props.dispatch({
			type: `OPEN_MODAL`,
			payload: {
				modal,
				typeModal
			}
		})
	}
	  
	handleChangeDisplay(type){
		if(type === "ObjetivosButton"){
			this.setState({
				display: "Objetivos"
			})
		}else if(type === "MetasButton"){
			this.setState({
				display: "Metas"
			})
		}else if(type === "TareasButton"){
			this.setState({
				display: "Tareas"
			})
		}else if(type === "CalendarButton"){
			this.setState({
				display: "Calendar"
			})
		}
	}

	render(){
		if (this.props.conexion === false) {
			return <Redirect to='/login' />
		}else if(this.props.usuario.permisos.AdminObjetivos !== true){
			return <Redirect to='/dashboard' />
		} else {	
			return(
				<DashboardComponent>
					<AdminObjetivos handleOpenModal={this.handleOpenModal} display={this.state.display} handleChangeDisplay={this.handleChangeDisplay} />
					{
						this.state.display === "Calendar" ? (
							<div className="container" style={{marginBottom: "25px"}}>
								<div className="row">
									<div className="col-12 col-md-4 mx-auto text-center" style={{marginTop: '15px'}}>
										<button type="button" className="btn btn-info" onClick={ () => {
											let type = "ObjetivosButton"
											this.handleChangeDisplay(type)
										} }>Mostrar Objetivos</button>
									</div>
									<div className="col-12 col-md-4 mx-auto text-center" style={{marginTop: '15px'}}>
										<button type="button" className="btn btn-info" onClick={ () => {
											let type = "MetasButton"
											this.handleChangeDisplay(type)
										} }>Mostrar Metas</button>
									</div>
									<div className="col-12 col-md-4 mx-auto text-center" style={{marginTop: '15px'}}>
										<button type="button" className="btn btn-info" onClick={ () => {
											let type = "TareasButton"
											this.handleChangeDisplay(type)
										} }>Mostrar Tareas</button>
									</div>
								</div>
							</div>
						) : null
					}
				</DashboardComponent>
			)

		}
	}
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
	usuario: state.logIn.usuario,
	dataAdminObjetivos: state.dataAdminObjetivos
  }
}

export default connect(mapStateToProps)(AdminUserDashboard)
