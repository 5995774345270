import React, { Component } from 'react';

import touchGalerias from '../../../../../MyModules/touch-galerias.js';

import GaleriaAnalisisFisicoQuimicoComponent from '../Components/GaleriaAnalisisFisicoQuimico.jsx';

class GaleriaAnalisisMicrobiológico extends Component{

	componentDidMount(){
		touchGalerias.mount();
	}

	componentWillUnmount(){
		touchGalerias.unMount();
	}

	render() {
		return (
			<GaleriaAnalisisFisicoQuimicoComponent>
				<h1 className="col-xs-12">ANÁLISIS FÍSICO QUÍMICO</h1>
				  <div id="carousel-analisis-clinicos-pacientes" className="carousel slide" data-ride="carousel">
						<div className="carousel-inner" role="listbox">

						  <div className="carousel-item active"><img src="/img/gim017.png" alt="First slide" className="center-block img-responsive" />
							{/*<div className="carousel-caption">
							  <h3 id="sub1-carousel">METODOLOGÍAS SEGÚN NORMAS NACIONALES E INTERNACIONALES</h3>
							</div>*/}
						  </div>

						</div>
				  </div>
			</GaleriaAnalisisFisicoQuimicoComponent>
		)
	}
}

export default GaleriaAnalisisMicrobiológico;
