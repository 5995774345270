/*General*/
import AnalisisClinicos from '../../../WebPage/Components/AnalisisClinicos/AnalisisClinicos.jsx';

/*Veterinaria*/
//import QuienesSomosVeterinaria from '../../../WebPage/Veterinaria/QuienesSomos/Components/QuienesSomos.jsx';
import VeterinariaContacto from '../../../WebPage/Veterinaria/Contacto/Components/Contacto.jsx';

/*const QUIENES_SOMOS_VETERINARIA = {
    component: QuienesSomosVeterinaria,
    path: "/veterinaria"
}*/

const ANALISIS_CLINICOS_VETERINARIA = {
    component: AnalisisClinicos,
    path: "/veterinaria/analisis-clinicos"
}

const CONTACTO_VETERINARIA = {
    component: VeterinariaContacto,
    path: "/veterinaria/contacto"
}

const routesVeterinaria = [ ANALISIS_CLINICOS_VETERINARIA, CONTACTO_VETERINARIA ]

export default routesVeterinaria
