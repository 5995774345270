import React, { Component } from 'react';

import '../components/css/MenuCMR.css';

//import { Redirect } from 'react-router';

import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import sessionService from '../../../../sessionService/sessionService';

import url from '../../../../apiService/ApiUrl.js';

import 'mfb/src/mfb.css';

import 'mfb/src/mfb.js';

import {Link} from 'react-router-dom';

import Loader from '../../../Components/Loader.jsx';

//Recordar cambiar el main color en en node_modules/mfb/srcmfb.scss a #2984be

/*
Y agregar
.mfb-component__icon{
  position: absolute;
  font-size: 18px;
  text-align: center;
  line-height: 56px !important;
  width: 100%;
}
*/

class MenuCMR extends Component{

	constructor(props) {
    super(props);

    this.OnLogOut = this.OnLogOut.bind(this);

  }

	OnLogOut() {

		let sessionServiceCallback = () => {
      
      if(this.props.conexion === true){
        return <Loader />
      }else{
        //return <Redirect to='/login' />
      }
    }

		sessionService.logOut(url, this, sessionServiceCallback)
    /*.then( () => {
       return <Redirect to='/login' />
    })*/

  }

	render(){

		if(window.location.pathname === "/dashboard"){
			return null
		} else {
			return(
				<ul className="mfb-component--br mfb-zoomin" data-mfb-toggle="hover" data-mfb-state="closed">
					<li className="mfb-component__wrap">
				    {/*<!-- the main menu button -->*/}
				    <span data-mfb-label="Menu" className="mfb-component__button--main">
				      {/*<!-- the main button icon visibile by default -->*/}
							<FontAwesomeIcon className="mfb-component__main-icon--resting" icon="bars" style={{'left' : '20px', 'top' : '20px'}} />
				      {/*<!-- the main button icon visibile when the user is hovering/interacting with the menu -->*/}
							<FontAwesomeIcon className="mfb-component__main-icon--active" icon="times" style={{'left' : '22px', 'top' : '20px'}} />
				    </span>
				    <ul className="mfb-component__list">
				
				      	{/*<!-- a child button, repeat as many times as needed -->*/}
				
						<li>
							<a data-mfb-label="Llamar a Biolab" className="mfb-component__button--child" href="tel:50225063131" >
								<FontAwesomeIcon className="mfb-component__child-icon" icon="phone" style={{'left' : '20px', 'top' : '20px'}} />
							</a>
						</li>

						<li>
							<Link to="/pacientes/resultados-en-linea" data-mfb-label="Ver Resultados en Línea" className="mfb-component__button--child">
								<FontAwesomeIcon className="mfb-component__child-icon" icon="file-medical-alt" style={{'left' : '18px', 'top' : '20px'}} />
							</Link>
						</li>

						<li>
							<Link to="/pacientes/contacto" data-mfb-label="Contáctanos por Formulario" className="mfb-component__button--child">
								<FontAwesomeIcon className="mfb-component__child-icon" icon="envelope-open-text" style={{'left' : '18px', 'top' : '20px'}} />
							</Link>
						</li>

						{
							(this.props.conexion === true) || ((this.props.conexion === true) && (this.props.usuario.permisos.includes('adminUI') === false)) ? (
								<li>
									<Link to="/dashboard" data-mfb-label="Regresar a Dashboard" className="mfb-component__button--child">
										<FontAwesomeIcon className="mfb-component__child-icon" icon="tachometer-alt" style={{'left' : '18px', 'top' : '20px'}} />
									</Link>
								</li>
							) : null
						}

						{
							(this.props.conexion === true) || ((this.props.conexion === true) && (this.props.usuario.permisos.includes('adminUI') === false)) ? (
								<li>
									<span data-mfb-label="Log Out" onClick={this.OnLogOut} className="mfb-component__button--child">
										<FontAwesomeIcon className="mfb-component__child-icon" icon="sign-out-alt" style={{'left' : '20px', 'top' : '20px'}} />
									</span>
								</li>
							) : null
						}
					  	

				    </ul>
				  </li>
				</ul>
			)
		}
	}

}

let mapStateToProps = (state, props) => {
  return {
		conexion: state.logIn.conexion,
    usuario: state.logIn.usuario
  }
}

export default connect(mapStateToProps)(MenuCMR);