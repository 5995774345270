import GraphQL from './ConsultaGraphQL';

import url from '../../../../../../apiService/ApiUrl.js';

// JavaScript Document
function handleError(err, This){
	console.log(`Request failed: ${err}`);
	This.setState({
		insertEmpresaMensaje: {
			response: 'failure',
			mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
		}
	})
}

const insertEmpresa = (This, empresaJSON) => {
	//console.log(usuarioJSON)
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(empresaJSON)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {
		//console.log(res.data.insertEmpresa.empresa)

		This.setState({
			insertEmpresaMensaje: {
				response: 'success',
				mensaje: res.data.empresaMutation.insertEmpresa.mensaje
			}
		})

		This.props.dispatch({
			type: 'ADD_EMPRESA_TO_EMPRESAS',
			payload: {
				dataEmpresa: res.data.empresaMutation.insertEmpresa.empresa
			}
		})
		return res
	})
	.catch(err => handleError(err, This));

	//termina fetch
}

export default insertEmpresa;
