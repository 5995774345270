import React from 'react';

import EmpresasEIndustrias from '../../Components/EmpresasEIndustrias.jsx';

import GaleriaAnalisisFisicoQuimico from '../Containers/GaleriaAnalisisFisicoQuimico.jsx';

import AnalisisDeAgua from '../Containers/AnalisisDeAgua.jsx';

import AnalisisDeAlimentos from '../Containers/AnalisisDeAlimentos.jsx';

let AnalisisFisicoQuimico = props => (
  <EmpresasEIndustrias>
    <GaleriaAnalisisFisicoQuimico />
    <section id="seccion-analisis-agua-alimentos" style={{"marginBottom": "0px"}}>
      <AnalisisDeAgua />
      <AnalisisDeAlimentos />
    </section>
    <section id="seccion-mensaje" style={{"marginTop": "0px", "marginBottom": "25px"}}>
      <div className="container">
        <div id="mensaje" className="row" style={{"marginRight": "0px", "marginTop": "0px"}}>
          <span className="col-12" href="contacto.html" rel="external" data-ajax="false">
          <h3 className="col-12 text-center"><span className="icon-light-bulb"></span> CONSULTE NUESTROS SERVICIOS ADICIONALES</h3><br />
          <em><p className="col-12 text-center" style={{"textAlign": "center", "paddingLeft": "15px"}}>(Servicios de mensajería, asesorías, capacitaciones, entre otros)</p></em>
          </span>
        </div>
      </div>
    </section>
  </EmpresasEIndustrias>
)

export default AnalisisFisicoQuimico;
