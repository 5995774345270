// JavaScript Document
import GraphQL from './ConsultaGraphQLGetTipoClientes';

import url from '../../../../../../apiService/ApiUrl.js';
import urlencode from 'urlencode';

function handleError(err){
	console.log(`Request failed: ${err}`);
}

const fetchGetTipoClientes = (setState) => {

	const options = {
		method: 'GET',
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(`${url}?query=query${urlencode(GraphQL())}`, options)
	.then(res => res.json())
	.then( (res) => {

		setState({
			tipoClientes: res.data.appInfo.productos.tipoClientes
		})

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchGetTipoClientes;
