import $ from 'jquery';

// JavaScript Document

let menu = () => {}

//MenuInicio
menu.mountMenuInicio = () => {
	// media query event handler
	if (matchMedia) {
		var mq = window.matchMedia("(min-width: 768px)");
		mq.addListener(WidthChange);
		WidthChange(mq);
	}

	// media query change
	function WidthChange(mq) {
		if (mq.matches) {
			// window width is at least 767px
			var scroll;

			//$("#NavbarInicio").removeClass("fixed-top").css({"height":"100px"});

			$(window).scroll(function(){
				scroll = $(window).scrollTop();
				$("#NavbarInicio").addClass("fixed-top").css({"height":"80px", "top":"0px"});
				if(scroll >= 100){
					$("#NavbarInicio-collapse").addClass("fixed-top");
					$("#navbar-nav-inicio").css("top", "60px");
					$("#NavbarInicio").css({"height": "60px", "margin-top":"0px"});
					$("#brand").css({"height": "50px"})	
				}else{
					$("#NavbarInicio-collapse").removeClass("fixed-top");
					$("#navbar-nav-inicio").css("top", "80px");
					$("#NavbarInicio").css({"height": "80px", "margin-top":"0px"});
					$("#brand").css({"height": "75px"})
				}
			});


		} else {
		// window width is less than 767px

			$("#NavbarInicio").addClass("fixed-top").css({"height":"80px", "top":"0px"});
			$("#NavbarInicio-collapse").css("height", "100%");
			$("#navbar-nav-inicio").css("top", "0px");

			$(window).scroll(function(){
				scroll = $(window).scrollTop();
				if(scroll >= 100){
					$("#NavbarInicio").addClass("fixed-top");
					$("#navbar-nav-inicio").css("top", "0px");
					$("#NavbarInicio-collapse").css("top", "-16px");
					$("#NavbarInicio").css("height", "80px");
					$("#brand").css({"height": "50px"})	
				}else{
					$("#NavbarInicio").addClass("fixed-top");
					$("#navbar-nav-inicio").css("top", "0px");
					$("#NavbarInicio-collapse").css("top", "-16px");
					$("#brand").css({"height": "50px"})
				}
			});

		}
	}
}
//Termina MenuInicio

//MenuPacientes
menu.mountMenuPacientes = () => {

	// media query event handler
	if (matchMedia) {
		var mq = window.matchMedia("(min-width: 768px)");
		mq.addListener(WidthChange);
		WidthChange(mq);
	}

	// media query change
	function WidthChange(mq) {
		if (mq.matches) {
			// window width is at least 767px
			var scroll;

			$("#NavbarPacientes").removeClass("fixed-top").css({"height":"100px"});

			$(window).scroll(function(){
				scroll = $(window).scrollTop();
				$("#NavbarPacientes").removeClass("fixed-top").css({"height":"100px", "top":"0px"});
				if(scroll >= 100){
					$("#NavbarPacientes-collapse").addClass("fixed-top");
					$("#navbar-nav-pacientes").css("top", "0px");
				}else{
					$("#NavbarPacientes-collapse").removeClass("fixed-top");
					$("#navbar-nav-pacientes").css("top", "100px");
				}
				$("#NavbarPacientes").removeClass("fixed-top").css({"height":"100px"});
			});


		} else {
		// window width is less than 767px

			$("#NavbarPacientes").addClass("fixed-top").css({"height":"100px", "top":"0px"});
			$("#NavbarPacientes-collapse").css("height", "100%");
			$("#navbar-nav-pacientes").css("top", "0px");

			$(window).scroll(function(){
				$("#NavbarPacientes").addClass("fixed-top").css({"height":"100px", "top":"0px"});
				$("#NavbarPacientes-collapse").css("height", "100%");
				scroll = $(window).scrollTop();
				if(scroll >= 100){
					$("#NavbarPacientes").addClass("fixed-top");
					$("#navbar-nav-pacientes").css("top", "0px");
					$("#brand").css({"height": "43px"})
					$(".navbar-collapse").css("top", "-4px");
				}else{
					$("#NavbarPacientes").addClass("fixed-top");
					$("#navbar-nav-pacientes").css("top", "0px");
					$("#brand").css({"height": "43px"})
					$(".navbar-collapse").css("top", "-4px");
				}
				$("#NavbarPacientes").addClass("fixed-top").css({"height":"100px", "top":"0px"});
				$("#NavbarPacientes-collapse").css("height", "100%");
			});

		}
	}
}
//Termina MenuPacientes

//MenuLaboratoriosYHospitales
menu.mountMenuLaboratoriosYHospitales= () => {

	// media query event handler
	if (matchMedia) {
		var mq = window.matchMedia("(min-width: 768px)");
		mq.addListener(WidthChange);
		WidthChange(mq);
	}

	// media query change
	function WidthChange(mq) {
		if (mq.matches) {
			// window width is at least 767px
			var scroll;

			$("#NavbarLabsYHosp").removeClass("fixed-top").css({"height":"100px"});

			$(window).scroll(function(){
				scroll = $(window).scrollTop();
				$("#NavbarLabsYHosp").removeClass("fixed-top").css({"height":"100px", "top":"0px"});
				if(scroll >= 100){
					$("#NavbarLabsYHosp-collapse").addClass("fixed-top");
					$("#navbar-nav-LabsYHosp").css("top", "0px");
				}else{
					$("#NavbarLabsYHosp-collapse").removeClass("fixed-top");
					$("#navbar-nav-LabsYHosp").css("top", "100px");
				}
				$("#NavbarLabsYHosp").removeClass("fixed-top").css({"height":"100px"});
			});


		} else {
		// window width is less than 767px

			$("#NavbarLabsYHosp").addClass("fixed-top").css({"height":"100px", "top":"0px"});
			$("#NavbarLabsYHosp-collapse").css("height", "100%");
			$("#navbar-nav-LabsYHosp").css("top", "0px");

			$(window).scroll(function(){
				$("#NavbarLabsYHosp").addClass("fixed-top").css({"height":"100px", "top":"0px"});
				$("#NavbarLabsYHosp-collapse").css("height", "100%");
				scroll = $(window).scrollTop();
				if(scroll >= 100){
					$("#NavbarLabsYHosp").addClass("fixed-top");
					$("#navbar-nav-LabsYHosp").css("top", "0px");
					$("#brand").css({"height": "43px"})
					$(".navbar-collapse").css("top", "-4px");
				}else{
					$("#NavbarLabsYHosp").addClass("fixed-top");
					$("#navbar-nav-LabsYHosp").css("top", "0px");
					$("#brand").css({"height": "43px"})
					$(".navbar-collapse").css("top", "-4px");
				}
				$("#NavbarLabsYHosp").addClass("fixed-top").css({"height":"100px", "top":"0px"});
				$("#NavbarLabsYHosp-collapse").css("height", "100%");
			});

		}
	}
}
//Termina MenuLaboratoriosYHospitales

//MenuLaboratoriosYHospitales
menu.mountMenuEmpresasEIndustrias= () => {

	// media query event handler
	if (matchMedia) {
		var mq = window.matchMedia("(min-width: 768px)");
		mq.addListener(WidthChange);
		WidthChange(mq);
	}

	// media query change
	function WidthChange(mq) {
		if (mq.matches) {
			// window width is at least 767px
			var scroll;

			$("#NavbarEmpresasEIndustrias").removeClass("fixed-top").css({"height":"100px"});

			$(window).scroll(function(){
				scroll = $(window).scrollTop();
				$("#NavbarEmpresasEIndustrias").removeClass("fixed-top").css({"height":"100px", "top":"0px"});
				if(scroll >= 100){
					$("#NavbarEmpresasEIndustrias-collapse").addClass("fixed-top");
					$("#navbar-nav-NavbarEmpresasEIndustrias").css("top", "0px");
				}else{
					$("#NavbarEmpresasEIndustrias-collapse").removeClass("fixed-top");
					$("#navbar-nav-NavbarEmpresasEIndustrias").css("top", "100px");
				}
				$("#NavbarEmpresasEIndustrias").removeClass("fixed-top").css({"height":"100px"});
			});


		} else {
		// window width is less than 767px

			$("#NavbarEmpresasEIndustrias").addClass("fixed-top").css({"height":"100px", "top":"0px"});
			$("#NavbarEmpresasEIndustrias-collapse").css("height", "100%");
			$("#navbar-nav-NavbarEmpresasEIndustrias").css("top", "0px")

			$(window).scroll(function(){
				$("#NavbarEmpresasEIndustrias").addClass("fixed-top").css({"height":"100px", "top":"0px"});
				$("#NavbarEmpresasEIndustrias-collapse").css("height", "100%");
				scroll = $(window).scrollTop();
				if(scroll >= 100){
					$("#NavbarEmpresasEIndustrias").addClass("fixed-top");
					$("#navbar-nav-NavbarEmpresasEIndustrias").css("top", "0px");
					$("#brand").css({"height": "43px"})
					$(".navbar-collapse").css("top", "-4px");
				}else{
					$("#NavbarLabsYHosp").addClass("fixed-top");
					$("#navbar-nav-NavbarEmpresasEIndustrias").css("top", "0px");
					$("#brand").css({"height": "43px"})
					$(".navbar-collapse").css("top", "-4px");
				}
				$("#NavbarEmpresasEIndustrias").addClass("fixed-top").css({"height":"100px", "top":"0px"});
				$("#NavbarEmpresasEIndustrias-collapse").css("height", "100%");
			});

		}
	}
}
//Termina MenuLaboratoriosYHospitales

//Menu Veterinaria
menu.mountMenuVeterinaria = () => {

	// media query event handler
	if (matchMedia) {
		var mq = window.matchMedia("(min-width: 768px)");
		mq.addListener(WidthChange);
		WidthChange(mq);
	}

	// media query change
	function WidthChange(mq) {
		if (mq.matches) {
			// window width is at least 767px
			var scroll;

			$("#NavbarVeterinaria").removeClass("fixed-top").css({"height":"100px"});

			$(window).scroll(function(){
				scroll = $(window).scrollTop();
				$("#NavbarVeterinaria").removeClass("fixed-top").css({"height":"100px", "top":"0px"});
				if(scroll >= 100){
					$("#NavbarVeterinaria-collapse").addClass("fixed-top");
					$("#navbar-nav-Veterinaria").css("top", "0px");
				}else{
					$("#NavbarVeterinaria-collapse").removeClass("fixed-top");
					$("#navbar-nav-Veterinaria").css("top", "100px");
				}
				$("#NavbarVeterinaria").removeClass("fixed-top").css({"height":"100px"});
			});


		} else {
		// window width is less than 767px

			$("#NavbarVeterinaria").addClass("fixed-top").css({"height":"100px", "top":"0px"});
			$("#NavbarVeterinaria-collapse").css("height", "100%");
			$("#navbar-nav-Veterinaria").css("top", "0px");

			$(window).scroll(function(){
				$("#NavbarVeterinaria").addClass("fixed-top").css({"height":"100px", "top":"0px"});
				$("#NavbarVeterinaria-collapse").css("height", "100%");
				scroll = $(window).scrollTop();
				if(scroll >= 100){
					$("#NavbarVeterinaria").addClass("fixed-top");
					$("#navbar-nav-Veterinaria").css("top", "0px");
					$("#brand").css({"height": "43px"})
					$(".navbar-collapse").css("top", "-4px");
				}else{
					$("#NavbarVeterinaria").addClass("fixed-top");
					$("#navbar-nav-Veterinaria").css("top", "0px");
					$("#brand").css({"height": "43px"})
					$(".navbar-collapse").css("top", "-4px");
				}
				$("#NavbarVeterinaria").addClass("fixed-top").css({"height":"100px", "top":"0px"});
				$("#NavbarVeterinaria-collapse").css("height", "100%");
			});

		}
	}
}
//Termina Menu Veterinaria

export default menu
