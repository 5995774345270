import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NavLink } from 'react-router-dom';

let RealizarOrdenes = props => (
    <div className="container" style={{minHeight: '400px'}}>
        <div className="row" style={{marginTop: '50px'}}>
            <div className="col-12 col-md-6" style={{marginTop: '15px'}}>
                <NavLink exact to="/dashboard/mensajeria/realizar-orden/administrativa" style={{textDecoration: 'none'}}>
                    <button className="btn btn-outline-info btn-block" style={{padding: '30px'}}><FontAwesomeIcon icon="clipboard-list" style={{fontSize: '50px'}} /> Orden Administrativa</button>
                </NavLink>
            </div>
            <div className="col-12 col-md-6" style={{marginTop: '15px'}}>
                <NavLink exact to="/dashboard/mensajeria/realizar-orden/muestras" style={{textDecoration: 'none'}}>
                    <button className="btn btn-outline-info btn-block" style={{padding: '30px'}}><FontAwesomeIcon icon="vials" style={{fontSize: '50px'}} /> Recolección de Muestras</button>
                </NavLink>
            </div>
        </div>
    </div>
)

export default RealizarOrdenes