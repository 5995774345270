import React, { Component } from 'react';

import { connect } from 'react-redux';

import EmpresasEIndustrias from '../../Components/EmpresasEIndustrias.jsx';

import Summernote from '../../../../Summernote/Containers/Summernote.jsx';

import SaludOcupacionalComponent from '../Components/SaludOcupacional.jsx';

class SaludOcupacional extends Component {

  render(){

    let renderVal = this.props.dataUI,
        textSaludOcupacionalObj = renderVal.textos.find( text => {
          return text.id === 'tex016'
        }),
        texto = textSaludOcupacionalObj.texto

    return(
      <EmpresasEIndustrias>
        <SaludOcupacionalComponent>
          <Summernote itemID='#texto-salud-ocupacional' textObj={textSaludOcupacionalObj} texto={texto} url='http://localhost:8080/updateText' />
        </SaludOcupacionalComponent>
      </EmpresasEIndustrias>
    )

  }


}

let mapStateToProps = (state, props) => {
  return {
    dataUI: state.dataUI
  }
}

export default connect(mapStateToProps)(SaludOcupacional);
