import React, { Component } from 'react';

import { connect } from 'react-redux';

import moment from 'moment';

import FetchEditObjetivo from './EditObjetivoFetch/FetchEditObjetivoPromise.js';

import ModalB4 from 'react-bootstrap4-modal';
import ModalContainer from '../../../../Modal/Components/Modal.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ObjetivoView extends Component {

  componentDidMount(){

    let objetivo = this.props.dataAdminObjetivos.objetivos.filter( objetivo => objetivo.id === this.props.objetivo.id)[0]

    let metasFinalizadas = objetivo.metas.filter( meta => meta.estado === "Finalizado").length,
          metasFinalizadasSinAlcanzarMeta = objetivo.metas.filter( meta => meta.estado === "Finalizado Sin Alcanzar Meta").length,
          metasLength = objetivo.metas.length,
          totalFinalizadas = metasFinalizadas + metasFinalizadasSinAlcanzarMeta

    if(metasLength > 0){
      if(metasLength === totalFinalizadas){
        objetivo.estado = "Finalizado"
        objetivo.fechaFinal = new Date();
        FetchEditObjetivo(this, objetivo)
      }else{
        objetivo.estado = "En Proceso"
        objetivo.fechaFinal = null;
        FetchEditObjetivo(this, objetivo)
      }
    }


  }

  render(){
    //let objetivo = this.props.objetivo,
    let objetivo = this.props.dataAdminObjetivos.objetivos.filter( objetivo => objetivo.id === this.props.objetivo.id)[0],
      modalVisible = this.props.modalVisible,
      porcentaje = 0,
      sucursal = objetivo.idSucursal !== null ? this.props.sucursales.filter( sucursal => sucursal.id === objetivo.idSucursal)[0] : null,
      handleCloseModal = this.props.handleCloseModal

    if(objetivo.metas.length > 0){

      let metasFinalizadas = objetivo.metas.filter( meta => meta.estado === "Finalizado").length,
          metasFinalizadasSinAlcanzarMeta = objetivo.metas.filter( meta => meta.estado === "Finalizado Sin Alcanzar Meta").length,
          metasLength = objetivo.metas.length,
          totalFinalizadas = metasFinalizadas + metasFinalizadasSinAlcanzarMeta

      porcentaje = (totalFinalizadas * 100) / metasLength

    }

    return(
        <ModalContainer>
          <ModalB4 visible={modalVisible} fade={true} className="modal-type1">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                <img className="brand-modal" src="/img/Inicio/LogoBiolabBlanco.png" alt="LogoBiolabBlanco" />
                <button type="button" className="close cerrar-cruz" onClick={handleCloseModal} aria-label="Close">
                  <div></div>
                  <div></div>
                </button>
                </div>

                <div className="modal-body sin-margen-y-padding container-fluid">
                  <div className="row text-center">


                    <div className="col-12">
                      <FontAwesomeIcon icon="calendar-check" style={{'fontSize': '400%', 'marginTop': '25px'}} />
                      <h1>Nombre de Objetivo: {objetivo.nombre}</h1>
                    </div>
                    <div className="col-12 row" style={{'marginTop': '25px'}}>

                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="nombreObjetivo" style={{'marginTop': '0px'}}><b>Nombre:</b> {objetivo.nombre}</p>
                      </div>

                      {
                        objetivo.idSucursal !== null ? (
                          <div className="col-md-4 row mx-auto">
                            <p className="col" id="nombreSucursal" style={{'marginTop': '0px'}}><b>Sucursal:</b> {sucursal.nombre_comercial}</p>
                          </div>
                        ) : null
                      }

                      {
                        objetivo.metas.length > 0 ? (
                          <div className="col-md-4 row mx-auto">
                            <b className="text-center col-12">Metas del Objetivo:</b>
                            <ul>
                              {
                                objetivo.metas.map( (meta, i) => <li key={i} className="col text-left" style={{'marginTop': '0px'}}><b>Nombre:</b> {meta.nombre} <br/> <b>Estado:</b> {meta.estado}</li>)
                              }
                            </ul>
                          </div>
                        ) : null
                      }

                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="creadorObjetivo" style={{'marginTop': '0px'}}><b>Creador de Objetivo:</b> {objetivo.creador.nombre} {objetivo.creador.apellidos}</p>
                      </div>

                      <div className="col-md-4 row mx-auto">
                        <b className="text-center col-12">Involucrados:</b>
                        <ul>
                          {
                            objetivo.invitados.map( (invitado, i) => <li key={i} className="col text-left" style={{'marginTop': '0px'}}>{invitado.nombre} {invitado.apellidos}</li>)
                          }
                        </ul>
                      </div>

                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="FechaInicioObjetivo" style={{'marginTop': '0px'}}><b>Fecha de Inicio:</b> {moment(objetivo.fechaDeInicio).format("DD/MM/YYYY")} a las {moment(objetivo.fechaDeInicio).format("HH:mm")} horas</p>
                      </div>

                      {
                        objetivo.fechaFinal !== null ? (
                          <div className="col-md-4 row mx-auto">
                            <p className="col" id="FechaFinalObjetivo" style={{'marginTop': '0px'}}><b>Fecha Final:</b> {moment(objetivo.fechaFinal).format("DD/MM/YYYY")} a las {moment(objetivo.fechaFinal).format("HH:mm")} horas</p>
                          </div>
                        ) : null
                      }

                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="FechaFinalPlanificadaObjetivo" style={{'marginTop': '0px'}}><b>Fecha Final Planificada:</b> {moment(objetivo.fechaFinalPlanificada).format("DD/MM/YYYY")} a las {moment(objetivo.fechaFinalPlanificada).format("HH:mm")} horas</p>
                      </div>

                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="estadoObjetivo" style={{'marginTop': '0px'}}><b>Estado:</b> {objetivo.estado} {objetivo.estado === 'Finalizado' ? <FontAwesomeIcon icon="check" style={{'fontSize': '25px', color: 'rgb(119, 255, 52)'}} /> : <FontAwesomeIcon icon="history" style={{'fontSize': '25px', color: 'rgb(45, 166, 196)'}} />}</p>
                      </div>

                      {
                        objetivo.tipo !== null ? (
                          <div className="col-md-4 row mx-auto">
                            <p className="col" id="tipoObjetivo" style={{'marginTop': '0px'}}><b>Tipo de Objetivo:</b> {objetivo.tipo}</p>
                          </div>
                        ) : null
                      }


                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="nivelDeVisualizacionObjetivo" style={{'marginTop': '0px'}}><b>Nivel de Vizualización:</b> {objetivo.nivelDeVisualizacion}</p>
                      </div>

                      <div className="col-md-4 row mx-auto">
                        <p className="col" id="observacionesObjetivo" style={{'marginTop': '0px'}}><b>Observaciones de Objetivo:</b> {objetivo.observaciones}</p>
                      </div>

                      {
                        objetivo.metas.length > 0 ? (
                          <div className="col-12 col-md-9 mx-auto">
                            <b className="text-center col-12">Avance Objetivo:</b>
                            <div className="progress">
                              <div className="progress-bar bg-info" style={{ width: `${porcentaje}%` }} role="progressbar" aria-valuenow={porcentaje} aria-valuemin="0" aria-valuemax="100">{porcentaje}%</div>
                            </div>
                          </div>
                        ) : null
                      }

                    </div>

                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default mx-auto" onClick={handleCloseModal}>Cerrar</button>
                </div>
              </div>
            </div>
        </ModalB4>
      </ModalContainer>
    )
  }
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario,
    usuarios: state.dataUsers.usuarios,
    dataAdminObjetivos: state.dataAdminObjetivos,
    dataEmpresas: state.dataEmpresas
  }
}

export default connect(mapStateToProps)(ObjetivoView)
