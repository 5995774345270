import React from 'react';
import './css/AnalisisClinicos.css';

//ImportandoModal
import ModalPortal from '../../../Components/ModalPortal.jsx';
import Modal from '../../../Modal/Containers/Modal.jsx';

//Components Generales
import AnalisisPreventivos from '../../Containers/AnalisisClinicos/AnalisisPreventivos.jsx';
import DiagnosticoClinico from '../../Containers/AnalisisClinicos/DiagnosticoClinico.jsx';

//Components Pacientes
import Pacientes from '../../Pacientes/Components/Pacientes.jsx';
// import GaleriaAnalisisClinicosPacientes from '../../Pacientes/AnalisisClinicos/Containers/GaleriaAnalisisClinicos.jsx';
import PortadaAnalisisClinicos from '../../Pacientes/AnalisisClinicos/Components/PortadaAnalisisClinicos'

//Components Hospitales y LaboratoriosYHospitales
import LaboratoriosYHospitales from '../../LaboratoriosYHospitales/Components/LaboratoriosYHospitales.jsx';
import PortadaAnalisisClinicosLabYHosp from '../../LaboratoriosYHospitales/AnalisisClinicos/Components/PortadaAnalisisClinicos';
// import GaleriaAnalisisClinicosLabsYHosp from '../../LaboratoriosYHospitales/AnalisisClinicos/Containers/GaleriaAnalisisClinicos.jsx';

//Components Veterinaria
import Veterinaria from '../../Veterinaria/Components/Veterinaria.jsx';
import GaleriaAnalisisClinicosVeterinaria from '../../Veterinaria/AnalisisClinicos/Containers/GaleriaAnalisisClinicos.jsx';

const PacientesAnalisisClinicos = (props) => {
	if(props.path === '/pacientes/analisis-clinicos'){
		return (
			<Pacientes>
				<PortadaAnalisisClinicos />
				<AnalisisPreventivos path={props.path}/>
				<DiagnosticoClinico path={props.path}/>
				<ModalPortal>
					<Modal />
				</ModalPortal>
			</Pacientes>
		)
	}else if(props.path === '/laboratorios-y-hospitales/analisis-clinicos'){
		return(
			<LaboratoriosYHospitales>
				<PortadaAnalisisClinicosLabYHosp />
				<AnalisisPreventivos path={props.path}/>
				<DiagnosticoClinico path={props.path}/>
				<ModalPortal>
					<Modal />
				</ModalPortal>
			</LaboratoriosYHospitales>
		)
	}else if(props.path === '/veterinaria/analisis-clinicos'){
		return(
			<Veterinaria>
				<GaleriaAnalisisClinicosVeterinaria />
				<DiagnosticoClinico path={props.path}/>
				<ModalPortal>
					<Modal />
				</ModalPortal>
			</Veterinaria>
		)
	}else {
		return null
	}
}

export default PacientesAnalisisClinicos;
