import React from 'react';
// import ImagenPortadaContainer from '../../Containers/ImagenPortada.jsx';
import GaleriaPortada from '../../Containers/GaleriaPortada'
import BotonesPortada from './BotonesPortada.jsx';

import './css/Portada.css';

const Portada = (props) => (
	<section id="inicio-portada" className="container-fluid sin-margen-y-padding">
			<div id="cont-img-btns" className="row sin-margen-y-padding">
				<GaleriaPortada />
				<BotonesPortada />
			</div>
	</section>
)

export default Portada;
