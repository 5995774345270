let consulta = (metaJSON) => {

  const string =
    `mutation {
      adminObjetivos {
        deleteMeta(idMeta: "${metaJSON.id}"){
          id
          mensaje
        }
      }
    }`

    return {
      "query": string
    }

}

export default consulta
