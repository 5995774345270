let consulta = () => {
  let string = `{
    appInfo{
      sexoVals
      permisoVals
      tipoVals
      puestoVals
      departamentoVals
    }
  }`

  /*return {
    "query": string
  }*/

  return string

}

export default consulta;
