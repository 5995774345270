import GraphQL from './ConsultaGraphQL';

import url from '../../../../../../../../apiService/ApiUrl.js';

import GraphQLInsertRuta from '../FetchInsertRuta/fetchGraphQL.js';

function handleError(err, This){
	console.log(`Request failed: ${err}`);
	This.setState({
		mensajeInsertRuta: {
			response: 'failure',
			mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
		}
	})
}

const insertOrdenes = (This, data) => {

	const options = {
		method: 'POST',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(data)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		This.setState({
			ordenesRuta: res.data.mensajeriaMutation.insertOrdenes.ids,
			mensajeInsertRuta: {
				response: 'success',
				mensaje: `${res.data.mensajeriaMutation.insertOrdenes.mensaje}`
			}
		})

	}).then( ()=>{

		let data = {
			idMensajero: This.state.selectedOptionMensajeros.value.id,
			nombreRuta: This.state.nombreRuta,
			ordenesRuta: This.state.ordenesRuta,
			fechaRuta: This.state.fechaRuta
		}

		GraphQLInsertRuta(This, data)

		setTimeout( ()=>{ 
			This.setState({
				mensajeInsertRuta: null
			})
		}, 3000)

	})
	.catch(err => handleError(err, This))
	.then( ()=>{
		setTimeout( ()=>{ 
			This.setState({
				orden: null,
				ordenesDesde: "",
				ordenesHasta: "",
				selectedOptionMensajeros: null,
				nombreRuta: "",
				ordenesRuta: [],
				mensajeInsertRuta: null
			})
		}, 3000)
	});

	//termina fetch
}

export default insertOrdenes;
