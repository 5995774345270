/*Import QR Scanner*/
import DashboardMensajeriaQR from '../../Dashboard/Mensajeria/containers/QRScanner/DashboardQR.jsx';
/*Import RealizarOrdenMensajeria*/
import RealizarOrdenMensajeria from '../../Dashboard/Mensajeria/containers/RealizarOrden/DashboardRealizarOrden.jsx';
/*Import RealizarOrdenMuestras*/
import RealizarOrdenMuestras from '../../Dashboard/Mensajeria/containers/RealizarOrden/DashboardRealizarOrdenMuestras.jsx';
/*Import RealizarOrdenAdministrativa*/
import RealizarOrdenAdministrativa from '../../Dashboard/Mensajeria/containers/RealizarOrden/DashboardRealizarOrdenAdministrativa.jsx';
/*Import MensajeriaTodasOrdenes*/
import MensajeriaTodasOrdenes from '../../Dashboard/Mensajeria/containers/MensajeriaTodasOrdenes.jsx';
/*Import MensajeriaRealizarRutas*/
import MensajeriaRealizarRutas from '../../Dashboard/Mensajeria/containers/Rutas/RealizarRutas/containers/RealizarRutas.jsx';
/*Import MensajeriaVerRutas*/
import MensajeriaVerRutas from '../../Dashboard/Mensajeria/containers/Rutas/VerRutas/containers/VerRutas.jsx';
/*Import MensajeriaRealizarPlantillas*/
import MensajeriaRealizarPlantillas from '../../Dashboard/Mensajeria/containers/Rutas/PlantillasRutas/containers/RealizarPlantillaRuta.jsx';
/*Import MensajeriaVerPlantillas*/
import MensajeriaVerPlantillasRutas from '../../Dashboard/Mensajeria/containers/Rutas/PlantillasRutas/containers/VerPlantillasRutas.jsx';

const QR_MENSAJERIA = {
    component: DashboardMensajeriaQR,
    path: "/dashboard/mensajeria/qr"
}

const REALIZAR_ORDEN_MENSAJERIA = {
    component: RealizarOrdenMensajeria,
    path: "/dashboard/mensajeria/realizar-orden"
}

const REALIZAR_ORDEN_MUESTRAS_MENSAJERIA = {
    component: RealizarOrdenMuestras,
    path: "/dashboard/mensajeria/realizar-orden/muestras"
}

const REALIZAR_ORDEN_ADMINISTRATIVA_MENSAJERIA = {
    component: RealizarOrdenAdministrativa,
    path: "/dashboard/mensajeria/realizar-orden/administrativa"
}

const ORDENES_MENSAJERIA = {
    component: MensajeriaTodasOrdenes,
    path: "/dashboard/mensajeria/ordenes"
}

const REALIZAR_RUTAS_MENSAJERIA = {
    component: MensajeriaRealizarRutas,
    path: "/dashboard/mensajeria/realizar-ruta"
}

const RUTAS_MENSAJERIA = {
    component: MensajeriaVerRutas,
    path: "/dashboard/mensajeria/rutas"
}

const REALIZAR_PANTILLA_RUTAS_MENSAJERIA = {
    component: MensajeriaRealizarPlantillas,
    path: "/dashboard/mensajeria/realizar-plantilla-ruta"
}

const PANTILLA_RUTAS_MENSAJERIA = {
    component: MensajeriaVerPlantillasRutas,
    path: "/dashboard/mensajeria/plantillas-rutas"
}

let routesMensajeria = [ QR_MENSAJERIA, REALIZAR_ORDEN_MENSAJERIA, REALIZAR_ORDEN_MUESTRAS_MENSAJERIA, REALIZAR_ORDEN_ADMINISTRATIVA_MENSAJERIA, ORDENES_MENSAJERIA, REALIZAR_RUTAS_MENSAJERIA, RUTAS_MENSAJERIA, REALIZAR_PANTILLA_RUTAS_MENSAJERIA, PANTILLA_RUTAS_MENSAJERIA ]

export default routesMensajeria